import axios from "axios";

export default class FaultReportEvidenceService {
  getFaultReportEvidencebyFaultReport(id_reporte_falla) {
    const url = process.env.VUE_APP_APIURL + `reportefallaarchivo/reportefalla`;
    return axios
      .get(url, { params: { id_reporte_falla: id_reporte_falla } })
      .then((res) => res.data.data);
  }
  createFaultReportEvidence(reportefallaarchivo) {
    const url = process.env.VUE_APP_APIURL + `/reportefallaarchivo`;
    return axios.post(url, reportefallaarchivo).then((res) => res.data.data);
  }
  updateFaultReportEvidence(reportefallaarchivo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/reportefallaarchivo/${reportefallaarchivo.id_reporte_falla_archivo}`;
    return axios.put(url, reportefallaarchivo).then((res) => res.data.data);
  }
  deleteFaultReportEvidence(reportefallaarchivo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/reportefallaarchivo/${reportefallaarchivo.id_reporte_falla_archivo}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
