import axios from 'axios';

export default class AbilityService {

	getHabilidades() {		
        const url = process.env.VUE_APP_APIURL + `habilidades`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getHabilidad(habilidad) {
        const url = process.env.VUE_APP_APIURL + `/habilidades/${habilidad.id_habilidad}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateHabilidad(habilidad){
        const url = process.env.VUE_APP_APIURL + `/habilidades/${habilidad.id_habilidad}`;
		return axios.put(url,habilidad).then(res=>res.data.data);
	}

	createHabilidad(habilidad){
        const url = process.env.VUE_APP_APIURL + `/habilidades`;
		return axios.post(url,habilidad).then(res=>res.data.data);
	}

	deleteHabilidad(habilidad){
        const url = process.env.VUE_APP_APIURL + `/habilidades/${habilidad.id_habilidad}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}