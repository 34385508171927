import axios from 'axios';

export default class LayoutService {

	getLayouts() {			
        const url = process.env.VUE_APP_APIURL + `layouts`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getLayout(layout) {
        const url = process.env.VUE_APP_APIURL + `/layouts/${layout.id_layout}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateLayout(layout){
        const url = process.env.VUE_APP_APIURL + `/layouts/${layout.id_layout}`;
		return axios.put(url,layout).then(res=>res.data.data);
	}

	createLayout(layout){
        const url = process.env.VUE_APP_APIURL + `/layouts`;
		return axios.post(url,layout).then(res=>res.data.data);
	}

	deleteLayout(layout){
        const url = process.env.VUE_APP_APIURL + `/layouts/${layout.id_layout}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}