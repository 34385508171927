import axios from 'axios';

export default class TypeEventService {

	getTipoEventos() {			
        const url = process.env.VUE_APP_APIURL + `tipoeventos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoEvento(tipoevento) {
        const url = process.env.VUE_APP_APIURL + `/tipoeventos/${tipoevento.id_tipo_evento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoEvento(tipoevento){
        const url = process.env.VUE_APP_APIURL + `/tipoeventos/${tipoevento.id_tipo_evento}`;
		return axios.put(url,tipoevento).then(res=>res.data.data);
	}

	createTipoEvento(tipoevento){
        const url = process.env.VUE_APP_APIURL + `/tipoeventos`;
		return axios.post(url,tipoevento).then(res=>res.data.data);
	}

	deleteTipoEvento(tipoevento){
        const url = process.env.VUE_APP_APIURL + `/tipoeventos/${tipoevento.id_tipo_evento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}