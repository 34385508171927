import axios from 'axios';

export default class ProcedureService {

	getProcedimientos() {			
        const url = process.env.VUE_APP_APIURL + `procedimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getProcedimiento(procedimiento) {
        const url = process.env.VUE_APP_APIURL + `/procedimientos/${procedimiento.id_procedimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateProcedimiento(procedimiento){
        const url = process.env.VUE_APP_APIURL + `/procedimientos/${procedimiento.id_procedimiento}`;
		return axios.put(url,procedimiento).then(res=>res.data.data);
	}

	createProcedimiento(procedimiento){
        const url = process.env.VUE_APP_APIURL + `/procedimientos`;
		return axios.post(url,procedimiento).then(res=>res.data.data);
	}

	deleteProcedimiento(procedimiento){
        const url = process.env.VUE_APP_APIURL + `/procedimientos/${procedimiento.id_procedimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}