import axios from "axios";

export default class ChecklistEvidenceService {
  getChecklistEvidenceByChecklist(listaverificacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `listaverificacionevidencias/listaverificacion`;
    return axios
      .get(url, { params: { id_lista_verificacion: listaverificacion } })
      .then((res) => res.data.data);
  }

  createChecklistEvidence(listaverificacionevidencia) {
    const url = process.env.VUE_APP_APIURL + `/listaverificacionevidencias`;
    return axios
      .post(url, listaverificacionevidencia)
      .then((res) => res.data.data);
  }
  updateChecklistEvidence(listaverificacionevidencia) {
    const url =
      process.env.VUE_APP_APIURL +
      `/listaverificacionevidencias/${listaverificacionevidencia.id_lista_verificacion_evidencia}`;
    return axios
      .put(url, listaverificacionevidencia)
      .then((res) => res.data.data);
  }
  deleteChecklistEvidence(listaverificacionevidencia) {
    const url =
      process.env.VUE_APP_APIURL +
      `/listaverificacionevidencias/${listaverificacionevidencia.id_lista_verificacion_evidencia}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
