
import {
  DxDataGrid, DxColumn, DxPaging, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxColumnChooser, DxLookup,DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import auth from '../auth';
import { DxToast } from 'devextreme-vue/toast';

import CompanyService from '../services/company-service';
import LogService from '../services/log-service';
import UserService from '../services/user-service';
import TypeLogService from '../services/type-log-service';


export default {
  components: {
    DxDataGrid,
    DxColumn,    
    DxPaging,
    DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxColumnChooser, DxLookup,
    DxToast, DxDateBox,
    DxGroupPanel,
    DxGrouping,
    DxSelectBox,
    DxButton
  },
  data() {
    const now = new Date();
    return {
      usuarioId: null,
      company: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      dataSourceCompanies: null,
      dataSourceCompany: null,
      dataSourceLogs: null,
      dataSourceTypeLogs: null,
      dataSourceUsers: null,
      logs: {},
      events: [],
      tipolog: null,
      usuarios: null,
      alertService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      mode: 'select',
      initialDate: null,
      finalDate: null,
      autoExpandAll: false,
    };
  },
  created() {
    this.companyService = new CompanyService();
    this.logService = new LogService();
    this.userService = new UserService();
    this.typeLogService = new TypeLogService();
  },
  mounted() {
    this.userService.getUsuarios().then(data => this.dataSourceUsers=data);
    this.typeLogService.getTipoLogs().then(data => this.dataSourceTypeLogs=data);
    auth.getUser().then((data) => {
      this.usuarioId = data.data.id_usuario;
      this.companyService.getEmpresas().then((data) => {
        this.dataSourceCompanies=data;
        this.dataSourceCompany=data; 
        this.company=data[0];
        this.logService.getLogsPorFiltro(this.company.id_empresa,this.startDate,this.endDate).then(data => this.dataSourceLogs=data);
      });
    });
  },
  methods: {
    onClick() {
      this.logService.getLogsPorFiltro(this.company.id_empresa,this.startDate,this.endDate).then(data => this.dataSourceLogs=data);
    },
  }
};
