
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxEditing,
    DxSorting,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';

import ChannelService from '../services/channel-service';
import ChannelActivityStatusService from '../services/channel-activity-status-service';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxSelection,
        DxToast,
        DxSelectBox,
        Button,
        DxEditing,
        DxSorting,
    },
    data() {
        return {
            channels: null,
            dataSourceChannel: null,
            dataSourceStatusAvailable: null,
            dataSourceStatusAssigned: null,
            dataSourceApplications: [{ "id_aplicacion": 1, "nombre": "WEB" }, { "id_aplicacion": 2, "nombre": "APP" }],
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            mode: 'select',
            selectedRowsData: [],
            i: 0,
        };
    },
    created() {
        this.channelService = new ChannelService();
        this.channelActivityStatusService = new ChannelActivityStatusService();
    },
    mounted() {
        this.channelService.getCanales().then(data => this.dataSourceChannel = data);
    },
    computed: {
        selectedRowKeys() {
            return this.selectedRowsData.map((data) => data.id_estatus_actividad);
        },
    },
    methods: {
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
        addRole() {
            for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
                this.channelActivityStatusService.createEstatusActividadCanal({ "id_canal": this.channels.id_canal, "id_estatus_actividad": this.selectedRowsData[this.i].id_estatus_actividad }).then(() => {

                    if (this.i == this.selectedRowsData.length) {
                        this.channelActivityStatusService.getEstatusActividadDisponibles(this.channels.id_canal).then(data => this.dataSourceStatusAvailable = data);
                        this.channelActivityStatusService.getEstatusActividadAsignados(this.channels.id_canal).then(data => this.dataSourceStatusAssigned = data);
                    }

                });
            }
        },
        onSelectionChanged({ selectedRowsData }) {
            this.selectedRowsData = selectedRowsData;
        },
        valueChangedItem(e) {
            this.channelActivityStatusService.getEstatusActividadDisponibles(e.value.id_canal).then(data => this.dataSourceStatusAvailable = data);
            this.channelActivityStatusService.getEstatusActividadAsignados(e.value.id_canal).then(data => this.dataSourceStatusAssigned = data);
        },
        deleteStatus(e) {
            this.channelActivityStatusService.deleteEstatusActividadCanal(e.data).then(() => {
                this.channelActivityStatusService.getEstatusActividadDisponibles(this.channels.id_canal).then(data => this.dataSourceStatusAvailable = data);
                this.channelActivityStatusService.getEstatusActividadAsignados(this.channels.id_canal).then(data => this.dataSourceStatusAssigned = data);
            });
        },
        editStatus(e) {
            this.channelActivityStatusService.updateEstatusActividadCanalOrden(e.data).then(() => {
                this.channelActivityStatusService.getEstatusActividadDisponibles(this.channels.id_canal).then(data => this.dataSourceStatusAvailable = data);
                this.channelActivityStatusService.getEstatusActividadAsignados(this.channels.id_canal).then(data => this.dataSourceStatusAssigned = data);
            });
        }
    },
};
