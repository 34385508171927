import axios from 'axios';

export default class StockService {

	getExistencias() {		
        const url = process.env.VUE_APP_APIURL + `existencias`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getExistenciasPorAlmacen(almacen) {		
        const url = process.env.VUE_APP_APIURL + `existencias/almacenes/${almacen}`;
		return axios.get(url).then(res=>res.data.data);
	}

}