import axios from 'axios';

export default class GroupPermitService {

	getGrupoPermisos() {	
        const url = process.env.VUE_APP_APIURL + `grupopermisos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoPermiso(grupopermiso) {
        const url = process.env.VUE_APP_APIURL + `/grupopermisos/${grupopermiso.id_grupo_permiso}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoPermiso(grupopermiso) {
        const url = process.env.VUE_APP_APIURL + `/grupopermisos/${grupopermiso.id_grupo_permiso}`;
		return axios.put(url,grupopermiso)
		.then(res=>res.data.data);
	}

	createGrupoPermiso(grupopermiso){
        const url = process.env.VUE_APP_APIURL + `/grupopermisos`;
		return axios.post(url,grupopermiso).then(res=>res.data.data);
	}

	deleteGrupoPermiso(grupopermiso){
        const url = process.env.VUE_APP_APIURL + `/grupopermisos/${grupopermiso.id_grupo_permiso}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}