import axios from 'axios';

export default class ConfigFrequencyService {

	getConfigFrecuencias() {			
        const url = process.env.VUE_APP_APIURL + `configfrecuencias`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigFrecuencia(configfrecuencia) {
        const url = process.env.VUE_APP_APIURL + `configfrecuencias/${configfrecuencia.id_frecuencia_config}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigFrecuencia(configfrecuencia){
        const url = process.env.VUE_APP_APIURL + `configfrecuencias/${configfrecuencia.id_frecuencia_config}`;
		return axios.put(url,configfrecuencia).then(res=>res.data.data);
	}

	createConfigFrecuencia(configfrecuencia){
        const url = process.env.VUE_APP_APIURL + `configfrecuencias`;
		return axios.post(url,configfrecuencia).then(res=>res.data.data);
	}

	deleteConfigFrecuencia(configfrecuencia){
        const url = process.env.VUE_APP_APIURL + `configfrecuencias/${configfrecuencia.id_frecuencia_config}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}