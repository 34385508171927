import axios from 'axios';

export default class MeasurementService {

	getMediciones() {			
        const url = process.env.VUE_APP_APIURL + `mediciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getMedicionesTipoFormula() {			
        const url = process.env.VUE_APP_APIURL + `mediciones/formulas`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getMedicionesNumericas() {			
        const url = process.env.VUE_APP_APIURL + `mediciones/numericas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMedicion(medicion) {
        const url = process.env.VUE_APP_APIURL + `/mediciones/${medicion.id_medicion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMedicion(medicion){
        const url = process.env.VUE_APP_APIURL + `/mediciones/${medicion.id_medicion}`;
		return axios.put(url,medicion).then(res=>res.data.data);
	}

	createMedicion(medicion){
        const url = process.env.VUE_APP_APIURL + `/mediciones`;
		return axios.post(url,medicion).then(res=>res.data.data);
	}

	deleteMedicion(medicion){
        const url = process.env.VUE_APP_APIURL + `/mediciones/${medicion.id_medicion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}