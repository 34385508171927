
import {
    DxTreeList,
    DxColumn,
    DxLookup,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxButton,
    DxColumnChooser
} from 'devextreme-vue/tree-list';
import { DxToast } from 'devextreme-vue/toast';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import {
    DxForm,
    DxSimpleItem,
    DxEmptyItem,
    DxLabel,
} from 'devextreme-vue/form';

import ActivityService from '../services/activity-service';
import UserService from '../services/user-service';
import PriorityService from '../services/priority-service';
import StatusActivityService from '../services/status-activity-service';
import UserChannelService from '../services/user-channel-service';
import ChannelService from '../services/channel-service';

export default {
    components: {
        DxTreeList,
        DxColumn,
        DxLookup,
        DxPaging,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxToolbar,
        DxItem,
        DxSelectBox,
        DxButton,
        DxColumnChooser,
        DxPopup,
        DxToolbarItem,
        DxForm,
        DxSimpleItem,
        DxEmptyItem,
        DxLabel,
        DxScrollView,
    },
    data() {
        return {
            dataSourceArchivedActivities: null,
            dataSourceArchivedActivitiesChannel: null,
            dataSourcePriorities: null,
            dataSourceAssignee: null,
            dataSourceStatus: null,
            isVisible: false,
            message: '',
            type: 'info',
            mode: 'select',
            channel: null,
            allowSearch: true,
            dataSourceChannels: null,
            refreshButtonOptions: {
                icon: 'refresh',
                onClick: () => {
                    this.refresh();
                },
            },
            popupVisible: false,
            closeButtonOptions: {
                text: 'Close',
                onClick: () => {
                    this.popupVisible = false;
                },
            },
            activityFormData: {},
            disabledEditorOptions: { disabled: true },
            idAsignado: null,
            idActividadPadre: null,
            idEstatus: null,
        }
    },
    created() {
        this.userChannelService = new UserChannelService();
        this.userService = new UserService();
        this.activityService = new ActivityService();
        this.priorityService = new PriorityService();
        this.statusActivityService = new StatusActivityService();
        this.channelService = new ChannelService();
    },
    mounted() {
        var usuarioId = localStorage.getItem("user-data");
        this.priorityService.getPrioridades().then(data => this.dataSourcePriorities = data);
        this.channelService.getCanales().then(data => this.dataSourceArchivedActivitiesChannel = data);
        this.userService.getUsuarios().then((data) => this.dataSourceAssignee = data);
        this.statusActivityService.getEstatusActividadesKanban().then((data) => this.dataSourceStatus = data);
        this.userChannelService.getUsuarioCanales(usuarioId).then((data) =>
            this.dataSourceChannels = data)
    },
    methods: {
        refresh() {
            if (this.channel != null)
                this.activityService.getActividadesArchivadosPorCanal(this.channel.id_canal).then(data => {
                    this.dataSourceParentActivity = data;
                    this.dataSourceArchivedActivities = data;
                });
        },
        restoreactivity(e) {
            this.activityService.restoreactivity(e.row.data).then(() => {
                this.refresh();
                this.type = 'success';
                this.message = 'Se ha restaurado correctamente';
                this.isVisible = true;
            }).catch(() => {
                this.refresh();
                this.type = 'success';
                this.message = 'Falla al restaurar';
                this.isVisible = true;
            });
        },
        deleteactivity(e) {
            this.activityService.deleteActividad(e.row.data).then(() => {
                this.refresh();
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
            }).catch(() => {
                this.refresh();
                this.type = 'error';
                this.message = 'Falla al crear el registro';
                this.isVisible = true;
            });
        },
        valueChangedItem() {
            this.activityService.getActividadesArchivadosPorCanal(this.channel.id_canal).then(data => {
                this.dataSourceParentActivity = data;
                this.dataSourceArchivedActivities = data;
            });
        },
        view(e) {
            this.popupVisible = true;

            this.activityFormData.id_actividad = e.row.data.id_actividad;
            this.activityFormData.id_canal = e.row.data.id_canal;
            this.activityFormData.folio = e.row.data.folio;
            this.activityFormData.titulo = e.row.data.titulo;
            this.activityFormData.id_prioridad = e.row.data.id_prioridad;
            this.activityFormData.fecha_inicio = e.row.data.fecha_inicio;
            this.activityFormData.fecha_fin = e.row.data.fecha_fin;
            this.activityFormData.avance = e.row.data.avance;
            this.activityFormData.id_usuario = e.row.data.id_usuario;
            this.activityFormData.id_usuario_asignado = e.row.data.id_usuario_asignado;
            this.activityFormData.id_actividad_padre = e.row.data.id_actividad_padre;
            this.activityFormData.referencia = e.row.data.referencia;
            this.activityFormData.descripcion = e.row.data.descripcion;
            this.activityFormData.id_estatus_actividad = e.row.data.id_estatus_actividad;
            this.activityFormData.fecha_reg = e.row.data.fecha_reg;
            this.activityFormData.orden = e.row.data.orden;
            this.activityFormData.archivado = e.row.data.archivado;
            this.activityFormData.fecha_archivado = e.row.data.fecha_archivado;
            this.activityFormData.fecha_restaurado = e.row.data.fecha_restaurado;
            this.activityFormData.avance_porcentaje = e.row.data.avance_porcentaje;

            this.idAsignado = e.row.data.id_usuario_asignado;
            this.idPrioridad = e.row.data.id_prioridad;
            this.idActividadPadre = e.row.data.id_actividad_padre;
            this.idEstatus = e.row.data.id_estatus_actividad;
            console.log(e.row.data);
        }
    },
}
