import axios from 'axios';

export default class ProjectService {

	getProyectos() {			
        const url = process.env.VUE_APP_APIURL + `proyectos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getProyecto(proyecto) {
        const url = process.env.VUE_APP_APIURL + `/proyectos/${proyecto.id_proyecto}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateProyecto(proyecto){
        const url = process.env.VUE_APP_APIURL + `/proyectos/${proyecto.id_proyecto}`;
		return axios.put(url,proyecto).then(res=>res.data.data);
	}

	createProyecto(proyecto){
        const url = process.env.VUE_APP_APIURL + `/proyectos`;
		return axios.post(url,proyecto).then(res=>res.data.data);
	}

	deleteProyecto(proyecto){
        const url = process.env.VUE_APP_APIURL + `/proyectos/${proyecto.id_proyecto}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}