import axios from 'axios';

export default class WarrantyRequestDetailService {

	getSolicitudGarantiaDetalles() {			
        const url = process.env.VUE_APP_APIURL + `solicitudgarantiadetalles`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getSolicitudGarantiaDetalle(solicitudgarantiadetalle) {
        const url = process.env.VUE_APP_APIURL + `/solicitudgarantiadetalles/${solicitudgarantiadetalle.id_solicitud_garantia_detalle}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateSolicitudGarantiaDetalle(solicitudgarantiadetalle){
        const url = process.env.VUE_APP_APIURL + `/solicitudgarantiadetalles/${solicitudgarantiadetalle.id_solicitud_garantia_detalle}`;
		return axios.put(url,solicitudgarantiadetalle).then(res=>res.data.data);
	}

	createSolicitudGarantiaDetalle(solicitudgarantiadetalle){
        const url = process.env.VUE_APP_APIURL + `/solicitudgarantiadetalles`;
		return axios.post(url,solicitudgarantiadetalle).then(res=>res.data.data);
	}

	deleteSolicitudGarantiaDetalle(solicitudgarantiadetalle){
        const url = process.env.VUE_APP_APIURL + `/solicitudgarantiadetalles/${solicitudgarantiadetalle.id_solicitud_garantia_detalle}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}