
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxValidationRule,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';

import ConfigSurveyDetailOpcionService from '../services/config-survey-detail-opcion-service';

export default {
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxFilterRow,
        DxScrolling,
        DxPager,
        DxToast,
        DxColumnChooser,
        DxSelection,
        DxSorting,
        DxValidationRule,
    },
    data() {
        const { id_config_encuesta_detalle } = this.templateData.data;
        return {
            dataSourceConfigSurveyDetailOpcion: null,
            idConfigEncuestaDetalle: id_config_encuesta_detalle,
        }
    },
    created() {
        this.configSurveyDetailOpcionService = new ConfigSurveyDetailOpcionService();
    },
    mounted() {
        this.refreshDataGrid();
    },
    methods: {
        refreshDataGrid() {
            this.configSurveyDetailOpcionService.getConfigSurveyDetailOpcionByIdConfigSurveyDetail(this.idConfigEncuestaDetalle).then(data => this.dataSourceConfigSurveyDetailOpcion = data);
        },
        onInitNewRow(e) {
            e.data.activo = true;
        },
        createConfigSurveyDetailOpcion(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.id_config_encuesta_detalle = this.idConfigEncuestaDetalle;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyDetailOpcionService.createConfigSurveyDetailOpcion(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha registrado correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        editWorkConfigSurveyDetailOpcion(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.id_config_encuesta_detalle = this.idConfigEncuestaDetalle;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyDetailOpcionService.updateConfigSurveyDetailOpcion(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha actualizado correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        deleteWorkConfigSurveyDetailOpcion(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.id_config_encuesta_detalle = this.idConfigEncuestaDetalle;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyDetailOpcionService.deleteConfigSurveyDetailOpcion(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha eliminar correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
    }

}
