
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import KitService from '../services/kit-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceKit: null,
      moneda: {},
      events: [],
      kitService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.kitService = new KitService();
  },
  mounted() {    
    this.kitService.getKits().then(data => this.dataSourceKit=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editKit(e) {
			this.kitService.updateKit(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.kitService.getKits().then(data => this.dataSourceKit=data);
      }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al actualizar el registro';
          this.isVisible = true;
          this.kitService.getKits().then(data => this.dataSourceKit=data);
      });
		},
    deleteKit(e) {
			this.kitService.deleteKit(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.kitService.getKits().then(data => this.dataSourceKit=data);
      }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al eliminar el registro';
          this.isVisible = true;
          this.kitService.getKits().then(data => this.dataSourceKit=data);
      });
		},
    createKit(e) {
			this.kitService.createKit(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.kitService.getKits().then(data => this.dataSourceKit=data);
      }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.kitService.getKits().then(data => this.dataSourceKit=data);
      });
		},
    onInitNewRow(e) {      
      e.data.activo = true;      
    },
  },
};
