
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';

import EquipmentCategoryService from '../services/equipment-category-service';
import EquipmentCategoryTaskService from '../services/equipment-category-task-service';
import EquipmentCategoryMeasurementService from '../services/equipment-category-measurement-service';
import MeasurementService from '../services/measurement-service';
import GroupMeasurementService from '../services/group-measurement-service';
import MeasurementFlowService from '../services/measurement-flow-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import TypeDataService from '../services/type-data-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSelectBox,
    DxSelection,
    DxSorting,   
  },
  data() {
    return {
      measurement: {},      
      dataSourceConfigMeasurements: null,
      dataSourceEquipmentCategories: null,
      dataSourceEquipmentCategoryTasks: null,
      dataSourceGroupMeasurements: null,      
      dataSourceMeasurementFlows: null,
      dataSourceMeasurements: null,
      dataSourceDataTypes: null,
      dataSourceUnitMeasurements: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Mediciones por tarea',
        showTitle: true,
        height: 500,
        width: 700,
      },      
      mode: 'select',
      equipmentcategory: null,
      equipmentcategorytask: null,
    };
  },
  created() {    
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.equipmentCategoryTaskService = new EquipmentCategoryTaskService();
    this.measurementService = new MeasurementService();
    this.measurementFlowService = new MeasurementFlowService();    
    this.groupMeasurementService = new GroupMeasurementService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.typeDataService = new TypeDataService();
    this.equipmentCategoryMeasurementService = new EquipmentCategoryMeasurementService();
  },
  mounted() {
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);
    this.measurementFlowService.getMedicionFlujoCombo().then(data => this.dataSourceMeasurementFlows=data);
    this.groupMeasurementService.getGrupoMediciones().then(data => this.dataSourceGroupMeasurements=data);
    this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
    this.typeDataService.getTipoDatos().then(data => this.dataSourceDataTypes=data);
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitMeasurements=data);
  },
  methods: {
    valueChangedEquipmentCategory(e) {
      this.equipmentCategoryTaskService.getEquipoCategoriaTareasPorCategoria(e.value.id_equipo_categoria).then(data => this.dataSourceEquipmentCategoryTasks=data);
      this.dataSourceConfigMeasurements = null;
    },
    valueChangedEquipmentCategoryTask(e) {
      this.equipmentCategoryMeasurementService.getEquipoCategoriaMedicionesPorTarea(e.value.id_equipo_categoria_tarea).then(data => this.dataSourceConfigMeasurements=data);        
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_equipo_categoria_tarea') {
        e.editorOptions.disabled = true;
      }
    },
    /*
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_grupo_tarea') {
        e.editorOptions.disabled = (typeof e.row.data.id_tarea !== 'number');
      }
    },
    setStateValue(rowData, value) {
      this.taskService.getTareasPorGrupo(value).then((data) => {
          this.dataSourceTasks=data;          
      });
      rowData.id_tarea = null;
      this.defaultSetCellValue(rowData, value);      
    },
    getFilteredTasks: (options) => ({
      filter: options.data ? ['id_grupo_tarea', '=', options.data.id_grupo_tarea] : null,
    }),
    */
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigMeasurement(e) {
        this.equipmentCategoryMeasurementService.updateEquipoCategoriaMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCategoryMeasurementService.getEquipoCategoriaMedicionesPorTarea(e.data.id_equipo_categoria_tarea).then(data => this.dataSourceConfigMeasurements=data);
      });
    },
    deleteConfigMeasurement(e) {
        this.equipmentCategoryMeasurementService.deleteEquipoCategoriaMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createConfigMeasurement(e) {
        this.equipmentCategoryMeasurementService.createEquipoCategoriaMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentCategoryMeasurementService.getEquipoCategoriaMedicionsPorTarea(e.data.id_equipo_categoria_tarea).then(data => this.dataSourceConfigMeasurements=data);
      });
    },
    onInitNewRow(e) {
      e.data.id_equipo_categoria_tarea= this.equipmentcategorytask.id_equipo_categoria_tarea;
      e.data.activo = true;
      e.data.orden = 1;
    },
    /*
    onToolbarPreparing(e) {
    e.toolbarOptions.items.forEach((item) => {
        if (item.name=="addRowButton") {
            item.options.onClick = function () {  
            if (this.equipmentcategorytask!=null)  
                e.component.addRow();  
            }; 
        }
        //console.log(item, index)
    });
    }
    */
  },
};
