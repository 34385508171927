import axios from 'axios';

export default class PurchaseOrderItemService {
  createOrdenCompraArticulo(ordencompra) {
    const url = process.env.VUE_APP_APIURL + `/ordencompraarticulos`;
    return axios.post(url, ordencompra).then((res) => res.data.data);
  }

  getOrdenCompraArticuloID(ordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordencompraarticulos/ordencompra`;
    return axios
      .get(url, { params: ordencompra })
      .then((res) => res.data.data);
  }

  updateOrdenCompraArticulo(ordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordencompraarticulos/${ordencompra.id_ordencompra_articulo}`;
    return axios.put(url, ordencompra).then((res) => res.data.data);
  }

  deleteOrdenCompraArticulo(ordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordencompraarticulos/${ordencompra.id_ordencompra_articulo}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getOrdenCompraArticuloPorUsuario(id_ordencompra) {
    const url =
      process.env.VUE_APP_APIURL + `ordencompraarticulos/ordencompra`;
    return axios
      .get(url, { params: { id_ordencompra: id_ordencompra } })
      .then((res) => res.data.data);
  }

}