
// :data="masterDetailData.data"
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import WorkOrderEquipmentTaskMeasurement from './workorder-equipment-task-measurement.vue';
import WorkOrderEquipmentTaskItem from './workorder-equipment-task-item.vue';
import WorkOrderEquipmentTaskService from './workorder-equipment-task-service.vue';

export default {
  components: {
    DxTabPanel, DxItem, WorkOrderEquipmentTaskMeasurement, WorkOrderEquipmentTaskItem, WorkOrderEquipmentTaskService
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
