
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxMasterDetail,
  DxSorting,
  DxValidationRule
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import ClientService from '../services/client-service';
import CountryService from '../services/country-service';
import DetailTemplate from './client-contact.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSorting,
    DxSelection,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule
  },
  data() {
    return {
      clientes: null,
      dataSourceClients: null,
      dataSourceCountries: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Cliente',
        showTitle: true,
        width: 800,
      },
    };
  },
  created() {
    this.clientService = new ClientService();
    this.countryService = new CountryService();
  },
  mounted() {
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.countryService.getPaises().then(data => this.dataSourceCountries = data);
  },
  methods: {
    getData(){
      this.clientService.getClientes().then(data => this.dataSourceClients = data);
    },
    sortClave(value1, value2) {
      // Handling null values
      if (!value1 && value2) return -1;
      if (!value1 && !value2) return 0;
      if (value1 && !value2) return 1;

      if ((!isNaN(parseInt(value1))) && (!isNaN(parseInt(value2))))
        return parseInt(value1) < parseInt(value2) ? -1 : 1;

      return value1.localeCompare(value2);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editClient(e) {
      this.clientService.updateCliente(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
        this.getData();
      });
    },
    deleteClient(e) {
      this.clientService.deleteCliente(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
        this.getData();
      });
    },
    createClient(e) {
      this.clientService.createCliente(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
        this.getData();
      });
    },
    onInitNewRow(e) {
      e.data.activo = true;
    },
  },
};
