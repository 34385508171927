

import { DxDataGrid, DxColumn, DxEditing, DxValidationRule } from 'devextreme-vue/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import SupplierContactService from '../services/supplier-contact-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxValidationRule },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { nombre_comercial } = this.templateData.data;
    return {
      details: null,
      dataSourceSupplierContacts: null,
      detailInfo: `${nombre_comercial}`,
    };
  },
  created() {
    this.supplierContactService = new SupplierContactService();      
  },
  mounted() {    
    this.supplierContactService.getProveedorContactos().then((data) => {
        this.details=data;         
        this.dataSourceSupplierContacts = this.getDetails(this.templateData.key); 
    });             
  },
  methods: {
    editSupplierContact(e) {
        this.supplierContactService.updateProveedorContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    deleteSupplierContact(e) {
		this.supplierContactService.deleteProveedorContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    createSupplierContact(e) {
        e.data.id_proveedor = this.templateData.key;
		this.supplierContactService.createProveedorContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    refreshData() {
        this.supplierContactService.getProveedorContactos().then((data) => {
        this.details=data;         
        this.dataSourceSupplierContacts = this.getDetails(this.templateData.key); 
    });
    },           
    getDetails(key) {            
      return new DataSource({
        store: new ArrayStore({
          data: this.details,
          key: 'id_proveedor_contacto',
        }),
        filter: ['id_proveedor', '=', key],
      });
    },
  },
};
