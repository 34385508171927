
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';

import EquipmentCategoryService from '../services/equipment-category-service';
import EquipmentCategoryTaskService from '../services/equipment-category-task-service';
import EquipmentCategoryMeasurementService from '../services/equipment-category-measurement-service';
import EquipmentCategoryMeasurementControlService from '../services/equipment-category-measurement-control-service';

import TypeControlService from '../services/type-control-service';
import ControlSizeService from '../services/control-size-service';
import MeasurementOptionService from '../services/measurement-option-service';
import MeasurementOptionDetailService from '../services/measurement-option-detail-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSelectBox,
    DxSelection,
    DxSorting,   
  },
  data() {
    return {
      measurement: {},
      measurementoption: null,      
      dataSourceConfigControls: null,
      dataSourceEquipmentCategories: null,
      dataSourceEquipmentCategoryTasks: null,
      dataSourceEquipmentCategoryTaskMeasurements: null,
      dataSourceControlTypes: null,      
      dataSourceControlSizes: null,
      dataSourceMeasurementOptions: null,
      dataSourceMeasurementOptionDetails: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Controles por medición',
        showTitle: true,
        height: 670,
        width: 900,
      },      
      mode: 'select',
      equipmentcategory: null,
      equipmentcategorytask: null,
      equipmentcategorytaskmeasurement: null,
    };
  },
  created() {    
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.equipmentCategoryTaskService = new EquipmentCategoryTaskService();
    this.equipmentCategoryMeasurementService = new EquipmentCategoryMeasurementService();
    this.typeControlService = new TypeControlService();
    this.controlSizeService = new ControlSizeService();
    this.measurementOptionService = new MeasurementOptionService();    
    this.measurementOptionDetailService = new MeasurementOptionDetailService();
    this.equipmentCategoryMeasurementControlService = new EquipmentCategoryMeasurementControlService();
  },
  mounted() {
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);
    this.typeControlService.getTipoControles().then(data => this.dataSourceControlTypes=data);
    this.controlSizeService.getTamanoControles().then(data => this.dataSourceControlSizes=data);
    this.measurementOptionService.getMedicionOpciones().then(data => this.dataSourceMeasurementOptions=data);
    this.measurementOptionDetailService.getMedicionOpcionDetalles().then(data => this.dataSourceMeasurementOptionDetails=data);
  },
  methods: {
    valueChangedEquipmentCategory(e) {
      this.equipmentCategoryTaskService.getEquipoCategoriaTareasPorCategoria(e.value.id_equipo_categoria).then(data => this.dataSourceEquipmentCategoryTasks=data);
      this.dataSourceConfigControls = null;
    },
    valueChangedEquipmentCategoryTask(e) {
      this.equipmentCategoryMeasurementService.getEquipoCategoriaMedicionesPorTarea(e.value.id_equipo_categoria_tarea).then(data => this.dataSourceEquipmentCategoryTaskMeasurements=data);
      this.dataSourceConfigControls = null;
    },
    valueChangedEquipmentCategoryTaskMeasurement(e) {
      this.equipmentCategoryMeasurementControlService.getEquipoCategoriaMedicionControlesPorMedicion(e.value.id_equipo_categoria_tarea_medicion).then(data => this.dataSourceConfigControls=data);        
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_equipo_categoria_tarea_medicion') {
        e.editorOptions.disabled = true;
      }
    },
    /*
    setStateValue(rowData, value) {
      this.measurementOptionDetailService.getMedicionOpcionDetallesPorOpcion(value).then(data => this.dataSourceMeasurementOptionDetails=data);
      rowData.id_medicion_opcion_detalle = null;
      this.defaultSetCellValue(rowData, value);      
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_grupo_tarea') {
        e.editorOptions.disabled = (typeof e.row.data.id_tarea !== 'number');
      }
    },
    setStateValue(rowData, value) {
      this.taskService.getTareasPorGrupo(value).then((data) => {
          this.dataSourceTasks=data;          
      });
      rowData.id_tarea = null;
      this.defaultSetCellValue(rowData, value);      
    },
    getFilteredTasks: (options) => ({
      filter: options.data ? ['id_grupo_tarea', '=', options.data.id_grupo_tarea] : null,
    }),
    */
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigControl(e) {
        
        if (e.data.valor_minimo=='')
            e.data.valor_minimo = null;
        if (e.data.valor_maximo=='')
            e.data.valor_maximo = null;
        if (e.data.divisor_mayor=='')
            e.data.divisor_mayor = null;
        if (e.data.divisor_menor=='')
            e.data.divisor_menor = null;
        if (e.data.valor_predeterminado_numerico=='')
            e.data.valor_predeterminado_numerico = null;
        if (e.data.valor_aceptado_min=='')
            e.data.valor_aceptado_min = null;
        if (e.data.valor_aceptado_max=='')
            e.data.valor_aceptado_max = null;
        if (e.data.valor_aceptado_unico=='')
            e.data.valor_aceptado_unico = null;
        this.equipmentCategoryMeasurementControlService.updateEquipoCategoriaMedicionControl(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCategoryMeasurementControlService.getEquipoCategoriaMedicionControlesPorMedicion(e.data.id_equipo_categoria_tarea_medicion).then(data => this.dataSourceConfigControls=data);
      });
    },
    deleteConfigControl(e) {
        this.equipmentCategoryMeasurementControlService.deleteEquipoCategoriaMedicionControl(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createConfigControl(e) {
        if (e.data.valor_minimo=='')
            e.data.valor_minimo = null;
        if (e.data.valor_maximo=='')
            e.data.valor_maximo = null;
        if (e.data.divisor_mayor=='')
            e.data.divisor_mayor = null;
        if (e.data.divisor_menor=='')
            e.data.divisor_menor = null;
        if (e.data.valor_predeterminado_numerico=='')
            e.data.valor_predeterminado_numerico = null;
        if (e.data.valor_aceptado_min=='')
            e.data.valor_aceptado_min = null;
        if (e.data.valor_aceptado_max=='')
            e.data.valor_aceptado_max = null;
        if (e.data.valor_aceptado_unico=='')
            e.data.valor_aceptado_unico = null;
        this.equipmentCategoryMeasurementControlService.createEquipoCategoriaMedicionControl(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentCategoryMeasurementControlService.getEquipoCategoriaMedicionControlesPorMedicion(e.data.id_equipo_categoria_tarea_medicion).then(data => this.dataSourceConfigControls=data);
      });
    },
    onInitNewRow(e) {
      e.data.id_equipo_categoria_tarea_medicion= this.equipmentcategorytaskmeasurement.id_equipo_categoria_tarea_medicion;
      e.data.predeterminado = 1;
    },
    /*
    onToolbarPreparing(e) {
    e.toolbarOptions.items.forEach((item) => {
        if (item.name=="addRowButton") {
            item.options.onClick = function () {  
            if (this.equipmentcategorytask!=null)  
                e.component.addRow();  
            }; 
        }
        //console.log(item, index)
    });
    }
    */
  },
};
