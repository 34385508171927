import axios from "axios";

export default class SurveyService {
  getSurveyToken(token) {
    const url = process.env.VUE_APP_APIURL + `encuesta`;
    return axios
      .get(url, {
        params: { token: token },
      })
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  createSurvey(encuesta) {
    const url = process.env.VUE_APP_APIURL + `/encuesta`;
    return axios
      .post(url, encuesta)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  SendSurvey(encuesta) {
    const url = process.env.VUE_APP_APIURL + `/encuesta/enviar`;
    return axios
      .post(url, encuesta)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
