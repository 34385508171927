

import {DxTabPanel, DxItem as DxItemTab } from 'devextreme-vue/tab-panel';
import DxButton from 'devextreme-vue/button';
import notify from 'devextreme/ui/notify';
import { DxBox, DxItem } from 'devextreme-vue/box';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxTreeList, DxColumn, DxFilterRow, DxSelection, DxScrolling, DxPaging, DxPager } from 'devextreme-vue/tree-list';

import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';

import { monitorareas, sistemas } from '../data/data.js';

import MonitorCtrl from './monitor-ctrl.vue';
import MonitorChart from './monitor-chart.vue';
import MonitorAlert from './monitor-alert.vue';
import MonitorAnalytic from './monitor-analytic.vue';


export default {
  components: {
    DxButton,
    DxBox,
    DxItem,
    DxTreeList,
    DxColumn,
    DxFilterRow,
    DxSelection,
    DxPaging,
    DxScrolling,
    DxPager,
    DxSelectBox,
    DxTabPanel,
    DxItemTab,
    MonitorCtrl,
    MonitorChart,
    MonitorAlert,
    MonitorAnalytic,
  },
  data() {
      return {
          selectedIndex: 0,
          loop: true,
          animationEnabled: true,
          swipeEnabled: false,
          showImage: true,
          showLink: false,
          showTable: false,
          showButton: false,
          valTempZona: "26",
          valTempAgua: "17",
          valEstadoEquipo: "NORMAL",
          lblTempZona: "Temperatura de Zona",
          lblTempAgua: "Temperatura de Agua",
          lblEstadoEquipo: "Condición del Equipo",
          valValvula: "10",
          valInyeccion: "22",
          lblInyecccion: "Inyección",
          valRetorno: "25",
          lblRetorno: "Retorno",
          lblFiltro: "Filtro",
          imageSrc: "../assets/diagrama.png",
          screen(width) {
            return (width < 700) ? 'sm' : 'lg';
          },
          dataSourceMonitorAreas: monitorareas,
          selectedMonitorArea: undefined,
          allowedPageSizes: [5, 10, 20],
          dataSourceSystems: sistemas,
          dataSourceClients: null,
          dataSourceCompanies: null
      };
  },
  created() {
      this.imageSrc = "../assets/diagrama.png";
      this.companyService = new CompanyService();
      this.clientService = new ClientService();
      //this.$refs.TEMPEXT = this.tempexterior; //Math.round(parseFloat(rootElem.childNodes.item(28).text)) + " PSI"
  },
  mounted() {
      this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
      this.clientService.getClientes().then(data => this.dataSourceClients=data);
  },
  methods: {
    selectMonitorArea(e) {
        e.component.byKey(e.currentSelectedRowKeys[0]).done(monitorarea => {
            if(monitorarea) {
                this.selectedMonitorArea = monitorarea;
            }
        });
    },
    onClick(e) {
      const buttonText = e.component.option('text');
      notify(`The ${this.capitalize(buttonText)} button was clicked`);
    },
    toggleImage() {
        this.showImage = !this.showImage;
    },
    logEvent(eventName) {
        this.events.unshift(eventName);
    },
  },
}
