import axios from 'axios';

export default class UserChannelService {

	getUsuarioCanales(usuario) {			
        const url = process.env.VUE_APP_APIURL + `usuariocanales/${usuario}`;	
		return axios.get(url).then(res=>res.data.data);
	}

	createUsuarioCanal(usuariocanal){
        const url = process.env.VUE_APP_APIURL + `usuariocanales`;
		return axios.post(url,usuariocanal).then(res=>res.data.data);
	}

	deleteUsuarioCanal(usuario,canal){
        const url = process.env.VUE_APP_APIURL + `usuariocanales`;
		return axios.delete(url,{ params: { id_usuario: usuario, id_canal: canal} }).then(res=>res.data.data);
	}
}