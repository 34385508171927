import axios from "axios";

export default class WorkCalendarService {
  getCalendarioLaborales() {
    const url = process.env.VUE_APP_APIURL + `calendariolaborales`;
    return axios.get(url).then((res) => res.data.data);
  }

  getCalendarioLaboral(calendariolaboral) {
    const url =
      process.env.VUE_APP_APIURL +
      `/calendariolaborales/${calendariolaboral.id_calendario_laboral}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateCalendarioLaboral(calendariolaboral) {
    const url =
      process.env.VUE_APP_APIURL +
      `/calendariolaborales/${calendariolaboral.id_calendario_laboral}`;
    return axios
      .put(url, calendariolaboral)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createCalendarioLaboral(calendariolaboral) {
    const url = process.env.VUE_APP_APIURL + `/calendariolaborales`;
    return axios
      .post(url, calendariolaboral)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteCalendarioLaboral(calendariolaboral) {
    const url =
      process.env.VUE_APP_APIURL +
      `/calendariolaborales/${calendariolaboral.id_calendario_laboral}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
