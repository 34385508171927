
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import EquipmentCategoryTaskService from '../services/equipment-category-task-service';
import EquipmentCategoryService from '../services/equipment-category-service';
import GroupTaskService from '../services/group-task-service';
import TaskService from '../services/task-service';
import ConfigFrequencyService from '../services/config-frequency-service';
import UnitOperationService from '../services/unit-operation-service';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSelectBox,
    DxSelection,
    DxSorting,   
  },
  data() {
    return {
      group: {},
      clientes: null,
      dataSourceConfigTasks: null,
      dataSourceEquipmentCategories: null,
      dataSourceGroupTasks: null,
      dataSourceTasks: null,      
      dataSourceConfigFrequencies: null,
      dataSourceUnitOperation: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Categoría del Equipo & Tarea',
        showTitle: true,
        height: 550,
        width: 900,
      },      
      mode: 'select',
      equipmentcategory: null,
    };
  },
  created() {
    this.equipmentCategoryTaskService = new EquipmentCategoryTaskService();
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.groupTaskService = new GroupTaskService();
    this.taskService = new TaskService();
    this.configFrequencyService = new ConfigFrequencyService();
    this.unitOperationService = new UnitOperationService();
  },
  mounted() {    
    this.taskService.getTareasConGrupos().then(data => this.dataSourceTasks=data);
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);
    this.groupTaskService.getGrupoTareas().then(data => this.dataSourceGroupTasks=data);
    this.configFrequencyService.getConfigFrecuencias().then(data => this.dataSourceConfigFrequencies=data);
    this.unitOperationService.getUnidadOperaciones().then(data => this.dataSourceUnitOperation=data);
  },
  methods: {
    
    valueChangedEquipmentCategory(e) {
      this.equipmentCategoryTaskService.getEquipoCategoriaTareasPorCategoria(e.value.id_equipo_categoria).then(data => this.dataSourceConfigTasks=data);        
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_equipo_categoria') {
        e.editorOptions.disabled = true;
      }
    },
    /*
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_grupo_tarea') {
        e.editorOptions.disabled = (typeof e.row.data.id_tarea !== 'number');
      }
    },
    setStateValue(rowData, value) {
      this.taskService.getTareasPorGrupo(value).then((data) => {
          this.dataSourceTasks=data;          
      });
      rowData.id_tarea = null;
      this.defaultSetCellValue(rowData, value);      
    },
    getFilteredTasks: (options) => ({
      filter: options.data ? ['id_grupo_tarea', '=', options.data.id_grupo_tarea] : null,
    }),
    */
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigTask(e) {
        this.equipmentCategoryTaskService.updateEquipoCategoriaTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCategoryTaskService.getEquipoCategoriaTareasPorCategoria(e.data.id_equipo_categoria).then(data => this.dataSourceConfigTasks=data);
      });
    },
    deleteConfigTask(e) {
        this.equipmentCategoryTaskService.deleteEquipoCategoriaTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createConfigTask(e) {
        this.equipmentCategoryTaskService.createEquipoCategoriaTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentCategoryTaskService.getEquipoCategoriaTareasPorCategoria(e.data.id_equipo_categoria).then(data => this.dataSourceConfigTasks=data);
      });
    },    
    onInitNewRow(e) {      
      e.data.activo = true;
      e.data.id_equipo_categoria = this.equipmentcategory.id_equipo_categoria;
    },
  },
};
