import axios from 'axios';

export default class TypeMaintenanceService {

	getTipoMantenimientos() {			
        const url = process.env.VUE_APP_APIURL + `tipomantenimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getCalendarioTipoMantenimientos() {			
        const url = process.env.VUE_APP_APIURL + `tipomantenimientos/calendarios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoMantenimiento(tipomantenimiento) {
        const url = process.env.VUE_APP_APIURL + `/tipomantenimientos/${tipomantenimiento.id_tipo_mantenimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoMantenimiento(tipomantenimiento){
        const url = process.env.VUE_APP_APIURL + `/tipomantenimientos/${tipomantenimiento.id_tipo_mantenimiento}`;
		return axios.put(url,tipomantenimiento).then(res=>res.data.data);
	}

	createTipoMantenimiento(tipomantenimiento){
        const url = process.env.VUE_APP_APIURL + `/tipomantenimientos`;
		return axios.post(url,tipomantenimiento).then(res=>res.data.data);
	}

	deleteTipoMantenimiento(tipomantenimiento){
        const url = process.env.VUE_APP_APIURL + `/tipomantenimientos/${tipomantenimiento.id_tipo_mantenimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}