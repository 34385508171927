import axios from "axios";

export default class SurveyControlTypeService {
  getSurveyControlType() {
    const url = process.env.VUE_APP_APIURL + `tipocontrolencuestas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getSurveyControlTypeById(tipocontrolencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/tipocontrolencuestas/${tipocontrolencuesta.id_tipo_control_encuesta}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateSurveyControlType(tipocontrolencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/tipocontrolencuestas/${tipocontrolencuesta.id_tipo_control_encuesta}`;
    return axios.put(url, tipocontrolencuesta).then((res) => res.data.data);
  }

  createSurveyControlType(tipocontrolencuesta) {
    const url = process.env.VUE_APP_APIURL + `/tipocontrolencuestas`;
    return axios.post(url, tipocontrolencuesta).then((res) => res.data.data);
  }

  deleteSurveyControlType(tipocontrolencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/tipocontrolencuestas/${tipocontrolencuesta.id_tipo_control_encuesta}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
