import axios from 'axios';

export default class ConfigEmailService {

	getConfigCorreos() {		
        const url = process.env.VUE_APP_APIURL + `configcorreos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigCorreo(configcorreo) {
        const url = process.env.VUE_APP_APIURL + `/configcorreos/${configcorreo.id_config_correo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigCorreo(configcorreo){
        const url = process.env.VUE_APP_APIURL + `/configcorreos/${configcorreo.id_config_correo}`;
		return axios.put(url,configcorreo).then(res=>res.data.data);
	}

	createConfigCorreo(configcorreo){
        const url = process.env.VUE_APP_APIURL + `/configcorreos`;
		return axios.post(url,configcorreo).then(res=>res.data.data);
	}

	deleteConfigCorreo(configcorreo){
        const url = process.env.VUE_APP_APIURL + `/configcorreos/${configcorreo.id_config_correo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}