import axios from "axios";

export default class ItemRackService {
  getArticuloEstantes() {
    const url = process.env.VUE_APP_APIURL + `articuloestantes`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getArticuloEstante(articuloestante) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloestantes/${articuloestante.id_articulo_estante}`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  updateArticuloEstante(articuloestante) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloestantes/${articuloestante.id_articulo_estante}`;
    return axios
      .put(url, articuloestante)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createArticuloEstante(articuloestante) {
    const url = process.env.VUE_APP_APIURL + `/articuloestantes`;
    return axios
      .post(url, articuloestante)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteArticuloEstante(articuloestante) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloestantes/${articuloestante.id_articulo_estante}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
