import axios from 'axios';

export default class ConfigPlanMaintenanceEquipmentService {

    getEquiposDisponiblesPorPlan(planId) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipos/disponibles/${planId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquiposAsignadosPorPlan(planId) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipos/asignados/${planId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getConfigPlanMttoEquipos() {			
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigPlanMttoEquipo(configplanmttoequipo) {
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipos/${configplanmttoequipo.id_config_plan_mtto_equipo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createConfigPlanMttoEquipo(configplanmttoequipo){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipos`;
		return axios.post(url,configplanmttoequipo).then(res=>res.data.data);
	}

	updateConfigPlanMttoEquipo(configplanmttoequipo){
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipos/${configplanmttoequipo.id_config_plan_mtto_equipo}`;
		return axios.put(url,configplanmttoequipo).then(res=>res.data.data);
	}

	deleteConfigPlanMttoEquipo(configplanmttoequipo){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipos/${configplanmttoequipo.id_config_plan_mtto_equipo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}

