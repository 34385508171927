

var dataSourcePurchaseOrderItems = [];
var dataSourcePurchaseOrderItems1 = [];
var dataSourceItem1 = [];
var dataSourceUnitOfMeasurement1 = [];

var dataSourcePurchaseOrderServices = [];
var dataSourcePurchaseOrderServices1 = [];
var dataSourceService1 = [];

import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';

import PurchaseOrderService from '../services/purchase-order-service';
import StatusPurchaseOrderService from '../services/status-po-service';
import SupplierService from '../services/supplier-service';
import CompanyService from '../services/company-service';
import UserService from '../services/user-service';
import WarehouseService from '../services/warehouse-service';
import TypePurchaseOrderService from '../services/type-purchase-order-service';
import AddressCompanyService from '../services/address-company-service';
import LegalCompanyService from '../services/legal-company-service';
import DepartmentService from '../services/department-service';
import CurrencyService from '../services/currency-service';
import TypeItemService from '../services/type-item-service'
import ItemService from '../services/item-service';
import ServiceService from '../services/service-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import PurchaseOrderItemService from '../services/purchase-order-item-service';
import PurchaseOrderServiceService from '../services/purchase-order-service-service';

import DetailTemplate from './purchase-order-detail.vue';
import auth from '../auth';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxTabbedItem,
    DxTab
  },
  data() {
    return {
      usuarioId: null,
      ordendecompra: null,
      detalleordencompra: null,
      dataSourcePurchaseOrder: null,
      dataSourcePurchaseOrderTypes: null,
      dataSourceStatus: null,
      dataSourceUsers: null,
      dataSourceCompanies: null,
      dataSourceWarehouses: null,
      dataSourceSuppliers: null,
      dataSourceAddresses: null,
      dataSourceLegalCompanies: null,
      dataSourceDepartments: null,
      dataSourceCurrencies: null,
      dataSourcePurchaseOrderItems,
      dataSourceUnitOfMeasurement: null,
      dataSourceTypeItem: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Orden de Compra',
        showTitle: true,
        width: 900,
      },
      popupItemOptions: {
        title: 'Artículos',
        showTitle: true,
        width: 800,
        height: 350,
      },
      popupServiceOptions: {
        title: 'Servicios',
        showTitle: true,
        width: 800,
        height: 350,
      },
      mode: 'select',
      dataSourcePurchaseOrderServices,
      dataSourcePurchaseOrderServices1,
      dataSourcePurchaseOrderItems1,
      dataSourceItem1,
      dataSourceService1,
      dataSourceUnitOfMeasurement1,
      dataSourceItem: null,
      setStateValue(rowData, value) {
        rowData.id_empresa = null;
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueArticulo(rowData, value) {
        var resultado = dataSourceItem1.filter((element) => element.id_articulo === value);
        if (resultado.length > 0) {
          rowData.id_unidad_medida = resultado[0].id_unidad_medida;
          rowData.articulo = resultado[0].articulo;
        }
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueServicio(rowData, value) {
        var resultado = dataSourceService1.filter((element) => element.id_servicio === value);
        if (resultado.length > 0) {
          rowData.id_unidad_medida = resultado[0].id_unidad_medida;
          rowData.servicio = resultado[0].servicio;
        }
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueTipoArticulo(rowData, value) {
        rowData.id_articulo = null;
        this.defaultSetCellValue(rowData, value);
      },
    };
  },
  created() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
    this.purchaseOrderService = new PurchaseOrderService();
    this.statusPurchaseOrderService = new StatusPurchaseOrderService();
    this.typePurchaseOrderService = new TypePurchaseOrderService();
    this.supplierService = new SupplierService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.addressCompanyService = new AddressCompanyService();
    this.legalCompanyService = new LegalCompanyService();
    this.departmentService = new DepartmentService();
    this.currencyService = new CurrencyService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.itemService = new ItemService();
    this.serviceService = new ServiceService();
    this.purchaseOrderItemService = new PurchaseOrderItemService();
    this.purchaseOrderServiceService = new PurchaseOrderServiceService();
    this.typeItemService = new TypeItemService();
  },
  mounted() {
    auth.getUser().then(data => this.usuarioId = data.data.id_usuario);
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers=data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.statusPurchaseOrderService.getEstatusOrdenCompras().then(data => this.dataSourceStatus=data);
    this.typePurchaseOrderService.getTipoOrdenCompras().then(data => this.dataSourcePurchaseOrderTypes=data);
    this.departmentService.getDepartamentos().then(data => this.dataSourceDepartments=data);
    this.currencyService.getMonedas().then(data => this.dataSourceCurrencies=data);
    // Ponerlo por empresa. Quitar hardcode
    this.warehouseService.getAlmacenesPorEmpresa(1).then(data => this.dataSourceWarehouses=data);
    this.typeItemService.getTipoArticulosComboRequisiciones().then(data => this.dataSourceTypeItem = data);
    this.purchaseOrderService.getOrdenComprasPorEmpresa(1).then(data => this.dataSourcePurchaseOrder=data);
    this.addressCompanyService.getDireccionesPorEmpresa(1).then(data => this.dataSourceAddresses=data);
    this.legalCompanyService.getRazonSocialesPorEmpresa(1).then(data => this.dataSourceLegalCompanies=data);

    this.itemService.getArticuloComboActivos().then(data => {
      dataSourceItem1 = data;
      this.dataSourceItem = data;
    });
    this.serviceService.getServicioComboActivos().then(data => {
      dataSourceService1 = data;
      this.dataSourceService = data;
    });
    this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
      dataSourceUnitOfMeasurement1 = data;
      this.dataSourceUnitOfMeasurement = data;
    });

  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editPurchaseOrder(e) {
      this.purchaseOrderService.updateOrdenCompra(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.purchaseOrderService.getOrdenComprasPorEmpresa(1).then(data => this.dataSourcePurchaseOrder=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.purchaseOrderService.getOrdenComprasPorEmpresa(1).then(data => this.dataSourcePurchaseOrder=data);
      });
		},
    deletePurchaseOrder(e) {
			this.purchaseOrderService.deleteOrdenCompra(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.purchaseOrderService.getOrdenComprasPorEmpresa(1).then(data => this.dataSourcePurchaseOrder=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.purchaseOrderService.getOrdenComprasPorEmpresa(1).then(data => this.dataSourcePurchaseOrder=data);
      });
		},
    createPurchaseOrder(e) {
			this.purchaseOrderService.createOrdenCompra({"comentario": e.data.comentario, "fecha": e.data.fecha,
      "id_almacen": e.data.id_almacen, "id_departamento": e.data.id_departamento, "id_empresa": e.data.id_empresa, "id_empresa_razon_social":e.data.id_empresa_razon_social,
    "id_estatus_orden_compra":e.data.id_estatus_orden_compra,"id_proveedor":e.data.id_proveedor,"id_tipo_orden_compra":e.data.id_tipo_orden_compra,"id_usuario":this.usuarioId}).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.purchaseOrderService.getOrdenComprasPorEmpresa(1).then(data => this.dataSourcePurchaseOrder=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.purchaseOrderService.getOrdenComprasPorEmpresa(1).then(data => this.dataSourcePurchaseOrder=data);
      });
		},    
    onInitNewRow(e) {
      e.data.id_estatus_orden_compra = 1;
    },

    createPurchaseOrderItems(e) {
      this.dataSourdeItems = this.dataSourcePurchaseOrderItems;
      e.data.id_orden_compra = this.id_orden_compra;
      if (this.id_orden_compra != null) {
        this.purchaseOrderItemService.createOrdenCompraArticulo(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.purchaseOrderItemService.getOrdenCompraArticuloID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.dataSourcePurchaseOrderItems = [];
          this.purchaseOrderItemService.getOrdenCompraArticuloID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderItems = data);
        });
      }
    },
    editPurchaseOrderItems(e) {
      e.data.id_orden_compra = this.id_orden_compra;
      if (this.id_orden_compra != null) {
        var dataJson = {
          id_orden_compra: this.id_orden_compra,
          id_orden_compra_articulo: e.data.id_orden_compra_articulo,
          id_articulo: e.data.id_articulo,
          cantidad: e.data.cantidad,
          precio_unitario: e.data.precio_unitario,
          descripcion: e.data.descripcion,
          id_moneda: e.data.id_moneda,
        };
        this.purchaseOrderItemService.updateOrdenCompraArticulo(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.purchaseOrderItemService.getOrdenCompraArticuloID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.purchaseOrderItemService.getOrdenCompraArticuloID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderItems = data);
        });
      }
    },
    deletePurchaseOrderItems(e) {
      e.data.id_orden_compra = this.id_orden_compra;
      if (this.id_orden_compra != null) {
        var dataJson = {
          id_orden_compra_articulo: e.data.id_orden_compra_articulo,
        };
        this.purchaseOrderItemService.deleteOrdenCompraArticulo(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.purchaseOrderItemService.getOrdenCompraArticuloID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.purchaseOrderItemService.getOrdenCompraArticuloID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderItems = data);
        });
      }
    },
    getFilteredArticulo: (options) => (
      {
        store: dataSourceItem1,
        filter: options.data ? ['id_tipo_articulo', '=', options.data.id_tipo_articulo] : null,
      }),
    onInitNewRowPurchaseOrderItems(e) {
      e.data.cantidad = 1;
      e.data.id_tipo_articulo = 1;
    },

    createPurchaseOrderServices(e) {
      this.dataSourdeServices = this.dataSourcePurchaseOrderServices;
      e.data.id_orden_compra = this.id_orden_compra;
      if (this.id_orden_compra != null) {
        this.purchaseOrderServiceService.createOrdenCompraServicio(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.purchaseOrderServiceService.getOrdenCompraServicioID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderServices = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.dataSourcePurchaseOrderServices = [];
          this.purchaseOrderServiceService.getOrdenCompraServicioID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderServices = data);
        });
      }
    },
    editPurchaseOrderServices(e) {
      e.data.id_orden_compra = this.id_orden_compra;
      if (this.id_orden_compra != null) {
        var dataJson = {
          id_orden_compra: this.id_orden_compra,
          id_orden_compra_servicio: e.data.id_orden_compra_servicio,
          id_servicio: e.data.id_servicio,
          cantidad: e.data.cantidad,
          servicio: e.data.descripcion,
          precio_unitario: e.data.precio_unitario,
          id_moneda: e.data.id_moneda,
        };
        this.purchaseOrderServiceService.updateOrdenCompraServicio(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.purchaseOrderServiceService.getOrdenCompraServicioID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderServices = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.purchaseOrderServiceService.getOrdenCompraServicioID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderServices = data);
        });
      }
    },
    deletePurchaseOrderServices(e) {
      e.data.id_orden_compra = this.id_orden_compra;
      if (this.id_orden_compra != null) {
        var dataJson = {
          id_orden_compra_servicio: e.data.id_orden_compra_servicio,
        };
        this.purchaseOrderServiceService.deleteOrdenCompraServicio(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.purchaseOrderServiceService.getOrdenCompraServicioID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderServices = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.purchaseOrderServiceService.getOrdenCompraServicioID({ id_orden_compra: this.id_orden_compra }).then(data => this.dataSourcePurchaseOrderServices = data);
        });
      }
    },
    getFilteredServicio: () => (
    {
      store: dataSourceService1
    }),
    onInitNewRowPurchaseOrderServices(e) {
      e.data.cantidad = 1;
    },
  },
};
