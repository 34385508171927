
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
// import 'devextreme-vue/text-area';
import MaintenancePolicyService from '../services/maintenance-policy-service';
import ClientService from '../services/client-service';
import SupplierService from '../services/supplier-service';
import ConfigFrequencyService from '../services/config-frequency-service';
import CompanyService from '../services/company-service';


export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
  },
  data() {
    return {
      polizamantenimiento: null,      
      dataSourceMaintenancePolicies: null,
      dataSourceClients: null,
      dataSourceSuppliers: null,
      dataSourceConfigFrequencies: null,
      dataSourceCompanies: null, 
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Póliza de Mantenimiento',
        showTitle: true,
        width: 850,
      },
      mode: 'select',
    };
  },
  created() {
    this.maintenancePolicyService = new MaintenancePolicyService();
    this.clientService = new ClientService();
    this.supplierService = new SupplierService();
    this.configFrequencyService = new ConfigFrequencyService();
    this.companyService = new CompanyService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.maintenancePolicyService.getPolizaMantenimientos().then(data => this.dataSourceMaintenancePolicies=data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers=data);
    this.configFrequencyService.getConfigFrecuencias().then(data => this.dataSourceConfigFrequencies=data);    
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editMaintenancePolicy(e) {
      this.maintenancePolicyService.updatePolizaMantenimiento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        console.log('bandera');
      });
		},
    deleteMaintenancePolicy(e) {
			this.maintenancePolicyService.deletePolizaMantenimiento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createMaintenancePolicy(e) {
			this.maintenancePolicyService.createPolizaMantenimiento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {      
      e.data.activo = true;
      //e.data.id_equipo_padre = null;      
      //this.maintenancePolicyService.getEquipoPadres().then(data => this.dataSourceFatherMaintenancePolicys=data);
    },
  },  
};
