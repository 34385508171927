import axios from 'axios';

export default class AlertService {

    getAlertasActivas(usuario) {		
        const url = process.env.VUE_APP_APIURL + `alertas/activas`;		
		return axios.get(url,{ params: { id_usuario: usuario} }).then(res=>res.data.data);
	}

    getAlertasReconocidasPorFiltro(id_usuario,id_empresa,fecha_inicio,fecha_fin) {		
        const url = process.env.VUE_APP_APIURL + `alertas/reconocidas/web`;		
		return axios.get(url,{ params: { id_usuario: id_usuario, id_empresa: id_empresa, fecha_inicio: fecha_inicio, fecha_fin: fecha_fin } }).then(res=>res.data.data);
	}

    updateReconocerAlerta(alerta){
        const url = process.env.VUE_APP_APIURL + `/alertas/reconocimiento`;
		return axios.put(url,alerta).then(res=>res.data.data);
	}
}