
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
  DxValidationRule,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import { DxLookup } from 'devextreme-vue/lookup'
import DxTreeView from 'devextreme-vue/tree-view';
import { DxToast } from 'devextreme-vue/toast';
import VueQrcode from 'vue-qrcode'
import { exportFromMarkup } from 'devextreme/viz/export';
// import toCanvas from 'canvg';
import { jsPDF } from 'jspdf';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import html2canvas from "html2canvas";
const submitButtonOptions = {
    text: "Imprimir Códigos QR",
    type: 'default',
    useSubmitBehavior: true
};

function prepareMarkup(markup) {
  return '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="820px" height="420px">'
  + `<foreignobject class="wow-star" width="100%" height="100%">
    <body xmlns="http://www.w3.org/1999/xhtml">
      ${markup}
    </body>     
  </foreignobject></svg>`;
}

const treeViewRef = 'treeView';

const qrs = [{"codigo_qr": "WEK9240F","descripcion": "AL839-SL199-HP S/N:28774"},{"codigo_qr": "WEK9240A","descripcion": "AL839-SL199-HP S/N:28774"},{"codigo_qr": "WEK92401","descripcion": "AL839-SL199-HP S/N:28774"},{"codigo_qr": "WEK92402","descripcion": "AL839-SL199-HP S/N:28774"}];

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxButton,
    DxTreeView,
    DxLookup,
    DxLabel,
    DxValidationRule,
    DxToast,
    VueQrcode,
  },
  data() {
    const selectionModes = ['multiple', 'single'];
    const showCheckBoxesModes = ['normal', 'selectAll', 'none'];
    
    return {      
      idEmpresa: null,
      idCliente: null,
      searchMode: 'contains',
      treeViewRef,
      qrCodeFormData: null,
      dataSourceQrs: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceEquipments: null,
      submitButtonOptions,
      showCheckBoxesModeValue: showCheckBoxesModes[0],
      selectionModeValue: selectionModes[0],
      qrCodes: qrs,
      selectedEquipments1: [],
      selectedEquipments2: [],
      selectedEquipments3: [],
      selectedEquipments4: [],
      selectedEquipments5: [],
      selectedEquipments6: [],
      isVisible: false,
      message: '',
      tableqr1: null,
      indexqr:1,
      type: 'info',
    };
  },
  created() {
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.equipmentCompanyService = new EquipmentCompanyService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
  },
  computed: {
    treeView() {
      return this.$refs[treeViewRef].instance;
    },
  },
  methods: { 
    getMarkup() {
      return this.$el.innerHTML;
    },
    onClick() {
      console.log(this.idEmpresa);
      console.log(this.idCliente);

      const formContent = this.$refs.qrtable.innerHTML;
      console.log(formContent);

      console.log(prepareMarkup(formContent));

      

      exportFromMarkup(prepareMarkup(formContent), {
        width: 820,
        height: 420,
        margin: 0,
        format: 'svg',
        fileName: 'codigoqr',
        /*
        svgToCanvas(svg, canvas) {
          return new Promise((resolve) => {
            toCanvas(canvas, new XMLSerializer().serializeToString(svg), {
              ignoreDimensions: true,
              ignoreClear: true,
              renderCallback: resolve,
            });
          });
        },
        */
      });

      /*
      console.log(this.qrCodeFormData.empresa);
      console.log(this.qrCodeFormData.cliente);
      console.log(this.idEmpresa);
      console.log(this.idCliente);
      
     
      auth.getUser().then((data) => {
        this.idUsuario = data.data.id_usuario;
        this.selectedTasks = [];
        for(var i in this.dataSourceEquipmentTasks) {
          this.selectedTasks.push(this.dataSourceEquipmentTasks[i].id);
          //this.selectedTasks.push({"id_equipo_empresa": this.dataSourceEquipmentTasks[i].id_equipo_empresa, "id_tarea": this.dataSourceEquipmentTasks[i].id_tarea});
        }
        this.workOrderService.createOrdenTrabajoWeb({"titulo": this.workOrderFormData.titulo, "fecha_inicio_plan": this.fechaInicio,
        "id_tipo_mantenimiento": this.idTipoMantenimiento, "id_empresa": this.idEmpresa, "id_cliente": this.idCliente,
        "id_proveedor": this.idProveedor, "id_tipo_mayor_menor": this.idClasificacion, "id_prioridad": this.idPrioridad,
        "id_origen_orden_trabajo": 3,"id_estatus_orden_trabajo": 1, "id_usuario_mod": this.idUsuario, "id_proyecto": this.idProyecto,
        "descripcion": this.workOrderFormData.descripcion,"responsables": this.idResponsables, "equipos": this.selectedEquipments, 
        "tareas_comunes": this.selectedCommonTasks, "tareas": this.selectedTasks,"tipo_equipo": this.equipmentTypeValue
        }).then(() => {
          this.type = 'success';
          this.message = 'Se ha registrado la OT correctamente';
          this.isVisible = true;
          this.cleanForm();
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al registrar la OT';
          this.isVisible = true;
        });
      });
      */
      //e.preventDefault();
    },

    exportGrid() { 
      let pdfRef = this.$refs.qrtableref;
      html2canvas(pdfRef).then((canvas) => {
        let pdfImage = canvas.toDataURL("image/jpeg");

        let pdf = new jsPDF( );
        pdf.width = pdf.internal.pageSize.width;
        pdf.height = pdf.internal.pageSize.height;
        pdf.margin = {
          horiz: 10,
          vert: 0
        };
        pdf.addImage(pdfImage, "image/jpg", 10, 1);
        pdf.autoPrint();
        window.open(
          URL.createObjectURL(pdf.output("blob")),
          "_blank",
          "height=800,width=600,scrollbars=yes,location=yes"
        );
        setTimeout(() => {
          window.URL.revokeObjectURL(pdf.output("bloburl"));
        }, 100);
      });
    },
    
    valueChangedCompany(e) {
      this.tableqr1 = document.getElementById('tableqr').insertRow(0);
      this.selectedEquipments1 = [];
      this.selectedEquipments2 = [];
      this.selectedEquipments3 = [];
      this.selectedEquipments4 = [];
      this.selectedEquipments5 = [];
      this.selectedEquipments6 = [];
      this.idEmpresa = e.value;
      this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipo(e.value,this.idCliente).then(data => this.dataSourceEquipments=data);
    },
    valueChangedClient(e) {
      this.tableqr1 = document.getElementById('tableqr').insertRow(0);
      this.selectedEquipments1 = [];
      this.selectedEquipments2 = [];
      this.selectedEquipments3 = [];
      this.selectedEquipments4 = [];
      this.selectedEquipments5 = [];
      this.selectedEquipments6 = [];
      this.idCliente = e.value;
      this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipo(this.idEmpresa,this.idCliente).then(data => this.dataSourceEquipments=data);
    },
    
    treeViewSelectionChanged(e) { 
      this.syncSelection(e.component);
    },

    treeViewContentReady(e) {
      this.syncSelection(e.component);
    },

    syncSelection(treeView) {
      const selectedEquipments = treeView.getSelectedNodes()
        .map((node) => node.itemData); 
      this.selectedEquipments1 = [];
      this.selectedEquipments2 = [];
      this.selectedEquipments3 = [];
      this.selectedEquipments4 = [];  
      this.selectedEquipments5 = [];
      this.selectedEquipments6 = [];

      // document.getElementById('tableqr').innerHTML = '';
      // this.tableqr1 = document.getElementById('tableqr').insertRow(0);
      // this.indexqr=1;
      for(var i in selectedEquipments){
        var index =Number(i)+1;
       var codigoqr = selectedEquipments[i].codigo_qr ??"";
        var codigo = selectedEquipments[i].codigo ?? ""; 
        var serie = selectedEquipments[i].serie ?? "";
        
        var qrdata = { "codigo_qr": codigoqr, "descripcion": codigo + " " + serie };
        
        if((index % 6) == 1){ 
          // var a = this.tableqr1.insertCell(0);
          // a.innerHTML = "<vue-qrcode value='222222' width='150' margin='0' render-as='svg' />";
          if (this.selectedEquipments1.length < 4)
           this.selectedEquipments1.push(qrdata);
        } 
        if ((index % 6) == 2) {
          // var b = this.tableqr1.insertCell(1)
          // b.innerHTML = "New Cell3";
          if (this.selectedEquipments2.length < 4)
            this.selectedEquipments2.push(qrdata);
        } 
        if ((index % 6) == 3) {
          // var c = this.tableqr1.insertCell(2);
          // c.innerHTML = "New Cell4";
          if (this.selectedEquipments3.length < 4)
            this.selectedEquipments3.push(qrdata);
        } 
        if ((index % 6) == 4) {
          // var d = this.tableqr1.insertCell(3);
          // d.innerHTML = "New Cell4";
          // this.tableqr1 = document.getElementById('tableqr').insertRow(0);
          // this.indexqr++;
          if (this.selectedEquipments4.length < 4)
            this.selectedEquipments4.push(qrdata);
        } 
        if ((index % 6) == 5) {
          // var d = this.tableqr1.insertCell(3);
          // d.innerHTML = "New Cell4";
          // this.tableqr1 = document.getElementById('tableqr').insertRow(0);
          // this.indexqr++;
          if (this.selectedEquipments5.length<4)
            this.selectedEquipments5.push(qrdata);
        } 
        if ((index % 6) == 0) {
          // var d = this.tableqr1.insertCell(3);
          // d.innerHTML = "New Cell4";
          // this.tableqr1 = document.getElementById('tableqr').insertRow(0);
          // this.indexqr++;
          if (this.selectedEquipments6.length < 4)
            this.selectedEquipments6.push(qrdata);
        } 
      }
       
        
 
    },
  
  } 

};
