
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import LocationSettingTab from './location-settings-tab.vue';
import LocationTab from './location-tab.vue';
import LocationItemsTab from './locations-items-tab.vue';

export default {
    components: {
        DxTabPanel,
        DxItem,
        LocationSettingTab,
        LocationTab,
        LocationItemsTab
    },
    data() {
        return {
            selectedIndex: 0,
            loop: true,
            animationEnabled: true,
            swipeEnabled: false,
        }
    },
    methods: {}
}
