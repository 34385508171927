import axios from 'axios';

export default class TypeOperationUnitService {

	getTipoUnidadOperaciones() {		
        const url = process.env.VUE_APP_APIURL + `tipounidadoperaciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoUnidadOperacion(tipounidadoperacion) {
        const url = process.env.VUE_APP_APIURL + `/tipounidadoperaciones/${tipounidadoperacion.id_tipo_unidad_operacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoUnidadOperacion(tipounidadoperacion){
        const url = process.env.VUE_APP_APIURL + `/tipounidadoperaciones/${tipounidadoperacion.id_tipo_unidad_operacion}`;
		return axios.put(url,tipounidadoperacion).then(res=>res.data.data);
	}

	createTipoUnidadOperacion(tipounidadoperacion){
        const url = process.env.VUE_APP_APIURL + `/tipounidadoperaciones`;
		return axios.post(url,tipounidadoperacion).then(res=>res.data.data);
	}

	deleteTipoUnidadOperacion(tipounidadoperacion){
        const url = process.env.VUE_APP_APIURL + `/tipounidadoperaciones/${tipounidadoperacion.id_tipo_unidad_operacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}