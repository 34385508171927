import axios from 'axios';

export default class EquipmentMaintenanceTypeActionService {

	getEquipoTipoMantenimientoAcciones() {			
        const url = process.env.VUE_APP_APIURL + `equipotipomttoacciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoTipoMantenimientoAccion(equipotipomantenimientoaccion) {
        const url = process.env.VUE_APP_APIURL + `/equipotipomttoacciones/${equipotipomantenimientoaccion.id_equipo_tipo_mtto_accion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoTipoMantenimientoAccion(equipotipomantenimientoaccion){
        const url = process.env.VUE_APP_APIURL + `/equipotipomttoacciones/${equipotipomantenimientoaccion.id_equipo_tipo_mtto_accion}`;
		return axios.put(url,equipotipomantenimientoaccion).then(res=>res.data.data);
	}

	createEquipoTipoMantenimientoAccion(equipotipomantenimientoaccion){
        const url = process.env.VUE_APP_APIURL + `/equipotipomttoacciones`;
		return axios.post(url,equipotipomantenimientoaccion).then(res=>res.data.data);
	}

	deleteEquipoTipoMantenimientoAccion(equipotipomantenimientoaccion){
        const url = process.env.VUE_APP_APIURL + `/equipotipomttoacciones/${equipotipomantenimientoaccion.id_equipo_tipo_mtto_accion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}