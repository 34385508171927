import axios from 'axios';

export default class GroupProductService {

	getGrupoProductos() {	
        const url = process.env.VUE_APP_APIURL + `grupoproductos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoProducto(grupoproducto) {
        const url = process.env.VUE_APP_APIURL + `/grupoproductos/${grupoproducto.id_grupo_producto}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoProducto(grupoproducto) {
        const url = process.env.VUE_APP_APIURL + `/grupoproductos/${grupoproducto.id_grupo_producto}`;
		return axios.put(url,grupoproducto)
		.then(res=>res.data.data);
	}

	createGrupoProducto(grupoproducto){
        const url = process.env.VUE_APP_APIURL + `/grupoproductos`;
		return axios.post(url,grupoproducto).then(res=>res.data.data);
	}

	deleteGrupoProducto(grupoproducto){
        const url = process.env.VUE_APP_APIURL + `/grupoproductos/${grupoproducto.id_grupo_producto}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}