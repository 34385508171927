
  import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxLookup, 
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxColumnChooser,
    DxSorting
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';

  import EquipmentCategoryTaskService from '../services/equipment-category-task-service';
  import EquipmentCategoryMeasurementService from '../services/equipment-category-measurement-service';
  import LogicOperatorService from '../services/logic-operator-service';
  import MeasurementFlowService from '../services/measurement-flow-service';
  
  export default {
    components: {
      DxDataGrid,
      DxEditing,
      DxColumn,
      DxValidationRule,
      DxPaging,
      DxLookup,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxSelection,
      DxToast,
      DxColumnChooser,
      DxSorting
    },
    data() {
      return {
        dataSourceLogic: null,
        dataSourceMeasurementFlow: null,
        dataSourceTask: null,
        dataSourceMeasurement: null,
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        mode: 'select',
      };
    },
    created() {
      this.logicOperatorService = new LogicOperatorService();
      this.measurementFlowService = new MeasurementFlowService();
      this.equipmentCategoryTaskService = new EquipmentCategoryTaskService();
      this.equipmentCategoryMeasurementService = new EquipmentCategoryMeasurementService();
    },
    mounted() {    
      this.logicOperatorService.getComparadorLogicos().then(data => this.dataSourceLogic=data);
      this.equipmentCategoryTaskService.getEquipoCategoriaTareasTareas().then(data => this.dataSourceTask=data);
      this.equipmentCategoryMeasurementService.getEquipoCategoriaMedicionesMediciones().then(data => this.dataSourceMeasurement=data);
      this.measurementFlowService.getMedicionFlujos().then(data => this.dataSourceMeasurementFlow=data);
    },
    methods: {
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      editMeasurementFlow(e) {
        this.measurementFlowService.updateMedicionFlujo(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          console.log('bandera');
        });
      },
      deleteMeasurementFlow(e) {
        this.measurementFlowService.deleteMedicionFlujo(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
        });
      },
      createMeasurementFlow(e) {
        this.measurementFlowService.createMedicionFlujo(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        });
      },
      onInitNewRow(e) {
        e.data.id_operador_logico = 1;
      },
    },  
  };
  