import axios from 'axios';

export default class TypeItemService {
  getTipoArticulos() {
    const url = process.env.VUE_APP_APIURL + `tipoarticulos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getTipoArticulo(tipoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/tipoarticulos/${tipoarticulo.id_tipo_articulo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateTipoArticulo(tipoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/tipoarticulos/${tipoarticulo.id_tipo_articulo}`;
    return axios.put(url, tipoarticulo).then((res) => res.data.data);
  }

  createTipoArticulo(tipoarticulo) {
    const url = process.env.VUE_APP_APIURL + `/tipoarticulos`;
    return axios.post(url, tipoarticulo).then((res) => res.data.data);
  }

  deleteTipoArticulo(tipoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/tipoarticulos/${tipoarticulo.id_tipo_articulo}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getTipoArticulosCombo() {
    const url = process.env.VUE_APP_APIURL + `tipoarticulos/combo`;
    return axios.get(url).then((res) => res.data.data);
  }

  getTipoArticulosComboRequisiciones() {
    const url = process.env.VUE_APP_APIURL + `tipoarticulos/comborequisiciones`;
    return axios.get(url).then((res) => res.data.data);
  }

  getTipoArticulosTodosCombo() {
    const url = process.env.VUE_APP_APIURL + `tipoarticulos/combos/opciontodos`;
    return axios.get(url).then((res) => res.data.data);
  }
}