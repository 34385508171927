import axios from 'axios';

export default class TypeChecklistService {

	getTipoListaVerificaciones() {	
        const url = process.env.VUE_APP_APIURL + `tipolistaverificaciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoListaVerificacion(tipolistaverificacion) {
        const url = process.env.VUE_APP_APIURL + `/tipolistaverificaciones/${tipolistaverificacion.id_tipo_lista_verificacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoListaVerificacion(tipolistaverificacion) {
        const url = process.env.VUE_APP_APIURL + `/tipolistaverificaciones/${tipolistaverificacion.id_tipo_lista_verificacion}`;
		return axios.put(url,tipolistaverificacion)
		.then(res=>res.data.data);
	}

	createTipoListaVerificacion(tipolistaverificacion){
        const url = process.env.VUE_APP_APIURL + `/tipolistaverificaciones`;
		return axios.post(url,tipolistaverificacion).then(res=>res.data.data);
	}

	deleteTipoListaVerificacion(tipolistaverificacion){
        const url = process.env.VUE_APP_APIURL + `/tipolistaverificaciones/${tipolistaverificacion.id_tipo_lista_verificacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}