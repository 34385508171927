import axios from 'axios';

export default class ProductSpecialFieldService {

	getCamposAsignadosPorProducto(productoId) {		
        const url = process.env.VUE_APP_APIURL + `productocampos/asignados/${productoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getCamposDisponiblesPorProducto(productoId) {		
        const url = process.env.VUE_APP_APIURL + `productocampos/disponibles/${productoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getProductoCampo(productocampo) {
        const url = process.env.VUE_APP_APIURL + `/productocampos/${productocampo.id_producto_campo}`;
		return axios.get(url).then(res=>res.data.data);
	}

    updateProductoCampo(productocampo){
        const url = process.env.VUE_APP_APIURL + `productocampos/${productocampo.id_producto_campo}`;
		return axios.put(url,productocampo).then(res=>res.data.data);
	}

	createProductoCampo(productocampo){
        const url = process.env.VUE_APP_APIURL + `/productocampos`;
		return axios.post(url,productocampo).then(res=>res.data.data);		
	}

	deleteProductoCampo(productocampo){
        const url = process.env.VUE_APP_APIURL + `/productocampos/${productocampo.id_producto_campo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}