import axios from 'axios';

export default class ClientContactService {

	getClienteContactos() {			
        const url = process.env.VUE_APP_APIURL + `clientecontactos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getClienteContacto(clientecontacto) {
        const url = process.env.VUE_APP_APIURL + `/clientecontactos/${clientecontacto.id_cliente_contacto}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateClienteContacto(clientecontacto){
        const url = process.env.VUE_APP_APIURL + `/clientecontactos/${clientecontacto.id_cliente_contacto}`;
		return axios.put(url,clientecontacto).then(res=>res.data.data);
	}

	createClienteContacto(clientecontacto){
        const url = process.env.VUE_APP_APIURL + `/clientecontactos`;
		return axios.post(url,clientecontacto).then(res=>res.data.data);
	}

	deleteClienteContacto(clientecontacto){
        const url = process.env.VUE_APP_APIURL + `/clientecontactos/${clientecontacto.id_cliente_contacto}`;
		return axios.delete(url).then(res=>res.data.data);
	}

	getCorreosPorOrdenTrabajo(ordentrabajo) {
        const url = process.env.VUE_APP_APIURL + `/clientecontactos/correos`;
		return axios.get(url,{ params: { id_orden_trabajo: ordentrabajo} }).then(res=>res.data.data);
	}
}