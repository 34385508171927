

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import Document from './document.vue';
import EquipmentCategoryDocument from './equipment-category-document.vue';
import EquipmentDocument from './equipment-document.vue';
import DocumentClient from './document-client.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    Document,
    EquipmentCategoryDocument,
    EquipmentDocument,
    DocumentClient,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
