
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxEditing,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';

import UserService from '../services/user-service';
import KpiService from '../services/kpi-service';
import KpiUserService from '../services/kpi-user-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting,
  },
  data() {
    return {
      users: null,      
      dataSourceUsers: null,
      dataSourceKPIAvailable: null,
      dataSourceKPIAssigned: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
    };
  },
  created() {
    this.kpiService = new KpiService();
    this.kpiUserService = new KpiUserService();
    this.userService = new UserService();
  },
  mounted() {
    this.userService.getUsuarios().then(data => this.dataSourceUsers=data);  
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_kpi);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addKpi() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.kpiUserService.createKPIUsuario({"id_usuario": this.users.id_usuario, "id_kpi": this.selectedRowsData[this.i].id_kpi}).then(() => {           
            
            if (this.i==this.selectedRowsData.length) {
              
              this.kpiUserService.getKPIDisponiblesPorUsuario(this.users.id_usuario).then(data => this.dataSourceKPIAvailable=data);
              this.kpiUserService.getKPIAsignadosPorUsuario(this.users.id_usuario).then(data => this.dataSourceKPIAssigned=data);
            }
            
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    valueChangedUser(e) {
        this.kpiUserService.getKPIDisponiblesPorUsuario(e.value.id_usuario).then(data => this.dataSourceKPIAvailable=data);
        this.kpiUserService.getKPIAsignadosPorUsuario(e.value.id_usuario).then(data => this.dataSourceKPIAssigned=data);
    },
    deleteKPI(e) {
      this.kpiUserService.deleteKPIUsuario(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.kpiUserService.getKPIDisponiblesPorUsuario(this.users.id_usuario).then(data => this.dataSourceKPIAvailable=data);
        this.kpiUserService.getKPIAsignadosPorUsuario(this.users.id_usuario).then(data => this.dataSourceKPIAssigned=data); 
      });
    },
    editKPI(e) {
      this.kpiUserService.updateKPIUsuario(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.kpiUserService.getKPIAsignadosPorUsuario(this.users.id_usuario).then(data => this.dataSourceKPIAssigned=data); 
      });
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'nombre') {
        e.editorOptions.disabled = true;
      }
    },
  },  
};
