
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import LogicOperatorService from '../services/logic-operator-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceLogicOperators: null,
      events: [],
      logicOperatorService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.logicOperatorService = new LogicOperatorService();
  },
  mounted() {    
    this.logicOperatorService.getComparadorLogicos().then(data => this.dataSourceLogicOperators=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editLogicOperator(e) {
			this.logicOperatorService.updateComparadorLogico(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteLogicOperator(e) {
			this.logicOperatorService.deleteComparadorLogico(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createLogicOperator(e) {
			this.logicOperatorService.createComparadorLogico(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
