import axios from 'axios';

export default class WarrantyEquipmentService {

    getEquiposDisponiblesPorGarantia(garantiaId) {		
        const url = process.env.VUE_APP_APIURL + `garantiaequipos/disponibles/${garantiaId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquiposAsignadosPorGarantia(garantiaId) {		
        const url = process.env.VUE_APP_APIURL + `garantiaequipos/asignados/${garantiaId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getGarantiaEquipos() {			
        const url = process.env.VUE_APP_APIURL + `garantiaequipos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGarantiaEquipo(garantiaequipo) {
        const url = process.env.VUE_APP_APIURL + `/garantiaequipos/${garantiaequipo.id_garantia_equipo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createGarantiaEquipo(garantiaequipo){
        const url = process.env.VUE_APP_APIURL + `/garantiaequipos`;
		return axios.post(url,garantiaequipo).then(res=>res.data.data);
	}

	deleteGarantiaEquipo(garantiaequipo){
        const url = process.env.VUE_APP_APIURL + `/garantiaequipos/${garantiaequipo.id_garantia_equipo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}

