import axios from 'axios';

export default class ControlService {

	getControles() {		
        const url = process.env.VUE_APP_APIURL + `controles`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getControl(control) {
        const url = process.env.VUE_APP_APIURL + `/controles/${control.id_control}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateControl(control){
        const url = process.env.VUE_APP_APIURL + `/controles/${control.id_control}`;
		return axios.put(url,control).then(res=>res.data.data);
	}

	createControl(control){
        const url = process.env.VUE_APP_APIURL + `/controles`;
		return axios.post(url,control).then(res=>res.data.data);
	}

	deleteControl(control){
        const url = process.env.VUE_APP_APIURL + `/controles/${control.id_control}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}