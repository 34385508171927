import axios from "axios";

export default class ItemLevelService {
  getArticuloNiveles() {
    const url = process.env.VUE_APP_APIURL + `articuloniveles`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getArticuloNivel(articulonivel) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloniveles/${articulonivel.id_articulo_nivel}`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  updateArticuloNivel(articulonivel) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloniveles/${articulonivel.id_articulo_nivel}`;
    return axios
      .put(url, articulonivel)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createArticuloNivel(articulonivel) {
    const url = process.env.VUE_APP_APIURL + `/articuloniveles`;
    return axios
      .post(url, articulonivel)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteArticuloNivel(articulonivel) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloniveles/${articulonivel.id_articulo_nivel}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
