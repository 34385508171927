import axios from 'axios';

export default class TypeDocumentService {

	getTipoDocumentos() {		
        const url = process.env.VUE_APP_APIURL + `tipodocumentos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoDocumento(tipodocumento) {
        const url = process.env.VUE_APP_APIURL + `/tipodocumentos/${tipodocumento.id_tipo_documento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoDocumento(tipodocumento){
        const url = process.env.VUE_APP_APIURL + `/tipodocumentos/${tipodocumento.id_tipo_documento}`;
		return axios.put(url,tipodocumento).then(res=>res.data.data);
	}

	createTipoDocumento(tipodocumento){
        const url = process.env.VUE_APP_APIURL + `/tipodocumentos`;
		return axios.post(url,tipodocumento).then(res=>res.data.data);
	}

	deleteTipoDocumento(tipodocumento){
        const url = process.env.VUE_APP_APIURL + `/tipodocumentos/${tipodocumento.id_tipo_documento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}