

//     <div class="master-detail-caption">{{ detailInfo }}</div>

import { DxDataGrid, DxColumn, DxEditing } from 'devextreme-vue/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import PhysicalInventoryItemService from '../services/physical-inventory-item-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { id_inventario_fisico } = this.templateData.data;
    return {
      items: null,
      dataSourcePhysicalInventoryItems: null,
      idInventarioFisico: id_inventario_fisico,
    };
  },
  created() {
    this.physicalInventoryItemService = new PhysicalInventoryItemService();
  },
  mounted() {
    this.physicalInventoryItemService.getInventarioFisicoArticulos().then((data) => {
        this.items=data;
        this.dataSourcePhysicalInventoryItems = this.getItems(this.templateData.key); 
    });
  },
  methods: {
    editPhysicalInventoryItem(e) {
      this.physicalInventoryItemService.updateInventarioFisicoArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
    },
    deletePhysicalInventoryItem(e) {
      this.procedureStepService.deleteInventarioFisicoArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
    },
    refreshData() {
      this.physicalInventoryItemService.getInventarioFisicoArticulos().then((data) => {
      this.items=data;
      this.dataSourcePhysicalInventoryItems = this.getItems(this.templateData.key); 
      });
    },
    getItems(key) {
      return new DataSource({
        store: new ArrayStore({
          data: this.items,
          key: 'id_inventario_fisico_articulo',
        }),
        filter: ['id_inventario_fisico', '=', key],
      });
    },
  },
};
