import axios from 'axios';

export default class ItemService {

  getArticulosPorFiltro(filtro) {
    const url = process.env.VUE_APP_APIURL + `articulos/filtros`;
    return axios.get(url,{ params: { id_almacen: filtro.id_almacen, id_tipo_articulo: filtro.id_tipo_articulo, id_grupo_articulo: filtro.id_grupo_articulo } }).then((res) => res.data.data);
  }

  getArticulosPorValeElectronico(valeelectronico) {
    const url = process.env.VUE_APP_APIURL + `articulos/valeelectronicos/${valeelectronico}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getArticulosCombo() {
    const url = process.env.VUE_APP_APIURL + `articulos/combos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getArticulos() {
    const url = process.env.VUE_APP_APIURL + `articulos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getArticulosWeb() {
    const url = process.env.VUE_APP_APIURL + `articulos/web`;
    return axios.get(url).then((res) => res.data.data);
  }

  getArticulo(articulo) {
    const url =
      process.env.VUE_APP_APIURL + `/articulos/${articulo.id_articulo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateArticulo(articulo) {
    const url =
      process.env.VUE_APP_APIURL + `/articulos/${articulo.id_articulo}`;
    return axios.put(url, articulo).then((res) => res.data.data);
  }

  createArticulo(articulo) {
    const url = process.env.VUE_APP_APIURL + `/articulos`;
    return axios.post(url, articulo).then((res) => res.data.data);
  }

  deleteArticulo(articulo) {
    const url =
      process.env.VUE_APP_APIURL + `/articulos/${articulo.id_articulo}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getArticuloComboActivos() {
    const url = process.env.VUE_APP_APIURL + `/articulos/combos/activo`;
    return axios.get(url).then((res) => res.data.data);
  }

  getArticuloComboTodos() {
    const url = process.env.VUE_APP_APIURL + `/articulos/combos/todos`;
    return axios.get(url).then((res) => res.data.data);
  }
}