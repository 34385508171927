
export default {
  data() {
    return {
      appVersion: null,
    };
  },
  created() {
    this.appVersion = process.env.VUE_APP_VERSION;
  },
};
