import axios from 'axios';

export default class EquipmentCompanySpecialFieldService {

	getCamposPorEquipoEmpresa(equipoempresa) {		
        const url = process.env.VUE_APP_APIURL + `equipoempresacampos/equipoempresas/${equipoempresa}`;		
		return axios.get(url).then(res=>res.data.data);
	}
    
    getEquipoEmpresaCampos() {		
        const url = process.env.VUE_APP_APIURL + `equipoempresacampos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoEmpresaCampo(equipoempresacampo) {
        const url = process.env.VUE_APP_APIURL + `/equipoempresacampos/${equipoempresacampo.id_equipo_empresa_campo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoEmpresaCampo(equipoempresacampo){
        const url = process.env.VUE_APP_APIURL + `/equipoempresacampos/${equipoempresacampo.id_equipo_empresa_campo}`;
		return axios.put(url,equipoempresacampo).then(res=>res.data.data);
	}

	createEquipoEmpresaCampo(equipoempresacampo){
        const url = process.env.VUE_APP_APIURL + `/equipoempresacampos`;
		return axios.post(url,equipoempresacampo).then(res=>res.data.data);		
	}

	deleteEquipoEmpresaCampo(equipoempresacampo){
        const url = process.env.VUE_APP_APIURL + `/equipoempresacampos/${equipoempresacampo.id_equipo_empresa_campo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}