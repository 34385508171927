
import {
  DxDataGrid, DxColumn, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxLoadPanel, DxScrolling, DxColumnChooser, DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
export default {
  components: {
    DxDataGrid, DxColumn, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxLoadPanel, DxScrolling, DxColumnChooser, DxGroupPanel,
    DxGrouping,
  },
  data() {
      return {
        mode: 'select',
        autoExpandAll: false,
        dataSourceAnalytics: null,
      };
  },
  created() {
      //this.companyService = new CompanyService();
  },
  mounted() {
      //this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);    
  },
  methods: {
    logEvent(eventName) {
        this.events.unshift(eventName);
    },
  },
}
