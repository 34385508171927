import axios from 'axios';

export default class MeasurementOptionService {

	getMedicionOpciones() {			
        const url = process.env.VUE_APP_APIURL + `medicionopciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMedicionOpcion(mediocionOpcion) {
        const url = process.env.VUE_APP_APIURL + `/medicionopciones/${mediocionOpcion.id_medicion_opcion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMedicionOpcion(mediocionOpcion){
        const url = process.env.VUE_APP_APIURL + `/medicionopciones/${mediocionOpcion.id_medicion_opcion}`;
		return axios.put(url,mediocionOpcion).then(res=>res.data.data);
	}

	createMedicionOpcion(mediocionOpcion){
        const url = process.env.VUE_APP_APIURL + `/medicionopciones`;
		return axios.post(url,mediocionOpcion).then(res=>res.data.data);
	}

	deleteMedicionOpcion(mediocionOpcion){
        const url = process.env.VUE_APP_APIURL + `/medicionopciones/${mediocionOpcion.id_medicion_opcion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}