import axios from 'axios';

export default class ActionService {

	getAcciones() {		
        const url = process.env.VUE_APP_APIURL + `acciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getAccion(accion) {
        const url = process.env.VUE_APP_APIURL + `/acciones/${accion.id_accion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateAccion(accion){
        const url = process.env.VUE_APP_APIURL + `/acciones/${accion.id_accion}`;
		return axios.put(url,accion).then(res=>res.data.data);
	}

	createAccion(accion){
        const url = process.env.VUE_APP_APIURL + `/acciones`;
		return axios.post(url,accion).then(res=>res.data.data);
	}

	deleteAccion(accion){
        const url = process.env.VUE_APP_APIURL + `/acciones/${accion.id_accion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}