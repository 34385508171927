
var listaequipos = [];
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup,
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxSorting,
  DxColumnChooser,
  DxButton,
  DxMasterDetail,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxGallery from "devextreme-vue/gallery";

import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import robotoNormal from '@/assets/fonts/Roboto-Thin-normal.js'
import robotoRegular from '@/assets/fonts/Roboto-Thin-regular.js'

import TypeFailureService from '../services/type-failure-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import StatusBugReportService from '../services/status-bug-report-service';
import BrandService from '../services/brand-service';
import FaultReportService from '../services/fault-report-service';
import ClientService from '../services/client-service';
import CompanyService from '../services/company-service';

import FaultReportTab from './fault-report-tab.vue';

export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxColumnChooser,
    DxGallery,
    DxButton,
    DxPopup,
    DxToolbarItem,
    DxMasterDetail,
    FaultReportTab,
    DxToolbar
  },
  data() {
    return {
      selectedRowKeys: [],
      reportefallas: null,
      dataSourceFaultReports: null,
      dataSourceFaulReportFiles: null,
      dataSourceStatus: null,
      dataSourceBrands: null,
      dataSourceEquipments: null,
      dataSourceFailureTypes: null,
      dataSourceClients: null,
      dataSourceCompanies: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Reporte de Falla',
        showTitle: true,
        width: 950,
        height: 640,
      },
      mode: 'select',
      autoExpandAll: true,
      titulo_principal: null,
      descripcion_principal: null,
      equipo: null,
      folio: null,
      cliente: null,
      clave_empresa: null,
      estatus: null,
      tipo_falla: null,
      fecha_falla: null,
      fecha_reporte: null,
      reporte_servicio: '',
      cotizacion: '',
      set_id_empresa: null,
      set_id_cliente: null,
      listaequipos,
      setStateValueEmpresa(rowData, value) {
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueCliente(rowData, value) {
        this.defaultSetCellValue(rowData, value);
      },
      popupVisible: false,
      closeButtonOptions: {
        text: 'Close',
        onClick: () => {
          this.popupVisible = false;
        },
      },
    };
  },
  created() {
    this.faultReportService = new FaultReportService();
    this.typeFailureService = new TypeFailureService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.statusBugReportService = new StatusBugReportService();
    this.brandService = new BrandService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
  },
  mounted() {
    this.companyService.getEmpresasCombo().then(data => this.dataSourceCompanies = data);
    this.clientService.getClienteComboPorUsuario().then(data => this.dataSourceClients = data);
    this.typeFailureService.getTipoFallas().then(data => this.dataSourceFailureTypes = data);
    this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => {
      listaequipos = data;
      this.dataSourceEquipments = data
    });
    this.statusBugReportService.getEstatusReporteFallas().then(data => this.dataSourceStatus = data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands = data);
    this.faultReportService.getConsultaReporteFallas().then(data => this.dataSourceFaultReports = data);
  },
  methods: {
    refreshDataGrid() {
      this.faultReportService.getConsultaReporteFallas().then(data => this.dataSourceFaultReports = data);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editFaultReport(e) {
      this.usuarioId = localStorage.getItem("user-data");
      this.faultReportService.updateReporteFalla({
        "id_reporte_falla": e.data.id_reporte_falla, "id_empresa": e.data.id_empresa, "id_cliente": e.data.id_cliente, "id_usuario_mod": this.usuarioId,
        "id_equipo_empresa": e.data.id_equipo_empresa, "titulo": e.data.titulo, "descripcion": e.data.descripcion, "resolucion": e.data.resolucion,
        "id_estatus_reporte_falla": e.data.id_estatus_reporte_falla, "id_tipo_falla": e.data.id_tipo_falla, "fecha_falla": e.data.fecha_falla
      }).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el reporte';
        this.isVisible = true;
        this.faultReportService.getConsultaReporteFallas().then(data => this.dataSourceFaultReports = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el reporte';
        this.isVisible = true;
        this.faultReportService.getConsultaReporteFallas().then(data => this.dataSourceFaultReports = data);
      });
    },
    deleteFaultReport(e) {
      this.faultReportService.deleteReporteFalla(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el reporte';
        this.isVisible = true;
        this.faultReportService.getConsultaReporteFallas().then(data => this.dataSourceFaultReports = data);
      });
    },
    createFaultReport(e) {
      this.usuarioId = localStorage.getItem("user-data");
      this.faultReportService.createReporteFalla({
        "id_empresa": e.data.id_empresa, "id_cliente": e.data.id_cliente, "id_usuario_reporta": this.usuarioId, "id_usuario_mod": this.usuarioId,
        "id_equipo_empresa": e.data.id_equipo_empresa, "titulo": e.data.titulo, "descripcion": e.data.descripcion, "resolucion": e.data.resolucion,
        "id_estatus_reporte_falla": e.data.id_estatus_reporte_falla, "id_tipo_falla": e.data.id_tipo_falla, "fecha_falla": e.data.fecha_falla
      }).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el reporte';
        this.isVisible = true;
        this.faultReportService.getConsultaReporteFallas().then(data => this.dataSourceFaultReports = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el reporte';
        this.isVisible = true;
        this.faultReportService.getConsultaReporteFallas().then(data => this.dataSourceFaultReports = data);
      });
    },
    /*
    onEditorPreparing(e) {
      if (e.dataField === 'id_equipo_padre' && e.row.data.id_equipo === 0) {
        e.editorOptions.disabled = true;
        e.editorOptions.value = null;
      }
    },
    */
    onInitNewRow(e) {
      e.data.id_estatus_reporte_falla = 1;
      this.set_id_empresa = null;
      this.set_id_cliente = null;
      //e.data.id_equipo_padre = null;      
      //this.taskService.getEquipoPadres().then(data => this.dataSourceFatherTasks=data);
    },
    editFiles(e) {
      this.faultReportService.getReporteFallaById(e.row.data.id_reporte_falla).then(
        data => {
          this.dataSourceFaulReportFiles = [];
          this.titulo_principal = '';
          this.descripcion_principal = '';
          this.equipo = '';
          this.folio = '';
          this.clave_empresa = '';
          this.cliente = '';
          this.tipo_falla = '';
          this.fecha_falla = '';
          this.fecha_reporte = '';
          this.estatus = '';
          this.cotizacion = '';
          this.reporte_servicio = '';
          data.forEach((element) => {
            var files = {
              url: element.url,
              descripcion: element.descripcion,
              titulo_principal: element.titulo_principal,
              descripcion_principal: element.descripcion_principal,
              equipo: element.equipo,
              folio: element.folio,
              clave_empresa: element.clave_empresa,
              cliente: element.cliente,
              estatus: element.estatus,
              tipo_falla: element.tipo_falla,
              fecha_falla: element.fecha_falla,
              fecha_reporte: element.fecha,
              usuario: element.usuario_iniciales,
              fecha: element.fecha_reg,
              id_tipo_archivo: element.id_tipo_archivo,
              reporte_servicio: element.reporte_servicio,
              cotizacion: element.cotizacion,
            };
            this.titulo_principal = files.titulo_principal;
            this.descripcion_principal = files.descripcion_principal;
            this.equipo = files.equipo;
            this.folio = files.folio;
            this.clave_empresa = files.clave_empresa;
            this.cliente = files.cliente;
            this.tipo_falla = files.tipo_falla;
            this.fecha_falla = files.fecha_falla;
            this.fecha_reporte = files.fecha_reporte;
            this.estatus = files.estatus;
            this.cotizacion = files.cotizacion;
            this.reporte_servicio = files.reporte_servicio;
            this.dataSourceFaulReportFiles.push(files);
          });
          this.popupVisible = true;
        }

      );

    },
    /*
    onSelectionChanged(e) {
      console.log(e["selectedRowsData"][0]["id_reporte_falla"]);
      this.faultReportService.getReporteFallaById(e["selectedRowsData"][0]["id_reporte_falla"]).then(
        data=> {
          this.dataSourceFaulReportFiles = [];
          data.forEach((element) => {
            var files = {
              url: element.url,
              descripcion: element.descripcion,
              titulo_principal: element.titulo_principal,
              descripcion_principal: element.descripcion_principal,
              equipo: element.equipo,
              folio: element.folio,
              clave_empresa: element.clave_empresa,
              cliente: element.cliente,
              estatus: element.estatus,
              tipo_falla: element.tipo_falla,
              fecha_falla: element.fecha_falla,
              fecha_reporte: element.fecha,
              usuario: element.usuario_iniciales,
              fecha: element.fecha_reg,
              id_tipo_archivo: element.id_tipo_archivo,
              reporte_falla: element.reporte_falla,
              cotizacion: element.cotizacion,
            };
            this.titulo_principal = files.titulo_principal;
            this.descripcion_principal = files.descripcion_principal;
            this.equipo = files.equipo;
            this.folio = files.folio;
            this.clave_empresa = files.clave_empresa;
            this.cliente = files.cliente;
            this.tipo_falla = files.tipo_falla;
            this.fecha_falla = files.fecha_falla;
            this.fecha_reporte = files.fecha_reporte;
            this.estatus = files.estatus;
            this.dataSourceFaulReportFiles.push(files);
          });

          console.log("Id falla",data);
          var modal = document.getElementById("modalfaultimg"); 
          modal.style.display = "block"; 
        } 
      );
    },
    */
    closeModalFault() {
      var modal = document.getElementById("modalfaultimg");
      modal.style.display = "none";
    },
    getFilteredEquipos: (options) => (
      {
        store: listaequipos,
        filter: options.data ? (options.data.id_cliente ? (['id_cliente', '=', options.data.id_cliente]) : null) || (options.data.id_cliente ? (['id_cliente', '=', options.data.id_cliente]) : null) : null,
      }),
    createpdf(e) {
      // var imgUrl = require("../assets/12.png");
      this.faultReportService
        .getReporteFallasPDF(e.row.data.id_reporte_falla).then((dataReporte) => {
          const doc = new jsPDF('portrait', 'mm', 'a4');
          // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          // var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          doc.addFileToVFS('Roboto-normal1.ttf', robotoNormal)
          doc.addFileToVFS('Roboto-regular.ttf', robotoRegular)
          doc.setFont("RobotoRegular");

          doc.setDrawColor("35B729");
          doc.setLineWidth(0);
          doc.setFillColor("35B729");
          doc.triangle(200, 0, 20, 0, 120, 10, 'FD');


          doc.setDrawColor("F80202");
          doc.setLineWidth(0);
          doc.setFillColor("F80202");
          doc.triangle(0, 0, 0, 3, 80, 10, 'FD');
          doc.triangle(140, 0, 0, 0, 80, 10, 'FD');

          doc.setDrawColor("004EFF");
          doc.setLineWidth(0);
          doc.setFillColor("004EFF");
          doc.triangle(120, 0, 20, 0, 60, 5, 'FD');

          var imgUrl = dataReporte['empresa_logotipo'];
          var img = new Image;
          img.src = imgUrl;

          var date = new Date();
          var fechaactual = date.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

          doc.text("Reporte de Falla " + dataReporte["folio"] ?? "", 105, 18, { align: 'center' });

          doc.setFontSize(8);
          doc.text(`Fecha: ${fechaactual}`, 155, 18, { align: "left" });

          doc.addImage(img, 5, 5, 50, 20);
          doc.setFontSize(9);

          // doc.setFillColor("E2F7DA");
          // doc.rect(5, 30, 200, 28, 'F');

          doc.setFont("RobotoNormal");
          doc.text("Empresa: ", 8, 35, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["empresa_clave"] ?? "", 22, 35, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Cliente: ", 8, 40, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["cliente"] ?? "", 20, 40, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Tipo de Falla: ", 70, 35, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["tipo_falla"] ?? "", 89, 35, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Realizado el: ", 140, 35, { align: "left" });

          var datefechafalla = new Date(dataReporte["fecha_mod"]);
          var fechafalla = datefechafalla.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

          doc.setFont("RobotoRegular");
          doc.text(fechafalla, 158, 35, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Fecha de Falla: ", 70, 40, { align: "left" });

          var datefecha = new Date(dataReporte["fecha_mod"]);
          var fechafallas = datefecha.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

          doc.setFont("RobotoRegular");
          doc.text(fechafallas ?? "", 92, 40, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Estatus: ", 140, 40, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["estatus_reporte_falla"] ?? "", 152, 40, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Orden de Trabajo: ", 70, 45, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["orden_trabajo_folio"] ?? "", 80, 45, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Reportado por: ", 140, 45, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["iniciales"] ?? "", 162, 45, { align: "left" });

          var value = 50;
          if (dataReporte["id_equipo_empresa"] != null) {
            doc.setFont("RobotoRegular");
            doc.setFontSize(12);
            doc.text("Información del equipo", 8, value, { align: "left" });
            value += 5;
            doc.setFontSize(10);
            doc.text(dataReporte["equipo_empresa_nombre"] ?? "", 8, value, { align: "left" });
            doc.setFontSize(9);

            doc.setFont("RobotoNormal");
            doc.text("Parte de: ", 140, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_padre_nombre"] ?? "", 153, value, { align: "left" });

            value += 5;

            doc.setFont("RobotoNormal");
            doc.text("Marca: ", 8, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["marca"] ?? "", 18, value, { align: "left" });

            doc.setFont("RobotoNormal");
            doc.text("Modelo: ", 70, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_modelo"] ?? "", 82, value, { align: "left" });

            doc.setFont("RobotoNormal");
            doc.text("No. Serie: ", 140, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_serie"] ?? "", 154, value, { align: "left" });

            value += 5;

            doc.setFont("RobotoNormal");
            doc.text("No. Activo: ", 8, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_numero_activo"] ?? "", 24, value, { align: "left" });

            doc.setFont("RobotoNormal");
            doc.text("Referencia: ", 70, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_numero_referencia"] ?? "", 86, value, { align: "left" });

            value += 10;
          }

          doc.setFont("RobotoNormal");
          doc.setFontSize(12);
          doc.text("Título: ", 8, value, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["titulo"] ?? "", 20, value, { align: "left" });

          value += 10;

          doc.setFont("RobotoNormal");
          doc.text("Descripción de la falla: ", 8, value, { align: "left" });

          value += 5;

          doc.setFont("RobotoRegular");
          doc.setFontSize(9);
          let desfalla = ((dataReporte["descripcion"] ?? ""));
          let splitText = doc.splitTextToSize(desfalla, 188);
          doc.text(splitText, 8, value, { align: "left" });

          value += 5;

          value += (splitText.length * 3.1);




          var dataimgevidencia = dataReporte["evidencias"];

          let imgposicion = value + 5;
          let textToSizeDes = 0;

          var evidenciasimg = dataimgevidencia.filter(ele => ele.id_tipo_archivo === 6);

          if (evidenciasimg.length > 0) {
            doc.setFont("RobotoNormal");
            doc.setFontSize(12);
            doc.text("Archivos: ", 8, value, { align: "left" });
          }

          evidenciasimg.forEach((imgEvidencia, index) => {
            var img = new Image;
            var imgCotizacion = new Image;

            imgCotizacion.src = require("../assets/cotizacion.png");

            var datefechaimg = new Date(imgEvidencia["fecha_reg"]);
            var fechafallasimg = datefechaimg.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

            img.src = (imgEvidencia["url"] ?? "");
            // img.src = imgUrl; //Dev Local

            index += 1;

            if (imgposicion >= 200) {
              doc.addPage();
              imgposicion = 15; // Restart height position
            }

            if ((index % 3) == 1) {
              textToSizeDes = 0;
              doc.setFontSize(9);
              doc.addImage(img, 8, imgposicion, 50, 50);
              let desfalla = ((imgEvidencia["descripcion"] ?? ""));
              let splitText = doc.splitTextToSize(desfalla, 60);
              doc.text(splitText, 8, imgposicion + 55, { align: "left" });
              let textsize = (splitText.length * 3.1);
              textToSizeDes = textsize;
              doc.text(fechafallasimg, 8, imgposicion + (60 + textsize), { align: "left" });
              doc.text((imgEvidencia["iniciales"] ?? ""), 53, imgposicion + (60 + textsize), { align: "left" });
              if (imgEvidencia.cotizacion) {
                doc.addImage(imgCotizacion, 8, imgposicion + (62 + textsize));
              }
            }

            if ((index % 3) == 2) {
              doc.setFontSize(9);
              doc.addImage(img, 80, imgposicion, 50, 50);
              let desfalla = ((imgEvidencia["descripcion"] ?? ""));
              let splitText = doc.splitTextToSize(desfalla, 60);
              doc.text(splitText, 80, imgposicion + 55, { align: "left" });

              let textsize = (splitText.length * 3.1);
              if (textsize > textToSizeDes) {
                textToSizeDes = textsize;
              }
              doc.text(fechafallasimg, 80, imgposicion + (60 + textsize), { align: "left" });
              doc.text((imgEvidencia["iniciales"] ?? ""), 125, imgposicion + (60 + textsize), { align: "left" });
              if (imgEvidencia.cotizacion) {
                doc.addImage(imgCotizacion, 80, imgposicion + (62 + textsize));
              }
            }

            if ((index % 3) == 0) {
              doc.setFontSize(9);
              doc.addImage(img, 150, imgposicion, 50, 50);
              let desfalla = ((imgEvidencia["descripcion"] ?? ""));
              let splitText = doc.splitTextToSize(desfalla, 58);
              doc.text(splitText, 150, imgposicion + 55, { align: "left" });

              let textsize = (splitText.length * 3.1);
              if (textsize > textToSizeDes) {
                textToSizeDes = textsize;
              }
              doc.text(fechafallasimg, 150, imgposicion + (60 + textsize), { align: "left" });
              doc.text((imgEvidencia["iniciales"] ?? ""), 195, imgposicion + (60 + textsize), { align: "left" });
              if (imgEvidencia.cotizacion) {
                doc.addImage(imgCotizacion, 150, imgposicion + (62 + textsize));
              }
              imgposicion += (70 + textToSizeDes);
            }

          });
          value = imgposicion + 65 + textToSizeDes;


          //Dev
          window.open(
            URL.createObjectURL(doc.output("blob")),
            "_blank",
            "scrollbars=yes,location=yes"
          );
          setTimeout(() => {
            window.URL.revokeObjectURL(doc.output("bloburl"));
          }, 100);
        });
    },
    allowDeleting(e) {
      return !e.row.data.cliente;
    },
    allowUpdating(e) {
      return !e.row.data.cliente;
    }

  },
};
