
import DxTagBox from 'devextreme-vue/tag-box';
import DxSelectBox from 'devextreme-vue/select-box';
import GroupProductService from '../services/group-product-service';
import SpecialFieldService from '../services/special-field-service';

export default {
  components: {
    DxTagBox,
    DxSelectBox
  },
  data() {
    return {
      dataSourceGroupProducts: null,
      dataSourceSpecialFields: null,
      specialFields: [],      
    };
  },
  created() {
    this.groupProductService = new GroupProductService();
    this.specialFieldService = new SpecialFieldService();
  },
  mounted() {    
    this.groupProductService.getGrupoProductos().then(data => this.dataSourceGroupProducts=data);
    this.specialFieldService.getCampos().then((data) => {
      data.forEach(element => {
        console.log(element);
        this.specialFields.push(element.nombre);
      });
      this.dataSourceSpecialFields=this.specialFields;
    });
  },
};
