
import {
  DxDataGrid, DxColumn, DxPaging, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxLookup, DxEditing, DxColumnChooser, DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import AlertService from '../services/alert-service';
import SeverityService from '../services/severity-service';
import GroupAlertService from '../services/group-alert-service';
import AlertOriginService from '../services/alert-origin-service';
import auth from '../auth';

export default {
  components: {
    DxDataGrid,
    DxColumn,    
    DxPaging,
    DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxLookup, DxEditing, DxColumnChooser,
    DxToast,
    DxGroupPanel,
    DxGrouping,
  },
  data() {
    return {
      dataSourceActiveAlertOrigin: null,
      dataSourceActiveAlertGroup: null,
      dataSourceActiveAlertSeverity: null,
      dataSourceActiveAlerts: null,
      alertas: {},
      events: [],
      severidad: null,
      alertService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      mode: 'select',
      usuarioId: null,
      autoExpandAll: false,
    };
  },
  created() {
    this.alertService = new AlertService();
    this.severityService = new SeverityService();
    this.groupAlertService = new GroupAlertService();
    this.alertOriginService = new AlertOriginService();
  },
  mounted() {
    this.severityService.getSeveridadAlertas().then(data => this.dataSourceActiveAlertSeverity=data);
    this.groupAlertService.getGrupoAlertas().then(data => this.dataSourceActiveAlertGroup=data);
    this.alertOriginService.getOrigenAlertas().then(data => this.dataSourceActiveAlertOrigin=data);
    auth.getUser().then((data) => {
      this.usuarioId = data.data.id_usuario;
      this.alertService.getAlertasActivas(data.data.id_usuario).then(data => this.dataSourceActiveAlerts=data);
    });
    
  },
  methods: {
    editActiveAlert(e) {
      this.alertService.updateReconocerAlerta({"id_alerta": e.data.id_alerta, "id_usuario": this.usuarioId}).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.alertService.getAlertasActivas(this.usuarioId).then(data => this.dataSourceActiveAlerts=data);
      });
		},
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'folio' || e.dataField === 'empresa' || e.dataField === 'fecha' || 
      e.dataField === 'clave_equipo' || e.dataField === 'equipo' || e.dataField === 'descripcion' || e.dataField === 'ubicacion' ||
      e.dataField === 'id_severidad_alerta' || e.dataField === 'id_origen_alerta' || e.dataField === 'id_grupo_alerta'))  {
        e.editorOptions.disabled = true;
      }
    },
  }
};
