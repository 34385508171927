import axios from 'axios';

export default class DepartmentService {

	getDepartamentos() {		
        const url = process.env.VUE_APP_APIURL + `departamentos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getDepartamento(departamento) {
        const url = process.env.VUE_APP_APIURL + `departamentos/${departamento.id_departamento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateDepartamento(departamento){
        const url = process.env.VUE_APP_APIURL + `departamentos/${departamento.id_departamento}`;
		return axios.put(url,departamento).then(res=>res.data.data);
	}

	createDepartamento(departamento){
        const url = process.env.VUE_APP_APIURL + `departamentos`;
		return axios.post(url,departamento).then(res=>res.data.data);
	}

	deleteDepartamento(departamento){
        const url = process.env.VUE_APP_APIURL + `departamentos/${departamento.id_departamento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}