import axios from 'axios';

export default class EquipmentCategoryTaskService {
	
	getEquipoCategoriaTareasPorCategoria(equipoCategoriaId) {
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareas/equipocategorias/${equipoCategoriaId}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCategoriaTareas() {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareas`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCategoriaTareasTareas() {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareas/tareas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoCategoriaTarea(equipocategoriatarea) {
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareas/${equipocategoriatarea.id_equipo_categoria_tarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoCategoriaTarea(equipocategoriatarea){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareas/${equipocategoriatarea.id_equipo_categoria_tarea}`;
		return axios.put(url,equipocategoriatarea).then(res=>res.data.data);
	}

	createEquipoCategoriaTarea(equipocategoriatarea){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareas`;
		return axios.post(url,equipocategoriatarea).then(res=>res.data.data);
	}

	deleteEquipoCategoriaTarea(equipocategoriatarea){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareas/${equipocategoriatarea.id_equipo_categoria_tarea}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}