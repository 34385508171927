import axios from 'axios';

export default class EquipmentCategoryMeasurementService {
    
	getEquipoCategoriaMedicionesPorTarea(equipoCategoriaTareaId) {
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareamediciones/equipocategoriatareas/${equipoCategoriaTareaId}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCategoriaMediciones() {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareamediciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCategoriaMedicionesMediciones() {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareamediciones/mediciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoCategoriaMedicion(equipocategoriamedicion) {
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareamediciones/${equipocategoriamedicion.id_equipo_categoria_tarea_medicion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoCategoriaMedicion(equipocategoriamedicion){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareamediciones/${equipocategoriamedicion.id_equipo_categoria_tarea_medicion}`;
		return axios.put(url,equipocategoriamedicion).then(res=>res.data.data);
	}

	createEquipoCategoriaMedicion(equipocategoriamedicion){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareamediciones`;
		return axios.post(url,equipocategoriamedicion).then(res=>res.data.data);
	}

	deleteEquipoCategoriaMedicion(equipocategoriamedicion){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareamediciones/${equipocategoriamedicion.id_equipo_categoria_tarea_medicion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}