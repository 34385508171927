import axios from 'axios';

export default class RolePermitService {
  getPermisosAsignadosPorRol(rolId) {
    const url = process.env.VUE_APP_APIURL + `rolpermisos/asignados/${rolId}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getPermisosDisponiblesPorRol(rolId) {
    const url = process.env.VUE_APP_APIURL + `rolpermisos/disponibles/${rolId}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getRolPermiso(rolpermiso) {
    const url =
      process.env.VUE_APP_APIURL + `/rolpermisos/${rolpermiso.id_rol_permiso}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateRolPermiso(rolpermiso) {
    const url =
      process.env.VUE_APP_APIURL + `rolpermisos/${rolpermiso.id_rol_permiso}`;
    return axios.put(url, rolpermiso).then((res) => res.data.data);
  }

  createRolPermiso(rolpermiso) {
    const url = process.env.VUE_APP_APIURL + `/rolpermisos`;
    return axios.post(url, rolpermiso).then((res) => res.data.data);
  }

  deleteRolPermiso(rolpermiso) {
    const url =
      process.env.VUE_APP_APIURL + `/rolpermisos/${rolpermiso.id_rol_permiso}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getRolPermisoPorClave(rolpermiso) {
    const url = process.env.VUE_APP_APIURL + `/rolpermisos/clave`;
    return axios.get(url, { params: rolpermiso }).then((res) => res.data.data);
  }
}