import axios from 'axios';

export default class WarrantyRequestService {

	getSolicitudGarantias() {			
        const url = process.env.VUE_APP_APIURL + `solicitudgarantias`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getSolicitudGarantia(solicitudgarantia) {
        const url = process.env.VUE_APP_APIURL + `/solicitudgarantias/${solicitudgarantia.id_solicitud_garantia}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateSolicitudGarantia(solicitudgarantia){
        const url = process.env.VUE_APP_APIURL + `/solicitudgarantias/${solicitudgarantia.id_solicitud_garantia}`;
		return axios.put(url,solicitudgarantia).then(res=>res.data.data);
	}

	createSolicitudGarantia(solicitudgarantia){
        const url = process.env.VUE_APP_APIURL + `/solicitudgarantias`;
		return axios.post(url,solicitudgarantia).then(res=>res.data.data);
	}

	deleteSolicitudGarantia(solicitudgarantia){
        const url = process.env.VUE_APP_APIURL + `/solicitudgarantias/${solicitudgarantia.id_solicitud_garantia}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}