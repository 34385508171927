

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import StockTransfer from './stock-transfer.vue';
import StockTransferCreate from './stock-transfer-create.vue';
import Stock from './stock.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    StockTransferCreate,
    StockTransfer,
    Stock,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
