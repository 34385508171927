
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxEditing,
    DxSorting,
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import DxButton from 'devextreme-vue/button';

import ConfigSurveyService from '../services/config-survey-service';
import ClientContactService from '../services/client-contact-service';
import SupplierContactService from '../services/supplier-contact-service';
import UserService from '../services/user-service';
import DestinationSurveyConfigService from '../services/destination-survey-config-service';

export default {
    components: {
        DxSelectBox,
        DxButton,
        Button,
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxSelection,
        DxEditing,
        DxSorting,
    },
    data() {
        return {
            dataSourceConfigSurvey: null,
            dataSourceAvailable: [],
            dataSourceAssigned: [],
            dataSourceUserType: [
                { id: 1, tipousuario: 'Cliente' },
                { id: 2, tipousuario: 'Proveedor' },
                { id: 3, tipousuario: 'Usuario' },
            ],
            configSurvey: null,
            userType: null,
            user: null,
            selectedRowsData: [],
        }
    },
    created() {
        this.configSurveyService = new ConfigSurveyService();
        this.clientContactService = new ClientContactService();
        this.supplierContactService = new SupplierContactService();
        this.userService = new UserService();
        this.destinationSurveyConfigService = new DestinationSurveyConfigService();
    },
    mounted() {
        this.refreshDataGrid();
    },
    computed: {
        selectedRowKeys() {
            return this.selectedRowsData.map((data) => data.id);
        },
    },
    methods: {
        onSelectionChanged({ selectedRowsData }) {
            this.selectedRowsData = selectedRowsData;
        },
        refreshDataGrid() {
            this.configSurveyService.getConfigSurveySendEmail().then(data => this.dataSourceConfigSurvey = data);
        },
        valueChangedConfigSurvey() {
            if (this.userType != null) {
                this.getDataConfigSurvey()
            }
        },
        valueChangedUserType() {
            if (this.configSurvey != null) {
                this.getDataConfigSurvey();
            }
        },
        getDataConfigSurvey() {
            this.destinationSurveyConfigService.getDestinationSurveyConfigAvailable(this.configSurvey.id_config_encuesta, this.userType.id).then(data => {
                this.dataSourceUser = [];
                let datauser = [];
                if (this.userType.id == 1) {

                    for (const keydata in data) {
                        if (data[keydata].activo == 1) {
                            let item = {
                                id: data[keydata].id_cliente_contacto,
                                nombre: data[keydata].contacto
                            };
                            datauser.push(item);
                        }
                    }
                    this.dataSourceAvailable = datauser;
                }
                if (this.userType.id == 2) {
                    for (const keydata in data) {
                        if (data[keydata].activo == 1) {
                            let item = {
                                id: data[keydata].id_proveedor_contacto,
                                nombre: data[keydata].contacto
                            };
                            datauser.push(item);
                        }
                    }
                    this.dataSourceAvailable = datauser;
                }
                if (this.userType.id == 3) {
                    for (const keydata in data) {
                        if (data[keydata].activo == 1) {
                            let item = {
                                id: data[keydata].id_usuario,
                                nombre: data[keydata].nombre + ' ' + data[keydata].apellido_paterno + data[keydata].apellido_materno + '  ' + '(' + data[keydata].iniciales + ')'
                            };
                            datauser.push(item);
                        }
                    }
                    this.dataSourceAvailable = datauser;
                }
            });

            this.destinationSurveyConfigService.getDestinationSurveyConfigAssigned(this.configSurvey.id_config_encuesta, this.userType.id).then(data => {
                this.dataSourceAssigned = [];
                let datauser = [];
                if (this.userType.id == 1) {

                    for (const keydata in data) {
                        if (data[keydata].activo == 1) {
                            let item = {
                                id: data[keydata].id_config_encuesta_destino,
                                nombre: data[keydata].contacto
                            };
                            datauser.push(item);
                        }
                    }
                    this.dataSourceAssigned = datauser;
                }
                if (this.userType.id == 2) {
                    for (const keydata in data) {
                        if (data[keydata].activo == 1) {
                            let item = {
                                id: data[keydata].id_config_encuesta_destino,
                                nombre: data[keydata].contacto
                            };
                            datauser.push(item);
                        }
                    }
                    this.dataSourceAssigned = datauser;
                }
                if (this.userType.id == 3) {
                    for (const keydata in data) {
                        if (data[keydata].activo == 1) {
                            let item = {
                                id: data[keydata].id_config_encuesta_destino,
                                nombre: data[keydata].nombre + ' ' + data[keydata].apellido_paterno + data[keydata].apellido_materno + '  ' + '(' + data[keydata].iniciales + ')'
                            };
                            datauser.push(item);
                        }
                    }
                    this.dataSourceAssigned = datauser;
                }
            });
        },
        addConfigSurvey() {
            for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
                let datajson = null;
                if (this.userType.id == 1) {
                    datajson = {
                        id_config_encuesta: this.configSurvey.id_config_encuesta,
                        id_cliente_contacto: this.selectedRowsData[this.i].id
                    }
                }
                if (this.userType.id == 2) {
                    datajson = {
                        id_config_encuesta: this.configSurvey.id_config_encuesta,
                        id_proveedor_contacto: this.selectedRowsData[this.i].id
                    }
                }
                if (this.userType.id == 3) {
                    datajson = {
                        id_config_encuesta: this.configSurvey.id_config_encuesta,
                        id_usuario: this.selectedRowsData[this.i].id
                    }
                }

                this.destinationSurveyConfigService.createDestinationSurveyConfig(datajson).then(() => {
                    if (this.i == this.selectedRowsData.length) {
                        this.getDataConfigSurvey()
                    }
                })


            }

        },
        deleteconfigSurveyDestination(e) {
            e.data.id_config_encuesta_destino = e.data.id;
            this.destinationSurveyConfigService.deleteDestinationSurveyConfig(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha eliminar correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.getDataConfigSurvey()
            });
        }
    },
}
