
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxForm, DxItem, DxButton, DxMasterDetail
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxFileUploader from 'devextreme-vue/file-uploader';
import MasterDetail from './company-detail.vue';
import CompanyService from '../services/company-service';


const fileUploaderRef = "fu";
const imgRef = "img"

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxFileUploader,
    DxForm,
    DxItem,
    DxButton,
    DxMasterDetail,
    MasterDetail
  },
  data() {
    return {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
      dataSourceCompany: null,
      empresa: {},
      events: [],
      companyService: null,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Empresa',
        showTitle: true,
        width: 600,
      },
      backendURL: process.env.VUE_APP_APIURL,
      imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
      imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
      retryButtonVisible: false,
      fileUploaderRef,
      imgRef,
    };
  },
  created() {
    this.companyService = new CompanyService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompany = data);
  },
  computed: {
    fileUploader: function () {
      return this.$refs[fileUploaderRef].instance;
    },
    imageElement: function () {
      return this.$refs[imgRef];
    }
  },
  methods: {
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onClick() {
      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
    },
    onValueChanged(e) {
      let reader = new FileReader();
      reader.onload = (args) => {
        this.imageElement.setAttribute('src', args.target.result);
      }
      reader.readAsDataURL(e.value[0]); // convert to base64 string
    },
    onUploaded(e, cellInfo) {
      //console.log(e);
      let fileObject = JSON.parse(e.request.responseText);
      //console.log(fileObject.data.image_name);
      //console.log(cellInfo);
      //cellInfo.setValue("archivos/fotos/" + e.request.responseText);
      cellInfo.setValue(fileObject.data.image_url);
      this.retryButtonVisible = false;
    },
    onUploadError(e) {
      let xhttp = e.request;
      if (xhttp.status === 400) {
        e.message = e.error.responseText;
      }
      if (xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
      this.retryButtonVisible = true;
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editCompany(e) {
      this.companyService.updateEmpresa(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    deleteCompany(e) {
      this.companyService.deleteEmpresa(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    createCompany(e) {
      this.companyService.createEmpresa(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
  },
};
