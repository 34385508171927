
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import TypeFileService from '../services/type-file-service';
import TypeItemService from '../services/type-item-service';
import TypeItemMovService from '../services/type-item-mov-service';
import TypeControlService from '../services/type-control-service';
import TypeDataService from '../services/type-data-service';
import TypeDocumentService from '../services/type-document-service';
import TypeEquipmentMovService from '../services/type-equipment-mov-service';
import TypeEventService from '../services/type-event-service';
import TypeFailureService from '../services/type-failure-service';
import TypeKpiService from '../services/type-kpi-service';
import TypeGeofenceEventService from '../services/type-geofence-event-service';
import TypePhysicalInventoryService from '../services/type-physical-inventory-service';
import TypeKitService from '../services/type-kit-service';
import TypeLayoutService from '../services/type-layout-service';
import TypeLogService from '../services/type-log-service';
import TypeMaintenanceService from '../services/type-maintenance-service';
import TypeMeasurementService from '../services/type-measurement-service';
import TypeMovementService from '../services/type-movement-service';
import TypeNotificationService from '../services/type-notification-service';
import TypeWorkOrderService from '../services/type-workorder-service';
import TypeProcedureService from '../services/type-procedure-service';
import TypeAcknowledgeService from '../services/type-acknowledge-service';
import TypeFindingService from '../services/type-finding-service';
import TypeMajorMinorService from '../services/type-major-minor-service';
import TypeOperationUnitService from '../services/type-operation-unit-service';
import TypeChecklistService from '../services/type-checklist-service';
import TypeWarrantyService from '../services/type-warranty-service';
import TypeServiceRequestService from '../services/type-service-request-service';
import TypeRequisitionService from '../services/type-requisition-service';
import TypePurchaseOrderService from '../services/type-purchase-order-service';


import { tipos } from '../data/data.js';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxSelectBox
  },
  props: {
    typeId: {
      type: Number,
      default: null,
    },
  },
  data() {

    return {
      dataSourceType: null,
      dataSourceTypesCombo: tipos,
      typeSelect: null,
      idTipo: null,
      grupo: {},
      events: [],
      clave: false,
      icono: false,
      entradasalida: false,
      typeService: null,
      isVisible: false,
      message: '',
      type: 'success',
      chosenTypeId: null,
      typeFileService: null,
      typeItemService: null,
      typeItemMovService: null,
      typeControlService: null,
      typeDataService: null, 
      typeDocumentService: null,
      typeEquipmentMovService: null,
      typeEventService: null,
      typeFailureService: null,
      typeKpiService: null,
      typeGeofenceEventService: null,
      typePhysicalInventoryService: null,
      typeKitService: null,
      typeLayoutService: null,
      typeLogService: null,
      typeMaintenanceService: null,
      typeMeasurementService: null,
      typeMovementService: null,
      typeNotificationService: null,
      typeWorkOrderService: null,
      typeProcedureService: null,
      typeAcknowledgeService: null,
      typeFindingService: null,
      typeMajorMinorService: null,
      typeOperationUnitService: null,
      typeChecklistService: null,
      typeWarrantyService: null,
      typeServiceRequestService: null,
      typeRequisitionService: null,
      TypePurchaseOrderService: null
    };
  },
  created() {
    this.typeFileService = new TypeFileService();
    this.typeItemService = new TypeItemService();
    this.typeItemMovService = new TypeItemMovService();
    this.typeControlService = new TypeControlService();
    this.typeDataService = new TypeDataService();
    this.typeEquipmentMovService = new TypeEquipmentMovService();
    this.typeDocumentService = new TypeDocumentService();
    this.typeEventService = new TypeEventService();
    this.typeFailureService = new TypeFailureService();
    this.typeKpiService = new TypeKpiService();
    this.typeGeofenceEventService = new TypeGeofenceEventService();
    this.typePhysicalInventoryService = new TypePhysicalInventoryService();
    this.typeKitService = new TypeKitService();
    this.typeLayoutService = new TypeLayoutService();
    this.typeLogService = new TypeLogService();
    this.typeMaintenanceService = new TypeMaintenanceService();
    this.typeMeasurementService = new TypeMeasurementService();
    this.typeMovementService = new TypeMovementService();
    this.typeNotificationService = new TypeNotificationService();
    this.typeWorkOrderService = new TypeWorkOrderService();
    this.typeProcedureService = new TypeProcedureService();
    this.typeAcknowledgeService = new TypeAcknowledgeService();
    this.typeFindingService = new TypeFindingService();
    this.typeMajorMinorService = new TypeMajorMinorService();
    this.typeOperationUnitService = new TypeOperationUnitService();
    this.typeChecklistService = new TypeChecklistService();
    this.typeWarrantyService = new TypeWarrantyService();
    this.typeServiceRequestService = new TypeServiceRequestService();
    this.typeRequisitionService = new TypeRequisitionService();
    this.typePurchaseOrderService = new TypePurchaseOrderService();
  },
  methods: {
    valueChanged(e) {
        const newType = e.value;
        switch (newType.idTipo) {
          case 1:
            this.dataSourceType = [];
            this.idTipo = 'id_tipo_archivo';
            this.clave = false;
            this.icono = true;
            this.entradasalida = false;
            this.typeFileService.getTipoArchivos().then(data => this.dataSourceType=data);
            break;
          case 2:
            this.dataSourceType = [];
            this.idTipo = 'id_tipo_articulo';
            this.clave = true;
            this.icono = true;
            this.entradasalida = false;
            this.typeItemService.getTipoArticulos().then(data => this.dataSourceType=data);
            break;
          case 3:
            this.dataSourceType = [];
            this.idTipo = 'id_tipo_control';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeControlService.getTipoControles().then(data => this.dataSourceType=data);
            break;
          case 4:
            this.dataSourceType = [];
            this.idTipo = 'id_tipo_dato';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeDataService.getTipoDatos().then(data => this.dataSourceType=data);
            break;
          case 5:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_documento';
            this.clave = false;
            this.icono = true;
            this.entradasalida = false;  
            this.typeDocumentService.getTipoDocumentos().then(data => this.dataSourceType=data);
            break;

          case 6:
            this.dataSourceType = [];
            this.idTipo = 'id_tipo_evento';
            this.clave = true;
            this.icono = false;
            this.entradasalida = false;
            this.typeEventService.getTipoEventos().then(data => this.dataSourceType=data);
            break;
          case 7:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_geocerca_evento';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeGeofenceEventService.getTipoGeocercaEventos().then(data => this.dataSourceType=data);
            break;
          case 8:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_falla';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeFailureService.getTipoFallas().then(data => this.dataSourceType=data);
            break;
          case 9:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_kpi';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeKpiService.getTipoKPIs().then(data => this.dataSourceType=data);
            break;
          case 10:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_inventario_fisico';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typePhysicalInventoryService.getTipoInventarioFisicos().then(data => this.dataSourceType=data);
            break;
          case 12:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_layout';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeLayoutService.getTipoLayouts().then(data => this.dataSourceType=data);
            break;
          case 13:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_log';
            this.clave = false;
            this.icono = false; 
            this.entradasalida = false; 
            this.typeLogService.getTipoLogs().then(data => this.dataSourceType=data);
            break;
          case 15:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_mantenimiento';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeMaintenanceService.getTipoMantenimientos().then(data => this.dataSourceType=data);
            break;
          case 16:
            this.dataSourceType = [];
            this.idTipo = 'id_tipo_movimiento';
            this.clave = true;
            this.icono = false;
            this.entradasalida = true;
            this.typeMovementService.getTipoMovimientos().then(data => this.dataSourceType=data);
            break;
          case 17:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_articulo_movimiento';
            this.clave = true;
            this.icono = false;
            this.entradasalida = true;  
            this.typeItemMovService.getTipoArticuloMovimientos().then(data => this.dataSourceType=data);
            break;
          case 18:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_equipo_movimiento';
            this.clave = true;
            this.icono = false;
            this.entradasalida = true;  
            this.typeEquipmentMovService.getTipoEquipoMovimientos().then(data => this.dataSourceType=data);
            break;
          case 19:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_notificacion';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeNotificationService.getTipoNotificaciones().then(data => this.dataSourceType=data);
            break;
          case 20:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_mayor_menor';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeMajorMinorService.getTipoMayorMenores().then(data => this.dataSourceType=data);
            break;
          case 21:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_procedimiento';
            this.clave = false;
            this.icono = true;
            this.entradasalida = false;  
            this.typeProcedureService.getTipoProcedimientos().then(data => this.dataSourceType=data);
            break;
          case 22:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_reconocimiento';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeAcknowledgeService.getTipoReconocimientos().then(data => this.dataSourceType=data);
            break;
          case 23:            
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_hallazgo';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeFindingService.getTipoHallazgos().then(data => this.dataSourceType=data);
            break;
          case 24:
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_unidad_operacion';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeOperationUnitService.getTipoUnidadOperaciones().then(data => this.dataSourceType=data);
            break;
          case 25:
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_lista_verificacion';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeChecklistService.getTipoListaVerificaciones().then(data => this.dataSourceType=data);
            break;
          case 26:
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_garantia';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeWarrantyService.getTipoGarantias().then(data => this.dataSourceType=data);
            break;
          case 27:
            this.dataSourceType = [];        
            this.idTipo = 'id_tipo_solicitud_servicio';
            this.clave = false;
            this.icono = false;
            this.entradasalida = false;  
            this.typeServiceRequestService.getTipoSolicitudServicios().then(data => this.dataSourceType=data);
            break;
          case 28:
            this.dataSourceType = [];
            this.idTipo = 'id_tipo_requisicion';
            this.clave = true;
            this.icono = false;
            this.entradasalida = false;
            this.typeRequisitionService.getTipoRequisiciones().then(data => this.dataSourceType=data);
            break;
          case 29:
            this.dataSourceType = [];
            this.idTipo = 'id_tipo_orden_compra';
            this.clave = true;
            this.icono = false;
            this.entradasalida = false;
            this.typePurchaseOrderService.getTipoOrdenCompras().then(data => this.dataSourceType=data);
            break;
        }
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editType(e) {    
      switch (this.typeSelect.idTipo) {
          case 1:
            this.typeFileService.updateTipoArchivo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 2:            
            this.typeItemService.updateTipoArticulo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 3:            
            this.typeControlService.updateTipoControl(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 4:            
            this.typeDataService.updateTipoDato(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 5:
            this.typeDocumentService.updateTipoDocumento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 6:
            this.typeEventService.updateTipoEvento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 7:            
            this.typeGeofenceEventService.updateTipoGeocercaEvento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 8:            
            this.typeFailureService.updateTipoFalla(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 9:            
            this.typeKpiService.updateTipoKPI(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 10:
            this.typePhysicalInventoryService.updateTipoInventarioFisico(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 11:
            this.typeKitService.updateTipoKit(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 12:            
            this.typeLayoutService.updateTipoLayout(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 13:            
            this.typeFindingService.updateTipoLog(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 14:            
            this.typeMeasurementService.updateTipoMedicion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 15:
            this.typeMaintenanceService.updateTipoMantenimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 16:
            this.typeMovementService.updateTipoMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 17:            
            this.typeItemMovService.updateTipoArticuloMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 18:            
            this.typeEquipmentMovService.updateTipoEquipoMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 19:            
            this.typeNotificationService.updateTipoNotificacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 20:
            this.typeMajorMinorService.updateTipoMayorMenor(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 21:            
            this.typeProcedureService.updateTipoProcedimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 22:
            this.typeAcknowledgeService.updateTipoReconocimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 23:
            this.typeFindingService.updateTipoHallazgo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 24:
            this.typeOperationUnitService.updateTipoUnidadOperacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 25:
            this.typeChecklistService.updateTipoListaVerificacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 26:
            this.typeWarrantyService.updateTipoGarantia(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 27:
            this.typeServiceRequestService.updateTipoSolicitudServicio(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 28:
            this.typeRequisitionService.updateTipoRequisicion(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 29:
            this.typePurchaseOrderService.updateTipoOrdenCompra(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
        }   			
		},
    deleteType(e) {
      switch (this.typeSelect.idTipo) {
        case 1:
            this.typeFileService.deleteTipoArchivo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 2:            
            this.typeItemService.deleteTipoArticulo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 3:            
            this.typeControlService.deleteTipoControl(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 4:            
            this.typeDataService.deleteTipoDato(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 5:
            this.typeDocumentService.deleteTipoDocumento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 6:
            this.typeEventService.deleteTipoEvento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 7:            
            this.typeGeofenceEventService.deleteTipoGeocercaEvento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 8:            
            this.typeFailureService.deleteTipoFalla(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 9:            
            this.typeKpiService.deleteTipoKPI(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 10:
            this.typePhysicalInventoryService.deleteTipoInventarioFisico(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 11:
            this.typeKitService.deleteTipoKit(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 12:            
            this.typeLayoutService.deleteTipoLayout(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 13:            
            this.typeFindingService.deleteTipoLog(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 14:            
            this.typeMeasurementService.deleteTipoMedicion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 15:
            this.typeMaintenanceService.deleteTipoMantenimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 16:
            this.typeMovementService.deleteTipoMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 17:            
            this.typeItemMovService.deleteTipoArticuloMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 18:            
            this.typeEquipmentMovService.deleteTipoEquipoMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 19:            
            this.typeNotificationService.deleteTipoNotificacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 20:
            this.typeMajorMinorService.deleteTipoMayorMenor(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 21:            
            this.typeProcedureService.deleteTipoProcedimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 22:
            this.typeAcknowledgeService.deleteTipoReconocimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 23:
            this.typeFindingService.deleteTipoHallazgo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 24:
            this.typeOperationUnitService.deleteTipoUnidadOperacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 25:            
            this.typeChecklistService.deleteTipoListaVerificacion(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 26:
            this.typeWarrantyService.deleteTipoGarantia(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 27:
            this.typeServiceRequestService.deleteTipoSolicitudServicio(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 28:
            this.typeRequisitionService.deleteTipoRequisicion(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
          case 29:
            this.typePurchaseOrderService.deleteTipoOrdenCompra(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha eliminado el registro';
              this.isVisible = true;
            });
            break;
      }
			
		},
    createType(e) {
      switch (this.typeSelect.idTipo) {
        case 1:
            this.typeFileService.createTipoArchivo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 2:            
            this.typeItemService.createTipoArticulo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 3:            
            this.typeControlService.createTipoControl(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 4:            
            this.typeDataService.createTipoDato(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 5:
            this.typeDocumentService.createTipoDocumento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 6:
            this.typeEventService.createTipoEvento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 7:            
            this.typeGeofenceEventService.createTipoGeocercaEvento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 8:            
            this.typeFailureService.createTipoFalla(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 9:            
            this.typeKpiService.createTipoKPI(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 10:
            this.typePhysicalInventoryService.createTipoInventarioFisico(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 11:
            this.typeKitService.createTipoKit(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 12:            
            this.typeLayoutService.createTipoLayout(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 13:            
            this.typeFindingService.createTipoLog(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 14:            
            this.typeMeasurementService.createTipoMedicion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 15:
            this.typeMaintenanceService.createTipoMantenimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 16:
            this.typeMovementService.createTipoMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 17:            
            this.typeItemMovService.createTipoArticuloMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 18:            
            this.typeEquipmentMovService.createTipoEquipoMovimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 19:            
            this.typeNotificationService.createTipoNotificacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 20:
            this.typeMajorMinorService.createTipoMayorMenor(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 21:            
            this.typeProcedureService.createTipoProcedimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 22:
            this.typeAcknowledgeService.createTipoReconocimiento(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 23:
            this.typeFindingService.createTipoHallazgo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 24:
            this.typeOperationUnitService.createTipoUnidadOperacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 25:
            this.typeChecklistService.createTipoListaVerificacion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 26:
            this.typeWarrantyService.createTipoGarantia(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 27:
            this.typeServiceRequestService.createTipoSolicitudServicio(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 28:
            this.typeRequisitionService.createTipoRequisicion(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
          case 29:
            this.typePurchaseOrderService.createTipoOrdenCompra(e.data).then(() => {
              this.type = 'success';
              this.message = 'Se ha creado el registro';
              this.isVisible = true;
            });
            break;
      }
		},
  },
};
