
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';

import EquipmentCategoryService from '../services/equipment-category-service';
import EquipmentCategoryTaskService from '../services/equipment-category-task-service';
import EquipmentCategoryItemService from '../services/equipment-category-item-service';
import ItemService from '../services/item-service';
import BrandService from '../services/brand-service';
import GroupItemService from '../services/group-item-service';
import TypeItemService from '../services/type-item-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSelectBox,
    DxSelection,
    DxSorting,   
  },
  data() {
    return {
      item: {},      
      dataSourceConfigItems: null,
      dataSourceEquipmentCategories: null,
      dataSourceEquipmentCategoryTasks: null,
      dataSourceGroupItems: null,      
      dataSourceBrands: null,
      dataSourceItemTypes: null,
      dataSourceItems: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Artículos por tarea',
        showTitle: true,
        height: 500,
        width: 700,
      },      
      mode: 'select',
      equipmentcategory: null,
      equipmentcategorytask: null,
    };
  },
  created() {    
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.equipmentCategoryTaskService = new EquipmentCategoryTaskService();
    this.itemService = new ItemService();
    this.brandService = new BrandService();
    this.typeItemService = new TypeItemService();
    this.groupItemService = new GroupItemService();
    this.equipmentCategoryItemService = new EquipmentCategoryItemService();
  },
  mounted() {
    this.typeItemService.getTipoArticulos().then(data => this.dataSourceItemTypes=data);
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
    this.groupItemService.getGrupoArticulos().then(data => this.dataSourceGroupItems=data);
    this.itemService.getArticulos().then(data => this.dataSourceItems=data);
  },
  methods: {
    valueChangedEquipmentCategory(e) {
      this.equipmentCategoryTaskService.getEquipoCategoriaTareasPorCategoria(e.value.id_equipo_categoria).then(data => this.dataSourceEquipmentCategoryTasks=data);
      this.dataSourceConfigItems = null;
      //this.equipmentcategorytask = null;
    },
    valueChangedEquipmentCategoryTask(e) {
      this.equipmentCategoryItemService.getEquipoCategoriaArticulosPorTarea(e.value.id_equipo_categoria_tarea).then(data => this.dataSourceConfigItems=data);        
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_equipo_categoria_tarea') {
        e.editorOptions.disabled = true;
      }
    },
    /*
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_grupo_tarea') {
        e.editorOptions.disabled = (typeof e.row.data.id_tarea !== 'number');
      }
    },
    setStateValue(rowData, value) {
      this.taskService.getTareasPorGrupo(value).then((data) => {
          this.dataSourceTasks=data;          
      });
      rowData.id_tarea = null;
      this.defaultSetCellValue(rowData, value);      
    },
    getFilteredTasks: (options) => ({
      filter: options.data ? ['id_grupo_tarea', '=', options.data.id_grupo_tarea] : null,
    }),
    */
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigItem(e) {
        this.equipmentCategoryItemService.updateEquipoCategoriaArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCategoryItemService.getEquipoCategoriaArticulosPorTarea(e.data.id_equipo_categoria_tarea).then(data => this.dataSourceConfigItems=data);
      });
    },
    deleteConfigItem(e) {
        this.equipmentCategoryItemService.deleteEquipoCategoriaArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createConfigItem(e) {
        this.equipmentCategoryItemService.createEquipoCategoriaArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentCategoryItemService.getEquipoCategoriaArticulosPorTarea(e.data.id_equipo_categoria_tarea).then(data => this.dataSourceConfigItems=data);
      });
    },    
    onInitNewRow(e) {
      e.data.id_equipo_categoria_tarea= this.equipmentcategorytask.id_equipo_categoria_tarea;
      e.data.activo = true;
      e.data.cantidad = 1;
    },
    /*
    onToolbarPreparing(e) {
    e.toolbarOptions.items.forEach((item) => {
        if (item.name=="addRowButton") {
            item.options.onClick = function () {  
            if (this.equipmentcategorytask!=null)  
                e.component.addRow();  
            }; 
        }
        //console.log(item, index)
    });
    }
    */
  },
};
