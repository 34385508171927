import axios from 'axios';

export default class TypeMeasurementService {

	getTipoMediciones() {			
        const url = process.env.VUE_APP_APIURL + `tipomediciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoMedicion(tipomedicion) {
        const url = process.env.VUE_APP_APIURL + `/tipomediciones/${tipomedicion.id_tipo_medicion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoMedicion(tipomedicion){
        const url = process.env.VUE_APP_APIURL + `/tipomediciones/${tipomedicion.id_tipo_medicion}`;
		return axios.put(url,tipomedicion).then(res=>res.data.data);
	}

	createTipoMedicion(tipomedicion){
        const url = process.env.VUE_APP_APIURL + `/tipomediciones`;
		return axios.post(url,tipomedicion).then(res=>res.data.data);
	}

	deleteTipoMedicion(tipomedicion){
        const url = process.env.VUE_APP_APIURL + `/tipomediciones/${tipomedicion.id_tipo_medicion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}