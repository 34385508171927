
  import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxEditing,
    DxSorting,
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';
  import DxSelectBox from 'devextreme-vue/select-box';
  import Button from 'devextreme-vue/button';
  
  import DocumentService from '../services/document-service';
  import ClientService from '../services/client-service';
  import DocumentClientService from '../services/document-client-service';
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxSelection,
      DxToast,
      DxSelectBox,
      Button,
      DxEditing,
      DxSorting,
    },
    data() {
      return {
        documents: null,
        dataSourceDocuments: null,
        dataSourceClientAvailable: null,
        dataSourceClientAssigned: null,
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        mode: 'select',
        selectedRowsData: [],
        i: 0,
      };
    },
    created() {
      this.documentService = new DocumentService();
      this.clientService = new ClientService();
      this.documentClientService = new DocumentClientService();
    },
    mounted() {
      this.documentService.getDocumentosCombo().then(data => this.dataSourceDocuments=data);  
    },
    computed: {
      selectedRowKeys() {
        return this.selectedRowsData.map((data) => data.id_cliente);
      },
    },
    methods: {
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      addDocument() {
        for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
            this.documentClientService.createDocumentoCliente({"id_documento": this.documents.id_documento, "id_cliente": this.selectedRowsData[this.i].id_cliente}).then(() => {           
              
              if (this.i==this.selectedRowsData.length) {
                this.documentClientService.getClienteDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceClientAvailable=data);
                this.documentClientService.getClienteAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceClientAssigned=data);
              }
              
            });
        }
      },
      onSelectionChanged({ selectedRowsData }) {
        this.selectedRowsData = selectedRowsData;
      },
      valueChangedDocument() {
        this.documentClientService.getClienteDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceClientAvailable=data);
        this.documentClientService.getClienteAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceClientAssigned=data);
      },
      deleteClient(e) {
        this.documentClientService.deleteDocumentoCliente(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.documentClientService.getClienteDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceClientAvailable=data);
          this.documentClientService.getClienteAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceClientAssigned=data);
        });
      },
    },  
  };
  