import axios from 'axios';

export default class ItemSupplierService {

	getProveedorAsignadosPorArticulo(articuloId) {		
        const url = process.env.VUE_APP_APIURL + `articuloproveedores/asignados/${articuloId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getProveedorDisponiblesPorArticulo(articuloId) {		
        const url = process.env.VUE_APP_APIURL + `articuloproveedores/disponibles/${articuloId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getArticuloProveedor(articuloproveedor) {
        const url = process.env.VUE_APP_APIURL + `/articuloproveedores/${articuloproveedor.id_articulo_proveedor}`;
		return axios.get(url).then(res=>res.data.data);
	}

    updateArticuloProveedor(articuloproveedor){
        const url = process.env.VUE_APP_APIURL + `articuloproveedores/${articuloproveedor.id_articulo_proveedor}`;
		return axios.put(url,articuloproveedor).then(res=>res.data.data);
	}

	createArticuloProveedor(articuloproveedor){
        const url = process.env.VUE_APP_APIURL + `/articuloproveedores`;
		return axios.post(url,articuloproveedor).then(res=>res.data.data);		
	}

	deleteArticuloProveedor(articuloproveedor){
        const url = process.env.VUE_APP_APIURL + `/articuloproveedores/${articuloproveedor.id_articulo_proveedor}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}