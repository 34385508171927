import axios from 'axios';

export default class AlgorithmService {

	getAlgoritmos() {				
        const url = process.env.VUE_APP_APIURL + `algoritmos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getAlgoritmo(algoritmo) {
        const url = process.env.VUE_APP_APIURL + `/algoritmos/${algoritmo.id_algoritmo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateAlgoritmo(algoritmo){
        const url = process.env.VUE_APP_APIURL + `/algoritmos/${algoritmo.id_algoritmo}`;
		return axios.put(url,algoritmo).then(res=>res.data.data);
	}

	createAlgoritmo(algoritmo){
        const url = process.env.VUE_APP_APIURL + `/algoritmos`;
		return axios.post(url,algoritmo).then(res=>res.data.data);
	}

	deleteAlgoritmo(algoritmo){
        const url = process.env.VUE_APP_APIURL + `/algoritmos/${algoritmo.id_algoritmo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}