
  import DxList from 'devextreme-vue/list';
  import ChannelService from '../services/channel-service';
  import UserChannelService from '../services/user-channel-service';
  
  export default {
    components: { DxList },
    props: {
      templateData: {
        type: Object,
        default: () => {},
      },
      userId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        selectedItemKeys: [],
        loading: true,
        dataSourceChannels: null,
        dataSourceUserChannels: null,
      };
    },
    created() {
      this.channelService = new ChannelService();
      this.userChannelService = new UserChannelService();
    },
    mounted() {    
      this.channelService.getCanales().then(data => this.dataSourceChannels=data);    
      this.refresh();
    },
    methods: {
      onSelectionChanged (e) {
        if (!this.loading) {
          if (e.addedItems.length>0) {
            this.userChannelService.createUsuarioCanal({"id_usuario": this.userId, "id_canal": e.addedItems[0].id_canal}).then(() => {
              this.refresh();
            });
          } else if (e.removedItems.length>0) {
            this.userChannelService.deleteUsuarioCanal(this.userId,e.removedItems[0].id_canal).then(() => {
              this.refresh();
            });
          }
        } else {
          this.loading = false;
        }
      },
      refresh() {
        this.userChannelService.getUsuarioCanales(this.userId).then((data) => {
          this.dataSourceUserChannels=data;
          var canales = "";
          for (var i = 0; i < data.length; i++) {
            if (canales.length==0)
              canales = data[i].id_canal;
            else
              canales = canales + "," + data[i].id_canal;
          }
          if (String(canales).length>0) {
            this.selectedItemKeys = String(canales).split(',').map(function(item) {
            return parseInt(item, 10);
          });
          } else {
            this.selectedItemKeys = [];
          }        
        });
      }
    }
  };
  