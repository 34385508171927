import axios from 'axios';

export default class TypeWorkOrderService {

	getTipoOrdenTrabajos() {			
        const url = process.env.VUE_APP_APIURL + `tipoordentrabajos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoOrdenTrabajo(tipoordentrabajo) {
        const url = process.env.VUE_APP_APIURL + `/tipoordentrabajos/${tipoordentrabajo.id_tipo_orden_trabajo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoOrdenTrabajo(tipoordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/tipoordentrabajos/${tipoordentrabajo.id_tipo_orden_trabajo}`;
		return axios.put(url,tipoordentrabajo).then(res=>res.data.data);
	}

	createTipoOrdenTrabajo(tipoordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/tipoordentrabajos`;
		return axios.post(url,tipoordentrabajo).then(res=>res.data.data);
	}

	deleteTipoOrdenTrabajo(tipoordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/tipoordentrabajos/${tipoordentrabajo.id_tipo_orden_trabajo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}