
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxScrolling
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import ItemClassificationService from '../services/item-classification-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxFilterRow,
    DxSelection,
    DxSearchPanel,
    DxSorting,
    DxPager,
    DxScrolling,
  },
  data() {
    return {
      dataSourceItemClassification: null,
      canal: {},
      events: [],
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.itemClassificationService = new ItemClassificationService();
  },
  mounted() {
    this.itemClassificationService.getClasificacionArticulos().then(data => this.dataSourceItemClassification = data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editItemClassification(e) {
      this.itemClassificationService.updateClasificacionArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
    },
    deleteItemClassification(e) {
      this.itemClassificationService.deleteClasificacionArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createItemClassification(e) {
      this.itemClassificationService.createClasificacionArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
    },
  },
};
