
import DxScrollView from "devextreme-vue/scroll-view";

import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

export default {
    components: {
        DxScrollView
    },
    setup() {
        const route = useRoute();

        const title = ref(route.meta.title);
        const description = ref("");

        watch(() => route.path,
            () => {
                title.value = route.meta.title;
                description.value = route.meta.description;
            }
        )
        return {
            title,
            description
        }
    }
};
