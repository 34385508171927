import axios from 'axios';

export default class ConfigPlanMaintenanceTaskServiceService {

	getServiciosDisponiblesPorPlanTarea(configplanmttoequipotarea) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareaservicios/disponibles/${configplanmttoequipotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getServiciosAsignadasPorPlanTarea(configplanmttoequipotarea) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareaservicios/asignadas/${configplanmttoequipotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getConfigPlanMttoTareaServicios() {			
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareaservicios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigPlanMttoTareaServicio(configplanmttoequipotareaservicio) {
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareaservicios/${configplanmttoequipotareaservicio.id_config_plan_mtto_tarea_servicio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createConfigPlanMttoTareaServicio(configplanmttoequipotareaservicio){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareaservicios`;
		return axios.post(url,configplanmttoequipotareaservicio).then(res=>res.data.data);
	}

	updateConfigPlanMttoTareaServicio(configplanmttoequipotareaservicio){
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareaservicios/${configplanmttoequipotareaservicio.id_config_plan_mtto_tarea_servicio}`;
		return axios.put(url,configplanmttoequipotareaservicio).then(res=>res.data.data);
	}

	deleteConfigPlanMttoTareaServicio(configplanmttoequipotareaservicio){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareaservicios/${configplanmttoequipotareaservicio.id_config_plan_mtto_tarea_servicio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}

