import axios from "axios";

export default class SurveySetupStatusServiece {
  getSurveySetupStatus() {
    const url = process.env.VUE_APP_APIURL + `estatusconfigencuestas`;
    return axios.get(url).then((res) => res.data.data);
  }
  createSurveySetupStatus(estatusconfigencuesta) {
    const url = process.env.VUE_APP_APIURL + `/estatusconfigencuestas`;
    return axios.post(url, estatusconfigencuesta).then((res) => res.data.data);
  }
  updateSurveySetupStatus(estatusconfigencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusconfigencuestas/${estatusconfigencuesta.id_estatus_config_encuesta}`;
    return axios.put(url, estatusconfigencuesta).then((res) => res.data.data);
  }
  deleteSurveySetupStatus(estatusconfigencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusconfigencuestas/${estatusconfigencuesta.id_estatus_config_encuesta}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
