
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import FrequencyService from '../services/frequency-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceFrequency: null,
      frecuencia: {},
      events: [],
      frequencyService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.frequencyService = new FrequencyService();
  },
  mounted() {    
    this.frequencyService.getFrecuencias().then(data => this.dataSourceFrequency=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editFrequency(e) {
			this.frequencyService.updateFrecuencia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteFrequency(e) {
			this.frequencyService.deleteFrecuencia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createFrequency(e) {
			this.frequencyService.createFrecuencia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
