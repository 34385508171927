import axios from 'axios';

export default class SynonymousService {

	getSinonimos() {		
        const url = process.env.VUE_APP_APIURL + `sinonimos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getSinonimo(sinonimo) {
        const url = process.env.VUE_APP_APIURL + `/sinonimos/${sinonimo.id_sinonimo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateSinonimo(sinonimo){
        const url = process.env.VUE_APP_APIURL + `/sinonimos/${sinonimo.id_sinonimo}`;
		return axios.put(url,sinonimo).then(res=>res.data.data);
	}

	createSinonimo(sinonimo){
        const url = process.env.VUE_APP_APIURL + `/sinonimos`;
		return axios.post(url,sinonimo).then(res=>res.data.data);
	}

	deleteSinonimo(sinonimo){
        const url = process.env.VUE_APP_APIURL + `/sinonimos/${sinonimo.id_sinonimo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}