
import moment from 'moment';
import Button from 'devextreme-vue/button';
import LicenseService from '../services/license-service';

export default {
  components: {
    Button,
},
  data() {
    return {
      contract: null,
      apiclixkey: null,
      email: null,
      company: null,
      paymentMethod: null,
      modules: null,
      licenseNumber: null,
      licenseNumberClient: null,
      url: null,
      code: null,
      description: null,
      status: null,
      beginDate: null,
      endDate: null,
      nextPayment: null,
      dataSourceLicense: null,
    };
  },
  computed: {
    beginDateFormat() {
      return moment(this.beginDate).format('DD/MM/YYYY');
    },
    endDateFormat() {
      return moment(this.endDate).format('DD/MM/YYYY');
    },
  },
  created() {
    this.licenseService = new LicenseService();
  },
  mounted() {
    this.licenseService.getLicencia().then((data) => {
      this.dataSourceLicense=data;
      this.contract = data.contrato;
      this.apiclixkey = data.llave;
      this.email = data.correo_electronico;
      this.company = data.empresa;
      this.paymentMethod = data.forma_pago;
      this.modules = data.modulo;
      this.licenseNumber = data.numero_licencias;
      this.licenseNumberClient = data.numero_licencias_cliente;
      this.url = data.url_portal;
      this.code = data.clave;
      this.description = data.nombre;
      this.endDate = data.vigencia;
      this.beginDate = data.fecha_adquisicion;
      this.nextPayment = data.siguiente_pago;
      if (data.activo==1)
        this.status = "Al corriente";
      else
        this.status = "Pago Pendiente";
    });  
  },
  methods: {
    renewLicense() {
      // TODO Redireccionar a https://apiclix.com/renovacion
    },
    
  },  
};
