
var dataSourceWorkOrder = [];
var dataSourceStockRequestItems = [];
var dataSourceStockRequestItems1 = [];
var dataSourceItem1 = [];
var dataSourceUnitOfMeasurement1 = [];
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';

import StockRequestService from '../services/stock-request-service';
import StatusRequestService from '../services/status-request-service';
import ClientService from '../services/client-service';
import CompanyService from '../services/company-service';
import UserService from '../services/user-service';
import WarehouseService from '../services/warehouse-service';
import DetailTemplate from './stock-request-detail.vue';
import WorkOrderService from '../services/workorder-service';
import ItemService from '../services/item-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import StockRequestItemService from '../services/stock-request-item-service';
import TypeItemService from '../services/type-item-service'
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxSelectBox,
    DxDateBox,
    DxButton,
  },
  data() {
    const now = new Date();
    return {
      usuario: null,
      company: null,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      valeelectronico: null,
      detallevaleelectronico: null,
      dataSourceStockRequests: null,
      dataSourceStatus: null,
      dataSourceUsers: null,
      dataSourceCompanies: null,
      dataSourceWarehouse: null,
      dataSourceClients: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      dataSourceStockRequestItems1,
      dataSourceWorkOrder,
      dataSourceItem: null,
      dataSourceStockRequestItems,
      dataSourceUnitOfMeasurement: null,
      dataSourceTypeItem: null,
      dataSourceItem1,
      dataSourceUnitOfMeasurement1,
      id_vale_electronico: null,
      dataSourdeItems: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
    };
  },
  created() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
    this.stockRequestService = new StockRequestService();
    this.statusRequestService = new StatusRequestService();
    this.clientService = new ClientService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.workOrderService = new WorkOrderService();
    this.itemService = new ItemService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.stockRequestItemService = new StockRequestItemService();
    this.typeItemService = new TypeItemService();
  },
  mounted() {
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.companyService.getEmpresas().then(data => {
      this.dataSourceCompanies = data;
      this.company=data[0];
      this.stockRequestService.getValeElectronicosHistoria({"id_empresa":this.company.id_empresa,"fecha_inicio":this.startDate,"fecha_fin":this.endDate}).then(data => this.dataSourceStockRequests = data);
    });
    this.statusRequestService.getEstatusValeElectronicos().then(data => this.dataSourceStatus = data);
    this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouse = data);
    this.workOrderService.getworkordersheet().then(data => dataSourceWorkOrder = data);
    this.typeItemService.getTipoArticulosCombo().then(data => this.dataSourceTypeItem = data);
    this.itemService.getArticuloComboActivos().then(data => {
      dataSourceItem1 = data;
      this.dataSourceItem = data;
    });
    this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
      dataSourceUnitOfMeasurement1 = data;
      this.dataSourceUnitOfMeasurement = data;
    });
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    onClick() {
      this.stockRequestService.getValeElectronicosHistoria({"id_empresa":this.company.id_empresa,"fecha_inicio":this.startDate,"fecha_fin":this.endDate}).then(data => this.dataSourceStockRequests = data);
    },
  },
};
