import axios from 'axios';

export default class KitService {

	getKits() {		
        const url = process.env.VUE_APP_APIURL + `kits`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getKit(kit) {
        const url = process.env.VUE_APP_APIURL + `/kits/${kit.id_kit}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateKit(kit){
        const url = process.env.VUE_APP_APIURL + `/kits/${kit.id_kit}`;
		return axios.put(url,kit).then(res=>res.data.data);
	}

	createKit(kit){
        const url = process.env.VUE_APP_APIURL + `/kits`;
		return axios.post(url,kit).then(res=>res.data.data);
	}

	deleteKit(kit){
        const url = process.env.VUE_APP_APIURL + `/kits/${kit.id_kit}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}