import axios from 'axios';

export default class StatusRequestService {

	getEstatusValeElectronicos() {			
        const url = process.env.VUE_APP_APIURL + `estatusvaleelectronicos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEstatusValeElectronico(estatusvaleelectronico) {
        const url = process.env.VUE_APP_APIURL + `/estatusvaleelectronicos/${estatusvaleelectronico.id_estatus_vale_electronico}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEstatusValeElectronico(estatusvaleelectronico){
        const url = process.env.VUE_APP_APIURL + `/estatusvaleelectronicos/${estatusvaleelectronico.id_estatus_vale_electronico}`;
		return axios.put(url,estatusvaleelectronico).then(res=>res.data.data);
	}

	createEstatusValeElectronico(estatusvaleelectronico){
        const url = process.env.VUE_APP_APIURL + `/estatusvaleelectronicos`;
		return axios.post(url,estatusvaleelectronico).then(res=>res.data.data);
	}

	deleteEstatusValeElectronico(estatusvaleelectronico){
        const url = process.env.VUE_APP_APIURL + `/estatusvaleelectronicos/${estatusvaleelectronico.id_estatus_vale_electronico}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}