

var dataSourceRequisitionItems = [];
var dataSourceRequisitionItems1 = [];
var dataSourceItem1 = [];
var dataSourceUnitOfMeasurement1 = [];
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';

import RequisitionService from '../services/requisition-service';
import StatusRequisitionService from '../services/status-requisition-service';
import ClientService from '../services/client-service';
import CompanyService from '../services/company-service';
import UserService from '../services/user-service';
import WarehouseService from '../services/warehouse-service';
import DetailTemplate from './requisition-detail.vue';
import SupplierService from '../services/supplier-service';
import ItemService from '../services/item-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import RequisitionItemService from '../services/requisition-item-service';
import TypeItemService from '../services/type-item-service'

import DepartmentService from '../services/department-service';
import CurrencyService from '../services/currency-service'
import TypeRequisitionService from '../services/type-requisition-service'
import LocationService from '../services/location-service'
import ProjectService from '../services/project-service'

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxSelectBox,
    DxDateBox,
    DxButton,
  },
  data() {
    const now = new Date();
    return {
      usuario: null,
      company: null,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      requisicion: null,
      detallerequisicion: null,
      dataSourceRequisitions: null,
      dataSourceStatus: null,
      dataSourceUsers: null,
      dataSourceCompanies: null,
      dataSourceWarehouse: null,
      dataSourceClients: null,
      dataSourceCurrencies: null,
      dataSourceProjects: null,
      dataSourceRequisitionTypes: null,
      dataSourceLocations: null,
      dataSourceDepartments: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      dataSourceRequisitionItems1,
      dataSourceSupplier: null,
      dataSourceItem: null,
      dataSourceRequisitionItems,
      dataSourceUnitOfMeasurement: null,
      dataSourceTypeItem: null,
      dataSourceItem1,
      dataSourceUnitOfMeasurement1,
      id_requisicion: null,
      dataSourceItems: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
    };
  },
  created() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
    this.requisitionService = new RequisitionService();
    this.statusRequisitionService = new StatusRequisitionService();
    this.clientService = new ClientService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.supplierService = new SupplierService();
    this.itemService = new ItemService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.requisitionItemService = new RequisitionItemService();
    this.typeItemService = new TypeItemService();
    this.currencyService = new CurrencyService();
    this.typeRequisitionService = new TypeRequisitionService();
    this.locationService = new LocationService();
    this.projectService = new ProjectService();
    this.departmentService = new DepartmentService();
  },
  mounted() {
    this.currencyService.getMonedas().then(data => this.dataSourceCurrencies = data);
    this.departmentService.getDepartamentos().then(data => this.dataSourceDepartments = data);
    this.typeRequisitionService.getTipoRequisiciones().then(data => this.dataSourceRequisitionTypes = data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations = data);
    this.projectService.getProyectos().then(data => this.dataSourceProjects = data);
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.companyService.getEmpresas().then(data => {
      this.dataSourceCompanies = data;
      this.company=data[0];
      this.requisitionService.getRequisicionesHistoria({"id_empresa":this.company.id_empresa,"fecha_inicio":this.startDate,"fecha_fin":this.endDate}).then(data => this.dataSourceRequisitions = data);
    });
    this.statusRequisitionService.getEstatusRequisiciones().then(data => this.dataSourceStatus = data);
    this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouse = data);
    this.supplierService.getProveedores().then(data => this.dataSourceSupplier = data);
    this.typeItemService.getTipoArticulosCombo().then(data => this.dataSourceTypeItem = data);
    this.itemService.getArticuloComboTodos().then(data => {
      dataSourceItem1 = data;
      this.dataSourceItem = data;
    });
    this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
      dataSourceUnitOfMeasurement1 = data;
      this.dataSourceUnitOfMeasurement = data;
    });
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    onClick() {
      this.requisitionService.getRequisicionesHistoria({"id_empresa":this.company.id_empresa,"fecha_inicio":this.startDate,"fecha_fin":this.endDate}).then(data => this.dataSourceRequisitions = data);
    },
  },
};
