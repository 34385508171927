import axios from 'axios';

export default class RequisitionService {
  
  getRequisicionPorUsuario(id_usuario) {
    const url = process.env.VUE_APP_APIURL + `requisiciones/usuario`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  getRequisicionesPorEmpresa(empresa) {
    const url =
      process.env.VUE_APP_APIURL + `requisiciones/empresas/${empresa}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getRequisicionesHistoria(historia) {
    const url =
      process.env.VUE_APP_APIURL + `requisiciones/historia`;
    return axios.get(url,{ params: historia }).then((res) => res.data.data);
  }

  createRequisicion(requisicion) {
    const url = process.env.VUE_APP_APIURL + `/requisiciones`;
    return axios.post(url, requisicion).then((res) => res.data.data);
  }

  updateRequisicion(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/requisiciones/${requisicion.id_requisicion}`;
    return axios.put(url, requisicion).then((res) => res.data.data);
  }

  deleteRequisicion(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/requisiciones/${requisicion.id_requisicion}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}