import axios from 'axios';

export default class MeasurementFormulaService {
	
	getMedicionFormulasPorMedicion(medicionId) {
        const url = process.env.VUE_APP_APIURL + `medicionformulas/mediciones/${medicionId}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getMedicionFormulas() {		
        const url = process.env.VUE_APP_APIURL + `medicionformulas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMedicionFormula(medicionFormula) {
        const url = process.env.VUE_APP_APIURL + `/medicionformulas/${medicionFormula.id_medicion_formula}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMedicionFormula(medicionFormula){
        const url = process.env.VUE_APP_APIURL + `/medicionformulas/${medicionFormula.id_medicion_formula}`;
		return axios.put(url,medicionFormula).then(res=>res.data.data);
	}

	createMedicionFormula(medicionFormula){
        const url = process.env.VUE_APP_APIURL + `/medicionformulas`;
		return axios.post(url,medicionFormula).then(res=>res.data.data);
	}

	deleteMedicionFormula(medicionFormula){
        const url = process.env.VUE_APP_APIURL + `/medicionformulas/${medicionFormula.id_medicion_formula}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}