
import DxList from 'devextreme-vue/list';
import SkillService from '../services/skill-service';
import UserSkillService from '../services/user-skill-service';

export default {
  components: { DxList },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedItemKeys: [],
      loading: true,
      dataSourceSkills: null,       
      dataSourceUserSkills: null,
    };
  },
  created() {
    this.skillService = new SkillService();
    this.userSkillService = new UserSkillService();
  },
  mounted() {    
    this.skillService.getHabilidades().then(data => this.dataSourceSkills=data);    
    this.refresh();
  },
  methods: {
    onSelectionChanged (e) {
      if (!this.loading) {
        if (e.addedItems.length>0) {
          this.userSkillService.createUsuarioHabilidad({"id_usuario": this.userId, "id_habilidad": e.addedItems[0].id_habilidad}).then(() => {           
            this.refresh();
          });
        } else if (e.removedItems.length>0) {
          this.userSkillService.deleteUsuarioHabilidad(this.userId,e.removedItems[0].id_habilidad).then(() => {          
            this.refresh();
          });
        }
      } else {
        this.loading = false;
      }
    },
    refresh() {
      this.userSkillService.getUsuarioHabilidades(this.userId).then((data) => {
        this.dataSourceUserSkills=data;
        var habilidades = "";
        for (var i = 0; i < data.length; i++) {
          if (habilidades.length==0)
            habilidades = data[i].id_habilidad;
          else
            habilidades = habilidades + "," + data[i].id_habilidad;
        }
        if (String(habilidades).length>0) {
          this.selectedItemKeys = String(habilidades).split(',').map(function(item) {
          return parseInt(item, 10);
        });
        } else {
          this.selectedItemKeys = [];
        }        
      });
    }
  }
};
