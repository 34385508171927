import axios from 'axios';

export default class ConfigPlanMaintenanceService {

	getConfigPlanMttosPorEmpresaCliente(empresa,cliente) {			
        const url = process.env.VUE_APP_APIURL + `configplanmttos/filtros`;		
		return axios.get(url, { params: {id_empresa: empresa, id_cliente: cliente}}).then(res=>res.data.data);
	}
	
	getConfigPlanMttos() {			
        const url = process.env.VUE_APP_APIURL + `configplanmttos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigPlanMtto(configplanmmto) {
        const url = process.env.VUE_APP_APIURL + `configplanmttos/${configplanmmto.id_config_plan_mtto}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigPlanMtto(configplanmmto){
        const url = process.env.VUE_APP_APIURL + `configplanmttos/${configplanmmto.id_config_plan_mtto}`;
		return axios.put(url,configplanmmto).then(res=>res.data.data);
	}

	createConfigPlanMtto(configplanmmto){
        const url = process.env.VUE_APP_APIURL + `configplanmttos`;
		return axios.post(url,configplanmmto).then(res=>res.data.data);
	}

	deleteConfigPlanMtto(configplanmmto){
        const url = process.env.VUE_APP_APIURL + `configplanmttos/${configplanmmto.id_config_plan_mtto}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}