
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import CountryService from '../services/country-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceCountry: null,
      pais: {},
      events: [],
      countryService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.countryService = new CountryService();
  },
  mounted() {    
    this.countryService.getPaises().then(data => this.dataSourceCountry=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editCountry(e) {
			this.countryService.updatePais(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteCountry(e) {
			this.countryService.deletePais(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createCountry(e) {
			this.countryService.createPais(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
