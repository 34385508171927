
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import ItemSpareService from '../services/item-spare-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceItemSpare: null,
      formapago: {},
      events: [],
      itemSpareService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.itemSpareService = new ItemSpareService();
  },
  mounted() {    
    this.itemSpareService.getRefacciones().then(data => this.dataSourceItemSpare=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editItemSpare(e) {
			this.itemSpareService.updateRefaccion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteItemSpare(e) {
			this.itemSpareService.deleteRefaccion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createItemSpare(e) {
			this.itemSpareService.createRefaccion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
