
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import AbilityService from '../services/ability-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceAbility: null,
      habilidad: {},
      events: [],
      abilityService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.abilityService = new AbilityService();
  },
  mounted() {    
    this.abilityService.getHabilidades().then(data => this.dataSourceAbility=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editAbility(e) {
			this.abilityService.updateHabilidad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteAbility(e) {
			this.abilityService.deleteHabilidad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createAbility(e) {
			this.abilityService.createHabilidad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
