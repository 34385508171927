
import {
  DxTreeList,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/tree-list';
import { DxToast } from 'devextreme-vue/toast';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import DxMap from 'devextreme-vue/map';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import {
  DxForm, DxItem, DxLabel, DxGroupItem, DxRequiredRule
} from 'devextreme-vue/form';
import LocationService from '../services/location-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';

const markerUrl = 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/maps/map-marker.png';

export default {
  components: {
    DxTreeList,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSorting,
    DxTabPanel,
    DxMap,
    DxPopup,
    DxToolbarItem,
    DxLabel,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      localidades: null,
      expandedRowKeys: [],
      dataSourceLocations: null,
      dataSourceFatherLocations: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Ubicación',
        showTitle: true,
        width: 600,
        height: 420,
      },
      markersData: [],
      markersIcon: markerUrl,
      popupVisible: false,
      saveButtonOptions: {
        text: 'GUARDAR',
        onClick: () => this.createLocationModal(),
      },
      closeButtonOptions: {
        text: 'CERRAR',
        onClick: () => {
          this.popupVisible = false;
        },
      },
      locationdata: {},
      fatherLocationsEditorOptions: {},
    };
  },
  created() {
    this.locationService = new LocationService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.locationService.getLocalidades().then(data => {
      this.dataSourceLocations = data;
      this.markersData = [];
      data.forEach(element => {
        var item = {
          location: [element.latitud, element.longitud],
          tooltip: {
            text: element.nombre,
          }
        };
        this.markersData.push(item);
      });
    });
    this.locationService.getLocalidades().then(data => {
      this.dataSourceFatherLocations = data;
      var list = [];
      data.forEach(element => {
        list.push(element.nombre);
      });
      this.fatherLocationsEditorOptions = { items: list, searchEnabled: true, value: '', showClearButton: true };
    });
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editLocation(e) {
      this.locationService.updateLocalidad(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.locationService.getLocalidades().then((data) => {
            this.dataSourceLocations = data; this.dataSourceFatherLocations = data;
            this.markersData = [];
            data.forEach(element => {
              var item = {
                location: [element.latitud, element.longitud],
                tooltip: {
                  text: element.nombre,
                }
              };
              this.markersData.push(item);
            });
          });
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    deleteLocation(e) {
      this.locationService.deleteLocalidad(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.locationService.getLocalidades().then((data) => {
            this.dataSourceLocations = data;
            this.dataSourceFatherLocations = data;
            this.markersData = [];
            data.forEach(element => {
              var item = {
                location: [element.latitud, element.longitud],
                tooltip: {
                  text: element.nombre,
                }
              };
              this.markersData.push(item);
            });
          });
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    createLocation(e) {
      if (e.data.id_localizacion_padre == 0) {
        e.data.id_localizacion_padre = null;
      }
      this.locationService.createLocalidad(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.locationService.getLocalidades().then((data) => {
            this.dataSourceLocations = data;
            this.dataSourceFatherLocations = data;
            this.markersData = [];
            data.forEach(element => {
              var item = {
                location: [element.latitud, element.longitud],
                tooltip: {
                  text: element.nombre,
                }
              };
              this.markersData.push(item);
            });
          });
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    onInitNewRow(e) {
      e.data.activo = true;
      e.data.id_equipo_padre = null;
      //this.locationService.getLocalidadPadres().then(data => this.dataSourceFatherLocations=data);
    },
    addMarker(e) {
      this.popupVisible = true;
      this.locationdata = {};
      this.locationdata.latitud = e.location.lat;
      this.locationdata.longitud = e.location.lng;
      this.locationdata.NombreUbicación = null;
      this.locationdata.UbicaciónPadre = null;
      this.locationdata['Geocerca(mts)'] = null;
      this.locationdata.activo = true;

    },
    createLocationModal() {
      var dataJson = {};
      if (this.locationdata.NombreUbicación != null) {
        if (this.locationdata.UbicaciónPadre == '') {
          dataJson.id_localizacion_padre = null;
        } else {
          this.dataSourceFatherLocations.forEach(element => {
            if (element.nombre == this.locationdata.UbicaciónPadre) {
              dataJson.id_localizacion_padre = element.id_localizacion;
            }
          });
        }
        dataJson.nombre = this.locationdata.NombreUbicación;
        dataJson.activo = this.locationdata.activo;
        dataJson.latitud = this.locationdata.latitud;
        dataJson.longitud = this.locationdata.longitud;
        dataJson.radio_geocerca = this.locationdata['Geocerca(mts)'];
        this.locationService.createLocalidad(dataJson).then((data) => {
          if (typeof (data.error) === "undefined") {
            this.popupVisible = false;
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
            this.locationService.getLocalidades().then((data) => {
              this.dataSourceLocations = data;
              this.dataSourceFatherLocations = data;
              this.markersData = [];
              data.forEach(element => {
                var item = {
                  location: [element.latitud, element.longitud],
                  tooltip: {
                    text: element.nombre,
                  }
                };
                this.markersData.push(item);
              });
            });
          } else {
            this.type = 'error';
            this.message = data.error;
            this.isVisible = true;
          }
        });
      }
    }
  },
};
