import axios from 'axios';

export default class PaymentMethodService {

	getFormaPagos() {		
        const url = process.env.VUE_APP_APIURL + `formapagos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getFormaPago(formapago) {
        const url = process.env.VUE_APP_APIURL + `/formapagos/${formapago.id_forma_pago}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateFormaPago(formapago){
        const url = process.env.VUE_APP_APIURL + `/formapagos/${formapago.id_forma_pago}`;
		return axios.put(url,formapago).then(res=>res.data.data);
	}

	createFormaPago(formapago){
        const url = process.env.VUE_APP_APIURL + `/formapagos`;
		return axios.post(url,formapago).then(res=>res.data.data);
	}

	deleteFormaPago(formapago){
        const url = process.env.VUE_APP_APIURL + `/formapagos/${formapago.id_forma_pago}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}