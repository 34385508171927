
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxButtonItem,
  DxLabel,
  DxValidationRule,
} from 'devextreme-vue/form';
import { DxLookup } from 'devextreme-vue/lookup';
import DxRadioGroup from 'devextreme-vue/radio-group';
import { DxTagBox } from 'devextreme-vue/tag-box';
import { counterItemTypes } from '../data/data.js';
import DxDateBox from 'devextreme-vue/date-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxToast } from 'devextreme-vue/toast';

import CompanyService from '../services/company-service';
import WarehouseService from '../services/warehouse-service';
import TypePhysicalInventoryService from '../services/type-physical-inventory-service';
import UserService from '../services/user-service';
import TypeItemService from '../services/type-item-service';
import GroupItemService from '../services/group-item-service';
import ItemClassificationService from '../services/item-classification-service';
import ItemAreaService from '../services/item-area-service';
import ItemAisleService from '../services/item-aisle-service';
import ItemRackGroupService from '../services/item-rack-group-service';
import ItemRackService from '../services/item-rack-service';
import ItemLevelService from '../services/item-level-service';
import PhysicalInventoryService from '../services/physical-inventory-service';
import auth from '../auth';

const submitButtonOptions = {
  text: "Crear Inventario Físico",
  type: 'default',
  useSubmitBehavior: true
};

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxRadioGroup,
    DxLookup,
    DxLabel,
    DxValidationRule,
    DxTagBox,
    DxToast,
    DxDateBox,
    DxNumberBox,
    DxCheckBox,
  },
  data() {

    return {
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      dateSerializationFormatDate: "yyyy-MM-dd",
      cualquierarticulo: false,
      fecha: new Date(),
      idUsuario: null,
      idEmpresa: null,
      idAlmacen: null,
      idTipoInventarioFisico: null,
      idParticipantes: null,
      idTipoArticulo: null,
      idGrupoArticulo: null,
      idClasificacionArticulo: null,
      costoMinimo: 0,
      costoMaximo: null,
      idArticuloArea: null,
      idArticuloPasillo: null,
      idArticuloGrupoEstante: null,
      idArticuloEstante: null,
      idArticuloNivel: null,
      ciclos: 1,
      counterItemTypeValue: counterItemTypes[0],
      cantidad: null,
      searchMode: 'contains',
      counterItemTypes,
      physicalInventoryFormData: null,
      dataSourceCompanies: null,
      dataSourceWarehouses: null,
      dataSourcePhysicalInventoryTypes: null,
      dataSourceUsers: null,
      dataSourceItemTypes: null,
      dataSourceItemGroups: null,
      dataSourceItemAreas: null,
      dataSourceItemAisles: null,
      dataSourceItemRackGroups: null,
      dataSourceItemRacks: null,
      dataSourceItemLevels: null,
      dataSourceItemClassifications: null,
      submitButtonOptions,
      dateOptions: { width: '100%', value: new Date(), type: "datetime", dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss" },
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.physicalInventoryService = new PhysicalInventoryService();
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.userService = new UserService();
    this.typeItemService = new TypeItemService();
    this.groupItemService = new GroupItemService();
    this.itemClassificationService = new ItemClassificationService();
    this.typePhysicalInventoryService = new TypePhysicalInventoryService();
    this.itemAreaService = new ItemAreaService();
    this.itemAisleService = new ItemAisleService();
    this.itemRackGroupService = new ItemRackGroupService();
    this.itemRackService = new ItemRackService();
    this.itemLevelService = new ItemLevelService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.typeItemService.getTipoArticulos().then(data => this.dataSourceItemTypes = data);
    this.groupItemService.getGrupoArticulos().then(data => this.dataSourceItemGroups = data);
    this.itemClassificationService.getClasificacionArticulos().then(data => this.dataSourceItemClassifications = data);
    this.typePhysicalInventoryService.getTipoInventarioFisicos().then(data => this.dataSourcePhysicalInventoryTypes = data);
    this.itemAreaService.getArticuloAreas().then(data => this.dataSourceItemAreas = data);
    this.itemAisleService.getArticuloPasillos().then(data => this.dataSourceItemAisles = data);
    this.itemRackGroupService.getArticuloGrupoEstantes().then(data => this.dataSourceItemRackGroups = data);
    this.itemRackService.getArticuloEstantes().then(data => this.dataSourceItemRacks = data);
    this.itemLevelService.getArticuloNiveles().then(data => this.dataSourceItemLevels = data);
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
  },
  methods: {
    handleSubmit(e) {
      auth.getUser().then((data) => {
        this.idUsuario = data.data.id_usuario;

        let datajson = {
          'id_usuario': this.idUsuario,
          'fecha': this.fecha,
          'fecha_estimada_fin': this.fechaFinEstimada,
          'id_empresa': this.idEmpresa,
          'id_almacen': this.idAlmacen,
          'id_tipo_inventario_fisico': this.idTipoInventarioFisico,
          'titulo': this.physicalInventoryFormData.titulo,
          'participantes': this.idParticipantes,
          'tipoarticulos': this.idTipoArticulo,
          'grupoarticulos': this.idGrupoArticulo,
          'clasificacionarticulos': this.idClasificacionArticulo,
          'costo_min': this.costoMinimo,
          'costo_max': this.costoMaximo,
          'articuloarea': this.idArticuloArea,
          'articulopasillo': this.idArticuloPasillo,
          'articulogrupoestante': this.idArticuloGrupoEstante,
          'articuloestante': this.idArticuloEstante,
          'articulonivel': this.idArticuloNivel,
          'ciclos': this.ciclos,
          'tipoconteo': this.counterItemTypeValue,
          'cantidad': this.cantidad,
          'capturaparticipante': this.cualquierarticulo,
        };
        this.physicalInventoryService.createInventarioFisico(datajson).then((data) => {
          if (typeof (data.error) === "undefined") {
            this.type = 'success';
            this.message = 'Se ha creado el registro con folio:' + data.folio;
            this.isVisible = true;
            this.cleanForm();
          } else {
            this.type = 'error';
            this.message = data.error;
            this.isVisible = true;
          }
        });

      });
      e.preventDefault();
    },
    cleanForm() {
      this.$refs.anyName.reset();
      this.fecha = new Date(),
        this.cualquierarticulo = false,
        this.idEmpresa = null,
        this.idAlmacen = null,
        this.idTipoInventarioFisico = null,
        this.idParticipantes = null,
        this.idTipoArticulo = null,
        this.idGrupoArticulo = null,
        this.idClasificacionArticulo = null,
        this.costoMinimo = 0,
        this.costoMaximo = null,
        this.idArticuloArea = null,
        this.idArticuloPasillo = null,
        this.idArticuloGrupoEstante = null,
        this.idArticuloEstante = null,
        this.idArticuloNivel = null,
        this.ciclos = 1,
        this.counterItemTypeValue = counterItemTypes[0],
        this.cantidad = null
    },
    valueChangedCompany(e) {
      this.idEmpresa = e.value;
      this.warehouseService.getAlmacenesPorEmpresa(e.value).then((data) => {
        this.dataSourceWarehouses = data;
        if (data.length > 0) {
          this.idAlmacen = data[0].id_almacen;
        }
      });
    },
    valueChangedWarehouse(e) {
      this.idAlmacen = e.value;
    },
    valueChangedPhysicalInventoryType(e) {
      console.log(e);
    },
    changeSelectionSource(e) {
      console.log(e.value);
      this.counterItemTypeValue = e.value;
    },
  }

};
