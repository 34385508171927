
import {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxToolbar
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import { DxButton } from 'devextreme-vue/button';

import ItemAreaService from '../services/item-area-service';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxPaging,
        DxToast,
        DxToolbar,
        DxItem,
        DxButton
    },
    created() {
        this.itemAreaService = new ItemAreaService();
    },
    mounted() {
        this.itemAreaService.getArticuloAreas().then(data => this.dataSourceItemArea = data);
    },
    data() {
        return {
            dataSourceItemArea: null,
            isVisible: false,
            message: '',
            type: 'info',
        }
    },
    methods: {
        refreshDataGrid() {
            this.itemAreaService.getArticuloAreas().then(data => this.dataSourceItemArea = data);
        },
        createItemArea(e) {
            this.itemAreaService.createArticuloArea(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha creado el registro';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        editItemArea(e) {
            this.itemAreaService.updateArticuloArea(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        deleteItemArea(e) {
            this.itemAreaService.deleteArticuloArea(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha eliminado el registro';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        }
    },
}
