
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import ProjectService from '../services/project-service';
import CurrencyService from '../services/currency-service';
import ClientService from '../services/client-service';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSelection,
    DxSorting,
  },
  data() {
    return {
      clientes: null,
      dataSourceProjects: null,
      dataSourceCountries: null,
      dataSourceClients: null,
      dataSourceCurrencies: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Proyecto',
        showTitle: true,
        width: 800,
      },
      moneda: { style: 'currency', currency: 'MXN' },
      mode: 'select',
    };
  },
  created() {
    this.projectService = new ProjectService();
    this.currencyService = new CurrencyService();
    this.clientService = new ClientService();    
  },
  mounted() {    
    this.projectService.getProyectos().then(data => this.dataSourceProjects=data);
    this.currencyService.getMonedas().then(data => this.dataSourceCurrencies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);       
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editProject(e) {
      this.projectService.updateProyecto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteProject(e) {
			this.projectService.deleteProyecto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createProject(e) {
			this.projectService.createProyecto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {
      e.data.activo = true;
    },
  },
};
