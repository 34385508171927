import axios from 'axios';

export default class QuoteDetailService {

  createCotizacionDetalle(cotizaciondetalle) {
    const url = process.env.VUE_APP_APIURL + `/cotizaciondestalles`;
    return axios.post(url, cotizaciondetalle).then((res) => res.data.data);
  }

  getCotizacionesPorRequisicionID(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/cotizaciondestalles/requisicion`;
    return axios
      .get(url, { params: requisicion })
      .then((res) => res.data.data);
  }

  updateCotizacionDetalle(cotizaciondetalle) {
    const url =
      process.env.VUE_APP_APIURL +
      `/cotizaciondestalles/${cotizaciondetalle.id_cotizacion_detalle}`;
    return axios.put(url, cotizaciondetalle).then((res) => res.data.data);
  }

  deleteCotizacionDetalle(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/cotizaciondestalles/${requisicion.id_cotizacion_detalle}`;
    return axios.delete(url).then((res) => res.data.data);
  }

}