
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { bugs } from '../data/data.js';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
  },
  data() {
    return {
      dataSource: bugs,
      displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
      displayMode: 'full',
      pageSizes: [5, 10, 'all'],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
    };
  },  
};
