

import DxScheduler, { DxResource } from 'devextreme-vue/scheduler';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxBox } from 'devextreme-vue/box';
import DxTileView from 'devextreme-vue/tile-view';
import notify from 'devextreme/ui/notify';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxList from 'devextreme-vue/list';
import auth from '../auth';
import { confirm } from "devextreme/ui/dialog";

import MaintenancePlanService from '../services/maintenance-plan-service';
import WorkOrderService from '../services/workorder-service';
import StatusMaintenancePlanService from '../services/status-mp-service';
import GroupUserService from '../services/group-user-service';
import PriorityService from '../services/priority-service';
import TypeMaintenanceService from '../services/type-maintenance-service';


export default {
  components: {
    DxScheduler,
    DxResource,
    DxToolbar,
    DxItem,
    DxPopup,
    DxScrollView,
    DxList,
    DxToolbarItem,
    DxBox,
    DxTileView,
  },
  data() {
    return {
      assigneeId: null,
      allDayPanelMode: 'allDay',
      loading: true,
      selectedItemKeys: [],
      popupTitle: "Generación de Órdenes de Trabajo",
      popupVisible: false,
      usuarioId: null,
      showAllDayPanel: false,
      allowAdding: false,
      allowDeleting: true,
      allowUpdating: true,
      allowResizing: true,
      allowDragging: true,
      isPopupVisible: false,
      views: ['day', 'week', 'workWeek', 'month'],
      currentDate: new Date(),
      dataSourceGroups: [{text: 'PM', id: 1, color:'#32aced'},{text: 'OT', id:2, color:'#b8baba'}],
      dataSourceMaintenancePlans: null,
      dataSourceStatus: null,
      dataSourceUserGroups: null,
      dataSourcePriorities: null,
      dataSourceMaintenanceTypes: null,
      dataSourceNewMaintenancePlans: null,
      refreshButtonOptions: {
        icon: 'refresh',
        onClick: () => {
          this.loadIndicatorVisible = true;
          this.maintenancePlanService.getCalendarioPlanMantenimientosPorEmpresa(1).then((data) => {
            this.dataSourceMaintenancePlans=data;
            notify('Se ha actualizado el calendario');
          });
        },
      },
      createMaintenancePlanButtonOptions: {
        text: 'Generar OTs ...',
        onClick: () => {
          this.popupVisible = true;
          this.refresh();
        },
      },
    };
  },
  created() {
    this.workOrderService = new WorkOrderService();
    this.maintenancePlanService = new MaintenancePlanService();
    this.statusMaintenancePlanService = new StatusMaintenancePlanService();
    this.groupUserService = new GroupUserService();
    this.priorityService = new PriorityService();
    this.typeMaintenanceService = new TypeMaintenanceService();
  },
  mounted() {   
    this.statusMaintenancePlanService.getCalendarioEstatusPlanMantenimientos().then(data => this.dataSourceStatus=data);
    this.groupUserService.getCalendarioGrupoUsuarios().then(data => this.dataSourceUserGroups=data);
    this.priorityService.getCalendarioPrioridades().then(data => this.dataSourcePriorities=data);
    this.typeMaintenanceService.getCalendarioTipoMantenimientos().then(data => this.dataSourceMaintenanceTypes=data); 
    this.maintenancePlanService.getCalendarioPlanMantenimientosPorEmpresa(1).then(data => this.dataSourceMaintenancePlans=data);
  },
  computed: {
    editing() {
      return {
        allowAdding: this.allowAdding,
        allowDeleting: this.allowDeleting,
        allowUpdating: this.allowUpdating,
        allowResizing: this.allowResizing,
        allowDragging: this.allowDragging,
      };
    },
  },
  methods: {
    async refresh() {
      await this.maintenancePlanService.getPlanMantenimientosPorEmpresa(1).then((data) => {
        this.dataSourceNewMaintenancePlans=data;
        this.loading = false;
      });
    },
    showPopup(data, maintenancePlanId) {
      this.loading = true;      
      this.selectedItemKeys = [];
      this.maintenancePlanId = maintenancePlanId;
      this.formData = data;      
      this.popupVisible = true;
      this.refresh();
    },
    onSelectionChangedMaintenancePlan(e) {
      if (!this.loading) {
        if (e.addedItems.length==1) {
          auth.getUser().then((data) => {
            this.usuarioId = data.data.id_usuario;
            this.workOrderService.createOrdenTrabajoDesdePlanMantenimiento({"id_plan_mantenimiento": e.addedItems[0].id_plan_mantenimiento, "id_usuario": this.usuarioId}).then(() => {           
              this.refresh();
            }).catch(() => {
              // TODO
            });
          });
        }
      }
    },
    onAppointmentFormOpening(e) {      
      //console.log(e.appointmentData.titulo);
      e.popup.option('showTitle', true);
      e.popup.option('title', e.appointmentData.text);
      var form = e.form;
      form.itemOption("recurrenceGroup.recurrenceRule", {visible: false});
      form.itemOption("mainGroup.allDay",{visible: false});
      form.itemOption("mainGroup.repeat",{visible: false});
    },
    showToast(event, value, type) {
      notify(`${event} "${value}" task`, type, 800);
    },
    showAddedToast(e) {
      this.showToast('Added', e.appointmentData.text, 'success');
    },
    showUpdatedToast(e) {
      let isArray = Array.isArray(e.appointmentData.assigneeId);
      if (isArray)
        this.assigneeId = e.appointmentData.assigneeId[0];
      else
        this.assigneeId = e.appointmentData.assigneeId;

      auth.getUser().then((data) => {
        this.usuarioId = data.data.id_usuario;
        this.maintenancePlanService.updatePlanMantenimientoCalendario({"id_plan_mantenimiento": e.appointmentData.referencia,
        "titulo": e.appointmentData.text.split("-")[0].trim(), "descripcion": e.appointmentData.description,
        "fecha_inicio": e.appointmentData.startDate, "fecha_fin": e.appointmentData.endDate,
        "id_prioridad":e.appointmentData.priorityId,"id_estatus": e.appointmentData.groupId,
        "id_usuario_grupo":this.assigneeId,"id_tipo_mantenimiento":e.appointmentData.typeId,
        "id_usuario": this.usuarioId}).then(() => {
            this.type = 'success';
            this.message = 'Se ha actualizado el plan de mantenimiento';
            this.isVisible = true;
            this.maintenancePlanService.getCalendarioPlanMantenimientosPorEmpresa(1).then(data => this.dataSourceMaintenancePlans=data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al actualizar el plan de mantenimiento';
          this.isVisible = true;
          this.maintenancePlanService.getCalendarioPlanMantenimientosPorEmpresa(1).then(data => this.dataSourceMaintenancePlans=data);
        });
      });
    },
    closeClick() {
      this.popupVisible = false;
      this.maintenancePlanService.getCalendarioPlanMantenimientosPorEmpresa(1).then(data => this.dataSourceMaintenancePlans=data);
    },
    showDeletedToast(e) {
      confirm("¿Desea eliminar el plan de mantenimiento seleccionado?", "Eliminación de plan de mantenimiento").then((dialogResult) => {
        if (dialogResult) {
          this.maintenancePlanService.deletePlanMantenimiento({"id_plan_mantenimiento": e.appointmentData.referencia}).then(() => {
              this.type = 'success';
              this.message = 'Se ha eliminado el plan de mantenimiento';
              this.isVisible = true;
              this.maintenancePlanService.getCalendarioPlanMantenimientosPorEmpresa(1).then(data => this.dataSourceMaintenancePlans=data);
          }).catch(() => {
            this.type = 'error';
            this.message = 'Falla al eliminar el plan de mantenimiento';
            this.isVisible = true;
            this.maintenancePlanService.getCalendarioPlanMantenimientosPorEmpresa(1).then(data => this.dataSourceMaintenancePlans=data);
          });
        } else {
          this.maintenancePlanService.getCalendarioPlanMantenimientosPorEmpresa(1).then(data => this.dataSourceMaintenancePlans=data);
        }
      });
    },
  },
};
