
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';

import ConfigChecklistService from '../services/config-checklist-service';
import TypeChecklistService from '../services/type-checklist-service';
import DetailTemplate from './config-checklist-detail.vue';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser
  },
  data() {
    return {
      configlistaverificacion: null,
      dataSourceConfigChecklists: null,
      dataSourceChecklistTypes: null,
      dataSourceFileTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'ListaVerificacion',
        showTitle: true,
        width: 400,
        height: 400,
      },
      mode: 'select',
    };
  },
  created() {
    this.configChecklistService = new ConfigChecklistService();
    this.typeChecklistService = new TypeChecklistService();
  },
  mounted() {        
    this.typeChecklistService.getTipoListaVerificaciones().then(data => this.dataSourceChecklistTypes=data);
    this.configChecklistService.getConfigListaVerificaciones().then(data => this.dataSourceConfigChecklists=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigChecklist(e) {
      this.configChecklistService.updateConfigListaVerificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteConfigChecklist(e) {
			this.configChecklistService.deleteConfigListaVerificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createConfigChecklist(e) {
			this.configChecklistService.createConfigListaVerificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {
      e.data.activo = true;
    },
  },
};
