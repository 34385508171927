
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import GroupTaskService from '../services/group-task-service';
import GroupItemService from '../services/group-item-service';
import GroupFindingService from '../services/group-finding-service';
import GroupAlertService from '../services/group-alert-service';
import GroupMeasurementService from '../services/group-measurement-service';
import GroupPermitService from '../services/group-permit-service';
import GroupUserService from '../services/group-user-service';
import GroupProductService from '../services/group-product-service';
import GroupConfigService from '../services/group-config-service';
import GroupActionService from '../services/group-action-service';
import GroupStageService from '../services/group-stage-service';

import { grupos } from '../data/data.js';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxSelectBox
  },
  props: {
    groupId: {
      type: Number,
      default: null,
    },
  },
  data() {

    return {
      clave: false,
      orientacion: false,
      dataSourceGroup: null,
      dataSourceGroupsCombo: grupos,
      group: null,
      idGrupo: 'id_grupo_tarea',
      grupo: {},
      events: [],
      groupService: null,
      isVisible: false,
      message: '',
      type: 'success',
      chosenGroupId: null,
      groupTaskService: null,
      groupItemService: null,
      groupFindingService: null,
      groupMeasurementService: null,
      groupAlertService: null,
      groupPermitService: null,
      groupUserService: null,
      groupProductService: null,
      groupConfigService: null,
      groupActionService: null,
      groupStageService: null,
    };
  },
  created() {
    this.groupAlertService = new GroupAlertService();
    this.groupItemService = new GroupItemService();
    this.groupFindingService = new GroupFindingService();
    this.groupMeasurementService = new GroupMeasurementService();
    this.groupTaskService = new GroupTaskService();
    this.groupPermitService = new GroupPermitService();
    this.groupUserService = new GroupUserService();
    this.groupProductService = new GroupProductService();
    this.groupConfigService = new GroupConfigService();
    this.groupActionService = new GroupActionService();
    this.groupStageService = new GroupStageService();
  },
  methods: {
    valueChanged(e) {
        const newGroup = e.value;
        switch (newGroup.idGrupo) {
          case 1:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];
            this.idGrupo = 'id_grupo_alerta';
            this.groupAlertService.getGrupoAlertas().then(data => this.dataSourceGroup=data);
            break;
          case 2:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];        
            this.idGrupo = 'id_grupo_articulo';  
            this.groupItemService.getGrupoArticulos().then(data => this.dataSourceGroup=data);
            break;
          case 3:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];        
            this.idGrupo = 'id_grupo_hallazgo';  
            this.groupFindingService.getGrupoHallazgos().then(data => this.dataSourceGroup=data);
            break;
          case 4:
          this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];        
            this.idGrupo = 'id_grupo_medicion';  
            this.groupMeasurementService.getGrupoMediciones().then(data => this.dataSourceGroup=data);
            break;
          case 5:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];        
            this.idGrupo = 'id_grupo_permiso';  
            this.groupPermitService.getGrupoPermisos().then(data => this.dataSourceGroup=data);
            break;
          case 6:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];        
            this.idGrupo = 'id_grupo_tarea';  
            this.groupTaskService.getGrupoTareas().then(data => this.dataSourceGroup=data);
            break;
          case 7:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];        
            this.idGrupo = 'id_usuario_grupo';  
            this.groupUserService.getGrupoUsuarios().then(data => this.dataSourceGroup=data);
            break;
          case 8:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];        
            this.idGrupo = 'id_grupo_producto';  
            this.groupProductService.getGrupoProductos().then(data => this.dataSourceGroup=data);
            break;
          case 9:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];
            this.idGrupo = 'id_grupo_config';
            this.groupConfigService.getGrupoConfiguraciones().then(data => this.dataSourceGroup=data);
            break;
          case 10:
            this.clave = true;
            this.orientacion = true;
            this.dataSourceGroup = [];
            this.idGrupo = 'id_grupo_accion';
            this.groupActionService.getGrupoAcciones().then(data => this.dataSourceGroup=data);
            break;
          case 11:
            this.clave = false;
            this.orientacion = false;
            this.dataSourceGroup = [];
            this.idGrupo = 'id_grupo_etapa';
            this.groupStageService.getGrupoEtapas().then(data => this.dataSourceGroup=data);
            break;
        }
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editGroup(e) {    
      switch (this.group.idGrupo) {
          case 1:
            this.groupAlertService.updateGrupoAlerta(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 2:            
            this.groupItemService.updateGrupoArticulo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 3:            
            this.groupFindingService.updateGrupoHallazgo(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 4:            
            this.groupMeasurementService.updateGrupoMedicion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 5:
            this.groupPermitService.updateGrupoPermiso(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 6:
            this.groupTaskService.updateGrupoTarea(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 7:
            this.groupUserService.updateGrupoUsuario(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 8:
            this.groupProductService.updateGrupoProducto(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 9:
            this.groupConfigService.updateGrupoConfiguracion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 10:
            this.groupActionService.updateGrupoAccion(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
          case 11:
            this.groupStageService.updateGrupoEtapa(e.data).then(() => {            
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;
            });
            break;
        }   			
		},
    deleteGroup(e) {
      switch (this.group.idGrupo) {
        case 1:
          this.groupAlertService.deleteGrupoAlerta(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 2:
          this.groupItemService.deleteGrupoArticulo(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 3:
          this.groupFindingService.deleteGrupoHallazgo(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 4:
          this.groupMeasurementService.deleteGrupoMedicion(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 5:
          this.groupPermitService.deleteGrupoPermiso(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 6:
          this.groupTaskService.deleteGrupoTarea(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 7:
          this.groupUserService.deleteGrupoUsuario(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 8:
          this.groupProductService.deleteGrupoProducto(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 9:
          this.groupConfigService.deleteGrupoConfiguracion(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 10:
          this.groupActionService.deleteGrupoAccion(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
        case 11:
          this.groupStageService.deleteGrupoEtapa(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
          });
          break;
      }
			
		},
    createGroup(e) {
      switch (this.group.idGrupo) {
        case 1:
          this.groupAlertService.createGrupoAlerta(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 2:
          this.groupItemService.createGrupoArticulo(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 3:
          this.groupFindingService.createGrupoHallazgo(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 4:
          this.groupMeasurementService.createGrupoMedicion(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 5:
          this.groupPermitService.createGrupoPermiso(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 6:
          this.groupTaskService.createGrupoTarea(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 7:
          this.groupUserService.createGrupoUsuario(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 8:
          this.groupProductService.createGrupoProducto(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 9:
          this.groupConfigService.createGrupoConfiguracion(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 10:
          this.groupActionService.createGrupoAccion(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
        case 11:
          this.groupStageService.createGrupoEtapa(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
          });
          break;
      }
		},
  },
};
