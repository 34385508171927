import axios from 'axios';

export default class GroupUserService {

	getCalendarioGrupoUsuarios() {			
        const url = process.env.VUE_APP_APIURL + `grupousuarios/calendarios`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getGrupoUsuarios() {	
        const url = process.env.VUE_APP_APIURL + `grupousuarios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoUsuario(grupousuario) {
        const url = process.env.VUE_APP_APIURL + `/grupousuarios/${grupousuario.id_usuario_grupo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoUsuario(grupousuario) {
        const url = process.env.VUE_APP_APIURL + `/grupousuarios/${grupousuario.id_usuario_grupo}`;
		return axios.put(url,grupousuario)
		.then(res=>res.data.data);
	}

	createGrupoUsuario(grupousuario){
        const url = process.env.VUE_APP_APIURL + `/grupousuarios`;
		return axios.post(url,grupousuario).then(res=>res.data.data);
	}

	deleteGrupoUsuario(grupousuario){
        const url = process.env.VUE_APP_APIURL + `/grupousuarios/${grupousuario.id_usuario_grupo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}