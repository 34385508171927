


import {
  DxDataGrid,
  DxEditing,
  DxGrouping,
  DxGroupPanel,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxDateBox from 'devextreme-vue/date-box';
import WorkCalendarService from '../services/work-calendar-service';
import WeekService from '../services/week-service';
import CompanyService from '../services/company-service';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxGrouping,
    DxGroupPanel,    
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSelection,
    DxSorting,
    DxDateBox,
  },
  data() {
    return {
      calendariolaborales: null,
      dataSourceWorkCalendars: null,      
      dataSourceWeeks: null,
      dataSourceCompanies: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      now: new Date(),
      popupOptions: {
        title: 'Calendario Laboral',
        showTitle: true,
        width: 800,
      },
      hora: 'hh:mm a',
    };
  },
  created() {
    this.workCalendarService = new WorkCalendarService();
    this.weekService = new WeekService();
    this.companyService = new CompanyService();

  },
  mounted() {        
    this.weekService.getSemanas().then(data => this.dataSourceWeeks=data); 
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.workCalendarService.getCalendarioLaborales().then(data => this.dataSourceWorkCalendars=data);      
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editWorkCalendar(e) {
      this.workCalendarService.updateCalendarioLaboral(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteWorkCalendar(e) {
			this.workCalendarService.deleteCalendarioLaboral(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createWorkCalendar(e) {
			this.workCalendarService.createCalendarioLaboral(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {      
      e.data.activo = true;      
    },
  },
};
