

import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser,
  DxToolbar,
  DxButton,
} from 'devextreme-vue/data-grid';
import { DxItem, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import { DxButton as DxBtn } from 'devextreme-vue/button';
import DxFileUploader from 'devextreme-vue/file-uploader';

import QuoteService from '../services/quote-service';
import StatusQuoteService from '../services/status-quote-service';
import ClientService from '../services/client-service';
import CompanyService from '../services/company-service';
import UserService from '../services/user-service';
import WarehouseService from '../services/warehouse-service';
import DepartmentService from '../services/department-service';
import DetailTemplate from './quote-detail.vue';
import SupplierService from '../services/supplier-service';
import CurrencyService from '../services/currency-service';
import TypeRequisitionService from '../services/type-requisition-service';
import LocationService from '../services/location-service';
import ProjectService from '../services/project-service';


const fileUploaderRef = "fu";
const imgRef = "img"

const downloadFile = (path, filename) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.setAttribute('target', '_blank');

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
};

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxTabbedItem,
    DxTab,
    DxButton,
    DxToolbar,
    DxFileUploader,
    DxBtn
  },
  data() {
    return {
      unavez: true,
      gridRefName: 'dataGrid',
      usuario: null,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      cotizacion: null,
      detallecotizacion: null,
      dataSourceQuotes: null,
      dataSourceStatus: null,
      dataSourceUsers: null,
      dataSourceCompanies: null,
      dataSourceWarehouse: null,
      dataSourceDepartments: null,
      dataSourceClients: null,
      dataSourceSupplier: null,
      dataSourceCurrencies: null,
      dataSourceProjects: null,
      dataSourceRequisitionTypes: null,
      dataSourceLocations: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Cotización',
        showTitle: true,
        width: 900,
        height: 650
      },
      mode: 'select',
      backendURL: process.env.VUE_APP_APIURL,
      imageURL: process.env.VUE_APP_APIURL + "storage/documentos/",
      retryButtonVisible: false,
      fileUploaderRef,
      imgRef,
      setStateValue(rowData, value) {
        rowData.id_empresa = null;
        this.defaultSetCellValue(rowData, value);
      },
      id_cotizacion: null,      
      addButtonOptions: {
        icon: 'plus',
        onClick: () => {
          this.popupVisible = true;
        },
      },
      refreshButtonOptions: {
        icon: 'refresh',
        onClick: () => {
          this.refresh();
        },
      },
    };
  },
  created() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
    this.typeRequisitionService = new TypeRequisitionService();
    this.quoteService = new QuoteService();
    this.statusQuoteService = new StatusQuoteService();
    this.clientService = new ClientService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.departmentService = new DepartmentService();
    this.supplierService = new SupplierService();
    this.currencyService = new CurrencyService();
    this.typeRequisitionService = new TypeRequisitionService();
    this.locationService = new LocationService();
    this.projectService = new ProjectService();
  },
  mounted() {
    this.typeRequisitionService.getTipoRequisiciones().then(data => this.dataSourceRequisitionTypes = data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations = data);
    this.projectService.getProyectos().then(data => this.dataSourceProjects = data);
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.statusQuoteService.getEstatusCotizaciones().then(data => this.dataSourceStatus = data);
    this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouse = data);
    this.departmentService.getDepartamentos().then(data => this.dataSourceDepartments = data);
    this.quoteService.getCotizacionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceQuotes = data);
    this.supplierService.getProveedores().then(data => this.dataSourceSupplier = data);
    this.currencyService.getMonedas().then(data => this.dataSourceCurrencies = data);
  },
  computed: {
    fileUploader: function() {
      return this.$refs[fileUploaderRef].instance;
    },
    imageElement: function() {
      return this.$refs[imgRef];
    }
  },
  methods: {
    downloadMyFile(e) {
      const liga = this.imageURL + e.row.data.archivo;
      downloadFile(liga, e.row.data.archivo);
    },
    onSaved() {
      if(this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if(this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onClick() {
      // The retry UI/API is not implemented. Use a private API as shown at T611719.
      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
    },
    onValueChanged(e) {
      let reader = new FileReader();
      reader.onload = (args) => {
        this.imageElement.setAttribute('src', args.target.result);
      }
      reader.readAsDataURL(e.value[0]); // convert to base64 string
    },
    onUploaded(e, cellInfo) {
      let fileObject = JSON.parse(e.request.responseText);
      //console.log(fileObject.data.image_name);
      //console.log(cellInfo);
      //cellInfo.setValue("archivos/fotos/" + e.request.responseText);
      cellInfo.setValue(fileObject.data.image_name);
      this.retryButtonVisible = false;
    },
    onUploadError(e) {
      let xhttp = e.request;
      if(xhttp.status === 400){
        e.message = e.error.responseText;
      }
      if(xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
      this.retryButtonVisible = true;
    },
    refreshDataGrid() {
      //this.$refs[this.gridRefName].instance.refresh();
      this.quoteService.getCotizacionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceQuotes = data);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editQuote(e) {
      this.quoteService.updateCotizacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.unavez = true;
        this.quoteService.getCotizacionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceQuotes = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.unavez = true;
        this.quoteService.getCotizacionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceQuotes = data);
      });
    },
    deleteQuote(e) {
      this.quoteService.deleteCotizacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.quoteService.getCotizacionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceQuotes = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.quoteService.getCotizacionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceQuotes = data);
      });
    },
    createQuote(e) {
      var date = new Date(e.data.fecha);
      var datetoLocaleString = date.toLocaleString("en-ZA");
      e.data.fecha = datetoLocaleString;
      var user = JSON.parse(localStorage.getItem("user-data"));
      e.data.id_usuario = user;
      e.data.id_usuario_reg = user;
      this.quoteService.createCotizacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.quoteService.getCotizacionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceQuotes = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.quoteService.getCotizacionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceQuotes = data);
      });
    },
    onInitNewRow(e) {
      this.id_cotizacion = null;
      var user = JSON.parse(localStorage.getItem("user"));
      e.data.fecha = Date.now();
      e.data.id_estatus_cotizacion= 1;
      var resultado = this.dataSourceCompanies.filter((element) => element.id_empresa === user.id_usuario_empresa);
      if (resultado.length > 0) {
        e.data.id_empresa = user.id_usuario_empresa;
      }
    },
    
    onEditorQuote(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'id_estatus_requisicion')) {
        if (e.row.isEditing)
          e.editorOptions.disabled = true;
      }
    },
    
  },
};
