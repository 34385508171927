import axios from 'axios';

export default class EquipmentGenericService {

	getEquipoGenericos() {			
        const url = process.env.VUE_APP_APIURL + `equipogenericos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoGenerico(equipogenerico) {
        const url = process.env.VUE_APP_APIURL + `/equipogenericos/${equipogenerico.id_equipo_generico}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoGenerico(equipogenerico){
        const url = process.env.VUE_APP_APIURL + `/equipogenericos/${equipogenerico.id_equipo_generico}`;
		return axios.put(url,equipogenerico).then(res=>res.data.data);
	}

	createEquipoGenerico(equipogenerico){
        const url = process.env.VUE_APP_APIURL + `/equipogenericos`;
		return axios.post(url,equipogenerico).then(res=>res.data.data);
	}

	createEquipoGenericoDesdeEquipo(equipo){
        const url = process.env.VUE_APP_APIURL + `/equipogenericos/equipos`;
		return axios.post(url,equipo).then(res=>res.data.data);
	}

	deleteEquipoGenerico(equipogenerico){
        const url = process.env.VUE_APP_APIURL + `/equipogenericos/${equipogenerico.id_equipo_generico}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}