import axios from "axios";

const defaultUser = {
  email: "demo@apiclix.com",
  avatarUrl:
    "https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=Jesus+Galaz&rounded=true",
};

export default {
  _user: null,
  _userCred: null,

  loggedIn() {
    return !!this._user;
  },

  setUser(email) {
    this._user = { email: email };
  },

  async logIn(email, password) {
    try {
      //console.log(email, password);
      //console.log(this._user);
      //console.log(process.env.VUE_APP_SECRET);
      //console.log(process.env.VUE_APP_APIURL);
      this._userCred = {
        grant_type: "password",
        client_id: process.env.VUE_APP_CLIENT,
        client_secret: process.env.VUE_APP_SECRET,
        scope: "*",
        username: email,
        password: password,
      };

      const url = process.env.VUE_APP_APIURL + `oauth/token`;
      await axios
        .post(url, this._userCred)
        .then(async (res) => {
          //console.log(res.data.token_type);
          //console.log(res.data.expires_in);
          //console.log(res.data.access_token);
          localStorage.setItem("user-token", res.data.access_token);
          const url = process.env.VUE_APP_APIURL + `token/whois`;
          await axios.get(url).then((resdata) => {
            var userdata = {
              id_usuario_empresa: resdata.data.data.id_usuario_empresa,
              id_rol: resdata.data.data.id_rol,
            };

            localStorage.setItem("user", JSON.stringify(userdata));
            localStorage.setItem("user-data", resdata.data.data.id_usuario);
            this._user = {
              email: resdata.data.data.email,
              id_usuario: resdata.data.data.id_usuario,
              iniciales: resdata.data.data.iniciales,
              id_rol: resdata.data.data.id_rol,
            };
          });
          // Obtener el nombre del usuario y ponerlo en el objeto
          this._user = { ...defaultUser, email };
        })
        .catch((e) => {
          localStorage.removeItem("user-token");
          return Promise.reject(e);
        });

      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
        message: "Usuario o contraseña inválida",
      };
    }
  },

  async logOut() {
    this._user = null;
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-data");
  },

  async getUser() {
    try {
      // Send request
      const url = process.env.VUE_APP_APIURL + `token/whois`;
      await axios.get(url).then((res) => {
        var userdata = {
          id_usuario_empresa: res.data.data.id_usuario_empresa,
          id_rol: res.data.data.id_rol,
        };

        localStorage.setItem("user", JSON.stringify(userdata));
        localStorage.setItem("user-data", res.data.data.id_usuario);
        this._user = {
          email: res.data.data.email,
          id_usuario: res.data.data.id_usuario,
          iniciales: res.data.data.iniciales,
        };
        return {
          isOk: true,
          data: this._user,
        };
      });

      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      const url = process.env.VUE_APP_APIURL + `/password/email`;

      var dataemail = {
        email: email,
      };

      var res = await axios
        .post(url, dataemail)
        .then(() => {
          return {
            isOk: true,
          };
        })
        .catch((error) => {
          return {
            isOk: false,
            message: error.response.data.error,
          };
        });

      return res;
    } catch {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  },

  async changePassword(password, recoveryCode) {
    try {
      // Send request
      const url = process.env.VUE_APP_APIURL + `/password/reset`;

      var dataemail = {
        password: password,
        password_confirmation: password,
        token: recoveryCode,
      };

      var res = await axios
        .post(url, dataemail)
        .then(() => {
          return {
            isOk: true,
          };
        })
        .catch((error) => {
          return {
            isOk: false,
            message: error.response.data.error,
          };
        });

      return res;
    } catch {
      return {
        isOk: false,
        message: "Falla al cambiar la contraseña",
      };
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Falla al crear la cuenta",
      };
    }
  },
};
