import axios from 'axios';

export default class NotificationMethodService {

	getMetodoNotificaciones() {		
        const url = process.env.VUE_APP_APIURL + `metodonotificaciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMetodoNotificacion(metodonotificacion) {
        const url = process.env.VUE_APP_APIURL + `/metodonotificaciones/${metodonotificacion.id_metodo_notificacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMetodoNotificacion(metodonotificacion){
        const url = process.env.VUE_APP_APIURL + `/metodonotificaciones/${metodonotificacion.id_metodo_notificacion}`;
		return axios.put(url,metodonotificacion).then(res=>res.data.data);
	}

	createMetodoNotificacion(metodonotificacion){
        const url = process.env.VUE_APP_APIURL + `/metodonotificaciones`;
		return axios.post(url,metodonotificacion).then(res=>res.data.data);
	}

	deleteMetodoNotificacion(metodonotificacion){
        const url = process.env.VUE_APP_APIURL + `/metodonotificaciones/${metodonotificacion.id_metodo_notificacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}