
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxButtonItem,
  DxLabel,
  DxValidationRule,
  DxButtonOptions ,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxSorting,
  DxColumnChooser,
} from 'devextreme-vue/data-grid';
import { DxLookup } from 'devextreme-vue/lookup'
import DxRadioGroup from 'devextreme-vue/radio-group';
import { movementTypes } from '../data/data.js';
import DxTreeView from 'devextreme-vue/tree-view';
import DxDateBox from 'devextreme-vue/date-box';
import { DxToast } from 'devextreme-vue/toast';
import { DxNumberBox } from 'devextreme-vue/number-box';


import BrandService from '../services/brand-service';
import CompanyService from '../services/company-service';
import TypeItemService from '../services/type-item-service';
import GroupItemService from '../services/group-item-service';
import WarehouseService from '../services/warehouse-service';
import TypeMovementService from '../services/type-movement-service';
import StockRequestService from '../services/stock-request-service';
import StockSerieService from '../services/stock-serie-service';
import ItemService from '../services/item-service';
import StockTransferService from '../services/stock-transfer-service';
import auth from '../auth';

const submitButtonOptions = {
    text: "Registrar Movimiento",
    type: 'default',
    useSubmitBehavior: true
};

const treeViewRefSeries = 'treeViewSeries';

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxRadioGroup,
    DxTreeView,
    DxLookup,
    DxLabel,
    DxValidationRule,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxSorting,
    DxToast,
    DxDateBox,
    DxColumnChooser,
    DxNumberBox,
    DxButtonOptions,
},
  data() {
    const selectionModes = ['multiple', 'single'];
    const showCheckBoxesModes = ['normal', 'selectAll', 'none'];
    
    return {
      isCantidadVisible: false,
      cantidadSeleccionada: 0,
      encontro: false,
      id: null,
      modelo: null,
      noParte: null,
      idArticulo: null,
      idValeElectronicoArticulo: null,
      descripcionArticulo: null,
      unidadMedida: null,
      idMarca: null,
      clave: null,
      cantidadCapturada: 1,
      cantidadSolicitada: 0,
      cantidadPendiente: 0,
      existencia: 0,
      deshabilitaAlmacenDestino: true,
      deshabilitaValeElectronico: true,
      deshabilitaTipoArticulo: false,
      deshabilitaGrupoArticulo: false,
      mode: 'select',
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      fecha: new Date(),
      idUsuario: null,
      idEmpresa: null,
      idAlmacen: null,
      idTipoMovimiento: 2,
      idAlmacenDestino: null,
      idValeElectronico: null,
      idTipoArticulo: null,
      idGrupoArticulo: null,
      movementTypeValue: movementTypes[1],
      searchMode: 'contains',
      treeViewRefSeries,
      movementTypes,
      stockTransferFormData: null,
      dataSourceMovementTypes: null,
      dataSourceCompanies: null,
      dataSourceStockRequests: null,
      dataSourceWarehouses: null,
      dataSourceItemTypes: null,
      dataSourceItemGroups: null,
      dataSourceBrands: null,
      dataSourceSelectedItems: null,
      dataSourceSeries: null,
      dataSourceAvailableItems: null,
      submitButtonOptions,
      showCheckBoxesModeValue: showCheckBoxesModes[0],
      selectionModeValue: selectionModes[0],
      selectedItems: [],
      selectedSeries: [],
      selectedItemsTemp: [],
      dateOptions: { width: '100%', value: new Date(), type: "datetime", dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss" },      
      descriptionOptions: { width: '100%' },
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.itemService = new ItemService();
    this.typeMovementService = new TypeMovementService();
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.brandService = new BrandService();
    this.typeItemService = new TypeItemService();
    this.groupItemService = new GroupItemService();
    this.stockRequestService = new StockRequestService();
    this.stockSerieService = new StockSerieService();
    this.stockTransferService = new StockTransferService();
  },
  mounted() {
    this.typeMovementService.getTipoMovimientoSalidas().then((data) => {
      this.dataSourceMovementTypes=data;
      this.idTipoMovimiento=data[0].id_tipo_movimiento;
    });
    this.companyService.getEmpresas().then((data) => {
      this.dataSourceCompanies=data;
      this.idEmpresa=data[0].id_empresa;
    });
    this.warehouseService.getAlmacenes().then((data) => {
      this.dataSourceWarehouses=data;
      
      if (data.length>0) {
        this.idAlmacen=data[0].id_almacen;
      }
    });
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
    this.typeItemService.getTipoArticulosTodosCombo().then(data => {
      this.dataSourceItemTypes=data;
      this.idTipoArticulo = 0;
    });
    this.groupItemService.getGrupoArticulosTodosCombo().then(data => {
      this.dataSourceItemGroups=data;
      this.idGrupoArticulo = 0;
    });
  },
  computed: {
    treeViewSeries() {
      return this.$refs[treeViewRefSeries].instance;
    },
  },
  methods: {
    onSelectionChangedAvailableItems({ selectedRowsData }) {
      if (selectedRowsData.length>0) {
        const data = selectedRowsData[0];
        this.cantidadCapturada = parseFloat(data.cantidad);
        this.cantidadSolicitada = parseFloat(data.cantidad);
        this.cantidadPendiente = parseFloat(data.cantidad_pendiente);
        this.unidadMedida = data.um;
        this.clave = data.clave;
        this.descripcionArticulo = data.nombre;
        this.idMarca = data.id_marca;
        this.id = data.id;
        this.modelo = data.modelo;
        this.noParte = data.numero_parte;
        if (data.existencia!=null)
          this.existencia = parseFloat(data.existencia);
        else {
          this.existencia = 0;
        }
        if (this.idValeElectronico!=null) {
          this.idValeElectronicoArticulo = data.id;
          this.idArticulo = data.id_articulo;
        } else {
          this.idValeElectronicoArticulo = null;
          this.idArticulo = data.id;
        }
      } else {
        this.cantidadCapturada = 0;
        this.cantidadSolicitada = 0;
        this.unidadMedida = null;
        this.clave = null;
        this.descripcionArticulo = null;
        this.idMarca = null;
        this.id = null;
        this.modelo = null;
        this.noParte = null;
        this.existencia = 0;
        this.idValeElectronicoArticulo = null;
        this.idArticulo = null;
      }
    },
    addItemClick(){
      this.encontro = false;
      const arrayItems = [];
      this.cantidadSeleccionada = 0;

      if ((this.idValeElectronico!=null)&&(this.movementTypeValue=="Salida")) {
        // Validar cantidad solicitada
        if (parseFloat(this.cantidadCapturada)>parseFloat(this.cantidadSolicitada)) {
          this.type = 'error';
          this.message = 'La cantidad capturada no debe exceder la cantidad solicitada del vale';
          this.isVisible = true;
          return;
        }
        // Validar cantidad pendiente
        if (parseFloat(this.cantidadCapturada)>parseFloat(this.cantidadPendiente)) {
          this.type = 'error';
          this.message = 'La cantidad capturada no debe exceder la cantidad pendiente del vale';
          this.isVisible = true;
          return;
        }
      }

      // Valida que la cantidad sea mayor a 0
      if (parseFloat(this.cantidadCapturada)==0) {
        this.type = 'error';
        this.message = 'La cantidad capturada debe ser mayor a cero';
        this.isVisible = true;
        return;
      }

      //Valida existencia
      if (this.movementTypeValue=="Salida") {
        if (parseFloat(this.cantidadCapturada)>parseFloat(this.existencia)) {
          this.type = 'error';
          this.message = 'Existencia insuficiente';
          this.isVisible = true;
          return;
        }
      }
      
      // Verifica que no exista el id
      for(var i in this.dataSourceSelectedItems) {
        if (this.dataSourceSelectedItems[i].id_articulo==this.idArticulo) {
          this.cantidadSeleccionada = parseFloat(this.dataSourceSelectedItems[i].cantidad);
          if (this.movementTypeValue=="Salida") {
            if (parseFloat(this.cantidadCapturada)>(parseFloat(this.existencia)-parseFloat(this.cantidadSeleccionada))) {
              this.type = 'error';
              this.message = 'Existencia insuficiente';
              this.isVisible = true;
              return;
            }
          }
          // Validar cantidad pendiente
          if ((this.idValeElectronico!=null)&&(this.movementTypeValue=="Salida")) {
            if (parseFloat(this.cantidadCapturada)+parseFloat(this.cantidadSeleccionada)>(parseFloat(this.cantidadPendiente))) {
              this.type = 'error';
              this.message = 'La cantidad capturada no debe exceder la cantidad pendiente del vale';
              this.isVisible = true;
              return;
            }
          }
          this.dataSourceSelectedItems[i].cantidad = parseFloat(this.dataSourceSelectedItems[i].cantidad) + parseFloat(this.cantidadCapturada);
          this.encontro = true;
        }
      }

      // Agregar artículo
      if (this.encontro==false) {
        if (this.dataSourceSelectedItems==null) {
          arrayItems.push({"id_articulo": this.idArticulo, "cantidad": this.cantidadCapturada, 
          "nombre": this.descripcionArticulo, "clave": this.clave, "um": this.unidadMedida, "id_marca": this.idMarca,
          "modelo": this.modelo, "numero_parte": this.noParte, "id_vale_electronico_articulo": this.idValeElectronicoArticulo});
          this.dataSourceSelectedItems = arrayItems;
        } else {
          for(var j in this.dataSourceSelectedItems) {
            arrayItems.push({"id_articulo": this.dataSourceSelectedItems[j].id_articulo, "cantidad": this.dataSourceSelectedItems[j].cantidad, 
            "nombre": this.dataSourceSelectedItems[j].nombre, "clave": this.dataSourceSelectedItems[j].clave, "um": this.dataSourceSelectedItems[j].um, "id_marca": this.dataSourceSelectedItems[j].id_marca,
            "modelo": this.dataSourceSelectedItems[j].modelo, "numero_parte": this.dataSourceSelectedItems[j].numero_parte,
            "id_vale_electronico_articulo": this.dataSourceSelectedItems[j].id_vale_electronico_articulo});
          }
          arrayItems.push({"id_articulo": this.idArticulo, "cantidad": this.cantidadCapturada, 
          "nombre": this.descripcionArticulo, "clave": this.clave, "um": this.unidadMedida, "id_marca": this.idMarca,
          "modelo": this.modelo, "numero_parte": this.noParte, "id_vale_electronico_articulo": this.idValeElectronicoArticulo});
          this.dataSourceSelectedItems = arrayItems;
        }
      }
    },
    
    handleSubmit(e) {
      auth.getUser().then((data) => {
        this.idUsuario = data.data.id_usuario;
        this.selectedItems = [];
        for(var i in this.dataSourceSelectedItems) {
          this.selectedItems.push({ "id_articulo": this.dataSourceSelectedItems[i].id_articulo, "cantidad": this.dataSourceSelectedItems[i].cantidad,
          "id_vale_electronico_articulo": this.dataSourceSelectedItems[i].id_vale_electronico_articulo });
          //this.selectedItems.push({"id_equipo_empresa": this.dataSourceSelectedItems[i].id_equipo_empresa, "id_tarea": this.dataSourceSelectedItems[i].id_tarea});
        }
        this.stockTransferService.createMovimiento({"id_empresa": this.idEmpresa, "fecha": this.fecha,
        "id_tipo_movimiento": this.idTipoMovimiento, "id_almacen": this.idAlmacen,"referencia": this.stockTransferFormData.referencia,
        "id_almacen_destino": this.idAlmacenDestino, "id_vale_electronico": this.idValeElectronico,
        "articulos": this.selectedItems,"series": this.selectedSeries, "id_usuario": this.idUsuario, 
        "descripcion": this.stockTransferFormData.descripcion
        }).then(() => {
          this.type = 'success';
          this.message = 'Se ha registrado el movimiento correctamente';
          this.isVisible = true;
          this.cleanForm();
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al registrar el movimiento';
          this.isVisible = true;
        });
      });
      
      e.preventDefault();
    },
    cleanForm() {
      //this.stockTransferFormData.instance.resetValues();
      //this.stockTransferFormData.titulo = '',
      //this.idTipoMantenimiento = null,
      //this.stockTransferFormData.fechaInicio = null,
      this.stockTransferFormData.descripcion = null;
      this.stockTransferFormData.referencia = null;
      this.fecha = new Date();
      //this.idTipoMovimiento = null;
      this.idTipoArticulo = 0;
      this.idGrupoArticulo = 0;
      //this.idAlmacen = null;
      this.idAlmacenDestino = null;
      this.selectedItems = [];
      this.selectedSeries = [];
      this.idValeElectronico = null;
      this.dataSourceSelectedItems = null;
      /*
      //this.stockTransferFormData.instance.resetValues();
      */
    },
    valueChangedItemGroup() {
      // Si el movimiento es de Vale Electrónico, cargar los artículos
      if ((this.idAlmacen>0)&&(this.idValeElectronico==null)) {
        this.itemService.getArticulosPorFiltro({ id_almacen: this.idAlmacen, id_tipo_articulo: this.idTipoArticulo==0?null:this.idTipoArticulo, id_grupo_articulo: this.idGrupoArticulo==0?null:this.idGrupoArticulo }).then((data) => this.dataSourceAvailableItems=data);
      }
    },
    valueChangedItemType() {
      // Si el movimiento es de Vale Electrónico, cargar los artículos
      if ((this.idAlmacen>0)&&(this.idValeElectronico==null)) {
        this.itemService.getArticulosPorFiltro({ id_almacen: this.idAlmacen, id_tipo_articulo: this.idTipoArticulo==0?null:this.idTipoArticulo, id_grupo_articulo: this.idGrupoArticulo==0?null:this.idGrupoArticulo }).then((data) => this.dataSourceAvailableItems=data);
      }
    },
    valueChangedCompany(e) {
      // Cambiar de almacen
      this.warehouseService.getAlmacenesPorEmpresa(e.value).then((data) => {
        this.dataSourceWarehouses=data;
        if (data.length>0) {
          this.idAlmacen=data[0].id_almacen;
        }
      });
    },
    valueChangedWarehouse(e) {
      // Actualiza combo de vales electronicos
      if (this.idTipoMovimiento==2 || this.idTipoMovimiento==3) {
        this.deshabilitaValeElectronico = false;
        this.stockRequestService.getValesElectronicosPorAlmacen(e.value).then((data) => {
          this.dataSourceStockRequests=data;
          this.idValeElectronico = null;
        });
      }
      this.itemService.getArticulosPorFiltro({ id_almacen: this.idAlmacen, id_tipo_articulo: this.idTipoArticulo==0?null:this.idTipoArticulo, id_grupo_articulo: this.idGrupoArticulo==0?null:this.idGrupoArticulo }).then((data) => this.dataSourceAvailableItems=data);
      // Recargar los articulos

    },
    valueChangedMovementType(e) {
      // Si el movimiento es de Vale Electrónico, cargar los artículos
      // Si es un traspaso
      if (e.value==5) {
        this.deshabilitaAlmacenDestino = false;
      } else {
        this.idAlmacenDestino = null;
        this.deshabilitaAlmacenDestino = true;
      }
      // Devolución de Vale electrónico o salida por vale electrónico
      if ((e.value==3)||(e.value==2)) {
        this.deshabilitaValeElectronico = false;
      } else {
        this.idValeElectronico = null;
        this.deshabilitaValeElectronico = true;
      }
    },
    valueChangedStockRequest(e) {
      // Al seleccionar un vale electrónico
      if (e.value>0) {
        this.idValeElectronico = e.value;
        this.idTipoArticulo = 0;
        this.idGrupoArticulo = 0;
        this.deshabilitaTipoArticulo = true;
        this.deshabilitaGrupoArticulo = true;
        this.isCantidadVisible = true;
        this.itemService.getArticulosPorValeElectronico(e.value).then((data) => this.dataSourceAvailableItems=data);
      } else {
        this.isCantidadVisible = false;
        this.deshabilitaTipoArticulo = false;
        this.deshabilitaGrupoArticulo = false;
        this.dataSourceAvailableItems = null;
        this.itemService.getArticulosPorFiltro({ id_almacen: this.idAlmacen, id_tipo_articulo: this.idTipoArticulo==0?null:this.idTipoArticulo, id_grupo_articulo: this.idGrupoArticulo==0?null:this.idGrupoArticulo }).then((data) => this.dataSourceAvailableItems=data);
      }
      // Cargar artículos
      
    },
    changeSelectionSource(e) {
      if (e.value=='Entrada') {
        this.movementTypeValue = movementTypes[0];
        this.typeMovementService.getTipoMovimientoEntradas().then((data) => {
          this.dataSourceMovementTypes=data;
          this.idTipoMovimiento=data[0].id_tipo_movimiento;
        });
      }
      if (e.value=='Salida') { 
        this.movementTypeValue = movementTypes[1];
        this.typeMovementService.getTipoMovimientoSalidas().then((data) => {
          this.dataSourceMovementTypes=data;
          this.idTipoMovimiento=data[0].id_tipo_movimiento;
        });
      }
    },
    treeViewSelectionChangedSeries(e) {
      this.syncSelectionSeries(e.component);
    },

    treeViewContentReadySeries(e) {
      this.syncSelectionSeries(e.component);
    },

    syncSelectionSeries(treeView) {
      const selectedSeries = treeView.getSelectedNodes()
        .map((node) => node.itemData);

      this.selectedSeries = [];

      for(var i in selectedSeries)
        this.selectedSeries.push(selectedSeries[i].id_existencia_serie);
    },

    deleteItem(e) {
      console.log(e);
      /*
			this.measurementService.deleteMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar la medición';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
      });
      */
	},
  } 

};
