import axios from 'axios';

export default class TaskMaintenanceTypeService {

	getTareaTipoMantenimientos(tarea) {			
        const url = process.env.VUE_APP_APIURL + `tareatipomantenimientos/${tarea}`;	
		return axios.get(url).then(res=>res.data.data);
	}

	createTareaTipoMantenimiento(tareatipomantenimiento){
        const url = process.env.VUE_APP_APIURL + `tareatipomantenimientos`;
		return axios.post(url,tareatipomantenimiento).then(res=>res.data.data);
	}

	deleteTareaTipoMantenimiento(tarea,tipomantenimiento){
        const url = process.env.VUE_APP_APIURL + `tareatipomantenimientos/tareas/${tarea}/tipomantenimientos/${tipomantenimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}