import axios from "axios";

export default class PhysicalInventoryService {
  getInventarioFisicos() {
    const url = process.env.VUE_APP_APIURL + `inventariofisicos`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getInventarioFisico(inventariofisico) {
    const url =
      process.env.VUE_APP_APIURL +
      `/inventariofisicos/${inventariofisico.id_inventario_fisico}`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  updateInventarioFisico(inventariofisico) {
    const url =
      process.env.VUE_APP_APIURL +
      `/inventariofisicos/${inventariofisico.id_inventario_fisico}`;
    return axios
      .put(url, inventariofisico)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createInventarioFisico(inventariofisico) {
    const url = process.env.VUE_APP_APIURL + `/inventariofisicos`;
    return axios
      .post(url, inventariofisico)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteInventarioFisico(inventariofisico) {
    const url =
      process.env.VUE_APP_APIURL +
      `/inventariofisicos/${inventariofisico.id_inventario_fisico}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
