
var dataSourceWorkOrder = [];
var dataSourceStockRequestItems = [];
var dataSourceStockRequestItems1 = [];
var dataSourceItem1 = [];
var dataSourceUnitOfMeasurement1 = [];
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser,
  DxPopup,
  DxToolbar
} from 'devextreme-vue/data-grid';
import { DxItem, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import { DxButton } from 'devextreme-vue/button';

import StockRequestService from '../services/stock-request-service';
import StatusRequestService from '../services/status-request-service';
import ClientService from '../services/client-service';
import CompanyService from '../services/company-service';
import UserService from '../services/user-service';
import WarehouseService from '../services/warehouse-service';
import DetailTemplate from './stock-request-detail.vue';
import WorkOrderService from '../services/workorder-service';
import ItemService from '../services/item-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import StockRequestItemService from '../services/stock-request-item-service';
import TypeItemService from '../services/type-item-service'
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxTabbedItem,
    DxTab,
    DxPopup,
    DxButton,
    DxToolbar,
  },
  data() {
    return {
      gridRefName: 'dataGrid',
      usuario: null,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      valeelectronico: null,
      detallevaleelectronico: null,
      dataSourceStockRequests: null,
      dataSourceStatus: null,
      dataSourceUsers: null,
      dataSourceCompanies: null,
      dataSourceWarehouse: null,
      dataSourceClients: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Vale Electrónico',
        showTitle: true,
        width: 800,
      },
      mode: 'select',
      dataSourceStockRequestItems1,
      dataSourceWorkOrder,
      dataSourceItem: null,
      dataSourceStockRequestItems,
      dataSourceUnitOfMeasurement: null,
      dataSourceTypeItem: null,
      dataSourceItem1,
      dataSourceUnitOfMeasurement1,
      setStateValue(rowData, value) {
        rowData.id_empresa = null;
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueArticulo(rowData, value) {
        var resultado = dataSourceItem1.filter((element) => element.id_articulo === value);
        if (resultado.length > 0) {
          rowData.id_unidad_medida = resultado[0].id_unidad_medida;
        }
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueTipoArticulo(rowData, value) {
        rowData.id_articulo = null;
        this.defaultSetCellValue(rowData, value);
      },
      id_vale_electronico: null,
      dataSourdeItems: null,
      addButtonOptions: {
        icon: 'plus',
        onClick: () => {
          this.popupVisible = true;
        },
      },
      refreshButtonOptions: {
        icon: 'refresh',
        onClick: () => {
          this.refresh();
          //notify('Actividades actualizadas');
        },
      },
    };
  },
  created() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
    this.stockRequestService = new StockRequestService();
    this.statusRequestService = new StatusRequestService();
    this.clientService = new ClientService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.workOrderService = new WorkOrderService();
    this.itemService = new ItemService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.stockRequestItemService = new StockRequestItemService();
    this.typeItemService = new TypeItemService();
  },
  mounted() {
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.statusRequestService.getEstatusValeElectronicos().then(data => this.dataSourceStatus = data);
    this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouse = data);
    this.stockRequestService.getValeElectronicosPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceStockRequests = data);
    this.workOrderService.getworkordersheet().then(data => dataSourceWorkOrder = data);
    this.typeItemService.getTipoArticulosCombo().then(data => this.dataSourceTypeItem = data);
    this.itemService.getArticuloComboActivos().then(data => {
      dataSourceItem1 = data;
      this.dataSourceItem = data;
    });
    this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
      dataSourceUnitOfMeasurement1 = data;
      this.dataSourceUnitOfMeasurement = data;
    });
  },
  methods: {
    refreshDataGrid() {
      //this.$refs[this.gridRefName].instance.refresh();
      this.stockRequestService.getValeElectronicosPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceStockRequests = data);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editStockRequest(e) {
      console.log('editStockRequest');
      this.stockRequestService.updateValeElectronico(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.stockRequestService.getValeElectronicosPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceStockRequests = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.stockRequestService.getValeElectronicosPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceStockRequests = data);
      });
      this.dataSourceStockRequestItems.forEach(element => {
        if (typeof (element.id_vale_electronico) === "undefined") {
          var dataJson = {
            id_vale_electronico: e.data.id_vale_electronico,
            cantidad: element.cantidad,
            id_unidad_medida: element.id_unidad_medida,
            id_articulo: element.id_articulo,
          };
          this.stockRequestItemService.createValeElectronicoArticulo(dataJson);
        }
      });
    },
    deleteStockRequest(e) {
      console.log('deleteStockRequest');
      this.stockRequestService.deleteValeElectronico(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.stockRequestService.getValeElectronicosPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceStockRequests = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.stockRequestService.getValeElectronicosPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceStockRequests = data);
      });
    },
    createStockRequest(e) {
      var date = new Date(e.data.fecha);
      var datetoLocaleString = date.toLocaleString("en-ZA");
      e.data.fecha = datetoLocaleString;
      var user = JSON.parse(localStorage.getItem("user-data"));
      e.data.id_usuario = user;
      this.stockRequestService.createValeElectronico(e.data).then((data) => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        if (this.dataSourdeItems != null) {
          this.dataSourdeItems.forEach(element => {
            var dataJson = {
              id_vale_electronico: data.id_vale_electronico,
              cantidad: element.cantidad,
              id_unidad_medida: element.id_unidad_medida,
              id_articulo: element.id_articulo,
            };
            this.stockRequestItemService.createValeElectronicoArticulo(dataJson);
          });
        }
        this.stockRequestService.getValeElectronicosPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceStockRequests = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.stockRequestService.getValeElectronicosPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceStockRequests = data);
      });
    },
    onInitNewRow(e) {
      this.id_vale_electronico = null;
      var user = JSON.parse(localStorage.getItem("user"));
      e.data.fecha = Date.now();
      e.data.id_estatus_vale_electronico = 1;
      var resultado = this.dataSourceCompanies.filter((element) => element.id_empresa === user.id_usuario_empresa);
      if (resultado.length > 0) {
        e.data.id_empresa = user.id_usuario_empresa;
      }
    },
    onEditorStockRequest(e) {
      /*
      this.dataSourceStockRequestItems = [];
      this.dataSourceItem = [];
      this.dataSourceUnitOfMeasurement = [];
      //this.typeItemService.getTipoArticulosCombo().then(data => this.dataSourceTypeItem = data);
      this.itemService.getArticulosComboActive().then(data => {
        dataSourceItem1 = data;
        this.dataSourceItem = data;
      });
      this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
        dataSourceStockRequestItems1 = data;
        this.dataSourceUnitOfMeasurement = data;
      });
      */
      if (e.parentType === 'dataRow' && (e.dataField === 'id_estatus_vale_electronico'))  {
        if (e.row.isEditing)
          e.editorOptions.disabled = true;
      }
      if (typeof (e.row) != "undefined") {
        if (e.parentType === 'dataRow' && (e.dataField === 'id_estatus_vale_electronico'))  {
          if (!e.row.isEditing) {
            this.id_vale_electronico = e.row.data.id_vale_electronico;
            var dataJson = {
              id_vale_electronico: e.row.data.id_vale_electronico,
            };
            this.stockRequestItemService.getValeElectronicoArticuloID(dataJson).then(data => {
              this.dataSourceStockRequestItems = data;
            });
          }
        }
      }
      /*
      if (typeof (e.row) != "undefined") {

        this.id_vale_electronico = e.row.data.id_vale_electronico;
        var dataJson = {
          id_vale_electronico: e.row.data.id_vale_electronico,
        };
        console.log('onEditorStockRequest');
        this.stockRequestItemService.getValeElectronicoArticuloID(dataJson).then(data => {
          this.dataSourceStockRequestItems = data;
        });
      }
      */
    },
    getFilteredOT: (options) => (
      {
        store: dataSourceWorkOrder,
        filter: options.data ? ['id_empresa', '=', options.data.id_empresa] : null,
      }),
    createRequestItems(e) {
      this.dataSourdeItems = this.dataSourceStockRequestItems;
      e.data.id_vale_electronico = this.id_vale_electronico;
      if (this.id_vale_electronico != null) {
        this.stockRequestItemService.createValeElectronicoArticulo(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.stockRequestItemService.getValeElectronicoArticuloID({id_vale_electronico: this.id_vale_electronico}).then(data => this.dataSourceStockRequestItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.dataSourceStockRequestItems = [];
          this.stockRequestItemService.getValeElectronicoArticuloID({id_vale_electronico: this.id_vale_electronico}).then(data => this.dataSourceStockRequestItems = data);
        });
      }
    },
    editStockRequestItems(e) {
      e.data.id_vale_electronico = this.id_vale_electronico;
      if (this.id_vale_electronico != null) {
        var dataJson = {
          id_vale_electronico: this.id_vale_electronico,
          id_vale_electronico_articulo: e.data.id_vale_electronico_articulo,
          id_articulo: e.data.id_articulo,
          cantidad: e.data.cantidad,
        };
        this.stockRequestItemService.updateValeElectronicoArticulo(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.stockRequestItemService.getValeElectronicoArticuloID({id_vale_electronico: this.id_vale_electronico}).then(data => this.dataSourceStockRequestItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.stockRequestItemService.getValeElectronicoArticuloID({id_vale_electronico: this.id_vale_electronico}).then(data => this.dataSourceStockRequestItems = data);
        });
      }
    },
    deleteStockRequestItems(e) {
      e.data.id_vale_electronico = this.id_vale_electronico;
      if (this.id_vale_electronico != null) {
        var dataJson = {
          id_vale_electronico_articulo: e.data.id_vale_electronico_articulo,
        };
        this.stockRequestItemService.deleteValeElectronicoArticulo(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.stockRequestItemService.getValeElectronicoArticuloID({id_vale_electronico: this.id_vale_electronico}).then(data => this.dataSourceStockRequestItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.stockRequestItemService.getValeElectronicoArticuloID({id_vale_electronico: this.id_vale_electronico}).then(data => this.dataSourceStockRequestItems = data);
        });
      }
    },
    getFilteredArticulo: (options) => (
    {
      store: dataSourceItem1,
      filter: options.data ? ['id_tipo_articulo', '=', options.data.id_tipo_articulo] : null,
    }),
    onInitNewRowStockRequestItems(e) {
      e.data.cantidad = 1;
      e.data.id_tipo_articulo = 1;
    },
  },
};
