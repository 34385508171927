import axios from 'axios';

export default class ConfigPlanMaintenanceTaskMeasurementService {

	getMedicionesDisponiblesPorPlanTarea(configplanmttoequipotarea) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareamediciones/disponibles/${configplanmttoequipotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getMedicionesAsignadasPorPlanTarea(configplanmttoequipotarea) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareamediciones/asignadas/${configplanmttoequipotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getConfigPlanMttoTareaMediciones() {			
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareamediciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigPlanMttoTareaMedicion(configplanmttoequipotareamedicion) {
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareamediciones/${configplanmttoequipotareamedicion.id_config_plan_mtto_tarea_medicion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createConfigPlanMttoTareaMedicion(configplanmttoequipotareamedicion){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareamediciones`;
		return axios.post(url,configplanmttoequipotareamedicion).then(res=>res.data.data);
	}

	updateConfigPlanMttoTareaMedicion(configplanmttoequipotareamedicion){
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareamediciones/${configplanmttoequipotareamedicion.id_config_plan_mtto_tarea_medicion}`;
		return axios.put(url,configplanmttoequipotareamedicion).then(res=>res.data.data);
	}

	deleteConfigPlanMttoTareaMedicion(configplanmttoequipotareamedicion){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareamediciones/${configplanmttoequipotareamedicion.id_config_plan_mtto_tarea_medicion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}

