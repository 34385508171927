
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import AreaTab from './area-tab.vue';
import AisleTab from './aisle-tab.vue';
import RackGroupTab from './rack-group-tab.vue';
import RackTab from './rack-tab.vue';
import LevelTab from './level-tab.vue';
export default {
    components: {
        DxTabPanel,
        DxItem,
        AreaTab,
        AisleTab,
        RackGroupTab,
        RackTab,
        LevelTab,
    },
    data() {
        return {
            selectedIndex: 0,
            loop: true,
            animationEnabled: true,
            swipeEnabled: false,
        }
    },
    methods: {}
}
