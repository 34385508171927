import axios from 'axios';

export default class CancelReasonService {

	getMotivoCancelaciones() {			
        const url = process.env.VUE_APP_APIURL + `motivocancelaciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMotivoCancelacion(motivocancelacion) {
        const url = process.env.VUE_APP_APIURL + `/motivocancelaciones/${motivocancelacion.id_motivo_cancelacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMotivoCancelacion(motivocancelacion){
        const url = process.env.VUE_APP_APIURL + `/motivocancelaciones/${motivocancelacion.id_motivo_cancelacion}`;
		return axios.put(url,motivocancelacion).then(res=>res.data.data);
	}

	createMotivoCancelacion(motivocancelacion){
        const url = process.env.VUE_APP_APIURL + `/motivocancelaciones`;
		return axios.post(url,motivocancelacion).then(res=>res.data.data);
	}

	deleteMotivoCancelacion(motivocancelacion){
        const url = process.env.VUE_APP_APIURL + `/motivocancelaciones/${motivocancelacion.id_motivo_cancelacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}