import axios from 'axios';

export default class ConsecutiveService {

	getFolios() {		
        const url = process.env.VUE_APP_APIURL + `folios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getFolio(folio) {
        const url = process.env.VUE_APP_APIURL + `/folios/${folio.id_folio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateFolio(folio){
        const url = process.env.VUE_APP_APIURL + `/folios/${folio.id_folio}`;
		return axios.put(url,folio).then(res=>res.data.data);
	}

	createFolio(folio){
        const url = process.env.VUE_APP_APIURL + `/folios`;
		return axios.post(url,folio).then(res=>res.data.data);
	}

	deleteFolio(folio){
        const url = process.env.VUE_APP_APIURL + `/folios/${folio.id_folio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}