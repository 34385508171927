import axios from 'axios';

export default class SpecialFieldService {

	getCampos() {		
        const url = process.env.VUE_APP_APIURL + `campos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getCampo(campo) {
        const url = process.env.VUE_APP_APIURL + `/campos/${campo.id_campo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateCampo(campo){
        const url = process.env.VUE_APP_APIURL + `/campos/${campo.id_campo}`;
		return axios.put(url,campo).then(res=>res.data.data);
	}

	createCampo(campo){
        const url = process.env.VUE_APP_APIURL + `/campos`;
		return axios.post(url,campo).then(res=>res.data.data);
	}

	deleteCampo(campo){
        const url = process.env.VUE_APP_APIURL + `/campos/${campo.id_campo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}