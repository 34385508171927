import axios from 'axios';

export default class TypeControlService {

	getTipoControles() {			
        const url = process.env.VUE_APP_APIURL + `tipocontroles`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoControl(tipocontrol) {
        const url = process.env.VUE_APP_APIURL + `/tipocontroles/${tipocontrol.id_tipo_control}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoControl(tipocontrol){
        const url = process.env.VUE_APP_APIURL + `/tipocontroles/${tipocontrol.id_tipo_control}`;
		return axios.put(url,tipocontrol).then(res=>res.data.data);
	}

	createTipoControl(tipocontrol){
        const url = process.env.VUE_APP_APIURL + `/tipocontroles`;
		return axios.post(url,tipocontrol).then(res=>res.data.data);
	}

	deleteTipoControl(tipocontrol){
        const url = process.env.VUE_APP_APIURL + `/tipocontroles/${tipocontrol.id_tipo_control}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}