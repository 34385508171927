
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxEditing,
  DxSorting,
  DxGroupPanel,
  DxGrouping,
  DxLookup,
  DxValidationRule,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';

import KitService from '../services/kit-service';
import ItemService from '../services/item-service';
import KitItemService from '../services/kit-item-service';
import TypeItemService from '../services/type-item-service';
import GroupItemService from '../services/group-item-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting,
    DxGroupPanel,
    DxGrouping,
    DxLookup,
    DxValidationRule,
  },
  data() {
    return {
      kits: null,
      dataSourceItemTypes: null,
      dataSourceItemGroups: null,
      dataSourceKits: null,
      dataSourceItemAvailable: null,
      dataSourceItemAssigned: null,
      dataSourceUnitOfMeasurements: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
      autoExpandAll: false,
    };
  },
  created() {
    this.kitService = new KitService();
    this.itemService = new ItemService();
    this.kitItemService = new KitItemService();
    this.typeItemService = new TypeItemService();
    this.groupItemService = new GroupItemService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
  },
  mounted() {
    this.kitService.getKits().then(data => this.dataSourceKits=data);
    this.typeItemService.getTipoArticulos().then(data => this.dataSourceItemTypes=data);
    this.groupItemService.getGrupoArticulos().then(data => this.dataSourceItemGroups=data);
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitOfMeasurements=data);
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_articulo);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addKit() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.kitItemService.createKitArticulo({"id_kit": this.kits.id_kit, "id_articulo": this.selectedRowsData[this.i].id_articulo, "cantidad": 1}).then(() => {           
            
            if (this.i==this.selectedRowsData.length) {
              
              this.kitItemService.getArticuloDisponiblesPorKit(this.kits.id_kit).then(data => this.dataSourceItemAvailable=data);
              this.kitItemService.getArticuloAsignadosPorKit(this.kits.id_kit).then(data => this.dataSourceItemAssigned=data);
            }
            
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    valueChangedItem(e) {
        this.kitItemService.getArticuloDisponiblesPorKit(e.value.id_kit).then(data => this.dataSourceItemAvailable=data);
        this.kitItemService.getArticuloAsignadosPorKit(e.value.id_kit).then(data => this.dataSourceItemAssigned=data);           
    },
    deleteItem(e) {
      this.kitItemService.deleteKitArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.itemService.getArticuloDisponiblesPorKit(this.kits.id_kit).then(data => this.dataSourceItemAvailable=data);
        this.itemService.getArticuloAsignadosPorKit(this.kits.id_kit).then(data => this.dataSourceItemAssigned=data); 
      });
    },
    editItem(e) {
      this.kitItemService.updateKitArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.itemService.getArticuloAsignadosPorKit(this.kits.id_kit).then(data => this.dataSourceItemAssigned=data); 
      });
    },
  },  
};
