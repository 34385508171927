import axios from 'axios';

export default class EquipmentTaskService {

	getTareasPorEquipos(equipotarea) {		
        const url = process.env.VUE_APP_APIURL + `equipotareas`;		
		return axios.post(url,equipotarea).then(res=>res.data.data);
	}

    getTareasPorEquiposGenericos(equipotarea) {		
        const url = process.env.VUE_APP_APIURL + `equipotareas/genericos`;		
		return axios.post(url,equipotarea).then(res=>res.data.data);
	}

	getTareasPorEquiposArmadoEquipos(equipotarea) {		
        const url = process.env.VUE_APP_APIURL + `equipotareas/armados`;		
		return axios.post(url,equipotarea).then(res=>res.data.data);
	}
}