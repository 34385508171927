

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import ConfigTaskTask from './config-task-task.vue';
import ConfigTaskItem from './config-task-item.vue';
import ConfigTaskService from './config-task-service.vue';
import ConfigTaskProcedure from './config-task-procedure.vue';
import ConfigTaskMeasurement from './config-task-measurement.vue';
import ConfigTaskMeasurementControl from './config-task-measurement-control.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    ConfigTaskTask,
    ConfigTaskItem,
    ConfigTaskService,
    ConfigTaskProcedure,
    ConfigTaskMeasurement,
    ConfigTaskMeasurementControl
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
