
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DataOriginService from '../services/data-origin-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceDataOrigin: null,
      origenDato: {},
      events: [],
      dataOriginService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.dataOriginService = new DataOriginService();
  },
  mounted() {    
    this.dataOriginService.getOrigenDatos().then(data => this.dataSourceDataOrigin=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editDataOrigin(e) {
			this.dataOriginService.updateOrigenDato(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteDataOrigin(e) {
			this.dataOriginService.deleteOrigenDato(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createDataOrigin(e) {
			this.dataOriginService.createOrigenDato(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
