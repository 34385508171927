import axios from 'axios';

export default class StockRequestService {
  
  // No está implementado en el API
  getValesElectronicosPendientes(empresa) {
    const url =
      process.env.VUE_APP_APIURL + `valeelectronicos/pendientes/empresas`;
    return axios
      .get(url, { params: { id_empresa: empresa } })
      .then((res) => res.data.data);
  }

  getValesElectronicosPorAlmacen(almacen) {
    const url =
      process.env.VUE_APP_APIURL + `valeelectronicos/almacenes/${almacen}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getValeElectronicosPorEmpresa(empresa) {
    const url =
      process.env.VUE_APP_APIURL + `valeelectronicos/empresas/${empresa}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getValeElectronicosHistoria(historia) {
    const url =
      process.env.VUE_APP_APIURL + `valeelectronicos/historia`;
    return axios.get(url,{ params: historia }).then((res) => res.data.data);
  }

  createValeElectronico(valeelectronico) {
    const url = process.env.VUE_APP_APIURL + `/valeelectronicos`;
    return axios.post(url, valeelectronico).then((res) => res.data.data);
  }

  updateValeElectronico(valeelectronico) {
    const url =
      process.env.VUE_APP_APIURL +
      `/valeelectronicos/${valeelectronico.id_vale_electronico}`;
    return axios.put(url, valeelectronico).then((res) => res.data.data);
  }

  deleteValeElectronico(valeelectronico) {
    const url =
      process.env.VUE_APP_APIURL +
      `/valeelectronicos/${valeelectronico.id_vale_electronico}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}