import axios from 'axios';

export default class UserGroupAlertService {

	getUsuarioGrupoAlertas(usuario) {			
        const url = process.env.VUE_APP_APIURL + `usuariogrupoalertas/${usuario}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	createUsuarioGrupoAlerta(usuariogrupoalerta){
        const url = process.env.VUE_APP_APIURL + `usuariogrupoalertas`;
		return axios.post(url,usuariogrupoalerta).then(res=>res.data.data);
	}

	deleteUsuarioGrupoAlerta(usuario,grupoalerta){
        const url = process.env.VUE_APP_APIURL + `usuariogrupoalertas`;
		return axios.delete(url,{ params: { id_usuario: usuario, id_grupo_alerta: grupoalerta} }).then(res=>res.data.data);
	}
}