import axios from 'axios';

export default class MaintenancePolicyService {

	getPolizaMantenimientosPorEmpresaCliente(empresa,cliente) {			
        const url = process.env.VUE_APP_APIURL + `polizamantenimientos/filtros`;		
		return axios.get(url,{ params: {id_empresa: empresa, id_cliente: cliente}}).then(res=>res.data.data);
	}

	getPolizaMantenimientos() {			
        const url = process.env.VUE_APP_APIURL + `polizamantenimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getPolizaMantenimiento(polizamantenimiento) {
        const url = process.env.VUE_APP_APIURL + `/polizamantenimientos/${polizamantenimiento.id_poliza_mantenimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updatePolizaMantenimiento(polizamantenimiento){
        const url = process.env.VUE_APP_APIURL + `/polizamantenimientos/${polizamantenimiento.id_poliza_mantenimiento}`;
		return axios.put(url,polizamantenimiento).then(res=>res.data.data);
	}

	createPolizaMantenimiento(polizamantenimiento){
        const url = process.env.VUE_APP_APIURL + `/polizamantenimientos`;
		return axios.post(url,polizamantenimiento).then(res=>res.data.data);
	}

	deletePolizaMantenimiento(polizamantenimiento){
        const url = process.env.VUE_APP_APIURL + `/polizamantenimientos/${polizamantenimiento.id_poliza_mantenimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}