import axios from 'axios';

export default class ItemClassificationService {

	getClasificacionArticulos() {				
        const url = process.env.VUE_APP_APIURL + `clasificacionarticulos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getClasificacionArticulo(clasificacionarticulo) {
        const url = process.env.VUE_APP_APIURL + `/clasificacionarticulos/${clasificacionarticulo.id_clasificacion_articulo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateClasificacionArticulo(clasificacionarticulo){
        const url = process.env.VUE_APP_APIURL + `/clasificacionarticulos/${clasificacionarticulo.id_clasificacion_articulo}`;
		return axios.put(url,clasificacionarticulo).then(res=>res.data.data);
	}

	createClasificacionArticulo(clasificacionarticulo){
        const url = process.env.VUE_APP_APIURL + `/clasificacionarticulos`;
		return axios.post(url,clasificacionarticulo).then(res=>res.data.data);
	}

	deleteClasificacionArticulo(clasificacionarticulo){
        const url = process.env.VUE_APP_APIURL + `/clasificacionarticulos/${clasificacionarticulo.id_clasificacion_articulo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}