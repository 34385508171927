

import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import Client from './client.vue';
import ClientCompany from './client-company.vue';
import ClientUser from './client-user.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    Client,
    ClientCompany,
    ClientUser
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
