

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import MaintenancePolicy from './maintenance-policy.vue';
import MaintenancePolicyEquipment from './maintenance-policy-equipment.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    MaintenancePolicy,
    MaintenancePolicyEquipment,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
