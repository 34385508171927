import axios from "axios";

export default class EquipmentCategoryService {
  getEquipoCategorias() {
    const url = process.env.VUE_APP_APIURL + `equipocategorias`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquipoCategoria(equipocategoria) {
    const url =
      process.env.VUE_APP_APIURL +
      `/equipocategorias/${equipocategoria.id_equipo_categoria}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEquipoCategoria(equipocategoria) {
    const url =
      process.env.VUE_APP_APIURL +
      `/equipocategorias/${equipocategoria.id_equipo_categoria}`;
    return axios
      .put(url, equipocategoria)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEquipoCategoria(equipocategoria) {
    const url = process.env.VUE_APP_APIURL + `/equipocategorias`;
    return axios
      .post(url, equipocategoria)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEquipoCategoria(equipocategoria) {
    const url =
      process.env.VUE_APP_APIURL +
      `/equipocategorias/${equipocategoria.id_equipo_categoria}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
