
import {
  DxDataGrid, DxColumn, DxLookup, DxEditing, DxPaging, DxValidationRule, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxScrolling, DxPager, DxColumnChooser,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import EquipmentGenericService from '../services/equipment-generic-service';
import EquipmentCategoryService from '../services/equipment-category-service';
import EquipmentService from '../services/equipment-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxValidationRule,
    DxFilterRow,
    DxSelection,
    DxSearchPanel,
    DxSorting,
    DxScrolling,
    DxPager,
    DxLookup,
    DxColumnChooser,
  },
  data() {
    return {      
      dataSourceGenericEquipments: null,
      dataSourceEquipments: null,
      dataSourceEquipmentCategories: null,
      servicio: {},
      events: [],
      serviceService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
    };
  },
  created() {
    this.equipmentGenericService = new EquipmentGenericService();
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.equipmentService = new EquipmentService();
  },
  mounted() {
    this.equipmentGenericService.getEquipoGenericos().then(data => this.dataSourceGenericEquipments=data);
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);
    this.equipmentService.getEquiposCombo().then(data => this.dataSourceEquipments=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editEquipment(e) {
			this.equipmentGenericService.updateEquipoGenerico(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentGenericService.getEquipoGenericos().then(data => this.dataSourceGenericEquipments=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.equipmentGenericService.getEquipoGenericos().then(data => this.dataSourceGenericEquipments=data);
      });
		},
    deleteEquipment(e) {
			this.equipmentGenericService.deleteEquipoGenerico(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.equipmentGenericService.getEquipoGenericos().then(data => this.dataSourceGenericEquipments=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.equipmentGenericService.getEquipoGenericos().then(data => this.dataSourceGenericEquipments=data);
      });
		},
    createEquipment(e) {
			this.equipmentGenericService.createEquipoGenerico(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentGenericService.getEquipoGenericos().then(data => this.dataSourceGenericEquipments=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.equipmentGenericService.getEquipoGenericos().then(data => this.dataSourceGenericEquipments=data);
      });
		},
  },
};
