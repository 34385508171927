import axios from 'axios';

export default class ConfigNotificationService {

	getConfigNotificaciones() {
        const url = process.env.VUE_APP_APIURL + `confignotificaciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigNotificacion(confignotificacion) {
        const url = process.env.VUE_APP_APIURL + `confignotificaciones/${confignotificacion.id_config_notificacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigNotificacion(confignotificacion){
        const url = process.env.VUE_APP_APIURL + `confignotificaciones/${confignotificacion.id_config_notificacion}`;
		return axios.put(url,confignotificacion).then(res=>res.data.data);
	}

	createConfigNotificacion(confignotificacion){
        const url = process.env.VUE_APP_APIURL + `confignotificaciones`;
		return axios.post(url,confignotificacion).then(res=>res.data.data);
	}

	deleteConfigNotificacion(confignotificacion){
        const url = process.env.VUE_APP_APIURL + `confignotificaciones/${confignotificacion.id_config_notificacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}