
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxValidationRule,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import ConfigEmailService from '../services/config-email-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import SupplierService from '../services/supplier-service';
import { surveyTypes } from '../data/data.js';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxValidationRule,
    DxSelection,
  },
  data() {
    return {
      correos: null,
      dataSourceSurveyTypes: surveyTypes,
      dataSourceEmails: null,
      dataSourceCompanies: null,
      dataSourceSuppliers: null,
      dataSourceClients: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Configuración de Correo',
        showTitle: true,
        width: 800,
        height: 600
      },
      backendURL: process.env.VUE_APP_APIURL,
    };
  },
  created() {
    this.configEmailService = new ConfigEmailService();
    this.clientService = new ClientService();
    this.supplierService = new SupplierService();
    this.companyService = new CompanyService();
  },
  mounted() {    
    this.configEmailService.getConfigCorreos().then(data => this.dataSourceEmails=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editEmail(e) {
      this.configEmailService.updateConfigCorreo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.configEmailService.getConfigCorreos().then(data => this.dataSourceEmails=data);
      });
		},
    deleteEmail(e) {
			this.configEmailService.deleteConfigCorreo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.configEmailService.getConfigCorreos().then(data => this.dataSourceEmails=data);
      });
		},
    createEmail(e) {
			this.configEmailService.createConfigCorreo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.configEmailService.getConfigCorreos().then(data => this.dataSourceEmails=data);
      });
		},    
    onInitNewRow(e) {
      e.data.html = true;
      e.data.activo = true;
    },
  },
};
