 
var dataSourceItem1 = [];
var dataSourceService1 = [];

import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxScrolling,
    DxPager, 
} from 'devextreme-vue/data-grid';
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import RequisitionItemService from '../services/requisition-item-service';
import RequisitionServiceService from '../services/requisition-service-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import TypeItemService from '../services/type-item-service'
import ItemService from '../services/item-service';
import ServiceService from '../services/service-service';
import CurrencyService from '../services/currency-service';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxLookup,
        DxScrolling,
        DxPager,
        DxTabPanel,
        DxItem
    },
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        const { id_requisicion } = this.templateData.data;
        return {
            id_requisicion: id_requisicion,
            dataSourceRequisitionItems:null,
            dataSourceUnitOfMeasurement: null, 
            dataSourceItem: null,
            dataSourceTypeItem: null, 
            dataSourceCurrency: null,
            dataSourceRequisitionServices: null,
            dataSourceService: null,
            dataSourceItem1,
            dataSourceService1,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
        }
    },
    created(){
        this.currencyService = new CurrencyService();
        this.requisitionItemService = new RequisitionItemService();
        this.requisitionServiceService = new RequisitionServiceService();
        this.unitOfMeasurementService = new UnitOfMeasurementService();
        this.typeItemService = new TypeItemService();
        this.itemService = new ItemService();
        this.serviceService = new ServiceService();
    },
    mounted() {
        var dataJson = {
            id_requisicion: this.id_requisicion,
        };
        this.requisitionItemService.getRequisicionArticuloID(dataJson).then(data => {
            this.dataSourceRequisitionItems = data;
        });
        this.requisitionServiceService.getRequisicionServicioID(dataJson).then(data => {
            this.dataSourceRequisitionServices = data;
        });
        this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
            this.dataSourceUnitOfMeasurement = data;
        }); 
        this.itemService.getArticuloComboTodos().then(data => {
            dataSourceItem1 = data;
            this.dataSourceItem = data;
        });
        this.serviceService.getServicioComboActivos().then(data => {
            dataSourceService1 = data;
            this.dataSourceService = data;
        });
        this.typeItemService.getTipoArticulosCombo().then(data => this.dataSourceTypeItem = data);
        this.currencyService.getMonedas().then(data => this.dataSourceCurrency = data);
    },
    methods:{
        getFilteredArticulo: (options) => (
        {
            store: dataSourceItem1,
            filter: options.data ? ['id_tipo_articulo', '=', options.data.id_tipo_articulo] : null,
        }),
        getFilteredServicio: () => (
        {
            store: dataSourceService1,
        }),
    }

};
