import axios from "axios";

export default class LeagueModuleService {
  getModuloLigas() {
    const url = process.env.VUE_APP_APIURL + `modulosligas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getModuloLiga(moduloliga) {
    const url =
      process.env.VUE_APP_APIURL + `modulosligas/${moduloliga.id_modulo_liga}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateModuloLiga(moduloliga) {
    const url =
      process.env.VUE_APP_APIURL + `modulosligas/${moduloliga.id_modulo_liga}`;
    return axios.put(url, moduloliga).then((res) => res.data.data);
  }

  createModuloLiga(moduloliga) {
    const url = process.env.VUE_APP_APIURL + `modulosligas`;
    return axios.post(url, moduloliga).then((res) => res.data.data);
  }

  deleteModuloLiga(moduloliga) {
    const url =
      process.env.VUE_APP_APIURL + `modulosligas/${moduloliga.id_modulo_liga}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
