import axios from 'axios';

export default class EquipmentCategoryMeasurementControlService {
    
	getEquipoCategoriaMedicionControlesPorMedicion(equipoCategoriaTareaMedicionId) {
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareamedicioncontroles/equipocategoriatareamediciones/${equipoCategoriaTareaMedicionId}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCategoriaMedicionControles() {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareamedicioncontroles`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoCategoriaMedicionControl(equipocategoriamedicioncontrol) {
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareamedicioncontroles/${equipocategoriamedicioncontrol.id_equipo_categoria_tarea_medicion_control}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoCategoriaMedicionControl(equipocategoriamedicioncontrol){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareamedicioncontroles/${equipocategoriamedicioncontrol.id_equipo_categoria_tarea_medicion_control}`;
		return axios.put(url,equipocategoriamedicioncontrol).then(res=>res.data.data);
	}

	createEquipoCategoriaMedicionControl(equipocategoriamedicioncontrol){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareamedicioncontroles`;
		return axios.post(url,equipocategoriamedicioncontrol).then(res=>res.data.data);
	}

	deleteEquipoCategoriaMedicionControl(equipocategoriamedicioncontrol){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareamedicioncontroles/${equipocategoriamedicioncontrol.id_equipo_categoria_tarea_medicion_control}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}