
// :data="masterDetailData.data"
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import CompanyAddress from './company-address.vue';
import CompanyContact from './company-contact.vue';

export default {
  components: {
    DxTabPanel, DxItem, CompanyAddress, CompanyContact
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
