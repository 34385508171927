
import {
  DxTreeList,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/tree-list';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
// Combos
import ConfigPlanMaintenanceService from '../services/config-plan-maintenance-service';
import ConfigPlanMaintenanceEquipmentService from '../services/config-plan-maintenance-equipment-service';
// Catalogos
import GroupTaskService from '../services/group-task-service';
import TaskService from '../services/task-service';
// TreeList
import ConfigPlanMaintenanceTaskService from '../services/config-plan-maintenance-task-service';

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxSelectBox,
    DxSelection,
    DxSorting,
    Button
  },
  data() {
    return {
      dataSourceEquipments: null,
      dataSourceMaintenancePlan: null,
      dataSourceMaintenancePlanEquipments: null,
      dataSourceMaintenancePlanTaskAssigned: null,
      dataSourceMaintenancePlanTaskAvailable: null,
      dataSourceTaskGroups: null,
      dataSourceParentTasks: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      maintenanceplan: null,
      configplanmaintenanceequipment: null,
      selectedRowsData: [],
    };
  },
  created() {
    this.configPlanMaintenanceService = new ConfigPlanMaintenanceService();
    this.configPlanMaintenanceEquipmentService = new ConfigPlanMaintenanceEquipmentService();
    this.groupTaskService = new GroupTaskService();
    this.taskService = new TaskService();
    this.configPlanMaintenanceTaskService = new ConfigPlanMaintenanceTaskService();
  },
  mounted() {
    this.configPlanMaintenanceService.getConfigPlanMttos().then(data => this.dataSourceMaintenancePlan=data);
    this.groupTaskService.getGrupoTareas().then(data => this.dataSourceTaskGroups=data);
    this.taskService.getTareas().then(data => this.dataSourceParentTasks=data);
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_tarea);
    },
  },
  methods: {
    addTask() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.configPlanMaintenanceTaskService.createConfigPlanMttoTarea({"id_config_plan_mtto_equipo": this.configplanmaintenanceequipment.id_config_plan_mtto_equipo, "id_tarea": this.selectedRowsData[this.i].id_tarea}).then(() => {           
            if (this.i>=this.selectedRowsData.length-1) {
              this.configPlanMaintenanceTaskService.getTareasDisponiblesPorPlanEquipo(this.configplanmaintenanceequipment.id_config_plan_mtto_equipo).then(data => this.dataSourceMaintenancePlanEquipmentAvailable=data);
              this.configPlanMaintenanceTaskService.getTareasAsignadasPorPlanEquipo(this.configplanmaintenanceequipment.id_config_plan_mtto_equipo).then(data => this.dataSourceMaintenancePlanEquipmentAssigned=data);
            }
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'fecha_ultimo_mtto' || e.dataField === 'id_equipo_empresa_padre' || e.dataField === 'imagen' || 
      e.dataField === 'codigo_qr' || e.dataField === 'disponible' || e.dataField === 'id_condicion' || e.dataField === 'edificio' || 
      e.dataField === 'posicion' || e.dataField === 'nivel' || e.dataField === 'fecha_adquisicion' || e.dataField === 'fuera_servicio' ||
      e.dataField === 'longitud' || e.dataField === 'latitud' ||e.dataField === 'referencia' ||e.dataField === 'id_localizacion' || 
      e.dataField === 'id_cliente' || e.dataField === 'id_empresa' || e.dataField === 'id_equipo' || e.dataField === 'marca' || 
      e.dataField === 'modelo' || e.dataField === 'numero_activo' || e.dataField === 'serie' || e.dataField === 'clave'))  {
        e.editorOptions.disabled = true;
      }
    },
    valueChangedMaintenancePlan(e) {
      this.configPlanMaintenanceEquipmentService.getEquiposAsignadosPorPlan(e.value.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipments=data);
      this.dataSourceMaintenancePlanTaskAvailable = null;
      this.dataSourceMaintenancePlanTaskAssigned = null;
    },
    valueChangedEquipment(e) {
      this.configPlanMaintenanceTaskService.getTareasDisponiblesPorPlanEquipo(e.value.id_config_plan_mtto_equipo).then(data => this.dataSourceMaintenancePlanTaskAvailable=data);
      this.configPlanMaintenanceTaskService.getTareasAsignadasPorPlanEquipo(e.value.id_config_plan_mtto_equipo).then(data => this.dataSourceMaintenancePlanTaskAssigned=data);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigTask(e) {
        this.configPlanMaintenanceTaskService.updateConfigPlanMttoTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.configPlanMaintenanceTaskService.getTareasAsignadasPorPlanEquipo(e.data.id_config_plan_mtto_equipo).then(data => this.dataSourceMaintenancePlanTaskAssigned=data);
      });
    },
    deleteConfigTask(e) {
        this.configPlanMaintenanceTaskService.deleteConfigPlanMttoTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.configPlanMaintenanceTaskService.getTareasDisponiblesPorPlanEquipo(e.value.id_config_plan_mtto_equipo).then(data => this.dataSourceMaintenancePlanTaskAvailable=data);
        this.configPlanMaintenanceTaskService.getTareasAsignadasPorPlanEquipo(e.value.id_config_plan_mtto_equipo).then(data => this.dataSourceMaintenancePlanTaskAssigned=data);
      });
    },
  },
};
