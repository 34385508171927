import axios from 'axios';

export default class EquipmentCategoryItemService {
    
	getEquipoCategoriaArticulosPorTarea(equipoCategoriaTareaId) {
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareaarticulos/equipocategoriatareas/${equipoCategoriaTareaId}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCategoriaArticulos() {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareaarticulos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoCategoriaArticulo(equipocategoriaarticulo) {
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaarticulos/${equipocategoriaarticulo.id_equipo_categoria_tarea_articulo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoCategoriaArticulo(equipocategoriaarticulo){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaarticulos/${equipocategoriaarticulo.id_equipo_categoria_tarea_articulo}`;
		return axios.put(url,equipocategoriaarticulo).then(res=>res.data.data);
	}

	createEquipoCategoriaArticulo(equipocategoriaarticulo){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaarticulos`;
		return axios.post(url,equipocategoriaarticulo).then(res=>res.data.data);
	}

	deleteEquipoCategoriaArticulo(equipocategoriaarticulo){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaarticulos/${equipocategoriaarticulo.id_equipo_categoria_tarea_articulo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}