import axios from 'axios';

export default class MaintenancePlanService {

	
	getPlanMantenimientosPorEmpresa(empresa) {			
        const url = process.env.VUE_APP_APIURL + `planmantenimientos/calendarios/${empresa}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getUltimosMantenimientosPorEmpresa(empresa) {			
        const url = process.env.VUE_APP_APIURL + `planmantenimientos/ultimosmantenimientos/${empresa}`;
		return axios.get(url).then(res=>res.data.data);
	}

    getCalendarioPlanMantenimientosPorEmpresa(empresa) {			
        const url = process.env.VUE_APP_APIURL + `planmantenimientos/empresas/${empresa}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getPlanMantenimientos() {		
        const url = process.env.VUE_APP_APIURL + `planmantenimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getPlanMantenimiento(planmantenimiento) {
        const url = process.env.VUE_APP_APIURL + `/planmantenimientos/${planmantenimiento.id_plan_mantenimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updatePlanMantenimientoCalendario(planmantenimiento){
        const url = process.env.VUE_APP_APIURL + `/planmantenimientos/calendarios/${planmantenimiento.id_plan_mantenimiento}`;
		return axios.put(url,planmantenimiento).then(res=>res.data.data);
	}

	updatePlanMantenimiento(planmantenimiento){
        const url = process.env.VUE_APP_APIURL + `/planmantenimientos/${planmantenimiento.id_plan_mantenimiento}`;
		return axios.put(url,planmantenimiento).then(res=>res.data.data);
	}

	createPlanMantenimiento(planmantenimiento){
        const url = process.env.VUE_APP_APIURL + `/planmantenimientos`;
		return axios.post(url,planmantenimiento).then(res=>res.data.data);
	}

	deletePlanMantenimiento(planmantenimiento){
        const url = process.env.VUE_APP_APIURL + `/planmantenimientos/${planmantenimiento.id_plan_mantenimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}

	createPlanMantenimientoPlaneacion(planmantenimiento){
        const url = process.env.VUE_APP_APIURL + `/planmantenimientos/planeacion`;
		return axios.post(url,planmantenimiento).then(res=>res.data.data);
	}

	updateUltimoMantenimiento(planmantenimiento){
        const url = process.env.VUE_APP_APIURL + `/planmantenimientos/ultimomantenimiento`;
		return axios.put(url,planmantenimiento).then(res=>res.data.data);
	}
}