
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxButtonItem,
  DxLabel,
  DxValidationRule,
  DxEmptyItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxLookup } from 'devextreme-vue/lookup';
import DxRadioGroup from 'devextreme-vue/radio-group';
import { DxTagBox } from 'devextreme-vue/tag-box';
import { equipmenttypes } from '../data/data.js';
import DxTreeView from 'devextreme-vue/tree-view';
import DxDateBox from 'devextreme-vue/date-box';
import { DxToast } from 'devextreme-vue/toast';

import TypeMaintenanceService from '../services/type-maintenance-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import SupplierService from '../services/supplier-service';
import ProjectService from '../services/project-service';
import TypeMajorMinorService from '../services/type-major-minor-service';
import PriorityService from '../services/priority-service';
import UserService from '../services/user-service';
import MaintenancePolicyService from '../services/maintenance-policy-service';
import ConfigPlanMaintenanceService from '../services/config-plan-maintenance-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import GroupTaskService from '../services/group-task-service';
import EquipmentTaskService from '../services/equipment-task-service';
import TaskService from '../services/task-service';
import WorkOrderService from '../services/workorder-service';
import FaultReportService from '../services/fault-report-service';
import auth from '../auth';
import moment from 'moment';


const submitButtonOptions = {
  text: "Registrar Orden de Trabajo",
  type: 'default',
  useSubmitBehavior: true
};

const treeViewRef = 'treeView';
const treeViewRefCommonTask = 'treeViewcommontask';

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxRadioGroup,
    DxTreeView,
    DxLookup,
    DxLabel,
    DxValidationRule,
    DxTagBox,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxToast,
    DxDateBox,
    DxEmptyItem,
  },
  data() {
    const selectionModes = ['multiple', 'single'];
    const showCheckBoxesModes = ['normal', 'selectAll', 'none'];

    return {
      chkAutorizado: true,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      fechaInicio: new Date(),
      idUsuario: null,
      idEmpresa: null,
      idCliente: null,
      idProveedor: null,
      idPrioridad: null,
      idProyecto: null,
      idResponsables: null,
      idTipoMantenimiento: null,
      idConfigPlanMtto: null,
      idPolizaMantenimiento: null,
      idClasificacion: null,
      idReporteFalla: null,
      idOrdenTrabajoLiga: null,
      equipmentTypeValue: equipmenttypes[0],
      otro: true,
      plantilla: false,
      poliza: false,
      searchMode: 'contains',
      treeViewRef,
      treeViewRefCommonTask,
      equipmenttypes,
      workOrderFormData: null,
      dataSourceMaintenanceTypes: null,
      dataSourceCompanies: null,
      dataSourcePriorities: null,
      dataSourceClients: null,
      dataSourceSuppliers: null,
      dataSourceProjects: null,
      dataSourceClassifications: null,
      dataSourceWorkOrderUsers: null,
      dataSourceMaintenancePlans: null,
      dataSourceMaintenancePolicies: null,
      dataSourceEquipments: null,
      dataSourceEquipmentTasks: null,
      dataSourceTaskEquipments: null,
      dataSourceGroupTasks: null,
      dataSourceCommonTasks: null,
      dataSourceFaultReports: null,
      dataSourceWorkOrderLink: null,
      isOrderVisible: true,
      submitButtonOptions,
      showCheckBoxesModeValue: showCheckBoxesModes[0],
      selectionModeValue: selectionModes[0],
      selectedEquipments: [],
      selectedTasks: [],
      selectedCommonTasks: [],
      dateOptions: { width: '100%', value: new Date(), type: "datetime", dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss" },
      descriptionOptions: { height: 100, width: '100%' },
      popupOptions: {
        title: 'Agregar tareas comunes',
        showTitle: true,
        width: 650,
        height: 250,
      },
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.taskService = new TaskService();
    this.typeMaintenanceService = new TypeMaintenanceService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.supplierService = new SupplierService();
    this.projectService = new ProjectService();
    this.priorityService = new PriorityService();
    this.typeMajorMinorService = new TypeMajorMinorService();
    this.userService = new UserService();
    this.maintenancePolicyService = new MaintenancePolicyService();
    this.configPlanMaintenanceService = new ConfigPlanMaintenanceService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.equipmentTaskService = new EquipmentTaskService();
    this.groupTaskService = new GroupTaskService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.workOrderService = new WorkOrderService();
    this.faultReportService = new FaultReportService();

  },
  mounted() {
    this.faultReportService.getReporteFallasPendientes().then(data => this.dataSourceFaultReports = data);
    this.taskService.getTareasComunes().then(data => this.dataSourceCommonTasks = data);
    this.typeMaintenanceService.getTipoMantenimientos().then(data => this.dataSourceMaintenanceTypes = data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers = data);
    this.projectService.getProyectos().then(data => this.dataSourceProjects = data);
    this.priorityService.getPrioridades().then(data => this.dataSourcePriorities = data);
    this.typeMajorMinorService.getTipoMayorMenores().then(data => this.dataSourceClassifications = data);
    this.userService.getUsuariosOTComboWeb().then(data => this.dataSourceWorkOrderUsers = data);
    this.groupTaskService.getGrupoTareas().then(data => this.dataSourceGroupTasks = data);
  },
  computed: {
    treeView() {
      return this.$refs[treeViewRef].instance;
    },
    treeViewCommonTask() {
      return this.$refs[treeViewRefCommonTask].instance;
    },
  },
  methods: {
    handleSubmit(e) {
      var hora = moment(this.fechaInicio).format('YYYY/MM/DD HH:mm');
      /*
      // Registrar OT
      console.log(this.workOrderFormData.titulo);
      console.log(this.workOrderFormData.fechaInicio);
      console.log(this.idTipoMantenimiento);
      console.log(this.idEmpresa);
      console.log(this.idCliente);
      console.log(this.idProveedor);
      console.log(this.idClasificacion);
      console.log(this.idPrioridad);
      console.log(this.idProyecto);
      console.log(this.idResponsables);
      console.log(this.selectedEquipments);
      console.log(this.workOrderFormData.descripcion);
      */

      auth.getUser().then((data) => {
        this.idUsuario = data.data.id_usuario;
        this.selectedTasks = [];
        for (var i in this.dataSourceEquipmentTasks) {
          this.selectedTasks.push(this.dataSourceEquipmentTasks[i].id);
          //this.selectedTasks.push({"id_equipo_empresa": this.dataSourceEquipmentTasks[i].id_equipo_empresa, "id_tarea": this.dataSourceEquipmentTasks[i].id_tarea});
        }
        this.workOrderService.createOrdenTrabajoWeb({
          "titulo": this.workOrderFormData.titulo, "fecha_inicio_plan": hora,
          "id_tipo_mantenimiento": this.idTipoMantenimiento, "id_empresa": this.idEmpresa, "id_cliente": this.idCliente,
          "id_proveedor": this.idProveedor, "id_tipo_mayor_menor": this.idClasificacion, "id_reporte_falla": this.idReporteFalla, "id_prioridad": this.idPrioridad,
          "id_origen_orden_trabajo": 3, "id_estatus_orden_trabajo": 1, "id_usuario_mod": this.idUsuario, "id_proyecto": this.idProyecto,
          "descripcion": this.workOrderFormData.descripcion, "responsables": this.idResponsables, "equipos": this.selectedEquipments,
          "tareas_comunes": this.selectedCommonTasks, "tareas": this.selectedTasks, "tipo_equipo": this.equipmentTypeValue, "estatus": this.workOrderFormData.estatus,
          "id_orden_trabajo_liga": this.idOrdenTrabajoLiga
        }).then((data) => {
          // Si se autoriza
          if (typeof (data.error) === "undefined") {
            if (this.workOrderFormData.iniciaConEstatusAutorizado) {
              this.workOrderService.updateCambioEstatusOrdenTrabajo({ "id_orden_trabajo": data.id_orden_trabajo, "id_estatus_orden_trabajo": 2, "id_usuario": this.idUsuario }).then(() => {
                this.type = 'success';
                this.message = 'Folio OT ' + data.folio + ' creado con estatus AUTORIZADA';
                this.isVisible = true;
              });
            } else {
              this.type = 'success';
              this.message = 'Folio OT ' + data.folio + ' creado con estatus NUEVA';
              this.isVisible = true;
              this.id_orden_trabajo = data.id_orden_trabajo;
            }
            this.cleanForm();
          } else {
            this.type = 'error';
            this.message = data.error;
            this.isVisible = true;
          }

        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al registrar la OT';
          this.isVisible = true;
        });
      });

      e.preventDefault();
    },
    cleanForm() {

      this.$refs.anyName.reset();
      this.treeView.unselectAll();
      //this.idTipoMantenimiento = null,
      //this.workOrderFormData.fechaInicio = null,
      this.fechaInicio = new Date();
      this.idClasificacion = null;
      this.idReporteFalla = null;
      this.idOrdenTrabajoLiga = null;
      this.idConfigPlanMtto = null;
      this.idPolizaMantenimiento = null;
      this.idResponsables = null;
      this.selectedEquipments = [];
      this.selectedTasks = [];
      this.selectedCommonTasks = [];
      this.idProveedor = null;
      this.idPrioridad = null;
      this.idProyecto = null;
      this.idEmpresa = 1;
      //this.workOrderFormData.iniciaConEstatusAutorizado = false;
      /*
      //this.workOrderFormData.instance.resetValues();
      */
    },
    valueChangedCompany(e) {
      this.idEmpresa = e.value;
      console.log(e.value);
      if (this.equipmentTypeValue == "Genérico") {
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipoGenerico(e.value, this.idCliente).then(data => this.dataSourceEquipments = data);
      } else if (this.equipmentTypeValue == "Armado Equipos") {
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorArmado(e.value, this.idCliente).then(data => this.dataSourceEquipments = data);
      } else if (this.equipmentTypeValue == "Equipo") {
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipo(e.value, this.idCliente).then(data => this.dataSourceEquipments = data);
      } else if (this.equipmentTypeValue == "Plantilla") {
        this.configPlanMaintenanceService.getConfigPlanMttosPorEmpresaCliente(e.value, this.idCliente).then(data => this.dataSourceMaintenancePlans = data);
        this.dataSourceMaintenancePolicies = null;
      } else if (this.equipmentTypeValue == "Póliza Mtto") {
        this.maintenancePolicyService.getPolizaMantenimientosPorEmpresaCliente(e.value, this.idCliente).then(data => this.dataSourceMaintenancePolicies = data);
        this.dataSourceMaintenancePlans = null;
      } else {
        this.dataSourceEquipments = null;
      }
      this.dataSourceEquipmentTasks = null;
    },
    valueChangedClient(e) {
      this.idCliente = e.value;
      if (this.equipmentTypeValue == "Genérico") {
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipoGenerico(this.idEmpresa, this.idCliente).then(data => this.dataSourceEquipments = data);
      } else if (this.equipmentTypeValue == "Armado Equipos") {
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorArmado(e.value, this.idCliente).then(data => this.dataSourceEquipments = data);
      } else if (this.equipmentTypeValue == "Equipo") {
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipo(this.idEmpresa, this.idCliente).then(data => this.dataSourceEquipments = data);
      } else if (this.equipmentTypeValue == "Plantilla") {
        this.configPlanMaintenanceService.getConfigPlanMttosPorEmpresaCliente(this.idEmpresa, this.idCliente).then(data => this.dataSourceMaintenancePlans = data);
        this.dataSourceMaintenancePolicies = null;
      } else if (this.equipmentTypeValue == "Póliza Mtto") {
        this.maintenancePolicyService.getPolizaMantenimientosPorEmpresaCliente(this.idEmpresa, this.idCliente).then(data => this.dataSourceMaintenancePolicies = data);
        this.dataSourceMaintenancePlans = null;
      } else {
        this.dataSourceEquipments = null;
      }
      this.dataSourceEquipmentTasks = null;

      if (this.idEmpresa != null && this.idCliente != null) {
        this.workOrderService.getOrdenTrabajoLigaPorEmpresaCliente(this.idEmpresa, this.idCliente).then(data => this.dataSourceWorkOrderLink = data);
      }

    },
    valueChangedMaintenanceType(e) {
      if ((this.idEmpresa != null) && (this.selectedEquipments.length > 0)) {
        this.equipmentTaskService.getTareasPorEquipos({ "id_empresa": this.idEmpresa, "id_cliente": this.idCliente, "id_tipo_mantenimiento": e.value, "tipo": this.equipmentTypeValue, "equipos": this.selectedEquipments }).then(data => this.dataSourceEquipmentTasks = data);
      }
    },
    valueChangedMaintenancePlan(e) {
      this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorPlantilla(this.idEmpresa, this.idCliente, e.value).then(data => this.dataSourceEquipments = data);
    },
    valueChangedPolicy(e) {
      this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorPolizaMtto(this.idEmpresa, this.idCliente, e.value).then(data => this.dataSourceEquipments = data);
    },
    changeSelectionSource(e) {
      if (e.value == 'Plantilla') {
        this.plantilla = true;
        this.otro = false;
        this.equipmentTypeValue = equipmenttypes[3];
        this.configPlanMaintenanceService.getConfigPlanMttosPorEmpresaCliente(this.idEmpresa, this.idCliente).then(data => this.dataSourceMaintenancePlans = data);
        this.dataSourceEquipments = null;
        this.dataSourceEquipmentTasks = null;
      } else {
        this.plantilla = false;
      }
      if (e.value == 'Póliza Mtto') {
        this.poliza = true;
        this.otro = false;
        this.equipmentTypeValue = equipmenttypes[4];
        this.maintenancePolicyService.getPolizaMantenimientosPorEmpresaCliente(this.idEmpresa, this.idCliente).then(data => this.dataSourceMaintenancePolicies = data);
        this.dataSourceEquipments = null;
        this.dataSourceEquipmentTasks = null;
      } else {
        this.poliza = false;
      }
      if (e.value == 'Armado Equipos') {
        this.otro = true;
        this.equipmentTypeValue = equipmenttypes[1];
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorArmado().then(data => this.dataSourceEquipments = data);
        this.dataSourceEquipmentTasks = null;
      }
      if (e.value == 'Genérico') {
        this.otro = true;
        this.equipmentTypeValue = equipmenttypes[2];
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipoGenerico().then(data => this.dataSourceEquipments = data);
        this.dataSourceEquipmentTasks = null;
      }
      if (e.value == 'Equipo') {
        this.otro = true;
        this.equipmentTypeValue = equipmenttypes[0];
        this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipo(this.idEmpresa, this.idCliente).then(data => this.dataSourceEquipments = data);
        this.dataSourceEquipmentTasks = null;
      }
    },
    treeViewSelectionChanged(e) {
      this.syncSelection(e.component);
    },
    treeViewContentReady(e) {
      this.syncSelection(e.component);
    },
    syncSelection(treeView) {
      const selectedEquipments = treeView.getSelectedNodes()
        .map((node) => node.itemData);
      this.selectedEquipments = [];
      for (var i in selectedEquipments)
        this.selectedEquipments.push(selectedEquipments[i].id_equipo_empresa);

      if (this.equipmentTypeValue == "Genérico")
        this.equipmentTaskService.getTareasPorEquiposGenericos({ "id_empresa": this.idEmpresa, "id_cliente": this.idCliente, "id_tipo_mantenimiento": this.idTipoMantenimiento, "tipo": this.equipmentTypeValue, "equipos": this.selectedEquipments }).then(data => this.dataSourceEquipmentTasks = data);
      else if (this.equipmentTypeValue == "Armado Equipos")
        this.equipmentTaskService.getTareasPorEquiposArmadoEquipos({ "id_empresa": this.idEmpresa, "id_cliente": this.idCliente, "id_tipo_mantenimiento": this.idTipoMantenimiento, "tipo": this.equipmentTypeValue, "equipos": this.selectedEquipments }).then(data => this.dataSourceEquipmentTasks = data);
      else
        this.equipmentTaskService.getTareasPorEquipos({ "id_empresa": this.idEmpresa, "id_cliente": this.idCliente, "id_tipo_mantenimiento": this.idTipoMantenimiento, "tipo": this.equipmentTypeValue, "equipos": this.selectedEquipments }).then(data => this.dataSourceEquipmentTasks = data);
      // this.selectedEquipments = selectedEquipments;
    },
    treeViewSelectionChangedCommonTask(e) {
      this.syncSelectionCommonTask(e.component);
    },
    treeViewContentReadyCommonTask(e) {
      this.syncSelectionCommonTask(e.component);
    },
    syncSelectionCommonTask(treeView) {
      const selectedCommonTasks = treeView.getSelectedNodes()
        .map((node) => node.itemData);
      this.selectedCommonTasks = [];
      for (var i in selectedCommonTasks)
        this.selectedCommonTasks.push(selectedCommonTasks[i].id_tarea);
    },
    onInitNewRow(e) {
      // TODO
      console.log(e);
      //e.data.activo = true;
    },
    deleteTask(e) {
      console.log(e);
      /*
      this.measurementService.deleteMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar la medición';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
      });
      */
    },
    createTask(e) {
      console.log(e);
      /*
      this.measurementService.createMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear la medición';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
      });
      */
    },
  }

};
