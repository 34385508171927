import axios from 'axios';

export default class EventEquipmentService {

    getEventoEquipoPorFiltro(id_usuario,id_empresa,fecha_inicio,fecha_fin) {		
        const url = process.env.VUE_APP_APIURL + `equipoeventos/web`;		
		return axios.get(url,{ params: { id_usuario: id_usuario, id_empresa: id_empresa, fecha_inicio: fecha_inicio, fecha_fin: fecha_fin } }).then(res=>res.data.data);
	}

    getEventoEquipos() {		
        const url = process.env.VUE_APP_APIURL + `eventoequipos`;
		return axios.get(url).then(res=>res.data.data);
	}

    getEventoEquipo(eventoequipo) {
        const url = process.env.VUE_APP_APIURL + `/eventoequipos/${eventoequipo.id_evento_equipo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEventoEquipo(eventoequipo){
        const url = process.env.VUE_APP_APIURL + `/eventoequipos/${eventoequipo.id_evento_equipo}`;
		return axios.put(url,eventoequipo).then(res=>res.data.data);
	}

	createEventoEquipo(eventoequipo){
        const url = process.env.VUE_APP_APIURL + `/eventoequipos`;
		return axios.post(url,eventoequipo).then(res=>res.data.data);		
	}

	deleteEventoEquipo(eventoequipo){
        const url = process.env.VUE_APP_APIURL + `/eventoequipos/${eventoequipo.id_evento_equipo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}