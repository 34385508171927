
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule,
    DxMasterDetail,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import SurveyControlTypeService from '../services/survey-control-type-service';
import ConfigSurveyDetailService from '../services/config-survey-detail-service';
import TypeDataService from '../services/type-data-service';

import ConfigSurveyDetailOpcion from './config-survey-detail-opcion.vue';

export default {
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxFilterRow,
        DxScrolling,
        DxPager,
        DxToast,
        DxColumnChooser,
        DxSelection,
        DxSorting,
        DxForm,
        DxItem,
        DxValidationRule,
        DxMasterDetail,
        ConfigSurveyDetailOpcion,
    },
    data() {
        const { id_config_encuesta } = this.templateData.data;
        return {
            dataSourceConfigSurveyDetail: null,
            dataSourceSurveyControlType: null,
            dataSourceTypeDataService: null,
            idConfigEncuesta: id_config_encuesta,
            popupOptions: {
                title: 'Config Encuesta Detalle',
                showTitle: true,
                width: 950,
                height: 640,
            },
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            mode: 'select',
            isVisible: false,
            message: '',
            type: 'info',
            autoExpandAll: true,
        }
    },
    created() {
        this.surveyControlTypeService = new SurveyControlTypeService();
        this.configSurveyDetailService = new ConfigSurveyDetailService();
        this.typeDataService = new TypeDataService();
    },
    mounted() {
        this.surveyControlTypeService.getSurveyControlType().then(data => this.dataSourceSurveyControlType = data);
        this.typeDataService.getTipoDatos().then(data => this.dataSourceTypeDataService = data);
        this.refreshDataGrid();
    },
    methods: {
        refreshDataGrid() {
            this.configSurveyDetailService.getConfigSurveyDetailByIdConfigSurvey(this.idConfigEncuesta).then(data => this.dataSourceConfigSurveyDetail = data);
        },
        onInitNewRow(e) {
            e.data.activo = true;
            e.data.evaluacion_global = false;
            e.data.opcional = false;
        },
        createConfigSurveyDetail(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.id_config_encuesta = this.idConfigEncuesta;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyDetailService.createConfigSurveyDetail(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha registrado correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        editWorkConfigSurveyDetail(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.id_config_encuesta = this.idConfigEncuesta;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyDetailService.updateConfigSurveyDetail(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha actualizado correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        deleteWorkConfigSurveyDetail(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.id_config_encuesta = this.idConfigEncuesta;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyDetailService.deleteConfigSurveyDetail(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha eliminar correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
    },

}
