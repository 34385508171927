
import DxList from 'devextreme-vue/list';
import GroupAlertService from '../services/group-alert-service';
import UserGroupAlertService from '../services/user-group-alert-service';

export default {
  components: { DxList },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedItemKeys: [],
      loading: true,
      dataSourceGroupAlerts: null,       
      dataSourceUserGroupAlerts: null,
    };
  },
  created() {
    this.groupAlertService = new GroupAlertService();
    this.userGroupAlertService = new UserGroupAlertService();
  },
  mounted() {    
    this.groupAlertService.getGrupoAlertas().then(data => this.dataSourceGroupAlerts=data);    
    this.refresh();
  },
  methods: {
    onSelectionChanged (e) {
      if (!this.loading) {
        if (e.addedItems.length>0) {
          this.userGroupAlertService.createUsuarioGrupoAlerta({"id_usuario": this.userId, "id_grupo_alerta": e.addedItems[0].id_grupo_alerta}).then(() => {           
            this.refresh();
          });
        } else if (e.removedItems.length>0) {
          this.userGroupAlertService.deleteUsuarioGrupoAlerta(this.userId,e.removedItems[0].id_grupo_alerta).then(() => {          
            this.refresh();
          });
        }
      } else {
        this.loading = false;
      }
    },
    refresh() {
      this.userGroupAlertService.getUsuarioGrupoAlertas(this.userId).then((data) => {
        this.dataSourceUserGroupAlerts=data;
        var grupoalertas = "";
        for (var i = 0; i < data.length; i++) {
          if (grupoalertas.length==0)
            grupoalertas = data[i].id_grupo_alerta;
          else
            grupoalertas = grupoalertas + "," + data[i].id_grupo_alerta;
        }
        if (String(grupoalertas).length>0) {
          this.selectedItemKeys = String(grupoalertas).split(',').map(function(item) {
          return parseInt(item, 10);
        });
        } else {
          this.selectedItemKeys = [];
        }        
      });
    }
  }
};
