import axios from 'axios';

export default class ConditionService {

	getCondiciones() {			
        const url = process.env.VUE_APP_APIURL + `condiciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getCondicion(condicion) {
        const url = process.env.VUE_APP_APIURL + `/condiciones/${condicion.id_condicion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateCondicion(condicion){
        const url = process.env.VUE_APP_APIURL + `/condiciones/${condicion.id_condicion}`;
		return axios.put(url,condicion).then(res=>res.data.data);
	}

	createCondicion(condicion){
        const url = process.env.VUE_APP_APIURL + `/condiciones`;
		return axios.post(url,condicion).then(res=>res.data.data);
	}

	deleteCondicion(condicion){
        const url = process.env.VUE_APP_APIURL + `/condiciones/${condicion.id_condicion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}