import axios from 'axios';

export default class SeverityService {

	getSeveridadAlertas() {		
        const url = process.env.VUE_APP_APIURL + `severidadalertas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getSeveridadAlerta(severidadalerta) {
        const url = process.env.VUE_APP_APIURL + `/severidadalertas/${severidadalerta.id_severidad_alerta}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateSeveridadAlerta(severidadalerta){
        const url = process.env.VUE_APP_APIURL + `/severidadalertas/${severidadalerta.id_severidad_alerta}`;
		return axios.put(url,severidadalerta).then(res=>res.data.data);
	}

	createSeveridadAlerta(severidadalerta){
        const url = process.env.VUE_APP_APIURL + `/severidadalertas`;
		return axios.post(url,severidadalerta).then(res=>res.data.data);
	}

	deleteSeveridadAlerta(severidadalerta){
        const url = process.env.VUE_APP_APIURL + `/severidadalertas/${severidadalerta.id_severidad_alerta}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}