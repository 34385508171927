import axios from 'axios';

export default class TaskSkillService {

	getTareaHabilidades(tarea) {			
        const url = process.env.VUE_APP_APIURL + `tareahabilidades/${tarea}`;	
		return axios.get(url).then(res=>res.data.data);
	}

	createTareaHabilidad(tareahabilidad){
        const url = process.env.VUE_APP_APIURL + `tareahabilidades`;
		return axios.post(url,tareahabilidad).then(res=>res.data.data);
	}

	deleteTareaHabilidad(tarea,habilidad){
        const url = process.env.VUE_APP_APIURL + `tareahabilidades/tareas/${tarea}/habilidades/${habilidad}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}