import axios from 'axios';

export default class GeneralConfigService {

	getConfigGenerales() {	
        const url = process.env.VUE_APP_APIURL + `configgenerales`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigGeneral(configgeneral) {
        const url = process.env.VUE_APP_APIURL + `/configgenerales/${configgeneral.id_config_general}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigGeneral(configgeneral) {
        const url = process.env.VUE_APP_APIURL + `/configgenerales/${configgeneral.id_config_general}`;
		return axios.put(url,configgeneral)
		.then(res=>res.data.data);
	}

	createConfigGeneral(configgeneral){
        const url = process.env.VUE_APP_APIURL + `/configgenerales`;
		return axios.post(url,configgeneral).then(res=>res.data.data);
	}

	deleteConfigGeneral(configgeneral){
        const url = process.env.VUE_APP_APIURL + `/configgenerales/${configgeneral.id_config_general}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}