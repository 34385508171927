
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxScrolling
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import ChannelService from '../services/channel-service';

import ChannelStatus from './channel-status.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxFilterRow,
    DxSelection,
    DxSearchPanel,
    DxSorting,
    DxPager,
    DxScrolling,
    DxTabPanel,
    DxItem,
    ChannelStatus
  },
  data() {
    return {
      dataSourceChannel: null,
      canal: {},
      events: [],
      channelService: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.channelService = new ChannelService();
  },
  mounted() {
    this.channelService.getCanales().then(data => this.dataSourceChannel = data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editChannel(e) {
      this.channelService.updateCanal(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.channelService.getCanales().then(data => this.dataSourceChannel = data);
      });
    },
    deleteChannel(e) {
      this.channelService.deleteCanal(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.channelService.getCanales().then(data => this.dataSourceChannel = data);
      });
    },
    createChannel(e) {
      this.channelService.createCanal(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.channelService.getCanales().then(data => this.dataSourceChannel = data);
      });
    },
  },
};
