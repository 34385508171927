import axios from "axios";

export default class PhysicalInventoryItemService {
  getArticulosDisponiblesPorUsuario(id_inventario_fisico) {
    const url =
      process.env.VUE_APP_APIURL + `inventariofisicoarticulos/disponibles`;
    return axios
      .get(url, {
        params: {
          id_inventario_fisico: id_inventario_fisico,
        },
      })
      .then((res) => res.data.data);
  }

  getArticulosAsignadosPorUsuario(id_inventario_fisico) {
    const url =
      process.env.VUE_APP_APIURL + `inventariofisicoarticulos/asignados`;
    return axios
      .get(url, {
        params: {
          id_inventario_fisico: id_inventario_fisico,
        },
      })
      .then((res) => res.data.data);
  }

  getInventarioFisicoArticulos() {
    const url = process.env.VUE_APP_APIURL + `inventariofisicoarticulos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getInventarioFisicoArticulo(inventariofisicoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/inventariofisicoarticulos/${inventariofisicoarticulo.id_inventario_fisico_articulo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateInventarioFisicoArticulo(inventariofisicoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/inventariofisicoarticulos/${inventariofisicoarticulo.id_inventario_fisico_articulo}`;
    return axios
      .put(url, inventariofisicoarticulo)
      .then((res) => res.data.data);
  }

  createInventarioFisicoArticulo(inventariofisicoarticulo) {
    const url = process.env.VUE_APP_APIURL + `/inventariofisicoarticulos`;
    return axios
      .post(url, inventariofisicoarticulo)
      .then((res) => res.data.data);
  }

  deleteInventarioFisicoArticulo(inventariofisicoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/inventariofisicoarticulos/${inventariofisicoarticulo.id_inventario_fisico_articulo}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
