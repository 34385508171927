
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxColumnChooser,
  DxButton,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import EquipmentCompanyDataService from '../services/equipment-company-data-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import BrandService from '../services/brand-service';

const dataGridRefKey = "my-data-grid";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSelection,
    DxGroupPanel,
    DxGrouping,
    DxColumnChooser,
    DxButton,
  },
  data() {
    return {
      mode: 'select',
      correos: null,
      dataSourceEquipmentDatas: null,
      dataSourceCompanies: null,
      dataSourceSuppliers: null,
      dataSourceClients: null,
      dataSourceBrands: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      backendURL: process.env.VUE_APP_APIURL,
      dataGridRefKey
    };
  },
  created() {
    this.equipmentCompanyDataService = new EquipmentCompanyDataService();
    this.clientService = new ClientService();
    this.brandService = new BrandService();
    this.companyService = new CompanyService();
  },
  mounted() {
    this.equipmentCompanyDataService.getLevantamientoDatos().then(data => this.dataSourceEquipmentDatas=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
  },
  computed: {
      dataGrid: function() {
          return this.$refs[dataGridRefKey].instance;
      }
  },
  methods: {
    sendData(e) {
      this.equipmentCompanyDataService.updateLevantamientoDato({ ...e.row.data }).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCompanyDataService.getLevantamientoDatos().then(data => this.dataSourceEquipmentDatas=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.equipmentCompanyDataService.getLevantamientoDatos().then(data => this.dataSourceEquipmentDatas=data);
      });
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editEquipmentData(e) {
      console.log("edit",e.data);

      this.equipmentCompanyDataService.updateLevantamientoDato(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCompanyDataService.getLevantamientoDatos().then(data => this.dataSourceEquipmentDatas=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.equipmentCompanyDataService.getLevantamientoDatos().then(data => this.dataSourceEquipmentDatas=data);
      });

		},
    deleteEquipmentData(e) {
      this.equipmentCompanyDataService.deleteLevantamientoDato(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCompanyDataService.getLevantamientoDatos().then(data => this.dataSourceEquipmentDatas=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.equipmentCompanyDataService.getLevantamientoDatos().then(data => this.dataSourceEquipmentDatas=data);
      });

		},
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && ( e.dataField === 'id_equipo_empresa' || e.dataField === 'folio' || e.dataField === 'id_cliente'
      || e.dataField === 'codigo' || e.dataField === 'grupo' || e.dataField === 'nombre' || e.dataField === 'alias' || e.dataField === 'id_marca' || e.dataField === 'modelo'
      || e.dataField === 'serie' || e.dataField === 'clave'))  {
        e.editorOptions.disabled = true;
      }
      if (e.dataField === "dummy" && e.parentType ==="dataRow"){
          e.row.data.dummy = 1;
      }
    },

  },
};
