

// <div class="master-detail-caption">{{ detailInfo }}</div>
import { DxDataGrid, DxColumn, DxEditing } from 'devextreme-vue/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import MeasurementOptionDetailService from '../services/measurement-option-detail-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { nombre } = this.templateData.data;
    return {
      details: null,
      dataSourceMeasurementOptionDetails: null,
      detailInfo: `${nombre}`,
    };
  },
  created() {
    this.measurementOptionDetailService = new MeasurementOptionDetailService();      
  },
  mounted() {    
    this.measurementOptionDetailService.getMedicionOpcionDetalles().then((data) => {
        this.details=data;         
        this.dataSourceMeasurementOptionDetails = this.getDetails(this.templateData.key); 
    });             
  },
  methods: {
    editMeasurementOptionDetail(e) {
        this.measurementOptionDetailService.updateMedicionOpcionDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    deleteMeasurementOptionDetail(e) {
		this.measurementOptionDetailService.deleteMedicionOpcionDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    createMeasurementOptionDetail(e) {
        e.data.id_medicion_opcion = this.templateData.key;
		this.measurementOptionDetailService.createMedicionOpcionDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    refreshData() {
        this.measurementOptionDetailService.getMedicionOpcionDetalles().then((data) => {
        this.details=data;         
        this.dataSourceMeasurementOptionDetails = this.getDetails(this.templateData.key); 
    });
    },           
    getDetails(key) {      
      console.log(key);
      return new DataSource({
        store: new ArrayStore({
          data: this.details,
          key: 'id_medicion_opcion_detalle',
        }),
        filter: ['id_medicion_opcion', '=', key],
      });
    },
  },
};
