import axios from 'axios';

export default class GroupTaskService {

	getGrupoTareas() {	
        const url = process.env.VUE_APP_APIURL + `grupotareas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoTarea(grupotarea) {
        const url = process.env.VUE_APP_APIURL + `/grupotareas/${grupotarea.id_grupo_tarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoTarea(grupotarea){
        const url = process.env.VUE_APP_APIURL + `/grupotareas/${grupotarea.id_grupo_tarea}`;
		return axios.put(url,grupotarea).then(res=>res.data.data);
	}

	createGrupoTarea(grupotarea){
        const url = process.env.VUE_APP_APIURL + `/grupotareas`;
		return axios.post(url,grupotarea).then(res=>res.data.data);
	}

	deleteGrupoTarea(grupotarea){
        const url = process.env.VUE_APP_APIURL + `/grupotareas/${grupotarea.id_grupo_tarea}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}