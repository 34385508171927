 

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxScrolling,
  DxPager, 
} from 'devextreme-vue/data-grid';
import QuoteDetailService from '../services/quote-detail-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import CurrencyService from '../services/currency-service';

export default {
  components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxLookup,
      DxScrolling,
      DxPager,
  },
  props: {
      templateData: {
          type: Object,
          default: () => { },
      },
  },
  data() {
      const { id_requisicion } = this.templateData.data;
      return {
          id_requisicion: id_requisicion,
          dataSourceQuoteDetails: null,
          dataSourceUnitOfMeasurement: null, 
          dataSourceCurrency: null,
          allowedPageSizes: [5, 10, 20, 50, 'all'],
      }
  },
  created(){
      this.currencyService = new CurrencyService();
      this.unitOfMeasurementService = new UnitOfMeasurementService();
      this.quoteDetailService = new QuoteDetailService();
  },
  mounted() {
      var dataJson = {
          id_requisicion: this.id_requisicion,
      };
      this.quoteDetailService.getCotizacionDetallesRequisicionID(dataJson).then(data => {
          this.dataSourceQuoteDetails = data;
      });
      this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
          this.dataSourceUnitOfMeasurement = data;
      }); 
      this.currencyService.getMonedas().then(data => this.dataSourceCurrency = data);
  },

};
