import axios from "axios";

export default class StatusRequisitionService {
  getEstatusRequisiciones() {
    const url = process.env.VUE_APP_APIURL + `estatusrequisiciones`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusRequisicion(estatusrequisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusrequisiciones/${estatusrequisicion.id_estatus_requisicion}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusRequisicion(estatusrequisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusrequisiciones/${estatusrequisicion.id_estatus_requisicion}`;
    return axios
      .put(url, estatusrequisicion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEstatusRequisicion(estatusrequisicion) {
    const url = process.env.VUE_APP_APIURL + `/estatusrequisiciones`;
    return axios
      .post(url, estatusrequisicion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEstatusRequisicion(estatusrequisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusrequisiciones/${estatusrequisicion.id_estatus_requisicion}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
