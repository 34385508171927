import axios from 'axios';

export default class AbilityService {

	getPaises() {		
        const url = process.env.VUE_APP_APIURL + `paises`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getPais(pais) {
        const url = process.env.VUE_APP_APIURL + `/paises/${pais.id_pais}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updatePais(pais){
        const url = process.env.VUE_APP_APIURL + `/paises/${pais.id_pais}`;
		return axios.put(url,pais).then(res=>res.data.data);
	}

	createPais(pais){
        const url = process.env.VUE_APP_APIURL + `/paises`;
		return axios.post(url,pais).then(res=>res.data.data);
	}

	deletePais(pais){
        const url = process.env.VUE_APP_APIURL + `/paises/${pais.id_pais}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}