import axios from 'axios';

export default class UserSkillService {

	getUsuarioHabilidades(usuario) {			
        const url = process.env.VUE_APP_APIURL + `usuariohabilidades/${usuario}`;	
		return axios.get(url).then(res=>res.data.data);
	}

	createUsuarioHabilidad(usuariohabilidad){
        const url = process.env.VUE_APP_APIURL + `usuariohabilidades`;
		return axios.post(url,usuariohabilidad).then(res=>res.data.data);
	}

	deleteUsuarioHabilidad(usuario,habilidad){
        const url = process.env.VUE_APP_APIURL + `usuariohabilidades`;
		return axios.delete(url,{ params: { id_usuario: usuario, id_habilidad: habilidad} }).then(res=>res.data.data);
	}
}