import axios from 'axios';

export default class ItemSpecialFieldService {

	getCamposAsignadosPorArticulo(articuloId) {		
        const url = process.env.VUE_APP_APIURL + `articulocampos/asignados/${articuloId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getCamposDisponiblesPorArticulo(articuloId) {		
        const url = process.env.VUE_APP_APIURL + `articulocampos/disponibles/${articuloId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getArticuloCampo(articulocampo) {
        const url = process.env.VUE_APP_APIURL + `/articulocampos/${articulocampo.id_articulo_campo}`;
		return axios.get(url).then(res=>res.data.data);
	}

    updateArticuloCampo(articulocampo){
        const url = process.env.VUE_APP_APIURL + `articulocampos/${articulocampo.id_articulo_campo}`;
		return axios.put(url,articulocampo).then(res=>res.data.data);
	}

	createArticuloCampo(articulocampo){
        const url = process.env.VUE_APP_APIURL + `/articulocampos`;
		return axios.post(url,articulocampo).then(res=>res.data.data);		
	}

	deleteArticuloCampo(articulocampo){
        const url = process.env.VUE_APP_APIURL + `/articulocampos/${articulocampo.id_articulo_campo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}