import axios from "axios";

export default class PortalClientService {
  getPortalClientHeaders() {
    const id_usuario = localStorage.getItem("user-data");
    const url =
      process.env.VUE_APP_APIURL + `/clienteportales/encabezados/${id_usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }
  getPortalClientCalendars() {
    const id_usuario = localStorage.getItem("user-data");
    const url =
      process.env.VUE_APP_APIURL + `/clienteportales/calendarios/${id_usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }
  getPortalClientTeams() {
    const id_usuario = localStorage.getItem("user-data");
    const url =
      process.env.VUE_APP_APIURL + `/clienteportales/equipos/${id_usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }
  getPortalClientDocuments() {
    const id_usuario = localStorage.getItem("user-data");
    const url =
      process.env.VUE_APP_APIURL + `/clienteportales/documentos/${id_usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }
  getPortalClientSurveys() {
    const id_usuario = localStorage.getItem("user-data");
    const url =
      process.env.VUE_APP_APIURL + `/clienteportales/encuestas/${id_usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }
  getPortalClientContacts() {
    const id_usuario = localStorage.getItem("user-data");
    const url =
      process.env.VUE_APP_APIURL + `/clienteportales/contactos/${id_usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }
}
