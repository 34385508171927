import axios from "axios";

export default class ActivityLeagueService {
  getActividadesLigas() {
    const url = process.env.VUE_APP_APIURL + `actividadesligas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getActividadLiga(actividadliga) {
    const url =
      process.env.VUE_APP_APIURL +
      `actividadesligas/${actividadliga.id_actividad_liga}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateActividadLiga(actividadliga) {
    const url =
      process.env.VUE_APP_APIURL +
      `actividadesligas/${actividadliga.id_actividad_liga}`;
    return axios.put(url, actividadliga).then((res) => res.data.data);
  }

  createActividadLiga(actividadliga) {
    const url = process.env.VUE_APP_APIURL + `actividadesligas`;
    return axios.post(url, actividadliga).then((res) => res.data.data);
  }

  deleteActividadLiga(actividadliga) {
    const url =
      process.env.VUE_APP_APIURL +
      `actividadesligas/${actividadliga.id_actividad_liga}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getActividadLigaPorActividad(actividad) {
    const url =
      process.env.VUE_APP_APIURL + `actividadesligas/actividad/${actividad}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getActividadLigaPorFolios(modulo) {
    const url =
      process.env.VUE_APP_APIURL + `actividadesligas/folios/${modulo}`;
    return axios.get(url).then((res) => res.data.data);
  }
}
