import axios from 'axios';

export default class GroupMeasurementService {

	getGrupoMediciones() {	
        const url = process.env.VUE_APP_APIURL + `grupomediciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoMedicion(grupomedicion) {
        const url = process.env.VUE_APP_APIURL + `/grupomediciones/${grupomedicion.id_grupo_medicion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoMedicion(grupomedicion) {
        const url = process.env.VUE_APP_APIURL + `/grupomediciones/${grupomedicion.id_grupo_medicion}`;
		return axios.put(url,grupomedicion)
		.then(res=>res.data.data);
	}

	createGrupoMedicion(grupomedicion){
        const url = process.env.VUE_APP_APIURL + `/grupomediciones`;
		return axios.post(url,grupomedicion).then(res=>res.data.data);
	}

	deleteGrupoMedicion(grupomedicion){
        const url = process.env.VUE_APP_APIURL + `/grupomediciones/${grupomedicion.id_grupo_medicion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}