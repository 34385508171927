import axios from "axios";

export default class ServiceReportService {
  getReporteServicios(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `reporteservicios`;
    return axios
      .get(url, { params: { id_orden_trabajo: ordentrabajo } })
      .then((res) => res.data.data);
  }

  getReporteServiciosWeb(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `reporteservicios/web`;
    return axios
      .get(url, { params: { id_orden_trabajo: ordentrabajo } })
      .then((res) => res.data.data);
  }
  uploadfile(data) {
    const url = process.env.VUE_APP_APIURL + `/archivo`;
    return axios.post(url, data).then((res) => res.data.data);
  }

  getDownloadpdf(ordentrabajo) {
    const url = process.env.VUE_APP_APIURL + `ordentrabajos/descargar`;
    return axios
      .get(
        url,
        { params: { id_orden_trabajo: ordentrabajo } },
        {
          responseType: "blob",
        }
      )
      .then((res) => res.data);
  }

  uploadfilesendmail(data) {
    const url = process.env.VUE_APP_APIURL + `/archivosendmail`;
    return axios.post(url, data).then((res) => res.data.data);
  }
}
