import axios from "axios";

export default class StatusPurchaseOrderService {
  getEstatusOrdenCompras() {
    const url = process.env.VUE_APP_APIURL + `estatusordencompras`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusOrdenCompra(estatusordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusordencompras/${estatusordencompra.id_estatus_orden_compra}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusOrdenCompra(estatusordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusordencompras/${estatusordencompra.id_estatus_orden_compra}`;
    return axios
      .put(url, estatusordencompra)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEstatusOrdenCompra(estatusordencompra) {
    const url = process.env.VUE_APP_APIURL + `/estatusordencompras`;
    return axios
      .post(url, estatusordencompra)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEstatusOrdenCompra(estatusordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusordencompras/${estatusordencompra.id_estatus_orden_compra}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
