import axios from 'axios';

export default class ServiceRequestService {

	getSolicitudServicios() {			
        const url = process.env.VUE_APP_APIURL + `solicitudservicios`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getSolicitudServiciosActivas() {			
        const url = process.env.VUE_APP_APIURL + `solicitudservicios/activas`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getSolicitudServiciosHistorial() {			
        const url = process.env.VUE_APP_APIURL + `solicitudservicios/historial`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getSolicitudServicio(solicitudservicio) {
        const url = process.env.VUE_APP_APIURL + `/solicitudservicios/${solicitudservicio.id_solicitud_servicio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateSolicitudServicio(solicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/solicitudservicios/${solicitudservicio.id_solicitud_servicio}`;
		return axios.put(url,solicitudservicio).then(res=>res.data.data);
	}

	createSolicitudServicio(solicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/solicitudservicios`;
		return axios.post(url,solicitudservicio).then(res=>res.data.data);
	}

	deleteSolicitudServicio(solicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/solicitudservicios/${solicitudservicio.id_solicitud_servicio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}