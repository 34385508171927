
import {
  DxDataGrid, DxColumn, DxPaging, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxLookup, DxColumnChooser, DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import auth from '../auth';

import CompanyService from '../services/company-service';
import AlertService from '../services/alert-service';
import SeverityService from '../services/severity-service';
import GroupAlertService from '../services/group-alert-service';
import AlertOriginService from '../services/alert-origin-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,    
    DxPaging,
    DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxLookup, DxColumnChooser,
    DxToast,
    DxSelectBox,
    DxDateBox,
    DxButton,
    DxGroupPanel,
    DxGrouping,
  },
  data() {
    const now = new Date();
    return {
      usuarioId: null,
      company: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      dataSourceCompanies: null,
      dataSourceAlerts: null,
      dataSourceSeverity: null,
      dataSourceGroup: null,
      dataSourceOrigin: null,
      alertas: {},
      events: [],
      severidad: null,
      alertService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      mode: 'select',
      autoExpandAll: false,
    };
  },
  created() {
    this.alertService = new AlertService();
    this.severityService = new SeverityService();
    this.groupAlertService = new GroupAlertService();
    this.alertOriginService = new AlertOriginService();
    this.companyService = new CompanyService();
  },
  mounted() {
    this.severityService.getSeveridadAlertas().then(data => this.dataSourceSeverity=data);
    this.groupAlertService.getGrupoAlertas().then(data => this.dataSourceGroup=data);
    this.alertOriginService.getOrigenAlertas().then(data => this.dataSourceOrigin=data);
    auth.getUser().then((data) => {
      console.log(data.data.id_usuario);
      this.usuarioId = data.data.id_usuario;
      this.companyService.getEmpresas().then((data) => {
        this.dataSourceCompanies=data; 
        this.company=data[0];
        this.alertService.getAlertasReconocidasPorFiltro(this.usuarioId,this.company.id_empresa,this.startDate,this.endDate).then(data => this.dataSourceAckAlerts=data);
      });
      
    });
  },
  methods: {
    onClick() {
      this.alertService.getAlertasReconocidasPorFiltro(this.usuarioId,this.company.id_empresa,this.startDate,this.endDate).then(data => this.dataSourceAlerts=data);
    },
  }
};
