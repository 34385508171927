import axios from 'axios';

export default class SafetyService {

	getSeguridades() {			
        const url = process.env.VUE_APP_APIURL + `seguridades`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getSeguridad(seguridad) {
        const url = process.env.VUE_APP_APIURL + `seguridades/${seguridad.id_seguridad}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateSeguridad(seguridad){
        const url = process.env.VUE_APP_APIURL + `seguridades/${seguridad.id_seguridad}`;
		return axios.put(url,seguridad).then(res=>res.data.data);
	}

	createSeguridad(seguridad){
        const url = process.env.VUE_APP_APIURL + `seguridades`;
		return axios.post(url,seguridad).then(res=>res.data.data);
	}

	deleteSeguridad(seguridad){
        const url = process.env.VUE_APP_APIURL + `seguridades/${seguridad.id_seguridad}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}