import axios from 'axios';

export default class AreaService {

	getAreas() {				
        const url = process.env.VUE_APP_APIURL + `areas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getArea(area) {
        const url = process.env.VUE_APP_APIURL + `/areas/${area.id_area}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateArea(area){
        const url = process.env.VUE_APP_APIURL + `/areas/${area.id_area}`;
		return axios.put(url,area).then(res=>res.data.data);
	}

	createArea(area){
        const url = process.env.VUE_APP_APIURL + `/areas`;
		return axios.post(url,area).then(res=>res.data.data);
	}

	deleteArea(area){
        const url = process.env.VUE_APP_APIURL + `/areas/${area.id_area}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}