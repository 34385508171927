import axios from 'axios';

export default class SpecialDayService {

	getDiasEspeciales() {		
        const url = process.env.VUE_APP_APIURL + `diasespeciales`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getDiaEspecial(diaespecial) {
        const url = process.env.VUE_APP_APIURL + `/diasespeciales/${diaespecial.id_dia_especial}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateDiaEspecial(diaespecial){
        const url = process.env.VUE_APP_APIURL + `/diasespeciales/${diaespecial.id_dia_especial}`;
		return axios.put(url,diaespecial).then(res=>res.data.data);
	}

	createDiaEspecial(diaespecial){
        const url = process.env.VUE_APP_APIURL + `/diasespeciales`;
		return axios.post(url,diaespecial).then(res=>res.data.data);
	}

	deleteDiaEspecial(diaespecial){
        const url = process.env.VUE_APP_APIURL + `/diasespeciales/${diaespecial.id_dia_especial}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}