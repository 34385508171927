

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import QuoteMaintenancePage from './quote-request-maintenance.vue'; // MTTO
import QuoteRequisitionPage from './quote-requisition.vue'; // Requisiciones
import QuotePage from './quote.vue'; // Cotizaciones

export default {
  components: {
    DxTabPanel,
    DxItem,
    QuoteMaintenancePage,
    QuoteRequisitionPage,
    QuotePage,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
