
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSorting,
  DxSelection,
  DxMasterDetail,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import SupplierService from '../services/supplier-service';
import CountryService from '../services/country-service';
import DetailTemplate from './supplier-contact.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSorting,
    DxSelection,
    DxMasterDetail,
    DetailTemplate,
  },
  data() {
    return {
      proveedores: null,
      dataSourceSuppliers: null,
      dataSourceCountries: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Proveedor',
        showTitle: true,
        width: 800,
      },
    };
  },
  created() {
    this.supplierService = new SupplierService();
    this.countryService = new CountryService();
  },
  mounted() {
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers = data);
    this.countryService.getPaises().then(data => this.dataSourceCountries = data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editSupplier(e) {
      this.supplierService.updateProveedor(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    deleteSupplier(e) {
      this.supplierService.deleteProveedor(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    createSupplier(e) {
      this.supplierService.createProveedor(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    onInitNewRow(e) {
      e.data.activo = true;
    },
  },
};
