import axios from 'axios';

export default class TypePurchaseOrderService {

	getTipoOrdenCompras() {
        const url = process.env.VUE_APP_APIURL + `tipoordencompras`;
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoOrdenCompra(tipoordencompra) {
        const url = process.env.VUE_APP_APIURL + `tipoordencompras/${tipoordencompra.id_tipo_orden_compra}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoOrdenCompra(tipoordencompra){
        const url = process.env.VUE_APP_APIURL + `tipoordencompras/${tipoordencompra.id_tipo_orden_compra}`;
		return axios.put(url,tipoordencompra).then(res=>res.data.data);
	}

	createTipoOrdenCompra(tipoordencompra){
        const url = process.env.VUE_APP_APIURL + `tipoordencompras`;
		return axios.post(url,tipoordencompra).then(res=>res.data.data);
	}

	deleteTipoOrdenCompra(tipoordencompra){
        const url = process.env.VUE_APP_APIURL + `tipoordencompras/${tipoordencompra.id_tipo_orden_compra}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}