import axios from 'axios';

export default class StockTransferService {

	getMovimientos() {		
        const url = process.env.VUE_APP_APIURL + `movimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMovimientosPorAlmacen(almacen,fechaInicio,fechaFin) {		
        const url = process.env.VUE_APP_APIURL + `movimientos/almacenfechas`;
		return axios.get(url,{ params: { id_almacen: almacen, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Genera el movimiento en el inventario
	createMovimiento(movimiento){
        const url = process.env.VUE_APP_APIURL + `/movimientos`;
		return axios.post(url,movimiento).then(res=>res.data.data);
	}

}