import axios from "axios";

export default class ActivityService {
  getActividades() {
    const url = process.env.VUE_APP_APIURL + `actividades`;
    return axios.get(url).then((res) => res.data.data);
  }

  getActividadesPorCanal(canal) {
    const url = process.env.VUE_APP_APIURL + `actividades/canales/${canal}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getActividad(actividad) {
    const url =
      process.env.VUE_APP_APIURL + `/actividades/${actividad.id_actividad}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateActividad(actividad) {
    const url =
      process.env.VUE_APP_APIURL + `/actividades/${actividad.id_actividad}`;
    return axios.put(url, actividad).then((res) => res.data.data);
  }

  createActividad(actividad) {
    const url = process.env.VUE_APP_APIURL + `/actividades`;
    return axios.post(url, actividad).then((res) => res.data.data);
  }

  deleteActividad(actividad) {
    const url =
      process.env.VUE_APP_APIURL + `/actividades/${actividad.id_actividad}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  updateActividadDragDrop(actividad) {
    const url = process.env.VUE_APP_APIURL + `/actividades/dragdrop`;
    return axios.put(url, actividad).then((res) => res.data.data);
  }

  updateActividadStatus(actividad) {
    const url = process.env.VUE_APP_APIURL + `/actividades/estatus`;
    return axios.put(url, actividad).then((res) => res.data.data);
  }

  archive(actividad) {
    const url = process.env.VUE_APP_APIURL + `/actividades/archivar`;
    return axios.put(url, actividad).then((res) => res.data.data);
  }

  getActividadesArchivados() {
    const url = process.env.VUE_APP_APIURL + `actividades/achivados`;
    return axios.get(url).then((res) => res.data.data);
  }

  restoreactivity(actividad) {
    const url = process.env.VUE_APP_APIURL + `/actividades/restaurar`;
    return axios.put(url, actividad).then((res) => res.data.data);
  }

  updateActividadOrden(actividad) {
    const url = process.env.VUE_APP_APIURL + `/actividades/orden`;
    return axios.put(url, actividad).then((res) => res.data.data);
  }

  getActividadesArchivadosPorCanal(idCanal) {
    const url = process.env.VUE_APP_APIURL + `actividades/achivados/${idCanal}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getActividadesGantt(id_canal, id_usuario_asignado) {
    const url = process.env.VUE_APP_APIURL + `actividades/gantt`;
    return axios
      .get(url, {
        params: {
          id_canal: id_canal,
          id_usuario_asignado: id_usuario_asignado,
        },
      })
      .then((res) => res.data.data);
  }

  getActividadesCalendario(id_canal, id_usuario) {
    const url = process.env.VUE_APP_APIURL + `actividades/calendario`;
    return axios
      .get(url, {
        params: {
          id_canal: id_canal,
          id_usuario: id_usuario,
        },
      })
      .then((res) => res.data.data);
  }
}
