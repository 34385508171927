
import {
  DxTreeList,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxButton
} from 'devextreme-vue/tree-list';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import { confirm } from "devextreme/ui/dialog";

import EquipmentService from '../services/equipment-service';
import BrandService from '../services/brand-service';
import EquipmentCategoryService from '../services/equipment-category-service';
import ProductService from '../services/product-service';
import GroupProductService from '../services/group-product-service';
import EquipmentGenericService from '../services/equipment-generic-service';


export default {
  components: {
    DxTreeList,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
    DxButton,
  },
  data() {
    return {
      equipos: null,
      product: {},
      groupproduct: null,
      bindingCodigo: null,
      expandedRowKeys: [1],
      dataSourceEquipments: null,
      dataSourceBrands: null,
      dataSourceEquipmentCategories: null,
      dataSourceFatherEquipments: null,
      dataSourceProducts: null,
      dataSourceGroupProducts: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Equipo',
        showTitle: true,
        height: 600,
        width: 950,
      },
      mode: 'select',      
    };
  },
  created() {
    this.equipmentService = new EquipmentService();
    this.brandService = new BrandService();
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.productService = new ProductService();
    this.groupProductService = new GroupProductService();
    this.equipmentGenericService = new EquipmentGenericService();
  },
  mounted() {
    this.productService.getComboProductos().then(data => this.dataSourceProducts=data);
    this.groupProductService.getGrupoProductos().then(data => this.dataSourceGroupProducts=data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);    
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);    
    this.equipmentService.getWebEquipos().then((data) => {this.dataSourceFatherEquipments=data; this.dataSourceEquipments=data;});
    //this.equipmentService.getEquipos().then(data => this.dataSourceEquipments=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },    
    editEquipment(e) {
      this.equipmentService.updateEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al editar el equipo';
        this.isVisible = true;
        this.equipmentService.getWebEquipos().then((data) => {this.dataSourceFatherEquipments=data; this.dataSourceEquipments=data;});
      });
		},
    deleteEquipment(e) {
			this.equipmentService.deleteEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el equipo';
        this.isVisible = true;
        this.equipmentService.getWebEquipos().then((data) => {this.dataSourceFatherEquipments=data; this.dataSourceEquipments=data;});
      });
		},
    createEquipment(e) {
			this.equipmentService.createEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentService.getWebEquipos().then((data) => {this.dataSourceFatherEquipments=data; this.dataSourceEquipments=data;});
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el equipo';
        this.isVisible = true;
        this.equipmentService.getWebEquipos().then((data) => {this.dataSourceFatherEquipments=data; this.dataSourceEquipments=data;});
      });
		},    
    onInitNewRow(e) {
      e.data.fijo = true;
      e.data.activo = true;
      e.data.cantidad = 1;
      //e.data.id_equipo_padre = 0;
      //this.equipmentService.getEquipoPadres().then(data => this.dataSourceFatherEquipments=data);
    },
    setStateValue(rowData, value) {
      if (value!=null) {
        this.dataSourceProducts.forEach(element => {
          if (element.id_producto==value) {
            rowData.clave = element.clave;
            rowData.nombre = element.nombre;
            rowData.vida_util_ano = element.vida_util_ano;
            rowData.modelo = element.modelo;
            rowData.id_marca = element.id_marca;
            rowData.numero_parte = element.numero_parte;
            rowData.cantidad = 1;
            rowData.id_producto = value;
          }
        });
      } else {
        rowData.id_producto = null;
      }
    },
    onEditorPreparing(e) {
      if (e.parentType === "dataRow" && e.dataField === "id_producto")
      {
        if (!e.row.isEditing)
          e.editorOptions.disabled = true;
      }
        
    },
    popupGenericEquipment(e) {
      confirm("¿Desea crear un equipo genérico?", "Crear Equipo Genérico").then((dialogResult) => {
        if (dialogResult) {
          this.equipmentGenericService.createEquipoGenericoDesdeEquipo({"id_equipo": e.row.data.id_equipo}).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado equipo genérico';
            this.isVisible = true;
          }).catch(() => {
            this.type = 'error';
            this.message = 'Falla al crear equipo genérico';
            this.isVisible = true;
          });
        }
      });
    },
  },  
};
