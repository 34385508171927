import axios from 'axios';

export default class EquipmentService {

	getWebEquipos() {		
        const url = process.env.VUE_APP_APIURL + `equipos/web`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipos() {		
        const url = process.env.VUE_APP_APIURL + `equipos`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoPadres() {		
        const url = process.env.VUE_APP_APIURL + `equipos/padres`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquiposCombo() {		
        const url = process.env.VUE_APP_APIURL + `equipos/combo`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCatalogos() {		
        const url = process.env.VUE_APP_APIURL + `equipos/catalogos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipo(equipo) {
        const url = process.env.VUE_APP_APIURL + `/equipos/${equipo.id_equipo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipo(equipo){
        const url = process.env.VUE_APP_APIURL + `/equipos/${equipo.id_equipo}`;
		return axios.put(url,equipo).then(res=>res.data.data);
	}

	createEquipo(equipo){
        const url = process.env.VUE_APP_APIURL + `/equipos`;
		return axios.post(url,equipo).then(res=>res.data.data);		
	}

	deleteEquipo(equipo){
        const url = process.env.VUE_APP_APIURL + `/equipos/${equipo.id_equipo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}