import CompanyDetail from './views/company-detail';
import CompanyContact from './views/company-contact';
import CompanyAddress from './views/company-address';
// import Survey from "./views/survey";
import ConfigSurveyTab from "./views/config-survey-tab";
import QuoteRequisition from "./views/quote-requisition";
import QuoteRequestMaintenance from "./views/quote-request-maintenance";
import Department from "./views/department";
import ClientCompany from "./views/client-company";
import ClientUser from "./views/client-user";
import ClientTab from "./views/client-tab";
import ServiceRequestHistory from "./views/service-request-history";
import ServiceRequestTab from "./views/service-request-tab";
import ConfigNotification from "./views/config-notification";
import MonitorCtrl from "./views/monitor-ctrl";
import MonitorAnalytic from "./views/monitor-analytic";
import MonitorAlert from "./views/monitor-alert";
import MonitorChart from "./views/monitor-chart";
import MonitorSchedule from "./views/monitor-schedule";
import Monitor01 from "./views/monitor01";
import MonitorArea from "./views/monitor-area";
import MonitorControlDetail from "./views/monitor-control-detail";
import MonitorControl from "./views/monitor-control";
import MonitorFont from "./views/monitor-font";
import MonitorVariableIndicator from "./views/monitor-variable-indicator";
import MonitorVariable from "./views/monitor-variable";
import MonitorLayoutObject from "./views/monitor-layout-object";
import MonitorLayout from "./views/monitor-layout";
import MonitorTab from "./views/monitor-tab";
import PhysicalInventoryItem from "./views/physical-inventory-item";
import PhysicalInventoryHistory from "./views/physical-inventory-history";
import PhysicalInventoryActive from "./views/physical-inventory-active";
import PhysicalInventoryUser from "./views/physical-inventory-user";
import PhysicalInventoryTab from "./views/physical-inventory-tab";
import ServiceRequest from "./views/service-request";
import Kpi from "./views/kpi";
import WarrantyRequestDetail from "./views/warranty-request-detail";
import WarrantyRequest from "./views/warranty-request";
import EventTab from "./views/event-tab";
import EventGps from "./views/event-gps";
import AlertAcknowledge from "./views/alert-acknowledge";
import AlertTab from "./views/alert-tab";
import KitItem from "./views/kit-item";
import ItemClassification from "./views/item-classification";
import EquipmentCategoryDocument from "./views/equipment-category-document";
import WarrantyTab from "./views/warranty-tab";
import WarrantyEquipment from "./views/warranty-equipment";
import Warranty from "./views/warranty";
import UserKpi from "./views/user-kpi";
import UserNotifications from "./views/user-notifications.vue";
import UserTab from "./views/user-tab";
import PrivacyPolicy from "./views/privacy-policy";
import ClientHome from "./views/client-home";
import License from "./views/license";
import EquipmentCompanyData from "./views/equipment-company-data";
import ConfigEmail from "./views/config-email";
import RequisitionTab from "./views/requisition-tab";
import RequisitionHistory from "./views/requisition-history";
import StockRequestHistory from "./views/stock-request-history";
import StockRequestTab from "./views/stock-request-tab";
import DocumentTab from "./views/document-tab";
import QuoteTab from "./views/quote-tab";
import Channel from "./views/channel";
import ServiceReportList from "./views/service-report-list";
import ActionMaintenanceType from "./views/action-maintenance-type";
import ActionWorkorder from "./views/action-workorder";
import MeasurementTab from "./views/measurement-tab";
import MeasurementFlow from "./views/measurement-flow";
import EquipmentCompanyQr from "./views/equipment-company-qr";
import EquipmentCompanyTab from "./views/equipment-company-tab";
import ActionTab from "./views/action-tab";
import StockTransferTab from "./views/stock-transfer-tab";
import TaskTab from "./views/task-tab";
import StockRequest from "./views/stock-request";
import MaintenancePlanLast from "./views/maintenance-plan-last";
import MaintenancePlanPlanner from "./views/maintenance-plan-planner";
import MaintenancePlanTab from "./views/maintenance-plan-tab";
import WorkOrderCreate from "./views/work-order-create";
import EquipmentSearch from "./views/equipment-search";
import EquipmentGallery from "./views/equipment-gallery";
import EquipmentTab from "./views/equipment-tab";
import Requisition from "./views/requisition";
import PhysicalInventory from "./views/physical-inventory";
import StockTransfer from "./views/stock-transfer";
import RoleTab from "./views/role-tab";
import RolePermit from "./views/role-permit";
import PurchaseOrder from "./views/purchase-order";
import FaultReport from "./views/fault-report";
import MeasurementRecord from "./views/measurement-record";
import ConfigChecklist from "./views/config-checklist";
import Checklist from "./views/checklist";
import FollowRecord from "./views/follow-record";
import FaultRecord from "./views/fault-record";
import MaintenancePlan from "./views/maintenance-plan";
import WorkOrderTab from "./views/work-order-tab";
import ProductSpecialField from "./views/product-special-field";
import ProductTab from "./views/product-tab";
import ItemSpare from "./views/item-spare";
import ItemSpecialField from "./views/item-special-field";
import ItemTab from "./views/item-tab";
import CompanyClient from "./views/company-client";
import CompanyTab from "./views/company-tab";
import ConfigAlertRule from "./views/config-alert-rule";
import ConfigAlertTab from "./views/config-alert-tab";
import AlertVar from "./views/alert-var";
import LogicOperator from "./views/logic-operator";
import Safety from "./views/safety";
import UnitOperation from "./views/unit-operation";
import MaintPolicy from "./views/maint-policy";
import OriginWorkorder from "./views/origin-workorder";
import ConfigTask from "./views/config-task";
import ConfigFrequencyDetail from "./views/config-frequency-detail";
import Consecutive from "./views/consecutive";
import Month from "./views/month";
import CancelReason from "./views/cancel-reason";
import FindingLevel from "./views/finding-level";
import OriginData from "./views/origin-data";
import OriginAlert from "./views/origin-alert";
import ConfigFrequency from "./views/config-frequency";
import MeasurementOption from "./views/measurement-option";
import About from "./views/about";
import Skill from "./views/skill";
import MaintenancePolicy from "./views/maintenance-policy";
import TaskReason from "./views/task-reason";
import Permit from "./views/permit";
import WorkorderNew from "./views/workorder-new";
import EquipmentCompany from "./views/equipment-company";
import Condition from "./views/condition";
import Cfgspecialfield from "./views/cfgspecialfield";
import Specialfield from "./views/specialfield";
import Product from "./views/product";
import Activity from "./views/activity";
import Measurement from "./views/measurement";
import ControlSize from "./views/control-size";
import Control from "./views/control";
import Synonymous from "./views/synonymous";
import Priority from "./views/priority";
import Stock from "./views/stock";
import LayoutReport from "./views/layout-report";
import PriceList from "./views/price-list";
import MapEquipment from "./views/map-equipment";
import MapPerson from "./views/map-person";
import WorkOrderHistory from "./views/work-order-history";
import ServiceReport from "./views/service-report";
import Company from "./views/company";
import UnitOfMeasurement from "./views/unit-of-measurement";
import Project from "./views/project";
import BugReport from "./views/bug-report";
import Procedure from "./views/procedure";
import Country from "./views/country";
import Origin from "./views/origin";
import NotificationMethod from "./views/notification-method";
import Notifications from "./views/notification";
import Currency from "./views/currency";
import Brand from "./views/brand";
import Log from "./views/log";
import Location from "./views/location";
import Localization from "./views/localization";
import Layout from "./views/layout";
import Kit from "./views/kit";
import Finding from "./views/finding";
import Ability from "./views/ability";
import Group from "./views/group";
import Frequency from "./views/frequency";
import PaymentMethod from "./views/payment-method";
import Status from "./views/status";
import Quote from "./views/quote";
import Certification from "./views/certification";
import EquipmentCategory from "./views/equipment-category";
import FestiveDay from "./views/festive-day";
import SpecialDay from "./views/special-day";
import Document from "./views/document";
import ConfigPlan from "./views/config-plan";
import ConfigGeneral from "./views/config-general";
import ConfigField from "./views/config-field";
import ConfigCalendar from "./views/config-calendar";
import ConfigAlert from "./views/config-alert";
import WorkCalendar from "./views/work-calendar";
import Event from "./views/event";
import Alert from "./views/alert";
import Role from "./views/role";
import Severity from "./views/severity";
import Service from "./views/service";
import Jobtitle from "./views/jobtitle";
import Type from "./views/type";
import Equipment from "./views/equipment";
import Supplier from "./views/supplier";
import Client from "./views/client";
import Item from "./views/item";
import Warehouse from "./views/warehouse";
import User from "./views/user";
import WorkOrder from "./views/work-order";
import ItemLocations from "./views/item-locations";
// import ValidatePage from "./views/validate-page";
// import GenerateServiceReport from "./views/generate-service-report";
import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home";
import Profile from "./views/profile";
import Task from "./views/task";
import Tasks from "./views/tasks";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import simpleLayoutSurvey from "./layouts/single-card-survey";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`);
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Home,
    },
    {
      path: "/profile",
      name: "Mi Perfil",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Profile,
    },
    {
      path: "/tasks",
      name: "Demo Tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Tasks,
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        //title: "Login",
      },
      component: loadView("login-form"),
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Recuperar Contraseña",
        description:
          "Favor de escribir el correo electrónico registrado para enviar una liga de recuperación de contraseña.",
      },
      component: loadView("reset-password-form"),
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up",
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password",
      },
      component: loadView("change-password-form"),
    },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/recovery",
      redirect: "/home",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home",
    },
    {
      path: "/work-order",
      name: "work-order",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WorkOrder,
    },
    {
      path: "/user",
      name: "user",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: User,
    },
    {
      path: "/warehouse",
      name: "warehouse",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Warehouse,
    },
    {
      path: "/item",
      name: "item",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Item,
    },
    {
      path: "/client",
      name: "client",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Client,
    },
    {
      path: "/supplier",
      name: "supplier",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Supplier,
    },
    {
      path: "/equipment",
      name: "equipment",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Equipment,
    },
    {
      path: "/type",
      name: "type",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Type,
    },
    {
      path: "/jobtitle",
      name: "jobtitle",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Jobtitle,
    },
    {
      path: "/service",
      name: "service",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Service,
    },
    {
      path: "/severity",
      name: "severity",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Severity,
    },
    {
      path: "/role",
      name: "role",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Role,
    },
    {
      path: "/alert",
      name: "alert",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Alert,
    },
    {
      path: "/event",
      name: "event",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Event,
    },
    {
      path: "/work-calendar",
      name: "work-calendar",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WorkCalendar,
    },
    {
      path: "/config-alert",
      name: "config-alert",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigAlert,
    },
    {
      path: "/config-calendar",
      name: "config-calendar",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigCalendar,
    },
    {
      path: "/config-field",
      name: "config-field",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigField,
    },
    {
      path: "/config-general",
      name: "config-general",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigGeneral,
    },
    {
      path: "/config-plan",
      name: "config-plan",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigPlan,
    },
    {
      path: "/document",
      name: "document",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Document,
    },
    {
      path: "/special-day",
      name: "special-day",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: SpecialDay,
    },
    {
      path: "/festive-day",
      name: "festive-day",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FestiveDay,
    },
    {
      path: "/equipment-category",
      name: "equipment-category",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentCategory,
    },
    {
      path: "/certification",
      name: "certification",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Certification,
    },
    {
      path: "/quote",
      name: "quote",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Quote,
    },
    {
      path: "/status",
      name: "status",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Status,
    },
    {
      path: "/payment-method",
      name: "payment-method",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PaymentMethod,
    },
    {
      path: "/frequency",
      name: "frequency",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Frequency,
    },
    {
      path: "/group",
      name: "group",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Group,
    },
    {
      path: "/ability",
      name: "ability",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Ability,
    },
    {
      path: "/finding",
      name: "finding",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Finding,
    },
    {
      path: "/kit",
      name: "kit",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Kit,
    },
    {
      path: "/layout",
      name: "layout",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Layout,
    },
    {
      path: "/localization",
      name: "localization",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Localization,
    },
    {
      path: "/location",
      name: "location",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Location,
    },
    {
      path: "/log",
      name: "log",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Log,
    },
    {
      path: "/brand",
      name: "brand",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Brand,
    },
    {
      path: "/currency",
      name: "currency",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Currency,
    },
    {
      path: "/notification-method",
      name: "notification-method",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: NotificationMethod,
    },
    {
      path: "/notification",
      name: "notification",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Notifications,
    },
    {
      path: "/origin",
      name: "origin",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Origin,
    },
    {
      path: "/country",
      name: "country",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Country,
    },
    {
      path: "/procedure",
      name: "procedure",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Procedure,
    },
    {
      path: "/task",
      name: "task",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Task,
    },
    {
      path: "/bug-report",
      name: "bug-report",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: BugReport,
    },
    {
      path: "/project",
      name: "project",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Project,
    },
    {
      path: "/unit-of-measurement",
      name: "unit-of-measurement",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: UnitOfMeasurement,
    },
    {
      path: "/company",
      name: "company",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Company,
    },
    {
      path: "/service-report/:id_orden_trabajo",
      name: "service-report",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ServiceReport,
      props: true,
    },
    {
      path: "/work-order-history",
      name: "work-order-history",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WorkOrderHistory,
    },
    {
      path: "/map-person",
      name: "map-person",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MapPerson,
    },
    {
      path: "/map-equipment",
      name: "map-equipment",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MapEquipment,
    },
    {
      path: "/price-list",
      name: "price-list",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PriceList,
    },
    {
      path: "/layout-report",
      name: "layout-report",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: LayoutReport,
    },
    {
      path: "/stock",
      name: "stock",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Stock,
    },
    {
      path: "/priority",
      name: "priority",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Priority,
    },
    {
      path: "/synonymous",
      name: "synonymous",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Synonymous,
    },
    {
      path: "/control-size",
      name: "control-size",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ControlSize,
    },
    {
      path: "/measurement",
      name: "measurement",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Measurement,
    },
    {
      path: "/activity",
      name: "activity",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Activity,
    },
    {
      path: "/product",
      name: "product",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Product,
    },
    {
      path: "/specialfield",
      name: "specialfield",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Specialfield,
    },
    {
      path: "/cfgspecialfield",
      name: "cfgspecialfield",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Cfgspecialfield,
    },
    {
      path: "/condition",
      name: "condition",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Condition,
    },
    {
      path: "/equipment-company",
      name: "equipment-company",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentCompany,
    },
    {
      path: "/workorder-new",
      name: "workorder-new",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WorkorderNew,
    },
    {
      path: "/permit",
      name: "permit",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Permit,
    },
    {
      path: "/task-reason",
      name: "task-reason",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: TaskReason,
    },
    {
      path: "/maintenance-policy",
      name: "maintenance-policy",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MaintenancePolicy,
    },
    {
      path: "/skill",
      name: "skill",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Skill,
    },
    {
      path: "/about",
      name: "about",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: About,
    },
    {
      path: "/privacy-policy",
      name: "Aviso de Privacidad",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PrivacyPolicy,
    },
    {
      path: "/measurement-option",
      name: "Opciones de Medición",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MeasurementOption,
    },
    {
      path: "/config-frequency",
      name: "config-frequency",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigFrequency,
    },
    {
      path: "/origin-alert",
      name: "origin-alert",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OriginAlert,
    },
    {
      path: "/origin-data",
      name: "origin-data",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OriginData,
    },
    {
      path: "/finding-level",
      name: "finding-level",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FindingLevel,
    },
    {
      path: "/cancel-reason",
      name: "cancel-reason",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: CancelReason,
    },
    {
      path: "/month",
      name: "month",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Month,
    },
    {
      path: "/consecutive",
      name: "consecutive",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Consecutive,
    },
    {
      path: "/config-frequency-detail",
      name: "config-frequency-detail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigFrequencyDetail,
    },
    {
      path: "/config-task",
      name: "config-task",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigTask,
    },
    {
      path: "/origin-workorder",
      name: "origin-workorder",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: OriginWorkorder,
    },
    {
      path: "/maint-policy",
      name: "maint-policy",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MaintPolicy,
    },
    {
      path: "/unit-operation",
      name: "unit-operation",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: UnitOperation,
    },
    {
      path: "/safety",
      name: "safety",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Safety,
    },
    {
      path: "/logic-operator",
      name: "logic-operator",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: LogicOperator,
    },
    {
      path: "/alert-var",
      name: "alert-var",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: AlertVar,
    },
    {
      path: "/config-alert-tab",
      name: "config-alert-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigAlertTab,
    },
    {
      path: "/config-alert-rule",
      name: "config-alert-rule",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigAlertRule,
    },
    {
      path: "/company-tab",
      name: "company-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: CompanyTab,
    },
    {
      path: "/company-client",
      name: "company-client",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: CompanyClient,
    },
    {
      path: "/item-tab",
      name: "item-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ItemTab,
    },
    {
      path: "/item-special-field",
      name: "item-special-field",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ItemSpecialField,
    },
    {
      path: "/item-spare",
      name: "item-spare",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ItemSpare,
    },
    {
      path: "/product-tab",
      name: "product-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ProductTab,
    },
    {
      path: "/product-special-field",
      name: "product-special-field",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ProductSpecialField,
    },
    {
      path: "/work-order-tab",
      name: "work-order-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WorkOrderTab,
    },
    {
      path: "/maintenance-plan",
      name: "maintenance-plan",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MaintenancePlan,
    },
    {
      path: "/fault-record",
      name: "fault-record",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FaultRecord,
    },
    {
      path: "/follow-record",
      name: "follow-record",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FollowRecord,
    },
    {
      path: "/checklist",
      name: "checklist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Checklist,
    },
    {
      path: "/config-checklist",
      name: "config-checklist",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigChecklist,
    },
    {
      path: "/measurement-record",
      name: "measurement-record",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MeasurementRecord,
    },
    {
      path: "/fault-report",
      name: "fault-report",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: FaultReport,
    },
    {
      path: "/purchase-order",
      name: "purchase-order",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PurchaseOrder,
    },
    {
      path: "/role-permit",
      name: "role-permit",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: RolePermit,
    },
    {
      path: "/role-tab",
      name: "role-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: RoleTab,
    },
    {
      path: "/stock-transfer",
      name: "stock-transfer",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: StockTransfer,
    },
    {
      path: "/physical-inventory",
      name: "physical-inventory",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PhysicalInventory,
    },
    {
      path: "/requisition",
      name: "requisition",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Requisition,
    },
    {
      path: "/equipment-tab",
      name: "equipment-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentTab,
    },
    {
      path: "/equipment-gallery",
      name: "equipment-gallery",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentGallery,
    },
    {
      path: "/equipment-search",
      name: "equipment-search",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentSearch,
    },
    {
      path: "/control",
      name: "control",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Control,
    },
    {
      path: "/work-order-create",
      name: "work-order-create",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WorkOrderCreate,
    },
    {
      path: "/maintenance-plan-tab",
      name: "maintenance-plan-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MaintenancePlanTab,
    },
    {
      path: "/maintenance-plan-planner",
      name: "maintenance-plan-planner",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MaintenancePlanPlanner,
    },
    {
      path: "/maintenance-plan-last",
      name: "maintenance-plan-last",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MaintenancePlanLast,
    },
    {
      path: "/stock-request",
      name: "stock-request",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: StockRequest,
    },
    {
      path: "/task-tab",
      name: "task-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: TaskTab,
    },
    {
      path: "/stock-transfer-tab",
      name: "stock-transfer-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: StockTransferTab,
    },
    {
      path: "/action-tab",
      name: "action-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ActionTab,
    },
    {
      path: "/equipment-company-tab",
      name: "equipment-company-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentCompanyTab,
    },
    {
      path: "/equipment-company-qr",
      name: "equipment-company-qr",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentCompanyQr,
    },
    {
      path: "/measurement-flow",
      name: "measurement-flow",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MeasurementFlow,
    },
    {
      path: "/measurement-tab",
      name: "measurement-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MeasurementTab,
    },
    {
      path: "/action-workorder",
      name: "action-workorder",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ActionWorkorder,
    },
    {
      path: "/action-maintenance-type",
      name: "action-maintenance-type",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ActionMaintenanceType,
    },
    {
      path: "/service-report-list",
      name: "service-report-list",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ServiceReportList,
    },
    {
      path: "/channel",
      name: "channel",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Channel,
    },
    {
      path: "/quote-tab",
      name: "quote-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: QuoteTab,
    },
    {
      path: "/document-tab",
      name: "document-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: DocumentTab,
    },
    {
      path: "/stock-request-tab",
      name: "stock-request-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: StockRequestTab,
    },
    {
      path: "/stock-request-history",
      name: "stock-request-history",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: StockRequestHistory,
    },
    {
      path: "/requisition-history",
      name: "requisition-history",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: RequisitionHistory,
    },
    {
      path: "/requisition-tab",
      name: "requisition-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: RequisitionTab,
    },
    {
      path: "/config-email",
      name: "config-email",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigEmail,
    },
    {
      path: "/equipment-company-data",
      name: "equipment-company-data",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentCompanyData,
    },
    {
      path: "/license",
      name: "license",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: License,
    },
    {
      path: "/client-home",
      name: "client-home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ClientHome,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PrivacyPolicy,
    },
    {
      path: "/generate-service-report",
      name: "generate-service-report",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
      },
      component: loadView("generate-service-report"),
    },
    {
      path: "/user-tab",
      name: "user-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: UserTab,
    },
    {
      path: "/user-kpi",
      name: "user-kpi",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: UserKpi,
    },
    {
      path: "/user-notifications",
      name: "user-notifications",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: UserNotifications,
    },
    {
      path: "/warranty",
      name: "warranty",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Warranty,
    },
    {
      path: "/warranty-equipment",
      name: "warranty-equipment",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WarrantyEquipment,
    },
    {
      path: "/warranty-tab",
      name: "warranty-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WarrantyTab,
    },
    {
      path: "/equipment-category-document",
      name: "equipment-category-document",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EquipmentCategoryDocument,
    },
    {
      path: "/item-classification",
      name: "item-classification",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ItemClassification,
    },
    {
      path: "/kit-item",
      name: "kit-item",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: KitItem,
    },
    {
      path: "/alert-tab",
      name: "alert-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: AlertTab,
    },
    {
      path: "/alert-acknowledge",
      name: "alert-acknowledge",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: AlertAcknowledge,
    },
    {
      path: "/event-gps",
      name: "event-gps",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EventGps,
    },
    {
      path: "/event-tab",
      name: "event-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: EventTab,
    },
    {
      path: "/warranty-request",
      name: "warranty-request",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WarrantyRequest,
    },
    {
      path: "/warranty-request-detail",
      name: "warranty-request-detail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: WarrantyRequestDetail,
    },
    {
      path: "/kpi",
      name: "kpi",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Kpi,
    },
    {
      path: "/service-request",
      name: "service-request",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ServiceRequest,
    },
    {
      path: "/physical-inventory-tab",
      name: "physical-inventory-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PhysicalInventoryTab,
    },
    {
      path: "/physical-inventory-user",
      name: "physical-inventory-user",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PhysicalInventoryUser,
    },
    {
      path: "/physical-inventory-active",
      name: "physical-inventory-active",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PhysicalInventoryActive,
    },
    {
      path: "/physical-inventory-history",
      name: "physical-inventory-history",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PhysicalInventoryHistory,
    },
    {
      path: "/physical-inventory-item",
      name: "physical-inventory-item",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: PhysicalInventoryItem,
    },
    {
      path: "/monitor-tab",
      name: "monitor-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorTab,
    },
    {
      path: "/monitor-layout",
      name: "monitor-layout",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorLayout,
    },
    {
      path: "/monitor-layout-object",
      name: "monitor-layout-object",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorLayoutObject,
    },
    {
      path: "/monitor-variable",
      name: "monitor-variable",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorVariable,
    },
    {
      path: "/monitor-variable-indicator",
      name: "monitor-variable-indicator",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorVariableIndicator,
    },
    {
      path: "/monitor-font",
      name: "monitor-font",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorFont,
    },
    {
      path: "/monitor-control",
      name: "monitor-control",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorControl,
    },
    {
      path: "/monitor-control-detail",
      name: "monitor-control-detail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorControlDetail,
    },
    {
      path: "/monitor-area",
      name: "monitor-area",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorArea,
    },
    {
      path: "/monitor01",
      name: "monitor01",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Monitor01,
    },
    {
      path: "/monitor-schedule",
      name: "monitor-schedule",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorSchedule,
    },
    {
      path: "/monitor-chart",
      name: "monitor-chart",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorChart,
    },
    {
      path: "/monitor-alert",
      name: "monitor-alert",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorAlert,
    },
    {
      path: "/monitor-analytic",
      name: "monitor-analytic",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorAnalytic,
    },
    {
      path: "/monitor-ctrl",
      name: "monitor-ctrl",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: MonitorCtrl,
    },
    {
      path: "/config-notification",
      name: "config-notification",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigNotification,
    },
    {
      path: "/service-request-tab",
      name: "service-request-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ServiceRequestTab,
    },
    {
      path: "/service-request-history",
      name: "service-request-history",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ServiceRequestHistory,
    },
    {
      path: "/client-tab",
      name: "client-tab",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ClientTab,
    },
    {
      path: "/client-user",
      name: "client-user",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ClientUser,
    },
    {
      path: "/client-company",
      name: "client-company",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ClientCompany,
    },
    {
      path: "/department",
      name: "department",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: Department,
    },
    {
      path: "/quote-request-maintenance",
      name: "quote-request-maintenance",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: QuoteRequestMaintenance,
    },
    {
      path: "/quote-requisition",
      name: "quote-requisition",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: QuoteRequisition,
    },
    {
      path: "/item-locations",
      name: "item-locations",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ItemLocations,
    },
    {
      path: "/config-survey",
      name: "config-survey",
      meta: {
        requiresAuth: true,
        layout: defaultLayout,
      },
      component: ConfigSurveyTab,
    },
    {
      path: "/survey/:surveyCode",
      name: "survey",
      meta: {
        requiresAuth: false,
        layout: simpleLayoutSurvey,
        title: "Survey",
      },
      component: loadView("survey"),
    },
    {
      path: "/company-address",
      name: "company-address",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: CompanyAddress
    }, 
    {
      path: "/company-contact",
      name: "company-contact",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: CompanyContact
    }, 
    {
      path: "/company-detail",
      name: "company-detail",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: CompanyDetail
    }
  ],
  history: createWebHashHistory(),
});

router.beforeEach(async (to, from, next) => {
  const auth_token = localStorage.getItem("user-token");
  const usuario = JSON.parse(localStorage.getItem("user"));
  if (to.name === "login-form" && auth.loggedIn()) {
    usuario.id_rol == 5
      ? next({ name: "client-home" })
      : next({ name: "home" });
    //next({ name: "home" });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.loggedIn() && !auth_token) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeResolve(async (to) => {
  const id_usuario = localStorage.getItem("user-data");
  const usuario = JSON.parse(localStorage.getItem("user"));
  const urlroute =
    process.env.VUE_APP_APIURL +
    `permisos/menuswebroute` +
    "?id_usuario=" +
    id_usuario;

  if (id_usuario != null) {
    const firebaseConfig = {
      apiKey: "AIzaSyAI15PAQ_Mwgb9b4G250tCfcoyGk6Go6jI",
      authDomain: "apiclix.firebaseapp.com",
      projectId: "apiclix",
      storageBucket: "apiclix.appspot.com",
      messagingSenderId: "50460305076",
      appId: "1:50460305076:web:e9a3bd1c63cc08831fd9e1",
      measurementId: "G-ELD99ZW9R8",
    };

    // Initialize Firebase app
    const appFirebase = initializeApp(firebaseConfig);

    const messaging = getMessaging(appFirebase);

    onMessage(messaging, (payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: "favicon.ico",
      };

      new Notification(notificationTitle, notificationOptions);
    });

    getToken(messaging, {
      vapidKey:
        "BMaWGlhW7fHW0V7tm5nfEm_tVTIl9rgjorVSGi9HXRQCOVPDnRb-0lljLJSh36DmSBcnfkBcFSc6kFgyZoOtk3A",
    })
      .then((currentToken) => {
        if (currentToken) {
          var datanotify = {
            id_aplicacion: 1,
            token: currentToken,
            id_usuario: id_usuario,
          };
          axios
            .post(
              process.env.VUE_APP_APIURL + `/notificaciontokens/guardar`,
              datanotify
            )
            .then((res) => res.data.data);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    if (usuario.id_rol == 5) {
      if (
        to.path != "/privacy-policy" &&
        to.path != "/about" &&
        to.name != "service-report" &&
        to.name != "change-password" &&
        to.name != "survey"
      ) {
        axios.get(urlroute).then((res) => {
          var getroute = res.data.data.find((e) => e.ruta === to.path);
          if (getroute === undefined) {
            router.replace({
              path: "/client-home",
            });
          }
        });
      }
    } else {
      if (
        to.path != "/profile" &&
        to.path != "/about" &&
        to.path != "/privacy-policy" &&
        to.path != "/license" &&
        to.name != "service-report" &&
        to.name != "change-password" &&
        to.name != "survey"
      ) {
        axios.get(urlroute).then((res) => {
          var getroute = res.data.data.find((e) => e.ruta === to.path);
          if (getroute === undefined) {
            router.replace({
              path: "/home",
            });
          }
        });
      }
    }
  }
});

export default router;
