

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import Task from './task.vue';
import TaskMeasurement from './task-measurement.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    Task,
    TaskMeasurement,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
