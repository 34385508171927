import axios from 'axios';

export default class ItemSpareService {

	getRefacciones() {		
        const url = process.env.VUE_APP_APIURL + `refacciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getRefaccion(refaccion) {
        const url = process.env.VUE_APP_APIURL + `/refacciones/${refaccion.id_refaccion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateRefaccion(refaccion){
        const url = process.env.VUE_APP_APIURL + `/refacciones/${refaccion.id_refaccion}`;
		return axios.put(url,refaccion).then(res=>res.data.data);
	}

	createRefaccion(refaccion){
        const url = process.env.VUE_APP_APIURL + `/refacciones`;
		return axios.post(url,refaccion).then(res=>res.data.data);
	}

	deleteRefaccion(refaccion){
        const url = process.env.VUE_APP_APIURL + `/refacciones/${refaccion.id_refaccion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}