
import DxSelectBox from 'devextreme-vue/select-box';
import DxScheduler from 'devextreme-vue/scheduler';


import UserChannelService from '../services/user-channel-service';
import UserService from '../services/user-service';
import ActivityService from '../services/activity-service';

export default {
    components: {
        DxSelectBox,
        DxScheduler,
    },
    data() {
        return {
            dataSourceChannels: null,
            channel: null,
            views: ['day', 'week', 'workWeek', 'month'],
            currentDate: new Date(),
            dataSource: [],
            usuarioId: null,
            editing: { allowAdding: false },
        };
    },
    created() {
        this.userChannelService = new UserChannelService();
        this.userService = new UserService();
        this.activityService = new ActivityService();
    },
    mounted() {
        this.usuarioId = localStorage.getItem("user-data");
        this.userChannelService.getUsuarioCanales(this.usuarioId).then((data) => {
            this.dataSourceChannels = data;
        });
    },
    methods: {
        search() {
            this.usuarioId = localStorage.getItem("user-data");
            if (this.channel != null && this.usuarioId != null) {
                this.activityService.getActividadesCalendario(this.channel.id_canal, this.usuarioId).then(data => this.dataSource = data);
            }
        },
        valueChangedChannel() {
            this.search();
        },
    }
};
