

import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import CheckListDetail from './checklist-detail.vue';
import CheckListEvidence from './checklist-evidencie.vue';
export default {
    components: {
        DxTabPanel,
        DxItem,
        CheckListDetail,
        CheckListEvidence
    },
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            selectedIndex: 0,
            loop: true,
            animationEnabled: true,
            swipeEnabled: false,
        };
    },
};
