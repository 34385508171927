import axios from 'axios';

export default class GroupStageService {

	getGrupoEtapas() {	
        const url = process.env.VUE_APP_APIURL + `grupoetapas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoEtapa(grupoetapa) {
        const url = process.env.VUE_APP_APIURL + `/grupoetapas/${grupoetapa.id_grupo_etapa}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoEtapa(grupoetapa) {
        const url = process.env.VUE_APP_APIURL + `/grupoetapas/${grupoetapa.id_grupo_etapa}`;
		return axios.put(url,grupoetapa)
		.then(res=>res.data.data);
	}

	createGrupoEtapa(grupoetapa){
        const url = process.env.VUE_APP_APIURL + `/grupoetapas`;
		return axios.post(url,grupoetapa).then(res=>res.data.data);
	}

	deleteGrupoEtapa(grupoetapa){
        const url = process.env.VUE_APP_APIURL + `/grupoetapas/${grupoetapa.id_grupo_etapa}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}