
import {
  DxTreeList,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,  
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxSorting,
  DxButton
} from 'devextreme-vue/tree-list';
import DxDataGrid from 'devextreme-vue/data-grid';
import { DxItem, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxPopup, { DxToolbarItem }from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';

import AreaService from '../services/area-service';
import EquipmentCategoryService from '../services/equipment-category-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import ClientService from '../services/client-service';
import LocationService from '../services/location-service';
import LayoutService from '../services/layout-service';
import ConditionService from '../services/condition-service';
import CompanyService from '../services/company-service';
import BrandService from '../services/brand-service';
import ProductService from '../services/product-service';
import SpecialFieldService from '../services/special-field-service';
import TypeDataService from '../services/type-data-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import EquipmentCompanySpecialFieldService from '../services/equipment-company-specialfield-service';
import GroupUserService from '../services/group-user-service';
import UserService from '../services/user-service';
import MeasurementService from '../services/measurement-service';
import DataOriginService from '../services/data-origin-service';
import EquipmentCompanyMeasurementStatusService from '../services/equipment-company-measurement-status-service';
import WorkOrderEquipmentService from '../services/workorder-equipment-service';

export default {
  components: {
    DxTreeList,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxButton,
    DxToast,
    DxColumnChooser,
    DxSorting,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxDataGrid,
    DxTabbedItem,
    DxTab,
  },
  data() {
    return {
      popupSpecialField: null,
      itemCode: null,
      reference: null,
      description: null,
      serialNumber: null,
      activeNumber: null,
      model: null,
      alias: null,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      popupTitle: "Campos Especiales",
      popupVisible: false,
      popupVisibleResult: false,
      popupVisibleHistory: false,
      popupVisibleLocation: false,
      productos: null,
      dataSourceEquipmentCategories: null,
      dataSourceAreas: null,
      dataSourceEquipmentCompanies: null,
      dataSourceEquipments: null,
      dataSourceConditions: null,
      dataSourceLayouts: null,
      dataSourceClients: null,
      dataSourceLocations: null,
      dataSourceCompanies: null,
      dataSourceFatherEquipment: null,
      dataSourceEquipmentSpecialFields: null,
      dataSourceMeasurements: null,
      dataSourceEquipmentCompanyMeasurements: null,
      dataSourceHistory: null,
      dataSourceOrigins: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Equipo Físico',
        showTitle: true,
        height: 650,
        width: 1000,
      },
      mode: 'select',
    };
  },
  created() {
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.clientService = new ClientService();
    this.locationService = new LocationService();
    this.companyService = new CompanyService();
    this.layoutService = new LayoutService();
    this.conditionService = new ConditionService();
    this.areaService = new AreaService();
    this.dataOriginService = new DataOriginService();

    this.brandService = new BrandService();
    this.productService = new ProductService();
    this.groupUserService = new GroupUserService();
    this.userService = new UserService();
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.areaService = new AreaService();

    this.specialFieldService = new SpecialFieldService();
    this.typeDataService = new TypeDataService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.equipmentCompanySpecialFieldService = new EquipmentCompanySpecialFieldService();
    this.measurementService = new MeasurementService();
    this.equipmentCompanyMeasurementStatusService = new EquipmentCompanyMeasurementStatusService();
    this.workOrderEquipmentService = new WorkOrderEquipmentService();
  },
  mounted() {
    this.dataOriginService.getOrigenDatos().then(data => this.dataSourceOrigins=data);
    this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
    this.areaService.getAreas().then(data => this.dataSourceAreas=data);
    this.groupUserService.getGrupoUsuarios().then(data => this.dataSourceUserGroups=data);
    this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipments=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.layoutService.getLayouts().then(data => this.dataSourceLayouts=data);
    this.conditionService.getCondiciones().then(data => this.dataSourceConditions=data);  
    this.equipmentCompanyService.getEquipoEmpresasPadres().then(data => this.dataSourceFatherEquipment=data);
    this.equipmentCompanyService.getEquipoEmpresasConsultas().then(data => this.dataSourceEquipmentCompanies=data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);

    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers=data);
    this.productService.getProductos().then(data => this.dataSourceProducts=data);
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);

    this.typeDataService.getTipoDatos().then((data) => {
      this.dataSourceDataTypes=data;
      this.unitOfMeasurementService.getUnidadMedidas().then((data) => {
        this.dataSourceUnitOfMeasurements=data;
        this.specialFieldService.getCampos().then(data => this.dataSourceSpecialFields=data);
      });
    });
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editEquipmentCompany() {
      // TODO
		},
    closeClick() {
      this.popupVisible = false;
    },
    closeResultClick() {
      this.popupVisibleResult = false;
    },
    closeHistoryClick() {
      this.popupVisibleHistory = false;
    },
    closeLocationClick() {
      this.popupVisibleLocation = false;
    },
    popupHistory(e) {
      this.showPopupHistory(e.row.data.equipo, {...e.row.data}, e.row.data.id_equipo_empresa);
      this.workOrderEquipmentService.getHistorialTareasPorEquipo(e.row.data.id_equipo_empresa).then(data => this.dataSourceHistory=data);
    },
    popupLocation(e) {
      this.showPopupLocation(e.row.data.equipo, {...e.row.data}, e.row.data.id_equipo_empresa);
    },
    popupResult(e) {
      this.showPopupMeasurement(e.row.data.equipo, {...e.row.data}, e.row.data.id_equipo_empresa);
      this.equipmentCompanyMeasurementStatusService.getEquipoEmpresaMedicionEstatusPorEquipoEmpresa(e.row.data.id_equipo_empresa).then(data => this.dataSourceEquipmentCompanyMeasurements=data);
    },
    popupEquipmentSpecialField(e) {
      this.showPopup(e.row.data.equipo, {...e.row.data}, e.row.data.id_equipo_empresa);
      this.equipmentCompanySpecialFieldService.getCamposPorEquipoEmpresa(e.row.data.id_equipo_empresa).then(data => this.dataSourceEquipmentSpecialFields=data);
    },
    showPopupHistory(popupSpecialField, data, equipmentCompanyId) {
      this.loading = false;
      this.popupTitle = "Historial de Mantenimiento";
      this.equipmentCompanyId = equipmentCompanyId;
      this.formData = data;
      this.itemCode = data.codigo;
      this.description = data.nombre;
      this.brand = data.marca;
      this.model = data.modelo;
      this.activeNumber = data.numero_activo;
      this.serialNumber = data.serie;
      this.alias = data.alias;
      this.reference = data.referencia;
      this.popupSpecialField = popupSpecialField;
      this.popupVisibleHistory = true;
    },
    showPopupMeasurement(popupSpecialField, data, equipmentCompanyId) {
      this.loading = false;
      this.popupTitle = "Resultado de Mediciones/Acciones";
      this.equipmentCompanyId = equipmentCompanyId;
      this.formData = data;
      this.itemCode = data.codigo;
      this.description = data.nombre;
      this.brand = data.marca;
      this.model = data.modelo;
      this.activeNumber = data.numero_activo;
      this.serialNumber = data.serie;
      this.alias = data.alias;
      this.reference = data.referencia;
      this.popupSpecialField = popupSpecialField;
      this.popupVisibleResult = true;
    },
    showPopup(popupSpecialField, data, equipmentCompanyId) {
      this.loading = false;
      this.popupTitle = "Campos Especiales";
      this.equipmentCompanyId = equipmentCompanyId;
      this.formData = data;
      this.itemCode = data.codigo;
      this.description = data.nombre;
      this.brand = data.marca;
      this.model = data.modelo;
      this.activeNumber = data.numero_activo;
      this.serialNumber = data.serie;
      this.alias = data.alias;
      this.reference = data.referencia;
      this.popupSpecialField = popupSpecialField;
      this.popupVisible = true;
    },
    showPopupLocation(popupSpecialField, data, equipmentCompanyId) {
      this.loading = false;
      this.popupTitle = "Ubicación";
      this.equipmentCompanyId = equipmentCompanyId;
      this.formData = data;
      this.itemCode = data.codigo;
      this.description = data.nombre;
      this.brand = data.marca;
      this.model = data.modelo;
      this.activeNumber = data.numero_activo;
      this.serialNumber = data.serie;
      this.alias = data.alias;
      this.reference = data.referencia;
      this.popupSpecialField = popupSpecialField;
      this.popupVisibleLocation = true;
    },
  },  
};
