import axios from 'axios';

export default class CurrencyService {

	getMonedas() {		
        const url = process.env.VUE_APP_APIURL + `monedas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMoneda(moneda) {
        const url = process.env.VUE_APP_APIURL + `/monedas/${moneda.id_moneda}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMoneda(moneda){
        const url = process.env.VUE_APP_APIURL + `/monedas/${moneda.id_moneda}`;
		return axios.put(url,moneda).then(res=>res.data.data);
	}

	createMoneda(moneda){
        const url = process.env.VUE_APP_APIURL + `/monedas`;
		return axios.post(url,moneda).then(res=>res.data.data);
	}

	deleteMoneda(moneda){
        const url = process.env.VUE_APP_APIURL + `/monedas/${moneda.id_moneda}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}