import axios from 'axios';

export default class TypeLayoutService {

	getTipoLayouts() {			
        const url = process.env.VUE_APP_APIURL + `tipolayouts`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoLayout(tipolayout) {
        const url = process.env.VUE_APP_APIURL + `/tipolayouts/${tipolayout.id_tipo_layout}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoLayout(tipolayout){
        const url = process.env.VUE_APP_APIURL + `/tipolayouts/${tipolayout.id_tipo_layout}`;
		return axios.put(url,tipolayout).then(res=>res.data.data);
	}

	createTipoLayout(tipolayout){
        const url = process.env.VUE_APP_APIURL + `/tipolayouts`;
		return axios.post(url,tipolayout).then(res=>res.data.data);
	}

	deleteTipoLayout(tipolayout){
        const url = process.env.VUE_APP_APIURL + `/tipolayouts/${tipolayout.id_tipo_layout}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}