import axios from "axios";

export default class StatusBugReportService {
  getEstatusReporteFallas() {
    const url = process.env.VUE_APP_APIURL + `estatusreportefallas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusReporteFalla(estatusreportefalla) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusreportefallas/${estatusreportefalla.id_estatus_reporte_falla}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusReporteFalla(estatusreportefalla) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusreportefallas/${estatusreportefalla.id_estatus_reporte_falla}`;
    return axios
      .put(url, estatusreportefalla)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEstatusReporteFalla(estatusreportefalla) {
    const url = process.env.VUE_APP_APIURL + `/estatusreportefallas`;
    return axios
      .post(url, estatusreportefalla)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEstatusReporteFalla(estatusreportefalla) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusreportefallas/${estatusreportefalla.id_estatus_reporte_falla}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
