import axios from "axios";

export default class StatusWorkOrderEquipmentService {
  getEstatusOrdenTrabajoEquipos() {
    const url = process.env.VUE_APP_APIURL + `estatusordentrabajoequipos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusOrdenTrabajoEquipo(estatusordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusordentrabajoequipos/${estatusordentrabajoequipo.id_estatus_orden_trabajo_equipo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusOrdenTrabajoEquipo(estatusordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusordentrabajoequipos/${estatusordentrabajoequipo.id_estatus_orden_trabajo_equipo}`;
    return axios
      .put(url, estatusordentrabajoequipo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEstatusOrdenTrabajoEquipo(estatusordentrabajoequipo) {
    const url = process.env.VUE_APP_APIURL + `/estatusordentrabajoequipos`;
    return axios
      .post(url, estatusordentrabajoequipo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEstatusOrdenTrabajoEquipo(estatusordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusordentrabajoequipos/${estatusordentrabajoequipo.id_estatus_orden_trabajo_equipo}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
