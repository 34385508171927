
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxButton
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import ProductService from '../services/product-service';
import BrandService from '../services/brand-service';
import GroupProductService from '../services/group-product-service';
import DxFileUploader from 'devextreme-vue/file-uploader';
// import DxButton from 'devextreme-vue/button'

const fileUploaderRef = "fu";
const imgRef = "img"

export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxFileUploader ,
    DxButton,
    DxColumnChooser,
    DxSelectBox,
  },
  data() {
    return {
      headers:{Authorization: 'Bearer ' + localStorage.getItem('user-token')},
      productos: null,      
      dataSourceProducts: null,
      dataSourceBrands: null,
      dataSourceGroupProducts: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Componente',
        showTitle: true,
        width: 900,
      },
      mode: "select",
      //backendURL: "https://apiclix.apicontrol.website/api/v1/",
      //backendURL: "https://shn.apiclix.com/api/v1/",
      backendURL: process.env.VUE_APP_APIURL,
      //imageURL: "https://api.apicontrol.website/v1/storage/fotos/",
      //imageURL: "https://shn.apiclix.com/api/v1/storage/fotos/",
      imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
      imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
      retryButtonVisible: false,
      fileUploaderRef,
      imgRef,
      dataGridRefName: 'dataGridProducts',
      dataSourceGroupProductFilters: [],
      groupProductFilter: null,
    };
  },
  created() {
    this.productService = new ProductService();
    this.brandService = new BrandService();
    this.groupProductService = new GroupProductService();    
  },
  mounted() {            
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
    this.groupProductService.getGrupoProductos().then((data) => {
      this.dataSourceGroupProducts=data;
      this.dataSourceGroupProductFilters.push('(Todos)');
      for(var i in data) {
        this.dataSourceGroupProductFilters.push(data[i].nombre);
      }
      this.groupProductFilter = this.dataSourceGroupProductFilters[0];
    });
    this.productService.getWebProductos().then(data => this.dataSourceProducts=data);
  },
  computed: {
    fileUploader: function() {
      return this.$refs[fileUploaderRef].instance;
    },
    imageElement: function() {
      return this.$refs[imgRef];
    }
  },
  methods: {
    onSaved() {
      if(this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if(this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onClick() {
      // The retry UI/API is not implemented. Use a private API as shown at T611719.
      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
    },
    onValueChanged(e) {
      let reader = new FileReader();
      reader.onload = (args) => {
        this.imageElement.setAttribute('src', args.target.result);
      }
      reader.readAsDataURL(e.value[0]); // convert to base64 string
    },
    onUploaded(e, cellInfo) {
      //console.log(e);
      let fileObject = JSON.parse(e.request.responseText);
      //console.log(fileObject.data.image_name);
      //console.log(cellInfo);
      //cellInfo.setValue("archivos/fotos/" + e.request.responseText);
      cellInfo.setValue(fileObject.data.image_name);
      this.retryButtonVisible = false;
    },
    onUploadError(e) {
      let xhttp = e.request;
      if(xhttp.status === 400){
        e.message = e.error.responseText;
      }
      if(xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
      this.retryButtonVisible = true;
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editProduct(e) {
      this.productService.updateProducto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.productService.getWebProductos().then(data => this.dataSourceProducts=data);
      });
		},
    deleteProduct(e) {
			this.productService.deleteProducto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.productService.getWebProductos().then(data => this.dataSourceProducts=data);
      });
		},
    createProduct(e) {
			this.productService.createProducto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.productService.getWebProductos().then(data => this.dataSourceProducts=data);
      });
		},
    
    onInitNewRow(e) {
      e.data.serializable = true;
      e.data.activo = true;
      // 'https://api.apicontrol.website/v1/storage/fotos/AcxvWVp6WyVWKXHvpF3JBnlPFYkMtQ6p3bsoarqJ.png';
      //e.data.id_equipo_padre = null;      
      //this.equipmentService.getProductoPadres().then(data => this.dataSourceFatherItems=data);
    },

    onValueChangedFilter({ value }) {
      const dataGridProducts = this.$refs[this.dataGridRefName].instance;
      if (value === '(Todos)') {
        dataGridProducts.clearFilter();
      } else {
        dataGridProducts.filter(['grupo', '=', value]);
      }
    },
    viewimage(e) {
      var modal = document.getElementById("modalimage");
      var modalImg = document.getElementById("seemodalimage");
      modal.style.display = "block";
      modalImg.src = this.imageURL + e.row.data.imagen;
    },
    closeModal() {
      var modal = document.getElementById("modalimage");
      modal.style.display = "none";
    },
  },  
};
