import axios from 'axios';

export default class ConfigFrequencyDetailService {

	getConfigFrecuenciaDetalles() {			
        const url = process.env.VUE_APP_APIURL + `configfrecuenciadetalles`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigFrecuenciaDetalle(configfrecuenciadetalle) {
        const url = process.env.VUE_APP_APIURL + `/configfrecuenciadetalles/${configfrecuenciadetalle.id_frecuencia_config_detalle}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigFrecuenciaDetalle(configfrecuenciadetalle){
        const url = process.env.VUE_APP_APIURL + `/configfrecuenciadetalles/${configfrecuenciadetalle.id_frecuencia_config_detalle}`;
		return axios.put(url,configfrecuenciadetalle).then(res=>res.data.data);
	}

	createConfigFrecuenciaDetalle(configfrecuenciadetalle){
        const url = process.env.VUE_APP_APIURL + `/configfrecuenciadetalles`;
		return axios.post(url,configfrecuenciadetalle).then(res=>res.data.data);
	}

	deleteConfigFrecuenciaDetalle(configfrecuenciadetalle){
        const url = process.env.VUE_APP_APIURL + `/configfrecuenciadetalles/${configfrecuenciadetalle.id_frecuencia_config_detalle}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}