
const downloadFile2 = (path, filename) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;
  anchor.setAttribute('target', '_blank');

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
};

import {
  DxTreeList,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup,
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxSorting,
  DxButton
} from 'devextreme-vue/tree-list';
import DxDataGrid from 'devextreme-vue/data-grid';
import { DxItem, DxSimpleItem, DxLabel, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxFileUploader from 'devextreme-vue/file-uploader';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import { confirm } from "devextreme/ui/dialog";

import AreaService from '../services/area-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import EquipmentService from '../services/equipment-service';
import ClientService from '../services/client-service';
import LocationService from '../services/location-service';
import LayoutService from '../services/layout-service';
import ConditionService from '../services/condition-service';
import CompanyService from '../services/company-service';
import BrandService from '../services/brand-service';
import ProductService from '../services/product-service';
import SpecialFieldService from '../services/special-field-service';
import TypeDataService from '../services/type-data-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import EquipmentCompanySpecialFieldService from '../services/equipment-company-specialfield-service';
import GroupUserService from '../services/group-user-service';
import UserService from '../services/user-service';
import EquipmentCategoryService from '../services/equipment-category-service';

const fileUploaderRef = "fu";
const imgRef = "img"

export default {
  components: {
    DxTreeList,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxButton,
    DxToast,
    DxColumnChooser,
    DxSorting,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxDataGrid,
    DxSimpleItem,
    DxLabel,
    DxTabbedItem,
    DxTab,
    DxFileUploader,
  },
  data() {
    return {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
      UrlDocumentos: process.env.VUE_APP_APIURL + "storage/documentos/",
      popupSpecialField: null,
      itemCode: null,
      reference: null,
      description: null,
      serialNumber: null,
      activeNumber: null,
      model: null,
      alias: null,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      popupTitle: "Campos Especiales",
      popupTitleCopy: "Copiar Equipo",
      popupTitleReplace: "Sustituir Componente",
      popupVisible: false,
      popupVisibleCopy: false,
      popupVisibleReplace: false,
      productos: null,
      dataSourceAreas: null,
      dataSourceEquipmentCompanies: null,
      dataSourceConditions: null,
      dataSourceLayouts: null,
      dataSourceEquipments: null,
      dataSourceClients: null,
      dataSourceLocations: null,
      dataSourceCompanies: null,
      dataSourceFatherEquipment: null,
      dataSourceEquipmentSpecialFields: null,
      dataSourceUserGroups: null,
      specialFieldService: null,
      typeDataService: null,
      unitOfMeasurementService: null,
      dataSourceBrands: null,
      dataSourceEquipmentCategories: null,
      dataSourceUsers: null,
      dataSourceProducts: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Equipo',
        showTitle: true,
        height: 650,
        width: 1050,
      },
      mode: 'select',
      equipmentCompanyId: null,
      formData: {},
      backendURL: process.env.VUE_APP_APIURL,
      imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
      imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
      retryButtonVisible: false,
      fileUploaderRef,
      imgRef,
    };
  },
  created() {
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.equipmentService = new EquipmentService();
    this.clientService = new ClientService();
    this.locationService = new LocationService();
    this.companyService = new CompanyService();
    this.layoutService = new LayoutService();
    this.conditionService = new ConditionService();
    this.brandService = new BrandService();
    this.productService = new ProductService();
    this.groupUserService = new GroupUserService();
    this.userService = new UserService();
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.areaService = new AreaService();

    this.specialFieldService = new SpecialFieldService();
    this.typeDataService = new TypeDataService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.equipmentCompanySpecialFieldService = new EquipmentCompanySpecialFieldService();
  },
  mounted() {
    this.areaService.getAreas().then(data => this.dataSourceAreas = data);
    this.groupUserService.getGrupoUsuarios().then(data => this.dataSourceUserGroups = data);
    this.equipmentService.getEquipoPadres().then(data => this.dataSourceEquipments = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations = data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.layoutService.getLayouts().then(data => this.dataSourceLayouts = data);
    this.conditionService.getCondiciones().then(data => this.dataSourceConditions = data);
    this.equipmentCompanyService.getEquipoEmpresasPadres().then(data => this.dataSourceFatherEquipment = data);
    this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipmentCompanies = data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands = data);

    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
    this.productService.getProductos().then(data => this.dataSourceProducts = data);
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories = data);

    this.typeDataService.getTipoDatos().then((data) => {
      this.dataSourceDataTypes = data;
      this.unitOfMeasurementService.getUnidadMedidas().then((data) => {
        this.dataSourceUnitOfMeasurements = data;
        this.specialFieldService.getCampos().then(data => this.dataSourceSpecialFields = data);
      });
    });
  },
  computed: {
    fileUploader: function () {
      return this.$refs[fileUploaderRef].instance;
    },
    imageElement: function () {
      return this.$refs[imgRef];
    }
  },
  methods: {
    downloadMyFile(e) {
      const liga = this.UrlDocumentos + e.row.data.imagen_layout;
      downloadFile2(liga, e.row.data.imagen_layout);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editEquipmentCompany(e) {
      this.equipmentCompanyService.updateEquipoEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipmentCompanies = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al editar el equipo';
        this.isVisible = true;
        this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipmentCompanies = data);
      });
    },
    deleteEquipmentCompany(e) {
      this.equipmentCompanyService.deleteEquipoEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipmentCompanies = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el equipo';
        this.isVisible = true;
        this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipmentCompanies = data);
      });
    },
    createEquipmentCompany(e) {
      this.equipmentCompanyService.createEquipoEmpresa(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentCompanyService.getEquipoEmpresasPadres().then(data => this.dataSourceFatherEquipment = data);
        this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipmentCompanies = data);
        //this.dataSourceEquipmentCompanies.reload();
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el equipo';
        this.isVisible = true;
        this.equipmentCompanyService.getEquipoEmpresasPadres().then(data => this.dataSourceFatherEquipment = data);
        this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipmentCompanies = data);
      });
    },
    onInitNewRow(e) {
      e.data.activo = true;
      e.data.gps = false;
      e.data.disponible = true;
      e.data.fuera_servicio = false;
      e.data.edificio = false;
      //e.data.id_equipo_padre = null;      
      //this.equipmentService.getEquipoEmpresaPadres().then(data => this.dataSourceFatherItems=data);
    },
    viewimage(e) {
      var modal = document.getElementById("modalimage");
      var modalImg = document.getElementById("seemodalimage");
      modal.style.display = "block";
      modalImg.src = this.imageURL + e.row.data.imagen;
    },
    closeModal() {
      var modal = document.getElementById("modalimage");
      modal.style.display = "none";
    },
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onClick() {
      // The retry UI/API is not implemented. Use a private API as shown at T611719.
      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
    },
    onValueChanged(e) {
      let reader = new FileReader();
      reader.onload = (args) => {
        this.imageElement.setAttribute('src', args.target.result);
      }
      reader.readAsDataURL(e.value[0]); // convert to base64 string
    },
    onUploaded(e, cellInfo) {
      let fileObject = JSON.parse(e.request.responseText);
      cellInfo.setValue(fileObject.data.image_thumbnail);
      this.retryButtonVisible = false;
    },
    onUploadedDocument(e, cellInfo) {
      let fileObject = JSON.parse(e.request.responseText);
      cellInfo.setValue(fileObject.data.image_name);
      this.retryButtonVisible = false;
    },
    onUploadError(e) {
      let xhttp = e.request;
      if (xhttp.status === 400) {
        e.message = e.error.responseText;
      }
      if (xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
      this.retryButtonVisible = true;
    },
    showPopup(popupSpecialField, data, equipmentCompanyId) {
      this.loading = false;
      this.equipmentCompanyId = equipmentCompanyId;
      this.formData = data;
      this.itemCode = data.codigo;
      this.description = data.nombre;
      this.brand = data.marca;
      this.model = data.modelo;
      this.activeNumber = data.numero_activo;
      this.serialNumber = data.serie;
      this.alias = data.alias;
      this.reference = data.referencia;
      this.popupSpecialField = popupSpecialField;
      this.popupVisible = true;
    },
    showPopupCopy(popupCopy, data, equipmentCompanyId) {
      this.loading = false;
      this.equipmentCompanyId = equipmentCompanyId;
      this.formData = data;
      this.popupCopy = popupCopy;
      this.popupVisibleCopy = true;
    },
    showPopupReplace(popupReplace, data, equipmentCompanyId) {
      this.loading = false;
      this.equipmentCompanyId = equipmentCompanyId;
      this.formData = data;
      this.popupReplace = popupReplace;
      this.popupVisibleReplace = true;
    },
    popupEquipmentSpecialField(e) {
      this.showPopup(e.row.data.equipo, { ...e.row.data }, e.row.data.id_equipo_empresa);
      this.equipmentCompanySpecialFieldService.getCamposPorEquipoEmpresa(e.row.data.id_equipo_empresa).then(data => this.dataSourceEquipmentSpecialFields = data);
    },
    popupEquipmentPart(e) {
      confirm("¿Desea crear el componente " + e.row.data.equipo + "?", "Crear Componente de Equipo").then((dialogResult) => {
        if (dialogResult) {
          this.productService.createProductoDesdeEquipo({ "id_equipo_empresa": e.row.data.id_equipo_empresa }).then(() => {
            this.type = 'success';
            this.message = 'Se ha creado el componente del equipo';
            this.isVisible = true;
          }).catch(() => {
            this.type = 'error';
            this.message = 'Falla al crear el componente del equipo';
            this.isVisible = true;
          });
        }
      });
    },
    popupEquipmentPartReplace(e) {
      this.showPopupReplace(e.row.data.equipo, { ...e.row.data }, e.row.data.id_equipo_empresa);
      //this.equipmentCompanySpecialFieldService.getCamposPorEquipoEmpresa(e.row.data.id_equipo_empresa).then(data => this.dataSourceEquipmentSpecialFields=data);
    },
    popupEquipmentCopy(e) {
      confirm("¿Desea copiar el equipo " + e.row.data.nombre + "?", "Copiar Equipo").then((dialogResult) => {
        if (dialogResult) {
          this.equipmentCompanyService.createEquipoEmpresaCopia({ "id_equipo_empresa": e.row.data.id_equipo_empresa }).then(() => {
            this.type = 'success';
            this.message = 'Se ha copiado el equipo';
            this.isVisible = true;
            this.equipmentCompanyService.getEquipoEmpresasCatalogos().then(data => this.dataSourceEquipmentCompanies = data);
          }).catch(() => {
            this.type = 'error';
            this.message = 'Falla al copiar el equipo';
            this.isVisible = true;
          });
        }
      });
      //this.showPopupCopy(e.row.data.equipo, {...e.row.data}, e.row.data.id_equipo_empresa);
      //this.equipmentCompanySpecialFieldService.getCamposPorEquipoEmpresa(e.row.data.id_equipo_empresa).then(data => this.dataSourceEquipmentSpecialFields=data);
    },
    closeClick() {
      this.popupVisible = false;
    },
    closeClickCopy() {
      this.popupVisibleCopy = false;
    },
    closeClickReplace() {
      this.popupVisibleReplace = false;
    },
    saveClickCopy() {
      this.popupVisibleCopy = false;
    },
    saveClickReplace() {
      this.popupVisibleReplace = false;
    },
    editEquipmentSpecialField(e) {
      this.equipmentCompanySpecialFieldService.updateEquipoEmpresaCampo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCompanySpecialFieldService.reload();
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.equipmentCompanySpecialFieldService.reload();
      });
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'id_campo' || e.dataField === 'id_tipo_dato' || e.dataField === 'id_unidad_medida')) {
        e.editorOptions.disabled = true;
      }
    },
    onEditorPreparingEquipments(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'id_equipo')) {
        if (!e.row.isEditing)
          e.editorOptions.disabled = true;
      }
      if (e.parentType === 'dataRow' && (e.dataField === 'id_equipo_empresa_padre')) {
        if (e.row.isEditing)
          e.editorOptions.disabled = true;
      }
      if (e.parentType === 'dataRow' && (e.dataField === 'grupo')) {
        e.editorOptions.disabled = true;
      }
    },
    setStateValue(rowData, value) {
      if (value != null) {
        this.dataSourceEquipments.forEach(element => {
          if (element.id_equipo == value) {
            rowData.codigo = element.clave;
            rowData.nombre = element.nombre;
            rowData.vida_util_ano = element.vida_util_ano;
            rowData.modelo = element.modelo;
            rowData.id_marca = element.id_marca;
            rowData.numero_parte = element.numero_parte;
            rowData.numero_activo = element.numero_activo;
            rowData.id_equipo_empresa_padre = null;
            rowData.id_equipo = value;
            rowData.serie = element.serie;
            rowData.referencia = element.referencia;
            rowData.codigo_qr = element.codigo_qr;
            rowData.id_equipo_categoria = element.id_equipo_categoria;
            rowData.id_producto = element.id_producto;
          }
        });
      } else {
        rowData.id_equipo = null;
      }
    },
  },
};
