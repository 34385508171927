
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import WorkOrderOriginService from '../services/workorder-origin-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceWorkOrderOrigin: null,
      origenOrdenTrabajo: {},
      events: [],
      workOrderOriginService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.workOrderOriginService = new WorkOrderOriginService();
  },
  mounted() {    
    this.workOrderOriginService.getOrigenOrdenTrabajos().then(data => this.dataSourceWorkOrderOrigin=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editWorkOrderOrigin(e) {
			this.workOrderOriginService.updateOrigenOrdenTrabajo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteWorkOrderOrigin(e) {
			this.workOrderOriginService.deleteOrigenOrdenTrabajo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createWorkOrderOrigin(e) {
			this.workOrderOriginService.createOrigenOrdenTrabajo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
