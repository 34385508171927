import axios from "axios";

export default class ItemLocationService {
  getArticulosUbicaciones() {
    const url = process.env.VUE_APP_APIURL + `articuloubicaciones`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getArticuloUbicacion(articuloubicacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloubicaciones/${articuloubicacion.id_articulo_ubicacion}`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getArticuloUbicacionDisponibles(id_ubicacion, id_almacen) {
    const url = process.env.VUE_APP_APIURL + `/articuloubicaciones/disponibles`;
    return axios
      .get(url, {
        params: { id_ubicacion: id_ubicacion, id_almacen: id_almacen },
      })
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getArticuloUbicacionAsignados(id_ubicacion, id_almacen) {
    const url = process.env.VUE_APP_APIURL + `/articuloubicaciones/asignados`;
    return axios
      .get(url, {
        params: { id_ubicacion: id_ubicacion, id_almacen: id_almacen },
      })
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  updateArticulosUbicaciones(articuloubicacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloubicaciones/${articuloubicacion.id_articulo_ubicacion}`;
    return axios
      .put(url, articuloubicacion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createArticulosUbicaciones(articuloubicacion) {
    const url = process.env.VUE_APP_APIURL + `/articuloubicaciones`;
    return axios
      .post(url, articuloubicacion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteArticulosUbicaciones(articuloubicacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloubicaciones/${articuloubicacion.id_articulo_ubicacion}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
