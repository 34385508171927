import axios from 'axios';

export default class KitItemService {

	getArticuloAsignadosPorKit(kitId) {		
        const url = process.env.VUE_APP_APIURL + `kitarticulos/asignados/${kitId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getArticuloDisponiblesPorKit(kitId) {		
        const url = process.env.VUE_APP_APIURL + `kitarticulos/disponibles/${kitId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getKitArticulo(kitarticulo) {
        const url = process.env.VUE_APP_APIURL + `/kitarticulos/${kitarticulo.id_kit_articulo}`;
		return axios.get(url).then(res=>res.data.data);
	}

    updateKitArticulo(kitarticulo){
        const url = process.env.VUE_APP_APIURL + `kitarticulos/${kitarticulo.id_kit_articulo}`;
		return axios.put(url,kitarticulo).then(res=>res.data.data);
	}

	createKitArticulo(kitarticulo){
        const url = process.env.VUE_APP_APIURL + `/kitarticulos`;
		return axios.post(url,kitarticulo).then(res=>res.data.data);		
	}

	deleteKitArticulo(kitarticulo){
        const url = process.env.VUE_APP_APIURL + `/kitarticulos/${kitarticulo.id_kit_articulo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}