
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceUnitOfMeasurement: null,
      unidadDeMedida: {},
      events: [],
      unitOfMeasurementService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.unitOfMeasurementService = new UnitOfMeasurementService();
  },
  mounted() {    
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitOfMeasurement=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editUnitOfMeasurement(e) {
			this.unitOfMeasurementService.updateUnidadMedida(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteUnitOfMeasurement(e) {
			this.unitOfMeasurementService.deleteUnidadMedida(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createUnitOfMeasurement(e) {
			this.unitOfMeasurementService.createUnidadMedida(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
