

//     <div class="master-detail-caption">{{ detailInfo }}</div>

import { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxForm, DxItem, DxLookup, DxColumnChooser } from 'devextreme-vue/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ChecklistDetailService from '../services/checklist-detail-service';
import ChecklistService from '../services/checklist-service';
import MeasurementService from '../services/measurement-service';
import ControlService from '../services/control-service';
import BrandService from '../services/brand-service';
import EquipmentCompanyService from '../services/equipment-company-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxForm, DxItem, DxLookup, DxColumnChooser },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { nombre, id_lista_verificacion } = this.templateData.data;
    return {
      measurements: null,
      dataSourceChecklistDetails: null,
      dataSourceChecklists: null,
      dataSourceBrands: null,
      dataSourceEquipments: null,
      dataSourceMeasurements: null,
      dataSourceControls: null,
      detailInfo: `${nombre}`,
      listaVerificacionId: id_lista_verificacion,
      popupOptions: {
        title: 'Mediciones',
        showTitle: true,
        width: 600,
      },
      mode: 'select',
    };
  },
  created() {
    this.checklistDetailService = new ChecklistDetailService();
    this.brandService = new BrandService();
    this.measurementService = new MeasurementService();
    this.controlService = new ControlService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.checklistService = new ChecklistService();
    this.controlService = new ControlService();
  },
  mounted() {
    this.brandService.getMarcas().then(data => this.datatSourceBrands=data);
    this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
    this.controlService.getControles().then(data => this.dataSourceControls=data);
    this.checklistDetailService.getListaVerificacionDetallesPorListaVerificacion(this.listaVerificacionId).then(data => this.dataSourceChecklistDetails = data);
    /*
    this.checklistDetailService.getListaVerificacionDetallesPorListaVerificacion(this.listaVerificacionId).then((data) => {
        this.measurements=data;         
        this.dataSourceChecklistDetails = this.getMeasurements(this.templateData.key); 
    });
    */
  },
  methods: {
    editChecklistDetail(e) {
        this.checklistDetailService.updateListaVerificacionDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    deleteChecklistDetail(e) {
		this.checklistDetailService.deleteListaVerificacionDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    createChecklistDetail(e) {
        e.data.id_lista_verificacion = this.templateData.key;
		this.checklistDetailService.createListaVerificacionDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    refreshData() {
      this.checklistDetailService.getListaVerificacionDetallesPorListaVerificacion(this.listaVerificacionId).then(data => this.dataSourceChecklistDetails = data);
      /*
      this.checklistDetailService.getListaVerificacionDetallesPorListaVerificacion(this.listaVerificacionId).then((data) => {
        this.measurements=data;
        this.dataSourceChecklistDetails = this.getMeasurements(this.templateData.key);
        // this.dataSourceChecklistDetails = data;
      });
      */
    },           
    getMeasurements(key) {      
      return new DataSource({
        store: new ArrayStore({
          data: this.measurements,
          key: 'id_lista_verificacion_detalle',
        }),
        filter: ['id_lista_verificacion', '=', key],
      });
    },
  },
};
