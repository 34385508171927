
import {
  DxTreeList,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxEditing,
  DxSorting,
  DxLookup,
} from 'devextreme-vue/tree-list';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import RoleService from '../services/role-service';
import RolePermitService from '../services/role-permit-service';

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting,
    DxLookup,
},
  data() {
    return {
      expanded: false,
      expandedRowKeys: [],
      roles: null,      
      dataSourceRoles: null,
      dataSourcePermitsAvailable: null,
      dataSourcePermitsAssigned: null,
      dataSourceApplications: [{"id_aplicacion": 1, "nombre": "WEB"},{"id_aplicacion": 2, "nombre": "APP"}],
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
    };
  },
  created() {
    this.roleService = new RoleService();
    this.rolePermitService = new RolePermitService();
  },
  mounted() {
    this.roleService.getRoles().then(data => this.dataSourceRoles=data);  
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_permiso);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addRole() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.rolePermitService.createRolPermiso({"id_rol": this.roles.id_rol, "id_permiso": this.selectedRowsData[this.i].id_permiso}).then(() => {           
            
            if (this.i==this.selectedRowsData.length) {
              
              this.rolePermitService.getPermisosDisponiblesPorRol(this.roles.id_rol).then(data => this.dataSourcePermitsAvailable=data);
              this.rolePermitService.getPermisosAsignadosPorRol(this.roles.id_rol).then(data => this.dataSourcePermitsAssigned=data);
            }
            
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    valueChangedItem(e) {
        this.rolePermitService.getPermisosDisponiblesPorRol(e.value.id_rol).then(data => this.dataSourcePermitsAvailable=data);
        this.rolePermitService.getPermisosAsignadosPorRol(e.value.id_rol).then(data => this.dataSourcePermitsAssigned=data);           
    },
    deletePermits(e) {
      this.rolePermitService.deleteRolPermiso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.rolePermitService.getPermisosDisponiblesPorRol(this.roles.id_rol).then(data => this.dataSourcePermitsAvailable=data);
        this.rolePermitService.getPermisosAsignadosPorRol(this.roles.id_rol).then(data => this.dataSourcePermitsAssigned=data); 
      });
    },
  },  
};
