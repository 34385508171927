import axios from 'axios';

export default class AddressCompanyService {

	getDireccionesPorEmpresa(empresa) {
		const url =
		process.env.VUE_APP_APIURL + `empresadirecciones/empresas/${empresa}`;
		return axios.get(url).then((res) => res.data.data);
	}

	getDirecciones() {		
        const url = process.env.VUE_APP_APIURL + `empresadirecciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getDireccion(empresadireccion) {
        const url = process.env.VUE_APP_APIURL + `empresadirecciones/${empresadireccion.id_empresa_direccion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateDireccion(empresadireccion){
        const url = process.env.VUE_APP_APIURL + `empresadirecciones/${empresadireccion.id_empresa_direccion}`;
		return axios.put(url,empresadireccion).then(res=>res.data.data);
	}

	createDireccion(empresadireccion){
        const url = process.env.VUE_APP_APIURL + `empresadirecciones`;
		return axios.post(url,empresadireccion).then(res=>res.data.data);
	}

	deleteDireccion(empresadireccion){
        const url = process.env.VUE_APP_APIURL + `empresadirecciones/${empresadireccion.id_empresa_direccion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}