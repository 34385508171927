import axios from 'axios';

export default class ConfigAlertRuleService {

	getConfigAlertaReglas() {		
        const url = process.env.VUE_APP_APIURL + `configalertareglas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigAlertaRegla(configalertaregla) {
        const url = process.env.VUE_APP_APIURL + `/configalertareglas/${configalertaregla.id_config_alerta_regla}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigAlertaRegla(configalertaregla){
        const url = process.env.VUE_APP_APIURL + `/configalertareglas/${configalertaregla.id_config_alerta_regla}`;
		return axios.put(url,configalertaregla).then(res=>res.data.data);
	}

	createConfigAlertaRegla(configalertaregla){
        const url = process.env.VUE_APP_APIURL + `/configalertareglas`;
		return axios.post(url,configalertaregla).then(res=>res.data.data);
	}

	deleteConfigAlertaRegla(configalertaregla){
        const url = process.env.VUE_APP_APIURL + `/configalertareglas/${configalertaregla.id_config_alerta_regla}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}