
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxValidationRule,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import ConfigNotificationService from '../services/config-notification-service';
import TypeNotificationService from '../services/type-notification-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxValidationRule,
    DxSelection,
  },
  data() {
    return {
      notificaciones: null,
      dataSourceNotifications: null,
      dataSourceNotificationTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Configuración de Notificación',
        showTitle: true,
        width: 600,
      },
    };
  },
  created() {
    this.configNotificationService = new ConfigNotificationService();
    this.typeNotificationService = new TypeNotificationService();
  },
  mounted() {
    this.configNotificationService.getConfigNotificaciones().then(data => this.dataSourceNotifications=data);
    this.typeNotificationService.getTipoNotificaciones().then(data => this.dataSourceNotificationTypes=data);
  },
  methods: {
    editNotification(e) {
      this.configNotificationService.updateConfigNotificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.configNotificationService.getConfigNotificaciones().then(data => this.dataSourceNotifications=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar la configuración';
        this.isVisible = true;
        this.configNotificationService.getConfigNotificaciones().then(data => this.dataSourceNotifications=data);
      });
		},
  },
};
