import axios from 'axios';

export default class LogicOperatorService {

	getComparadorLogicos() {		
        const url = process.env.VUE_APP_APIURL + `comparadorlogicos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getComparadorLogico(comparadorlogico) {
        const url = process.env.VUE_APP_APIURL + `/comparadorlogicos/${comparadorlogico.id_comparador_logico}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateComparadorLogico(comparadorlogico){
        const url = process.env.VUE_APP_APIURL + `/comparadorlogicos/${comparadorlogico.id_comparador_logico}`;
		return axios.put(url,comparadorlogico).then(res=>res.data.data);
	}

	createComparadorLogico(comparadorlogico){
        const url = process.env.VUE_APP_APIURL + `/comparadorlogicos`;
		return axios.post(url,comparadorlogico).then(res=>res.data.data);
	}

	deleteComparadorLogico(comparadorlogico){
        const url = process.env.VUE_APP_APIURL + `/comparadorlogicos/${comparadorlogico.id_comparador_logico}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}