
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
// import 'devextreme-vue/text-area';
import WarrantyService from '../services/warranty-service';
import SupplierService from '../services/supplier-service';
import WarrantyTypeService from '../services/type-warranty-service';
import CompanyService from '../services/company-service';


export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
  },
  data() {
    return {
      garantia: null,      
      dataSourceWarranties: null,
      dataSourceClients: null,
      dataSourceSuppliers: null,
      dataSourceUnitMeasurements: null,
      dataSourceCompanies: null,
      dataSourceWarrantyTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Garantía',
        showTitle: true,
        width: 600,
        height: 400,
      },
      mode: 'select',
    };
  },
  created() {
    this.warrantyService = new WarrantyService();
    this.companyService = new CompanyService();
    this.supplierService = new SupplierService();
    this.warrantyTypeService = new WarrantyTypeService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.warrantyService.getGarantias().then(data => this.dataSourceWarranties=data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers=data);
    this.warrantyTypeService.getTipoGarantias().then(data => this.dataSourceWarrantyTypes=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editWarranty(e) {
      this.warrantyService.updateGarantia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.warrantyService.getGarantias().then(data => this.dataSourceWarranties=data);
      }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al actualizar el registro';
          this.isVisible = true;
          this.warrantyService.getGarantias().then(data => this.dataSourceWarranties=data);
      });
		},
    deleteWarranty(e) {
			this.warrantyService.deleteGarantia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.warrantyService.getGarantias().then(data => this.dataSourceWarranties=data);
      }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al eliminar el registro';
          this.isVisible = true;
          this.warrantyService.getGarantias().then(data => this.dataSourceWarranties=data);
      });
		},
    createWarranty(e) {
			this.warrantyService.createGarantia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.warrantyService.getGarantias().then(data => this.dataSourceWarranties=data);
      }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.warrantyService.getGarantias().then(data => this.dataSourceWarranties=data);
      });
		},    
    onInitNewRow(e) {
      e.data.activo = true;
    },
  },  
};
