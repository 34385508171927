
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';

import PhysicalInventoryService from '../services/physical-inventory-service';
import CompanyService from '../services/company-service';
import WarehouseService from '../services/warehouse-service';
import TypePhysicalInventoryService from '../services/type-physical-inventory-service';
import StatusPhysicalInventoryService from '../services/status-physical-inventory-service';
import DetailTemplate from './physical-inventory-item.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxColumnChooser
  },
  data() {
    return {
      inventariofisicos: null,
      dataSourcePhysicalInventory: null,
      dataSourcePhysicalInventoryTypes: null,
      dataSourceCompanies: null,
      dataSourceWarehouse: null,
      dataSourcePhysicalInventoryStatus: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Inventario Físico',
        showTitle: true,
        width: 800,
        height: 450,
      },
      mode: 'select',
    };
  },
  created() {
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.typePhysicalInventoryService = new TypePhysicalInventoryService();
    this.statusPhysicalInventoryService = new StatusPhysicalInventoryService();
    this.physicalInventoryService = new PhysicalInventoryService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouse=data);
    this.typePhysicalInventoryService.getTipoInventarioFisicos().then(data => this.dataSourcePhysicalInventoryTypes=data);
    this.statusPhysicalInventoryService.getEstatusInventarioFisicos().then(data => this.dataSourcePhysicalInventoryStatus=data);
    this.physicalInventoryService.getInventarioFisicos().then(data => this.dataSourcePhysicalInventory=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
  },
};
