import axios from 'axios';

export default class MaintenancePolicyEquipmentService {

    getEquiposDisponiblesPorPoliza(polizaId) {		
        const url = process.env.VUE_APP_APIURL + `polizamantenimientoequipos/disponibles/${polizaId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getEquiposAsignadosPorPoliza(polizaId) {		
        const url = process.env.VUE_APP_APIURL + `polizamantenimientoequipos/asignados/${polizaId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getPolizaMantenimientoEquipos() {			
        const url = process.env.VUE_APP_APIURL + `polizamantenimientoequipos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getPolizaMantenimientoEquipo(polizamantenimientoequipo) {
        const url = process.env.VUE_APP_APIURL + `/polizamantenimientoequipos/${polizamantenimientoequipo.id_poliza_mantenimiento_equipo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createPolizaMantenimientoEquipo(polizamantenimientoequipo){
        const url = process.env.VUE_APP_APIURL + `/polizamantenimientoequipos`;
		return axios.post(url,polizamantenimientoequipo).then(res=>res.data.data);
	}

	deletePolizaMantenimientoEquipo(polizamantenimientoequipo){
        const url = process.env.VUE_APP_APIURL + `/polizamantenimientoequipos/${polizamantenimientoequipo.id_poliza_mantenimiento_equipo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}

