import axios from 'axios';

export default class ConfigPlanMaintenanceTaskService {

	getTareasDisponiblesPorPlanEquipo(configplanmttoequipo) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareas/disponibles/${configplanmttoequipo}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getTareasAsignadasPorPlanEquipo(configplanmttoequipo) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareas/asignadas/${configplanmttoequipo}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getConfigPlanMttoTareas() {			
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigPlanMttoTarea(configplanmttoequipotarea) {
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareas/${configplanmttoequipotarea.id_config_plan_mtto_tarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createConfigPlanMttoTarea(configplanmttoequipotarea){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareas`;
		return axios.post(url,configplanmttoequipotarea).then(res=>res.data.data);
	}

	updateConfigPlanMttoTarea(configplanmttoequipotarea){
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareas/${configplanmttoequipotarea.id_config_plan_mtto_tarea}`;
		return axios.put(url,configplanmttoequipotarea).then(res=>res.data.data);
	}

	deleteConfigPlanMttoTarea(configplanmttoequipotarea){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareas/${configplanmttoequipotarea.id_config_plan_mtto_tarea}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}

