import axios from 'axios';

export default class DocumentService {

	getDocumentosCombo() {
		const url = process.env.VUE_APP_APIURL + `documentos/combos`;
		return axios.get(url).then((res) => res.data.data);
	}

	getDocumentos() {			
        const url = process.env.VUE_APP_APIURL + `documentos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getDocumento(documento) {
        const url = process.env.VUE_APP_APIURL + `/documentos/${documento.id_documento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateDocumento(documento){
        const url = process.env.VUE_APP_APIURL + `/documentos/${documento.id_documento}`;
		return axios.put(url,documento).then(res=>res.data.data);
	}

	createDocumento(documento){
        const url = process.env.VUE_APP_APIURL + `/documentos`;
		return axios.post(url,documento).then(res=>res.data.data);
	}

	deleteDocumento(documento){
        const url = process.env.VUE_APP_APIURL + `/documentos/${documento.id_documento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}