
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule,
    DxToolbar,
    DxMasterDetail,
    DxButton,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import {
    DxTabbedItem,
    DxTab,
} from 'devextreme-vue/form';

import CompanyService from '../services/company-service';
import ConfigSurveyService from '../services/config-survey-service';
import SurveySetupStatusServiece from '../services/surveysetupstatus-serviece';
import SurveyTriggerServiece from '../services/surveytrigger-service';
import SurveyFrequencyServiece from '../services/surveyfrequency-service';
import SurveyService from '../services/survey-service';

import ConfigSurveyDetail from './config-survey-detail.vue';

const gridRefName = "configsurvey";

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxFilterRow,
        DxScrolling,
        DxPager,
        DxToast,
        DxColumnChooser,
        DxSelection,
        DxSorting,
        DxForm,
        DxItem,
        DxValidationRule,
        DxToolbar,
        DxTabbedItem,
        DxTab,
        DxMasterDetail,
        ConfigSurveyDetail,
        DxButton,
    },

    data() {
        return {
            dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
            dataSourceCompanies: null,
            dataSourceConfigSurvey: [],
            dataSourceSurveySetupStatus: null,
            dataSourceSurveyTrigger: null,
            dataSourceSurveyFrequency: null,
            popupOptions: {
                title: 'Config Encuesta',
                showTitle: true,
                width: 950,
                height: 640,
            },
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            mode: 'select',
            isVisible: false,
            message: '',
            type: 'info',
            visiblefechainicio: false,
            autoExpandAll: true,
            gridRefName,
            formAttributesConfigSurvey: {
                id: 'elementId',
            },
            editorOptions: {
                disabled: false
            },
            timesanswer: [
                { id: 'U', text: 'Una Vez' },
                { id: 'M', text: 'Multiples Veces' },
            ],
            selectionTimesAnswer: null,
        }
    },
    created() {
        this.companyService = new CompanyService();
        this.configSurveyService = new ConfigSurveyService();
        this.surveySetupStatusServiece = new SurveySetupStatusServiece();
        this.surveyTriggerServiece = new SurveyTriggerServiece();
        this.surveyFrequencyServiece = new SurveyFrequencyServiece();
        this.surveyService = new SurveyService();
    },
    computed: {

    },
    mounted() {
        this.companyService.getEmpresasCombo().then(data => this.dataSourceCompanies = data);
        this.refreshDataGrid();
        this.surveySetupStatusServiece.getSurveySetupStatus().then(data => this.dataSourceSurveySetupStatus = data);
        this.surveyTriggerServiece.getSurveyTrigger().then(data => this.dataSourceSurveyTrigger = data);
        this.surveyFrequencyServiece.getSurveyFrequency().then(data => this.dataSourceSurveyFrequency = data);
    },
    methods: {
        onValueChangedInicio(e) {
            console.log(e);
        },
        refreshDataGrid() {
            this.dataSourceConfigSurvey = [];
            this.configSurveyService.getConfigSurvey().then(data => {
                this.dataSourceConfigSurvey = data;
                for (const keydata in data) {
                    for (const key in this.timesanswer) {
                        if (this.timesanswer[key].id == data[keydata].veces) {
                            data[keydata].veces = this.timesanswer[key];
                        }
                    }
                }
            });
        },
        onInitNewRow(e) {
            this.selectionTimesAnswer = this.timesanswer[0].id;
            e.data.activo = true;
            e.data.envio_correo = false;
            e.data.indefinido = false;
        },
        createConfigSurvey(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.veces = e.data.veces.id;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyService.createConfigSurvey(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha registrado correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        editWorkConfigSurvey(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.veces = e.data.veces.id;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyService.updateConfigSurvey(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha actualizado correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        deleteWorkConfigSurvey(e) {
            const id_usuario = localStorage.getItem('user-data');
            e.data.veces = e.data.veces.id;
            e.data.id_usuario_reg = id_usuario;
            this.configSurveyService.deleteConfigSurvey(e.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha eliminado correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        isManual(e) {
            return e.row.data.id_disparador_encuesta != 1 ? true : false;
        },
        sendSurvey(e) {
            this.surveyService.SendSurvey(e.row.data).then(data => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha enviado correctamente';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
            });
        }
    },
}
