
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSorting,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import SafetyService from '../services/safety-service';
import ClientService from '../services/client-service';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSorting,
    DxSelection,
  },
  data() {
    return {
      clientes: null,
      dataSourceSafeties: null,
      dataSourceClients: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Reglas de Seguridad del Mantenimiento',
        showTitle: true,
        width: 800,
      },
      mode: 'select',
    };
  },
  created() {
    this.safetyService = new SafetyService();
    this.clientService = new ClientService();    
  },
  mounted() {    
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.safetyService.getSeguridades().then(data => this.dataSourceSafeties=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editSafety(e) {
      this.safetyService.updateSeguridad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteSafety(e) {
			this.safetyService.deleteSeguridad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createSafety(e) {
			this.safetyService.createSeguridad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {      
      e.data.activo = true;      
    },
  },
};
