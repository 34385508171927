import axios from 'axios';

export default class GroupFindingService {

	getGrupoHallazgos() {	
        const url = process.env.VUE_APP_APIURL + `grupohallazgos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoHallazgo(grupohallazgo) {
        const url = process.env.VUE_APP_APIURL + `/grupohallazgos/${grupohallazgo.id_grupo_hallazgo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoHallazgo(grupohallazgo) {
        const url = process.env.VUE_APP_APIURL + `/grupohallazgos/${grupohallazgo.id_grupo_hallazgo}`;
		return axios.put(url,grupohallazgo)
		.then(res=>res.data.data);
	}

	createGrupoHallazgo(grupohallazgo){
        const url = process.env.VUE_APP_APIURL + `/grupohallazgos`;
		return axios.post(url,grupohallazgo).then(res=>res.data.data);
	}

	deleteGrupoHallazgo(grupohallazgo){
        const url = process.env.VUE_APP_APIURL + `/grupohallazgos/${grupohallazgo.id_grupo_hallazgo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}