
// :data="masterDetailData.data"
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import UserCompany from './user-company.vue';
import UserSkill from './user-skill.vue';
import UserGroupAlert from './user-group-alert.vue';
import UserChannel from './user-channel.vue';

export default {
  components: {
    DxTabPanel, DxItem, UserCompany, UserSkill, UserGroupAlert, UserChannel
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
