import axios from 'axios';

export default class WarehouseService {

	getAlmacenes() {		
        const url = process.env.VUE_APP_APIURL + `almacenes`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getAlmacenesPorEmpresa(empresa) {		
        const url = process.env.VUE_APP_APIURL + `almacenes/empresas/${empresa}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getAlmacen(almacen) {
        const url = process.env.VUE_APP_APIURL + `/almacenes/${almacen.id_almacen}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateAlmacen(almacen){
        const url = process.env.VUE_APP_APIURL + `/almacenes/${almacen.id_almacen}`;
		return axios.put(url,almacen).then(res=>res.data.data);
	}

	createAlmacen(almacen){
        const url = process.env.VUE_APP_APIURL + `/almacenes`;
		return axios.post(url,almacen).then(res=>res.data.data);
	}

	deleteAlmacen(almacen){
        const url = process.env.VUE_APP_APIURL + `/almacenes/${almacen.id_almacen}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}