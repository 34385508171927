import axios from 'axios';

export default class PriceListService {

	async downloadFile() {			
        const url = process.env.VUE_APP_APIURL + `listaprecios/descargas`;
		return await axios.get(url,{ responseType: 'blob' }).then(res=>res.data);
	}

    getListaPreciosPorUsuario(usuario) {			
        const url = process.env.VUE_APP_APIURL + `listaprecios/consultas`;
		return axios.post(url,usuario).then(res=>res.data.data);
	}

	getListaPrecios() {			
        const url = process.env.VUE_APP_APIURL + `listaprecios`;
		return axios.get(url).then(res=>res.data.data);
	}

    getListaPrecio(listaprecio) {
        const url = process.env.VUE_APP_APIURL + `/listaprecios/${listaprecio.id_lista_precio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateListaPrecio(listaprecio){
        const url = process.env.VUE_APP_APIURL + `/listaprecios/${listaprecio.id_lista_precio}`;
		return axios.put(url,listaprecio).then(res=>res.data.data);
	}

	createListaPrecio(listaprecio){
        const url = process.env.VUE_APP_APIURL + `/listaprecios`;
		return axios.post(url,listaprecio).then(res=>res.data.data);
	}

	deleteListaPrecio(listaprecio){
        const url = process.env.VUE_APP_APIURL + `/listaprecios/${listaprecio.id_lista_precio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}