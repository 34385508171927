import axios from 'axios';

export default class FestiveDayService {

	getDiasFestivos() {		
        const url = process.env.VUE_APP_APIURL + `diasfestivos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getDiaFestivo(diafestivo) {
        const url = process.env.VUE_APP_APIURL + `/diasfestivos/${diafestivo.id_dia_festivo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateDiaFestivo(diafestivo){
        const url = process.env.VUE_APP_APIURL + `/diasfestivos/${diafestivo.id_dia_festivo}`;
		return axios.put(url,diafestivo).then(res=>res.data.data);
	}

	createDiaFestivo(diafestivo){
        const url = process.env.VUE_APP_APIURL + `/diasfestivos`;
		return axios.post(url,diafestivo).then(res=>res.data.data);
	}

	deleteDiaFestivo(diafestivo){
        const url = process.env.VUE_APP_APIURL + `/diasfestivos/${diafestivo.id_dia_festivo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}