import axios from 'axios';

export default class TypeGeofenceEventService {

	getTipoGeocercaEventos() {			
        const url = process.env.VUE_APP_APIURL + `tipogeocercaeventos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoGeocercaEvento(tipogeocercaevento) {
        const url = process.env.VUE_APP_APIURL + `/tipogeocercaeventos/${tipogeocercaevento.id_tipo_geocerca_evento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoGeocercaEvento(tipogeocercaevento){
        const url = process.env.VUE_APP_APIURL + `/tipogeocercaeventos/${tipogeocercaevento.id_tipo_geocerca_evento}`;
		return axios.put(url,tipogeocercaevento).then(res=>res.data.data);
	}

	createTipoGeocercaEvento(tipogeocercaevento){
        const url = process.env.VUE_APP_APIURL + `/tipogeocercaeventos`;
		return axios.post(url,tipogeocercaevento).then(res=>res.data.data);
	}

	deleteTipoGeocercaEvento(tipogeocercaevento){
        const url = process.env.VUE_APP_APIURL + `/tipogeocercaeventos/${tipogeocercaevento.id_tipo_geocerca_evento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}