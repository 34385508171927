import axios from 'axios';

export default class RequisitionItemService {
  createRequisicionArticulo(requisicion) {
    const url = process.env.VUE_APP_APIURL + `/requisicionesarticulos`;
    return axios.post(url, requisicion).then((res) => res.data.data);
  }

  getRequisicionArticuloID(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/requisicionesarticulos/requisicion`;
    return axios
      .get(url, { params: requisicion })
      .then((res) => res.data.data);
  }

  updateRequisicionArticulo(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/requisicionesarticulos/${requisicion.id_requisicion_articulo}`;
    return axios.put(url, requisicion).then((res) => res.data.data);
  }

  deleteRequisicionArticulo(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/requisicionesarticulos/${requisicion.id_requisicion_articulo}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getRequisicionArticuloPorUsuario(id_requisicion) {
    const url =
      process.env.VUE_APP_APIURL + `requisicionesarticulos/requisicion`;
    return axios
      .get(url, { params: { id_requisicion: id_requisicion } })
      .then((res) => res.data.data);
  }

}