import axios from 'axios';

export default class IndicatorService {

	// Indicador de usuarios activos
	getUsuarioActivos(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/usuarios/activos`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Indicador de ordenes de trabajo activas
	getOrdenTrabajoActivas(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/activas`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Indicador de ordenes de trabajo realizadas
	getOrdenTrabajoRealizadas(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/realizadas`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Indicador de listas de verificaciones realizadas
	getListaVerificaciones(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/listaverificaciones`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Indicador de reporte de fallas
	getReporteFallas(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/reportefallas`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Indicador de alertas
	getAlertas(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/alertas`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

    // Gráfica de Pie de estatus de orden de trabajo
	getEstatusOrdenTrabajoPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/graficaestatus`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de estatus de orden de trabajo
	getOrigenOrdenTrabajoPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/graficaorigenes`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de tipos de mantenimiento de orden de trabajo
	getTipoMantenimientoOrdenTrabajoPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/graficatipomantenimientos`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de grupo de usuarios de orden de trabajo
	getUsuarioGrupoOrdenTrabajoPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/graficausuariogrupos`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de aguja % de OTs cerradas a tiempo
    getPorcentajeOTCerradasATiempoPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/cerradasatiempo`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de aguja % de cumplimiento de OTs
    getCumplimientoOTPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/cumplimientosot`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de aguja % de mantenimiento planeado PMP
	getPorcentajeMantenimientoPlaneadoOTPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/mantenimientoplaneados`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de motivos de cancelacion de OT
	getMotivoCancelacionEquipoOrdenTrabajoPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajoequipos/motivocancelaciones`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de motivos de cancelacion de tareas de OT
	getCancelacionTareaOrdenTrabajoPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajotareas/motivocancelaciones`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de tareas realizadas vs no realizadas
	getTareasRealizadasVsPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/tareas/realizadasvsnorealizadas`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de mediciones realizadas vs no realizadas
	getMedicionesRealizadasVsPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/mediciones/realizadasvsnorealizadas`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de tipos de falla
	getTiposFallaPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/reportefallas/tipos`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de estatus de reporte de falla
	getEstatusFallaPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/reportefallas/estatus`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de alertas
	getSeveridadAlertasPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/alertas/severidades`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de pólizas de mantenimiento
	getPolizasMantenimientoPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/polizamantenimientos`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

	// Gráfica de Pie de equipos en geocerca
	getEquiposGeocercaPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/equipos/geocercas`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}
    
    getHorasTrabajadasOTPorEmpresa(empresa,fechaInicio,fechaFin,usuario) {		
        const url = process.env.VUE_APP_APIURL + `indicadores/ordentrabajos/horastrabajadas`;
		return axios.get(url,{ params: { id_empresa: empresa, id_usuario: usuario, fecha_inicio: fechaInicio, fecha_fin: fechaFin} }).then(res=>res.data.data);
	}

}