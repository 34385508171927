

import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import User from './user.vue';
import Kpi from './user-kpi.vue';
import UserNotifications from './user-notifications.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    User,
    Kpi,
    UserNotifications
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
