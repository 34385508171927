
import DxList from 'devextreme-vue/list';
import { DxToast } from 'devextreme-vue/toast';
import CompanyService from '../services/company-service';
import UserCompanyService from '../services/user-company-service';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  props: {
    templateData: {
      type: Object,
      default: () => { },
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedItemKeys: [],
      loadingVisible: false,
      dataSourceCompanies: null,
      dataSourceUserCompanies: null,
      isVisible: false,
      message: '',
      type: 'info',
      position: { of: '#loadPanelUserCompany' },
    };
  },
  components: {
    DxList,
    DxToast,
    DxLoadPanel
  },
  created() {
    this.companyService = new CompanyService();
    this.userCompanyService = new UserCompanyService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.refresh();
    this.loadingVisible = false;
  },
  methods: {
    onSelectionChanged(e) {
      if (e.addedItems.length == 1) {
        this.loadingVisible = true;
        this.userCompanyService.createUsuarioEmpresa({ "id_usuario": this.userId, "id_empresa": e.addedItems[0].id_empresa }).then((data) => {
          if (typeof (data.error) !== "undefined") {
            this.type = 'error';
            this.message = data.error;
            this.isVisible = true;
          }
          this.refresh();
          this.loadingVisible = false;
        });
      } else if (e.removedItems.length == 1) {
        this.loadingVisible = true;
        this.userCompanyService.deleteUsuarioEmpresa(this.userId, e.removedItems[0].id_empresa).then((data) => {
          if (typeof (data.error) !== "undefined") {
            this.type = 'error';
            this.message = data.error;
            this.isVisible = true;
          }
          this.refresh();
          this.loadingVisible = false;
        }).catch(() => {
          this.type = 'error';
          this.message = 'No se puede eliminar la compañía para el usuario';
          this.isVisible = true;
          this.refresh();
          this.loadingVisible = false;
        });
      }
    },
    refresh() {
      this.userCompanyService.getUsuarioEmpresas(this.userId).then((data) => {
        this.dataSourceUserCompanies = data;
        var empresas = "";
        for (var i = 0; i < data.length; i++) {
          if (empresas.length == 0)
            empresas = data[i].id_empresa;
          else
            empresas = empresas + "," + data[i].id_empresa;
        }
        if (String(empresas).length > 0) {
          this.selectedItemKeys = String(empresas).split(',').map(function (item) {
            return parseInt(item, 10);
          });
        } else {
          this.selectedItemKeys = [];
        }
      });
    }
  },

};
