
  import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxEditing,
    DxSorting,
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';
  import DxSelectBox from 'devextreme-vue/select-box';
  import Button from 'devextreme-vue/button';

  import ItemService from '../services/item-service';
  import SupplierService from '../services/supplier-service';
  import ItemSupplierService from '../services/item-supplier-service';
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxSelection,
      DxToast,
      DxSelectBox,
      Button,
      DxEditing,
      DxSorting,
    },
    data() {
      return {
        items: null,      
        dataSourceItems: null,
        dataSourceSupplierAvailable: null,
        dataSourceSupplierAssigned: null,
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        mode: 'select',
        selectedRowsData: [],
        i: 0,
      };
    },
    created() {
      this.itemService = new ItemService();
      this.supplierService = new SupplierService();
      this.itemSupplierService = new ItemSupplierService();
    },
    mounted() {
      this.itemService.getArticulosCombo().then(data => this.dataSourceItems=data);  
    },
    computed: {
      selectedRowKeys() {
        return this.selectedRowsData.map((data) => data.id_proveedor);
      },
    },
    methods: {
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      addItem() {
        for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
            this.itemSupplierService.createArticuloProveedor({"id_articulo": this.items.id_articulo, "id_proveedor": this.selectedRowsData[this.i].id_proveedor}).then(() => {           
              
              if (this.i==this.selectedRowsData.length) {
                
                this.itemSupplierService.getProveedorDisponiblesPorArticulo(this.items.id_articulo).then(data => this.dataSourceSupplierAvailable=data);
                this.itemSupplierService.getProveedorAsignadosPorArticulo(this.items.id_articulo).then(data => this.dataSourceSupplierAssigned=data);
              }
              
            });
        }
      },
      onSelectionChanged({ selectedRowsData }) {
        this.selectedRowsData = selectedRowsData;
      },
      valueChangedItem(e) {
          this.itemSupplierService.getProveedorDisponiblesPorArticulo(e.value.id_articulo).then(data => this.dataSourceSupplierAvailable=data);
          this.itemSupplierService.getProveedorAsignadosPorArticulo(e.value.id_articulo).then(data => this.dataSourceSupplierAssigned=data);           
      },
      deleteSupplier(e) {
        this.itemSupplierService.deleteArticuloProveedor(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.itemSupplierService.getProveedorDisponiblesPorArticulo(this.items.id_articulo).then(data => this.dataSourceSupplierAvailable=data);
          this.itemSupplierService.getProveedorAsignadosPorArticulo(this.items.id_articulo).then(data => this.dataSourceSupplierAssigned=data); 
        });
      },
      editSupplier(e) {
        this.itemSupplierService.updateArticuloProveedor(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.itemSupplierService.getProveedorAsignadosPorArticulo(this.items.id_articulo).then(data => this.dataSourceSupplierAssigned=data); 
        });
      },
      onEditorPreparing(e) {
        if (e.parentType === 'dataRow' && e.dataField === 'proveedor') {
          e.editorOptions.disabled = true;
        }
      },
    },  
  };
  