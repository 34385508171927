import axios from 'axios';

export default class GroupActionService {

	getGrupoAcciones() {	
        const url = process.env.VUE_APP_APIURL + `grupoacciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoAccion(grupoaccion) {
        const url = process.env.VUE_APP_APIURL + `/grupoacciones/${grupoaccion.id_grupo_accion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoAccion(grupoaccion) {
        const url = process.env.VUE_APP_APIURL + `/grupoacciones/${grupoaccion.id_grupo_accion}`;
		return axios.put(url,grupoaccion)
		.then(res=>res.data.data);
	}

	createGrupoAccion(grupoaccion){
        const url = process.env.VUE_APP_APIURL + `/grupoacciones`;
		return axios.post(url,grupoaccion).then(res=>res.data.data);
	}

	deleteGrupoAccion(grupoaccion){
        const url = process.env.VUE_APP_APIURL + `/grupoacciones/${grupoaccion.id_grupo_accion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}