

//     <div class="master-detail-caption">{{ detailInfo }}</div>

import { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxForm, DxItem } from 'devextreme-vue/data-grid';
import DxFileUploader from 'devextreme-vue/file-uploader';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ProcedureStepService from '../services/procedure-step-service';

const fileUploaderRef = "fu";
export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxForm, DxItem, DxFileUploader },
  props: {
    templateData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    const { nombre } = this.templateData.data;
    return {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
      backendURL: process.env.VUE_APP_APIURL,
      imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
      imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
      fileUploaderRef,
      steps: null,
      dataSourceProcedureSteps: null,
      detailInfo: `${nombre}`,
    };
  },
  created() {
    this.procedureStepService = new ProcedureStepService();
  },
  mounted() {
    this.procedureStepService.getProcedimientoPasos().then((data) => {
      this.steps = data;
      this.dataSourceProcedureSteps = this.getSteps(this.templateData.key);
    });
  },
  computed: {
    fileUploader: function () {
      return this.$refs[fileUploaderRef].instance;
    },
  },
  methods: {
    onClick() {
      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
      this.loadingVisible = true;
    },
    onValueChanged(e) {
      document.getElementById("outputimg").style.display = "block";
      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById('outputimg');
        output.src = reader.result;
      };
      reader.readAsDataURL(e.value[0]);
    },
    onUploaded(e, cellInfo) {
      this.loadingVisible = true;
      let fileObject = JSON.parse(e.request.responseText);
      cellInfo.setValue(fileObject.data.image_url);
      this.loadingVisible = false;
      this.retryButtonVisible = false;
    },
    editProcedureStep(e) {
      this.procedureStepService.updateProcedimientoPaso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
    },
    deleteProcedureStep(e) {
      this.procedureStepService.deleteProcedimientoPaso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
    },
    createProcedureStep(e) {
      e.data.id_procedimiento = this.templateData.key;
      this.procedureStepService.createProcedimientoPaso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
    },
    refreshData() {
      this.procedureStepService.getProcedimientoPasos().then((data) => {
        this.steps = data;
        this.dataSourceProcedureSteps = this.getSteps(this.templateData.key);
      });
    },
    getSteps(key) {
      return new DataSource({
        store: new ArrayStore({
          data: this.steps,
          key: 'id_procedimiento_paso',
        }),
        filter: ['id_procedimiento', '=', key],
      });
    },
  },
};
