import axios from 'axios';

export default class TypeServiceRequestService {

	getTipoSolicitudServicios() {			
        const url = process.env.VUE_APP_APIURL + `tiposolicitudservicios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoSolicitudServicio(tiposolicitudservicio) {
        const url = process.env.VUE_APP_APIURL + `/tiposolicitudservicios/${tiposolicitudservicio.id_tipo_solicitud_servicio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoSolicitudServicio(tiposolicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/tiposolicitudservicios/${tiposolicitudservicio.id_tipo_solicitud_servicio}`;
		return axios.put(url,tiposolicitudservicio).then(res=>res.data.data);
	}

	createTipoSolicitudServicio(tiposolicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/tiposolicitudservicios`;
		return axios.post(url,tiposolicitudservicio).then(res=>res.data.data);
	}

	deleteTipoSolicitudServicio(tiposolicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/tiposolicitudservicios/${tiposolicitudservicio.id_tipo_solicitud_servicio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}