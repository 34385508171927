
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxMasterDetail,
} from 'devextreme-vue/data-grid';
import { services } from './../data/data.js';

import MasterDetail from './master-detail.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxMasterDetail,
    MasterDetail,
  },
  data() {
    return {
      suppliersData: services,
    };
  },
};
