import axios from "axios";

export default class StatusActivityService {
  getEstatusActividades() {
    const url = process.env.VUE_APP_APIURL + `estatusactividades`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusActividadesKanban() {
    const url = process.env.VUE_APP_APIURL + `estatusactividades/kanban`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusActividad(estatusactividad) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividades/${estatusactividad.id_estatus_actividad}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusActividad(estatusactividad) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividades/${estatusactividad.id_estatus_actividad}`;
    return axios
      .put(url, estatusactividad)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEstatusActividad(estatusactividad) {
    const url = process.env.VUE_APP_APIURL + `/estatusactividades`;
    return axios
      .post(url, estatusactividad)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEstatusActividad(estatusactividad) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividades/${estatusactividad.id_estatus_actividad}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
