
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxSorting,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import auth from '../auth';

import GeneralConfigService from '../services/general-config-service';
import GroupConfigService from '../services/group-config-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import SupplierService from '../services/supplier-service';
import TypeDataService from '../services/type-data-service';

export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxColumnChooser
  },
  data() {
    return {
      usuarioId: null,
      mediciones: null,      
      dataSourceGeneralConfigs: null,
      dataSourceGroupConfigs: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceSuppliers: null,
      dataSourceDataTypes: null,      
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Configuración General',
        showTitle: true,
        width: 800,
      },
      autoExpandAll: true,
      mode: 'select',
    };
  },
  created() {
    this.generalConfigService = new GeneralConfigService();
    this.groupConfigService = new GroupConfigService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.supplierService = new SupplierService();
    this.typeDataService = new TypeDataService();
  },
  mounted() {    
    this.generalConfigService.getConfigGenerales().then(data => this.dataSourceGeneralConfigs=data);
    this.groupConfigService.getGrupoConfiguraciones().then(data => this.dataSourceGroupConfigs=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers=data);
    this.typeDataService.getTipoDatos().then(data => this.dataSourceDataTypes=data);    
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editGeneralConfig(e) {
      auth.getUser().then((data) => {
        this.usuarioId = data.data.id_usuario;
        this.generalConfigService.updateConfigGeneral({...e.data, "id_usuario": this.usuarioId}).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
        });
      });
		},
    deleteGeneralConfig(e) {
			this.generalConfigService.deleteConfigGeneral(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createGeneralConfig(e) {
      auth.getUser().then((data) => {
        this.usuarioId = data.data.id_usuario;
        this.generalConfigService.createConfigGeneral({...e.data, "id_usuario": this.usuarioId}).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        });
      });
		},
    /*
    onEditorPreparing(e) {
      if (e.dataField === 'id_equipo_padre' && e.row.data.id_equipo === 0) {
        e.editorOptions.disabled = true;
        e.editorOptions.value = null;
      }
    },
    */
    onInitNewRow(e) {      
      e.data.activo = true;
      //e.data.id_equipo_padre = null;      
      //this.taskService.getEquipoPadres().then(data => this.dataSourceFatherTasks=data);
    },
  },  
};
