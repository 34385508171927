import axios from 'axios';

export default class PriorityService {

	getPrioridades() {			
        const url = process.env.VUE_APP_APIURL + `prioridades`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getCalendarioPrioridades() {			
        const url = process.env.VUE_APP_APIURL + `prioridades/calendarios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getPrioridad(prioridad) {
        const url = process.env.VUE_APP_APIURL + `/prioridades/${prioridad.id_prioridad}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updatePrioridad(prioridad){
        const url = process.env.VUE_APP_APIURL + `/prioridades/${prioridad.id_prioridad}`;
		return axios.put(url,prioridad).then(res=>res.data.data);
	}

	createPrioridad(prioridad){
        const url = process.env.VUE_APP_APIURL + `/prioridades`;
		return axios.post(url,prioridad).then(res=>res.data.data);
	}

	deletePrioridad(prioridad){
        const url = process.env.VUE_APP_APIURL + `/prioridades/${prioridad.id_prioridad}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}