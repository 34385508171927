

//     <div class="master-detail-caption">{{ detailInfo }}</div>

import { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxLookup, DxForm, DxItem, DxColumnChooser } from 'devextreme-vue/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import MeasurementService from '../services/measurement-service';
import EquipmentService from '../services/equipment-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import AlertVarService from '../services/alert-var-service';
import LogicOperatorService from '../services/logic-operator-service';
import SeverityService from '../services/severity-service';

import ConfigAlertRuleService from '../services/config-alert-rule-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxLookup, DxForm, DxItem, DxColumnChooser },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { nombre } = this.templateData.data;
    return {
      rules: null,
      dataSourceConfigAlertRules: null,
      dataSourceEquipments: null,
      dataSourcePhysicalEquipments: null,
      dataSourceMeasurements: null,
      dataSourceVariables: null,
      dataSourceLogicOperators: null,
      dataSourceAlertSeverity: null,
      detailInfo: `${nombre}`,
      type: 'info',
      popupOptions: {
        title: 'Disparador de Alertas',
        showTitle: true,
        width: 900,
      },      
      mode: 'select',
    };
  },
  created() {
    this.equipmentService = new EquipmentService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.alertVarService = new AlertVarService();
    this.logicOperatorService = new LogicOperatorService();
    this.severityService = new SeverityService();
    this.configAlertRuleService = new ConfigAlertRuleService();
    this.measurementService = new MeasurementService();      
  },
  mounted() {
    this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
    this.equipmentService.getEquipos().then(data => this.dataSourceEquipments=data);
    this.equipmentCompanyService.getEquipoEmpresasPadres().then(data => this.dataSourcePhysicalEquipments=data);
    this.alertVarService.getVariableAlertas().then(data => this.dataSourceVariables=data);
    this.logicOperatorService.getComparadorLogicos().then(data => this.dataSourceLogicOperators=data);
    this.severityService.getSeveridadAlertas().then(data => this.dataSourceAlertSeverity=data);
    this.configAlertRuleService.getConfigAlertaReglas().then((data) => {
        this.rules=data;         
        this.dataSourceConfigAlertRules = this.getRules(this.templateData.key); 
    });             
  },
  methods: {
    editConfigAlertRule(e) {
        this.configAlertRuleService.updateConfigAlertaRegla(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    deleteConfigAlertRule(e) {
		this.configAlertRuleService.deleteConfigAlertaRegla(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    createConfigAlertRule(e) {
        e.data.id_config_alerta = this.templateData.key;
		this.configAlertRuleService.createConfigAlertaRegla(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    refreshData() {
        this.configAlertRuleService.getConfigAlertaReglas().then((data) => {
        this.rules=data;         
        this.dataSourceConfigAlertRules = this.getRules(this.templateData.key); 
    });
    },           
    getRules(key) {      
      return new DataSource({
        store: new ArrayStore({
          data: this.rules,
          key: 'id_config_alerta_regla',
        }),
        filter: ['id_config_alerta', '=', key],
      });
    },
  },
};
