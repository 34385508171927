
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxEditing,
    DxSorting,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';

import UserService from '../services/user-service';
import NotificationSettingsService from '../services/notification-settings';
import UserNotificationService from '../services/user-notification';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxSelection,
        DxToast,
        DxSelectBox,
        Button,
        DxEditing,
        DxSorting,
    },
    data() {
        return {
            users: null,
            dataSourceUsers: null,
            dataSourceNotificationAvailable: null,
            dataSourceNotificationAssigned: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            mode: 'select',
            selectedRowsData: [],
            i: 0,
        };
    },
    created() {
        this.userService = new UserService();
        this.notificationSettingsService = new NotificationSettingsService();
        this.userNotificationService = new UserNotificationService();
    },
    mounted() {
        this.userService.getUsuarios().then(data => this.dataSourceUsers = data);
    },
    computed: {

    },
    methods: {
        onSelectionChanged({ selectedRowsData }) {
            this.selectedRowsData = selectedRowsData;
        },
        valueChangedUser(e) {
            this.notificationSettingsService.getConfigNotificacionesDisponibles(e.value.id_usuario).then(data => this.dataSourceNotificationAvailable = data);
            this.notificationSettingsService.getConfigNotificacionesAsignadas(e.value.id_usuario).then(data => this.dataSourceNotificationAssigned = data);
        },
        addNotificacion() {
            for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
                this.userNotificationService.createNotificacionUsuarios({ "id_usuario": this.users.id_usuario, "id_config_notificacion": this.selectedRowsData[this.i].id_config_notificacion }).then(() => {

                    if (this.i == this.selectedRowsData.length) {

                        this.notificationSettingsService.getConfigNotificacionesDisponibles(this.users.id_usuario).then(data => this.dataSourceNotificationAvailable = data);
                        this.notificationSettingsService.getConfigNotificacionesAsignadas(this.users.id_usuario).then(data => this.dataSourceNotificationAssigned = data);
                    }

                });
            }
        },
        deleteNotificacionUsuario(e) {
            this.userNotificationService.deleteNotificacionUsuarios(e.data).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.notificationSettingsService.getConfigNotificacionesDisponibles(this.users.id_usuario).then(data => this.dataSourceNotificationAvailable = data);
                this.notificationSettingsService.getConfigNotificacionesAsignadas(this.users.id_usuario).then(data => this.dataSourceNotificationAssigned = data);
            });
        },

    },
};
