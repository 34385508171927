
  // :data="masterDetailData.data"
  import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
  
  import WorkOrderTask from './workorder-equipment-task.vue';
  import WorkOrderEvidence from './workorder-equipment-evidence.vue';
  
  export default {
    components: {
      DxTabPanel, DxItem, WorkOrderTask, WorkOrderEvidence
    },
    props: {
      masterDetailData: {
        type: Object,
        default: () => ({}),
      },
    },
  };
  