import axios from 'axios';

export default class UnitOfMeasurementService {
  getUnidadMedidas() {
    const url = process.env.VUE_APP_APIURL + `unidadmedidas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getUnidadMedida(unidadmedida) {
    const url =
      process.env.VUE_APP_APIURL +
      `/unidadmedidas/${unidadmedida.id_unidad_medida}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateUnidadMedida(unidadmedida) {
    const url =
      process.env.VUE_APP_APIURL +
      `/unidadmedidas/${unidadmedida.id_unidad_medida}`;
    return axios.put(url, unidadmedida).then((res) => res.data.data);
  }

  createUnidadMedida(unidadmedida) {
    const url = process.env.VUE_APP_APIURL + `/unidadmedidas`;
    return axios.post(url, unidadmedida).then((res) => res.data.data);
  }

  deleteUnidadMedida(unidadmedida) {
    const url =
      process.env.VUE_APP_APIURL +
      `/unidadmedidas/${unidadmedida.id_unidad_medida}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getUnidadMedidasCombos() {
    const url = process.env.VUE_APP_APIURL + `/unidadmedidas/combos`;
    return axios.get(url).then((res) => res.data.data);
  }
}