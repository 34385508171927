import axios from "axios";

export default class StatusMaintenancePlanService {
  getCalendarioEstatusPlanMantenimientos() {
    const url =
      process.env.VUE_APP_APIURL + `estatusplanmantenimientos/calendarios`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusPlanMantenimientos() {
    const url = process.env.VUE_APP_APIURL + `estatusplanmantenimientos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusPlanMantenimiento(estatusplanmantenimiento) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusplanmantenimientos/${estatusplanmantenimiento.id_estatus_plan_mantenimiento}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusPlanMantenimiento(estatusplanmantenimiento) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusplanmantenimientos/${estatusplanmantenimiento.id_estatus_plan_mantenimiento}`;
    return axios
      .put(url, estatusplanmantenimiento)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEstatusPlanMantenimiento(estatusplanmantenimiento) {
    const url = process.env.VUE_APP_APIURL + `/estatusplanmantenimientos`;
    return axios
      .post(url, estatusplanmantenimiento)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEstatusPlanMantenimiento(estatusplanmantenimiento) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusplanmantenimientos/${estatusplanmantenimiento.id_estatus_plan_mantenimiento}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
