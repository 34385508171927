import axios from "axios";

export default class UserNotificationService {
  getNotificacionUsuarios() {
    const url = process.env.VUE_APP_APIURL + `notificacionusuarios`;
    return axios.get(url).then((res) => res.data.data);
  }

  getNotificacionUsuariosPorId(notificacionusuario) {
    const url =
      process.env.VUE_APP_APIURL +
      `notificacionusuarios/${notificacionusuario}`;
    return axios.get(url).then((res) => res.data.data);
  }

  createNotificacionUsuarios(notificacionusuario) {
    const url = process.env.VUE_APP_APIURL + `notificacionusuarios`;
    return axios.post(url, notificacionusuario).then((res) => res.data.data);
  }

  updateNotificacionUsuarios(notificacionusuario) {
    const url =
      process.env.VUE_APP_APIURL +
      `notificacionusuarios/${notificacionusuario.id_notificacion_usuario}`;
    return axios.put(url, notificacionusuario).then((res) => res.data.data);
  }

  deleteNotificacionUsuarios(notificacionusuario) {
    const url =
      process.env.VUE_APP_APIURL +
      `/notificacionusuarios/${notificacionusuario.id_notificacion_usuario}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
