import axios from 'axios';

export default class LegalCompanyService {

	getRazonSocialesPorEmpresa(empresa) {
		const url =
		process.env.VUE_APP_APIURL + `empresarazonsociales/empresas/${empresa}`;
		return axios.get(url).then((res) => res.data.data);
	}

	getRazonSociales() {		
        const url = process.env.VUE_APP_APIURL + `empresarazonsociales`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getRazonSocial(empresarazonsocial) {
        const url = process.env.VUE_APP_APIURL + `empresarazonsociales/${empresarazonsocial.id_empresa_razon_social}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateRazonSocial(empresarazonsocial){
        const url = process.env.VUE_APP_APIURL + `empresarazonsociales/${empresarazonsocial.id_empresa_razon_social}`;
		return axios.put(url,empresarazonsocial).then(res=>res.data.data);
	}

	createRazonSocial(empresarazonsocial){
        const url = process.env.VUE_APP_APIURL + `empresarazonsociales`;
		return axios.post(url,empresarazonsocial).then(res=>res.data.data);
	}

	deleteRazonSocial(empresarazonsocial){
        const url = process.env.VUE_APP_APIURL + `empresarazonsociales/${empresarazonsocial.id_empresa_razon_social}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}