import axios from "axios";

export default class SurveyTriggerServiece {
  getSurveyTrigger() {
    const url = process.env.VUE_APP_APIURL + `disparadorencuestas`;
    return axios.get(url).then((res) => res.data.data);
  }
  createSurveyTrigger(disparadorencuesta) {
    const url = process.env.VUE_APP_APIURL + `/disparadorencuestas`;
    return axios.post(url, disparadorencuesta).then((res) => res.data.data);
  }
  updateSurveyTrigger(disparadorencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/disparadorencuestas/${disparadorencuesta.id_disparador_encuesta}`;
    return axios.put(url, disparadorencuesta).then((res) => res.data.data);
  }
  deleteSurveyTrigger(disparadorencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/disparadorencuestas/${disparadorencuesta.id_disparador_encuesta}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
