import axios from 'axios';

export default class SupplierContactService {

	getProveedorContactos() {			
        const url = process.env.VUE_APP_APIURL + `proveedorcontactos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getProveedorContacto(proveedorcontacto) {
        const url = process.env.VUE_APP_APIURL + `/proveedorcontactos/${proveedorcontacto.id_proveedor_contacto}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateProveedorContacto(proveedorcontacto){
        const url = process.env.VUE_APP_APIURL + `/proveedorcontactos/${proveedorcontacto.id_proveedor_contacto}`;
		return axios.put(url,proveedorcontacto).then(res=>res.data.data);
	}

	createProveedorContacto(proveedorcontacto){
        const url = process.env.VUE_APP_APIURL + `/proveedorcontactos`;
		return axios.post(url,proveedorcontacto).then(res=>res.data.data);
	}

	deleteProveedorContacto(proveedorcontacto){
        const url = process.env.VUE_APP_APIURL + `/proveedorcontactos/${proveedorcontacto.id_proveedor_contacto}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}