
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxSorting,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import TypeControlService from '../services/type-control-service';
import ControlSizeService from '../services/control-size-service';
import MeasurementOptionService from '../services/measurement-option-service';
import ControlService from '../services/control-service';

export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxColumnChooser
  },
  data() {
    return {
      controles: null,      
      dataSourceControls: null,
      dataSourceControlTypes: null,      
      dataSourceControlSizes: null,
      dataSourceMeasurementOptions: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Configuración del Control',
        showTitle: true,
        width: 800,
      },
      mode: 'select',
    };
  },
  created() {
    this.controlService = new ControlService();
    this.typeControlService = new TypeControlService();
    this.controlSizeService = new ControlSizeService();
    this.measurementOptionService = new MeasurementOptionService();
  },
  mounted() {    
    this.typeControlService.getTipoControles().then(data => this.dataSourceControlTypes=data);
    this.controlSizeService.getTamanoControles().then(data => this.dataSourceControlSizes=data);
    this.measurementOptionService.getMedicionOpciones().then(data => this.dataSourceMeasurementOptions=data);
    this.controlService.getControles().then(data => this.dataSourceControls=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editControl(e) {
      //console.log(Object.keys(e.data).reduce((acc, key) => {acc[key] = e.data[key] === '' ? null : e.data[key]; return acc; }, {}));
      this.controlService.updateControl(Object.keys(e.data).reduce((acc, key) => {acc[key] = e.data[key] === '' ? null : e.data[key]; return acc; }, {})).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.controlService.getControles().then(data => this.dataSourceControls=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al editar el registro';
        this.isVisible = true;
        this.controlService.getControles().then(data => this.dataSourceControls=data);
      });
		},
    deleteControl(e) {
			this.controlService.deleteControl(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.controlService.getControles().then(data => this.dataSourceControls=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el control';
        this.isVisible = true;
        this.controlService.getControles().then(data => this.dataSourceControls=data);
      });
		},
    createControl(e) {
			this.controlService.createControl(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.controlService.getControles().then(data => this.dataSourceControls=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el control';
        this.isVisible = true;
        this.controlService.getControles().then(data => this.dataSourceControls=data);
      });
		},
    /*
    onEditorPreparing(e) {
      if (e.dataField === 'id_equipo_padre' && e.row.data.id_equipo === 0) {
        e.editorOptions.disabled = true;
        e.editorOptions.value = null;
      }
    },
    */
    onInitNewRow(e) {      
      e.data.activo = true;
      //e.data.id_equipo_padre = null;      
      //this.taskService.getEquipoPadres().then(data => this.dataSourceFatherTasks=data);
    },
  },  
};
