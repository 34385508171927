import axios from "axios";

export default class DestinationSurveyConfigService {
  getDestinationSurveyConfig() {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `configencuestadestinos`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  getDestinationSurveyConfigAvailable(id_config_encuesta, id) {
    var id_usuario = localStorage.getItem("user-data");
    const url =
      process.env.VUE_APP_APIURL + `configencuestadestinos/disponibles`;
    return axios
      .get(url, {
        params: {
          id_usuario: id_usuario,
          id_config_encuesta: id_config_encuesta,
          id: id,
        },
      })
      .then((res) => res.data.data);
  }

  getDestinationSurveyConfigAssigned(id_config_encuesta, id) {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `configencuestadestinos/asignados`;
    return axios
      .get(url, {
        params: {
          id_usuario: id_usuario,
          id_config_encuesta: id_config_encuesta,
          id: id,
        },
      })
      .then((res) => res.data.data);
  }

  getDestinationSurveyConfigByIdDestinationSurveyConfig(
    id_config_encuesta_destino
  ) {
    const url = process.env.VUE_APP_APIURL + `configencuestadestinos`;
    return axios
      .get(url, {
        params: { id_config_encuesta_destino: id_config_encuesta_destino },
      })
      .then((res) => res.data.data);
  }

  createDestinationSurveyConfig(configencuestadestino) {
    const url = process.env.VUE_APP_APIURL + `/configencuestadestinos`;
    return axios
      .post(url, configencuestadestino)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  updateDestinationSurveyConfig(configencuestadestino) {
    const url =
      process.env.VUE_APP_APIURL +
      `/configencuestadestinos/${configencuestadestino.id_config_encuesta_destino}`;
    return axios
      .put(url, configencuestadestino)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  deleteDestinationSurveyConfig(configencuestadestino) {
    const url =
      process.env.VUE_APP_APIURL +
      `/configencuestadestinos/${configencuestadestino.id_config_encuesta_destino}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
