import axios from "axios";

export default class UserService {
  getUsuariosPorPuesto(clavepuesto) {
    const url =
      process.env.VUE_APP_APIURL + `usuarios/clavepuestos/${clavepuesto}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getUsuariosPorOrdenTrabajoEquipo(ordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `usuarios/ordentrabajoequipos/${ordentrabajoequipo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getCalendarioUsuarios() {
    const url = process.env.VUE_APP_APIURL + `usuarios/calendarios`;
    return axios.get(url).then((res) => res.data.data);
  }

  getUsuarios() {
    const url = process.env.VUE_APP_APIURL + `usuarios`;
    return axios.get(url).then((res) => res.data.data);
  }

  getUsuariosFiltro(json) {
    const url = process.env.VUE_APP_APIURL + `usuarios/filtro`;
    return axios.get(url, { params: json }).then((res) => res.data.data);
  }

  getUsuariosTodos() {
    const url = process.env.VUE_APP_APIURL + `usuarios/todos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getUsuariosCombo() {
    const url = process.env.VUE_APP_APIURL + `usuarios/combos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getUsuariosComboWeb() {
    const url = process.env.VUE_APP_APIURL + `usuarios/combos/web`;
    return axios.get(url).then((res) => res.data.data);
  }

  getUsuariosOTComboWeb() {
    const url =
      process.env.VUE_APP_APIURL + `usuarios/ordentrabajos/combos/web`;
    return axios.get(url).then((res) => res.data.data);
  }

  getUsuario(usuario) {
    const url = process.env.VUE_APP_APIURL + `/usuarios/${usuario.id_usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateUsuario(usuario) {
    const url = process.env.VUE_APP_APIURL + `/usuarios/${usuario.id_usuario}`;
    return axios.put(url, usuario).then((res) => res.data.data);
  }

  createUsuario(usuario) {
    const url = process.env.VUE_APP_APIURL + `/usuarios`;
    return axios.post(url, usuario).then((res) => res.data.data);
  }

  deleteUsuario(usuario) {
    const url = process.env.VUE_APP_APIURL + `/usuarios/${usuario.id_usuario}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
