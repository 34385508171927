import axios from 'axios';

export default class GroupConfigService {

	getGrupoConfiguraciones() {	
        const url = process.env.VUE_APP_APIURL + `grupoconfiguraciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoConfiguracion(grupoconfiguracion) {
        const url = process.env.VUE_APP_APIURL + `/grupoconfiguraciones/${grupoconfiguracion.id_grupo_config}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoConfiguracion(grupoconfiguracion) {
        const url = process.env.VUE_APP_APIURL + `/grupoconfiguraciones/${grupoconfiguracion.id_grupo_config}`;
		return axios.put(url,grupoconfiguracion)
		.then(res=>res.data.data);
	}

	createGrupoConfiguracion(grupoconfiguracion){
        const url = process.env.VUE_APP_APIURL + `/grupoconfiguraciones`;
		return axios.post(url,grupoconfiguracion).then(res=>res.data.data);
	}

	deleteGrupoConfiguracion(grupoconfiguracion){
        const url = process.env.VUE_APP_APIURL + `/grupoconfiguraciones/${grupoconfiguracion.id_grupo_config}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}