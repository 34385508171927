
import {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxToolbar
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import { DxButton } from 'devextreme-vue/button';

import ItemAisleService from '../services/item-aisle-service';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxPaging,
        DxToast,
        DxToolbar,
        DxItem,
        DxButton
    },
    created() {
        this.itemAisleService = new ItemAisleService();
    },
    mounted() {
        this.itemAisleService.getArticuloPasillos().then(data => this.dataSourceItemAisle = data);
    },
    data() {
        return {
            dataSourceItemAisle: null,
            isVisible: false,
            message: '',
            type: 'info',
        }
    },
    methods: {
        refreshDataGrid() {
            this.itemAisleService.getArticuloPasillos().then(data => this.dataSourceItemAisle = data);
        },
        createItemAisle(e) {
            this.itemAisleService.createArticuloPasillo(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha creado el registro';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        editItemAisle(e) {
            this.itemAisleService.updateArticuloPasillo(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },
        deleteItemAisle(e) {
            this.itemAisleService.deleteArticuloPasillo(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha eliminado el registro';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        }
    },
}
