


import {
  DxDataGrid,
  DxEditing,
  DxGrouping,
  DxGroupPanel,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxDateBox from 'devextreme-vue/date-box';
import SpecialDayService from '../services/special-day-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxGrouping,
    DxGroupPanel,    
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxDateBox,
    DxSelection,
    DxSorting,
  },
  data() {
    return {      
      dataSourceSpecialDays: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      now: new Date(),
      fecha: 'dd/MM/yyyy HH:mm',
    };
  },
  created() {
    this.specialDayService = new SpecialDayService();    
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
  },
  mounted() {            
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.specialDayService.getDiasEspeciales().then(data => this.dataSourceSpecialDays=data);      
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editSpecialDay(e) {
      this.specialDayService.updateDiaEspecial(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteSpecialDay(e) {
			this.specialDayService.deleteDiaEspecial(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createSpecialDay(e) {
			this.specialDayService.createDiaEspecial(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {      
      e.data.activo = true;      
    },
  },
};
