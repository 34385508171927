import axios from 'axios';

export default class ConfigAlertService {

	getConfigAlertas() {		
        const url = process.env.VUE_APP_APIURL + `configalertas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigAlerta(configalerta) {
        const url = process.env.VUE_APP_APIURL + `/configalertas/${configalerta.id_config_alerta}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigAlerta(configalerta){
        const url = process.env.VUE_APP_APIURL + `/configalertas/${configalerta.id_config_alerta}`;
		return axios.put(url,configalerta).then(res=>res.data.data);
	}

	createConfigAlerta(configalerta){
        const url = process.env.VUE_APP_APIURL + `/configalertas`;
		return axios.post(url,configalerta).then(res=>res.data.data);
	}

	deleteConfigAlerta(configalerta){
        const url = process.env.VUE_APP_APIURL + `/configalertas/${configalerta.id_config_alerta}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}