import axios from 'axios';

export default class TypeWarrantyService {

	getTipoGarantias() {			
        const url = process.env.VUE_APP_APIURL + `tipogarantias`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoGarantia(tipogarantia) {
        const url = process.env.VUE_APP_APIURL + `/tipogarantias/${tipogarantia.id_tipo_garantia}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoGarantia(tipogarantia){
        const url = process.env.VUE_APP_APIURL + `/tipogarantias/${tipogarantia.id_tipo_garantia}`;
		return axios.put(url,tipogarantia).then(res=>res.data.data);
	}

	createTipoGarantia(tipogarantia){
        const url = process.env.VUE_APP_APIURL + `/tipogarantias`;
		return axios.post(url,tipogarantia).then(res=>res.data.data);
	}

	deleteTipoGarantia(tipogarantia){
        const url = process.env.VUE_APP_APIURL + `/tipogarantias/${tipogarantia.id_tipo_garantia}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}