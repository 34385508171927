
import {
  DxDataGrid,
  DxColumn,
  DxLookup, 
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxPopup, { DxToolbarItem }from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxList from 'devextreme-vue/list';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';

import WarehouseService from '../services/warehouse-service';
import TypeMovementService from '../services/type-movement-service';
import ItemService from '../services/item-service';
import BrandService from '../services/brand-service';
import UserService from '../services/user-service';
import StockTransferService from '../services/stock-transfer-service';
import StockTransferSerieService from '../services/stock-transfer-serie-service';

export default {
  components: {
    DxSelectBox,
    DxDateBox,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSelection,
    DxColumnChooser,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxList,
    DxGroupPanel,
    DxGrouping,
  },
  data() {
    const now = new Date();
    return {
      mode: 'select',
      movementId: null,
      company: null,
      usuarioId: null,
      warehouse: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      dataSourceWarehouses: null,
      dataSourceStockTransfer: null,
      dataSourceMovementTypes: null,
      dataSourceItems: null,
      dataSourceBrands: null,
      dataSourceUsers: null,
    };
  },
  created() {
    this.warehouseService = new WarehouseService();
    this.itemService = new ItemService();
    this.brandService = new BrandService();
    this.userService = new UserService();
    this.typeMovementService = new TypeMovementService();
    this.stockTransferService = new StockTransferService();
    this.stockTransferSerieService = new StockTransferSerieService();
  },
  mounted() {
    this.warehouseService.getAlmacenes().then((data) => {
      this.dataSourceWarehouses=data;
      this.warehouse = data[0];
    });
    this.userService.getUsuariosTodos().then(data => this.dataSourceUsers=data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
    this.itemService.getArticulos().then(data => this.dataSourceItems=data);
    this.typeMovementService.getTipoMovimientos().then(data => this.dataSourceMovementTypes=data);
  },
  methods: {
    onClick() {
      this.stockTransferService.getMovimientosPorAlmacen(this.warehouse.id_almacen,this.startDate,this.endDate).then(data => this.dataSourceStockTransfer=data);
    },
    showSeries(e) {
      this.showPopup(e.row.data.nombre, e.row.data.id_movimiento);
    },
    showPopup(popupStock, movementId) {
      this.loading = true;      
      this.movementId = movementId;
      this.popupStock = popupStock;
      this.popupVisible = true;
      this.refresh();
    },
    async refresh() {      
      await this.stockTransferSerieService.getMovimientoSeries(this.movementId).then((data) => {
        this.dataSourceSeries=data;
        this.loading = false;
      });
    },
    closeClick() {
        this.popupVisible = false;
    },
    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },
  },
};
