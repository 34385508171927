import axios from "axios";

export default class WorkOrderEquipmentService {
  // Historial de mantenimiento
  getHistorialTareasPorEquipo(equipoempresa) {
    const url =
      process.env.VUE_APP_APIURL +
      `ordentrabajoequipos/historial/equipoempresa`;
    return axios
      .get(url, { params: { id_equipo_empresa: equipoempresa } })
      .then((res) => res.data.data);
  }

  getEquiposComboPorOrdenTrabajo(ordentrabajo) {
    const url =
      process.env.VUE_APP_APIURL +
      `ordentrabajoequipos/equipos/combos/ordentrabajos/${ordentrabajo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquiposPorOrdenTrabajo(ordentrabajo) {
    const url =
      process.env.VUE_APP_APIURL +
      `ordentrabajoequipos/equipos/ordentrabajos/${ordentrabajo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoEquipos() {
    const url = process.env.VUE_APP_APIURL + `ordentrabajoequipos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoEquipo(ordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajoequipos/${ordentrabajoequipo.id_orden_trabajo_equipo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateOrdenTrabajoEquipo(ordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajoequipos/${ordentrabajoequipo.id_orden_trabajo_equipo}`;
    return axios
      .put(url, ordentrabajoequipo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createOrdenTrabajoEquipo(ordentrabajoequipo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajoequipos`;
    return axios
      .post(url, ordentrabajoequipo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteOrdenTrabajoEquipo(ordentrabajoequipo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajoequipos/${ordentrabajoequipo.id_orden_trabajo_equipo}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
