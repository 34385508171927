

import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import Items from './item.vue';
import ItemSupplier from './item-supplier.vue';
import ItemSpecialField from './item-special-field.vue';
import ItemSpare from './item-spare.vue';
import Kit from './kit.vue';
import KitItem from './kit-item.vue';

import RolePermitService from '../services/role-permit-service'

export default {
  components: {
    DxTabPanel,
    DxItem,
    Items,
    ItemSupplier,
    ItemSpecialField,
    ItemSpare,
    Kit,
    KitItem
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
      itemVisible: false,
      supplierItem: false,
      specialFields: false,
      genericParts: false,
      kits: false,
    };
  },
  created() {
    this.rolePermitService = new RolePermitService();
  },
  mounted() {
    var user = JSON.parse(localStorage.getItem("user-data"));
    var dataJsonItem = {
      id_usuario: user,
      clave: 'OP-ART'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonItem).then(data => {
      if (data) {
        this.itemVisible = true;
      } else {
        this.itemVisible = false;
      }
    });
    var dataJsonSupplierItem = {
      id_usuario: user,
      clave: 'OP-ART-P'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonSupplierItem).then(data => {
      if (data) {
        this.supplierItem = true;
      } else {
        this.supplierItem = false;
      }
    });

    var dataJsonSpecialFields = {
      id_usuario: user,
      clave: 'OP-ART-CE'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonSpecialFields).then(data => {
      if (data) {
        this.specialFields = true;
      } else {
        this.specialFields = false;
      }
    });

    var dataJsonGenericParts = {
      id_usuario: user,
      clave: 'OP-ART-RG'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonGenericParts).then(data => {
      if (data) {
        this.genericParts = true;
      } else {
        this.genericParts = false;
      }
    });

    var dataJsonKits = {
      id_usuario: user,
      clave: 'OP-ART-KITS'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonKits).then(data => {
      if (data) {
        this.kits = true;
      } else {
        this.kits = false;
      }
    });
  }
};
