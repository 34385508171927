

import {
  DxForm,
  DxSimpleItem,
  DxLabel,
  DxValidationRule,
  DxTabbedItem,
  DxTab,
  DxItem,
  DxGroupItem,
  DxEmptyItem,
  DxRangeRule,
} from 'devextreme-vue/form';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxSortable } from 'devextreme-vue/sortable';
import { DxLookup } from 'devextreme-vue/lookup'
import DxToolbar from 'devextreme-vue/toolbar';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button'
import notify from 'devextreme/ui/notify';
import 'devextreme/ui/select_box';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSelection,
  DxSearchPanel,
  DxSorting,
  DxPager,
  DxLoadPanel,
  DxScrolling,
  DxEditing,
  DxColumnChooser
} from 'devextreme-vue/data-grid';


import ActivityService from '../services/activity-service';
import UserService from '../services/user-service';
import PriorityService from '../services/priority-service';
import StatusActivityService from '../services/status-activity-service';
import UserChannelService from '../services/user-channel-service';
import ChannelService from '../services/channel-service';

import ArchivedActivities from './archived-activities.vue';
import ActivitiesCalendar from './activity-calendar.vue';

import ChannelActivityStatusService from '../services/channel-activity-status-service';
import ActivityEventService from '../services/activity-event-service';
import LeagueModuleService from '../services/league-module-service';
import ActivityLeagueService from '../services/activity-league-service';

export default {
  components: {
    DxScrollView,
    DxSortable,
    DxToolbar,
    DxItem,
    DxSelectBox,
    DxPopup,
    DxToolbarItem,
    DxValidationRule,
    DxForm,
    DxSimpleItem,
    DxLabel,
    DxLookup,
    DxButton,
    DxTabPanel,
    ArchivedActivities,
    ActivitiesCalendar,
    DxTabbedItem,
    DxTab,
    DxGroupItem,
    DxEmptyItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSelection,
    DxSearchPanel,
    DxSorting,
    DxPager,
    DxLoadPanel,
    DxScrolling,
    DxEditing,
    DxColumnChooser,
    DxRangeRule
  },
  data() {
    return {
      endStatus: 0,
      idActividad: null,
      activityFormData: { titulo: null, descripcion: null, referencia: null, avance: 0, fechaInicio: new Date(), fechaFin: null },
      idAsignado: null,
      idActividadPadre: null,
      idEstatus: null,
      idPrioridad: null,
      fechaFin: null,
      popupTitle: "Actividad",
      popupVisible: false,
      channel: null,
      usuarioId: null,
      statuses: [],
      statusesActivities: [],
      activities: [],
      lists: [],
      usersMap: {},
      activitiesEndDateMap: {},
      dataSourceChannels: null,
      dataSourcePriorities: null,
      dataSourceAssignee: null,
      dataSourceUsers: null,
      dataSourceActivities: null,
      dataSourceParentActivity: null,
      dataSourceStatus: null,
      addButtonOptions: {
        icon: 'plus',
        onClick: () => {
          this.dataSourceActivityEvent = [];
          this.dataSourceLeagueActivity = [];
          this.idActividad = null;
          this.popupVisible = true;
          this.leagueModuleService.getModuloLigas().then((data) => {
            this.dataLeagueModule = data;
          });
        },
      },
      refreshButtonOptions: {
        icon: 'refresh',
        onClick: () => {
          this.refresh();
          notify('Actividades actualizadas');
        },
      },
      selectBoxOptions: {
        width: 140,
      },
      archivado: false,
      isVisible: false,
      message: '',
      type: 'info',
      dataSourceArchivedActivities: null,
      dataSourceArchivedActivitiesChannel: null,
      dataSourceActivityEvent: null,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      dataLeagueModule: null,
      leagueModule: null,
      dataFolios: null,
      folios: null,
      searchModeOption: 'contains',
      dataSourceLeagueActivity: null,


    };
  },
  created() {
    this.userChannelService = new UserChannelService();
    this.userService = new UserService();
    this.activityService = new ActivityService();
    this.priorityService = new PriorityService();
    this.statusActivityService = new StatusActivityService();
    this.channelService = new ChannelService();
    this.channelActivityStatusService = new ChannelActivityStatusService();
    this.activityEventService = new ActivityEventService();
    this.leagueModuleService = new LeagueModuleService();
    this.activityLeagueService = new ActivityLeagueService();
  },
  mounted() {
    this.usuarioId = localStorage.getItem("user-data");
    this.priorityService.getPrioridades().then(data => this.dataSourcePriorities = data);
    this.userChannelService.getUsuarioCanales(this.usuarioId).then((data) => {
      this.dataSourceChannels = data;
      this.channel = data[0];

      this.userService.getUsuarios().then((data) => {
        this.dataSourceUsers = data;
        this.dataSourceAssignee = data;

        this.dataSourceUsers.forEach((user) => {
          this.usersMap[user.id_usuario] = user.nombre;
        });
      });

      this.statuses = [];
      this.statusesActivities = [];
      this.lists = [];
    });
    this.activityService.getActividadesArchivados().then(data => this.dataSourceArchivedActivities = data);
    this.channelService.getCanales().then(data => this.dataSourceArchivedActivitiesChannel = data);
    this.dataSourceActivityEvent = [];
    this.dataSourceLeagueActivity = [];
  },
  methods: {
    refresh() {
      if (this.channel != null) {
        this.statuses = [];
        this.statusesActivities = [];
        this.lists = [];
        this.channelActivityStatusService.getEstatusActividadAsignados(this.channel.id_canal).then((data) => {
          this.statusActivityService = data;
          this.dataSourceStatus = data;

          this.statusActivityService.forEach((status) => {
            this.statuses.push(status.nombre);
            this.statusesActivities.push(status);
          });
          this.activityService.getActividadesPorCanal(this.channel.id_canal).then((data) => {
            this.dataSourceActivities = data;
            this.dataSourceParentActivity = data;

            this.statusesActivities.forEach((status) => {
              this.lists.push(this.dataSourceActivities.filter((activity) => activity.id_estatus_actividad === status.id_estatus_actividad));
            });
          });
        });
      }
    },
    openPopup(actividadId) {
      this.activityService.getActividad({ "id_actividad": actividadId }).then((data) => {
        this.activityFormData.titulo = data.titulo;
        this.activityFormData.descripcion = data.descripcion;
        this.activityFormData.referencia = data.referencia;
        this.activityFormData.avance = data.avance;
        this.activityFormData.fechaInicio = data.fecha_inicio;
        this.activityFormData.fechaFin = data.fecha_fin;
        this.idEstatus = data.id_estatus_actividad;
        this.idPrioridad = data.id_prioridad;
        this.idAsignado = data.id_usuario_asignado;
        this.idActividadPadre = data.id_actividad_padre;
        this.idActividad = data.id_actividad;
        this.archivado = true;
        this.popupVisible = true;
        this.dataSourceActivityEvent = [];
        this.folios == null;
        this.dataFolios = [];
        this.activityEventService.getActividadEventoPorActividad(actividadId).then((data) => {
          this.dataSourceActivityEvent = data;
        });
        this.leagueModuleService.getModuloLigas().then((data) => {
          this.dataLeagueModule = data;
        });
        this.activityLeagueService.getActividadLigaPorActividad(actividadId).then((data) => {
          this.dataSourceLeagueActivity = data;
        });
      });
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    formatDate(date) {
      return [
        this.padTo2Digits(new Date(date).getDate()),
        this.padTo2Digits(new Date(date).getMonth() + 1),
        new Date(date).getFullYear(),
      ].join('/');
    },
    saveActivity() {
      const id_usuario = localStorage.getItem('user-data');
      if (this.idActividad == null) {
        this.activityService.createActividad({
          "titulo": this.activityFormData.titulo, "fecha_inicio": this.activityFormData.fechaInicio,
          "fecha_fin": this.activityFormData.fechaFin, "id_canal": this.channel.id_canal, "descripcion": this.activityFormData.descripcion,
          "id_prioridad": this.idPrioridad, "avance": this.activityFormData.avance, "id_usuario_asignado": this.idAsignado,
          "id_actividad_padre": this.idActividadPadre, "id_estatus_actividad": this.idEstatus, "id_usuario": id_usuario, "referencia": this.activityFormData.referencia
        }).then((data) => {

          this.dataSourceActivityEvent.forEach(element => {
            element.id_actividad = data.id_actividad;
            element.id_usuario_reg = id_usuario;
            this.activityEventService.createActividadEventos(element).then();
          });

          this.dataSourceLeagueActivity.forEach(element => {
            element.id_actividad = data.id_actividad;
            this.activityLeagueService.createActividadLiga(element).then();
            this.folios == null;
          });

          this.type = 'success';
          this.message = 'Se ha registrado la actividad correctamente';
          this.isVisible = true;
          this.cleanForm();
          this.refresh();
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al registrar la actividad';
          this.isVisible = true;
          this.cleanForm();
          this.refresh();
        });
      } else {
        this.activityService.updateActividad({
          "id_actividad": this.idActividad, "titulo": this.activityFormData.titulo, "fecha_inicio": this.activityFormData.fechaInicio,
          "fecha_fin": this.activityFormData.fechaFin, "id_canal": this.channel.id_canal, "descripcion": this.activityFormData.descripcion,
          "id_prioridad": this.idPrioridad, "avance": this.activityFormData.avance, "id_usuario_asignado": this.idAsignado,
          "id_actividad_padre": this.idActividadPadre, "id_estatus_actividad": this.idEstatus, "id_usuario": id_usuario, "referencia": this.activityFormData.referencia
        }).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado la actividad correctamente';
          this.isVisible = true;
          this.cleanForm();
          this.refresh();
          this.archivado = false;
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al actualizar la actividad';
          this.isVisible = true;
          this.cleanForm();
          this.refresh();
          this.archivado = false;
        });
      }

      this.popupVisible = false;
    },
    cleanForm() {
      this.idActividad = null;
      this.idEstatus = 1;
      this.idActividadPadre = null;
      this.idPrioridad = null;
      this.idAsignado = null;
      this.activityFormData.titulo = null;
      this.activityFormData.fechaInicio = new Date();
      this.activityFormData.fechaFin = null;
      this.activityFormData.avance = 0;
      this.activityFormData.descripcion = null;
      this.activityFormData.referencia = null;
      //this.activityFormData.instance.resetValues();
    },
    closeClick() {
      this.cleanForm();
      this.popupVisible = false;
      this.archivado = false;
    },
    onListReorder(e) {
      const list = this.lists.splice(e.fromIndex, 1)[0];
      this.lists.splice(e.toIndex, 0, list);

      const status = this.statuses.splice(e.fromIndex, 1)[0];
      this.statuses.splice(e.toIndex, 0, status);
      console.log('onListReorder');
      console.log(this.lists);
      console.log(this.statuses);
    },
    onActivityDragStart(e) {
      e.itemData = e.fromData[e.fromIndex];
      // console.log('onActivityDragStart', e);
      // console.log(e.itemData);
      // console.log(e);
      this.endStatus = e.itemData.id_estatus_actividad - 1;
    },
    onActivityDragEnd(e) {
      //e.itemData = e.fromData[e.fromIndex];

      this.statusesActivities.forEach(element => {
        if (e.toComponent.option("statusValue") == element.nombre)
          this.endStatus = element.id_estatus_actividad;
      });
      //console.log(e.toData.id_estatus_actividad);
      //console.log(this.endStatus);
      if (e.itemData.id_estatus_actividad != this.endStatus) {
        e.itemData.id_estatus_actividad = this.endStatus;
        this.activityService.updateActividadStatus(e.itemData);
      }

      if (e.toData.id_estatus_actividad != this.endStatus) {
        console.log("Cambio de estatus");
        //console.log(e.itemData.id_actividad);
        //console.log(this.endStatus);
      }
    },
    onActivityDrop(e) {
      e.fromData.splice(e.fromIndex, 1);
      e.toData.splice(e.toIndex, 0, e.itemData);

      var index = 0;
      e.toData.forEach(element => {
        index++;
        element.orden = index;
        this.activityService.updateActividadOrden(element);
      });
    },
    getPriorityClass(activity) {
      return `priority-${5 - activity.id_prioridad}`;
    },
    valueChangedChannel(e) {
      this.statuses = [];
      this.statusesActivities = [];
      this.lists = [];
      this.channelActivityStatusService.getEstatusActividadAsignados(e.value.id_canal).then((data) => {
        this.statusActivityService = data;
        this.dataSourceStatus = data;

        this.statusActivityService.forEach((status) => {
          this.statuses.push(status.nombre);
          this.statusesActivities.push(status);
        });

        if (this.channel != null) {
          this.activityService.getActividadesPorCanal(this.channel.id_canal).then((data) => {
            this.dataSourceActivities = data;
            this.dataSourceParentActivity = data;

            this.statusesActivities.forEach((status) => {
              this.lists.push(this.dataSourceActivities.filter((activity) => activity.id_estatus_actividad === status.id_estatus_actividad));
            });
          });
        }
      });

    },
    archiveactivities() {
      this.activityService.archive({ 'id_actividad': this.idActividad }).then(data => {
        if (data) {
          this.activityFormData.titulo = '';
          this.activityFormData.descripcion = '';
          this.activityFormData.referencia = '';
          this.activityFormData.avance = '';
          this.activityFormData.fechaInicio = '';
          this.activityFormData.fechaFin = '';
          this.idEstatus = '';
          this.idPrioridad = '';
          this.idAsignado = '';
          this.idActividadPadre = '';
          this.idActividad = '';
          this.archivado = false;
          this.dataSourceActivityEvent = [];
          this.folios == null;
          this.dataFolios = [];

          this.dataSourceActivityEvent = [];

          this.dataLeagueModule = [];
          this.dataSourceLeagueActivity = [];
          this.refresh();
          this.popupVisible = false;
        }
      });
    },
    getPriorityClassColor(activity) {
      this.dataSourcePriorities.forEach(element => {
        if (activity.id_prioridad == element.id_prioridad) {
          return element
        }
      });
    },
    createActivityEvent(e) {
      if (this.idActividad != null) {
        var usuario = JSON.parse(localStorage.getItem("user-data"));
        e.data.id_actividad = this.idActividad;
        e.data.id_usuario_reg = usuario;
        this.activityEventService.createActividadEventos(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.activityEventService.getActividadEventoPorActividad(this.idActividad).then((data) => {
            this.dataSourceActivityEvent = data;
          });
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al creado el registro';
          this.isVisible = true;
          this.activityEventService.getActividadEventoPorActividad(this.idActividad).then((data) => {
            this.dataSourceActivityEvent = data;
          });
        });
      }
    },
    editActivityEvent(e) {
      if (this.idActividad != null) {
        this.activityEventService.updateActividadEventos(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.activityEventService.getActividadEventoPorActividad(this.idActividad).then((data) => {
            this.dataSourceActivityEvent = data;
          });
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al actualizar el registro';
          this.isVisible = true;
          this.activityEventService.getActividadEventoPorActividad(this.idActividad).then((data) => {
            this.dataSourceActivityEvent = data;
          });
        });
      }
    },
    deleteActivityEvent(e) {
      if (this.idActividad != null) {
        this.activityEventService.deleteActividadEventos(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.activityEventService.getActividadEventoPorActividad(this.idActividad).then((data) => {
            this.dataSourceActivityEvent = data;
          });
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al eliminar el registro';
          this.isVisible = true;
          this.activityEventService.getActividadEventoPorActividad(this.idActividad).then((data) => {
            this.dataSourceActivityEvent = data;
          });
        });
      }
    },
    onInitNewRowActivityEvent(e) {
      var usuario = JSON.parse(localStorage.getItem("user-data"));
      e.data.id_usuario_reg = usuario;
    },
    valueChangedLeagueModule() {
      this.activityLeagueService.getActividadLigaPorFolios(this.leagueModule.id_modulo_liga).then(data => {
        this.dataFolios = data;
      });
    },
    createLeagueActivity(e) {
      const id_usuario = localStorage.getItem('user-data');
      if (this.idActividad != null) {
        e.data.id_actividad = this.idActividad;
        e.data.id_modulo_liga = this.leagueModule.id_modulo_liga;
        e.data.id = this.folios.id;
        e.data.id_usuario = id_usuario;

        this.activityLeagueService.createActividadLiga(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.folios == null;
          this.activityLeagueService.getActividadLigaPorActividad(this.idActividad).then((data) => {
            this.dataSourceLeagueActivity = data;
          });
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al creado el registro';
          this.isVisible = true;
        });
      } else {
        e.data.id_actividad = this.idActividad;
        e.data.id_modulo_liga = this.leagueModule == null ? null : this.leagueModule.id_modulo_liga;
        e.data.id = this.folios == null ? null : this.folios.id;
        e.data.id_usuario = id_usuario;
      }
    },
    editLeagueActivity(e) {
      if (this.idActividad != null) {
        const id_usuario = localStorage.getItem('user-data');
        e.data.id_actividad = this.idActividad;
        e.data.id_modulo_liga = this.leagueModule == null ? null : this.leagueModule.id_modulo_liga;
        e.data.id = this.folios == null ? null : this.folios.id;
        e.data.id_usuario = id_usuario;

        this.activityLeagueService.updateActividadLiga(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.folios == null;
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al creado el registro';
          this.isVisible = true;
        });
      }
    },
    deleteLeagueActivity(e) {
      if (this.idActividad != null) {
        this.activityLeagueService.deleteActividadLiga(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al eliminar el registro';
          this.isVisible = true;
        });
      }
    }
  },
};
