import axios from 'axios';

export default class TypeLogService {

	getTipoLogs() {			
        const url = process.env.VUE_APP_APIURL + `tipologs`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoLog(tipolog) {
        const url = process.env.VUE_APP_APIURL + `/tipologs/${tipolog.id_tipo_log}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoLog(tipolog){
        const url = process.env.VUE_APP_APIURL + `/tipologs/${tipolog.id_tipo_log}`;
		return axios.put(url,tipolog).then(res=>res.data.data);
	}

	createTipoLog(tipolog){
        const url = process.env.VUE_APP_APIURL + `/tipologs`;
		return axios.post(url,tipolog).then(res=>res.data.data);
	}

	deleteTipoLog(tipolog){
        const url = process.env.VUE_APP_APIURL + `/tipologs/${tipolog.id_tipo_log}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}