import axios from "axios";

export default class KpiService {
  getKPI(kpi) {
    const url = process.env.VUE_APP_APIURL + `/kpis/${kpi.id_kpi}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateKPI(kpi) {
    const url = process.env.VUE_APP_APIURL + `/kpis/${kpi.id_kpi}`;
    return axios.put(url, kpi).then((res) => res.data.data);
  }

  createKPI(kpi) {
    const url = process.env.VUE_APP_APIURL + `/kpis`;
    return axios.post(url, kpi).then((res) => res.data.data);
  }

  deleteKPI(kpi) {
    const url = process.env.VUE_APP_APIURL + `/kpis/${kpi.id_kpi}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getKPIs() {
    const url = process.env.VUE_APP_APIURL + `/kpis`;
    return axios.get(url).then((res) => res.data.data);
  }

  getKPICombo() {
    const url = process.env.VUE_APP_APIURL + `/kpis/combos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getdashboardweb(
    id_empresa,
    id_cliente,
    startDate,
    endDate,
    usuarioId,
    id_seccion_kpi
  ) {
    const url = process.env.VUE_APP_APIURL + `dashboardweb`;
    return axios
      .get(url, {
        params: {
          id_empresa: id_empresa,
          id_cliente: id_cliente,
          fecha_inicio: startDate,
          fecha_fin: endDate,
          id_usuario: usuarioId,
          id_seccion_kpi: id_seccion_kpi,
        },
      })
      .then((res) => res.data.data);
  }
}
