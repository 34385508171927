export const surveyTypes = [{
  clave: 'RS',
  nombre: 'Orden de Servicio'
}, {
  clave: 'EOT',
  nombre: 'Encuesta para OT'
}, {
  clave: 'EG',
  nombre: 'Encuesta General'
}];

export const tasksGantt = [{
  id: 1,
  parentId: 0,
  title: 'Software Development',
  start: new Date('2019-02-21T05:00:00.000Z'),
  end: new Date('2019-07-04T12:00:00.000Z'),
  progress: 31,
}, {
  id: 2,
  parentId: 1,
  title: 'Scope',
  start: new Date('2019-02-21T05:00:00.000Z'),
  end: new Date('2019-02-26T09:00:00.000Z'),
  progress: 60,
}, {
  id: 3,
  parentId: 2,
  title: 'Determine project scope',
  start: new Date('2019-02-21T05:00:00.000Z'),
  end: new Date('2019-02-21T09:00:00.000Z'),
  progress: 100,
}, {
  id: 4,
  parentId: 2,
  title: 'Secure project sponsorship',
  start: new Date('2019-02-21T10:00:00.000Z'),
  end: new Date('2019-02-22T09:00:00.000Z'),
  progress: 100,
}, {
  id: 5,
  parentId: 2,
  title: 'Define preliminary resources',
  start: new Date('2019-02-22T10:00:00.000Z'),
  end: new Date('2019-02-25T09:00:00.000Z'),
  progress: 60,
}, {
  id: 6,
  parentId: 2,
  title: 'Secure core resources',
  start: new Date('2019-02-25T10:00:00.000Z'),
  end: new Date('2019-02-26T09:00:00.000Z'),
  progress: 0,
}, {
  id: 7,
  parentId: 2,
  title: 'Scope complete',
  start: new Date('2019-02-26T09:00:00.000Z'),
  end: new Date('2019-02-26T09:00:00.000Z'),
  progress: 0,
}, {
  id: 8,
  parentId: 1,
  title: 'Analysis/Software Requirements',
  start: new Date('2019-02-26T10:00:00.000Z'),
  end: new Date('2019-03-18T09:00:00.000Z'),
  progress: 80,
}, {
  id: 9,
  parentId: 8,
  title: 'Conduct needs analysis',
  start: new Date('2019-02-26T10:00:00.000Z'),
  end: new Date('2019-03-05T09:00:00.000Z'),
  progress: 100,
}, {
  id: 10,
  parentId: 8,
  title: 'Draft preliminary software specifications',
  start: new Date('2019-03-05T10:00:00.000Z'),
  end: new Date('2019-03-08T09:00:00.000Z'),
  progress: 100,
}, {
  id: 11,
  parentId: 8,
  title: 'Develop preliminary budget',
  start: new Date('2019-03-08T10:00:00.000Z'),
  end: new Date('2019-03-12T09:00:00.000Z'),
  progress: 100,
}, {
  id: 12,
  parentId: 8,
  title: 'Review software specifications/budget with team',
  start: new Date('2019-03-12T10:00:00.000Z'),
  end: new Date('2019-03-12T14:00:00.000Z'),
  progress: 100,
}, {
  id: 13,
  parentId: 8,
  title: 'Incorporate feedback on software specifications',
  start: new Date('2019-03-13T05:00:00.000Z'),
  end: new Date('2019-03-13T14:00:00.000Z'),
  progress: 70,
}, {
  id: 14,
  parentId: 8,
  title: 'Develop delivery timeline',
  start: new Date('2019-03-14T05:00:00.000Z'),
  end: new Date('2019-03-14T14:00:00.000Z'),
  progress: 0,
}, {
  id: 15,
  parentId: 8,
  title: 'Obtain approvals to proceed (concept, timeline, budget)',
  start: new Date('2019-03-15T05:00:00.000Z'),
  end: new Date('2019-03-15T09:00:00.000Z'),
  progress: 0,
}, {
  id: 16,
  parentId: 8,
  title: 'Secure required resources',
  start: new Date('2019-03-15T10:00:00.000Z'),
  end: new Date('2019-03-18T09:00:00.000Z'),
  progress: 0,
}, {
  id: 17,
  parentId: 8,
  title: 'Analysis complete',
  start: new Date('2019-03-18T09:00:00.000Z'),
  end: new Date('2019-03-18T09:00:00.000Z'),
  progress: 0,
}, {
  id: 18,
  parentId: 1,
  title: 'Design',
  start: new Date('2019-03-18T10:00:00.000Z'),
  end: new Date('2019-04-05T14:00:00.000Z'),
  progress: 80,
}, {
  id: 19,
  parentId: 18,
  title: 'Review preliminary software specifications',
  start: new Date('2019-03-18T10:00:00.000Z'),
  end: new Date('2019-03-20T09:00:00.000Z'),
  progress: 100,
}, {
  id: 20,
  parentId: 18,
  title: 'Develop functional specifications',
  start: new Date('2019-03-20T10:00:00.000Z'),
  end: new Date('2019-03-27T09:00:00.000Z'),
  progress: 100,
}, {
  id: 21,
  parentId: 18,
  title: 'Develop prototype based on functional specifications',
  start: new Date('2019-03-27T10:00:00.000Z'),
  end: new Date('2019-04-02T09:00:00.000Z'),
  progress: 100,
}, {
  id: 22,
  parentId: 18,
  title: 'Review functional specifications',
  start: new Date('2019-04-02T10:00:00.000Z'),
  end: new Date('2019-04-04T09:00:00.000Z'),
  progress: 30,
}, {
  id: 23,
  parentId: 18,
  title: 'Incorporate feedback into functional specifications',
  start: new Date('2019-04-04T10:00:00.000Z'),
  end: new Date('2019-04-05T09:00:00.000Z'),
  progress: 0,
}, {
  id: 24,
  parentId: 18,
  title: 'Obtain approval to proceed',
  start: new Date('2019-04-05T10:00:00.000Z'),
  end: new Date('2019-04-05T14:00:00.000Z'),
  progress: 0,
}, {
  id: 25,
  parentId: 18,
  title: 'Design complete',
  start: new Date('2019-04-05T14:00:00.000Z'),
  end: new Date('2019-04-05T14:00:00.000Z'),
  progress: 0,
}, {
  id: 26,
  parentId: 1,
  title: 'Development',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-05-07T12:00:00.000Z'),
  progress: 42,
}, {
  id: 27,
  parentId: 26,
  title: 'Review functional specifications',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-04-08T14:00:00.000Z'),
  progress: 100,
}, {
  id: 28,
  parentId: 26,
  title: 'Identify modular/tiered design parameters',
  start: new Date('2019-04-09T05:00:00.000Z'),
  end: new Date('2019-04-09T14:00:00.000Z'),
  progress: 100,
}, {
  id: 29,
  parentId: 26,
  title: 'Assign development staff',
  start: new Date('2019-04-10T05:00:00.000Z'),
  end: new Date('2019-04-10T14:00:00.000Z'),
  progress: 100,
}, {
  id: 30,
  parentId: 26,
  title: 'Develop code',
  start: new Date('2019-04-11T05:00:00.000Z'),
  end: new Date('2019-05-01T14:00:00.000Z'),
  progress: 49,
}, {
  id: 31,
  parentId: 26,
  title: 'Developer testing (primary debugging)',
  start: new Date('2019-04-16T12:00:00.000Z'),
  end: new Date('2019-05-07T12:00:00.000Z'),
  progress: 24,
}, {
  id: 32,
  parentId: 26,
  title: 'Development complete',
  start: new Date('2019-05-07T12:00:00.000Z'),
  end: new Date('2019-05-07T12:00:00.000Z'),
  progress: 0,
}, {
  id: 33,
  parentId: 1,
  title: 'Testing',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-06-13T12:00:00.000Z'),
  progress: 23,
}, {
  id: 34,
  parentId: 33,
  title: 'Develop unit test plans using product specifications',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-04-11T14:00:00.000Z'),
  progress: 100,
}, {
  id: 35,
  parentId: 33,
  title: 'Develop integration test plans using product specifications',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-04-11T14:00:00.000Z'),
  progress: 100,
}, {
  id: 36,
  parentId: 33,
  title: 'Unit Testing',
  start: new Date('2019-05-07T12:00:00.000Z'),
  end: new Date('2019-05-28T12:00:00.000Z'),
  progress: 0,
}, {
  id: 37,
  parentId: 36,
  title: 'Review modular code',
  start: new Date('2019-05-07T12:00:00.000Z'),
  end: new Date('2019-05-14T12:00:00.000Z'),
  progress: 0,
}, {
  id: 38,
  parentId: 36,
  title: 'Test component modules to product specifications',
  start: new Date('2019-05-14T12:00:00.000Z'),
  end: new Date('2019-05-16T12:00:00.000Z'),
  progress: 0,
}, {
  id: 39,
  parentId: 36,
  title: 'Identify anomalies to product specifications',
  start: new Date('2019-05-16T12:00:00.000Z'),
  end: new Date('2019-05-21T12:00:00.000Z'),
  progress: 0,
}, {
  id: 40,
  parentId: 36,
  title: 'Modify code',
  start: new Date('2019-05-21T12:00:00.000Z'),
  end: new Date('2019-05-24T12:00:00.000Z'),
  progress: 0,
}, {
  id: 41,
  parentId: 36,
  title: 'Re-test modified code',
  start: new Date('2019-05-24T12:00:00.000Z'),
  end: new Date('2019-05-28T12:00:00.000Z'),
  progress: 0,
}, {
  id: 42,
  parentId: 36,
  title: 'Unit testing complete',
  start: new Date('2019-05-28T12:00:00.000Z'),
  end: new Date('2019-05-28T12:00:00.000Z'),
  progress: 0,
}, {
  id: 43,
  parentId: 33,
  title: 'Integration Testing',
  start: new Date('2019-05-28T12:00:00.000Z'),
  end: new Date('2019-06-13T12:00:00.000Z'),
  progress: 0,
}, {
  id: 44,
  parentId: 43,
  title: 'Test module integration',
  start: new Date('2019-05-28T12:00:00.000Z'),
  end: new Date('2019-06-04T12:00:00.000Z'),
  progress: 0,
}, {
  id: 45,
  parentId: 43,
  title: 'Identify anomalies to specifications',
  start: new Date('2019-06-04T12:00:00.000Z'),
  end: new Date('2019-06-06T12:00:00.000Z'),
  progress: 0,
}, {
  id: 46,
  parentId: 43,
  title: 'Modify code',
  start: new Date('2019-06-06T12:00:00.000Z'),
  end: new Date('2019-06-11T12:00:00.000Z'),
  progress: 0,
}, {
  id: 47,
  parentId: 43,
  title: 'Re-test modified code',
  start: new Date('2019-06-11T12:00:00.000Z'),
  end: new Date('2019-06-13T12:00:00.000Z'),
  progress: 0,
}, {
  id: 48,
  parentId: 43,
  title: 'Integration testing complete',
  start: new Date('2019-06-13T12:00:00.000Z'),
  end: new Date('2019-06-13T12:00:00.000Z'),
  progress: 0,
}, {
  id: 49,
  parentId: 1,
  title: 'Training',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-06-10T12:00:00.000Z'),
  progress: 25,
}, {
  id: 50,
  parentId: 49,
  title: 'Develop training specifications for end users',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-04-10T14:00:00.000Z'),
  progress: 100,
}, {
  id: 51,
  parentId: 49,
  title: 'Develop training specifications for helpdesk support staff',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-04-10T14:00:00.000Z'),
  progress: 100,
}, {
  id: 52,
  parentId: 49,
  title: 'Identify training delivery methodology (computer based training, classroom, etc.)',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-04-09T14:00:00.000Z'),
  progress: 100,
}, {
  id: 53,
  parentId: 49,
  title: 'Develop training materials',
  start: new Date('2019-05-07T12:00:00.000Z'),
  end: new Date('2019-05-28T12:00:00.000Z'),
  progress: 0,
}, {
  id: 54,
  parentId: 49,
  title: 'Conduct training usability study',
  start: new Date('2019-05-28T12:00:00.000Z'),
  end: new Date('2019-06-03T12:00:00.000Z'),
  progress: 0,
}, {
  id: 55,
  parentId: 49,
  title: 'Finalize training materials',
  start: new Date('2019-06-03T12:00:00.000Z'),
  end: new Date('2019-06-06T12:00:00.000Z'),
  progress: 0,
}, {
  id: 56,
  parentId: 49,
  title: 'Develop training delivery mechanism',
  start: new Date('2019-06-06T12:00:00.000Z'),
  end: new Date('2019-06-10T12:00:00.000Z'),
  progress: 0,
}, {
  id: 57,
  parentId: 49,
  title: 'Training materials complete',
  start: new Date('2019-06-10T12:00:00.000Z'),
  end: new Date('2019-06-10T12:00:00.000Z'),
  progress: 0,
}, {
  id: 58,
  parentId: 1,
  title: 'Documentation',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-05-20T09:00:00.000Z'),
  progress: 0,
}, {
  id: 59,
  parentId: 58,
  title: 'Develop Help specification',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-04-08T14:00:00.000Z'),
  progress: 80,
}, {
  id: 60,
  parentId: 58,
  title: 'Develop Help system',
  start: new Date('2019-04-22T10:00:00.000Z'),
  end: new Date('2019-05-13T09:00:00.000Z'),
  progress: 0,
}, {
  id: 61,
  parentId: 58,
  title: 'Review Help documentation',
  start: new Date('2019-05-13T10:00:00.000Z'),
  end: new Date('2019-05-16T09:00:00.000Z'),
  progress: 0,
}, {
  id: 62,
  parentId: 58,
  title: 'Incorporate Help documentation feedback',
  start: new Date('2019-05-16T10:00:00.000Z'),
  end: new Date('2019-05-20T09:00:00.000Z'),
  progress: 0,
}, {
  id: 63,
  parentId: 58,
  title: 'Develop user manuals specifications',
  start: new Date('2019-04-08T05:00:00.000Z'),
  end: new Date('2019-04-09T14:00:00.000Z'),
  progress: 65,
}, {
  id: 64,
  parentId: 58,
  title: 'Develop user manuals',
  start: new Date('2019-04-22T10:00:00.000Z'),
  end: new Date('2019-05-13T09:00:00.000Z'),
  progress: 0,
}, {
  id: 65,
  parentId: 58,
  title: 'Review all user documentation',
  start: new Date('2019-05-13T10:00:00.000Z'),
  end: new Date('2019-05-15T09:00:00.000Z'),
  progress: 0,
}, {
  id: 66,
  parentId: 58,
  title: 'Incorporate user documentation feedback',
  start: new Date('2019-05-15T10:00:00.000Z'),
  end: new Date('2019-05-17T09:00:00.000Z'),
  progress: 0,
}, {
  id: 67,
  parentId: 58,
  title: 'Documentation complete',
  start: new Date('2019-05-20T09:00:00.000Z'),
  end: new Date('2019-05-20T09:00:00.000Z'),
  progress: 0,
}, {
  id: 68,
  parentId: 1,
  title: 'Pilot',
  start: new Date('2019-03-18T10:00:00.000Z'),
  end: new Date('2019-06-24T12:00:00.000Z'),
  progress: 22,
}, {
  id: 69,
  parentId: 68,
  title: 'Identify test group',
  start: new Date('2019-03-18T10:00:00.000Z'),
  end: new Date('2019-03-19T09:00:00.000Z'),
  progress: 100,
}, {
  id: 70,
  parentId: 68,
  title: 'Develop software delivery mechanism',
  start: new Date('2019-03-19T10:00:00.000Z'),
  end: new Date('2019-03-20T09:00:00.000Z'),
  progress: 100,
}, {
  id: 71,
  parentId: 68,
  title: 'Install/deploy software',
  start: new Date('2019-06-13T12:00:00.000Z'),
  end: new Date('2019-06-14T12:00:00.000Z'),
  progress: 0,
}, {
  id: 72,
  parentId: 68,
  title: 'Obtain user feedback',
  start: new Date('2019-06-14T12:00:00.000Z'),
  end: new Date('2019-06-21T12:00:00.000Z'),
  progress: 0,
}, {
  id: 73,
  parentId: 68,
  title: 'Evaluate testing information',
  start: new Date('2019-06-21T12:00:00.000Z'),
  end: new Date('2019-06-24T12:00:00.000Z'),
  progress: 0,
}, {
  id: 74,
  parentId: 68,
  title: 'Pilot complete',
  start: new Date('2019-06-24T12:00:00.000Z'),
  end: new Date('2019-06-24T12:00:00.000Z'),
  progress: 0,
}, {
  id: 75,
  parentId: 1,
  title: 'Deployment',
  start: new Date('2019-06-24T12:00:00.000Z'),
  end: new Date('2019-07-01T12:00:00.000Z'),
  progress: 0,
}, {
  id: 76,
  parentId: 75,
  title: 'Determine final deployment strategy',
  start: new Date('2019-06-24T12:00:00.000Z'),
  end: new Date('2019-06-25T12:00:00.000Z'),
  progress: 0,
}, {
  id: 77,
  parentId: 75,
  title: 'Develop deployment methodology',
  start: new Date('2019-06-25T12:00:00.000Z'),
  end: new Date('2019-06-26T12:00:00.000Z'),
  progress: 0,
}, {
  id: 78,
  parentId: 75,
  title: 'Secure deployment resources',
  start: new Date('2019-06-26T12:00:00.000Z'),
  end: new Date('2019-06-27T12:00:00.000Z'),
  progress: 0,
}, {
  id: 79,
  parentId: 75,
  title: 'Train support staff',
  start: new Date('2019-06-27T12:00:00.000Z'),
  end: new Date('2019-06-28T12:00:00.000Z'),
  progress: 0,
}, {
  id: 80,
  parentId: 75,
  title: 'Deploy software',
  start: new Date('2019-06-28T12:00:00.000Z'),
  end: new Date('2019-07-01T12:00:00.000Z'),
  progress: 0,
}, {
  id: 81,
  parentId: 75,
  title: 'Deployment complete',
  start: new Date('2019-07-01T12:00:00.000Z'),
  end: new Date('2019-07-01T12:00:00.000Z'),
  progress: 0,
}, {
  id: 82,
  parentId: 1,
  title: 'Post Implementation Review',
  start: new Date('2019-07-01T12:00:00.000Z'),
  end: new Date('2019-07-04T12:00:00.000Z'),
  progress: 0,
}, {
  id: 83,
  parentId: 82,
  title: 'Document lessons learned',
  start: new Date('2019-07-01T12:00:00.000Z'),
  end: new Date('2019-07-02T12:00:00.000Z'),
  progress: 0,
}, {
  id: 84,
  parentId: 82,
  title: 'Distribute to team members',
  start: new Date('2019-07-02T12:00:00.000Z'),
  end: new Date('2019-07-03T12:00:00.000Z'),
  progress: 0,
}, {
  id: 85,
  parentId: 82,
  title: 'Create software maintenance team',
  start: new Date('2019-07-03T12:00:00.000Z'),
  end: new Date('2019-07-04T12:00:00.000Z'),
  progress: 0,
}, {
  id: 86,
  parentId: 82,
  title: 'Post implementation review complete',
  start: new Date('2019-07-04T12:00:00.000Z'),
  end: new Date('2019-07-04T12:00:00.000Z'),
  progress: 0,
}, {
  id: 87,
  parentId: 1,
  title: 'Software development template complete',
  start: new Date('2019-07-04T12:00:00.000Z'),
  end: new Date('2019-07-04T12:00:00.000Z'),
  progress: 0,
}];

export const dependencies = [{
  id: 1,
  predecessorId: 3,
  successorId: 4,
  type: 0,
}, {
  id: 2,
  predecessorId: 4,
  successorId: 5,
  type: 0,
}, {
  id: 3,
  predecessorId: 5,
  successorId: 6,
  type: 0,
}, {
  id: 4,
  predecessorId: 6,
  successorId: 7,
  type: 0,
}, {
  id: 5,
  predecessorId: 7,
  successorId: 9,
  type: 0,
}, {
  id: 6,
  predecessorId: 9,
  successorId: 10,
  type: 0,
}, {
  id: 7,
  predecessorId: 10,
  successorId: 11,
  type: 0,
}, {
  id: 8,
  predecessorId: 11,
  successorId: 12,
  type: 0,
}, {
  id: 9,
  predecessorId: 12,
  successorId: 13,
  type: 0,
}, {
  id: 10,
  predecessorId: 13,
  successorId: 14,
  type: 0,
}, {
  id: 11,
  predecessorId: 14,
  successorId: 15,
  type: 0,
}, {
  id: 12,
  predecessorId: 15,
  successorId: 16,
  type: 0,
}, {
  id: 13,
  predecessorId: 16,
  successorId: 17,
  type: 0,
}, {
  id: 14,
  predecessorId: 17,
  successorId: 19,
  type: 0,
}, {
  id: 15,
  predecessorId: 19,
  successorId: 20,
  type: 0,
}, {
  id: 16,
  predecessorId: 20,
  successorId: 21,
  type: 0,
}, {
  id: 17,
  predecessorId: 21,
  successorId: 22,
  type: 0,
}, {
  id: 18,
  predecessorId: 22,
  successorId: 23,
  type: 0,
}, {
  id: 19,
  predecessorId: 23,
  successorId: 24,
  type: 0,
}, {
  id: 20,
  predecessorId: 24,
  successorId: 25,
  type: 0,
}, {
  id: 21,
  predecessorId: 25,
  successorId: 27,
  type: 0,
}, {
  id: 22,
  predecessorId: 27,
  successorId: 28,
  type: 0,
}, {
  id: 23,
  predecessorId: 28,
  successorId: 29,
  type: 0,
}, {
  id: 24,
  predecessorId: 29,
  successorId: 30,
  type: 0,
}, {
  id: 25,
  predecessorId: 31,
  successorId: 32,
  type: 0,
}, {
  id: 26,
  predecessorId: 37,
  successorId: 38,
  type: 0,
}, {
  id: 27,
  predecessorId: 38,
  successorId: 39,
  type: 0,
}, {
  id: 28,
  predecessorId: 39,
  successorId: 40,
  type: 0,
}, {
  id: 29,
  predecessorId: 40,
  successorId: 41,
  type: 0,
}, {
  id: 30,
  predecessorId: 41,
  successorId: 42,
  type: 0,
}, {
  id: 31,
  predecessorId: 42,
  successorId: 44,
  type: 0,
}, {
  id: 32,
  predecessorId: 44,
  successorId: 45,
  type: 0,
}, {
  id: 33,
  predecessorId: 45,
  successorId: 46,
  type: 0,
}, {
  id: 34,
  predecessorId: 46,
  successorId: 47,
  type: 0,
}, {
  id: 35,
  predecessorId: 47,
  successorId: 48,
  type: 0,
}, {
  id: 36,
  predecessorId: 53,
  successorId: 54,
  type: 0,
}, {
  id: 37,
  predecessorId: 54,
  successorId: 55,
  type: 0,
}, {
  id: 38,
  predecessorId: 55,
  successorId: 56,
  type: 0,
}, {
  id: 39,
  predecessorId: 56,
  successorId: 57,
  type: 0,
}, {
  id: 40,
  predecessorId: 59,
  successorId: 60,
  type: 0,
}, {
  id: 41,
  predecessorId: 60,
  successorId: 61,
  type: 0,
}, {
  id: 42,
  predecessorId: 61,
  successorId: 62,
  type: 0,
}, {
  id: 43,
  predecessorId: 63,
  successorId: 64,
  type: 0,
}, {
  id: 44,
  predecessorId: 64,
  successorId: 65,
  type: 0,
}, {
  id: 45,
  predecessorId: 65,
  successorId: 66,
  type: 0,
}, {
  id: 46,
  predecessorId: 66,
  successorId: 67,
  type: 0,
}, {
  id: 47,
  predecessorId: 69,
  successorId: 70,
  type: 0,
}, {
  id: 48,
  predecessorId: 70,
  successorId: 71,
  type: 0,
}, {
  id: 49,
  predecessorId: 71,
  successorId: 72,
  type: 0,
}, {
  id: 50,
  predecessorId: 72,
  successorId: 73,
  type: 0,
}, {
  id: 51,
  predecessorId: 73,
  successorId: 74,
  type: 0,
}, {
  id: 52,
  predecessorId: 74,
  successorId: 76,
  type: 0,
}, {
  id: 53,
  predecessorId: 76,
  successorId: 77,
  type: 0,
}, {
  id: 54,
  predecessorId: 77,
  successorId: 78,
  type: 0,
}, {
  id: 55,
  predecessorId: 78,
  successorId: 79,
  type: 0,
}, {
  id: 56,
  predecessorId: 79,
  successorId: 80,
  type: 0,
}, {
  id: 57,
  predecessorId: 80,
  successorId: 81,
  type: 0,
}, {
  id: 58,
  predecessorId: 81,
  successorId: 83,
  type: 0,
}, {
  id: 59,
  predecessorId: 83,
  successorId: 84,
  type: 0,
}, {
  id: 60,
  predecessorId: 84,
  successorId: 85,
  type: 0,
}, {
  id: 61,
  predecessorId: 85,
  successorId: 86,
  type: 0,
}, {
  id: 62,
  predecessorId: 86,
  successorId: 87,
  type: 0,
}];

export const resources = [{
  id: 1,
  text: 'Management',
}, {
  id: 2,
  text: 'Project Manager',
}, {
  id: 3,
  text: 'Analyst',
}, {
  id: 4,
  text: 'Developer',
}, {
  id: 5,
  text: 'Testers',
}, {
  id: 6,
  text: 'Trainers',
}, {
  id: 7,
  text: 'Technical Communicators',
}, {
  id: 8,
  text: 'Deployment Team',
}];

export const resourceAssignments = [{
  id: 0,
  taskId: 3,
  resourceId: 1,
}, {
  id: 1,
  taskId: 4,
  resourceId: 1,
}, {
  id: 2,
  taskId: 5,
  resourceId: 2,
}, {
  id: 3,
  taskId: 6,
  resourceId: 2,
}, {
  id: 4,
  taskId: 9,
  resourceId: 3,
}, {
  id: 5,
  taskId: 10,
  resourceId: 3,
}, {
  id: 6,
  taskId: 11,
  resourceId: 2,
}, {
  id: 7,
  taskId: 12,
  resourceId: 2,
}, {
  id: 8,
  taskId: 12,
  resourceId: 3,
}, {
  id: 9,
  taskId: 13,
  resourceId: 3,
}, {
  id: 10,
  taskId: 14,
  resourceId: 2,
}, {
  id: 11,
  taskId: 15,
  resourceId: 1,
}, {
  id: 12,
  taskId: 15,
  resourceId: 2,
}, {
  id: 13,
  taskId: 16,
  resourceId: 2,
}, {
  id: 14,
  taskId: 19,
  resourceId: 3,
}, {
  id: 15,
  taskId: 20,
  resourceId: 3,
}, {
  id: 16,
  taskId: 21,
  resourceId: 3,
}, {
  id: 17,
  taskId: 22,
  resourceId: 1,
}, {
  id: 18,
  taskId: 23,
  resourceId: 1,
}, {
  id: 19,
  taskId: 24,
  resourceId: 1,
}, {
  id: 20,
  taskId: 24,
  resourceId: 2,
}, {
  id: 21,
  taskId: 27,
  resourceId: 4,
}, {
  id: 22,
  taskId: 28,
  resourceId: 4,
}, {
  id: 23,
  taskId: 29,
  resourceId: 4,
}, {
  id: 24,
  taskId: 30,
  resourceId: 4,
}, {
  id: 25,
  taskId: 31,
  resourceId: 4,
}, {
  id: 26,
  taskId: 34,
  resourceId: 5,
}, {
  id: 27,
  taskId: 35,
  resourceId: 5,
}, {
  id: 28,
  taskId: 37,
  resourceId: 5,
}, {
  id: 29,
  taskId: 38,
  resourceId: 5,
}, {
  id: 30,
  taskId: 39,
  resourceId: 5,
}, {
  id: 31,
  taskId: 40,
  resourceId: 5,
}, {
  id: 32,
  taskId: 41,
  resourceId: 5,
}, {
  id: 33,
  taskId: 44,
  resourceId: 5,
}, {
  id: 34,
  taskId: 45,
  resourceId: 5,
}, {
  id: 35,
  taskId: 46,
  resourceId: 5,
}, {
  id: 36,
  taskId: 47,
  resourceId: 5,
}, {
  id: 37,
  taskId: 50,
  resourceId: 6,
}, {
  id: 38,
  taskId: 51,
  resourceId: 6,
}, {
  id: 39,
  taskId: 52,
  resourceId: 6,
}, {
  id: 40,
  taskId: 53,
  resourceId: 6,
}, {
  id: 41,
  taskId: 54,
  resourceId: 6,
}, {
  id: 42,
  taskId: 55,
  resourceId: 6,
}, {
  id: 43,
  taskId: 56,
  resourceId: 6,
}, {
  id: 44,
  taskId: 59,
  resourceId: 7,
}, {
  id: 45,
  taskId: 60,
  resourceId: 7,
}, {
  id: 46,
  taskId: 61,
  resourceId: 7,
}, {
  id: 47,
  taskId: 62,
  resourceId: 7,
}, {
  id: 48,
  taskId: 63,
  resourceId: 7,
}, {
  id: 49,
  taskId: 64,
  resourceId: 7,
}, {
  id: 50,
  taskId: 65,
  resourceId: 7,
}, {
  id: 51,
  taskId: 66,
  resourceId: 7,
}, {
  id: 52,
  taskId: 69,
  resourceId: 2,
}, {
  id: 53,
  taskId: 71,
  resourceId: 8,
}, {
  id: 54,
  taskId: 72,
  resourceId: 8,
}, {
  id: 55,
  taskId: 73,
  resourceId: 8,
}, {
  id: 56,
  taskId: 76,
  resourceId: 8,
}, {
  id: 57,
  taskId: 77,
  resourceId: 8,
}, {
  id: 58,
  taskId: 78,
  resourceId: 8,
}, {
  id: 59,
  taskId: 79,
  resourceId: 8,
}, {
  id: 60,
  taskId: 80,
  resourceId: 8,
}, {
  id: 61,
  taskId: 83,
  resourceId: 2,
}, {
  id: 62,
  taskId: 84,
  resourceId: 2,
}, {
  id: 63,
  taskId: 85,
  resourceId: 2,
}];


export const productos = [{
  id: '1',
  text: 'Stores',
  expanded: true,
  items: [{
    id: '1_1',
    text: 'Super Mart of the West',
    expanded: true,
    items: [{
      id: '1_1_1',
      text: 'Video Players',
      items: [{
        id: '1_1_1_1',
        text: 'HD Video Player',
        price: 220,
      }, {
        id: '1_1_1_2',
        text: 'SuperHD Video Player',
        price: 270,
      }],
    }, {
      id: '1_1_2',
      text: 'Televisions',
      items: [{
        id: '1_1_2_1',
        text: 'SuperLCD 42',
        price: 1200,
      }, {
        id: '1_1_2_2',
        text: 'SuperLED 42',
        price: 1450,
      }, {
        id: '1_1_2_3',
        text: 'SuperLED 50',
        price: 1600,
      }, {
        id: '1_1_2_4',
        text: 'SuperLCD 55',
        price: 1350,
      }, {
        id: '1_1_2_5',
        text: 'SuperLCD 70',
        price: 4000,
      }],
    }, {
      id: '1_1_3',
      text: 'Monitors',
      items: [{
        id: '1_1_3_1',
        text: '19"',
        items: [{
          id: '1_1_3_1_1',
          text: 'DesktopLCD 19',
          price: 160,
        }],
      }, {
        id: '1_1_3_2',
        text: '21"',
        items: [{
          id: '1_1_3_2_1',
          text: 'DesktopLCD 21',
          price: 170,
        }, {
          id: '1_1_3_2_2',
          text: 'DesktopLED 21',
          price: 175,
        }],
      }],
    }, {
      id: '1_1_4',
      text: 'Projectors',
      items: [{
        id: '1_1_4_1',
        text: 'Projector Plus',
        price: 550,
      }, {
        id: '1_1_4_2',
        text: 'Projector PlusHD',
        price: 750,
      }],
    }],

  }, {
    id: '1_2',
    text: 'Braeburn',
    items: [{
      id: '1_2_1',
      text: 'Video Players',
      items: [{
        id: '1_2_1_1',
        text: 'HD Video Player',
        price: 240,
      }, {
        id: '1_2_1_2',
        text: 'SuperHD Video Player',
        price: 300,
      }],
    }, {
      id: '1_2_2',
      text: 'Televisions',
      items: [{
        id: '1_2_2_1',
        text: 'SuperPlasma 50',
        price: 1800,
      }, {
        id: '1_2_2_2',
        text: 'SuperPlasma 65',
        price: 3500,
      }],
    }, {
      id: '1_2_3',
      text: 'Monitors',
      items: [{
        id: '1_2_3_1',
        text: '19"',
        items: [{
          id: '1_2_3_1_1',
          text: 'DesktopLCD 19',
          price: 170,
        }],
      }, {
        id: '1_2_3_2',
        text: '21"',
        items: [{
          id: '1_2_3_2_1',
          text: 'DesktopLCD 21',
          price: 180,
        }, {
          id: '1_2_3_2_2',
          text: 'DesktopLED 21',
          price: 190,
        }],
      }],
    }],

  }, {
    id: '1_3',
    text: 'E-Mart',
    items: [{
      id: '1_3_1',
      text: 'Video Players',
      items: [{
        id: '1_3_1_1',
        text: 'HD Video Player',
        price: 220,
      }, {
        id: '1_3_1_2',
        text: 'SuperHD Video Player',
        price: 275,
      }],
    }, {
      id: '1_3_3',
      text: 'Monitors',
      items: [{
        id: '1_3_3_1',
        text: '19"',
        items: [{
          id: '1_3_3_1_1',
          text: 'DesktopLCD 19',
          price: 165,
        }],
      }, {
        id: '1_3_3_2',
        text: '21"',
        items: [{
          id: '1_3_3_2_1',
          text: 'DesktopLCD 21',
          price: 175,
        }],
      }],
    }],
  }, {
    id: '1_4',
    text: 'Walters',
    items: [{
      id: '1_4_1',
      text: 'Video Players',
      items: [{
        id: '1_4_1_1',
        text: 'HD Video Player',
        price: 210,
      }, {
        id: '1_4_1_2',
        text: 'SuperHD Video Player',
        price: 250,
      }],
    }, {
      id: '1_4_2',
      text: 'Televisions',
      items: [{
        id: '1_4_2_1',
        text: 'SuperLCD 42',
        price: 1100,
      }, {
        id: '1_4_2_2',
        text: 'SuperLED 42',
        price: 1400,
      }, {
        id: '1_4_2_3',
        text: 'SuperLED 50',
        price: 1500,
      }, {
        id: '1_4_2_4',
        text: 'SuperLCD 55',
        price: 1300,
      }, {
        id: '1_4_2_5',
        text: 'SuperLCD 70',
        price: 4000,
      }, {
        id: '1_4_2_6',
        text: 'SuperPlasma 50',
        price: 1700,
      }],
    }, {
      id: '1_4_3',
      text: 'Monitors',
      items: [{
        id: '1_4_3_1',
        text: '19"',
        items: [{
          id: '1_4_3_1_1',
          text: 'DesktopLCD 19',
          price: 160,
        }],
      }, {
        id: '1_4_3_2',
        text: '21"',
        items: [{
          id: '1_4_3_2_1',
          text: 'DesktopLCD 21',
          price: 170,
        }, {
          id: '1_4_3_2_2',
          text: 'DesktopLED 21',
          price: 180,
        }],
      }],
    }, {
      id: '1_4_4',
      text: 'Projectors',
      items: [{
        id: '1_4_4_1',
        text: 'Projector Plus',
        price: 550,
      }, {
        id: '1_4_4_2',
        text: 'Projector PlusHD',
        price: 750,
      }],
    }],

  }],
}];

export const movementTypes = ['Entrada', 'Salida'];

export const equipmenttypes = ['Equipo', 'Armado Equipos', 'Genérico', 'Plantilla', 'Póliza Mtto'];

export const counterItemTypes = ['Todos los artículos', 'Muestra aleatoria'];

export const areas = [{
  estatus: 'Autorizadas',
  valor: 25,
}, {
  estatus: 'En Proceso',
  valor: 25,
}, {
  estatus: 'Cerradas',
  valor: 10,
}, {
  estatus: 'Canceladas',
  valor: 40,
}];

export const gallery = [
  {
    id: '1',
    origen: 'OT',
    folio: 126,
    equipo: 'SHNJ1T-23-0020 Tablero SHNJ1T-23-0020 SHN NJ012',
    equipo_extra: 'N/S: 890982 Activo: ACT00383 Ref: HJ90983 Nivel: 1 Pos: 2',
    descripcion: 'Se presenta con mucho ruido y también le sale humo al momento de prender o cuando pasan 20 minutos de operación.',
    imagen: 'https://picsum.photos/200/300',
    fecha_hora: '12/02/2023 12:44',
    iniciales: 'ALP',
    antes_mtto: true,
    despues_mtto: false,
    cotizacion: true,
    reporte_servicio: true,
  },
  {
    id: '2',
    origen: 'RptF',
    folio: 14,
    equipo: 'SHNJ1T-23-0020 Tablero SHNJ1T-23-0020 SHN NJ012',
    equipo_extra: 'N/S: 890982 Activo: ACT00383 Ref: HJ90983 Nivel: 1 Pos: 2',
    descripcion: 'Se presenta con mucho ruido y también le sale humo al momento de prender o cuando pasan 20 minutos de operación.',
    imagen: 'https://picsum.photos/200/300',
    fecha_hora: '12/02/2023 12:44',
    iniciales: 'ALP',
    antes_mtto: true,
    despues_mtto: false,
    cotizacion: true,
    reporte_servicio: true,
  },
  {
    id: '3',
    origen: 'ChkLst',
    folio: 231,
    equipo: 'SHNJ1T-23-0020 Tablero SHNJ1T-23-0020 SHN NJ012',
    equipo_extra: 'N/S: 890982 Activo: ACT00383 Ref: HJ90983 Nivel: 1 Pos: 2',
    descripcion: 'Se presenta con mucho ruido y también le sale humo al momento de prender o cuando pasan 20 minutos de operación.',
    imagen: 'https://picsum.photos/200/300',
    fecha_hora: '12/02/2023 12:44',
    iniciales: 'ALP',
    antes_mtto: true,
    despues_mtto: false,
    cotizacion: true,
    reporte_servicio: true,
  },
  {
    id: '4',
    origen: 'Info',
    folio: null,
    equipo: 'SHNJ1T-23-0020 Tablero SHNJ1T-23-0020 SHN NJ012',
    equipo_extra: 'N/S: 890982 Activo: ACT00383 Ref: HJ90983 Nivel: 1 Pos: 2',
    descripcion: 'Se presenta con mucho ruido y también le sale humo al momento de prender o cuando pasan 20 minutos de operación.',
    imagen: 'https://picsum.photos/200/300',
    fecha_hora: '12/02/2023 12:44',
    iniciales: 'ALP',
    antes_mtto: true,
    despues_mtto: false,
    cotizacion: true,
    reporte_servicio: true,
  },
  {
    id: '5',
    origen: 'OT',
    folio: 126,
    equipo: 'SHNJ1T-23-0020 Tablero SHNJ1T-23-0020 SHN NJ012',
    equipo_extra: 'N/S: 890982 Activo: ACT00383 Ref: HJ90983 Nivel: 1 Pos: 2',
    descripcion: 'Se presenta con mucho ruido y también le sale humo al momento de prender o cuando pasan 20 minutos de operación.',
    imagen: 'https://picsum.photos/200/300',
    fecha_hora: '12/02/2023 12:44',
    iniciales: 'ALP',
    antes_mtto: true,
    despues_mtto: false,
    cotizacion: true,
    reporte_servicio: true,
  },
  {
    id: '6',
    origen: 'RptF',
    folio: 14,
    equipo: 'SHNJ1T-23-0020 Tablero SHNJ1T-23-0020 SHN NJ012',
    equipo_extra: 'N/S: 890982 Activo: ACT00383 Ref: HJ90983 Nivel: 1 Pos: 2',
    descripcion: 'Se presenta con mucho ruido y también le sale humo al momento de prender o cuando pasan 20 minutos de operación.',
    imagen: 'https://picsum.photos/200/300',
    fecha_hora: '12/02/2023 12:44',
    iniciales: 'ALP',
    antes_mtto: true,
    despues_mtto: false,
    cotizacion: true,
    reporte_servicio: true,
  },
  {
    id: '7',
    origen: 'ChkLst',
    folio: 231,
    equipo: 'SHNJ1T-23-0020 Tablero SHNJ1T-23-0020 SHN NJ012',
    equipo_extra: 'N/S: 890982 Activo: ACT00383 Ref: HJ90983 Nivel: 1 Pos: 2',
    descripcion: 'Se presenta con mucho ruido y también le sale humo al momento de prender o cuando pasan 20 minutos de operación.',
    imagen: 'https://picsum.photos/200/300',
    fecha_hora: '12/02/2023 12:44',
    iniciales: 'ALP',
    antes_mtto: true,
    despues_mtto: false,
    cotizacion: true,
    reporte_servicio: true,
  },
  {
    id: '8',
    origen: 'Info',
    folio: null,
    equipo: 'SHNJ1T-23-0020 Tablero SHNJ1T-23-0020 SHN NJ012',
    equipo_extra: 'N/S: 890982 Activo: ACT00383 Ref: HJ90983 Nivel: 1 Pos: 2',
    descripcion: 'Se presenta con mucho ruido y también le sale humo al momento de prender o cuando pasan 20 minutos de operación.',
    imagen: 'https://picsum.photos/200/300',
    fecha_hora: '12/02/2023 12:44',
    iniciales: 'ALP',
    antes_mtto: true,
    despues_mtto: false,
    cotizacion: true,
    reporte_servicio: true,
  }
];
  

export const tasks = [
  {
    text: 'SCIJCK1 Sistema contra Incendio Jockey Diesel',
  }, {
    text: 'B3V1002AM30G33 Hidroneumatico Triplex a VV',
  },
];

export const appointments = [
  {
    text: 'Mtto Correctivo Workin Bomba T6XE',
    startDate: new Date('2022-07-30T13:00:00.000Z'),
    endDate: new Date('2022-07-30T15:00:00.000Z'),
    allDay: false,
  },
];

export const resourcesList = ['Estatus', 'Tipo', 'Prioridad', 'Asignado'];

export const statusLegend = [
  {
    id: '1',
    text: 'Nueva',
    color: '#245E61'
  },
  {
    id: '2',
    text: 'Autorizada',
    color: '#214182'
  },
  {
    id: '3',
    text: 'En proceso',
    color: '#21823E'
  },
  {
    id: '4',
    text: 'En pausa',
    color: '#C27F21'
  },
  {
    id: '5',
    text: 'Cerrada',
    color: '#777777'
  },
  {
    id: '6',
    text: 'Cancelada',
    color: '#732424'
  },
];

export const jobtitles = [
{
  idPuesto: 1,
  nombre: 'Gerente de Mantenimiento',
  activo: true,
},
{
  idPuesto: 2,
  nombre: 'Supervidor de Mantenimiento',
  activo: true,
},
{
  idPuesto: 3,
  nombre: 'Guardia de Seguridad',
  activo: true,
},
{
  idPuesto: 4,
  nombre: 'Técnico en Mantenimiento',
  activo: true,
}];

export const businesses = [
  {
    idEmpresa: 1,
    clave: '001',
    nombre: 'Edificio MX3000',
    activo: true,
  },
  {
    idEmpresa: 2,
    clave: '002',
    nombre: 'Torres Babilon X',
    activo: true,
  },
];

export const companies = [
  {
    id_empresa: 1,
    clave: '001',
    nombre: 'Edificio MX3000',
    activo: true,
  },
  {
    id_empresa: 2,
    clave: '002',
    nombre: 'Torres Babilon X',
    activo: true,
  },
];

export const warehouses = [
  {
    idAlmacen: 1,
    clave: 'REF01',
    nombre: 'Refacciones',
    activo: true,
  },
  {
    idAlmacen: 2,
    clave: 'CON01',    
    nombre: 'Consumibles',
    activo: true,
  },
  {
    idAlmacen: 3,
    clave: 'HER01',    
    nombre: 'Herramientas',
    activo: true,
  },
];

export const clients = [
  {
    idCliente: 1,
    nombreComercial: 'ACME',
    razonSocial: 'ACME SA DE CV',
    rfc: 'ACM701212XG5',
    logotipo: '',
    vialidad: 'Ave. Juárez',
    numeroExterior: '14',
    numeroInterior: '',
    asentamiento: 'Col. Mirador',
    municipio: 'Guadalajara',
    idPais: 1,
    latitud: 26.344595675394523,
    longitud: -103.15847657960578,
    usoCfdi: '03',
    activo: true,
    fechaReg: '2022-01-01 10:30:25'
  },
  
];

export const countries = [
  {
    idPais: 1,
    nombre: 'México',
  },
  {
    idPais: 2,
    nombre: 'Estados Unidos',
  },
  {
    idPais: 3,
    nombre: 'Canadá',
  },
];

export const unitsofmeasurements = [
  {
    idUnidadMedida: 1,
    nombre: 'Grados Centígrados',
    clave: 'C',
    activo: true,
    planMantenimiento: false,
  },
  {
    idUnidadMedida: 2,
    nombre: 'Milímetros',
    clave: 'mm',
    activo: true,
    planMantenimiento: false,
  },
  {
    idUnidadMedida: 3,
    nombre: 'Metros Cúbicos',
    clave: 'm3',
    activo: true,
    planMantenimiento: false,
  },
  {
    idUnidadMedida: 4,
    nombre: 'Piezas',
    clave: 'Pza',
    activo: true,
    planMantenimiento: false,
  },
];


export const employees = [{
    ID: 1,
    FirstName: 'John',
    LastName: 'Heart',
    Prefix: 'Mr.',
    Position: 'CEO',
    BirthDate: '1964/03/16',
    HireDate: '1995/01/15',
    Notes: 'John has been in the Audio/Video industry since 1990. He has led DevAv as its CEO since 2003.\r\n\r\nWhen not working hard as the CEO, John loves to golf and bowl. He once bowled a perfect game of 300.',
    Address: '351 S Hill St.',
    StateID: 5,
  }, {
    ID: 2,
    FirstName: 'Olivia',
    LastName: 'Peyton',
    Prefix: 'Mrs.',
    Position: 'Sales Assistant',
    BirthDate: '1981/06/03',
    HireDate: '2012/05/14',
    Notes: 'Olivia loves to sell. She has been selling DevAV products since 2012. \r\n\r\nOlivia was homecoming queen in high school. She is expecting her first child in 6 months. Good Luck Olivia.',
    Address: '807 W Paseo Del Mar',
    StateID: 5,
  }, {
    ID: 3,
    FirstName: 'Robert',
    LastName: 'Reagan',
    Prefix: 'Mr.',
    Position: 'CMO',
    BirthDate: '1974/09/07',
    HireDate: '2002/11/08',
    Notes: 'Robert was recently voted the CMO of the year by CMO Magazine. He is a proud member of the DevAV Management Team.\r\n\r\nRobert is a championship BBQ chef, so when you get the chance ask him for his secret recipe.',
    Address: '4 Westmoreland Pl.',
    StateID: 4,
  }, {
    ID: 4,
    FirstName: 'Greta',
    LastName: 'Sims',
    Prefix: 'Ms.',
    Position: 'HR Manager',
    BirthDate: '1977/11/22',
    HireDate: '1998/04/23',
    Notes: "Greta has been DevAV's HR Manager since 2003. She joined DevAV from Sonee Corp.\r\n\r\nGreta is currently training for the NYC marathon. Her best marathon time is 4 hours. Go Greta.",
    Address: '1700 S Grandview Dr.',
    StateID: 11,
  }, {
    ID: 5,
    FirstName: 'Brett',
    LastName: 'Wade',
    Prefix: 'Mr.',
    Position: 'IT Manager',
    BirthDate: '1968/12/01',
    HireDate: '2009/03/06',
    Notes: 'Brett came to DevAv from Microsoft and has led our IT department since 2012.\r\n\r\nWhen he is not working hard for DevAV, he coaches Little League (he was a high school pitcher).',
    Address: '1120 Old Mill Rd.',
    StateID: 13,
  }, {
    ID: 6,
    FirstName: 'Sandra',
    LastName: 'Johnson',
    Prefix: 'Mrs.',
    Position: 'Controller',
    BirthDate: '1974/11/15',
    HireDate: '2005/05/11',
    Notes: "Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you've not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.",
    Address: '4600 N Virginia Rd.',
    StateID: 44,
  }, {
    ID: 7,
    FirstName: 'Kevin',
    LastName: 'Carter',
    Prefix: 'Mr.',
    Position: 'Shipping Manager',
    BirthDate: '1978/01/09',
    HireDate: '2009/08/11',
    Notes: 'Kevin is our hard-working shipping manager and has been helping that department work like clockwork for 18 months.\r\n\r\nWhen not in the office, he is usually on the basketball court playing pick-up games.',
    Address: '424 N Main St.',
    StateID: 5,
  }, {
    ID: 8,
    FirstName: 'Cynthia',
    LastName: 'Stanwick',
    Prefix: 'Ms.',
    Position: 'HR Assistant',
    BirthDate: '1985/06/05',
    HireDate: '2008/03/24',
    Notes: 'Cindy joined us in 2008 and has been in the HR department for 2 years. \r\n\r\nShe was recently awarded employee of the month. Way to go Cindy!',
    Address: '2211 Bonita Dr.',
    StateID: 4,
  }, {
    ID: 9,
    FirstName: 'Kent',
    LastName: 'Samuelson',
    Prefix: 'Dr.',
    Position: 'Ombudsman',
    BirthDate: '1972/09/11',
    HireDate: '2009/04/22',
    Notes: 'As our ombudsman, Kent is on the front-lines solving customer problems and helping our partners address issues out in the field.    He is a classically trained musician and is a member of the Chamber Orchestra.',
    Address: '12100 Mora Dr',
    StateID: 26,
  }, {
    ID: 10,
    FirstName: 'Taylor',
    LastName: 'Riley',
    Prefix: 'Mr.',
    Position: 'Network Admin',
    BirthDate: '1982/08/14',
    HireDate: '2012/04/14',
    Notes: "If you are like the rest of us at DevAV, then you've probably reached out for help from Taylor. He does a great job as a member of our IT department.",
    Address: '7776 Torreyson Dr',
    StateID: 5,
  }, {
    ID: 11,
    FirstName: 'Sam',
    LastName: 'Hill',
    Prefix: 'Mr.',
    Position: 'Sales Assistant',
    BirthDate: '1984/02/17',
    HireDate: '2012/02/01',
    Notes: 'Sammy is proud to be a member of the DevAV team. He joined the team in 2012 and has been in the sales department from the beginning.\r\n\r\nHe has just picked up golf so you can find him on the links every weekend.',
    Address: '645 Prospect Crescent',
    StateID: 11,
  }, {
    ID: 12,
    FirstName: 'Kelly',
    LastName: 'Rodriguez',
    Prefix: 'Ms.',
    Position: 'Support Assistant',
    BirthDate: '1988/05/11',
    HireDate: '2012/10/13',
    Notes: "Kelly loves people and that's why she joined DevAV's support department. One of the funniest people in the company, she does stand-up on the weekends at the Laugh Factory.",
    Address: '1601 W Mountain St.',
    StateID: 5,
  }, {
    ID: 13,
    FirstName: 'Natalie',
    LastName: 'Maguirre',
    Prefix: 'Mrs.',
    Position: 'Trainer',
    BirthDate: '1977/10/07',
    HireDate: '2008/06/19',
    Notes: 'Natalie travels the US and teaches our partners how to explain the benefits of our products to customers.\r\n\r\nShe is a proud wife and mom and volunteers her time at the elementary school.',
    Address: '6400 E Bixby Hill Rd',
    StateID: 29,
  }, {
    ID: 14,
    FirstName: 'Walter',
    LastName: 'Hobbs',
    Prefix: 'Mr.',
    Position: 'Programmer',
    BirthDate: '1984/12/24',
    HireDate: '2011/02/17',
    Notes: "Walter has been developing apps and websites for DevAV since 2011. His passion is software and if you ever walk by his desk, you'll know why.\r\n\r\nWally once worked 72 hours straight - writing code and fixing bugs.",
    Address: '10385 Shadow Oak Dr',
    StateID: 13,
  }];
  
  export const states = [{
    ID: 1,
    Name: 'Alabama',
  }, {
    ID: 2,
    Name: 'Alaska',
  }, {
    ID: 3,
    Name: 'Arizona',
  }, {
    ID: 4,
    Name: 'Arkansas',
  }, {
    ID: 5,
    Name: 'California',
  }, {
    ID: 6,
    Name: 'Colorado',
  }, {
    ID: 7,
    Name: 'Connecticut',
  }, {
    ID: 8,
    Name: 'Delaware',
  }, {
    ID: 9,
    Name: 'District of Columbia',
  }, {
    ID: 10,
    Name: 'Florida',
  }, {
    ID: 11,
    Name: 'Georgia',
  }, {
    ID: 12,
    Name: 'Hawaii',
  }, {
    ID: 13,
    Name: 'Idaho',
  }, {
    ID: 14,
    Name: 'Illinois',
  }, {
    ID: 15,
    Name: 'Indiana',
  }, {
    ID: 16,
    Name: 'Iowa',
  }, {
    ID: 17,
    Name: 'Kansas',
  }, {
    ID: 18,
    Name: 'Kentucky',
  }, {
    ID: 19,
    Name: 'Louisiana',
  }, {
    ID: 20,
    Name: 'Maine',
  }, {
    ID: 21,
    Name: 'Maryland',
  }, {
    ID: 22,
    Name: 'Massachusetts',
  }, {
    ID: 23,
    Name: 'Michigan',
  }, {
    ID: 24,
    Name: 'Minnesota',
  }, {
    ID: 25,
    Name: 'Mississippi',
  }, {
    ID: 26,
    Name: 'Missouri',
  }, {
    ID: 27,
    Name: 'Montana',
  }, {
    ID: 28,
    Name: 'Nebraska',
  }, {
    ID: 29,
    Name: 'Nevada',
  }, {
    ID: 30,
    Name: 'New Hampshire',
  }, {
    ID: 31,
    Name: 'New Jersey',
  }, {
    ID: 32,
    Name: 'New Mexico',
  }, {
    ID: 33,
    Name: 'New York',
  }, {
    ID: 34,
    Name: 'North Carolina',
  }, {
    ID: 35,
    Name: 'Ohio',
  }, {
    ID: 36,
    Name: 'Oklahoma',
  }, {
    ID: 37,
    Name: 'Oregon',
  }, {
    ID: 38,
    Name: 'Pennsylvania',
  }, {
    ID: 39,
    Name: 'Rhode Island',
  }, {
    ID: 40,
    Name: 'South Carolina',
  }, {
    ID: 41,
    Name: 'South Dakota',
  }, {
    ID: 42,
    Name: 'Tennessee',
  }, {
    ID: 43,
    Name: 'Texas',
  }, {
    ID: 44,
    Name: 'Utah',
  }, {
    ID: 45,
    Name: 'Vermont',
  }, {
    ID: 46,
    Name: 'Virginia',
  }, {
    ID: 47,
    Name: 'Washington',
  }, {
    ID: 48,
    Name: 'West Virginia',
  }, {
    ID: 49,
    Name: 'Wisconsin',
  }, {
    ID: 50,
    Name: 'Wyoming',
  }, {
    ID: 51,
    Name: 'North Dakota',
  }];

  export const assignees = [{
    text: 'Juan Hernández',
    id: 1,
    color: '#22AFE1',
  }, {
    text: 'Luis Carlos Macías',
    id: 2,
    color: '#32c9ed',
  }, {
    text: 'Esteban López',
    id: 3,
    color: '#1BB31A',
  }, {
    text: 'Miguel Treviño',
    id: 4,
    color: '#AFAFAF',
  }];
  
  export const priorities = [{
    text: 'Normal',
    id: 1,
    color: '#B37F25',
  }, {
    text: 'Alta',
    id: 2,
    color: '#D6521A',
  }, {
    text: 'Urgente',
    id: 3,
    color: '#9C1616',
  }];

  export const statuses = [
    {
      text: 'Planeada',
      id: 1,
      color: '#22AFE1',
    }, {
      text: 'Autorizada',
      id: 2,
      color: '#1BB31A',
    }, {
      text: 'En Proceso',
      id: 3,
      color: '#F2A220',
    }, {
      text: 'Terminada',
      id: 4,
      color: '#AFAFAF',
    }, {
      text: 'Cancelada',
      id: 5,
      color: '#801919',
    },
  ];

  export const types = [
    {
      text: 'Preventivo',
      id: 1,
      color: '#1BB31A',
    }, {
      text: 'Correctivo',
      id: 2,
      color: '#DC0D0D',
    }, {
      text: 'Predictivo',
      id: 3,
      color: '#F2A220',
    }, {
      text: 'Condicional',
      id: 4,
      color: '#22AFE1',
    }, {
      text: 'Oportunidad',
      id: 5,
      color: '#E9CF19',
    },
  ];

  
  export const data = [
    {
      text: 'OT 0001 Mantenimiento Preventivo',
      startDate: new Date('2023-05-31T09:00:00.000'),
      endDate: new Date('2023-05-31T11:30:00.000'),
      assigneeId: 3,
      priorityId: 1,
      statusId: 3,
      typeId: 1,
    },
    {
      text: 'OT 0002 Mantenimiento Preventivo',
      startDate: new Date('2023-06-30T09:00:00.000'),
      endDate: new Date('2023-06-30T16:00:00.000'),
      assigneeId: 3,
      priorityId: 1,
      statusId: 3,
      typeId: 1,
    }, {
      text: 'OT 0003 Mantenimiento Correctivo',
      startDate: new Date('2023-06-11T08:00:00.000'),
      endDate: new Date('2022-06-11T10:00:00.000'),
      assigneeId: 3,
      priorityId: 1,
      statusId: 3,
      typeId: 1,
    }, {
      text: 'OT 0004 Mtto Predictivo',
      startDate: new Date('2023-07-29T10:30:00.000'),
      endDate: new Date('2023-07-29T12:30:00.000'),
      assigneeId: 1,
      priorityId: 2,
      statusId: 2,
      typeId: 3,
    }, {
      text: 'OT 0005 Mtto Preventivo Póliza 1442',
      startDate: new Date('2023-08-30T14:00:00.000'),
      endDate: new Date('2023-08-30T18:00:00.000'),
      assigneeId: 3,
      priorityId: 1,
      statusId: 3,
      typeId: 1,
    }
  ];

  export const bugs = [
    {
      idReporteFalla: 1,
      folio: 264,
      fecha: '2022-01-01',
      tipoFalla: 'Desgaste Natural',
      titulo: 'Gotera en tubería de drenaje. Estacinamiento Nivel 2C',
      descripcion: 'Hay un charco bastante grande de mal aspecto. Los clientes le sacan la vuelta.',
      equipo: 'BARH-01 Bomba de recirculación',
      ordenTrabajo: '83',
      estatus: 'Pendiente',
      usuario: 'JGG',
      fechaReg: '2022-01-01 10:30:25'
    },
    {
      idReporteFalla: 2,
      folio: 285,
      fecha: '2022-01-16',
      tipoFalla: 'Desgaste Natural',
      titulo: 'Gotera en tubería de drenaje. Estacinamiento Nivel 2C',
      descripcion: 'Hay un charco bastante grande de mal aspecto. Los clientes le sacan la vuelta.',
      equipo: 'BARH-01 Bomba de recirculación',
      ordenTrabajo: '83',
      estatus: 'Pendiente',
      usuario: 'JGG',
      fechaReg: '2022-01-01 10:30:25'
    },
  ];

  export const markersData = [{
    location: [25.692127330811697, -100.13394299657016],
    tooltip: {
      text: 'JGG 22 feb 2022 8:16 AM',
    },
  }, {
    location: [25.69152491051239, -100.13076686727169],
    tooltip: {
      text: 'JGG 22 feb 2022 9:21 AM',
    },
  }, {
    location: { lat: 25.69093289890423, lng: -100.13204202198011 },
    tooltip: {
      text: 'JGG 22 feb 2022 11:38 AM',
    },
  }, {
    location: [25.69185631231703, -100.12854987442026],
    tooltip: {
      text: 'MJE 22 feb 2022 09:12 AM',
    },
  }];
  
  export const routesData = [{
    weight: 4,
    color: 'blue',
    opacity: 0.5,
    mode: 'walking',
    locations: [
      [25.692127330811697, -100.13394299657016],
      [25.69093289890423, -100.13204202198011],
      [25.69185631231703, -100.12854987442026],
    ],
  }];

  export const buildingData3 = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-121.969564, 37.374498],
            [-131.969564,38.37446],
            [-141.970011,39.37446],
            [-151.970457,40.37446],
            [-161.970457,41.374409],
          ]]
        }
      }
    ]
  };

  export const buildingData2 = {
    type: 'FeatureCollection',
    features: [
      { 
        type: 'Feature', 
        geometry: { 
          type: 'Polygon', 
          coordinates: [[
            [-121.969564, 37.374498],
            [-121.969564,37.37446],
            [-121.970011,37.37446],
            [-121.970457,37.37446],
            [-121.970457,37.374409],
            [-121.970457,37.374357],
            [-121.971305,37.374357],
            [-121.972152,37.374357],
            [-121.972152,37.374248],
            [-121.972152,37.374138],
            [-121.972088,37.374138],
            [-121.972023,37.374138],
            [-121.972023,37.374054],
            [-121.972023,37.37397],
            [-121.97214,37.373969],
            [-121.972257,37.373968],
            [-121.97214,37.373968],
            [-121.972103,37.373967],
            [-121.972053,37.373967],
            [-121.972034,37.373966],
            [-121.972028,37.373965],
            [-121.972025,37.373963],
            [-121.972024,37.373961],
            [-121.972023,37.37396],
            [-121.972024,37.373959],
            [-121.972024,37.373957],
            [-121.972028,37.373955],
            [-121.972034,37.373954],
            [-121.972053,37.373954],
            [-121.972102,37.373953],
            [-121.972139,37.373953],
            [-121.972255,37.373953],
            [-121.972255,37.373915],
            [-121.972255,37.373878],
            [-121.972267,37.373877],
            [-121.972274,37.373877],
            [-121.972274,37.373876],
            [-121.972268,37.373875],
            [-121.972264,37.373874],
            [-121.972256,37.373872],
            [-121.972253,37.373871],
            [-121.972248,37.37387],
            [-121.972217,37.373869],
            [-121.972124,37.373868],
            [-121.97204,37.373868],
            [-121.97183,37.373868],
            [-121.97183,37.373911],
            [-121.97183,37.373955],
            [-121.971798,37.373981],
            [-121.971766,37.374006],
            [-121.971766,37.374074],
            [-121.971766,37.374142],
            [-121.971957,37.374142],
            [-121.972148,37.374142],
            [-121.972148,37.374167],
            [-121.972148,37.374193],
            [-121.971672,37.374193],
            [-121.971195,37.374193],
            [-121.971195,37.374274],
            [-121.971195,37.374354],
            [-121.971176,37.374354],
            [-121.971157,37.374354],
            [-121.971157,37.374162],
            [-121.971157,37.37397],
            [-121.971176,37.37397],
            [-121.971195,37.37397],
            [-121.971195,37.374056],
            [-121.971195,37.374142],
            [-121.971324,37.374142],
            [-121.971453,37.374142],
            [-121.971453,37.374054],
            [-121.971453,37.373967],
            [-121.971197,37.373967],
            [-121.970942,37.373967],
            [-121.970942,37.37416],
            [-121.970942,37.374224],
            [-121.970942,37.374306],
            [-121.97094,37.374337],
            [-121.970939,37.374348],
            [-121.970937,37.374353],
            [-121.970935,37.374354],
            [-121.970933,37.374354],
            [-121.970932,37.374354],
            [-121.970929,37.374353],
            [-121.970928,37.374347],
            [-121.970926,37.374336],
            [-121.970925,37.374304],
            [-121.970925,37.374218],
            [-121.970925,37.374152],
            [-121.970925,37.37395],
            [-121.971189,37.37395],
            [-121.971453,37.37395],
            [-121.971453,37.373909],
            [-121.971453,37.373868],
            [-121.97089,37.373868],
            [-121.970328,37.373868],
            [-121.970328,37.374049],
            [-121.970328,37.374231],
            [-121.970382,37.374231],
            [-121.970436,37.374231],
            [-121.970436,37.374292],
            [-121.970436,37.374354],
            [-121.970382,37.374354],
            [-121.970328,37.374354],
            [-121.970328,37.374405],
            [-121.970328,37.374457],
            [-121.970088,37.374457],
            [-121.970023,37.374457],
            [-121.969929,37.374456],
            [-121.969874,37.374455],
            [-121.969855,37.374454],
            [-121.96985,37.374453],
            [-121.96985,37.374453],
            [-121.969855,37.37444],
            [-121.969872,37.374389],
            [-121.969911,37.37427],
            [-121.96997,37.374087],
            [-121.970005,37.373979],
            [-121.970016,37.373939],
            [-121.970017,37.373933],
            [-121.969995,37.373928],
            [-121.969868,37.3739],
            [-121.969794,37.373884],
            [-121.969779,37.373881],
            [-121.969753,37.373877],
            [-121.969725,37.373875],
            [-121.969683,37.373875],
            [-121.969653,37.373874],
            [-121.96956,37.373874],
            [-121.96956,37.374203],
            [-121.96956,37.374532],
            [-121.969067,37.374532],
            [-121.968573,37.374532],
            [-121.968573,37.374041],
            [-121.968573,37.373549],
            [-121.969159,37.373549],
            [-121.969745,37.373549],
            [-121.969745,37.373683],
            [-121.969745,37.373816],
            [-121.969933,37.373816],
            [-121.970122,37.373816],
            [-121.970122,37.373775],
            [-121.970122,37.373734],
            [-121.970333,37.373734],
            [-121.970543,37.373734],
            [-121.970543,37.373691],
            [-121.970543,37.373648],
            [-121.970333,37.373648],
            [-121.970264,37.373648],
            [-121.970175,37.373648],
            [-121.970141,37.373647],
            [-121.970129,37.373646],
            [-121.970124,37.373645],
            [-121.970122,37.373643],
            [-121.970122,37.373641],
            [-121.970122,37.37364],
            [-121.970124,37.373638],
            [-121.970129,37.373637],
            [-121.970141,37.373636],
            [-121.970175,37.373635],
            [-121.970264,37.373635],
            [-121.970333,37.373635],
            [-121.970543,37.373635],
            [-121.970543,37.37359],
            [-121.970543,37.373545],
            [-121.969556,37.373545],
            [-121.968569,37.373545],
            [-121.968569,37.374041],
            [-121.968569,37.374536],
            [-121.969067,37.374536],
            [-121.969564,37.374536],
            [-121.969564,37.374498]],
            [[-121.970333,37.374407],
            [-121.970333,37.374357],
            [-121.970386,37.374357],
            [-121.97044,37.374357],
            [-121.97044,37.374155],
            [-121.97044,37.374096],
            [-121.97044,37.374013],
            [-121.970442,37.37397],
            [-121.970444,37.373957],
            [-121.970445,37.373953],
            [-121.970446,37.373953],
            [-121.970447,37.373953],
            [-121.970448,37.373954],
            [-121.970449,37.373957],
            [-121.970451,37.373974],
            [-121.970452,37.374028],
            [-121.970453,37.374131],
            [-121.970453,37.374205],
            [-121.970453,37.374457],
            [-121.970393,37.374457],
            [-121.970333,37.374457],
            [-121.970333,37.374407]],
            [[-121.969764,37.374453],
            [-121.969767,37.374445],
            [-121.96977,37.374434],
            [-121.969775,37.374419],
            [-121.969816,37.374419],
            [-121.969828,37.374419],
            [-121.969845,37.37442],
            [-121.969852,37.37442],
            [-121.969854,37.374421],
            [-121.969855,37.374423],
            [-121.969855,37.374424],
            [-121.969855,37.374425],
            [-121.969852,37.374432],
            [-121.969849,37.374441],
            [-121.969847,37.374449],
            [-121.969845,37.374454],
            [-121.969843,37.374455],
            [-121.969836,37.374456],
            [-121.969817,37.374457],
            [-121.969788,37.374456],
            [-121.96977,37.374455],
            [-121.969765,37.374454],
            [-121.969764,37.374453],
            [-121.969764,37.374453]],
            [[-121.969745,37.374456],
            [-121.969766,37.37439],
            [-121.969818,37.374234],
            [-121.969846,37.374149],
            [-121.969884,37.37403],
            [-121.969889,37.374012],
            [-121.969867,37.374007],
            [-121.969815,37.373994],
            [-121.969743,37.373977],
            [-121.969654,37.373977],
            [-121.969625,37.373977],
            [-121.969587,37.373977],
            [-121.969573,37.373976],
            [-121.969568,37.373975],
            [-121.969565,37.373973],
            [-121.969564,37.373971],
            [-121.969564,37.37397],
            [-121.969564,37.373969],
            [-121.969565,37.373967],
            [-121.969568,37.373966],
            [-121.969573,37.373965],
            [-121.969587,37.373964],
            [-121.969625,37.373963],
            [-121.969653,37.373963],
            [-121.969742,37.373963],
            [-121.969824,37.373981],
            [-121.969882,37.373994],
            [-121.969907,37.374],
            [-121.969901,37.374019],
            [-121.969862,37.37414],
            [-121.969785,37.374376],
            [-121.96976,37.374453],
            [-121.969759,37.374454],
            [-121.969754,37.374456],
            [-121.969749,37.374457],
            [-121.969746,37.374457],
            [-121.969745,37.374456],
            [-121.969745,37.374456]],
            [[-121.969564,37.374219],
            [-121.969564,37.373981],
            [-121.969651,37.373981],
            [-121.969739,37.373981],
            [-121.969811,37.373998],
            [-121.969862,37.37401],
            [-121.969884,37.374016],
            [-121.969879,37.374033],
            [-121.969843,37.374145],
            [-121.969816,37.374227],
            [-121.969766,37.374379],
            [-121.969743,37.374447],
            [-121.96974,37.374457],
            [-121.969652,37.374457],
            [-121.969564,37.374457],
            [-121.969564,37.374219]],
            [[-121.969782,37.374396],
            [-121.969787,37.374382],
            [-121.96979,37.374375],
            [-121.969793,37.374375],
            [-121.969814,37.374377],
            [-121.969842,37.374379],
            [-121.969864,37.374382],
            [-121.969868,37.374383],
            [-121.969868,37.374384],
            [-121.969866,37.374394],
            [-121.969864,37.3744],
            [-121.969859,37.374416],
            [-121.969817,37.374416],
            [-121.969775,37.374416],
            [-121.969782,37.374396]],
            [[-121.969808,37.374372],
            [-121.969802,37.374372],
            [-121.969796,37.37437],
            [-121.969793,37.374368],
            [-121.969793,37.374364],
            [-121.969794,37.374361],
            [-121.969799,37.374345],
            [-121.969804,37.374331],
            [-121.969807,37.374327],
            [-121.969808,37.374327],
            [-121.969813,37.374327],
            [-121.969834,37.374331],
            [-121.969859,37.374336],
            [-121.969877,37.37434],
            [-121.96988,37.374341],
            [-121.969881,37.374342],
            [-121.969881,37.374346],
            [-121.969879,37.374354],
            [-121.969877,37.374361],
            [-121.969872,37.374378],
            [-121.969848,37.374376],
            [-121.969826,37.374374],
            [-121.969808,37.374372]],
            [[-121.972023,37.374275],
            [-121.972023,37.374196],
            [-121.972086,37.374196],
            [-121.972148,37.374196],
            [-121.972148,37.374275],
            [-121.972148,37.374354],
            [-121.972086,37.374354],
            [-121.972023,37.374354],
            [-121.972023,37.374275]],
            [[-121.971899,37.374275],
            [-121.971899,37.374196],
            [-121.971959,37.374196],
            [-121.972019,37.374196],
            [-121.972019,37.374275],
            [-121.972019,37.374354],
            [-121.971959,37.374354],
            [-121.971899,37.374354],
            [-121.971899,37.374275]],
            [[-121.97177,37.374275],
            [-121.97177,37.374196],
            [-121.971832,37.374196],
            [-121.971895,37.374196],
            [-121.971895,37.374275],
            [-121.971895,37.374354],
            [-121.971832,37.374354],
            [-121.97177,37.374354],
            [-121.97177,37.374275]],
            [[-121.971611,37.374275],
            [-121.971611,37.374196],
            [-121.971689,37.374196],
            [-121.971766,37.374196],
            [-121.971766,37.374275],
            [-121.971766,37.374354],
            [-121.971689,37.374354],
            [-121.971611,37.374354],
            [-121.971611,37.374275]],
            [[-121.971453,37.374275],
            [-121.971453,37.374196],
            [-121.97153,37.374196],
            [-121.971607,37.374196],
            [-121.971607,37.374275],
            [-121.971607,37.374354],
            [-121.97153,37.374354],
            [-121.971453,37.374354],
            [-121.971453,37.374275]],
            [[-121.971324,37.374275],
            [-121.971324,37.374196],
            [-121.971386,37.374196],
            [-121.971448,37.374196],
            [-121.971448,37.374275],
            [-121.971448,37.374354],
            [-121.971386,37.374354],
            [-121.971324,37.374354],
            [-121.971324,37.374275]],
            [[-121.971199,37.374275],
            [-121.971199,37.374196],
            [-121.97126,37.374196],
            [-121.97132,37.374196],
            [-121.97132,37.374275],
            [-121.97132,37.374354],
            [-121.97126,37.374354],
            [-121.971199,37.374354],
            [-121.971199,37.374275]],
            [[-121.970946,37.374313],
            [-121.970946,37.374272],
            [-121.971049,37.374272],
            [-121.971152,37.374272],
            [-121.971152,37.374313],
            [-121.971152,37.374354],
            [-121.971049,37.374354],
            [-121.970946,37.374354],
            [-121.970946,37.374313]],
            [[-121.970457,37.374112],
            [-121.970457,37.373871],
            [-121.970689,37.373871],
            [-121.970921,37.373871],
            [-121.970921,37.374112],
            [-121.970921,37.374354],
            [-121.970689,37.374354],
            [-121.970457,37.374354],
            [-121.970457,37.374112]],
            [[-121.969846,37.37433],
            [-121.969833,37.374328],
            [-121.969814,37.374323],
            [-121.96981,37.374322],
            [-121.96981,37.374321],
            [-121.96981,37.374317],
            [-121.969811,37.374309],
            [-121.969816,37.374296],
            [-121.96982,37.374289],
            [-121.969822,37.374287],
            [-121.969823,37.374286],
            [-121.969836,37.374289],
            [-121.96986,37.374294],
            [-121.969872,37.374297],
            [-121.969887,37.374301],
            [-121.969892,37.374304],
            [-121.969893,37.374305],
            [-121.969894,37.374306],
            [-121.969894,37.37431],
            [-121.969892,37.374317],
            [-121.969889,37.374328],
            [-121.969886,37.374334],
            [-121.969884,37.374337],
            [-121.969883,37.374337],
            [-121.969871,37.374335],
            [-121.969846,37.37433]],
            [[-121.969858,37.374291],
            [-121.969846,37.374288],
            [-121.969828,37.374283],
            [-121.969824,37.374282],
            [-121.969824,37.374281],
            [-121.969823,37.374278],
            [-121.969825,37.37427],
            [-121.969828,37.374258],
            [-121.969831,37.374251],
            [-121.969833,37.374248],
            [-121.969834,37.374248],
            [-121.969839,37.374249],
            [-121.96986,37.374252],
            [-121.969885,37.374257],
            [-121.969903,37.374262],
            [-121.969906,37.374263],
            [-121.969907,37.374264],
            [-121.969907,37.374267],
            [-121.969905,37.374276],
            [-121.969901,37.374289],
            [-121.969897,37.374296],
            [-121.969894,37.374298],
            [-121.969893,37.374298],
            [-121.969881,37.374295],
            [-121.969858,37.374291]],
            [[-121.970946,37.37416],
            [-121.970946,37.374053],
            [-121.971049,37.374053],
            [-121.971152,37.374053],
            [-121.971152,37.37416],
            [-121.971152,37.374268],
            [-121.971049,37.374268],
            [-121.970946,37.374268],
            [-121.970946,37.37416]],
            [[-121.969872,37.374252],
            [-121.969859,37.374249],
            [-121.969845,37.374246],
            [-121.96984,37.374244],
            [-121.969837,37.374241],
            [-121.969836,37.374238],
            [-121.969837,37.374231],
            [-121.969839,37.374224],
            [-121.96984,37.374219],
            [-121.969843,37.374213],
            [-121.969846,37.374209],
            [-121.969849,37.374208],
            [-121.969851,37.374208],
            [-121.969864,37.374211],
            [-121.969886,37.374216],
            [-121.969897,37.374218],
            [-121.969914,37.374222],
            [-121.969918,37.374224],
            [-121.969918,37.374225],
            [-121.969918,37.374228],
            [-121.969917,37.374236],
            [-121.969914,37.374248],
            [-121.969911,37.374255],
            [-121.969909,37.374258],
            [-121.969908,37.374258],
            [-121.969897,37.374256],
            [-121.969872,37.374252]],
            [[-121.970333,37.374208],
            [-121.970333,37.37419],
            [-121.970384,37.37419],
            [-121.970436,37.37419],
            [-121.970436,37.374208],
            [-121.970436,37.374227],
            [-121.970384,37.374227],
            [-121.970333,37.374227],
            [-121.970333,37.374208]],
            [[-121.969884,37.374212],
            [-121.969872,37.374209],
            [-121.969859,37.374206],
            [-121.969853,37.374204],
            [-121.969851,37.374201],
            [-121.969849,37.374198],
            [-121.969851,37.37419],
            [-121.969853,37.374183],
            [-121.969854,37.374177],
            [-121.969857,37.37417],
            [-121.96986,37.374168],
            [-121.969864,37.374167],
            [-121.969871,37.374167],
            [-121.969886,37.37417],
            [-121.969901,37.374173],
            [-121.969937,37.37418],
            [-121.96993,37.3742],
            [-121.969929,37.374205],
            [-121.969926,37.374212],
            [-121.969923,37.374217],
            [-121.96992,37.374219],
            [-121.969919,37.374219],
            [-121.969907,37.374217],
            [-121.969884,37.374212]],
            [[-121.970333,37.374166],
            [-121.970333,37.374145],
            [-121.970384,37.374145],
            [-121.970436,37.374145],
            [-121.970436,37.374166],
            [-121.970436,37.374186],
            [-121.970384,37.374186],
            [-121.970333,37.374186],
            [-121.970333,37.374166]],
            [[-121.969897,37.374169],
            [-121.96986,37.374161],
            [-121.969863,37.374151],
            [-121.969865,37.374143],
            [-121.969869,37.374132],
            [-121.969873,37.374128],
            [-121.969875,37.374126],
            [-121.969878,37.374126],
            [-121.969882,37.374126],
            [-121.969884,37.374126],
            [-121.969895,37.374129],
            [-121.969917,37.374134],
            [-121.969933,37.374139],
            [-121.969942,37.374142],
            [-121.969945,37.374144],
            [-121.969945,37.374144],
            [-121.969946,37.374147],
            [-121.969944,37.374156],
            [-121.96994,37.374167],
            [-121.969938,37.374173],
            [-121.969936,37.374176],
            [-121.969935,37.374176],
            [-121.969923,37.374174],
            [-121.969897,37.374169]],
            [[-121.970333,37.374123],
            [-121.970333,37.374104],
            [-121.970384,37.374104],
            [-121.970436,37.374104],
            [-121.970436,37.374123],
            [-121.970436,37.374142],
            [-121.970384,37.374142],
            [-121.970333,37.374142],
            [-121.970333,37.374123]],
            [[-121.97177,37.374072],
            [-121.97177,37.374006],
            [-121.971803,37.37398],
            [-121.971837,37.373953],
            [-121.971928,37.373953],
            [-121.972019,37.373953],
            [-121.972019,37.374046],
            [-121.972019,37.374138],
            [-121.971895,37.374138],
            [-121.97177,37.374138],
            [-121.97177,37.374072]],
            [[-121.971199,37.374054],
            [-121.971199,37.37397],
            [-121.971324,37.37397],
            [-121.971448,37.37397],
            [-121.971448,37.374054],
            [-121.971448,37.374138],
            [-121.971324,37.374138],
            [-121.971199,37.374138],
            [-121.971199,37.374054]],
            [[-121.969905,37.374128],
            [-121.969893,37.374125],
            [-121.969882,37.374122],
            [-121.969878,37.37412],
            [-121.969876,37.374117],
            [-121.969876,37.374113],
            [-121.969879,37.374103],
            [-121.969882,37.374094],
            [-121.969883,37.374092],
            [-121.969884,37.374089],
            [-121.969887,37.374087],
            [-121.969891,37.374086],
            [-121.969899,37.374087],
            [-121.969919,37.374091],
            [-121.969934,37.374094],
            [-121.969963,37.374101],
            [-121.969957,37.374117],
            [-121.969952,37.374129],
            [-121.969951,37.374136],
            [-121.96995,37.374136],
            [-121.969949,37.374137],
            [-121.969946,37.374137],
            [-121.969943,37.374137],
            [-121.969929,37.374134],
            [-121.969905,37.374128]],
            [[-121.970333,37.374085],
            [-121.970333,37.37407],
            [-121.970384,37.37407],
            [-121.970436,37.37407],
            [-121.970436,37.374085],
            [-121.970436,37.3741],
            [-121.970384,37.3741],
            [-121.970333,37.3741],
            [-121.970333,37.374085]],
            [[-121.969917,37.374087],
            [-121.96991,37.374085],
            [-121.969898,37.374081],
            [-121.969891,37.374078],
            [-121.969888,37.374076],
            [-121.969888,37.374075],
            [-121.969888,37.374075],
            [-121.969891,37.374067],
            [-121.969895,37.374056],
            [-121.969899,37.374043],
            [-121.96993,37.374049],
            [-121.969954,37.374055],
            [-121.969969,37.374058],
            [-121.96997,37.374058],
            [-121.969972,37.37406],
            [-121.969974,37.374061],
            [-121.969974,37.374063],
            [-121.969974,37.374064],
            [-121.969972,37.374071],
            [-121.969968,37.374083],
            [-121.969966,37.374087],
            [-121.969964,37.374092],
            [-121.969961,37.374095],
            [-121.969958,37.374096],
            [-121.969956,37.374096],
            [-121.969941,37.374092],
            [-121.969917,37.374087]],
            [[-121.970333,37.374051],
            [-121.970333,37.374035],
            [-121.970384,37.374035],
            [-121.970436,37.374035],
            [-121.970436,37.374051],
            [-121.970436,37.374066],
            [-121.970384,37.374066],
            [-121.970333,37.374066],
            [-121.970333,37.374051]],
            [[-121.969936,37.374047],
            [-121.969916,37.374043],
            [-121.969905,37.374041],
            [-121.969904,37.37404],
            [-121.969903,37.374037],
            [-121.969904,37.374029],
            [-121.969907,37.374016],
            [-121.96991,37.374008],
            [-121.969912,37.374004],
            [-121.969913,37.374003],
            [-121.969914,37.374002],
            [-121.969916,37.374002],
            [-121.969922,37.374002],
            [-121.969939,37.374006],
            [-121.969965,37.374013],
            [-121.96998,37.374019],
            [-121.969984,37.374022],
            [-121.969986,37.374023],
            [-121.969986,37.374024],
            [-121.969984,37.374032],
            [-121.96998,37.374044],
            [-121.969977,37.37405],
            [-121.969975,37.374053],
            [-121.969972,37.374054],
            [-121.96997,37.374055],
            [-121.969969,37.374055],
            [-121.969956,37.374052],
            [-121.969936,37.374047]],
            [[-121.970946,37.37401],
            [-121.970946,37.37397],
            [-121.971049,37.37397],
            [-121.971152,37.37397],
            [-121.971152,37.37401],
            [-121.971152,37.374049],
            [-121.971049,37.374049],
            [-121.970946,37.374049],
            [-121.970946,37.37401]],
            [[-121.970333,37.374017],
            [-121.970333,37.374001],
            [-121.970384,37.374001],
            [-121.970436,37.374001],
            [-121.970436,37.374017],
            [-121.970436,37.374032],
            [-121.970384,37.374032],
            [-121.970333,37.374032],
            [-121.970333,37.374017]],
            [[-121.96994,37.373998],
            [-121.969944,37.373985],
            [-121.969951,37.373962],
            [-121.969955,37.373949],
            [-121.969961,37.373935],
            [-121.969965,37.37393],
            [-121.96997,37.373927],
            [-121.969978,37.373927],
            [-121.969994,37.37393],
            [-121.970009,37.373934],
            [-121.97001,37.373935],
            [-121.970011,37.373936],
            [-121.970011,37.373941],
            [-121.970008,37.373956],
            [-121.969999,37.373982],
            [-121.969993,37.374002],
            [-121.969989,37.374014],
            [-121.969963,37.374009],
            [-121.969956,37.374007],
            [-121.969947,37.374005],
            [-121.969941,37.374002],
            [-121.969939,37.374],
            [-121.96994,37.373998]],
            [[-121.969905,37.373996],
            [-121.969899,37.373994],
            [-121.96989,37.373992],
            [-121.969885,37.373989],
            [-121.969883,37.373986],
            [-121.969884,37.373985],
            [-121.969888,37.373972],
            [-121.969895,37.37395],
            [-121.969902,37.373928],
            [-121.969905,37.373917],
            [-121.969906,37.373916],
            [-121.969907,37.373915],
            [-121.969912,37.373915],
            [-121.969919,37.373915],
            [-121.969929,37.373917],
            [-121.969938,37.373919],
            [-121.969947,37.373922],
            [-121.969954,37.373925],
            [-121.969957,37.373927],
            [-121.969957,37.373928],
            [-121.969957,37.373929],
            [-121.969953,37.373941],
            [-121.969946,37.373963],
            [-121.96994,37.373984],
            [-121.969935,37.373998],
            [-121.969932,37.374001],
            [-121.969931,37.374001],
            [-121.969922,37.374],
            [-121.969905,37.373996]],
            [[-121.970333,37.373975],
            [-121.970333,37.373953],
            [-121.970384,37.373953],
            [-121.970436,37.373953],
            [-121.970436,37.373975],
            [-121.970436,37.373998],
            [-121.970384,37.373998],
            [-121.970333,37.373998],
            [-121.970333,37.373975]],
            [[-121.969832,37.373974],
            [-121.969837,37.373961],
            [-121.969844,37.373938],
            [-121.969848,37.373925],
            [-121.969852,37.373912],
            [-121.969855,37.373907],
            [-121.969858,37.373905],
            [-121.969863,37.373904],
            [-121.969872,37.373905],
            [-121.969881,37.373907],
            [-121.969903,37.373912],
            [-121.969894,37.373939],
            [-121.969887,37.373961],
            [-121.969882,37.373978],
            [-121.969878,37.37399],
            [-121.969854,37.373985],
            [-121.969848,37.373983],
            [-121.969839,37.373981],
            [-121.969834,37.373978],
            [-121.969832,37.373975],
            [-121.969832,37.373974]],
            [[-121.969789,37.37397],
            [-121.969785,37.373969],
            [-121.96978,37.373967],
            [-121.969779,37.373966],
            [-121.969782,37.373956],
            [-121.96979,37.373934],
            [-121.969794,37.373922],
            [-121.969799,37.373901],
            [-121.9698,37.373896],
            [-121.969801,37.373895],
            [-121.969803,37.373893],
            [-121.969808,37.373892],
            [-121.969814,37.373892],
            [-121.969824,37.373893],
            [-121.969836,37.373896],
            [-121.969843,37.373898],
            [-121.969847,37.3739],
            [-121.969847,37.373901],
            [-121.969847,37.373905],
            [-121.969845,37.373918],
            [-121.969839,37.373941],
            [-121.969832,37.373964],
            [-121.969828,37.373973],
            [-121.969828,37.373974],
            [-121.969826,37.373975],
            [-121.96982,37.373976],
            [-121.96981,37.373975],
            [-121.969796,37.373972],
            [-121.969789,37.37397]],
            [[-121.969748,37.373961],
            [-121.969747,37.373961],
            [-121.969746,37.373958],
            [-121.969745,37.373952],
            [-121.969744,37.373933],
            [-121.969745,37.373911],
            [-121.969746,37.373897],
            [-121.969748,37.373886],
            [-121.96975,37.373881],
            [-121.969751,37.373879],
            [-121.969752,37.373879],
            [-121.969763,37.373881],
            [-121.969778,37.373885],
            [-121.969782,37.373885],
            [-121.969788,37.373887],
            [-121.969792,37.373889],
            [-121.969795,37.373892],
            [-121.969796,37.373896],
            [-121.969796,37.373901],
            [-121.969793,37.373911],
            [-121.96979,37.373921],
            [-121.969784,37.373937],
            [-121.969779,37.373952],
            [-121.969778,37.373955],
            [-121.969776,37.373959],
            [-121.969773,37.373962],
            [-121.96977,37.373965],
            [-121.969767,37.373966],
            [-121.969762,37.373966],
            [-121.969754,37.373964],
            [-121.969748,37.373961]],
            [[-121.969689,37.373919],
            [-121.969689,37.373878],
            [-121.969715,37.373878],
            [-121.96974,37.373878],
            [-121.96974,37.373919],
            [-121.96974,37.37396],
            [-121.969715,37.37396],
            [-121.969689,37.37396],
            [-121.969689,37.373919]],
            [[-121.969633,37.373919],
            [-121.969633,37.373878],
            [-121.969659,37.373878],
            [-121.969685,37.373878],
            [-121.969685,37.373919],
            [-121.969685,37.37396],
            [-121.969659,37.37396],
            [-121.969633,37.37396],
            [-121.969633,37.373919]],
            [[-121.969564,37.373919],
            [-121.969564,37.373878],
            [-121.969597,37.373878],
            [-121.969629,37.373878],
            [-121.969629,37.373919],
            [-121.969629,37.37396],
            [-121.969597,37.37396],
            [-121.969564,37.37396],
            [-121.969564,37.373919]],
            [[-121.972204,37.37391],
            [-121.972204,37.373871],
            [-121.972226,37.373872],
            [-121.972249,37.373873],
            [-121.97225,37.373911],
            [-121.972251,37.37395],
            [-121.972227,37.37395],
            [-121.972204,37.37395],
            [-121.972204,37.37391]],
            [[-121.972152,37.37391],
            [-121.972152,37.373871],
            [-121.972176,37.373871],
            [-121.972199,37.373871],
            [-121.972199,37.37391],
            [-121.972199,37.37395],
            [-121.972176,37.37395],
            [-121.972152,37.37395],
            [-121.972152,37.37391]],
            [[-121.972096,37.37391],
            [-121.972096,37.373871],
            [-121.972122,37.373871],
            [-121.972148,37.373871],
            [-121.972148,37.37391],
            [-121.972148,37.37395],
            [-121.972122,37.37395],
            [-121.972096,37.37395],
            [-121.972096,37.37391]],
            [[-121.972045,37.37391],
            [-121.972045,37.373871],
            [-121.972069,37.373871],
            [-121.972092,37.373871],
            [-121.972092,37.37391],
            [-121.972092,37.37395],
            [-121.972069,37.37395],
            [-121.972045,37.37395],
            [-121.972045,37.37391]],
            [[-121.971993,37.37391],
            [-121.971993,37.373871],
            [-121.972017,37.373871],
            [-121.972041,37.373871],
            [-121.972041,37.37391],
            [-121.972041,37.37395],
            [-121.972017,37.37395],
            [-121.971993,37.37395],
            [-121.971993,37.37391]],
            [[-121.971938,37.37391],
            [-121.971938,37.373871],
            [-121.971963,37.373871],
            [-121.971989,37.373871],
            [-121.971989,37.37391],
            [-121.971989,37.37395],
            [-121.971963,37.37395],
            [-121.971938,37.37395],
            [-121.971938,37.37391]],
            [[-121.971886,37.37391],
            [-121.971886,37.373871],
            [-121.97191,37.373871],
            [-121.971933,37.373871],
            [-121.971933,37.37391],
            [-121.971933,37.37395],
            [-121.97191,37.37395],
            [-121.971886,37.37395],
            [-121.971886,37.37391]],
            [[-121.971835,37.37391],
            [-121.971835,37.373871],
            [-121.971858,37.373871],
            [-121.971882,37.373871],
            [-121.971882,37.37391],
            [-121.971882,37.37395],
            [-121.971858,37.37395],
            [-121.971835,37.37395],
            [-121.971835,37.37391]],
            [[-121.970384,37.37391],
            [-121.970384,37.373871],
            [-121.970418,37.373871],
            [-121.970453,37.373871],
            [-121.970453,37.37391],
            [-121.970453,37.37395],
            [-121.970418,37.37395],
            [-121.970384,37.37395],
            [-121.970384,37.37391]],
            [[-121.970333,37.37391],
            [-121.970333,37.373871],
            [-121.970356,37.373871],
            [-121.97038,37.373871],
            [-121.97038,37.37391],
            [-121.97038,37.37395],
            [-121.970356,37.37395],
            [-121.970333,37.37395],
            [-121.970333,37.37391]],
            [[-121.971401,37.373909],
            [-121.971401,37.373871],
            [-121.971425,37.373871],
            [-121.971448,37.373871],
            [-121.971448,37.373909],
            [-121.971448,37.373946],
            [-121.971425,37.373946],
            [-121.971401,37.373946],
            [-121.971401,37.373909]],
            [[-121.971345,37.373909],
            [-121.971345,37.373871],
            [-121.971371,37.373871],
            [-121.971397,37.373871],
            [-121.971397,37.373909],
            [-121.971397,37.373946],
            [-121.971371,37.373946],
            [-121.971345,37.373946],
            [-121.971345,37.373909]],
            [[-121.971294,37.373909],
            [-121.971294,37.373871],
            [-121.971317,37.373871],
            [-121.971341,37.373871],
            [-121.971341,37.373909],
            [-121.971341,37.373946],
            [-121.971317,37.373946],
            [-121.971294,37.373946],
            [-121.971294,37.373909]],
            [[-121.971238,37.373909],
            [-121.971238,37.373871],
            [-121.971264,37.373871],
            [-121.97129,37.373871],
            [-121.97129,37.373909],
            [-121.97129,37.373946],
            [-121.971264,37.373946],
            [-121.971238,37.373946],
            [-121.971238,37.373909]],
            [[-121.971191,37.373909],
            [-121.971191,37.373871],
            [-121.971212,37.373871],
            [-121.971234,37.373871],
            [-121.971234,37.373909],
            [-121.971234,37.373946],
            [-121.971212,37.373946],
            [-121.971191,37.373946],
            [-121.971191,37.373909]],
            [[-121.971135,37.373909],
            [-121.971135,37.373871],
            [-121.971161,37.373871],
            [-121.971187,37.373871],
            [-121.971187,37.373909],
            [-121.971187,37.373946],
            [-121.971161,37.373946],
            [-121.971135,37.373946],
            [-121.971135,37.373909]],
            [[-121.971084,37.373909],
            [-121.971084,37.373871],
            [-121.971107,37.373871],
            [-121.971131,37.373871],
            [-121.971131,37.373909],
            [-121.971131,37.373946],
            [-121.971107,37.373946],
            [-121.971084,37.373946],
            [-121.971084,37.373909]],
            [[-121.971032,37.373909],
            [-121.971032,37.373871],
            [-121.971056,37.373871],
            [-121.971079,37.373871],
            [-121.971079,37.373909],
            [-121.971079,37.373946],
            [-121.971056,37.373946],
            [-121.971032,37.373946],
            [-121.971032,37.373909]],
            [[-121.970976,37.373909],
            [-121.970976,37.373871],
            [-121.971002,37.373871],
            [-121.971028,37.373871],
            [-121.971028,37.373909],
            [-121.971028,37.373946],
            [-121.971002,37.373946],
            [-121.970976,37.373946],
            [-121.970976,37.373909]],
            [[-121.970925,37.373909],
            [-121.970925,37.373871],
            [-121.970948,37.373871],
            [-121.970972,37.373871],
            [-121.970972,37.373909],
            [-121.970972,37.373946],
            [-121.970948,37.373946],
            [-121.970925,37.373946],
            [-121.970925,37.373909]],
            [[-121.970066,37.373772],
            [-121.970066,37.37373],
            [-121.970092,37.37373],
            [-121.970118,37.37373],
            [-121.970118,37.373772],
            [-121.970118,37.373813],
            [-121.970092,37.373813],
            [-121.970066,37.373813],
            [-121.970066,37.373772]],
            [[-121.970015,37.373772],
            [-121.970015,37.37373],
            [-121.970039,37.37373],
            [-121.970062,37.37373],
            [-121.970062,37.373772],
            [-121.970062,37.373813],
            [-121.970039,37.373813],
            [-121.970015,37.373813],
            [-121.970015,37.373772]],
            [[-121.969938,37.373772],
            [-121.969938,37.37373],
            [-121.969974,37.37373],
            [-121.970011,37.37373],
            [-121.970011,37.373772],
            [-121.970011,37.373813],
            [-121.969974,37.373813],
            [-121.969938,37.373813],
            [-121.969938,37.373772]],
            [[-121.969882,37.373772],
            [-121.969882,37.37373],
            [-121.969908,37.37373],
            [-121.969933,37.37373],
            [-121.969933,37.373772],
            [-121.969933,37.373813],
            [-121.969908,37.373813],
            [-121.969882,37.373813],
            [-121.969882,37.373772]],
            [[-121.96983,37.373772],
            [-121.96983,37.37373],
            [-121.969854,37.37373],
            [-121.969878,37.37373],
            [-121.969878,37.373772],
            [-121.969878,37.373813],
            [-121.969854,37.373813],
            [-121.96983,37.373813],
            [-121.96983,37.373772]],
            [[-121.969749,37.373772],
            [-121.969749,37.37373],
            [-121.969788,37.37373],
            [-121.969826,37.37373],
            [-121.969826,37.373772],
            [-121.969826,37.373813],
            [-121.969788,37.373813],
            [-121.969749,37.373813],
            [-121.969749,37.373772]],
            [[-121.972251,37.373739],
            [-121.972249,37.37374],
            [-121.972236,37.373741],
            [-121.972201,37.373743],
            [-121.972129,37.373744],
            [-121.972039,37.373745],
            [-121.971944,37.373745],
            [-121.971855,37.373744],
            [-121.971782,37.373742],
            [-121.971746,37.373741],
            [-121.971733,37.373739],
            [-121.971731,37.373739],
            [-121.971729,37.373737],
            [-121.971725,37.373731],
            [-121.971724,37.37372],
            [-121.971723,37.373702],
            [-121.971723,37.373689],
            [-121.971723,37.373645],
            [-121.97183,37.373645],
            [-121.971862,37.373645],
            [-121.971905,37.373644],
            [-121.971928,37.373643],
            [-121.971936,37.373642],
            [-121.971937,37.37364],
            [-121.971938,37.37364],
            [-121.971937,37.373639],
            [-121.971936,37.373638],
            [-121.971928,37.373636],
            [-121.971905,37.373635],
            [-121.971862,37.373635],
            [-121.97183,37.373635],
            [-121.971723,37.373635],
            [-121.971723,37.37359],
            [-121.971723,37.373545],
            [-121.971832,37.373545],
            [-121.971942,37.373545],
            [-121.971942,37.373643],
            [-121.971942,37.373741],
            [-121.972094,37.373741],
            [-121.972138,37.373741],
            [-121.972199,37.37374],
            [-121.972233,37.373739],
            [-121.972246,37.373738],
            [-121.97225,37.373736],
            [-121.972251,37.373736],
            [-121.972252,37.373735],
            [-121.972256,37.373733],
            [-121.97226,37.373732],
            [-121.972265,37.373731],
            [-121.972268,37.373731],
            [-121.972279,37.373731],
            [-121.972267,37.373733],
            [-121.972262,37.373734],
            [-121.972256,37.373736],
            [-121.972252,37.373738],
            [-121.972251,37.373739]],
            [[-121.971886,37.373695],
            [-121.971886,37.373648],
            [-121.971912,37.373648],
            [-121.971938,37.373648],
            [-121.971938,37.373695],
            [-121.971938,37.373741],
            [-121.971912,37.373741],
            [-121.971886,37.373741],
            [-121.971886,37.373695]],
            [[-121.971835,37.373695],
            [-121.971835,37.373648],
            [-121.971858,37.373648],
            [-121.971882,37.373648],
            [-121.971882,37.373695],
            [-121.971882,37.373741],
            [-121.971858,37.373741],
            [-121.971835,37.373741],
            [-121.971835,37.373695]],
            [[-121.971783,37.373695],
            [-121.971783,37.373648],
            [-121.971807,37.373648],
            [-121.97183,37.373648],
            [-121.97183,37.373695],
            [-121.97183,37.373741],
            [-121.971807,37.373741],
            [-121.971783,37.373741],
            [-121.971783,37.373695]],
            [[-121.971732,37.373737],
            [-121.971731,37.373735],
            [-121.971729,37.373728],
            [-121.971728,37.373709],
            [-121.971727,37.37369],
            [-121.971727,37.373648],
            [-121.971753,37.373648],
            [-121.971779,37.373648],
            [-121.971779,37.373695],
            [-121.971779,37.373741],
            [-121.971758,37.373741],
            [-121.97175,37.37374],
            [-121.971739,37.373739],
            [-121.971734,37.373737],
            [-121.971732,37.373737]],
            [[-121.97156,37.373689],
            [-121.97156,37.373734],
            [-121.971135,37.373734],
            [-121.97071,37.373734],
            [-121.97071,37.373689],
            [-121.97071,37.373645],
            [-121.971135,37.373645],
            [-121.97156,37.373645],
            [-121.97156,37.373689]],
            [[-121.971504,37.373689],
            [-121.971504,37.373648],
            [-121.97153,37.373648],
            [-121.971556,37.373648],
            [-121.971556,37.373689],
            [-121.971556,37.37373],
            [-121.97153,37.37373],
            [-121.971504,37.37373],
            [-121.971504,37.373689]],
            [[-121.971453,37.373689],
            [-121.971453,37.373648],
            [-121.971476,37.373648],
            [-121.9715,37.373648],
            [-121.9715,37.373689],
            [-121.9715,37.37373],
            [-121.971476,37.37373],
            [-121.971453,37.37373],
            [-121.971453,37.373689]],
            [[-121.971401,37.373689],
            [-121.971401,37.373648],
            [-121.971425,37.373648],
            [-121.971448,37.373648],
            [-121.971448,37.373689],
            [-121.971448,37.37373],
            [-121.971425,37.37373],
            [-121.971401,37.37373],
            [-121.971401,37.373689]],
            [[-121.971345,37.373689],
            [-121.971345,37.373648],
            [-121.971371,37.373648],
            [-121.971397,37.373648],
            [-121.971397,37.373689],
            [-121.971397,37.37373],
            [-121.971371,37.37373],
            [-121.971345,37.37373],
            [-121.971345,37.373689]],
            [[-121.971294,37.373689],
            [-121.971294,37.373648],
            [-121.971317,37.373648],
            [-121.971341,37.373648],
            [-121.971341,37.373689],
            [-121.971341,37.37373],
            [-121.971317,37.37373],
            [-121.971294,37.37373],
            [-121.971294,37.373689]],
            [[-121.971238,37.373689],
            [-121.971238,37.373648],
            [-121.971264,37.373648],
            [-121.97129,37.373648],
            [-121.97129,37.373689],
            [-121.97129,37.37373],
            [-121.971264,37.37373],
            [-121.971238,37.37373],
            [-121.971238,37.373689]],
            [[-121.971191,37.373689],
            [-121.971191,37.373648],
            [-121.971212,37.373648],
            [-121.971234,37.373648],
            [-121.971234,37.373689],
            [-121.971234,37.37373],
            [-121.971212,37.37373],
            [-121.971191,37.37373],
            [-121.971191,37.373689]],
            [[-121.971135,37.373689],
            [-121.971135,37.373648],
            [-121.971161,37.373648],
            [-121.971187,37.373648],
            [-121.971187,37.373689],
            [-121.971187,37.37373],
            [-121.971161,37.37373],
            [-121.971135,37.37373],
            [-121.971135,37.373689]],
            [[-121.971084,37.373689],
            [-121.971084,37.373648],
            [-121.971107,37.373648],
            [-121.971131,37.373648],
            [-121.971131,37.373689],
            [-121.971131,37.37373],
            [-121.971107,37.37373],
            [-121.971084,37.37373],
            [-121.971084,37.373689]],
            [[-121.971032,37.373689],
            [-121.971032,37.373648],
            [-121.971056,37.373648],
            [-121.971079,37.373648],
            [-121.971079,37.373689],
            [-121.971079,37.37373],
            [-121.971056,37.37373],
            [-121.971032,37.37373],
            [-121.971032,37.373689]],
            [[-121.970976,37.373689],
            [-121.970976,37.373648],
            [-121.971002,37.373648],
            [-121.971028,37.373648],
            [-121.971028,37.373689],
            [-121.971028,37.37373],
            [-121.971002,37.37373],
            [-121.970976,37.37373],
            [-121.970976,37.373689]],
            [[-121.970925,37.373689],
            [-121.970925,37.373648],
            [-121.970948,37.373648],
            [-121.970972,37.373648],
            [-121.970972,37.373689],
            [-121.970972,37.37373],
            [-121.970948,37.37373],
            [-121.970925,37.37373],
            [-121.970925,37.373689]],
            [[-121.970873,37.373689],
            [-121.970873,37.373648],
            [-121.970897,37.373648],
            [-121.970921,37.373648],
            [-121.970921,37.373689],
            [-121.970921,37.37373],
            [-121.970897,37.37373],
            [-121.970873,37.37373],
            [-121.970873,37.373689]],
            [[-121.970818,37.373689],
            [-121.970818,37.373648],
            [-121.970843,37.373648],
            [-121.970869,37.373648],
            [-121.970869,37.373689],
            [-121.970869,37.37373],
            [-121.970843,37.37373],
            [-121.970818,37.37373],
            [-121.970818,37.373689]],
            [[-121.970766,37.373689],
            [-121.970766,37.373648],
            [-121.97079,37.373648],
            [-121.970813,37.373648],
            [-121.970813,37.373689],
            [-121.970813,37.37373],
            [-121.97079,37.37373],
            [-121.970766,37.37373],
            [-121.970766,37.373689]],
            [[-121.970715,37.373689],
            [-121.970715,37.373648],
            [-121.970738,37.373648],
            [-121.970762,37.373648],
            [-121.970762,37.373689],
            [-121.970762,37.37373],
            [-121.970738,37.37373],
            [-121.970715,37.37373],
            [-121.970715,37.373689]],
            [[-121.970491,37.373691],
            [-121.970491,37.373652],
            [-121.970515,37.373652],
            [-121.970539,37.373652],
            [-121.970539,37.373691],
            [-121.970539,37.37373],
            [-121.970515,37.37373],
            [-121.970491,37.37373],
            [-121.970491,37.373691]],
            [[-121.97044,37.373691],
            [-121.97044,37.373652],
            [-121.970463,37.373652],
            [-121.970487,37.373652],
            [-121.970487,37.373691],
            [-121.970487,37.37373],
            [-121.970463,37.37373],
            [-121.97044,37.37373],
            [-121.97044,37.373691]],
            [[-121.970384,37.373691],
            [-121.970384,37.373652],
            [-121.97041,37.373652],
            [-121.970436,37.373652],
            [-121.970436,37.373691],
            [-121.970436,37.37373],
            [-121.97041,37.37373],
            [-121.970384,37.37373],
            [-121.970384,37.373691]],
            [[-121.970333,37.373691],
            [-121.970333,37.373652],
            [-121.970356,37.373652],
            [-121.97038,37.373652],
            [-121.97038,37.373691],
            [-121.97038,37.37373],
            [-121.970356,37.37373],
            [-121.970333,37.37373],
            [-121.970333,37.373691]],
            [[-121.970281,37.373691],
            [-121.970281,37.373652],
            [-121.970305,37.373652],
            [-121.970328,37.373652],
            [-121.970328,37.373691],
            [-121.970328,37.37373],
            [-121.970305,37.37373],
            [-121.970281,37.37373],
            [-121.970281,37.373691]],
            [[-121.970225,37.373691],
            [-121.970225,37.373652],
            [-121.970251,37.373652],
            [-121.970277,37.373652],
            [-121.970277,37.373691],
            [-121.970277,37.37373],
            [-121.970251,37.37373],
            [-121.970225,37.37373],
            [-121.970225,37.373691]],
            [[-121.970174,37.373691],
            [-121.970174,37.373652],
            [-121.970197,37.373652],
            [-121.970221,37.373652],
            [-121.970221,37.373691],
            [-121.970221,37.37373],
            [-121.970197,37.37373],
            [-121.970174,37.37373],
            [-121.970174,37.373691]],
            [[-121.970122,37.373691],
            [-121.970122,37.373652],
            [-121.970146,37.373652],
            [-121.970169,37.373652],
            [-121.970169,37.373691],
            [-121.970169,37.37373],
            [-121.970146,37.37373],
            [-121.970122,37.37373],
            [-121.970122,37.373691]],
            [[-121.969749,37.373722],
            [-121.969749,37.373721],
            [-121.969752,37.37372],
            [-121.969765,37.373718],
            [-121.969804,37.373717],
            [-121.969879,37.373717],
            [-121.969933,37.373717],
            [-121.969988,37.373717],
            [-121.970063,37.373717],
            [-121.970102,37.373718],
            [-121.970115,37.37372],
            [-121.970118,37.373721],
            [-121.970118,37.373722],
            [-121.970118,37.373723],
            [-121.970115,37.373724],
            [-121.970102,37.373726],
            [-121.970063,37.373727],
            [-121.969988,37.373727],
            [-121.969933,37.373727],
            [-121.969879,37.373727],
            [-121.969804,37.373727],
            [-121.969765,37.373726],
            [-121.969752,37.373724],
            [-121.969749,37.373723],
            [-121.969749,37.373722]],
            [[-121.969749,37.373631],
            [-121.969749,37.373549],
            [-121.969933,37.373549],
            [-121.970118,37.373549],
            [-121.970118,37.373631],
            [-121.970118,37.373713],
            [-121.969933,37.373713],
            [-121.969749,37.373713],
            [-121.969749,37.373631]],
            [[-121.97156,37.37359],
            [-121.97156,37.373635],
            [-121.971135,37.373635],
            [-121.97071,37.373635],
            [-121.97071,37.37359],
            [-121.97071,37.373545],
            [-121.971135,37.373545],
            [-121.97156,37.373545],
            [-121.97156,37.37359]],
            [[-121.971886,37.37359],
            [-121.971886,37.373549],
            [-121.971912,37.373549],
            [-121.971938,37.373549],
            [-121.971938,37.37359],
            [-121.971938,37.373631],
            [-121.971912,37.373631],
            [-121.971886,37.373631],
            [-121.971886,37.37359]],
            [[-121.971835,37.37359],
            [-121.971835,37.373549],
            [-121.971858,37.373549],
            [-121.971882,37.373549],
            [-121.971882,37.37359],
            [-121.971882,37.373631],
            [-121.971858,37.373631],
            [-121.971835,37.373631],
            [-121.971835,37.37359]],
            [[-121.971783,37.37359],
            [-121.971783,37.373549],
            [-121.971807,37.373549],
            [-121.97183,37.373549],
            [-121.97183,37.37359],
            [-121.97183,37.373631],
            [-121.971807,37.373631],
            [-121.971783,37.373631],
            [-121.971783,37.37359]],
            [[-121.971727,37.37359],
            [-121.971727,37.373549],
            [-121.971753,37.373549],
            [-121.971779,37.373549],
            [-121.971779,37.37359],
            [-121.971779,37.373631],
            [-121.971753,37.373631],
            [-121.971727,37.373631],
            [-121.971727,37.37359]],
            [[-121.971504,37.37359],
            [-121.971504,37.373549],
            [-121.97153,37.373549],
            [-121.971556,37.373549],
            [-121.971556,37.37359],
            [-121.971556,37.373631],
            [-121.97153,37.373631],
            [-121.971504,37.373631],
            [-121.971504,37.37359]],
            [[-121.971453,37.37359],
            [-121.971453,37.373549],
            [-121.971476,37.373549],
            [-121.9715,37.373549],
            [-121.9715,37.37359],
            [-121.9715,37.373631],
            [-121.971476,37.373631],
            [-121.971453,37.373631],
            [-121.971453,37.37359]],
            [[-121.971401,37.37359],
            [-121.971401,37.373549],
            [-121.971425,37.373549],
            [-121.971448,37.373549],
            [-121.971448,37.37359],
            [-121.971448,37.373631],
            [-121.971425,37.373631],
            [-121.971401,37.373631],
            [-121.971401,37.37359]],
            [[-121.971345,37.37359],
            [-121.971345,37.373549],
            [-121.971371,37.373549],
            [-121.971397,37.373549],
            [-121.971397,37.37359],
            [-121.971397,37.373631],
            [-121.971371,37.373631],
            [-121.971345,37.373631],
            [-121.971345,37.37359]],
            [[-121.971294,37.37359],
            [-121.971294,37.373549],
            [-121.971317,37.373549],
            [-121.971341,37.373549],
            [-121.971341,37.37359],
            [-121.971341,37.373631],
            [-121.971317,37.373631],
            [-121.971294,37.373631],
            [-121.971294,37.37359]],
            [[-121.971238,37.37359],
            [-121.971238,37.373549],
            [-121.971264,37.373549],
            [-121.97129,37.373549],
            [-121.97129,37.37359],
            [-121.97129,37.373631],
            [-121.971264,37.373631],
            [-121.971238,37.373631],
            [-121.971238,37.37359]],
            [[-121.971191,37.37359],
            [-121.971191,37.373549],
            [-121.971212,37.373549],
            [-121.971234,37.373549],
            [-121.971234,37.37359],
            [-121.971234,37.373631],
            [-121.971212,37.373631],
            [-121.971191,37.373631],
            [-121.971191,37.37359]],
            [[-121.971135,37.37359],
            [-121.971135,37.373549],
            [-121.971161,37.373549],
            [-121.971187,37.373549],
            [-121.971187,37.37359],
            [-121.971187,37.373631],
            [-121.971161,37.373631],
            [-121.971135,37.373631],
            [-121.971135,37.37359]],
            [[-121.971084,37.37359],
            [-121.971084,37.373549],
            [-121.971107,37.373549],
            [-121.971131,37.373549],
            [-121.971131,37.37359],
            [-121.971131,37.373631],
            [-121.971107,37.373631],
            [-121.971084,37.373631],
            [-121.971084,37.37359]],
            [[-121.971032,37.37359],
            [-121.971032,37.373549],
            [-121.971056,37.373549],
            [-121.971079,37.373549],
            [-121.971079,37.37359],
            [-121.971079,37.373631],
            [-121.971056,37.373631],
            [-121.971032,37.373631],
            [-121.971032,37.37359]],
            [[-121.970976,37.37359],
            [-121.970976,37.373549],
            [-121.971002,37.373549],
            [-121.971028,37.373549],
            [-121.971028,37.37359],
            [-121.971028,37.373631],
            [-121.971002,37.373631],
            [-121.970976,37.373631],
            [-121.970976,37.37359]],
            [[-121.970925,37.37359],
            [-121.970925,37.373549],
            [-121.970948,37.373549],
            [-121.970972,37.373549],
            [-121.970972,37.37359],
            [-121.970972,37.373631],
            [-121.970948,37.373631],
            [-121.970925,37.373631],
            [-121.970925,37.37359]],
            [[-121.970873,37.37359],
            [-121.970873,37.373549],
            [-121.970897,37.373549],
            [-121.970921,37.373549],
            [-121.970921,37.37359],
            [-121.970921,37.373631],
            [-121.970897,37.373631],
            [-121.970873,37.373631],
            [-121.970873,37.37359]],
            [[-121.970818,37.37359],
            [-121.970818,37.373549],
            [-121.970843,37.373549],
            [-121.970869,37.373549],
            [-121.970869,37.37359],
            [-121.970869,37.373631],
            [-121.970843,37.373631],
            [-121.970818,37.373631],
            [-121.970818,37.37359]],
            [[-121.970766,37.37359],
            [-121.970766,37.373549],
            [-121.97079,37.373549],
            [-121.970813,37.373549],
            [-121.970813,37.37359],
            [-121.970813,37.373631],
            [-121.97079,37.373631],
            [-121.970766,37.373631],
            [-121.970766,37.37359]],
            [[-121.970715,37.37359],
            [-121.970715,37.373549],
            [-121.970738,37.373549],
            [-121.970762,37.373549],
            [-121.970762,37.37359],
            [-121.970762,37.373631],
            [-121.970738,37.373631],
            [-121.970715,37.373631],
            [-121.970715,37.37359]],
            [[-121.970491,37.37359],
            [-121.970491,37.373549],
            [-121.970515,37.373549],
            [-121.970539,37.373549],
            [-121.970539,37.37359],
            [-121.970539,37.373631],
            [-121.970515,37.373631],
            [-121.970491,37.373631],
            [-121.970491,37.37359]],
            [[-121.97044,37.37359],
            [-121.97044,37.373549],
            [-121.970463,37.373549],
            [-121.970487,37.373549],
            [-121.970487,37.37359],
            [-121.970487,37.373631],
            [-121.970463,37.373631],
            [-121.97044,37.373631],
            [-121.97044,37.37359]],
            [[-121.970384,37.37359],
            [-121.970384,37.373549],
            [-121.97041,37.373549],
            [-121.970436,37.373549],
            [-121.970436,37.37359],
            [-121.970436,37.373631],
            [-121.97041,37.373631],
            [-121.970384,37.373631],
            [-121.970384,37.37359]],
            [[-121.970333,37.37359],
            [-121.970333,37.373549],
            [-121.970356,37.373549],
            [-121.97038,37.373549],
            [-121.97038,37.37359],
            [-121.97038,37.373631],
            [-121.970356,37.373631],
            [-121.970333,37.373631],
            [-121.970333,37.37359]],
            [[-121.970281,37.37359],
            [-121.970281,37.373549],
            [-121.970305,37.373549],
            [-121.970328,37.373549],
            [-121.970328,37.37359],
            [-121.970328,37.373631],
            [-121.970305,37.373631],
            [-121.970281,37.373631],
            [-121.970281,37.37359]],
            [[-121.970225,37.37359],
            [-121.970225,37.373549],
            [-121.970251,37.373549],
            [-121.970277,37.373549],
            [-121.970277,37.37359],
            [-121.970277,37.373631],
            [-121.970251,37.373631],
            [-121.970225,37.373631],
            [-121.970225,37.37359]],
            [[-121.970174,37.37359],
            [-121.970174,37.373549],
            [-121.970197,37.373549],
            [-121.970221,37.373549],
            [-121.970221,37.37359],
            [-121.970221,37.373631],
            [-121.970197,37.373631],
            [-121.970174,37.373631],
            [-121.970174,37.37359]],
            [[-121.970122,37.37359],
            [-121.970122,37.373549],
            [-121.970146,37.373549],
            [-121.970169,37.373549],
            [-121.970169,37.37359],
            [-121.970169,37.373631],
            [-121.970146,37.373631],
            [-121.970122,37.373631],
            [-121.970122,37.37359]
          ]],
        } 
      }
    ]
  };

  export const buildingData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
            [10, -80],
            [-80, -80],
            [-80, 80],
            [40, 80],
            [40, -20],
            [100, -20],
            [100, -80],
            [30, -80],
            [30, -74],
            [34, -74],
            [34, -68],
            [40, -68],
            [40, -74],
            [94, -74],
            [94, -26],
            [40, -26],
            [40, -60],
            [34, -60],
            [34, 74],
            [-74, 74],
            [-74, 30],
            [10, 30],
            [10, 24],
            [-74, 24],
            [-74, -24],
            [10, -24],
            [10, -30],
            [-74, -30],
            [-74, -74],
            [10, -74],
          ]
          ],
        },
      },
    ],
  };
  
  export const roomsData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          name: 'Cuarto Maq 1',
          square: 576,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-74, -30],
            [34, -30],
            [34, -74],
            [-74, -74],
          ]],
        },
      }, {
        type: 'Feature',
        properties: {
          name: 'Cuarto Maq 2',
          square: 600,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-74, 24],
            [34, 24],
            [34, -24],
            [-74, -24],
          ]],
        },
      }, {
        type: 'Feature',
        properties: {
          name: 'Cuarto Maq 3',
          square: 540,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-74, 74],
            [34, 74],
            [34, 30],
            [-74, 30],
          ]],
        },
      }, {
        type: 'Feature',
        properties: {
          name: 'Bombas',
          square: 288,
        },
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [40, -26],
            [94, -26],
            [94, -74],
            [40, -74],
          ]],
        },
      },
    ],
  };

  /*
  function getImageUrl(name) {
    return `./../assets/${name}.png`;
  }
  */

  export const equipmentData = {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-60, 40],
      },
      properties: {
        url: 'https://pic.onlinewebfonts.com/svg/img_97990.png',
        text: 'B1',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-40, 40],
      },
      properties: {
        url: 'https://pic.onlinewebfonts.com/svg/img_97990.png',
        text: 'B2',
      },
    }, {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-20, 40],
      },
      properties: {
        url: 'https://pic.onlinewebfonts.com/svg/img_97990.png',
        text: 'B3',
      },
    }],
  };

export const modes = ['Off', 'Horario automático', 'On'];

export const sistemas = [
  {
    sistema_id:1,
    descripcion:'HVAC',
  },
  {
    sistema_id:2,
    descripcion:'Iluminación',
  },
  {
    sistema_id:3,
    descripcion:'Energía',
  },
  {
    sistema_id:4,
    descripcion:'Transporte',
  },
];
export const monitorareas = [
  {
    monitor_area_id:1,
    descripcion:'Vista General',
    monitor_area_padre_id:null,
  },
  {
    monitor_area_id:2,
    descripcion:'Módulo A',
    monitor_area_padre_id:1,
  },
  {
    monitor_area_id:3,
    descripcion:'Módulo B',
    monitor_area_padre_id:1,
  },
  {
    monitor_area_id:4,
    descripcion:'Cuarto Máq OTE',
    monitor_area_padre_id:1,
  },
  {
    monitor_area_id:5,
    descripcion:'Cuarto Máq PTE',
    monitor_area_padre_id:1,
  },
  {
    monitor_area_id:6,
    descripcion:'A01',
    monitor_area_padre_id:2,
  },
  {
    monitor_area_id:7,
    descripcion:'A02',
    monitor_area_padre_id:2,
  },
  {
    monitor_area_id:8,
    descripcion:'A03',
    monitor_area_padre_id:2,
  },
  {
    monitor_area_id:9,
    descripcion:'A04',
    monitor_area_padre_id:2,
  },
  {
    monitor_area_id:10,
    descripcion:'A05',
    monitor_area_padre_id:2,
  },
  {
    monitor_area_id:11,
    descripcion:'Vista General 2',
    monitor_area_padre_id:null,
  },
  {
    monitor_area_id:12,
    descripcion:'Vista General 3',
    monitor_area_padre_id:null,
  },
  {
    monitor_area_id:13,
    descripcion:'Vista General 4',
    monitor_area_padre_id:null,
  },
];

export const taskdetails = [
  {
    idTarea:1,
    equipo:'BARH01 Bomba de recirculación',
    tipo:'Tarea',
    descripcion:'Engrasado de piezas mecánicas',
    cantidad:null,
    precio:null,
    total:null,
  },
  {
    idTarea:1,
    equipo:'BARH01 Bomba de recirculación',
    tipo:'Medición',
    descripcion:'Flujo de agua: 16 m3/s',
    cantidad:null,
    precio:null,
    total:null,
  },
  {
    idTarea:2,
    equipo:'BARH01 Bomba de recirculación',
    tipo:'Refacción',
    descripcion:'Cambio de empaques',
    cantidad:10,
    precio:50.00,
    total:500.00,
  },
  {
    idTarea:2,
    equipo:'RO02 Engranes principales',
    tipo:'Refacción',
    descripcion:'Cambio de engranes',
    cantidad:1,
    precio:6000.00,
    total:6000.00,
  },
];

export const services = [
  {
    idServicio:1,
    folio:672,
    fecha:'2022-02-15',
    titulo:'Mantenimiento Preventivo de bombas de recirculación',
    periodo:'Febrero 2022',
    poliza:'98824',
    estatus:'Facturado',
    importe:'$6,500',        
  },
  {
    idServicio:2,
    folio:673,
    fecha:'2022-02-16',
    titulo:'Mantenimiento Correctivo Fugas en mangueras',
    periodo:'Febrero 2022',
    poliza:'98824',
    estatus:'Enviado',
    importe:'$4,500',        
  },
];

export const listaprecios = [
  {
    idListaPrecio:1,
    codigo:'BARH01',
    descripcion:'Bomba de agua presurizada',
    numeroParte:'112473',
    marca:'Múnich',
    modelo:'BP-1209',
    um:'Pza',
    precio:1189.00,
    te:'Inmediata',
    condicion:'Contado',
    existencia:25,        
  },
  {
    idListaPrecio:2,
    codigo:'BAC01HP',
    descripcion:'Bomba de agua centrífuga 1hp',
    numeroParte:'824314',
    marca:'Pedrollo',
    modelo:'CPM-620',
    um:'Pza',
    precio:3600.00,
    te:'5 días',
    condicion:'Crédito',
    existencia:0,        
  },
];

export const inventarios = [
  {
    idExistencia:1,
    almacen:'REFACCIONES',
    codigo:'BARH01',
    descripcion:'Bomba de agua presurizada',
    numeroParte:'112473',
    marca:'Múnich',
    modelo:'BP-1209',    
    existencia:25,
    um:'Pza',
    precio:1189.00,            
  },
  {
    idExistencia:2,
    almacen:'REFACCIONES',
    codigo:'BAC01HP',
    descripcion:'Bomba de agua centrífuga 1hp',
    numeroParte:'824314',
    marca:'Pedrollo',
    modelo:'CPM-620',
    existencia:2,
    um:'Pza',
    precio:3600.00,
  },
];

export const tareas = [
  {
    idTarea:1,
    idGrupoTarea:1,
    nombre:'Revisar fugas visualmente',
    duracion: 10,
    descripcion:null,
    idProcedimiento:null,
    idTareaPadre:null,
    activo:true,             
  },
  {
    idTarea:2,
    idGrupoTarea:1,
    nombre:'Revisar lubricación de baleros',
    descripcion:'Revisar si aumenta la temperatura de los baleros',
    duracion: 20,
    idProcedimiento:null,
    idTareaPadre:null,
    activo:true,             
  },
];

export const grupotareas = [
  {
    idGrupoTarea:1,
    nombre:'Rodamientos',    
    activo:true,             
  },
  {
    idGrupoTarea:2,
    nombre:'Eléctricas',
    activo:true,             
  },
];

export const tareaspadre = [
  {
    idTareaPadre:1,
    nombre:'Revisión de Fugas',    
    activo:true,             
  },
];

export const procedimientos = [
  {
    idProcedimiento:1,
    nombre:'Procedimiento de revisión de fugas',    
    activo:true,             
  },
];

export const grupos = [
  {
    idGrupo:1,
    nombre:'Grupo de Alertas',            
  },
  {
    idGrupo:2,
    nombre:'Grupo de Artículos',          
  },
  {
    idGrupo:4,
    nombre:'Grupo de Mediciones',          
  },
  {
    idGrupo:5,
    nombre:'Grupo de Permisos',          
  },
  {
    idGrupo:6,
    nombre:'Grupo de Tareas',          
  },  
  {
    idGrupo:7,
    nombre:'Grupo de Usuarios',          
  },
  {
    idGrupo:8,
    nombre:'Grupo de Productos',          
  },
  {
    idGrupo:9,
    nombre:'Grupo de Configuraciones',          
  },
  {
    idGrupo:10,
    nombre:'Grupo de Acciones',
  },
  {
    idGrupo:11,
    nombre:'Grupo de Etapas',
  },
];

export const tipos = [
  {
    idTipo:1,
    nombre:'Tipo de Archivo',
  },
  {
    idTipo:2,
    nombre:'Tipo de Artículo',
  },
  {
    idTipo:3,
    nombre:'Tipo de Control',
  },
  {
    idTipo:4,
    nombre:'Tipo de Dato',
  },
  {
    idTipo:5,
    nombre:'Tipo de Documento',
  },
  {
    idTipo:6,
    nombre:'Tipo de Evento',
  },
  {
    idTipo:7,
    nombre:'Tipo de Evento de Geocerca',
  },
  {
    idTipo:8,
    nombre:'Tipo de Falla',
  },
  {
    idTipo:9,
    nombre:'Tipo de KPI',
  },
  {
    idTipo:10,
    nombre:'Tipo de Inventario Físico',
  },
  {
    idTipo:12,
    nombre:'Tipo de Layout',          
  },
  {
    idTipo:13,
    nombre:'Tipo de Log',          
  },
  {
    idTipo:15,
    nombre:'Tipo de Mantenimiento',          
  },
  {
    idTipo:16,
    nombre:'Tipo de Movimiento de Almacén',          
  },
  {
    idTipo:17,
    nombre:'Tipo de Movimiento de Artículo',          
  },
  {
    idTipo:18,
    nombre:'Tipo de Movimiento de Equipo',          
  },
  {
    idTipo:19,
    nombre:'Tipo de Notificación',          
  },
  {
    idTipo:20,
    nombre:'Tipo de Clasificación de Mantenimiento',          
  },
  {
    idTipo:21,
    nombre:'Tipo de Procedimiento',          
  },
  {
    idTipo:22,
    nombre:'Tipo de Reconocimiento de Alerta',          
  },
  {
    idTipo:23,
    nombre:'Tipo de Hallazgo de Auditoría',          
  },
  {
    idTipo:24,
    nombre:'Tipo de Frecuencia de Mantenimiento',          
  },
  {
    idTipo:25,
    nombre:'Tipo de Lista de Verificación',
  },
  {
    idTipo:26,
    nombre:'Tipo de Garantía',
  },
  {
    idTipo:27,
    nombre:'Tipo de Solicitud de Servicio',
  },
  {
    idTipo:28,
    nombre:'Tipo de Requisición',
  },
  {
    idTipo:29,
    nombre:'Tipo de Orden de Compra',
  },
];

export const estatus = [
  {
    idEstatus:1,
    nombre:'Estatus de Cotización',
  },
  {
    idEstatus:2,
    nombre:'Estatus de Inventario Físico',            
  },
  {
    idEstatus:3,
    nombre:'Estatus de Orden de Compra',          
  },
  {
    idEstatus:4,
    nombre:'Estatus de Orden de Trabajo',          
  },
  {
    idEstatus:5,
    nombre:'Estatus del Equipo de Orden de Trabajo',          
  },
  {
    idEstatus:6,
    nombre:'Estatus de Plan de Mantenimiento',          
  },
  {
    idEstatus:7,
    nombre:'Estatus de Reporte de Falla',          
  },
  {
    idEstatus:8,
    nombre:'Estatus de Requisición',          
  },
  {
    idEstatus:9,
    nombre:'Estatus de Tarea',          
  },
  {
    idEstatus:10,
    nombre:'Estatus de Actividad',          
  },
  {
    idEstatus:11,
    nombre:'Estatus de Lista de Verificación',
  },
  {
    idEstatus:12,
    nombre:'Estatus de Garantía',
  }
];


export const actividades = [{
  id_actividad: 1,
  id_usuario_asignado: 1,
  id_usuario: 1,
  titulo: 'Arranque con cliente Workin',
  fecha_inicio: '2022-03-08T00:00:00',
  fecha_fin: '2022-03-10T00:00:00',
  id_estatus: 1,
  id_prioridad: 1,
  avance: 20,
  id_actividad_padre: null,
}, {
  id_actividad: 2,
  id_usuario_asignado: 1,
  id_usuario: 1,
  titulo: 'Entrega de reporte de servicio a ACME',
  fecha_inicio: '2022-03-09T00:00:00',
  fecha_fin: '2022-03-09T00:00:00',
  id_estatus: 21,
  id_prioridad: 2,
  avance: 0,
  id_actividad_padre: null,
}];

export const taskTabs = [
  {
    id: 0,
    text: 'Habilidades',
    icon: 'user',
    content: 'Relción de tarea con habilidades',
  },
  {
    id: 1,
    text: 'Puestos',
    icon: 'comment',
    content: 'Relación de tarea con puestos',
  }
];

export const clientworkorders = [{
  id_orden_trabajo: 1,
  folio: 243,
  titulo: 'Mantenimiento Hidro de Alta',
  fecha: '6 Jun 2023 10:00 AM',
  tecnico: 'Juan Aguirre',
  id_estatus: 2,
}, {
  id_orden_trabajo: 2,
  folio: '',
  titulo: 'Mantenimiento Hidro Media',
  fecha: '14 Jul 2023 11:00 AM',
  tecnico: 'Pedro Rodriguez',
  id_estatus: 1,
}, {
  id_orden_trabajo: 2,
  folio: '',
  titulo: 'Cárcamo Pluvial',
  fecha: '14 Jul 2023 11:00 AM',
  tecnico: 'Pedro Rdz',
  id_estatus: 1,
}, {
  id_orden_trabajo: 2,
  folio: '',
  titulo: 'Cárcamo aguas negras',
  fecha: '23 Ago 2023 04:00 PM',
  tecnico: 'Pedro Rodriguez',
  id_estatus: 1,
}, {
  id_orden_trabajo: 2,
  folio: '',
  titulo: 'Cárcamo Pluvial',
  fecha: '14 Jul 2023 11:00 AM',
  tecnico: 'Pedro Rodriguez',
  id_estatus: 1,
}, {
  id_orden_trabajo: 2,
  folio: '',
  titulo: 'Cárcamo aguas negras',
  fecha: '23 Ago 2023 04:00 PM',
  tecnico: 'Pedro Rodriguez',
  id_estatus: 1,
}];



