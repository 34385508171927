
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxValidationRule, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxScrolling, DxPager, DxLookup
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import SpecialFieldService from '../services/special-field-service';
import TypeDataService from '../services/type-data-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxValidationRule,
    DxFilterRow,
    DxSelection,
    DxSearchPanel,
    DxSorting,
    DxScrolling,
    DxPager,
    DxLookup,
  },
  data() {
    return {      
      dataSourceSpecialFields: null,
      dataSourceDataTypes: null,
      dataSourceUnitOfMeasurements: null,
      caracteristicas: {},
      events: [],
      specialFieldService: null,
      typeDataService: null,
      unitOfMeasurementService: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.specialFieldService = new SpecialFieldService();
    this.typeDataService = new TypeDataService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
  },
  mounted() {
    this.typeDataService.getTipoDatos().then((data) => {
      this.dataSourceDataTypes=data;
      this.unitOfMeasurementService.getUnidadMedidas().then((data) => {
        this.dataSourceUnitOfMeasurements=data;
        this.specialFieldService.getCampos().then(data => this.dataSourceSpecialFields=data);
      });
    });
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editSpecialField(e) {
			this.specialFieldService.updateCampo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteSpecialField(e) {
			this.specialFieldService.deleteCampo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createSpecialField(e) {
			this.specialFieldService.createCampo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
