import axios from 'axios';

export default class StatusWorkOrderService {

	getEstatusOrdenTrabajos() {			
        const url = process.env.VUE_APP_APIURL + `estatusordentrabajos`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getCalendarioEstatusOrdenTrabajos() {			
        const url = process.env.VUE_APP_APIURL + `estatusordentrabajos/calendarios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEstatusOrdenTrabajo(estatusordentrabajo) {
        const url = process.env.VUE_APP_APIURL + `/estatusordentrabajos/${estatusordentrabajo.id_estatus_orden_trabajo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEstatusOrdenTrabajo(estatusordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/estatusordentrabajos/${estatusordentrabajo.id_estatus_orden_trabajo}`;
		return axios.put(url,estatusordentrabajo).then(res=>res.data.data);
	}

	createEstatusOrdenTrabajo(estatusordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/estatusordentrabajos`;
		return axios.post(url,estatusordentrabajo).then(res=>res.data.data);
	}

	deleteEstatusOrdenTrabajo(estatusordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/estatusordentrabajos/${estatusordentrabajo.id_estatus_orden_trabajo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}