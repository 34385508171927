
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxEditing,
  DxSorting
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import UserService from '../services/user-service';
import ClientUserService from '../services/client-user-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting
  },
  data() {
    return {
      user: null,
      dataSourceUsers: null,
      dataSourceClientAvailable: null,
      dataSourceClientAssigned: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
    };
  },
  created() {
    this.userService = new UserService();
    this.clientUserService = new ClientUserService();
  },
  mounted() {
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_cliente);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addClient() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
        this.clientUserService.createClienteUsuario({ "id_usuario": this.user.id_usuario, "id_cliente": this.selectedRowsData[this.i].id_cliente }).then(() => {
          if (this.i == this.selectedRowsData.length) {
            this.clientUserService.getClientesDisponiblesPorUsuario(this.user.id_usuario).then(data => this.dataSourceClientAvailable = data);
            this.clientUserService.getClientesAsignadosPorUsuario(this.user.id_usuario).then(data => this.dataSourceClientAssigned = data);
          }
        });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    valueChangedUser(e) {
      this.clientUserService.getClientesDisponiblesPorUsuario(e.value.id_usuario).then(data => this.dataSourceClientAvailable = data);
      this.clientUserService.getClientesAsignadosPorUsuario(e.value.id_usuario).then(data => this.dataSourceClientAssigned = data);
    },
    deleteClient(e) {
      this.clientUserService.deleteClienteUsuario(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.clientUserService.getClientesDisponiblesPorUsuario(this.user.id_usuario).then(data => this.dataSourceClientAvailable = data);
          this.clientUserService.getClientesAsignadosPorUsuario(this.user.id_usuario).then(data => this.dataSourceClientAssigned = data);
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
  },
};
