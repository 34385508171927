import axios from 'axios';

export default class AlertOriginService {

	getOrigenAlertas() {		
        const url = process.env.VUE_APP_APIURL + `origenalertas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrigenAlerta(origenalerta) {
        const url = process.env.VUE_APP_APIURL + `/origenalertas/${origenalerta.id_origen_alerta}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrigenAlerta(origenalerta){
        const url = process.env.VUE_APP_APIURL + `/origenalertas/${origenalerta.id_origen_alerta}`;
		return axios.put(url,origenalerta).then(res=>res.data.data);
	}

	createOrigenAlerta(origenalerta){
        const url = process.env.VUE_APP_APIURL + `/origenalertas`;
		return axios.post(url,origenalerta).then(res=>res.data.data);
	}

	deleteOrigenAlerta(origenalerta){
        const url = process.env.VUE_APP_APIURL + `/origenalertas/${origenalerta.id_origen_alerta}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}