import axios from 'axios';

export default class TaskMeasurementService {

    getTareaMedicionesPorTarea(tarea) {			
        const url = process.env.VUE_APP_APIURL + `tareamediciones/tareas/${tarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getTareaMediciones() {			
        const url = process.env.VUE_APP_APIURL + `tareamediciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTareaMedicion(tareamedicion) {
        const url = process.env.VUE_APP_APIURL + `/tareamediciones/${tareamedicion.id_tarea_medicion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTareaMedicion(tareamedicion){
        const url = process.env.VUE_APP_APIURL + `/tareamediciones/${tareamedicion.id_tarea_medicion}`;
		return axios.put(url,tareamedicion).then(res=>res.data.data);
	}

	createTareaMedicion(tareamedicion){
        const url = process.env.VUE_APP_APIURL + `/tareamediciones`;
		return axios.post(url,tareamedicion).then(res=>res.data.data);
	}

	deleteTareaMedicion(tareamedicion){
        const url = process.env.VUE_APP_APIURL + `/tareamediciones/${tareamedicion.id_tarea_medicion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}