
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSorting,
  DxSelection,
  DxLoadPanel,
  DxMasterDetail,
  DxToolbar,
  DxButton,
  DxSummary,
  DxTotalItem,
  DxForm as DxFormGrid,
} from 'devextreme-vue/data-grid';
import { DxForm, DxItem, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxToast } from 'devextreme-vue/toast';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
//import { DxButton } from 'devextreme-vue/button';
import auth from '../auth';
// Grid Principal
import WorkOrderService from '../services/workorder-service';
// Combos
import CompanyService from '../services/company-service';
//import CompanyClientService from '../services/company-client-service';
import ClientService from '../services/client-service';
import ConfigPlanMaintenanceService from '../services/config-plan-maintenance-service';
import TypeMaintenanceService from '../services/type-maintenance-service';
import PriorityService from '../services/priority-service';
import StatusWorkOrderService from '../services/status-wo-service';
import ProjectService from '../services/project-service';
import SupplierService from '../services/supplier-service';
import TypeMajorMinorService from '../services/type-major-minor-service';
import WorkOrderCostService from '../services/workorder-cost-service';
import WorkOrderSaleService from '../services/workorder-sale-service';
import StageService from '../services/stage-service';
import WorkOrderMasterDetail from './workorder-detail.vue';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSorting,
    DxSelection,
    DxLoadPanel,
    DxMasterDetail,
    WorkOrderMasterDetail,
    DxToolbar,
    DxButton,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxTabbedItem,
    DxTab,
    DxSummary,
    DxTotalItem,
    DxCheckBox,
    DxNumberBox,
    DxFormGrid
  },
  data() {
    return {
      idOrdenTrabajo: null,
      dataSourceStages: null,
      tipoCambioUSD: null,
      garantia: false,
      dataSourceWorkOrderSale: null,
      dataSourceWorkOrderCost: null,
      workorderFormData: { tipo_cambio: null, garantia: false },
      popupTitle: "Costo de Orden de Trabajo",
      popupVisible: false,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      gridRefName: 'dataGrid',
      empresas: null,
      clientes: null,
      dataSourceWorkOrders: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceMaintenancePlans: null,
      dataSourceMaintenanceTypes: null,
      dataSourcePriorities: null,
      dataSourceStatus: null,
      dataSourceSuppliers: null,
      dataSourceProjects: null,
      dataSourceMajorMinorTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Orden de Trabajo',
        showTitle: true,
        width: 850,
        height: 500,
      },
      mode: 'select',
      usuarioId: null,
    };
  },
  created() {
    this.workOrderService = new WorkOrderService();
    this.typeMaintenanceService = new TypeMaintenanceService();
    this.clientService = new ClientService();
    this.configPlanMaintenanceService = new ConfigPlanMaintenanceService();
    this.companyService = new CompanyService();
    this.priorityService = new PriorityService();
    this.statusWorkOrderService = new StatusWorkOrderService();
    this.projectService = new ProjectService();
    this.supplierService = new SupplierService();
    this.typeMajorMinorService = new TypeMajorMinorService();
    this.workOrderSaleService = new WorkOrderSaleService();
    this.workOrderCostService = new WorkOrderCostService();
    this.stageService = new StageService();

  },
  mounted() {
    this.stageService.getEtapas().then(data => this.dataSourceStages = data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.typeMaintenanceService.getTipoMantenimientos().then(data => this.dataSourceMaintenanceTypes = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers = data);
    this.configPlanMaintenanceService.getConfigPlanMttos().then(data => this.dataSourceMaintenancePlans = data);
    this.priorityService.getPrioridades().then(data => this.dataSourcePriorities = data);
    this.statusWorkOrderService.getEstatusOrdenTrabajos().then(data => this.dataSourceStatus = data);
    this.typeMajorMinorService.getTipoMayorMenores().then(data => this.dataSourceMajorMinorTypes = data);
    this.projectService.getProyectos().then(data => this.dataSourceProjects = data);
    this.workOrderService.getOrdenTrabajoActivas().then(data => this.dataSourceWorkOrders = data);

  },
  methods: {
    editWorkOrderCost(e) {
      if (e.data.id_orden_trabajo_costo != null) {
        this.workOrderCostService.updateOrdenTrabajoCosto(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.workOrderCostService.getOrdenTrabajoCostos(e.data.id_orden_trabajo).then(data => this.dataSourceWorkOrderCost = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al actualizar el registro';
          this.isVisible = true;
          this.workOrderCostService.getOrdenTrabajoCostos(e.data.id_orden_trabajo).then(data => this.dataSourceWorkOrderCost = data);
        });
      }
    },
    editWorkOrderSale(e) {
      if (e.data.id_orden_trabajo_venta != null) {
        this.workOrderSaleService.updateOrdenTrabajoVenta(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.workOrderSaleService.getOrdenTrabajoVentas(e.data.id_orden_trabajo).then(data => this.dataSourceWorkOrderSale = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al actualizar el registro';
          this.isVisible = true;
          this.workOrderSaleService.getOrdenTrabajoVentas(e.data.id_orden_trabajo).then(data => this.dataSourceWorkOrderSale = data);
        });
      }

    },
    popupCost(e) {
      this.popupVisible = true;
      this.popupTitle = "Costos de Orden de Trabajo " + e.row.data.folio;
      this.idOrdenTrabajo = e.row.data.id_orden_trabajo;
      this.tipoCambioUSD = e.row.data.tipo_cambio_usd;
      this.garantia = e.row.data.garantia;
      this.workOrderCostService.getOrdenTrabajoCostos(e.row.data.id_orden_trabajo).then(data => this.dataSourceWorkOrderCost = data);
      this.workOrderSaleService.getOrdenTrabajoVentas(e.row.data.id_orden_trabajo).then(data => this.dataSourceWorkOrderSale = data);
    },
    saveCost() {
      this.popupVisible = false;
      // Actualizar Garantía y tipo de cambio
      this.workOrderService.updateGarantia({ "id_orden_trabajo": this.idOrdenTrabajo, "garantia": this.garantia, "tipo_cambio_usd": this.tipoCambioUSD }).then(() => {
        this.cleanForm();
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
      });
    },
    closeClick() {
      this.cleanForm();
      this.popupVisible = false;
    },
    cleanForm() {
      this.idOrdenTrabajo = null;
      this.dataSourceWorkOrderCost = null;
      this.dataSourceWorkOrderSale = null;
      this.tipoCambioUSD = null;
      this.garantia = false;
    },
    refreshDataGrid() {
      this.workOrderService.getOrdenTrabajoActivas().then(data => this.dataSourceWorkOrders = data);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editWorkOrder(e) {
      auth.getUser().then((data) => {
        this.usuarioId = data.data.id_usuario;
        // Si se autoriza
        if (e.data.id_estatus_orden_trabajo == 2) {
          this.workOrderService.updateCambioEstatusOrdenTrabajo({ "id_orden_trabajo": e.data.id_orden_trabajo, "id_estatus_orden_trabajo": e.data.id_estatus_orden_trabajo, "id_usuario": this.usuarioId }).then(() => {
            this.type = 'success';
            this.message = 'Se ha actulizado el registro';
            this.isVisible = true;
          });
        }
        this.workOrderService.updateOrdenTrabajo({
          "id_orden_trabajo": e.data.id_orden_trabajo, "id_empresa": e.data.id_empresa, "id_cliente": e.data.id_cliente, "fecha_inicio_plan": e.data.fecha_inicio_plan, "titulo": e.data.titulo, "descripcion": e.data.descripcion,
          "id_origen_orden_trabajo": e.data.id_origen_orden_trabajo, "id_tipo_mantenimiento": e.data.id_tipo_mantenimiento, "id_prioridad": e.data.id_prioridad, "id_usuario_mod": this.usuarioId,
          "id_config_plan_mtto": e.data.id_config_plan_mtto, "id_proyecto": e.data.id_proyecto, "id_proveedor": e.data.id_proveedor,
          "id_tipo_mayor_menor": e.data.id_tipo_mayor_menor, "visible_cliente": e.data.visible_cliente, "reporte_servicio": e.data.reporte_servicio,
          "id_estatus_orden_trabajo": e.data.id_estatus_orden_trabajo
        }).then(() => {
          this.type = 'success';
          this.message = 'Se ha actulizado el registro';
          this.isVisible = true;
          this.workOrderService.getOrdenTrabajoActivas().then(data => this.dataSourceWorkOrders = data);
        });
      });
    },
    deleteWorkOrder(e) {
      e.data.id_usuario_reg = localStorage.getItem("user-data");
      this.workOrderService.deleteOrdenTrabajo(e.data).then(() => {
        this.workOrderService.getOrdenTrabajoActivas().then(data => this.dataSourceWorkOrders = data);
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createWorkOrder(e) {
      // TODO id_origen_orden_trabajo ponerlo como enumerable
      auth.getUser().then((data) => {
        this.usuarioId = data.data.id_usuario;
        this.workOrderService.createOrdenTrabajo({
          "id_empresa": e.data.id_empresa, "id_cliente": e.data.id_cliente, "fecha_inicio_plan": e.data.fecha_inicio_plan, "titulo": e.data.titulo, "descripcion": e.data.descripcion,
          "id_origen_orden_trabajo": 3, "id_tipo_mantenimiento": e.data.id_tipo_mantenimiento, "id_prioridad": e.data.id_prioridad, "id_usuario_mod": this.usuarioId,
          "id_config_plan_mtto": e.data.id_config_plan_mtto, "id_proyecto": e.data.id_proyecto, "id_proveedor": e.data.id_proveedor,
          "id_tipo_mayor_menor": e.data.id_tipo_mayor_menor, "visible_cliente": e.data.visible_cliente, "reporte_servicio": e.data.reporte_servicio,
          "id_estatus_orden_trabajo": e.data.id_estatus_orden_trabajo
        }).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.workOrderService.getOrdenTrabajoActivas().then(data => this.dataSourceWorkOrders = data);
        });
      });

    },
    onInitNewRow(e) {
      e.data.id_estatus_orden_trabajo = 1;
      e.data.visible_cliente = true;
      e.data.reporte_servicio = true;
      console.log(e.data.folio);
      this.popupTitle = "Costos de Orden de Trabajo " + e.data.folio;
    },
    onRowPrepared(e) {
      if (e.rowType === "data") {
        if (e.data.id_estatus_orden_trabajo === 6) {
          e.rowElement.style.backgroundColor = "red";
        }
        console.log(e.data.folio);
        this.popupTitle = "Costos de Orden de Trabajo " + e.data.folio;
      }
    },
  },
};
