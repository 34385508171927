import axios from 'axios';

export default class CompanyAddressService {

	getEmpresaDirecciones() {		
        const url = process.env.VUE_APP_APIURL + `empresadirecciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEmpresaDireccion(empresadireccion) {
        const url = process.env.VUE_APP_APIURL + `/empresadirecciones/${empresadireccion.id_empresa_direccion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEmpresaDireccion(empresadireccion){
        const url = process.env.VUE_APP_APIURL + `/empresadirecciones/${empresadireccion.id_empresa_direccion}`;
		return axios.put(url,empresadireccion).then(res=>res.data.data);
	}

	createEmpresaDireccion(empresadireccion){
        const url = process.env.VUE_APP_APIURL + `/empresadirecciones`;
		return axios.post(url,empresadireccion).then(res=>res.data.data);
	}

	deleteEmpresaDireccion(empresadireccion){
        const url = process.env.VUE_APP_APIURL + `/empresadirecciones/${empresadireccion.id_empresa_direccion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}