
import DxTreeView from "devextreme-vue/ui/tree-view";
import { sizes } from "../utils/media-query";
// import navigation from '../app-navigation';
import { onMounted, ref, watch, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";

export default {
  props: {
    compactMode: Boolean,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();

    const isLargeScreen = sizes()["screen-x-small"];

    const treeViewRef = ref(null);

    function forwardClick(...args) {
      context.emit("click", args);
    }

    function handleItemClick(e) {
      if (!e.itemData.path || props.compactMode) {
        return;
      }
      router.push(e.itemData.path);

      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
    }

    function updateSelection() {
      if (!treeViewRef.value || !treeViewRef.value.instance) {
        return;
      }

      treeViewRef.value.instance.selectItem(route.path);
      treeViewRef.value.instance.expandItem(route.path);
    }

    var items = ref([]);

    onBeforeMount(async () => {
      const id_usuario = localStorage.getItem('user-data');
      const url =
        process.env.VUE_APP_APIURL + `permisos/menusweb` + "?id_usuario=" + id_usuario;
      const res = await axios.get(url);
      items.value = res.data.data.map((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        return { ...item, expanded: isLargeScreen };
      });
    });

    onMounted(async () => {
      updateSelection();
      // JGM
      treeViewRef.value.instance.collapseAll();
      if (props.compactMode) {
        treeViewRef.value.instance.collapseAll();
      }
    });

    watch(
      () => route.path,
      () => {
        updateSelection();
      }
    );

    watch(
      () => props.compactMode,
      () => {
        if (props.compactMode) {
          treeViewRef.value.instance.collapseAll();
        } else {
          updateSelection();
        }
      }
    );

    return {
      treeViewRef,
      items,
      forwardClick,
      handleItemClick,
      updateSelection,
    };
  },
  components: {
    DxTreeView,
  },
};
