import axios from 'axios';

export default class PermitService {

	getPermisos() {			
        const url = process.env.VUE_APP_APIURL + `permisos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getPermiso(permiso) {
        const url = process.env.VUE_APP_APIURL + `/permisos/${permiso.id_permiso}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updatePermiso(permiso){
        const url = process.env.VUE_APP_APIURL + `/permisos/${permiso.id_permiso}`;
		return axios.put(url,permiso).then(res=>res.data.data);
	}

	createPermiso(permiso){
        const url = process.env.VUE_APP_APIURL + `/permisos`;
		return axios.post(url,permiso).then(res=>res.data.data);
	}

	deletePermiso(permiso){
        const url = process.env.VUE_APP_APIURL + `/permisos/${permiso.id_permiso}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}