
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxEditing,
    DxSorting,
} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import { DxToast } from 'devextreme-vue/toast';

import LocationTabService from '../services/location-tab-service';
import ItemService from '../services/item-service';
import ItemLocationService from '../services/item-location-service';
import WarehouseService from '../services/warehouse-service';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxSelection,
        DxEditing,
        DxSorting,
        DxSelectBox,
        DxToast,
        Button
    },
    data() {
        return {
            searchModeOption: 'contains',
            searchExprOptionlocaition: 'ubicacionstring',
            searchExprOptionitem: 'articulo',
            searchTimeoutOption: 200,
            minSearchLengthOption: 0,
            showDataBeforeSearchOption: false,
            dataSourceLocation: null,
            dataSourceItems: null,
            dataSourceItemsAvailable: null,
            dataSourceItemsAssigned: null,
            dataSourceWarehouses: null,
            location: null,
            warehouses: null,
            items: null,
            selectedRowsData: [],
            imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
            i: 0,
        }
    },
    created() {
        this.itemService = new ItemService();
        this.locationTabService = new LocationTabService();
        this.itemLocationService = new ItemLocationService();
        this.warehouseService = new WarehouseService();
    },
    mounted() {
        this.locationTabService.getUbicaciones().then((data) => {
            let ubicaciones = [];
            data.forEach(element => {
                element.ubicacionstring = element.ubicacion_area + ' - ' + element.ubicacion_pasillo + ' - ' + element.ubicacion_grupo_estante + ' - ' + element.ubicacion_estante + ' - ' + element.ubicacion_nivel;
                ubicaciones.push(element);
            });
            this.dataSourceLocation = ubicaciones;
        });
        this.itemService.getArticuloComboActivos().then(data => this.dataSourceItems = data);
        this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouses = data);
    },
    computed: {
        selectedRowKeys() {
            return this.selectedRowsData.map((data) => data.id_articulo);
        },
    },
    methods: {
        onSelectionChanged({ selectedRowsData }) {
            this.selectedRowsData = selectedRowsData;
        },
        refreshDataGrid() {
            if (this.location != null && this.warehouses != null) {
                this.itemLocationService.getArticuloUbicacionDisponibles(this.location.id_ubicacion, this.warehouses.id_almacen).then(
                    (data) => {
                        this.dataSourceItemsAvailable = data;
                    }
                );
                this.itemLocationService.getArticuloUbicacionAsignados(this.location.id_ubicacion, this.warehouses.id_almacen).then(
                    (data) => {
                        this.dataSourceItemsAssigned = data;
                    }
                );
            }

        },
        valueChangedLocation() {
            this.refreshDataGrid();
        },
        valueChangedWarehouses() {
            this.refreshDataGrid();
        },
        addlocationitem() {
            if (this.location != null && this.warehouses != null) {
                for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
                    let datajson = {
                        'id_ubicacion': this.location.id_ubicacion,
                        'id_almacen': this.warehouses.id_almacen,
                        'id_articulo': this.selectedRowsData[this.i].id_articulo
                    };

                    this.itemLocationService.createArticulosUbicaciones(datajson).then(() => {
                        if (this.i == this.selectedRowsData.length) {
                            this.refreshDataGrid();
                        }

                    });
                }
            } else {
                this.type = 'error';
                this.message = 'Selecciona una ubicacion y almacén';
                this.isVisible = true;
            }


        },
        deleteitem(e) {
            this.itemLocationService.deleteArticulosUbicaciones(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha eliminado el registro';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },

    },
}
