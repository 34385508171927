import axios from 'axios';

export default class TaskService {


	getTareasConGrupos() {
		const url = process.env.VUE_APP_APIURL + `/tareas/congrupos`;
		return axios.get(url).then(res=>res.data.data);
	}

	getTareasPorCategoria(equipocategoria) {
		const url = process.env.VUE_APP_APIURL + `/tareas/equipocategorias/${equipocategoria}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getTareasPorGrupo(grupotarea) {
		const url = process.env.VUE_APP_APIURL + `/tareas/grupos/${grupotarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getTareasComunes() {
		const url = process.env.VUE_APP_APIURL + `/tareas/comunes`;
		return axios.get(url).then(res=>res.data.data);
	}

	getTareasComunesPorGrupo(grupotarea) {
		const url = process.env.VUE_APP_APIURL + `/tareas/comunes/grupos/${grupotarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getTareas() {		
        const url = process.env.VUE_APP_APIURL + `tareas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTarea(tarea) {
        const url = process.env.VUE_APP_APIURL + `/tareas/${tarea.id_tarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTarea(tarea){
        const url = process.env.VUE_APP_APIURL + `/tareas/${tarea.id_tarea}`;
		return axios.put(url,tarea).then(res=>res.data.data);
	}

	createTarea(tarea){
        const url = process.env.VUE_APP_APIURL + `/tareas`;
		return axios.post(url,tarea).then(res=>res.data.data);
	}

	deleteTarea(tarea){
        const url = process.env.VUE_APP_APIURL + `/tareas/${tarea.id_tarea}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}