
import {
  DxTreeList,
  DxColumn,
  DxLookup, 
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxEditing,
  DxSorting
} from 'devextreme-vue/tree-list';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import MaintenancePolicyService from '../services/maintenance-policy-service';
import MaintenancePolicyEquipmentService from '../services/maintenance-policy-equipment-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import ClientService from '../services/client-service';
import LocationService from '../services/location-service';
import LayoutService from '../services/layout-service';
import ConditionService from '../services/condition-service';
import CompanyService from '../services/company-service';

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting
  },
  data() {
    return {
      polizamantenimiento: null,      
      dataSourceMaintenancePolicies: null,
      dataSourceMaintenancePolicyEquipmentAvailable: null,
      dataSourceMaintenancePolicyEquipmentAssigned: null,
      dataSourceConditions: null,
      dataSourceLayouts: null,
      dataSourceClients: null,
      dataSourceLocations: null,
      dataSourceCompanies: null,
      dataSourceFatherEquipment: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      maintenancepolicy: null,
      i: 0,
    };
  },
  created() {
    this.maintenancePolicyService = new MaintenancePolicyService();
    this.maintenancePolicyEquipmentService = new MaintenancePolicyEquipmentService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.clientService = new ClientService();
    this.locationService = new LocationService();
    this.companyService = new CompanyService();
    this.layoutService = new LayoutService();
    this.conditionService = new ConditionService();
  },
  mounted() {    
    this.maintenancePolicyService.getPolizaMantenimientos().then(data => this.dataSourceMaintenancePolicies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.layoutService.getLayouts().then(data => this.dataSourceLayouts=data);
    this.conditionService.getCondiciones().then(data => this.dataSourceConditions=data);  
    this.equipmentCompanyService.getEquipoEmpresasPadres().then(data => this.dataSourceFatherEquipment=data);    
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_equipo_empresa);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addEquipment() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.maintenancePolicyEquipmentService.createPolizaMantenimientoEquipo({"id_poliza_mantenimiento": this.maintenancepolicy.id_poliza_mantenimiento, "id_equipo_empresa": this.selectedRowsData[this.i].id_equipo_empresa}).then(() => {           
            
            if (this.i==this.selectedRowsData.length) {
              
              this.maintenancePolicyEquipmentService.getEquiposDisponiblesPorPoliza(this.maintenancepolicy.id_poliza_mantenimiento).then(data => this.dataSourceMaintenancePolicyEquipmentAvailable=data);
              this.maintenancePolicyEquipmentService.getEquiposAsignadosPorPoliza(this.maintenancepolicy.id_poliza_mantenimiento).then(data => this.dataSourceMaintenancePolicyEquipmentAssigned=data);
            }
            
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      //console.log(selectedRowsData[0].id_equipo_empresa);
      this.selectedRowsData = selectedRowsData;
      /*
      console.log(this.maintenancepolicy);
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          console.log(this.selectedRowsData[this.i].id_equipo_empresa);
      }
      */
    },
    /*
    selectFirstRow(e) {
        //const rowKey = e.component.getKeyByRowIndex(0);
        this.selectedRowKeys = [...this.selectedRowKeys, e.value.id_poliza_mantenimiento];
        console.log(this.selectedRowKeys);
    },
    */
    valueChangedMaintenancePolicy(e) {
        this.maintenancePolicyEquipmentService.getEquiposDisponiblesPorPoliza(e.value.id_poliza_mantenimiento).then(data => this.dataSourceMaintenancePolicyEquipmentAvailable=data);
        this.maintenancePolicyEquipmentService.getEquiposAsignadosPorPoliza(e.value.id_poliza_mantenimiento).then(data => this.dataSourceMaintenancePolicyEquipmentAssigned=data);           
    },
    deleteMaintenancePolicy(e) {
      
      this.maintenancePolicyEquipmentService.deletePolizaMantenimientoEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.maintenancePolicyEquipmentService.getEquiposDisponiblesPorPoliza(this.maintenancepolicy.id_poliza_mantenimiento).then(data => this.dataSourceMaintenancePolicyEquipmentAvailable=data);
        this.maintenancePolicyEquipmentService.getEquiposAsignadosPorPoliza(this.maintenancepolicy.id_poliza_mantenimiento).then(data => this.dataSourceMaintenancePolicyEquipmentAssigned=data); 
      });
    }
    
  },  
};
