import axios from 'axios';

export default class EquipmentCompanyMeasurementStatusService {

	getEquipoEmpresaMedicionEstatusPorEquipoEmpresa(equipoempresa) {		
        const url = process.env.VUE_APP_APIURL + `equipoempresamedicionestatus/equipoempresas/${equipoempresa}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoEmpresaMedicionEstatus(equipoempresamedicionestatus) {
        const url = process.env.VUE_APP_APIURL + `/equipoempresamedicionestatus/${equipoempresamedicionestatus.id_equipo_empresa_medicion_estatus}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoEmpresaMedicionEstatus(equipoempresamedicionestatus){
        const url = process.env.VUE_APP_APIURL + `/equipoempresamedicionestatus/${equipoempresamedicionestatus.id_equipo_empresa_medicion_estatus}`;
		return axios.put(url,equipoempresamedicionestatus).then(res=>res.data.data);
	}

	createEquipoEmpresaMedicionEstatus(equipoempresamedicionestatus){
        const url = process.env.VUE_APP_APIURL + `/equipoempresamedicionestatus`;
		return axios.post(url,equipoempresamedicionestatus).then(res=>res.data.data);		
	}

	deleteEquipoEmpresaMedicionEstatus(equipoempresamedicionestatus){
        const url = process.env.VUE_APP_APIURL + `/equipoempresamedicionestatus/${equipoempresamedicionestatus.id_equipo_empresa_medicion_estatus}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}