
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import TaskReasonService from '../services/task-reason-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceTaskReasons: null,
      razontarea: {},
      events: [],
      taskReasonService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.taskReasonService = new TaskReasonService();
  },
  mounted() {    
    this.taskReasonService.getRazonTareas().then(data => this.dataSourceTaskReasons=data);
  },
  methods: {    
    editTaskReason(e) {
			this.taskReasonService.updateRazonTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteTaskReason(e) {
			this.taskReasonService.deleteRazonTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createTaskReason(e) {
			this.taskReasonService.createRazonTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
