
import AppFooter from "./components/app-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed
} from "vue";
import { loadMessages, locale } from "devextreme/localization";
import esMessages from "devextreme/localization/messages/es.json";
import enMessages from "devextreme/localization/messages/en.json";
// import auth from "./auth";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
  };
}

export default {
  components: {
    AppFooter
  },
  setup() {
    const vm = getCurrentInstance();

    const title = vm.proxy.$appInfo.title;
    const screen = reactive({ getScreenSizeInfo: {} });
    screen.getScreenSizeInfo = getScreenSizeInfo();

    function screenSizeChanged() {
      screen.getScreenSizeInfo = getScreenSizeInfo();
    }

    onMounted(() => {
      subscribe(screenSizeChanged);
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => {
      return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
    });

    return {
      title,
      screen,
      cssClasses
    };
  },
  created() {
    loadMessages(esMessages);
    loadMessages(enMessages);
    locale(navigator.language);
  },
  mounted() {
    // this.$router.push({ path: "/login-form" });

    // console.log("App.vue");
    // auth.setUser(null);
    // Lógica para no pedir contraseña si existe el token
    // if (!auth.loggedIn()) {
    //   const auth_token = localStorage.getItem('user-token');
    //   if (!auth_token) {
    //     this.$router.push({ path: "/login-form" });
    //   }
    // }
    // Termina lógica de redireccionamiento

  },
};
