
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup,
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxSorting,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import GroupKpiService from '../services/group-kpi-service';
import TypeKpiService from '../services/type-kpi-service';
import KpiService from '../services/kpi-service';
import KpiSectionService from '../services/seccion-kpi-service';

export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxColumnChooser
  },
  data() {
    return {
      kpis: null,
      dataSourceKpis: null,
      dataSourceKPITypes: null,
      dataSourceGroupKpis: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'KPI',
        showTitle: true,
        height: 620,
        width: 800,
      },
      mode: 'select',
      dataSourceKPISection: null,
    };
  },
  created() {
    this.kpiService = new KpiService();
    this.groupKpiService = new GroupKpiService();
    this.typeKpiService = new TypeKpiService();
    this.kpisectionService = new KpiSectionService();
  },
  mounted() {
    this.groupKpiService.getGrupoKpis().then(data => this.dataSourceGroupKpis = data);
    this.typeKpiService.getTipoKPIs().then(data => this.dataSourceKPITypes = data);
    this.kpiService.getKPIs().then(data => this.dataSourceKpis = data);
    this.kpisectionService.getkpisection().then(data => this.dataSourceKPISection = data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editKpi(e) {
      this.kpiService.updateKPI(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.kpiService.getKPIs().then(data => this.dataSourceKpis = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.kpiService.getKPIs().then(data => this.dataSourceKpis = data);
      });
    },
    deleteKpi(e) {
      this.kpiService.deleteKPI(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.kpiService.getKPIs().then(data => this.dataSourceKpis = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.kpiService.getKPIs().then(data => this.dataSourceKpis = data);
      });
    },
    createKpi(e) {
      this.kpiService.createKPI(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.kpiService.getKPIs().then(data => this.dataSourceKpis = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.kpiService.getKPIs().then(data => this.dataSourceKpis = data);
      });
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'clave')) {
        e.editorOptions.disabled = true;
      }
    },
    onInitNewRow(e) {
      e.data.activo = true;
    },
  },
};
