import axios from "axios";

export default class ChecklistService {
  getListaVerificacionesPorEmpresa(empresa) {
    const url = process.env.VUE_APP_APIURL + `listaverificaciones/empresas`;
    return axios
      .get(url, { params: { id_empresa: empresa } })
      .then((res) => res.data.data);
  }

  getListaVerificaciones() {
    const url = process.env.VUE_APP_APIURL + `listaverificaciones`;
    return axios.get(url).then((res) => res.data.data);
  }

  getListaVerificacion(listaverificacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `listaverificaciones/${listaverificacion.id_lista_verificacion}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getListaVerificacionPDF(id_lista_verificacion) {
    const url = process.env.VUE_APP_APIURL + `listaverificaciones/reporte`;
    return axios
      .get(url, { params: { id_lista_verificacion: id_lista_verificacion } })
      .then((res) => res.data.data);
  }

  updateListaVerificacion(listaverificacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `listaverificaciones/${listaverificacion.id_lista_verificacion}`;
    return axios.put(url, listaverificacion).then((res) => res.data.data);
  }

  createListaVerificacion(listaverificacion) {
    const url = process.env.VUE_APP_APIURL + `listaverificaciones`;
    return axios.post(url, listaverificacion).then((res) => res.data.data);
  }

  deleteListaVerificacion(listaverificacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `listaverificaciones/${listaverificacion.id_lista_verificacion}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
