import axios from 'axios';

export default class TypeEquipmentMovService {

	getTipoEquipoMovimientos() {		
        const url = process.env.VUE_APP_APIURL + `tipoequipomovimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoEquipoMovimiento(tipoequipomovimiento) {
        const url = process.env.VUE_APP_APIURL + `/tipoequipomovimientos/${tipoequipomovimiento.id_tipo_equipo_movimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoEquipoMovimiento(tipoequipomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoequipomovimientos/${tipoequipomovimiento.id_tipo_equipo_movimiento}`;
		return axios.put(url,tipoequipomovimiento).then(res=>res.data.data);
	}

	createTipoEquipoMovimiento(tipoequipomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoequipomovimientos`;
		return axios.post(url,tipoequipomovimiento).then(res=>res.data.data);
	}

	deleteTipoEquipoMovimiento(tipoequipomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoequipomovimientos/${tipoequipomovimiento.id_tipo_equipo_movimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}