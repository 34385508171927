
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxEditing,
  DxSorting,
  DxFormat
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import ItemService from '../services/item-service';
import SpecialFieldService from '../services/special-field-service';
import ItemSpecialFieldService from '../services/item-special-field-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting,
    DxFormat
  },
  data() {
    return {
      items: null,      
      dataSourceItems: null,
      dataSourceSpecialFieldsAvailable: null,
      dataSourceSpecialFieldsAssigned: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
    };
  },
  created() {
    this.itemService = new ItemService();
    this.specialFieldService = new SpecialFieldService();
    this.itemSpecialFieldService = new ItemSpecialFieldService();
  },
  mounted() {
    this.itemService.getArticulosCombo().then(data => this.dataSourceItems=data);  
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_campo);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addItem() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.itemSpecialFieldService.createArticuloCampo({"id_articulo": this.items.id_articulo, "id_campo": this.selectedRowsData[this.i].id_campo}).then(() => {           
            
            if (this.i==this.selectedRowsData.length) {
              
              this.itemSpecialFieldService.getCamposDisponiblesPorArticulo(this.items.id_articulo).then(data => this.dataSourceSpecialFieldsAvailable=data);
              this.itemSpecialFieldService.getCamposAsignadosPorArticulo(this.items.id_articulo).then(data => this.dataSourceSpecialFieldsAssigned=data);
            }
            
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    valueChangedItem(e) {
        this.itemSpecialFieldService.getCamposDisponiblesPorArticulo(e.value.id_articulo).then(data => this.dataSourceSpecialFieldsAvailable=data);
        this.itemSpecialFieldService.getCamposAsignadosPorArticulo(e.value.id_articulo).then(data => this.dataSourceSpecialFieldsAssigned=data);           
    },
    deleteSpecialFields(e) {
      this.itemSpecialFieldService.deleteArticuloCampo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.itemSpecialFieldService.getCamposDisponiblesPorArticulo(this.items.id_articulo).then(data => this.dataSourceSpecialFieldsAvailable=data);
        this.itemSpecialFieldService.getCamposAsignadosPorArticulo(this.items.id_articulo).then(data => this.dataSourceSpecialFieldsAssigned=data); 
      });
    },
    editSpecialFields(e) {
      this.itemSpecialFieldService.updateArticuloCampo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.itemSpecialFieldService.getCamposAsignadosPorArticulo(this.items.id_articulo).then(data => this.dataSourceSpecialFieldsAssigned=data); 
      });
		},
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'campo') {
        e.editorOptions.disabled = true;
      }
    },
  },  
};
