
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxSorting
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import LayoutService from '../services/layout-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import TypeLayoutService from '../services/type-layout-service';

export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSorting
  },
  data() {
    return {
      productos: null,      
      dataSourceLayouts: null,      
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceLayoutTypes: null,      
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Layout',
        showTitle: true,
        width: 900,
      },
    };
  },
  created() {
    this.layoutService = new LayoutService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.typeLayoutService = new TypeLayoutService();    
  },
  mounted() {    
    this.layoutService.getLayouts().then(data => this.dataSourceLayouts=data);    
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.typeLayoutService.getTipoLayouts().then(data => this.dataSourceLayoutTypes=data);    
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editLayout(e) {
      this.layoutService.updateLayout(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteLayout(e) {
			this.layoutService.deleteLayout(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createLayout(e) {
			this.layoutService.createLayout(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},   
    onInitNewRow(e) {      
      e.data.activo = true;
      //e.data.id_equipo_padre = null;      
      //this.equipmentService.getLayoutPadres().then(data => this.dataSourceFatherItems=data);
    },
  },  
};
