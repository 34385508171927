import axios from "axios";

export default class FaultReportService {
  getConsultaReporteFallas() {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `reportefallas/consultas`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  getReporteFallasPendientes() {
    const url = process.env.VUE_APP_APIURL + `reportefallas/pendientes`;
    return axios.get(url).then((res) => res.data.data);
  }

  getReporteFallas() {
    const url = process.env.VUE_APP_APIURL + `reportefallas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getReporteFalla(reportefalla) {
    const url =
      process.env.VUE_APP_APIURL +
      `/reportefallas/${reportefalla.id_reporte_falla}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateReporteFalla(reportefalla) {
    const url =
      process.env.VUE_APP_APIURL +
      `/reportefallas/${reportefalla.id_reporte_falla}`;
    return axios.put(url, reportefalla).then((res) => res.data.data);
  }

  createReporteFalla(reportefalla) {
    const url = process.env.VUE_APP_APIURL + `/reportefallas`;
    return axios.post(url, reportefalla).then((res) => res.data.data);
  }

  deleteReporteFalla(reportefalla) {
    const url =
      process.env.VUE_APP_APIURL +
      `/reportefallas/${reportefalla.id_reporte_falla}`;
    return axios.delete(url).then((res) => res.data.data);
  }
  getReporteFallaById(id_reporte_falla) {
    const url =
      process.env.VUE_APP_APIURL + `reportefallaarchivo/reportefalla/id`;
    return axios
      .get(url, { params: { id_reporte_falla: id_reporte_falla } })
      .then((res) => res.data.data);
  }

  getReporteFallasPDF(id_reporte_falla) {
    const url = process.env.VUE_APP_APIURL + `reportefallas/web`;
    return axios
      .get(url, { params: { id_reporte_falla: id_reporte_falla } })
      .then((res) => res.data.data);
  }
}
