
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import FaultReportEvidence from './fault-report-evidence.vue';

export default {
    components: {
        DxTabPanel,
        DxItem,
        FaultReportEvidence,
    },
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            selectedIndex: 0,
            loop: true,
            animationEnabled: true,
            swipeEnabled: false,
        };
    },
};
