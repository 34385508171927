
import { DxButton as DxButtonVue } from 'devextreme-vue/button';
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxEditing,
    DxFilterRow,
    DxSearchPanel,
    DxButton
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxToolbar from 'devextreme-vue/toolbar';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import {
    DxForm,
    DxSimpleItem,
    DxLabel,
    DxValidationRule,
    DxItem,
    DxGroupItem,
    DxEmptyItem,
} from 'devextreme-vue/form';
import DxSelectBox from 'devextreme-vue/select-box';

import VueQrcode from 'vue-qrcode'

import LocationTabService from '../services/location-tab-service';
import ItemAreaService from '../services/item-area-service';
import ItemAisleService from '../services/item-aisle-service';
import ItemRackGroupService from '../services/item-rack-group-service';
import ItemRackService from '../services/item-rack-service';
import ItemLevelService from '../services/item-level-service';

export default {
    components: {
        DxDataGrid,
        DxEditing,
        DxColumn,
        DxPaging,
        DxLookup,
        DxFilterRow,
        DxSearchPanel,
        DxToast,
        DxValidationRule,
        DxToolbar,
        DxButton, DxPopup, DxToolbarItem,
        DxScrollView,
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxItem,
        DxGroupItem,
        DxSelectBox,
        DxEmptyItem,
        DxButtonVue,
        VueQrcode
    },
    created() {
        this.locationTabService = new LocationTabService();
        this.itemAreaService = new ItemAreaService();
        this.itemAisleService = new ItemAisleService();
        this.itemRackGroupService = new ItemRackGroupService();
        this.itemRackService = new ItemRackService();
        this.ItemLevelService = new ItemLevelService();
    },
    mounted() {
        this.refreshDataGrid();
        this.configlocation();
    },
    data() {
        return {
            dataSourceLocation: null,
            dataSourceItemArea: null,
            dataSourceItemAisle: null,
            dataSourceItemRackGroup: null,
            dataSourceItemRack: null,
            dataSourceItemLevel: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            popupOptions: {
                title: 'Ubicación',
                showTitle: true,
                width: 400,
                height: 600
            },
            isVisible: false,
            message: '',
            type: 'info',
            refreshButtonOptions: {
                icon: 'refresh',
                onClick: () => {
                    this.refreshDataGrid();
                },
            },
            addButtonOptions: {
                icon: 'plus',
                onClick: () => {
                    this.itemArea = null;
                    this.itemAisle = null;
                    this.itemRackGroup = null;
                    this.itemRack = null;
                    this.itemLevel = null;
                    this.locationFormData.id_ubicacion = 0;
                    this.locationFormData.ubicacion = '';
                    this.codigo_qr = '';
                    this.popupVisible = true;
                },
            },
            popupTitle: "Ubicación",
            popupVisible: false,
            itemArea: null,
            itemAisle: null,
            itemRackGroup: null,
            itemRack: null,
            itemLevel: null,
            formInstance: null,
            locationFormData: {
                id_ubicacion: 0,
                id_articulo_area: null,
                id_articulo_pasillo: null,
                id_articulo_grupo_estante: null,
                id_articulo_estante: null,
                id_articulo_nivel: null,
                ubicacion: '', codigo_qr: ''
            },
            characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
            codigo_qr: '',
            buttonOptions: {
                text: "Do Something",
                type: "success",

            }
        }
    },
    methods: {
        refreshDataGrid() {
            this.locationTabService.getUbicaciones().then(data => this.dataSourceLocation = data);
        },
        configlocation() {
            this.itemAreaService.getArticuloAreas().then(data => this.dataSourceItemArea = data);
            this.itemAisleService.getArticuloPasillos().then(data => this.dataSourceItemAisle = data);
            this.itemRackGroupService.getArticuloGrupoEstantes().then(data => this.dataSourceItemRackGroup = data);
            this.itemRackService.getArticuloEstantes().then(data => this.dataSourceItemRack = data);
            this.ItemLevelService.getArticuloNiveles().then(data => this.dataSourceItemLevel = data);
        },
        closeClick() {
            this.popupVisible = false;
        },
        valueChangedItemArea() {
            this.locationmodelchange();
        },
        valueChangedItemAisle() {
            this.locationmodelchange();
        },
        valueChangedItemRackGroup() {
            this.locationmodelchange();
        },
        valueChangedItemRack() {
            this.locationmodelchange();
        },
        valueChangedItemLevel() {
            this.locationmodelchange();
        },
        locationmodelchange() {
            if (this.itemArea != null) {
                this.locationFormData.ubicacion = this.itemArea.nombre;
            }
            if (this.itemAisle != null) {
                this.locationFormData.ubicacion += ' - ' + this.itemAisle.nombre;
            }
            if (this.itemRackGroup != null) {
                this.locationFormData.ubicacion += ' - ' + this.itemRackGroup.nombre;
            }
            if (this.itemRack != null) {
                this.locationFormData.ubicacion += ' - ' + this.itemRack.nombre;
            }
            if (this.itemLevel != null) {
                this.locationFormData.ubicacion += ' - ' + this.itemLevel.nombre;
            }
            if (this.itemArea != null && this.itemAisle != null && this.itemRackGroup != null &&
                this.itemRack != null && this.itemLevel != null) {
                if (this.locationFormData.id_ubicacion == 0) {
                    let sd = this.itemArea.nombre + this.itemAisle.nombre + this.itemRackGroup.nombre + this.itemRack.nombre + this.itemLevel.nombre;
                    sd = sd.replace('.', '');
                    sd = sd.replace(' ', '');
                    sd = sd.replace('-', '');
                    sd = sd.replace(' ', '');
                    sd = sd.replace('.', '');
                    sd = sd.replace(' ', '');
                    sd = sd.replace('.', '');
                    sd = sd.replace('-', '');
                    sd = sd.replace(' ', '');
                    sd = sd.replace(' ', '');

                    let qrcode = 'UB-' + sd + this.generateString(10);
                    this.locationFormData.codigo_qr = qrcode;
                    this.codigo_qr = qrcode;
                }
            }

        },
        generateString(length) {
            let result = '';
            const charactersLength = this.characters.length;
            for (let i = 0; i < length; i++) {
                result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        downloadimg() {
            const qrcodedownload = document.getElementById("qrcode");
            const url = qrcodedownload.src;
            const a = document.createElement('a');
            a.href = url;
            a.download = this.locationFormData.ubicacion + '.png';
            a.dispatchEvent(new MouseEvent('click'));
        },
        saveFormInstance(e) {
            this.formInstance = e.component;
        },
        createLocation() {
            if (this.itemArea != null && this.itemAisle != null && this.itemRackGroup != null &&
                this.itemRack != null && this.itemLevel != null) {
                var datajson = {
                    'id_ubicacion': this.locationFormData.id_ubicacion,
                    'id_articulo_area': this.itemArea.id_articulo_area,
                    'id_articulo_pasillo': this.itemAisle.id_articulo_pasillo,
                    'id_articulo_grupo_estante': this.itemRackGroup.id_articulo_grupo_estante,
                    'id_articulo_estante': this.itemRack.id_articulo_estante,
                    'id_articulo_nivel': this.itemLevel.id_articulo_nivel,
                    'codigo_qr': this.codigo_qr,
                };

                if (this.locationFormData.id_ubicacion == 0) {
                    this.locationTabService.createUbicacion(datajson).then((data) => {
                        if (typeof (data.error) === "undefined") {
                            this.type = 'success';
                            this.message = 'Se ha creado el registro';
                            this.isVisible = true;
                            this.popupVisible = false;
                        } else {
                            this.type = 'error';
                            this.message = data.error;
                            this.isVisible = true;
                        }
                        this.refreshDataGrid();
                    });
                } else {
                    this.locationTabService.updateUbicacion(datajson).then((data) => {
                        if (typeof (data.error) === "undefined") {
                            this.type = 'success';
                            this.message = 'Se ha actualizado el registro';
                            this.isVisible = true;
                            this.popupVisible = false;
                        } else {
                            this.type = 'error';
                            this.message = data.error;
                            this.isVisible = true;
                        }
                        this.refreshDataGrid();
                    });
                }


            }
        },
        showeditLocation(e) {
            var itemarea = this.dataSourceItemArea.find((element) => element.id_articulo_area == e.row.data.id_articulo_area);
            var itemaisle = this.dataSourceItemAisle.find((element) => element.id_articulo_pasillo == e.row.data.id_articulo_pasillo);
            var itemrackgroup = this.dataSourceItemRackGroup.find((element) => element.id_articulo_grupo_estante == e.row.data.id_articulo_grupo_estante);
            var itemrack = this.dataSourceItemRack.find((element) => element.id_articulo_estante == e.row.data.id_articulo_estante);
            var itemlevel = this.dataSourceItemLevel.find((element) => element.id_articulo_nivel == e.row.data.id_articulo_nivel);
            this.itemArea = itemarea;
            this.itemAisle = itemaisle;
            this.itemRackGroup = itemrackgroup;
            this.itemRack = itemrack;
            this.itemLevel = itemlevel;

            if (this.itemArea != null) {
                this.locationFormData.ubicacion = this.itemArea.nombre;
            }
            if (this.itemAisle != null) {
                this.locationFormData.ubicacion += ' - ' + this.itemAisle.nombre;
            }
            if (this.itemRackGroup != null) {
                this.locationFormData.ubicacion += ' - ' + this.itemRackGroup.nombre;
            }
            if (this.itemRack != null) {
                this.locationFormData.ubicacion += ' - ' + this.itemRack.nombre;
            }
            if (this.itemLevel != null) {
                this.locationFormData.ubicacion += ' - ' + this.itemLevel.nombre;
            }

            this.locationFormData.id_ubicacion = e.row.data.id_ubicacion;
            this.locationFormData.id_articulo_area = e.row.data.id_articulo_area;
            this.locationFormData.id_articulo_pasillo = e.row.data.id_articulo_pasillo;
            this.locationFormData.id_articulo_grupo_estante = e.row.data.id_articulo_grupo_estante;
            this.locationFormData.id_articulo_estante = e.row.data.id_articulo_estante;
            this.locationFormData.id_articulo_nivel = e.row.data.id_articulo_nivel;
            this.codigo_qr = e.row.data.codigo_qr;
            this.popupVisible = true;
        },
        deleteItemLocation(e) {
            this.locationTabService.deleteUbicacion(e.data).then((data) => {
                if (typeof (data.error) === "undefined") {
                    this.type = 'success';
                    this.message = 'Se ha eliminado el registro';
                    this.isVisible = true;
                } else {
                    this.type = 'error';
                    this.message = data.error;
                    this.isVisible = true;
                }
                this.refreshDataGrid();
            });
        },

    }
}
