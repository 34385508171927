

import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxSorting,
  DxValidationRule,
  DxLookup,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping
} from 'devextreme-vue/data-grid';
import { DxItem, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import { DxBox } from 'devextreme-vue/box';
import { DxToast } from 'devextreme-vue/toast';
import DxFileUploader from 'devextreme-vue/file-uploader';
import PriceListService from '../services/price-list-service';
import SupplierService from '../services/supplier-service';
import CurrencyService from '../services/currency-service';
import auth from '../auth';

const fileUploaderRef = "fu";
const imgRef = "img"

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSorting,
    DxValidationRule,
    DxLookup,
    DxFileUploader,
    DxTabbedItem,
    DxTab,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxBox,
    DxButton,
  },
  props: {
    productId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      idUsuario: null,
      dataSourcePriceLists: null,
      dataSourceSuppliers: null,
      dataSourceCurrencies: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Lista de Precio',
        showTitle: true,
        width: 800,
        height: 665,
      },
      mode: 'select',
      autoExpandAll: true,
      backendURL: process.env.VUE_APP_APIURL,
      imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
      imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
      retryButtonVisible: false,
      fileUploaderRef,
      imgRef,
    };
  },
  created() {
    this.priceListService = new PriceListService();
    this.supplierService = new SupplierService();
    this.currencyService = new CurrencyService();
  },
  mounted() {    
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers=data);
    this.currencyService.getMonedas().then(data => this.dataSourceCurrencies=data);
    auth.getUser().then((data) => {
        this.idUsuario = data.data.id_usuario;
        this.priceListService.getListaPreciosPorUsuario({"id_usuario": data.data.id_usuario}).then(data => this.dataSourcePriceLists=data);
    });
  },
  methods: {
    downloadFile() {
      try {
        this.priceListService.downloadFile().then((data) => {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          console.log(url);
          link.setAttribute('download', 'plantilla_lista_precio_apiclix.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } catch (error) {
        console.error(error);
      }
    },
    onClick() {
      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
    },
    onValueChanged(e) {
      let reader = new FileReader();
      reader.onload = (args) => {
        this.imageElement.setAttribute('src', args.target.result);
      }
      reader.readAsDataURL(e.value[0]); // convert to base64 string
    },
    onUploaded(e, cellInfo) {
      let fileObject = JSON.parse(e.request.responseText);
      cellInfo.setValue(fileObject.data.image_thumbnail);
      this.retryButtonVisible = false;
    },
    onUploadError(e) {
      let xhttp = e.request;
      if(xhttp.status === 400){
        e.message = e.error.responseText;
      }
      if(xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
      this.retryButtonVisible = true;
    },
    editPriceList(e) {
      this.priceListService.updateListaPrecio({...e.data, "id_usuario": this.idUsuario}).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.priceListService.getListaPreciosPorUsuario({"id_usuario": this.idUsuario}).then(data => this.dataSourcePriceLists=data);
      }).catch(() => {
          this.refresh();
          this.type = 'error';
          this.message = 'Falla al actualizar el registro';
          this.isVisible = true;
          this.priceListService.getListaPreciosPorUsuario({"id_usuario": this.idUsuario}).then(data => this.dataSourcePriceLists=data);
      });
    },
    deletePriceList(e) {
      this.priceListService.deleteListaPrecio({...e.data, "id_usuario": this.idUsuario}).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.priceListService.getListaPreciosPorUsuario({"id_usuario": this.idUsuario}).then(data => this.dataSourcePriceLists=data);
      }).catch(() => {
          this.refresh();
          this.type = 'error';
          this.message = 'Falla al eliminar el registro';
          this.isVisible = true;
          this.priceListService.getListaPreciosPorUsuario({"id_usuario": this.idUsuario}).then(data => this.dataSourcePriceLists=data);
      });
    },
    createPriceList(e) {
      this.priceListService.createListaPrecio({...e.data, "id_usuario": this.idUsuario}).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.priceListService.getListaPreciosPorUsuario({"id_usuario": this.idUsuario}).then(data => this.dataSourcePriceLists=data);
      }).catch(() => {
          this.refresh();
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.priceListService.getListaPreciosPorUsuario({"id_usuario": this.idUsuario}).then(data => this.dataSourcePriceLists=data);
      });
    },
    onInitNewRow(e) {
      e.data.activo = true;
    },
  }
};
