import axios from 'axios';

export default class QuoteService {

	getPendientesPorCotizar() {			
        const url = process.env.VUE_APP_APIURL + `cotizaciones/pendientes`;		
		return axios.get(url).then(res=>res.data.data);
	}

	deleteSolicitudCotizacion(solicitud){
        const url = process.env.VUE_APP_APIURL + `/cotizaciones/solicitudes`;
		return axios.put(url,solicitud).then(res=>res.data.data);
	}

	getCotizacionesPorEmpresa(empresa) {
		const url = process.env.VUE_APP_APIURL + `cotizaciones/empresas/${empresa}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	createCotizacion(cotizacion) {
		const url = process.env.VUE_APP_APIURL + `/cotizaciones`;
		return axios.post(url, cotizacion).then((res) => res.data.data);
	}

	updateCotizacion(cotizacion) {
	const url =
		process.env.VUE_APP_APIURL +
		`/cotizaciones/${cotizacion.id_cotizacion}`;
	return axios.put(url, cotizacion).then((res) => res.data.data);
	}

	deleteCotizacion(cotizacion) {
	const url =
		process.env.VUE_APP_APIURL +
		`/cotizaciones/${cotizacion.id_cotizacion}`;
	return axios.delete(url).then((res) => res.data.data);
	}

}