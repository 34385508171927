
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxEditing,
  DxSorting
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';

import PhysicalInventoryService from '../services/physical-inventory-service';
import PhysicalInventoryItemService from '../services/physical-inventory-item-service';
import TypeItemService from '../services/type-item-service';
import GroupItemService from '../services/group-item-service';
import UserService from '../services/user-service';
import BrandService from '../services/brand-service';
import ItemService from '../services/item-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting
  },
  data() {
    return {
      physicalinventory: null,
      wharehouseuser: null,
      dataSourcePhysicalInventory: null,
      dataSourceItemAvailable: null,
      dataSourceItemAssigned: null,
      dataSourceUsers: null,
      dataSourceItemTypes: null,
      dataSourceItemGroups: null,
      dataSourceBrands: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
      imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
    };
  },
  created() {
    this.physicalInventoryService = new PhysicalInventoryService();
    this.physicalInventoryItemService = new PhysicalInventoryItemService();
    this.userService = new UserService();
    this.brandService = new BrandService();
    this.typeItemService = new TypeItemService();
    this.groupItemService = new GroupItemService();
    this.itemService = new ItemService();
  },
  mounted() {
    this.userService.getUsuarios().then(data => this.dataSourceUsers = data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands = data);
    this.typeItemService.getTipoArticulos().then(data => this.dataSourceItemTypes = data);
    this.groupItemService.getGrupoArticulos().then(data => this.dataSourceItemGroups = data);
    this.physicalInventoryService.getInventarioFisicos().then(data => {
      let inventariofisicos = [];
      data.forEach(element => {
        if (element.cualquier_articulo == 0 && element.id_estatus_inventario_fisico == 1) {
          inventariofisicos.push(element);
        }
      });
      this.dataSourcePhysicalInventory = inventariofisicos;
    });
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_inventario_fisico_articulo);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addItem() {
      if (this.physicalinventory != null && this.wharehouseuser != null) {
        for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          let datajson = {
            'id_inventario_fisico': this.selectedRowsData[this.i].id_inventario_fisico,
            'id_inventario_fisico_articulo': this.selectedRowsData[this.i].id_inventario_fisico_articulo,
            'id_usuario': this.wharehouseuser.id_usuario,
          };
          this.physicalInventoryItemService.updateInventarioFisicoArticulo(datajson).then(() => {

            if (this.i == this.selectedRowsData.length) {
              this.refreshDataGrid();
            }

          });
        }
      } else {
        this.type = 'error';
        this.message = 'Selecciona una inventario físico  y usuario';
        this.isVisible = true;
      }


    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    refreshDataGrid() {
      if (this.physicalinventory != null) {
        this.physicalInventoryItemService.getArticulosDisponiblesPorUsuario(this.physicalinventory.id_inventario_fisico).then((data) => {
          this.dataSourceItemAvailable = data;
        });
        this.physicalInventoryItemService.getArticulosAsignadosPorUsuario(this.physicalinventory.id_inventario_fisico).then((data) => {
          this.dataSourceItemAssigned = data;
        });
      }
    },
    valueChangedPhysicalInventory() {
      this.refreshDataGrid();
    },
    valueChangedUser() {
    },
    deletePhysicalInventoryItem(e) {
      let datajson = {
        'id_inventario_fisico': e.data.id_inventario_fisico,
        'id_inventario_fisico_articulo': e.data.id_inventario_fisico_articulo,
        'id_usuario': null,
      };
      this.physicalInventoryItemService.updateInventarioFisicoArticulo(datajson).then(() => {
        this.refreshDataGrid();
      });
    }

  },
};
