import axios from 'axios';

export default class TypeProcedureService {

	getTipoProcedimientos() {			
        const url = process.env.VUE_APP_APIURL + `tipoprocedimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoProcedimiento(tipoprocedimiento) {
        const url = process.env.VUE_APP_APIURL + `/tipoprocedimientos/${tipoprocedimiento.id_tipo_procedimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoProcedimiento(tipoprocedimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoprocedimientos/${tipoprocedimiento.id_tipo_procedimiento}`;
		return axios.put(url,tipoprocedimiento).then(res=>res.data.data);
	}

	createTipoProcedimiento(tipoprocedimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoprocedimientos`;
		return axios.post(url,tipoprocedimiento).then(res=>res.data.data);
	}

	deleteTipoProcedimiento(tipoprocedimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoprocedimientos/${tipoprocedimiento.id_tipo_procedimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}