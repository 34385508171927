import axios from 'axios';

export default class EquipmentDocumentService {

	getEquipoAsignadosPorDocumento(documentoId) {		
        const url = process.env.VUE_APP_APIURL + `equipodocumentos/asignados/${documentoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoDisponiblesPorDocumento(documentoId) {		
        const url = process.env.VUE_APP_APIURL + `equipodocumentos/disponibles/${documentoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoDocumento(equipodocumento) {
        const url = process.env.VUE_APP_APIURL + `/equipodocumentos/${equipodocumento.id_equipo_documento}`;
		return axios.get(url).then(res=>res.data.data);
	}

    updateEquipoDocumento(equipodocumento){
        const url = process.env.VUE_APP_APIURL + `equipodocumentos/${equipodocumento.id_equipo_documento}`;
		return axios.put(url,equipodocumento).then(res=>res.data.data);
	}

	createEquipoDocumento(equipodocumento){
        const url = process.env.VUE_APP_APIURL + `/equipodocumentos`;
		return axios.post(url,equipodocumento).then(res=>res.data.data);		
	}

	deleteEquipoDocumento(equipodocumento){
        const url = process.env.VUE_APP_APIURL + `/equipodocumentos/${equipodocumento.id_equipo_documento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}