import axios from "axios";

export default class ConfigSurveyDetailService {
  getConfigSurveyDetail() {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `configencuestadetalles`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  getConfigSurveyDetailByIdConfigSurvey(id_config_encuesta) {
    const url =
      process.env.VUE_APP_APIURL + `configencuestadetalles/configencuesta`;
    return axios
      .get(url, {
        params: { id_config_encuesta: id_config_encuesta },
      })
      .then((res) => res.data.data);
  }

  createConfigSurveyDetail(configencuestadetalle) {
    const url = process.env.VUE_APP_APIURL + `/configencuestadetalles`;
    return axios
      .post(url, configencuestadetalle)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  updateConfigSurveyDetail(configencuestadetalle) {
    const url =
      process.env.VUE_APP_APIURL +
      `/configencuestadetalles/${configencuestadetalle.id_config_encuesta_detalle}`;
    return axios
      .put(url, configencuestadetalle)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  deleteConfigSurveyDetail(configencuestadetalle) {
    const url =
      process.env.VUE_APP_APIURL +
      `/configencuestadetalles/${configencuestadetalle.id_config_encuesta_detalle}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
