
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';

import ConfigAlertService from '../services/config-alert-service';
import TypeAcknowledgeService from '../services/type-acknowledge-service';
import GroupAlertService from '../services/group-alert-service';
import DetailTemplate from './config-alert-rule.vue';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser
  },
  data() {
    return {
      configalerts: null,
      dataSourceConfigAlerts: null,
      dataSourceAcknowledgeTypes: null,
      dataSourceGroupAlerts: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
    };
  },
  created() {
    this.configAlertService = new ConfigAlertService();
    this.typeAcknowledgeService = new TypeAcknowledgeService();
    this.groupAlertService = new GroupAlertService();    
  },
  mounted() {    
    this.typeAcknowledgeService.getTipoReconocimientos().then(data => this.dataSourceAcknowledgeTypes=data);
    this.groupAlertService.getGrupoAlertas().then(data => this.dataSourceGroupAlerts=data);
    this.configAlertService.getConfigAlertas().then(data => this.dataSourceConfigAlerts=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigAlert(e) {
      this.configAlertService.updateConfigAlerta(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteConfigAlert(e) {
			this.configAlertService.deleteConfigAlerta(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createConfigAlert(e) {
			this.configAlertService.createConfigAlerta(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {      
      e.data.activo = true;      
    },
  },
};
