
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import ServiceRequestService from '../services/service-request-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import TypeServiceRequestService from '../services/type-service-request-service';
import PriorityService from '../services/priority-service';
import StatusServiceRequestService from '../services/status-service-request-service';
import UserService from '../services/user-service';
import auth from '../auth';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSelection,
    DxSorting,
  },
  data() {
    return {
      idUsuario: null,
      clientes: null,
      dataSourceServiceRequests: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourcePriorities: null,
      dataSourceServiceRequestTypes: null,
      dataSourceStatus: null,
      dataSourceUsers: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Solicitud de Servicio',
        showTitle: true,
        width: 850,
        height: 620,
      },
      mode: 'select',
    };
  },
  created() {
    this.serviceRequestService = new ServiceRequestService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.typeServiceRequestService = new TypeServiceRequestService();
    this.priorityService = new PriorityService();
    this.statusServiceRequestService = new StatusServiceRequestService();
    this.userService = new UserService();
  },
  mounted() {    
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.typeServiceRequestService.getTipoSolicitudServicios().then(data => this.dataSourceServiceRequestTypes=data);
    this.priorityService.getPrioridades().then(data => this.dataSourcePriorities=data);
    this.statusServiceRequestService.getEstatusSolicitudServicios().then(data => this.dataSourceStatus=data);
    this.userService.getUsuarios().then(data => this.dataSourceUsers=data);
    this.serviceRequestService.getSolicitudServiciosActivas().then(data => this.dataSourceServiceRequests=data);
    auth.getUser().then((data) => {
        this.idUsuario = data.data.id_usuario;
    });
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editServiceRequest(e) {
      this.serviceRequestService.updateSolicitudServicio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.serviceRequestService.getSolicitudServiciosActivas().then(data => this.dataSourceServiceRequests=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.serviceRequestService.getSolicitudServiciosActivas().then(data => this.dataSourceServiceRequests=data);
      });
		},
    deleteServiceRequest(e) {
      this.serviceRequestService.deleteSolicitudServicio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.serviceRequestService.getSolicitudServiciosActivas().then(data => this.dataSourceServiceRequests=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.serviceRequestService.getSolicitudServiciosActivas().then(data => this.dataSourceServiceRequests=data);
      });
		},
    createServiceRequest(e) {
      this.serviceRequestService.createSolicitudServicio({...e.data,"id_usuario":this.idUsuario}).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.serviceRequestService.getSolicitudServiciosActivas().then(data => this.dataSourceServiceRequests=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.serviceRequestService.getSolicitudServiciosActivas().then(data => this.dataSourceServiceRequests=data);
      });
		},    
    onInitNewRow(e) {
      e.data.id_estatus_solicitud_servicio = 1;
      e.data.actividad = true;
      e.data.fecha = new Date();
    },
  },
};
