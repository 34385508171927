
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxSelection,
  DxSorting,
  DxForm,
  DxItem,
  DxValidationRule,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
// Catalogos
import UserService from '../services/user-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import TypeDataService from '../services/type-data-service';
import GroupMeasurementService from '../services/group-measurement-service';
import MeasurementService from '../services/measurement-service';
import WorkOrderEquipmentTaskMeasurementService from '../services/workorder-equipment-task-measurement-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule,
  },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    workOrderTaskId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      usuarioId: null,
      dataSourceUsers: null,
      ordentrabajotareamediciones: null,
      dataSourceWorkOrderMeasurements: null,
      dataSourceUnitOfMeasurements: null,
      dataSourceDataTypes: null,
      dataSourceMeasurements: null,
      dataSourceMeasurementGroups: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      popupOptions: {
        title: 'Medicion',
        showTitle: true,
        width: 600,
      },
    };
  },
  created() {
    this.userService = new UserService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.typeDataService = new TypeDataService();
    this.measurementService = new MeasurementService();
    this.groupMeasurementService = new GroupMeasurementService();
    this.workOrderEquipmentTaskMeasurementService = new WorkOrderEquipmentTaskMeasurementService();
  },
  mounted() {
    this.userService.getUsuarios().then(data => this.dataSourceUsers=data);
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitOfMeasurements=data);
    this.typeDataService.getTipoDatos().then(data => this.dataSourceDataTypes=data);
    this.groupMeasurementService.getGrupoMediciones().then(data => this.dataSourceMeasurementGroups=data);
    this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
    //this.workOrderEquipmentTaskMeasurementService.getMedicionesComboPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceMeasurements=data);
    this.workOrderEquipmentTaskMeasurementService.getMedicionesPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceWorkOrderMeasurements=data);
  },

  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    deleteWorkOrderMeasurement(e) {
        this.workOrderEquipmentTaskMeasurementService.deleteOrdenTrabajoMedicion(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.workOrderEquipmentTaskMeasurementService.getMedicionesPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceWorkOrderMeasurements=data);
            //this.workOrderEquipmentTaskMeasurementService.getMedicionesComboPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceMeasurements=data);
        });
    },
    createWorkOrderMeasurement(e) {
      this.workOrderEquipmentTaskMeasurementService.createOrdenTrabajoMedicion({"id_orden_trabajo_tarea": this.workOrderTaskId, "id_medicion": e.data.id_medicion,
      "orden": e.data.orden, "reporte_servicio": e.data.reporte_servicio}).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;

        this.workOrderEquipmentTaskMeasurementService.getMedicionesPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceWorkOrderMeasurements=data);
        //this.workOrderEquipmentTaskMeasurementService.getMedicionesComboPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceMeasurements=data);
      });
    },
    editWorkOrderMeasurement(e) {
      this.workOrderEquipmentTaskMeasurementService.updateOrdenTrabajoMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;

        this.workOrderEquipmentTaskMeasurementService.getMedicionesPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceWorkOrderMeasurements=data);
        //this.workOrderEquipmentTaskMeasurementService.getMedicionesComboPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceMeasurements=data);
      });
    },
    onInitNewRow(e) {
      e.data.reporte_servicio = true;
      e.data.orden = 1;
    },
  },
};
