import axios from 'axios';

export default class UnitOperationService {

	getUnidadOperaciones() {			
        const url = process.env.VUE_APP_APIURL + `unidadoperaciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getUnidadOperacion(unidadoperacion) {
        const url = process.env.VUE_APP_APIURL + `/unidadoperaciones/${unidadoperacion.id_unidad_operacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateUnidadOperacion(unidadoperacion){
        const url = process.env.VUE_APP_APIURL + `/unidadoperaciones/${unidadoperacion.id_unidad_operacion}`;
		return axios.put(url,unidadoperacion).then(res=>res.data.data);
	}

	createUnidadOperacion(unidadoperacion){
        const url = process.env.VUE_APP_APIURL + `/unidadoperaciones`;
		return axios.post(url,unidadoperacion).then(res=>res.data.data);
	}

	deleteUnidadOperacion(unidadoperacion){
        const url = process.env.VUE_APP_APIURL + `/unidadoperaciones/${unidadoperacion.id_unidad_operacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}