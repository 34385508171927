import axios from 'axios';

export default class PurchaseOrderServiceService {

  createOrdenCompraServicio(ordencompra) {
    const url = process.env.VUE_APP_APIURL + `ordencompraservicios`;
    return axios.post(url, ordencompra).then((res) => res.data.data);
  }

  getOrdenCompraServicioID(ordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordencompraservicios/ordencompra`;
    return axios
      .get(url, { params: ordencompra })
      .then((res) => res.data.data);
  }

  updateOrdenCompraServicio(ordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordencompraservicios/${ordencompra.id_ordencompra_articulo}`;
    return axios.put(url, ordencompra).then((res) => res.data.data);
  }

  deleteOrdenCompraServicio(ordencompra) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordencompraservicios/${ordencompra.id_ordencompra_articulo}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getOrdenCompraServicioPorUsuario(id_ordencompra) {
    const url =
      process.env.VUE_APP_APIURL + `ordencompraservicios/ordencompra`;
    return axios
      .get(url, { params: { id_ordencompra: id_ordencompra } })
      .then((res) => res.data.data);
  }

}