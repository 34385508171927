import axios from 'axios';

export default class ProductSparePartService {

	getRefaccionesAsignadasPorProducto(productoId) {		
        const url = process.env.VUE_APP_APIURL + `productoarticulos/asignados/${productoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getRefaccionesDisponiblesPorProducto(productoId) {		
        const url = process.env.VUE_APP_APIURL + `productoarticulos/disponibles/${productoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getRefaccionesPorEmpresaCliente(empresa, cliente) {		
        const url = process.env.VUE_APP_APIURL + `productoarticulos/empresaclientes`;		
		return axios.get(url,{ params: {id_empresa: empresa, id_cliente: cliente}}).then(res=>res.data.data);
	}

    getProductoRefaccion(productorefaccion) {
        const url = process.env.VUE_APP_APIURL + `/productoarticulos/${productorefaccion.id_producto_articulo}`;
		return axios.get(url).then(res=>res.data.data);
	}

    updateProductoRefaccion(productorefaccion){
        const url = process.env.VUE_APP_APIURL + `productoarticulos/${productorefaccion.id_producto_articulo}`;
		return axios.put(url,productorefaccion).then(res=>res.data.data);
	}

	createProductoRefaccion(productorefaccion){
        const url = process.env.VUE_APP_APIURL + `/productoarticulos`;
		return axios.post(url,productorefaccion).then(res=>res.data.data);		
	}

	deleteProductoRefaccion(productorefaccion){
        const url = process.env.VUE_APP_APIURL + `/productoarticulos/${productorefaccion.id_producto_articulo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}