import axios from 'axios';

export default class StockRequestItemService {
  createValeElectronicoArticulo(valeelectronico) {
    const url = process.env.VUE_APP_APIURL + `/valeselectronicosarticulos`;
    return axios.post(url, valeelectronico).then((res) => res.data.data);
  }

  getValeElectronicoArticuloID(valeelectronico) {
    const url =
      process.env.VUE_APP_APIURL +
      `/valeselectronicosarticulos/valeelectronico`;
    return axios
      .get(url, { params: valeelectronico })
      .then((res) => res.data.data);
  }

  updateValeElectronicoArticulo(valeelectronico) {
    const url =
      process.env.VUE_APP_APIURL +
      `/valeselectronicosarticulos/${valeelectronico.id_vale_electronico_articulo}`;
    return axios.put(url, valeelectronico).then((res) => res.data.data);
  }

  deleteValeElectronicoArticulo(valeelectronico) {
    const url =
      process.env.VUE_APP_APIURL +
      `/valeselectronicosarticulos/${valeelectronico.id_vale_electronico_articulo}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}