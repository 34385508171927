import axios from "axios";

export default class NotificationService {
  createNotificacion(notificacion) {
    const url = process.env.VUE_APP_APIURL + `/notificaciones`;
    return axios
      .post(url, notificacion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
