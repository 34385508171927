 
var dataSourceItem1 = [];
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxScrolling,
    DxPager, 
} from 'devextreme-vue/data-grid';
import StockRequestItemService from '../services/stock-request-item-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import TypeItemService from '../services/type-item-service'
import ItemService from '../services/item-service';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxLookup,
        DxScrolling,
        DxPager,
    },
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        const { id_vale_electronico } = this.templateData.data;
        return {
            id_vale_electronico: id_vale_electronico,
            dataSourceStockRequestItems:null,
            dataSourceUnitOfMeasurement: null, 
            dataSourceItem: null,
            dataSourceTypeItem: null, 
            dataSourceItem1,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
        }
    },
    created(){
        this.stockRequestItemService = new StockRequestItemService();
        this.unitOfMeasurementService = new UnitOfMeasurementService();
        this.typeItemService = new TypeItemService();
        this.itemService = new ItemService();
    },
    mounted() {
        var dataJson = {
            id_vale_electronico: this.id_vale_electronico,
        };
        this.stockRequestItemService.getValeElectronicoArticuloID(dataJson).then(data => {
            this.dataSourceStockRequestItems = data;
        }); 
        this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
           
            this.dataSourceUnitOfMeasurement = data;
        }); 
        this.itemService.getArticuloComboActivos().then(data => {
            dataSourceItem1 = data;
            this.dataSourceItem = data;
        });
        this.typeItemService.getTipoArticulosCombo().then(data => this.dataSourceTypeItem = data);
    },
    methods:{
        getFilteredArticulo: (options) => (
        {
            store: dataSourceItem1,
            filter: options.data ? ['id_tipo_articulo', '=', options.data.id_tipo_articulo] : null,
        }),
    }

};
