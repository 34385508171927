import axios from 'axios';

export default class EquipmentCategoryServiceService {
    
	getEquipoCategoriaServiciosPorTarea(equipoCategoriaTareaId) {
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareaservicios/equipocategoriatareas/${equipoCategoriaTareaId}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCategoriaServicios() {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareaservicios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoCategoriaServicio(equipocategoriaservicio) {
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaservicios/${equipocategoriaservicio.id_equipo_categoria_tarea_servicio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoCategoriaServicio(equipocategoriaservicio){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaservicios/${equipocategoriaservicio.id_equipo_categoria_tarea_servicio}`;
		return axios.put(url,equipocategoriaservicio).then(res=>res.data.data);
	}

	createEquipoCategoriaServicio(equipocategoriaservicio){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaservicios`;
		return axios.post(url,equipocategoriaservicio).then(res=>res.data.data);
	}

	deleteEquipoCategoriaServicio(equipocategoriaservicio){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaservicios/${equipocategoriaservicio.id_equipo_categoria_tarea_servicio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}