import axios from 'axios';

export default class TypeAcknowledgeService {

	getTipoReconocimientos() {		
        const url = process.env.VUE_APP_APIURL + `tiporeconocimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoReconocimiento(tiporeconocimiento) {
        const url = process.env.VUE_APP_APIURL + `/tiporeconocimientos/${tiporeconocimiento.id_tipo_reconocimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoReconocimiento(tiporeconocimiento){
        const url = process.env.VUE_APP_APIURL + `/tiporeconocimientos/${tiporeconocimiento.id_tipo_reconocimiento}`;
		return axios.put(url,tiporeconocimiento).then(res=>res.data.data);
	}

	createTipoReconocimiento(tiporeconocimiento){
        const url = process.env.VUE_APP_APIURL + `/tiporeconocimientos`;
		return axios.post(url,tiporeconocimiento).then(res=>res.data.data);
	}

	deleteTipoReconocimiento(tiporeconocimiento){
        const url = process.env.VUE_APP_APIURL + `/tiporeconocimientos/${tiporeconocimiento.id_tipo_reconocimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}