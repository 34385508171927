

import DxMap from 'devextreme-vue/map';
import { markersData, routesData } from './../data/data.js';

export default {
  components: {
    DxMap,
  },
  data() {
    return {
      markersData,
      routesData,
      routeModes: ['walking'],
      routeColors: ['blue', 'green', 'red'],
    };
  },
  methods: {
    updateRoutesMode({ value: mode }) {
      this.routesData = this.routesData.map((item) => {
        item.mode = mode;
        return item;
      });
    },
    updateRoutesColor({ value: color }) {
      this.routesData = this.routesData.map((item) => {
        item.color = color;
        return item;
      });
    },
  },
};
