
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup,
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxSorting,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import GroupMeasurementService from '../services/group-measurement-service';
import MeasurementService from '../services/measurement-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import TypeDataService from '../services/type-data-service';
import TypeControlService from '../services/type-control-service';
import TypeFileService from '../services/type-file-service';
import ControlService from '../services/control-service';
import SpecialFieldService from '../services/special-field-service';
import EquipmentCharacteristicService from '../services/equipment-characteristic-service';
import GroupActionService from '../services/group-action-service';

export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxColumnChooser
  },
  data() {
    return {
      mediciones: null,
      dataSourceMeasurements: null,
      dataSourceUnitOfMeasurements: null,
      dataSourceGroupMeasurements: null,
      dataSourceFileTypes: null,
      dataSourceControlTypes: null,
      dataSourceDataTypes: null,
      dataSourceControls: null,
      dataSourceCharacteristics: null,
      dataSourceSpecialFields: null,
      dataSourceGroupActions: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Medición',
        showTitle: true,
        height: 630,
        width: 800,
      },
      mode: 'select',
    };
  },
  created() {
    this.measurementService = new MeasurementService();
    this.groupMeasurementService = new GroupMeasurementService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.typeDataService = new TypeDataService();
    this.typeControlService = new TypeControlService();
    this.typeFileService = new TypeFileService();
    this.controlService = new ControlService();
    this.specialFieldService = new SpecialFieldService();
    this.equipmentCharacteristicService = new EquipmentCharacteristicService();
    this.groupActionService = new GroupActionService();
  },
  mounted() {
    this.controlService.getControles().then(data => this.dataSourceControls = data);
    this.measurementService.getMediciones().then(data => this.dataSourceMeasurements = data);
    this.groupMeasurementService.getGrupoMediciones().then(data => this.dataSourceGroupMeasurements = data);
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitOfMeasurements = data);
    this.typeDataService.getTipoDatos().then(data => this.dataSourceDataTypes = data);
    this.typeControlService.getTipoControles().then(data => this.dataSourceControlTypes = data);
    this.typeFileService.getTipoArchivos().then(data => this.dataSourceFileTypes = data);
    this.specialFieldService.getCampos().then(data => this.dataSourceSpecialFields = data);
    this.equipmentCharacteristicService.getEquipoCaracteristicas().then(data => this.dataSourceCharacteristics = data);
    this.groupActionService.getGrupoAcciones().then(data => this.dataSourceGroupActions = data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editMeasurement(e) {
      this.measurementService.updateMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar la medición';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements = data);
      });
    },
    deleteMeasurement(e) {
      this.measurementService.deleteMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar la medición';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements = data);
      });
    },
    createMeasurement(e) {
      this.measurementService.createMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear la medición';
        this.isVisible = true;
        this.measurementService.getMediciones().then(data => this.dataSourceMeasurements = data);
      });
    },
    /*
    onEditorPreparing(e) {
      if (e.dataField === 'id_equipo_padre' && e.row.data.id_equipo === 0) {
        e.editorOptions.disabled = true;
        e.editorOptions.value = null;
      }
    },
    */
    onInitNewRow(e) {
      e.data.activo = true;
      e.data.mostrar_descripcion = true;
      //e.data.id_equipo_padre = null;      
      //this.taskService.getEquipoPadres().then(data => this.dataSourceFatherTasks=data);
    },
  },
};
