import axios from "axios";

export default class StatusQuoteService {
  getEstatusCotizaciones() {
    const url = process.env.VUE_APP_APIURL + `estatuscotizaciones`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusCotizacion(estatuscotizacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatuscotizaciones/${estatuscotizacion.id_estatus_cotizacion}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusCotizacion(estatuscotizacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatuscotizaciones/${estatuscotizacion.id_estatus_cotizacion}`;
    return axios
      .put(url, estatuscotizacion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEstatusCotizacion(estatuscotizacion) {
    const url = process.env.VUE_APP_APIURL + `/estatuscotizaciones`;
    return axios
      .post(url, estatuscotizacion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEstatusCotizacion(estatuscotizacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatuscotizaciones/${estatuscotizacion.id_estatus_cotizacion}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
