import axios from "axios";

export default class ItemAreaService {
  getArticuloAreas() {
    const url = process.env.VUE_APP_APIURL + `articuloareas`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getArticuloArea(articuloarea) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloareas/${articuloarea.id_articulo_area}`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  updateArticuloArea(articuloarea) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloareas/${articuloarea.id_articulo_area}`;
    return axios
      .put(url, articuloarea)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createArticuloArea(articuloarea) {
    const url = process.env.VUE_APP_APIURL + `/articuloareas`;
    return axios
      .post(url, articuloarea)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteArticuloArea(articuloarea) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articuloareas/${articuloarea.id_articulo_area}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
