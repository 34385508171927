
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxButtonItem,
  DxLabel,
  DxValidationRule,
} from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import { DxLookup } from 'devextreme-vue/lookup'
import { confirm } from "devextreme/ui/dialog";
import DxTreeView from 'devextreme-vue/tree-view';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import AlgorithmService from '../services/algorithm-service';
import MaintenancePlanService from '../services/maintenance-plan-service';
import TaskService from '../services/task-service';
import LocationService from '../services/location-service';
import auth from '../auth';

const submitButtonOptions = {
    text: "Ejecutar Planeación",
    type: 'default',
    useSubmitBehavior: true
};

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxValidationRule,
    DxToast,
    DxLookup,
    DxTreeView,
  },
  data() {
    const now = new Date();
    const selectionModes = ['multiple', 'single'];
    const showCheckBoxesModes = ['normal', 'selectAll', 'none'];
    return {
      searchMode: 'contains',
      showCheckBoxesModeValue: showCheckBoxesModes[0],
      selectionModeValue: selectionModes[0],
      selectedCommonTasks: [],
      idUsuario: null,
      idEmpresa: null,
      idCliente: null,
      idAlgoritmo: null,
      plannerFormData: null,
      dataSourceAlgorithms: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceCommonTasks: null,
      dataSourceLocations: null,
      submitButtonOptions,
      dateOptions: { width: '100%', value: new Date(now.getFullYear(), now.getMonth() + 1, 0), type: "date" },
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.taskService = new TaskService();
    this.algorithmService = new AlgorithmService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.maintenancePlanService = new MaintenancePlanService();
    this.locationService = new LocationService();
  },
  mounted() {
    this.taskService.getTareasComunes().then(data => this.dataSourceCommonTasks=data);
    this.companyService.getEmpresas().then((data) => {
      this.dataSourceCompanies=data;
      this.idEmpresa=data[0].id_empresa;
    });
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    //this.locationService.getLocalidades().then(data => this.dataSourceLocations=data);
    this.algorithmService.getAlgoritmos().then((data) => {
      this.dataSourceAlgorithms=data;
      this.idAlgoritmo=data[0].id_algoritmo;
    });
  },
  methods: { 
    handleSubmit(e) {

      confirm("¿Desea ejecutar el proceso de planeación?", "Proceso de Planeación").then((dialogResult) => {
        if (dialogResult) {
          auth.getUser().then((data) => {
            this.idUsuario = data.data.id_usuario;
            this.maintenancePlanService.createPlanMantenimientoPlaneacion({"id_algoritmo": this.idAlgoritmo, "fecha_inicio": this.plannerFormData.fechaInicio,
            "fecha_fin": this.plannerFormData.fechaFin, "id_empresa": this.idEmpresa, "id_cliente": this.idCliente,
            "id_usuario": this.idUsuario, "tareas": this.selectedCommonTasks
            }).then(() => {
              this.type = 'success';
              this.message = 'Se ha generado la planeación correctamente';
              this.isVisible = true;
            }).catch(() => {
              this.type = 'error';
              this.message = 'Falla al generar la planeación';
              this.isVisible = true;
            });
          });
        }
      });

      e.preventDefault();
    },

    treeViewSelectionChangedCommonTask(e) {
      this.syncSelectionCommonTask(e.component);
    },

    treeViewContentReadyCommonTask(e) {
      this.syncSelectionCommonTask(e.component);
    },

    syncSelectionCommonTask(treeView) {
      const selectedCommonTasks = treeView.getSelectedNodes()
        .map((node) => node.itemData);

      this.selectedCommonTasks = [];

      for(var i in selectedCommonTasks)
        this.selectedCommonTasks.push(selectedCommonTasks[i].id_tarea);
      
    },
  } 

};
