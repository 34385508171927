
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxSelection,
  DxSorting,
  DxValidationRule,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import MeasurementOptionService from '../services/measurement-option-service';
import DetailTemplate from './measurement-option-detail.vue';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSelection,
    DxSorting,
    DxValidationRule,
    DxMasterDetail,
    DetailTemplate,
  },
  data() {
    return {
      medicionOpciones: null,
      dataSourceMeasurementOptions: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',      
    };
  },
  created() {
    this.measurementOptionService = new MeasurementOptionService();
  },
  mounted() {    
    this.measurementOptionService.getMedicionOpciones().then(data => this.dataSourceMeasurementOptions=data);     
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editMeasurementOption(e) {
      this.measurementOptionService.updateMedicionOpcion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteMeasurementOption(e) {
			this.measurementOptionService.deleteMedicionOpcion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createMeasurementOption(e) {
			this.measurementOptionService.createMedicionOpcion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {      
      e.data.activo = true;      
    },
  },
};
