

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import EquipmentSearch from './equipment-search.vue';
import EquipmentGallery from './equipment-gallery.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    EquipmentSearch,
    EquipmentGallery,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
