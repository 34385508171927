
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxValidationRule,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';

import EquipmentCategoryService from '../services/equipment-category-service';
import EquipmentCategoryTaskService from '../services/equipment-category-task-service';
import EquipmentCategoryServiceService from '../services/equipment-category-service-service';
import ServiceService from '../services/service-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxValidationRule,
    DxSelectBox,
    DxSelection,
    DxSorting,   
  },
  data() {
    return {
      item: {},      
      dataSourceConfigServices: null,
      dataSourceEquipmentCategories: null,
      dataSourceEquipmentCategoryTasks: null,
      dataSourceServices: null,            
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Servicios por tarea',
        showTitle: true,
        height: 500,
        width: 700,
      },      
      mode: 'select',
      equipmentcategory: null,
      equipmentcategorytask: null,
    };
  },
  created() {    
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.equipmentCategoryTaskService = new EquipmentCategoryTaskService();
    this.serviceService = new ServiceService();
    this.equipmentCategoryServiceService = new EquipmentCategoryServiceService();
  },
  mounted() {
    this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);
    this.serviceService.getServicios().then(data => this.dataSourceServices=data);
  },
  methods: {
    valueChangedEquipmentCategory(e) {
      this.equipmentCategoryTaskService.getEquipoCategoriaTareasPorCategoria(e.value.id_equipo_categoria).then(data => this.dataSourceEquipmentCategoryTasks=data);
      this.dataSourceConfigServices = null;
      //this.equipmentcategorytask = null;
    },
    valueChangedEquipmentCategoryTask(e) {
      this.equipmentCategoryServiceService.getEquipoCategoriaServiciosPorTarea(e.value.id_equipo_categoria_tarea).then(data => this.dataSourceConfigServices=data);        
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_equipo_categoria_tarea') {
        e.editorOptions.disabled = true;
      }
    },
    /*
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'id_grupo_tarea') {
        e.editorOptions.disabled = (typeof e.row.data.id_tarea !== 'number');
      }
    },
    setStateValue(rowData, value) {
      this.taskService.getTareasPorGrupo(value).then((data) => {
          this.dataSourceTasks=data;          
      });
      rowData.id_tarea = null;
      this.defaultSetCellValue(rowData, value);      
    },
    getFilteredTasks: (options) => ({
      filter: options.data ? ['id_grupo_tarea', '=', options.data.id_grupo_tarea] : null,
    }),
    */
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigService(e) {
        this.equipmentCategoryServiceService.updateEquipoCategoriaServicio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.equipmentCategoryServiceService.getEquipoCategoriaServiciosPorTarea(e.data.id_equipo_categoria_tarea).then(data => this.dataSourceConfigServices=data);
      });
    },
    deleteConfigService(e) {
        this.equipmentCategoryServiceService.deleteEquipoCategoriaServicio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createConfigService(e) {
        this.equipmentCategoryServiceService.createEquipoCategoriaServicio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.equipmentCategoryServiceService.getEquipoCategoriaServiciosPorTarea(e.data.id_equipo_categoria_tarea).then(data => this.dataSourceConfigServices=data);
      });
    },    
    onInitNewRow(e) {
      e.data.id_equipo_categoria_tarea= this.equipmentcategorytask.id_equipo_categoria_tarea;
      e.data.activo = true;      
    },
    /*
    onToolbarPreparing(e) {
    e.toolbarOptions.items.forEach((item) => {
        if (item.name=="addRowButton") {
            item.options.onClick = function () {  
            if (this.equipmentcategorytask!=null)  
                e.component.addRow();  
            }; 
        }
        //console.log(item, index)
    });
    }
    */
  },
};
