

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import WorkOrder from './work-order.vue';
import WorkOrderCreate from './work-order-create.vue';
import WorkOrderNew from './workorder-new.vue';
import WorkOrderHistory from './work-order-history.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    WorkOrder,
    WorkOrderNew,
    WorkOrderHistory,
    WorkOrderCreate,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
