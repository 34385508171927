import axios from 'axios';

export default class ProductService {

	getComboProductosPorEmpresaCliente(empresa,cliente) {		
        const url = process.env.VUE_APP_APIURL + `productos/empresaclientes`;		
		return axios.get(url,{ params: {id_empresa:empresa, id_cliente:cliente}}).then(res=>res.data.data);
	}

	getComboProductosPorGrupo(grupo) {		
        const url = process.env.VUE_APP_APIURL + `productos/combos/grupos/${grupo}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getComboProductos() {		
        const url = process.env.VUE_APP_APIURL + `productos/combos`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getWebProductos() {		
        const url = process.env.VUE_APP_APIURL + `productos/web`;		
		return axios.get(url).then(res=>res.data.data);
	}
	
	getProductos() {		
        const url = process.env.VUE_APP_APIURL + `productos`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getProductoPorValor() {
        const url = process.env.VUE_APP_APIURL + `/productos/2`;
		return axios.get(url).then(res=>res.data.data);
	}

    getProducto(producto) {
        const url = process.env.VUE_APP_APIURL + `/productos/${producto.id_producto}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateProducto(producto){
        const url = process.env.VUE_APP_APIURL + `/productos/${producto.id_producto}`;
		return axios.put(url,producto).then(res=>res.data.data);
	}

	createProducto(producto){
        const url = process.env.VUE_APP_APIURL + `/productos`;
		return axios.post(url,producto).then(res=>res.data.data);		
	}

	deleteProducto(producto){
        const url = process.env.VUE_APP_APIURL + `/productos/${producto.id_producto}`;
		return axios.delete(url).then(res=>res.data.data);
	}

	createProductoDesdeEquipo(producto){
        const url = process.env.VUE_APP_APIURL + `/productos/equipoempresas`;
		return axios.post(url,producto).then(res=>res.data.data);		
	}
}