

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import WorkCalendar from './work-calendar.vue';
import FestiveDay from './festive-day.vue';
import SpecialDay from './special-day.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    WorkCalendar,
    FestiveDay,
    SpecialDay
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
