

import { DxDataGrid, DxColumn, DxEditing, DxValidationRule } from 'devextreme-vue/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ClientContactService from '../services/client-contact-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxValidationRule },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { nombre_comercial } = this.templateData.data;
    return {
      details: null,
      dataSourceClientContacts: null,
      detailInfo: `${nombre_comercial}`,
    };
  },
  created() {
    this.clientContactService = new ClientContactService();      
  },
  mounted() {    
    this.clientContactService.getClienteContactos().then((data) => {
        this.details=data;         
        this.dataSourceClientContacts = this.getDetails(this.templateData.key); 
    });             
  },
  methods: {
    editClientContact(e) {
        this.clientContactService.updateClienteContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    deleteClientContact(e) {
		this.clientContactService.deleteClienteContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    createClientContact(e) {
        e.data.id_cliente = this.templateData.key;
		this.clientContactService.createClienteContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    refreshData() {
        this.clientContactService.getClienteContactos().then((data) => {
        this.details=data;         
        this.dataSourceClientContacts = this.getDetails(this.templateData.key); 
    });
    },           
    getDetails(key) {            
      return new DataSource({
        store: new ArrayStore({
          data: this.details,
          key: 'id_cliente_contacto',
        }),
        filter: ['id_cliente', '=', key],
      });
    },
  },
};
