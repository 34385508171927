import axios from "axios";

export default class ItemAisleService {
  getArticuloPasillos() {
    const url = process.env.VUE_APP_APIURL + `articulopasillos`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getArticuloPasillo(articulopasillo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articulopasillos/${articulopasillo.id_articulo_pasillo}`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  updateArticuloPasillo(articulopasillo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articulopasillos/${articulopasillo.id_articulo_pasillo}`;
    return axios
      .put(url, articulopasillo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createArticuloPasillo(articulopasillo) {
    const url = process.env.VUE_APP_APIURL + `/articulopasillos`;
    return axios
      .post(url, articulopasillo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteArticuloPasillo(articulopasillo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articulopasillos/${articulopasillo.id_articulo_pasillo}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
