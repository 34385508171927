import axios from 'axios';

export default class TypeNotificationService {

	getTipoNotificaciones() {			
        const url = process.env.VUE_APP_APIURL + `tiponotificaciones`;
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoNotificacion(tiponotificacion) {
        const url = process.env.VUE_APP_APIURL + `tiponotificaciones/${tiponotificacion.id_tipo_notificacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoNotificacion(tiponotificacion){
        const url = process.env.VUE_APP_APIURL + `tiponotificaciones/${tiponotificacion.id_tipo_notificacion}`;
		return axios.put(url,tiponotificacion).then(res=>res.data.data);
	}

	createTipoNotificacion(tiponotificacion){
        const url = process.env.VUE_APP_APIURL + `tiponotificaciones`;
		return axios.post(url,tiponotificacion).then(res=>res.data.data);
	}

	deleteTipoNotificacion(tiponotificacion){
        const url = process.env.VUE_APP_APIURL + `tiponotificaciones/${tiponotificacion.id_tipo_notificacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}