import axios from 'axios';

export default class ServiceService {

	getServicioComboActivos() {
	const url = process.env.VUE_APP_APIURL + `servicios/combos/activos`;
	return axios.get(url).then((res) => res.data.data);
	}

	getServicios() {			
        const url = process.env.VUE_APP_APIURL + `servicios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getServicio(servicio) {
        const url = process.env.VUE_APP_APIURL + `/servicios/${servicio.id_servicio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateServicio(servicio){
        const url = process.env.VUE_APP_APIURL + `/servicios/${servicio.id_servicio}`;
		return axios.put(url,servicio).then(res=>res.data.data);
	}

	createServicio(servicio){
        const url = process.env.VUE_APP_APIURL + `/servicios`;
		return axios.post(url,servicio).then(res=>res.data.data);
	}

	deleteServicio(servicio){
        const url = process.env.VUE_APP_APIURL + `/servicios/${servicio.id_servicio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}