

import { DxDataGrid, DxColumn, DxEditing, DxValidationRule } from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import CompanyContactService from '../services/company-contact-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxToast },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      contacts: null,
      dataSourceCompanyContacts: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.companyContactService = new CompanyContactService();
  },
  mounted() {
    this.companyContactService.getEmpresaContactos().then((data) => {
      this.contacts=data;
      this.dataSourceCompanyContacts = this.getContacts(this.companyId); 
    });
  },
  methods: {
    editCompanyContact(e) {
      this.companyContactService.updateEmpresaContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      }).catch(() => {
          this.refresh();
          this.type = 'error';
          this.message = 'Falla al actualizar el registro';
          this.isVisible = true;
          this.refreshData();
      });
    },
    deleteCompanyContact(e) {
      this.companyContactService.deleteEmpresaContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      }).catch(() => {
          this.refresh();
          this.type = 'error';
          this.message = 'Falla al eliminar el registro';
          this.isVisible = true;
          this.refreshData();
      });
    },
    createCompanyContact(e) {
      e.data.id_empresa = this.companyId;
      this.companyContactService.createEmpresaContacto(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      }).catch(() => {
          this.refresh();
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.refreshData();
      });
    },
    refreshData() {
      this.companyContactService.getEmpresaContactos().then((data) => {
      this.contacts=data;
      this.dataSourceCompanyContacts = this.getContacts(this.companyId); 
    });
    },
    getContacts(key) {
      return new DataSource({
        store: new ArrayStore({
          data: this.contacts,
          key: 'id_empresa_contacto',
        }),
        filter: ['id_empresa', '=', key],
      });
    },
    onInitNewRow(e) {
      e.data.activo = true;
      e.data.portal_cliente = true;
    },
  },
};
