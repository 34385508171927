import axios from 'axios';

export default class TypeFileService {

	getTipoArchivos() {			
        const url = process.env.VUE_APP_APIURL + `tipoarchivos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoArchivo(tipoarchivo) {
        const url = process.env.VUE_APP_APIURL + `/tipoarchivos/${tipoarchivo.id_tipo_archivo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoArchivo(tipoarchivo){
        const url = process.env.VUE_APP_APIURL + `/tipoarchivos/${tipoarchivo.id_tipo_archivo}`;
		return axios.put(url,tipoarchivo).then(res=>res.data.data);
	}

	createTipoArchivo(tipoarchivo){
        const url = process.env.VUE_APP_APIURL + `/tipoarchivos`;
		return axios.post(url,tipoarchivo).then(res=>res.data.data);
	}

	deleteTipoArchivo(tipoarchivo){
        const url = process.env.VUE_APP_APIURL + `/tipoarchivos/${tipoarchivo.id_tipo_archivo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}