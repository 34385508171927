

import DxScheduler, { DxResource } from 'devextreme-vue/scheduler';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxRadioGroup from 'devextreme-vue/radio-group'
import { DxBox } from 'devextreme-vue/box';
import notify from 'devextreme/ui/notify';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxList from 'devextreme-vue/list';
import DxTileView from 'devextreme-vue/tile-view';
import auth from '../auth';

import WorkOrderService from '../services/workorder-service';
import StatusWorkOrderService from '../services/status-wo-service';
import UserService from '../services/user-service';
import PriorityService from '../services/priority-service';
import TypeMaintenanceService from '../services/type-maintenance-service';
import UserCompanyService from '../services/user-company-service';

import { resourcesList, statusLegend } from './../data/data.js';

export default {
  components: {
    DxScheduler,
    DxToolbar,
    DxItem,
    DxResource,
    DxRadioGroup,
    DxBox,
    DxPopup,
    DxScrollView,
    DxList,
    DxToolbarItem,
    DxTileView
  },
  data() {
    return {
      idEmpresa: null,
      allDayPanelMode: 'allDay',
      loading: true,
      selectedItemKeys: [],
      popupTitle: "Autorización de Órdenes de Trabajo",
      popupVisible: false,
      usuarioId: null,
      showAllDayPanel: false,
      allowAdding: false,
      allowDeleting: true,
      allowUpdating: true,
      allowResizing: true,
      allowDragging: true,
      isPopupVisible: false,
      views: ['day', 'week', 'workWeek', 'month'],
      currentDate: new Date(),
      dataSourceWorkOrders: null,
      dataSourceUsers: null,
      dataSourcePriorities: null,
      dataSourceStatus: null,
      dataSourceMaintenanceTypes: null,
      dataSourceNewWorkOrders: null,
      dataSourceLegends: null,
      resources: resourcesList,
      statusLegend,
      radioGroupValue: 'Estatus',
      refreshButtonOptions: {
        icon: 'refresh',
        onClick: () => {
          this.workOrderService.getCalendarioOrdenTrabajosPorEmpresa(this.idEmpresa).then(data => this.dataSourceWorkOrders=data);
          notify('Listado de Órdenes de Trabajo actualizado','success');
        },
      },
      authorizeButtonOptions: {
        text: 'Autorizar OTs ...',
        onClick: () => {
          this.popupVisible = true;
          this.refresh();
        },
      },
    };
  },
  created() {
    this.workOrderService = new WorkOrderService();
    this.statusWorkOrderService = new StatusWorkOrderService();
    this.userService = new UserService();
    this.priorityService = new PriorityService();
    this.typeMaintenanceService = new TypeMaintenanceService();
    this.userCompanyService = new UserCompanyService();
  },
  mounted() {    
    this.statusWorkOrderService.getCalendarioEstatusOrdenTrabajos().then((data) => {
      this.dataSourceStatus=data;
      this.dataSourceLegends=data;
    });
    this.userService.getCalendarioUsuarios().then(data => this.dataSourceUsers=data);
    this.priorityService.getCalendarioPrioridades().then(data => this.dataSourcePriorities=data);
    this.typeMaintenanceService.getCalendarioTipoMantenimientos().then(data => this.dataSourceMaintenanceTypes=data);
    const usuario = JSON.parse(localStorage.getItem("user"));
    if (usuario.id_usuario_empresa != null) {
      this.userCompanyService.getUsuarioEmpresa(usuario.id_usuario_empresa).then((data) => {
        this.idEmpresa = data.id_empresa;
        this.workOrderService.getCalendarioOrdenTrabajosPorEmpresa(data.id_empresa).then(data => this.dataSourceWorkOrders=data);
      });
    }
    
  },
  computed: {
    editing() {
      return {
        allowAdding: this.allowAdding,
        allowDeleting: this.allowDeleting,
        allowUpdating: this.allowUpdating,
        allowResizing: this.allowResizing,
        allowDragging: this.allowDragging,
      };
    },
  },
  methods: {
    async refresh() {      
      await this.workOrderService.getOrdenesTrabajoNuevas(this.idEmpresa).then((data) => {
        this.dataSourceNewWorkOrders=data;
        /*
        var ordentrabajos = "";
        for (var i = 0; i < data.length; i++) {
          if (ordentrabajos.length==0)
            ordentrabajos = data[i].id_orden_trabajo;
          else
            ordentrabajos = ordentrabajos + "," + data[i].id_orden_trabajo;
        }
        if (ordentrabajos.length>0) {
          this.selectedItemKeys = ordentrabajos.split(',').map(function(item) {
            return parseInt(item, 10);
          });
        }
        */
        this.loading = false;
      });
    },
    showPopup(data, workOrderId) {
      this.loading = true;      
      this.selectedItemKeys = [];
      this.workOrderId = workOrderId;
      this.formData = data;      
      this.popupVisible = true;
      this.refresh();
    },
    onSelectionChangedWorkOrder(e) {
      if (!this.loading) {
        if (e.addedItems.length==1) {
          auth.getUser().then((data) => {
            this.usuarioId = data.data.id_usuario;
            this.workOrderService.updateAutorizaOrdenTrabajo({"id_orden_trabajo": e.addedItems[0].id_orden_trabajo, "id_usuario_mod": this.usuarioId}).then(() => {           
              this.refresh();
            }).catch(() => {
              // TODO
            });
          });
        }
      }
    },
    onAppointmentFormOpening(e) {      
      //console.log(e.appointmentData.titulo);
      e.popup.option('showTitle', true);
      e.popup.option('title', e.appointmentData.text);
      var form = e.form;
      form.itemOption("text", { visible:false });
      form.itemOption("mainGroup.text", { visible:false });

      let mainGroupItems = form.itemOption('mainGroup').items; 
      if (!mainGroupItems.find(function(i) { return i.dataField === "titulo" })) {
          mainGroupItems.push({
              colSpan: 2, 
              label: { text: "Título" },
              editorType: "dxTextBox",
              dataField: "titulo"
          });
          form.itemOption('mainGroup', 'items', mainGroupItems);
      }

      // form.itemOption("mainGroup.text", "value", {value: e.appointmentData.titulo});
      //e.appointmentData.text = "Hola";
      //console.log(form);
      form.itemOption("recurrenceGroup.recurrenceRule", {visible: false});
      form.itemOption("mainGroup.allDay",{visible: false});
      form.itemOption("mainGroup.repeat",{visible: false});
      /*
      //form.itemOption("startDateTimeZone","visible", true);
      //form.itemOption("endDateTimeZone", { visible: true });
      */
    },
    onRadioGroupValueChanged(e) {
      this.radioGroupValue = e.value;
      if (e.value==='Prioridad') {
        this.dataSourceLegends=this.dataSourcePriorities;
      } else if (e.value==='Estatus') {
        this.dataSourceLegends=this.dataSourceStatus;
      } else if (e.value==='Tipo') {
        this.dataSourceLegends=this.dataSourceMaintenanceTypes;
      } else if (e.value==='Asignado') {
        this.dataSourceLegends=this.dataSourceUsers;
      }
    },
    showToast(event, value, type) {
      notify(`${event} "${value}" task`, type, 800);
    },

    showAddedToast(e) {
      this.showToast('Added', e.appointmentData.text, 'success');
    },

    showUpdatedToast(e) {
      auth.getUser().then((data) => {
        this.usuarioId = data.data.id_usuario;
        this.workOrderService.updateOrdenTrabajoCalendario({"id_orden_trabajo": e.appointmentData.id_orden_trabajo,
        "titulo": e.appointmentData.titulo, "descripcion": e.appointmentData.description,
        "fecha_inicio_plan": e.appointmentData.startDate, "fecha_fin_plan": e.appointmentData.endDate,
        "id_prioridad":e.appointmentData.priorityId,"id_estatus_orden_trabajo": e.appointmentData.statusId,
        "id_usuario_mod": this.usuarioId}).then(() => {
            this.type = 'success';
            this.message = 'Se ha actualizado la orden de trabajo';
            this.isVisible = true;
            this.workOrderService.getCalendarioOrdenTrabajosPorEmpresa(this.idEmpresa).then(data => this.dataSourceWorkOrders=data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al actualizar la orden de trabajo';
          this.isVisible = true;
          this.workOrderService.getCalendarioOrdenTrabajosPorEmpresa(this.idEmpresa).then(data => this.dataSourceWorkOrders=data);
        });
      });
      
      //this.showToast('Updated', e.appointmentData.text, 'info');
    },
    closeClick() {
        this.workOrderService.getCalendarioOrdenTrabajosPorEmpresa(this.idEmpresa).then((data) => {
          this.dataSourceWorkOrders=data;
          this.popupVisible = false;
        });
    },
    showDeletedToast(e) {
      this.workOrderService.deleteOrdenTrabajo({"id_orden_trabajo": e.appointmentData.id_orden_trabajo}).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado la orden de trabajo';
            this.isVisible = true;
            this.workOrderService.getCalendarioOrdenTrabajosPorEmpresa(this.idEmpresa).then(data => this.dataSourceWorkOrders=data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al eliminar la orden de trabajo';
          this.isVisible = true;
          this.workOrderService.getCalendarioOrdenTrabajosPorEmpresa(this.idEmpresa).then(data => this.dataSourceWorkOrders=data);
        });
    },
  },
};
