

import { DxTabPanel } from 'devextreme-vue/tab-panel';
import KpiSectionService from '../services/seccion-kpi-service';
import Indicator4 from './indicator4.vue';
export default {
  components: {
    DxTabPanel,
    Indicator4
  },

  data() {
    return {
      selectedIndex: 0,
      dataSourceKPISection: [],
    };
  },
  created() {
    this.kpisectionService = new KpiSectionService();
  },
  mounted() {
    this.kpisectionService.getkpisection().then(data => this.dataSourceKPISection = data);
  },
};
