
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxSelection
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import ConfigFrequencyService from '../services/config-frequency-service';
import FrequencyService from '../services/frequency-service';
import DetailTemplate from './config-frequency-detail.vue';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxSelection
  },
  data() {
    return {
      configfrecuencias: null,
      dataSourceConfigFrequencies: null,
      dataSourceFrequencies: null,      
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.configFrequencyService = new ConfigFrequencyService();
    this.frequencyService = new FrequencyService();     
  },
  mounted() {
    this.frequencyService.getFrecuencias().then(data => this.dataSourceFrequencies=data);
    this.configFrequencyService.getConfigFrecuencias().then(data => this.dataSourceConfigFrequencies=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigFrequency(e) {
      this.configFrequencyService.updateConfigFrecuencia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteConfigFrequency(e) {
			this.configFrequencyService.deleteConfigFrecuencia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createConfigFrequency(e) {
			this.configFrequencyService.createConfigFrecuencia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {      
      e.data.activo = true;      
    },
  },
};
