
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxSelection,
  DxSorting,
  DxForm,
  DxItem,
  DxValidationRule,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxFileUploader from 'devextreme-vue/file-uploader';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
// Catalogos
import UserService from '../services/user-service';
import TypeFileService from '../services/type-file-service';
// TreeList
import WorkOrderEquipmentEvidenceService from '../services/workorder-equipment-evidence-service';

const fileUploaderRef = "fu";
const imgRef = "img"

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule,
    DxFileUploader,
    DxLoadPanel,
  },
  props: {
    templateData: {
      type: Object,
      default: () => { },
    },
    workOrderEquipmentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    //const { id_orden_trabajo_equipo } = this.templateData.data;
    return {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
      ordentrabajoevidencias: null,
      dataSourceWorkOrderEvidences: null,
      dataSourceFileTypes: null,
      dataSourceUsers: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      popupOptions: {
        title: 'Evidencia',
        showTitle: true,
        width: 800,

      },
      backendURL: process.env.VUE_APP_APIURL,
      imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
      imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
      retryButtonVisible: false,
      fileUploaderRef,
      imgRef,
      fileTypeImg: false,
      fileTypeVideo: false,
      loadingVisible: false,
      formAttributes: {
        id: 'popupevidencia',
      },
      position: { of: '#popupevidencia' },
    };
  },
  created() {
    this.workOrderEquipmentEvidenceService = new WorkOrderEquipmentEvidenceService();
    this.userService = new UserService();
    this.typeFileService = new TypeFileService();
  },
  mounted() {
    this.userService.getUsuariosPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceUsers = data);
    this.typeFileService.getTipoArchivos().then((data) => {
      let datatype = [];
      data.forEach(element => {
        if (element.id_tipo_archivo == 1 || element.id_tipo_archivo == 2 || element.id_tipo_archivo == 6) {
          datatype.push(element);
        }
      });
      this.dataSourceFileTypes = datatype;

    });
    this.workOrderEquipmentEvidenceService.getEvidenciasPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceWorkOrderEvidences = data);
  },
  computed: {
    fileUploader: function () {
      return this.$refs[fileUploaderRef].instance;
    },
    imageElement: function () {
      return this.$refs[imgRef];
    }
  },
  methods: {
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onClick() {

      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
      this.loadingVisible = true;
    },
    onValueChanged(e) {
      let type = e.value[0].type;
      let result = type.includes('image');
      this.loadingVisible = true;
      if (result) {
        document.getElementById("outputvideo").style.display = "none";
        document.getElementById("outputimg").style.display = "block";
        var reader = new FileReader();
        reader.onload = function () {
          var output = document.getElementById('outputimg');
          output.src = reader.result;
        };
        reader.readAsDataURL(e.value[0]);
      } else {
        document.getElementById("outputimg").style.display = "none";
        document.getElementById("outputvideo").style.display = "block";
        var readervideo = new FileReader();
        readervideo.onload = function () {
          var output = document.getElementById('outputvideo');
          output.src = readervideo.result;
        };
        readervideo.readAsDataURL(e.value[0]);
      }
      this.retryButtonVisible = false;
    },
    onUploaded(e, cellInfo) {
      this.loadingVisible = true;
      //console.log(e);
      let fileObject = JSON.parse(e.request.responseText);
      //cellInfo.setValue("archivos/fotos/" + e.request.responseText);
      cellInfo.setValue(fileObject.data.image_url);
      this.loadingVisible = false;
      this.retryButtonVisible = false;
    },
    onUploadError(e) {
      let xhttp = e.request;
      if (xhttp.status === 400) {
        e.message = e.error.responseText;
      }
      if (xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
      this.retryButtonVisible = true;
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    deleteWorkOrderEquipmentEvidence(e) {
      var id_usuario = localStorage.getItem("user-data");
      e.data.id_usuario_reg = id_usuario;
      this.workOrderEquipmentEvidenceService.deleteOrdenTrabajoEvidencia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.workOrderEquipmentEvidenceService.getEvidenciasPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceWorkOrderEvidences = data);
      });
    },
    createWorkOrderEquipmentEvidence(e) {
      var id_usuario = localStorage.getItem("user-data");
      this.workOrderEquipmentEvidenceService.createOrdenTrabajoEvidencia({
        'archivo': e.data.archivo,
        "id_orden_trabajo_equipo": this.workOrderEquipmentId, "descripcion": e.data.descripcion,
        "antes_mtto": e.data.antes_mtto, "reporte_servicio": e.data.reporte_servicio, "cotizacion": e.data.cotizacion, "id_tipo_archivo": e.data.id_tipo_archivo,
        "id_usuario": e.data.id_usuario, 'id_usuario_reg': id_usuario
      }).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.workOrderEquipmentEvidenceService.getEvidenciasPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceWorkOrderEvidences = data);
      });
    },
    editWorkOrderEquipmentEvidence(e) {
      // var urlimg = e.data.archivo;
      // let result = urlimg.includes("https")
      // if (!result) {
      //   e.data.archivo = this.imageURL + e.data.archivo;
      // }
      var id_usuario = localStorage.getItem("user-data");
      e.data.id_usuario_reg = id_usuario;
      e.data.id_orden_trabajo_equipo = this.workOrderEquipmentId;
      this.workOrderEquipmentEvidenceService.updateOrdenTrabajoEvidencia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.workOrderEquipmentEvidenceService.getEvidenciasPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceWorkOrderEvidences = data);
      });
    },
    onInitNewRow(e) {
      e.data.reporte_servicio = true;
      e.data.antes_mtto = false;
      e.data.cotizacion = false;
    },
  },
};
