import axios from 'axios';

export default class TypeItemMovService {

	getTipoArticuloMovimientos() {			
        const url = process.env.VUE_APP_APIURL + `tipoarticulomovimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoArticuloMovimiento(tipoarticulomovimiento) {
        const url = process.env.VUE_APP_APIURL + `/tipoarticulomovimientos/${tipoarticulomovimiento.id_tipo_articulo_movimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoArticuloMovimiento(tipoarticulomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoarticulomovimientos/${tipoarticulomovimiento.id_tipo_articulo_movimiento}`;
		return axios.put(url,tipoarticulomovimiento).then(res=>res.data.data);
	}

	createTipoArticuloMovimiento(tipoarticulomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoarticulomovimientos`;
		return axios.post(url,tipoarticulomovimiento).then(res=>res.data.data);
	}

	deleteTipoArticuloMovimiento(tipoarticulomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipoarticulomovimientos/${tipoarticulomovimiento.id_tipo_articulo_movimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}