import axios from 'axios';

export default class TaskReasonService {

	getRazonTareas() {			
        const url = process.env.VUE_APP_APIURL + `razontareas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getRazonTarea(razontarea) {
        const url = process.env.VUE_APP_APIURL + `/razontareas/${razontarea.id_razon_tarea}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateRazonTarea(razontarea){
        const url = process.env.VUE_APP_APIURL + `/razontareas/${razontarea.id_razon_tarea}`;
		return axios.put(url,razontarea).then(res=>res.data.data);
	}

	createRazonTarea(razontarea){
        const url = process.env.VUE_APP_APIURL + `/razontareas`;
		return axios.post(url,razontarea).then(res=>res.data.data);
	}

	deleteRazonTarea(razontarea){
        const url = process.env.VUE_APP_APIURL + `/razontareas/${razontarea.id_razon_tarea}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}