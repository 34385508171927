import axios from 'axios';

export default class GroupItemService {

	getGrupoArticulos() {	
		const url = process.env.VUE_APP_APIURL + `grupoarticulos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoArticulo(grupoarticulo) {
		const url = process.env.VUE_APP_APIURL + `/grupoarticulos/${grupoarticulo.id_grupo_articulo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoArticulo(grupoarticulo) {
		const url = process.env.VUE_APP_APIURL + `/grupoarticulos/${grupoarticulo.id_grupo_articulo}`;
		return axios.put(url,grupoarticulo)
		.then(res=>res.data.data);
	}

	createGrupoArticulo(grupoarticulo){
        const url = process.env.VUE_APP_APIURL + `/grupoarticulos`;
		return axios.post(url,grupoarticulo).then(res=>res.data.data);
	}

	deleteGrupoArticulo(grupoarticulo){
		const url = process.env.VUE_APP_APIURL + `/grupoarticulos/${grupoarticulo.id_grupo_articulo}`;
		return axios.delete(url).then(res=>res.data.data);
	}

	getGrupoArticulosTodosCombo() {
		const url = process.env.VUE_APP_APIURL + `grupoarticulos/combos/opciontodos`;
		return axios.get(url).then((res) => res.data.data);
	}
}