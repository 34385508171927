import axios from 'axios';

export default class AlertVarService {

	getVariableAlertas() {		
        const url = process.env.VUE_APP_APIURL + `variablealertas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getVariableAlerta(variablealerta) {
        const url = process.env.VUE_APP_APIURL + `/variablealertas/${variablealerta.id_variable_alerta}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateVariableAlerta(variablealerta){
        const url = process.env.VUE_APP_APIURL + `/variablealertas/${variablealerta.id_variable_alerta}`;
		return axios.put(url,variablealerta).then(res=>res.data.data);
	}

	createVariableAlerta(variablealerta){
        const url = process.env.VUE_APP_APIURL + `/variablealertas`;
		return axios.post(url,variablealerta).then(res=>res.data.data);
	}

	deleteVariableAlerta(variablealerta){
        const url = process.env.VUE_APP_APIURL + `/variablealertas/${variablealerta.id_variable_alerta}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}