
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxScrolling
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import BrandService from '../services/brand-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxFilterRow,
    DxSelection,
    DxSearchPanel,
    DxSorting,
    DxPager,
    DxScrolling
  },
  data() {
    return {
      dataSourceBrand: null,
      marca: {},
      events: [],
      brandService: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.brandService = new BrandService();
  },
  mounted() {
    this.brandService.getMarcas().then(data => this.dataSourceBrand = data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editBrand(e) {
      this.brandService.updateMarca(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    deleteBrand(e) {
      this.brandService.deleteMarca(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
    createBrand(e) {
      this.brandService.createMarca(e.data).then((data) => {
        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true;
        }
      });
    },
  },
};
