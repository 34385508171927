import axios from 'axios';

export default class TypeFailureService {

	getTipoFallas() {			
        const url = process.env.VUE_APP_APIURL + `tipofallas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoFalla(tipofalla) {
        const url = process.env.VUE_APP_APIURL + `/tipofallas/${tipofalla.id_tipo_falla}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoFalla(tipofalla){
        const url = process.env.VUE_APP_APIURL + `/tipofallas/${tipofalla.id_tipo_falla}`;
		return axios.put(url,tipofalla).then(res=>res.data.data);
	}

	createTipoFalla(tipofalla){
        const url = process.env.VUE_APP_APIURL + `/tipofallas`;
		return axios.post(url,tipofalla).then(res=>res.data.data);
	}

	deleteTipoFalla(tipofalla){
        const url = process.env.VUE_APP_APIURL + `/tipofallas/${tipofalla.id_tipo_falla}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}