

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import StockRequest from './stock-request.vue';
import StockRequestHistory from './stock-request-history.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    StockRequest,
    StockRequestHistory,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
