
import {
  DxDataGrid, DxColumn, DxPaging, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxLookup, DxColumnChooser, DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import auth from '../auth';

import CompanyService from '../services/company-service';
import TypeGeofenceEventService from '../services/type-geofence-event-service';
import LocationService from '../services/location-service';
import GeofenceEventService from '../services/geofence-event-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,    
    DxPaging,
    DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxColumnChooser, DxLookup,
    DxGroupPanel, 
    DxGrouping,
    DxToast, 
    DxDateBox,
    DxSelectBox,
    DxButton,
  },
  data() {
    const now = new Date();
    return {
      usuarioId: null,
      company: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      dataSourceCompanies: null,
      dataSourceGeofenceEvents: null,
      dataSourceTypeGeofenceEvent: null,
      dataSourceLocations: null,
      logs: {},
      events: [],
      tipolog: null,
      usuarios: null,
      alertService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      mode: 'select',
      initialDate: null,
      finalDate: null,
      autoExpandAll: false,
    };
  },
  created() {
    this.companyService = new CompanyService();
    this.typeGeofenceEventService = new TypeGeofenceEventService();
    this.locationService = new LocationService();
    this.geofenceEventService = new GeofenceEventService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.typeGeofenceEventService.getTipoGeocercaEventos().then(data => this.dataSourceTypeGeofenceEvent=data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations=data);
    auth.getUser().then((data) => {
      console.log(data.data.id_usuario);
      this.usuarioId = data.data.id_usuario;
      this.companyService.getEmpresas().then((data) => {
        this.dataSourceCompanies=data; 
        this.company=data[0];
        this.geofenceEventService.getGeocercaEventoPorFiltro(this.usuarioId,this.company.id_empresa,this.startDate,this.endDate).then(data => this.dataSourceGeofenceEvents=data);
      });
    });
  },
  methods: {
    onClick() {
      this.geofenceEventService.getGeocercaEventoPorFiltro(this.usuarioId,this.company.id_empresa,this.startDate,this.endDate).then(data => this.dataSourceGeofenceEvents=data);
    },
  }
};
