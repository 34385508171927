import axios from "axios";

export default class ConfigSurveyService {
  getConfigSurvey() {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `configencuestas`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  getConfigSurveySendEmail() {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `configencuestas/enviocorreo`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  getConfigSurveyByIdConfigSurvey(id_config_encuesta) {
    const url = process.env.VUE_APP_APIURL + `configencuestas`;
    return axios
      .get(url, { params: { id_config_encuesta: id_config_encuesta } })
      .then((res) => res.data.data);
  }

  createConfigSurvey(config_encuesta) {
    const url = process.env.VUE_APP_APIURL + `/configencuestas`;
    return axios
      .post(url, config_encuesta)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  updateConfigSurvey(config_encuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/configencuestas/${config_encuesta.id_config_encuesta}`;
    return axios
      .put(url, config_encuesta)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  deleteConfigSurvey(config_encuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/configencuestas/${config_encuesta.id_config_encuesta}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
