import axios from 'axios';

export default class TypeFindingService {

	getTipoHallazgos() {	
        const url = process.env.VUE_APP_APIURL + `tipohallazgos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoHallazgo(tipohallazgo) {
        const url = process.env.VUE_APP_APIURL + `/tipohallazgos/${tipohallazgo.id_tipo_hallazgo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoHallazgo(tipohallazgo) {
        const url = process.env.VUE_APP_APIURL + `/tipohallazgos/${tipohallazgo.id_tipo_hallazgo}`;
		return axios.put(url,tipohallazgo)
		.then(res=>res.data.data);
	}

	createTipoHallazgo(tipohallazgo){
        const url = process.env.VUE_APP_APIURL + `/tipohallazgos`;
		return axios.post(url,tipohallazgo).then(res=>res.data.data);
	}

	deleteTipoHallazgo(tipohallazgo){
        const url = process.env.VUE_APP_APIURL + `/tipohallazgos/${tipohallazgo.id_tipo_hallazgo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}