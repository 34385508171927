import axios from 'axios';

export default class ChecklistDetailService {

    getListaVerificacionDetallesPorListaVerificacion(listaverificacion) {
        const url = process.env.VUE_APP_APIURL + `listaverificaciondetalles/listaverificaciones`;
		return axios.get(url,{ params: { id_lista_verificacion: listaverificacion } }).then(res=>res.data.data);
	}

	getListaVerificacionDetalles() {
        const url = process.env.VUE_APP_APIURL + `listaverificaciondetalles`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getListaVerificacionDetalle(listaverificaciondetalle) {
        const url = process.env.VUE_APP_APIURL + `listaverificaciondetalles/${listaverificaciondetalle.id_lista_verificacion_detalle}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateListaVerificacionDetalle(listaverificaciondetalle){
        const url = process.env.VUE_APP_APIURL + `listaverificaciondetalles/${listaverificaciondetalle.id_lista_verificacion_detalle}`;
		return axios.put(url,listaverificaciondetalle).then(res=>res.data.data);
	}

	createListaVerificacionDetalle(listaverificaciondetalle){
        const url = process.env.VUE_APP_APIURL + `listaverificaciondetalles`;
		return axios.post(url,listaverificaciondetalle).then(res=>res.data.data);
	}

	deleteListaVerificacionDetalle(listaverificaciondetalle){
        const url = process.env.VUE_APP_APIURL + `listaverificaciondetalles/${listaverificaciondetalle.id_lista_verificacion_detalle}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}

