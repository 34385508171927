
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxValidationRule,
  DxSelection,
  DxButton,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DocumentService from '../services/document-service';
import TypeDocumentService from '../services/type-document-service';
import TypeFileService from '../services/type-file-service';
import DxFileUploader from 'devextreme-vue/file-uploader';

const fileUploaderRef = "fu";
const imgRef = "img"

/*
function downloadFile(url, fileName){
  fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
    .then(res => res.blob())
    .then(res => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', fileName);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      // aElement.setAttribute('href', href);
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
}
*/

const downloadFile2 = (path, filename) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.setAttribute('target', '_blank');

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
};

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxValidationRule,
    DxSelection,
    DxFileUploader,
    DxButton,
    DxColumnChooser
  },
  data() {
    return {
      headers:{Authorization: 'Bearer ' + localStorage.getItem('user-token')},
      documentos: null,
      dataSourceDocuments: null,
      dataSourceDocumentTypes: null,
      dataSourceFileTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Documento',
        showTitle: true,
        width: 800,
      },
      mode: 'select',
      backendURL: process.env.VUE_APP_APIURL,
      //imageURL: "https://api.apicontrol.website/v1/storage/fotos/",
      //imageURL: "https://shn.apiclix.com/api/v1/storage/fotos/",
      imageURL: process.env.VUE_APP_APIURL + "storage/documentos/",
      retryButtonVisible: false,
      fileUploaderRef,
      imgRef,
    };
  },
  created() {
    this.documentService = new DocumentService();
    this.typeDocumentService = new TypeDocumentService();
    this.typeFileService = new TypeFileService();    
  },
  mounted() {    
    this.documentService.getDocumentos().then(data => this.dataSourceDocuments=data);
    this.typeDocumentService.getTipoDocumentos().then(data => this.dataSourceDocumentTypes=data);
    this.typeFileService.getTipoArchivos().then(data => this.dataSourceFileTypes=data);       
  },
  computed: {
    fileUploader: function() {
      return this.$refs[fileUploaderRef].instance;
    },
    imageElement: function() {
      return this.$refs[imgRef];
    }
  },
  methods: {
    downloadMyFile(e) {
      const liga = this.imageURL + e.row.data.archivo;
      //console.log(liga);
      // downloadFile(liga, e.row.data.archivo);
      downloadFile2(liga, e.row.data.archivo);
      //console.log(e.row.data.archivo);
      //console.log(e.row.data.url);
    },
    onSaved() {
      if(this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if(this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onClick() {
      // The retry UI/API is not implemented. Use a private API as shown at T611719.
      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
    },
    onValueChanged(e) {
      let reader = new FileReader();
      reader.onload = (args) => {
        this.imageElement.setAttribute('src', args.target.result);
      }
      reader.readAsDataURL(e.value[0]); // convert to base64 string
    },
    onUploaded(e, cellInfo) {
      //console.log(e);
      let fileObject = JSON.parse(e.request.responseText);
      //console.log(fileObject.data.image_name);
      //console.log(cellInfo);
      //cellInfo.setValue("archivos/fotos/" + e.request.responseText);
      cellInfo.setValue(fileObject.data.image_name);
      this.retryButtonVisible = false;
    },
    onUploadError(e) {
      let xhttp = e.request;
      if(xhttp.status === 400){
        e.message = e.error.responseText;
      }
      if(xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
      this.retryButtonVisible = true;
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editDocument(e) {
      this.documentService.updateDocumento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.documentService.getDocumentos().then(data => this.dataSourceDocuments=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.documentService.getDocumentos().then(data => this.dataSourceDocuments=data);
      });
		},
    deleteDocument(e) {
			this.documentService.deleteDocumento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.documentService.getDocumentos().then(data => this.dataSourceDocuments=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.documentService.getDocumentos().then(data => this.dataSourceDocuments=data);
      });
		},
    createDocument(e) {
			this.documentService.createDocumento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.documentService.getDocumentos().then(data => this.dataSourceDocuments=data);
      });
		},    
    onInitNewRow(e) {
      e.data.banner = false;
      e.data.activo = true;
    },
  },
};
