
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import AlertOriginService from '../services/alert-origin-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceAlertOrigin: null,
      origenAlerta: {},
      events: [],
      alertOriginService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.alertOriginService = new AlertOriginService();
  },
  mounted() {    
    this.alertOriginService.getOrigenAlertas().then(data => this.dataSourceAlertOrigin=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editAlertOrigin(e) {
			this.alertOriginService.updateOrigenAlerta(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteAlertOrigin(e) {
			this.alertOriginService.deleteOrigenAlerta(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createAlertOrigin(e) {
			this.alertOriginService.createOrigenAlerta(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
