
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DepartmentService from '../services/department-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceDepartments: null,
      departamento: {},
      events: [],
      departmentService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.departmentService = new DepartmentService();
  },
  mounted() {    
    this.departmentService.getDepartamentos().then(data => this.dataSourceDepartments=data);
  },
  methods: {
    editDepartment(e) {
			this.departmentService.updateDepartamento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteDepartment(e) {
			this.departmentService.deleteDepartamento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createDepartment(e) {
			this.departmentService.createDepartamento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
