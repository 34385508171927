

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import AlertPage from './alert.vue';
import AlertAckPage from './alert-acknowledge.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    AlertPage,
    AlertAckPage,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
