import axios from "axios";

export default class ItemRackGroupService {
  getArticuloGrupoEstantes() {
    const url = process.env.VUE_APP_APIURL + `articulogrupoestantes`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getArticuloGrupoEstante(articulogrupoestante) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articulogrupoestantes/${articulogrupoestante.id_articulo_grupo_estante}`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  updateArticuloGrupoEstante(articulogrupoestante) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articulogrupoestantes/${articulogrupoestante.id_articulo_grupo_estante}`;
    return axios
      .put(url, articulogrupoestante)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createArticuloGrupoEstante(articulogrupoestante) {
    const url = process.env.VUE_APP_APIURL + `/articulogrupoestantes`;
    return axios
      .post(url, articulogrupoestante)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteArticuloGrupoEstante(articulogrupoestante) {
    const url =
      process.env.VUE_APP_APIURL +
      `/articulogrupoestantes/${articulogrupoestante.id_articulo_grupo_estante}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
