

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import ServiceRequest from './service-request.vue';
import ServiceRequestHistory from './service-request-history.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    ServiceRequest,
    ServiceRequestHistory,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
