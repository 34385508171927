
import {
  DxDataGrid,
  DxEditing,
  DxGrouping,
  DxGroupPanel,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxValidationRule,
  DxMasterDetail,
  DxSorting,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import UserService from '../services/user-service';
import RoleService from '../services/role-service';
import GroupUserService from '../services/group-user-service';
import JobTitleService from '../services/jobtitle-service';
import SupplierService from '../services/supplier-service';
import ClientService from '../services/client-service';
import CompanyUserService from '../services/company-user-service';
import MasterDetail from './user-detail.vue';



export default {
  /*
  onContentReady: function(e){
       e.element.find(".dx-texteditor-input").attr('autocomplete', 'off')
  },
  */
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxGrouping,
    DxGroupPanel,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxValidationRule,
    DxSorting,
    DxSelection,
    DxMasterDetail,
    MasterDetail,
  },
  data() {
    return {
      documentos: null,
      dataSourceUsers: null,
      dataSourceParentUsers: null,
      dataSourceJobTitles: null,
      dataSourceSuppliers: null,
      dataSourceClients: null,
      dataSourceRoles: null,
      dataSourceGroupUsers: null,
      dataSourceCompanies:null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Usuario',
        showTitle: true,
        width: 800,
      },
    };
  },
  created() {
    this.userService = new UserService();
    this.jobTitleService = new JobTitleService();
    this.groupUserService = new GroupUserService();
    this.supplierService = new SupplierService();
    this.clientService = new ClientService();
    this.roleService = new RoleService();
    this.companyUserService = new CompanyUserService();   
  },
  mounted() {       
    var id_usuario = localStorage.getItem("user-data");
    this.userService.getUsuarios().then(data => this.dataSourceParentUsers = data);
    this.roleService.getRoles().then(data => this.dataSourceRoles=data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data); 
    this.jobTitleService.getPuestos().then(data => this.dataSourceJobTitles=data);
    this.companyUserService.getEmpresasPorUsuario(id_usuario).then(data => this.dataSourceCompanies=data);
    this.groupUserService.getGrupoUsuarios().then(data => this.dataSourceGroupUsers=data).then(this.userService.getUsuarios().then(data => this.dataSourceUsers=data));
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editUser(e) {      
      this.userService.updateUsuario(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.userService.getUsuarios().then(data => this.dataSourceUsers=data);
      });
		},
    deleteUser(e) {
			this.userService.deleteUsuario(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.userService.getUsuarios().then(data => this.dataSourceUsers=data);
      });
		},
    createUser(e) {
			this.userService.createUsuario(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.userService.getUsuarios().then(data => this.dataSourceUsers=data);
      });
		},
    onInitNewRow(e) {
      e.data.activo = true;
      e.data.password = null;
    },
    // this.companyUserService.getEmpresasPorUsuario(id_usuario).then(data => this.dataSourceCompanies=data);
  },
};

