import axios from 'axios';

export default class MeasurementFlowService {

	getMedicionFlujoCombo() {
        const url = process.env.VUE_APP_APIURL + `medicionflujos/combos`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getMedicionFlujos() {
        const url = process.env.VUE_APP_APIURL + `medicionflujos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMedicionFlujo(medicionflujo) {
        const url = process.env.VUE_APP_APIURL + `/medicionflujos/${medicionflujo.id_medicion_flujo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMedicionFlujo(medicionflujo){
        const url = process.env.VUE_APP_APIURL + `/medicionflujos/${medicionflujo.id_medicion_flujo}`;
		return axios.put(url,medicionflujo).then(res=>res.data.data);
	}

	createMedicionFlujo(medicionflujo){
        const url = process.env.VUE_APP_APIURL + `/medicionflujos`;
		return axios.post(url,medicionflujo).then(res=>res.data.data);
	}

	deleteMedicionFlujo(medicionflujo){
        const url = process.env.VUE_APP_APIURL + `/medicionflujos/${medicionflujo.id_medicion_flujo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}