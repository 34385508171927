import axios from 'axios';

export default class ConfigPlanMaintenanceTaskItemService {

	getArticulosDisponiblesPorPlanTarea(configplanmttoequipotarea) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareaarticulos/disponibles/${configplanmttoequipotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getArticulosAsignadasPorPlanTarea(configplanmttoequipotarea) {		
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareaarticulos/asignadas/${configplanmttoequipotarea}`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getConfigPlanMttoTareaArticulos() {			
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareaarticulos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigPlanMttoTareaArticulo(configplanmttoequipotareaarticulo) {
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareaarticulos/${configplanmttoequipotareaarticulo.id_config_plan_mtto_tarea_articulo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	createConfigPlanMttoTareaArticulo(configplanmttoequipotareaarticulo){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareaarticulos`;
		return axios.post(url,configplanmttoequipotareaarticulo).then(res=>res.data.data);
	}

	updateConfigPlanMttoTareaArticulo(configplanmttoequipotareaarticulo){
        const url = process.env.VUE_APP_APIURL + `configplanmttoequipotareaarticulos/${configplanmttoequipotareaarticulo.id_config_plan_mtto_tarea_articulo}`;
		return axios.put(url,configplanmttoequipotareaarticulo).then(res=>res.data.data);
	}

	deleteConfigPlanMttoTareaArticulo(configplanmttoequipotareaarticulo){
        const url = process.env.VUE_APP_APIURL + `/configplanmttoequipotareaarticulos/${configplanmttoequipotareaarticulo.id_config_plan_mtto_tarea_articulo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}

