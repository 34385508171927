import axios from "axios";

export default class CompanyClientService {
  getClientesDisponiblesPorEmpresa(empresa) {
    const url =
      process.env.VUE_APP_APIURL + `empresaclientes/disponibles/${empresa}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getClientesAsignadosPorEmpresa(empresa) {
    const url =
      process.env.VUE_APP_APIURL + `empresaclientes/asignados/${empresa}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEmpresaClienteTodos() {
    const url = process.env.VUE_APP_APIURL + `empresaclientes/todos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEmpresaClientes() {
    const url = process.env.VUE_APP_APIURL + `empresaclientes`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEmpresaCliente(empresacliente) {
    const url =
      process.env.VUE_APP_APIURL +
      `/empresaclientes/${empresacliente.id_empresa_cliente}`;
    return axios.get(url).then((res) => res.data.data);
  }

  createEmpresaCliente(empresacliente) {
    const url = process.env.VUE_APP_APIURL + `/empresaclientes`;
    return axios
      .post(url, empresacliente)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  updateEmpresaCliente(empresacliente) {
    const url =
      process.env.VUE_APP_APIURL +
      `empresaclientes/${empresacliente.id_empresa_cliente}`;
    return axios
      .put(url, empresacliente)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEmpresaCliente(empresacliente) {
    const url =
      process.env.VUE_APP_APIURL +
      `/empresaclientes/${empresacliente.id_empresa_cliente}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  getClientesAsignadosPorEmpresaCombo(empresa) {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `empresaclientes/empresa`;
    return axios
      .get(url, { params: { id_empresa: empresa, id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }
}
