import axios from "axios";

export default class NotificationSettingsService {
  getConfigNotificaciones() {
    const url = process.env.VUE_APP_APIURL + `confignotificaciones`;
    return axios.get(url).then((res) => res.data.data);
  }

  getConfigNotificacionesDisponibles(usuario) {
    const url =
      process.env.VUE_APP_APIURL +
      `/confignotificaciones/disponibles/${usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getConfigNotificacionesAsignadas(usuario) {
    const url =
      process.env.VUE_APP_APIURL + `/confignotificaciones/asignados/${usuario}`;
    return axios.get(url).then((res) => res.data.data);
  }
}
