import axios from 'axios';

export default class DocumentClientService {
	
    getClienteAsignadosPorDocumento(documentoId) {		
        const url = process.env.VUE_APP_APIURL + `documentoclientes/asignados/${documentoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getClienteDisponiblesPorDocumento(documentoId) {		
        const url = process.env.VUE_APP_APIURL + `documentoclientes/disponibles/${documentoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    createDocumentoCliente(documentocliente){
        const url = process.env.VUE_APP_APIURL + `/documentoclientes`;
		return axios.post(url,documentocliente).then(res=>res.data.data);		
	}

	deleteDocumentoCliente(documentocliente){
        const url = process.env.VUE_APP_APIURL + `/documentoclientes/${documentocliente.id_documento_cliente}`;
		return axios.delete(url).then(res=>res.data.data);
	}

}