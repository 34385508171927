

import DxButton from "devextreme-vue/button";


export default {
  components: {
    DxButton,
  },
  data() {
    return {      
      type: 'info',
    };
  },
};
