import axios from 'axios';

export default class CompanyContactService {

	getEmpresaContactos() {		
        const url = process.env.VUE_APP_APIURL + `empresacontactos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEmpresaContacto(empresacontacto) {
        const url = process.env.VUE_APP_APIURL + `/empresacontactos/${empresacontacto.id_empresa_contacto}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEmpresaContacto(empresacontacto){
        const url = process.env.VUE_APP_APIURL + `/empresacontactos/${empresacontacto.id_empresa_contacto}`;
		return axios.put(url,empresacontacto).then(res=>res.data.data);
	}

	createEmpresaContacto(empresacontacto){
        const url = process.env.VUE_APP_APIURL + `/empresacontactos`;
		return axios.post(url,empresacontacto).then(res=>res.data.data);
	}

	deleteEmpresaContacto(empresacontacto){
        const url = process.env.VUE_APP_APIURL + `/empresacontactos/${empresacontacto.id_empresa_contacto}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}