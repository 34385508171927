
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';

import UserPanel from "./user-panel";

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const email = ref("");
    auth.getUser().then((e) => email.value = e.data.email);
    const usuario = JSON.parse(localStorage.getItem("user"));

    var userMenuItems = [];

    if (usuario.id_rol == 5) {
      userMenuItems = [
        {
          text: "Acerca de APIClix",
          icon: "info",
          onClick: onAboutClick
        },
        {
          text: "Aviso de Privacidad",
          icon: "bookmark",
          onClick: onPrivacyClick
        },
        {
          text: "Salir",
          icon: "runner",
          onClick: onLogoutClick
        }];

    } else if (usuario.id_rol == 1) {
      userMenuItems = [
        {
          text: "Mi Perfil",
          icon: "user",
          onClick: onProfileClick
        },
        {
          text: "Info de Licencia",
          icon: "info",
          onClick: onLicenseClick
        },
        {
          text: "Acerca de APIClix",
          icon: "info",
          onClick: onAboutClick
        },
        {
          text: "Aviso de Privacidad",
          icon: "bookmark",
          onClick: onPrivacyClick
        },
        {
          text: "Salir",
          icon: "runner",
          onClick: onLogoutClick
        }];
    } else {
      userMenuItems = [
        {
          text: "Mi Perfil",
          icon: "user",
          onClick: onProfileClick
        },
        {
          text: "Acerca de APIClix",
          icon: "info",
          onClick: onAboutClick
        },
        {
          text: "Aviso de Privacidad",
          icon: "bookmark",
          onClick: onPrivacyClick
        },
        {
          text: "Salir",
          icon: "runner",
          onClick: onLogoutClick
        }];
    }

    function onLogoutClick() {
      auth.logOut();
      router.push({
        path: "/login-form",
        query: { redirect: route.path }
      });
    }

    function onProfileClick() {
      router.push({
        path: "/profile",
        query: { redirect: route.path }
      });
    }

    function onLicenseClick() {
      router.push({
        path: "/license",
        query: { redirect: route.path }
      });
    }

    function onAboutClick() {
      router.push({
        path: "/about",
        query: { redirect: route.path }
      });
    }

    function onPrivacyClick() {
      router.push({
        path: "/privacy-policy",
        query: { redirect: route.path }
      });
    }

    return {
      email,
      userMenuItems
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel
  },
  data() {
    return {
      appVersion: null,
    };
  },
  created() {
    this.appVersion = process.env.VUE_APP_VERSION;
  },
};
