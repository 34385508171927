

// <div class="master-detail-caption">{{ idCliente }}</div>

import { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxLookup, DxForm, DxColumnChooser } from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import WarrantyRequestDetailService from '../services/warranty-request-detail-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import ProductSparePartService from '../services/product-spare-part-service';
import ProductService from '../services/product-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxLookup, DxForm, DxItem, DxToast, DxColumnChooser },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { folio, id_empresa, id_cliente } = this.templateData.data;
    const titulo = "Equipos, Componentes y Refacciones en Garantía. Folio: " + folio;
    return {
      solicitudgarantiadetalles: null,
      details: null,
      dataSourceWarrantyRequestDetails: null,
      dataSourceSpareParts: null,
      dataSourceComponents: null,
      dataSourceEquipments: null,
      detailInfo: `${folio}`,
      idEmpresa: id_empresa,
      idCliente: id_cliente,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: titulo,
        showTitle: true,
        width: 800,
        height: 600,
      },
      mode: 'select',
    };
  },
  created() {
    this.warrantyRequestDetailService = new WarrantyRequestDetailService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.productSparePartService = new ProductSparePartService();
    this.productService = new ProductService();
  },
  mounted() {
    this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesPorEquipo(this.idEmpresa,this.idCliente).then(data => this.dataSourceEquipments = data);
    this.productService.getComboProductosPorEmpresaCliente(this.idEmpresa,this.idCliente).then(data => this.dataSourceComponents = data);
    this.productSparePartService.getRefaccionesPorEmpresaCliente(this.idEmpresa,this.idCliente).then(data => this.dataSourceSpareParts = data);
    this.warrantyRequestDetailService.getSolicitudGarantiaDetalles().then((data) => {
        this.details=data;
        this.dataSourceWarrantyRequestDetails = this.getDetalles(this.templateData.key); 
    });
  },
  methods: {
    editWarrantyRequestDetail(e) {
        this.warrantyRequestDetailService.updateSolicitudGarantiaDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    deleteWarrantyRequestDetail(e) {
		this.warrantyRequestDetailService.deleteSolicitudGarantiaDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    createWarrantyRequestDetail(e) {
        e.data.id_solicitud_garantia = this.templateData.key;
		this.warrantyRequestDetailService.createSolicitudGarantiaDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    refreshData() {
        this.warrantyRequestDetailService.getSolicitudGarantiaDetalles().then((data) => {
        this.details=data;
        this.dataSourceWarrantyRequestDetails = this.getDetalles(this.templateData.key); 
    });
    },
    getDetalles(key) {
      return new DataSource({
        store: new ArrayStore({
          data: this.details,
          key: 'id_solicitud_garantia_detalle',
        }),
        filter: ['id_solicitud_garantia', '=', key],
      });
    },
  },
};
