
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxLookup
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import WarehouseService from '../services/warehouse-service';
import CompanyService from '../services/company-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxLookup,
  },
  data() {
    return {      
      dataSourceWarehouse: null,
      dataSourceCompanies: null,
      almacen: {},
      events: [],
      warehouseService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.warehouseService = new WarehouseService();
    this.companyService = new CompanyService();
  },
  mounted() {    
    this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouse=data);
    this.companyService.getEmpresasCombo().then(data => this.dataSourceCompanies=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editWarehouse(e) {
			this.warehouseService.updateAlmacen(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteWarehouse(e) {
			this.warehouseService.deleteAlmacen(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createWarehouse(e) {
			this.warehouseService.createAlmacen(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
