import axios from 'axios';

export default class MeasurementOptionDetailService {

	getMedicionOpcionDetalles() {			
        const url = process.env.VUE_APP_APIURL + `medicionopciondetalles`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getMedicionOpcionDetallesPorOpcion(medicionopcion) {			
        const url = process.env.VUE_APP_APIURL + `medicionopciondetalles/opciones/${medicionopcion}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getMedicionOpcionDetalle(medicionopciondetalle) {
        const url = process.env.VUE_APP_APIURL + `/medicionopciondetalles/${medicionopciondetalle.id_medicion_opcion_detalle}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateMedicionOpcionDetalle(medicionopciondetalle){
        const url = process.env.VUE_APP_APIURL + `/medicionopciondetalles/${medicionopciondetalle.id_medicion_opcion_detalle}`;
		return axios.put(url,medicionopciondetalle).then(res=>res.data.data);
	}

	createMedicionOpcionDetalle(medicionopciondetalle){
        const url = process.env.VUE_APP_APIURL + `/medicionopciondetalles`;        
		return axios.post(url,medicionopciondetalle).then(res=>res.data.data);
	}

	deleteMedicionOpcionDetalle(medicionopciondetalle){
        const url = process.env.VUE_APP_APIURL + `/medicionopciondetalles/${medicionopciondetalle.id_medicion_opcion_detalle}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}