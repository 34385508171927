import axios from 'axios';

export default class ConfigChecklistService {

	getConfigListaVerificaciones() {			
        const url = process.env.VUE_APP_APIURL + `configlistaverificaciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigListaVerificacion(configlistaverificacion) {
        const url = process.env.VUE_APP_APIURL + `configlistaverificaciones/${configlistaverificacion.id_config_lista_verificacion}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigListaVerificacion(configlistaverificacion){
        const url = process.env.VUE_APP_APIURL + `configlistaverificaciones/${configlistaverificacion.id_config_lista_verificacion}`;
		return axios.put(url,configlistaverificacion).then(res=>res.data.data);
	}

	createConfigListaVerificacion(configlistaverificacion){
        const url = process.env.VUE_APP_APIURL + `configlistaverificaciones`;
		return axios.post(url,configlistaverificacion).then(res=>res.data.data);
	}

	deleteConfigListaVerificacion(configlistaverificacion){
        const url = process.env.VUE_APP_APIURL + `configlistaverificaciones/${configlistaverificacion.id_config_lista_verificacion}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}