
  import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxLookup, 
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxSorting
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';

  import GroupActionService from '../services/group-action-service';
  import EquipmentCategoryService from '../services/equipment-category-service';
  import TypeMaintenanceService from '../services/type-maintenance-service';
  import EquipmentMaintenanceTypeActionService from '../services/equipment-maintenance-type-action-service';
  
  export default {
    components: {
      DxDataGrid,
      DxEditing,
      DxColumn,
      DxValidationRule,
      DxPaging,
      DxLookup,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxSelection,
      DxToast,
      DxSorting
    },
    data() {
      return {
        dataSourceEquipmentCategoryMaintenanceType: null,
        dataSourceGroupActions: null,
        dataSourceEquipmentCategories: null,
        dataSourceMaintenanceTypes: null,
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
      };
    },
    created() {
      this.groupActionService = new GroupActionService();
      this.equipmentCategoryService = new EquipmentCategoryService();
      this.typeMaintenanceService = new TypeMaintenanceService();
      this.equipmentMaintenanceTypeActionService = new EquipmentMaintenanceTypeActionService();
    },
    mounted() {    
      this.groupActionService.getGrupoAcciones().then(data => this.dataSourceGroupActions=data);
      this.equipmentCategoryService.getEquipoCategorias().then(data => this.dataSourceEquipmentCategories=data);
      this.typeMaintenanceService.getTipoMantenimientos().then(data => this.dataSourceMaintenanceTypes=data);
      this.equipmentMaintenanceTypeActionService.getEquipoTipoMantenimientoAcciones().then(data => this.dataSourceEquipmentCategoryMaintenanceType=data);
    },
    methods: {
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      editEquipmentCategoryMaintenanceType(e) {
        this.equipmentMaintenanceTypeActionService.updateEquipoTipoMantenimientoAccion(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
        });
      },
      deleteEquipmentCategoryMaintenanceType(e) {
        this.equipmentMaintenanceTypeActionService.deleteEquipoTipoMantenimientoAccion(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
        });
      },
      createEquipmentCategoryMaintenanceType(e) {
        this.equipmentMaintenanceTypeActionService.createEquipoTipoMantenimientoAccion(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
        });
      },
      onInitNewRow(e) {
        e.data.activo = true;
      },
    },  
  };
  