import axios from "axios";

export default class ConfigSurveyDetailOpcionService {
  getConfigSurveyDetailOpcion() {
    var id_usuario = localStorage.getItem("user-data");
    const url = process.env.VUE_APP_APIURL + `configencuestadetalleopciones`;
    return axios
      .get(url, { params: { id_usuario: id_usuario } })
      .then((res) => res.data.data);
  }

  getConfigSurveyDetailOpcionByIdConfigSurveyDetail(
    id_config_encuesta_detalle
  ) {
    const url =
      process.env.VUE_APP_APIURL +
      `configencuestadetalleopciones/configencuestadetalle`;
    return axios
      .get(url, {
        params: { id_config_encuesta_detalle: id_config_encuesta_detalle },
      })
      .then((res) => res.data.data);
  }

  createConfigSurveyDetailOpcion(configencuestadetalleopcion) {
    const url = process.env.VUE_APP_APIURL + `/configencuestadetalleopciones`;
    return axios
      .post(url, configencuestadetalleopcion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  updateConfigSurveyDetailOpcion(configencuestadetalleopcion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/configencuestadetalleopciones/${configencuestadetalleopcion.id_config_encuesta_detalle_opcion}`;
    return axios
      .put(url, configencuestadetalleopcion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
  deleteConfigSurveyDetailOpcion(configencuestadetalleopcion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/configencuestadetalleopciones/${configencuestadetalleopcion.id_config_encuesta_detalle_opcion}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
