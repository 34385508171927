
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule,
} from 'devextreme-vue/data-grid';
import {
    DxEmptyItem,
} from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxFileUploader from 'devextreme-vue/file-uploader';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

import UserService from '../services/user-service';
import TypeFileService from '../services/type-file-service';
import FaultReportEvidenceService from '../services/fault-report-evidence-service';

const fileUploaderRef = "fu";

export default {
    props: {
        templateData: {
            type: Object,
            default: () => { },
        },
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxFilterRow,
        DxScrolling,
        DxPager,
        DxToast,
        DxColumnChooser,
        DxSelection,
        DxSorting,
        DxForm,
        DxItem,
        DxValidationRule,
        DxFileUploader,
        DxLoadPanel,
        DxEmptyItem
    },
    data() {
        const { id_reporte_falla } = this.templateData.data;
        return {
            idReporteFalla: id_reporte_falla,
            dataSourceFaultReportEvidence: null,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') },
            dataSourceFileTypes: null,
            dataSourceUsers: null,
            isVisible: false,
            message: '',
            type: 'info',
            mode: 'select',
            popupOptions: {
                title: 'Evidencia',
                showTitle: true,
                width: 800,

            },
            backendURL: process.env.VUE_APP_APIURL,
            imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
            imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
            fileUploaderRef,
            retryButtonVisible: false,
            loadingVisible: false,
            formAttributes: {
                id: 'popupevidencia',
            },
            position: { of: '#popupevidencia' },
        }
    },
    created() {
        this.userService = new UserService();
        this.typeFileService = new TypeFileService();
        this.faultReportEvidenceService = new FaultReportEvidenceService();
    },
    mounted() {
        this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
        this.typeFileService.getTipoArchivos().then((data) => {
            let datatype = [];
            data.forEach(element => {
                if (element.id_tipo_archivo == 1 || element.id_tipo_archivo == 2 || element.id_tipo_archivo == 6) {
                    datatype.push(element);
                }
            });
            this.dataSourceFileTypes = datatype;
        });
        this.faultReportEvidenceService.getFaultReportEvidencebyFaultReport(this.idReporteFalla).then(data => this.dataSourceFaultReportEvidence = data);
    },
    computed: {
        fileUploader: function () {
            return this.$refs[fileUploaderRef].instance;
        },
    },
    methods: {
        onClick() {
            for (var i = 0; i < this.fileUploader._files.length; i++) {
                delete this.fileUploader._files[i].uploadStarted;
            }
            this.fileUploader.upload();
            this.loadingVisible = true;
        },
        onValueChanged(e) {
            let type = e.value[0].type;
            let result = type.includes('image');
            this.loadingVisible = true;
            if (result) {
                document.getElementById("outputvideo").style.display = "none";
                document.getElementById("outputimg").style.display = "block";
                var reader = new FileReader();
                reader.onload = function () {
                    var output = document.getElementById('outputimg');
                    output.src = reader.result;
                };
                reader.readAsDataURL(e.value[0]);
            } else {
                document.getElementById("outputimg").style.display = "none";
                document.getElementById("outputvideo").style.display = "block";
                var readervideo = new FileReader();
                readervideo.onload = function () {
                    var output = document.getElementById('outputvideo');
                    output.src = readervideo.result;
                };
                readervideo.readAsDataURL(e.value[0]);
            }
            this.retryButtonVisible = false;
        },
        onUploaded(e, cellInfo) {
            this.loadingVisible = true;
            let fileObject = JSON.parse(e.request.responseText);
            cellInfo.setValue(fileObject.data.image_url);
            this.loadingVisible = false;
            this.retryButtonVisible = false;
        },
        onUploadError(e) {
            let xhttp = e.request;
            if (xhttp.status === 400) {
                e.message = e.error.responseText;
            }
            if (xhttp.readyState === 4 && xhttp.status === 0) {
                e.message = "Conexión rechazada";
            }
            this.retryButtonVisible = true;
        },
        onInitNewRow(e) {
            e.data.reporte_servicio = true;
            e.data.cotizacion = false;
        },
        createFaultReportEvidence(e) {
            e.data.id_reporte_falla = this.idReporteFalla;
            this.faultReportEvidenceService.createFaultReportEvidence(e.data).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.faultReportEvidenceService.getFaultReportEvidencebyFaultReport(this.idReporteFalla).then(data => this.dataSourceFaultReportEvidence = data);
            });
        },
        editWorkFaultReportEvidence(e) {
            e.data.id_reporte_falla = this.idReporteFalla;
            this.faultReportEvidenceService.updateFaultReportEvidence(e.data).then(() => {
                this.type = 'success';
                this.message = 'Se ha acualizado el registro';
                this.isVisible = true;
                this.faultReportEvidenceService.getFaultReportEvidencebyFaultReport(this.idReporteFalla).then(data => this.dataSourceFaultReportEvidence = data);
            });
        },
        deleteWorkFaultReportEvidence(e) {
            e.data.id_reporte_falla = this.idReporteFalla;
            this.faultReportEvidenceService.deleteFaultReportEvidence(e.data).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.faultReportEvidenceService.getFaultReportEvidencebyFaultReport(this.idReporteFalla).then(data => this.dataSourceFaultReportEvidence = data);
            });
        },
    },
}

