import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import axios from "axios";

import { createApp } from "vue";
import router from "./router";

import App from "./App";
import appInfo from "./app-info";

axios.interceptors.request.use(
  function (config) {
    const auth_token = localStorage.getItem("user-token");
    if (auth_token) {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }
    return config;
  },
  function (error) {
    if (error.response.status === 401) {
      // && error.config && !error.config.__isRetryRequest
      this.$router.push({ path: "/login-form" });
      localStorage.removeItem("user-token");
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (config) {
    const auth_token = localStorage.getItem("user-token");
    if (auth_token) {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }
    return config;
  },
  function (error) {
    //console.log(error);
    if (error.response.status === 401) {
      location.reload();
      // && error.config && !error.config.__isRetryRequest

      localStorage.removeItem("user-token");
      localStorage.removeItem("user-data");
      localStorage.removeItem("user");
      // router.replace({
      //   path: "/login-form",
      //   query: { redirect: router.currentRoute.fullPath },
      // });
    }

    return Promise.reject(error);
  },
  (error) => {
    console.log("error", error);
  }
);
import vue3StarRatings from "vue3-star-ratings";
const app = createApp(App);
app.use(router);
app.config.globalProperties.$appInfo = appInfo;
app.component("vue3-star-ratings", vue3StarRatings);
app.mount("#app");
