
import {
  DxDataGrid, DxColumn, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxLoadPanel, DxScrolling, DxColumnChooser, DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import DxRadioGroup from 'devextreme-vue/radio-group';
import notify from 'devextreme/ui/notify';
import { modes } from '../data/data.js';



export default {
  components: {
    DxDataGrid, DxColumn, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxLoadPanel, DxScrolling, DxColumnChooser, DxGroupPanel,
    DxGrouping,
    DxTextBox,
    DxNumberBox,
    DxButton,
    DxRadioGroup,
  },
  data() {
      return {
        modes,
        selectedIndex: 0,
        valPassword: null,
        mode: 'select',
        autoExpandAll: false,
        dataSourceSchedules: null,
      };
  },
  created() {
      //this.companyService = new CompanyService();
  },
  mounted() {
      //this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);    
  },
  methods: {
    logEvent(eventName) {
        this.events.unshift(eventName);
    },
    onClick(e) {
      const buttonText = e.component.option('text');
      notify(`The ${this.capitalize(buttonText)} button was clicked`);
    },
    valueChangedNumberBox(e) {
      const buttonText = e.component.option('text');
      notify(`The ${this.capitalize(buttonText)} numberbox was modified`);
    }
  },
}
