import axios from 'axios';

export default class TypeKpiService {

	getTipoKPIs() {			
        const url = process.env.VUE_APP_APIURL + `tipokpis`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoKPI(tipokpi) {
        const url = process.env.VUE_APP_APIURL + `/tipokpis/${tipokpi.id_tipo_kpi}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoKPI(tipokpi){
        const url = process.env.VUE_APP_APIURL + `/tipokpis/${tipokpi.id_tipo_kpi}`;
		return axios.put(url,tipokpi).then(res=>res.data.data);
	}

	createTipoKPI(tipokpi){
        const url = process.env.VUE_APP_APIURL + `/tipokpis`;
		return axios.post(url,tipokpi).then(res=>res.data.data);
	}

	deleteTipoKPI(tipokpi){
        const url = process.env.VUE_APP_APIURL + `/tipokpis/${tipokpi.id_tipo_kpi}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}