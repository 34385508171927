
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxButtonItem,
  DxLabel,
  DxValidationRule,
} from 'devextreme-vue/form';

import { DxLookup } from 'devextreme-vue/lookup';
import { DxTagBox } from 'devextreme-vue/tag-box';
import DxDateBox from 'devextreme-vue/date-box';
import { DxToast } from 'devextreme-vue/toast';

import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import SupplierService from '../services/supplier-service';
import UserService from '../services/user-service';
import NotificationService from '../services/notification-service';
// import auth from '../auth';

const submitButtonOptions = {
  text: "Enviar Notificación",
  type: 'default',
  useSubmitBehavior: true,
  //icon: 'home'
};

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxLookup,
    DxLabel,
    DxValidationRule,
    DxTagBox,
    DxToast,
    DxDateBox,
  },
  data() {

    return {
      chkPush: true,
      chkSMS: false,
      chkCorreo: false,
      chkWhatsApp: false,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      fechaProgramacion: null,
      idUsuario: null,
      idEmpresa: null,
      idCliente: null,
      idProveedor: null,
      idUsuarios: null,
      notificationFormData: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceSuppliers: null,
      dataSourceUsers: null,
      submitButtonOptions,
      bodyOptions: { height: 100 },
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.supplierService = new SupplierService();
    this.userService = new UserService();
    this.notificationService = new NotificationService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers = data);

  },
  methods: {
    handleSubmit(e) {

      var json = {
        'usuarios': this.idUsuarios,
        'titulo': this.notificationFormData.titulo,
        'cuerpo': this.notificationFormData.cuerpo,
        'fecha_programacion': this.fechaProgramacion,
        'sms': this.chkSMS,
        'correo': this.chkCorreo,
        'push': this.chkPush,
        'whatsapp': this.chkWhatsApp,
      };

      this.notificationService.createNotificacion(json).then((data) => {

        if (typeof (data.error) === "undefined") {
          this.type = 'success';
          this.message = data;
          this.isVisible = true;
        } else {
          this.type = 'error';
          this.message = data.error;
          this.isVisible = true
        }
        this.cleanForm();
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al enviar notificación';
        this.isVisible = true;
      });

      e.preventDefault();
    },
    cleanForm() {
      this.$refs.anyName.reset();
      this.fechaProgramacion = new Date();
      this.idUsuarios = null;
      this.idProveedor = null;
      this.idEmpresa = 1;
      this.idCliente = null;
    },
    valueChangedCompany(e) {
      this.idEmpresa = e.value;
      this.getUsersfilter();
    },
    valueChangedClient(e) {
      this.idCliente = e.value;
      this.getUsersfilter();
    },
    valueChangedSupplier(e) {
      this.idProveedor = e.value;
      this.getUsersfilter();
    },
    getUsersfilter() {
      this.idUsuarios = null;
      var json = {
        'id_empresa': this.idEmpresa,
        'id_cliente': this.idCliente,
        'id_proveedor': this.idProveedor
      };
      this.userService.getUsuariosFiltro(json).then((data) => {
        this.dataSourceUsers = data;
      });
    },
  }

};
