
import {
  DxDataGrid, DxColumn, DxLookup, DxEditing, DxPaging, DxValidationRule, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxScrolling, DxPager,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import UnitOpeationService from '../services/unit-operation-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import TypeOperationUnitService from '../services/type-operation-unit-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxValidationRule,
    DxFilterRow,
    DxSelection,
    DxSearchPanel,
    DxSorting,
    DxScrolling,
    DxPager,
    DxLookup,
  },
  data() {
    return {      
      dataSourceUnitOperations: null,
      dataSourceUnitMeasurements: null,
      dataSourceUnitOperationTypes: null,
      servicio: {},
      events: [],
      serviceService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
    };
  },
  created() {
    this.unitOpeationService = new UnitOpeationService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.typeOperationUnitService = new TypeOperationUnitService();
  },
  mounted() {
    this.typeOperationUnitService.getTipoUnidadOperaciones().then(data => this.dataSourceUnitOperationTypes=data);
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitMeasurements=data);
    this.unitOpeationService.getUnidadOperaciones().then(data => this.dataSourceUnitOperations=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editUnitOperation(e) {
			this.unitOpeationService.updateUnidadOperacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteUnitOperation(e) {
			this.unitOpeationService.deleteUnidadOperacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createUnitOperation(e) {
			this.unitOpeationService.createUnidadOperacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
