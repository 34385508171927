
import {
  DxTreeList,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxButton,
  DxSorting
} from 'devextreme-vue/tree-list';
import { DxItem, DxTabbedItem, DxTab} from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxFileUploader from 'devextreme-vue/file-uploader';
// import DxButtonVue from 'devextreme-vue/button';

import ItemService from '../services/item-service';
import BrandService from '../services/brand-service';
import GroupItemService from '../services/group-item-service';
import TypeItemService from '../services/type-item-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import CurrencyService from '../services/currency-service';
import ItemSpareService from '../services/item-spare-service';
import ItemClassificationService from '../services/item-classification-service';

const fileUploaderRef = "fu";
const imgRef = "img"

export default {
  components: {
    DxTreeList,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
    DxSorting,
    DxTabbedItem,
    DxTab,
    DxFileUploader,
    DxButton, 
  },
  data() {
    return {
      headers:{Authorization: 'Bearer ' + localStorage.getItem('user-token')},
      equipos: null,
      expandedRowKeys: [1],
      dataSourceItems: null,
      dataSourceBrands: null,
      dataSourceGroupItems: null,
      dataSourceItemTypes: null,
      dataSourceCurrencies: null,
      dataSourceFatherItems: null,
      dataSourceUnitOfMeasurements: null,
      dataSourceSpareParts: null,
      dataSourceItemClassification: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Artículo',
        showTitle: true,
        width: 900,
      },
      mode: 'select',
      //backendURL: "https://apiclix.apicontrol.website/api/v1/",
      //backendURL: "https://shn.apiclix.com/api/v1/",
      backendURL: process.env.VUE_APP_APIURL,
      //imageURL: "https://api.apicontrol.website/v1/storage/fotos/",
      //imageURL: "https://shn.apiclix.com/api/v1/storage/fotos/",
      imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
      imageURLThumbnail: process.env.VUE_APP_APIURL + "storage/preview/",
      retryButtonVisible: false,
      fileUploaderRef,
      imgRef,
    };
  },
  created() {
    this.itemService = new ItemService();
    this.brandService = new BrandService();
    this.groupItemService = new GroupItemService();
    this.typeItemService = new TypeItemService();
    this.currencyService = new CurrencyService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.itemSpareService = new ItemSpareService();
    this.itemClassificationService = new ItemClassificationService();
  },
  mounted() {   
    this.itemService.getArticulosWeb().then((data) => {this.dataSourceItems=data; this.dataSourceFatherItems=data;});    
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
    this.groupItemService.getGrupoArticulos().then(data => this.dataSourceGroupItems=data);
    this.typeItemService.getTipoArticulos().then(data => this.dataSourceItemTypes=data);
    this.currencyService.getMonedas().then(data => this.dataSourceCurrencies=data);
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitOfMeasurements=data);
    this.itemSpareService.getRefacciones().then(data => this.dataSourceSpareParts=data);
    this.itemClassificationService.getClasificacionArticulos().then(data => this.dataSourceItemClassification=data);
  },
  computed: {
    fileUploader: function() {
      return this.$refs[fileUploaderRef].instance;
    },
    imageElement: function() {
      return this.$refs[imgRef];
    }
  },
  methods: {
    onSaved() {
      if(this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if(this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onClick() {
      // The retry UI/API is not implemented. Use a private API as shown at T611719.
      for (var i = 0; i < this.fileUploader._files.length; i++) {
        delete this.fileUploader._files[i].uploadStarted;
      }
      this.fileUploader.upload();
    },
    onValueChanged(e) {
      let reader = new FileReader();
      reader.onload = (args) => {
        this.imageElement.setAttribute('src', args.target.result);
      }
      reader.readAsDataURL(e.value[0]); // convert to base64 string
    },
    onUploaded(e, cellInfo) {
      //console.log(e);
      let fileObject = JSON.parse(e.request.responseText);
      //console.log(fileObject.data.image_name);
      //console.log(cellInfo);
      //cellInfo.setValue("archivos/fotos/" + e.request.responseText);
      cellInfo.setValue(fileObject.data.image_name);
      this.retryButtonVisible = false;
    },
    onUploadError(e) {
      let xhttp = e.request;
      if(xhttp.status === 400){
        e.message = e.error.responseText;
      }
      if(xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
      this.retryButtonVisible = true;
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editItem(e) {
      this.itemService.updateArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.itemService.getArticulosWeb().then((data) => {this.dataSourceItems=data; this.dataSourceFatherItems=data;});
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.itemService.getArticulosWeb().then((data) => {this.dataSourceItems=data; this.dataSourceFatherItems=data;});
      });
		},
    deleteItem(e) {
			this.itemService.deleteArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.itemService.getArticulosWeb().then((data) => {this.dataSourceItems=data; this.dataSourceFatherItems=data;});
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.itemService.getArticulosWeb().then((data) => {this.dataSourceItems=data; this.dataSourceFatherItems=data;});
      });
		},
    createItem(e) {
			this.itemService.createArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.itemService.getArticulosWeb().then((data) => {this.dataSourceItems=data; this.dataSourceFatherItems=data;});
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.itemService.getArticulosWeb().then((data) => {this.dataSourceItems=data; this.dataSourceFatherItems=data;});
      });
		},
    onEditorPreparing(e) {
      if (e.dataField === 'id_articulo_padre') {
        e.editorOptions.showClearButton = true;
        e.editorOptions.disabled = true;
        e.editorOptions.value = null;
      }
    },
    onInitNewRow(e) {
      e.data.trazabilidad = true;
      e.data.activo = true;
      e.data.serie = false;
      e.data.id_equipo_padre = null;
      //this.equipmentService.getArticuloPadres().then(data => this.dataSourceFatherItems=data);
    },
    viewimage(e){ 
      var modal = document.getElementById("modalimage");
      var modalImg = document.getElementById("seemodalimage");
      modal.style.display = "block";
      modalImg.src = this.imageURL + e.row.data.imagen;
    },
    closeModal() {
      var modal = document.getElementById("modalimage");
      modal.style.display = "none";
    },
  },  
};
