import axios from 'axios';

export default class PurchaseOrderService {

	getOrdenComprasPorEmpresa(empresa) {
		const url =
		process.env.VUE_APP_APIURL + `ordencompras/empresas/${empresa}`;
		return axios.get(url).then((res) => res.data.data);
	}

	getOrdenCompras() {			
        const url = process.env.VUE_APP_APIURL + `ordencompras`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getOrdenCompraActivas() {			
        const url = process.env.VUE_APP_APIURL + `ordencompras/activas`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getOrdenCompraHistorial() {			
        const url = process.env.VUE_APP_APIURL + `ordencompras/historial`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrdenCompra(ordencompra) {
        const url = process.env.VUE_APP_APIURL + `/ordencompras/${ordencompra.id_orden_compra}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrdenCompra(ordencompra){
        const url = process.env.VUE_APP_APIURL + `/ordencompras/${ordencompra.id_orden_compra}`;
		return axios.put(url,ordencompra).then(res=>res.data.data);
	}

	createOrdenCompra(ordencompra){
        const url = process.env.VUE_APP_APIURL + `/ordencompras`;
		return axios.post(url,ordencompra).then(res=>res.data.data);
	}

	deleteOrdenCompra(ordencompra){
        const url = process.env.VUE_APP_APIURL + `/ordencompras/${ordencompra.id_orden_compra}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}