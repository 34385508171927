

import { DxDataGrid, DxColumn, DxEditing, DxLookup } from 'devextreme-vue/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ConfigFrequencyDetailService from '../services/config-frequency-detail-service';
import WeekService from '../services/week-service';
import MonthService from '../services/month-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxLookup },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //const { nombre } = this.templateData.data;
    return {
      details: null,
      dataSourceConfigFrequencyDetails: null,
      dataSourceWeeks: null,
      dataSourceMonths: null,
    };
  },
  created() {
    this.configFrequencyDetailService = new ConfigFrequencyDetailService();
    this.weekService = new WeekService();
    this.monthService = new MonthService();      
  },
  mounted() {
    this.weekService.getSemanas().then(data => this.dataSourceWeeks=data);
    this.monthService.getMeses().then(data => this.dataSourceMonths=data);
    this.configFrequencyDetailService.getConfigFrecuenciaDetalles().then((data) => {
        this.details=data;         
        this.dataSourceConfigFrequencyDetails = this.getDetails(this.templateData.key); 
    });             
  },
  methods: {
    editConfigFrequencyDetail(e) {
        this.configFrequencyDetailService.updateConfigFrecuenciaDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    deleteConfigFrequencyDetail(e) {
		this.configFrequencyDetailService.deleteConfigFrecuenciaDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    createConfigFrequencyDetail(e) {
        e.data.id_frecuencia_config = this.templateData.key;
		this.configFrequencyDetailService.createConfigFrecuenciaDetalle(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    refreshData() {
        this.configFrequencyDetailService.getConfigFrecuenciaDetalles().then((data) => {
        this.details=data;         
        this.dataSourceConfigFrequencyDetails = this.getDetails(this.templateData.key); 
    });
    },           
    getDetails(key) {      
      return new DataSource({
        store: new ArrayStore({
          data: this.details,
          key: 'id_frecuencia_config_detalle',
        }),
        filter: ['id_frecuencia_config', '=', key],
      });
    },
  },
};
