import axios from 'axios';

export default class TypeKitService {

	getTipoKits() {			
        const url = process.env.VUE_APP_APIURL + `tipokits`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoKit(tipokit) {
        const url = process.env.VUE_APP_APIURL + `/tipokits/${tipokit.id_tipo_kit}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoKit(tipokit){
        const url = process.env.VUE_APP_APIURL + `/tipokits/${tipokit.id_tipo_kit}`;
		return axios.put(url,tipokit).then(res=>res.data.data);
	}

	createTipoKit(tipokit){
        const url = process.env.VUE_APP_APIURL + `/tipokits`;
		return axios.post(url,tipokit).then(res=>res.data.data);
	}

	deleteTipoKit(tipokit){
        const url = process.env.VUE_APP_APIURL + `/tipokits/${tipokit.id_tipo_kit}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}