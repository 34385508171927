
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxEditing,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';

import DocumentService from '../services/document-service';
import EquipmentCategoryService from '../services/equipment-category-service';
import EquipmentCategoryDocumentService from '../services/equipment-category-document-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting,
  },
  data() {
    return {
      documents: null,
      dataSourceDocuments: null,
      dataSourceEquipmentCategoryAvailable: null,
      dataSourceEquipmentCategoryAssigned: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
    };
  },
  created() {
    this.documentService = new DocumentService();
    this.equipmentCategoryService = new EquipmentCategoryService();
    this.equipmentCategoryDocumentService = new EquipmentCategoryDocumentService();
  },
  mounted() {
    this.documentService.getDocumentosCombo().then(data => this.dataSourceDocuments=data);  
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_equipo_categoria);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addDocument() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.equipmentCategoryDocumentService.createEquipoCategoriaDocumento({"id_documento": this.documents.id_documento, "id_equipo_categoria": this.selectedRowsData[this.i].id_equipo_categoria}).then(() => {           
            
            if (this.i==this.selectedRowsData.length) {
              this.equipmentCategoryDocumentService.getEquipoCategoriaDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentCategoryAvailable=data);
              this.equipmentCategoryDocumentService.getEquipoCategoriaAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentCategoryAssigned=data);
            }
            
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    valueChangedDocument() {
      this.equipmentCategoryDocumentService.getEquipoCategoriaDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentCategoryAvailable=data);
      this.equipmentCategoryDocumentService.getEquipoCategoriaAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentCategoryAssigned=data);
    },
    deleteEquipmentCategory(e) {
      this.equipmentCategoryDocumentService.deleteEquipoCategoriaDocumento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.equipmentCategoryDocumentService.getEquipoCategoriaDisponiblesPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentCategoryAvailable=data);
        this.equipmentCategoryDocumentService.getEquipoCategoriaAsignadosPorDocumento(this.documents.id_documento).then(data => this.dataSourceEquipmentCategoryAssigned=data);
      });
    },
  },  
};
