import axios from 'axios';

export default class FrequencyService {

	getFrecuencias() {		
        const url = process.env.VUE_APP_APIURL + `frecuencias`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getFrecuencia(frecuencia) {
        const url = process.env.VUE_APP_APIURL + `/frecuencias/${frecuencia.id_frecuencia}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateFrecuencia(frecuencia){
        const url = process.env.VUE_APP_APIURL + `/frecuencias/${frecuencia.id_frecuencia}`;
		return axios.put(url,frecuencia).then(res=>res.data.data);
	}

	createFrecuencia(frecuencia){
        const url = process.env.VUE_APP_APIURL + `/frecuencias`;
		return axios.post(url,frecuencia).then(res=>res.data.data);
	}

	deleteFrecuencia(frecuencia){
        const url = process.env.VUE_APP_APIURL + `/frecuencias/${frecuencia.id_frecuencia}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}