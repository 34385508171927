

//     <div class="master-detail-caption">{{ detailInfo }}</div>

import { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxLookup } from 'devextreme-vue/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import ConfigChecklistMeasurementService from '../services/config-checklist-measurement-service';
import MeasurementService from '../services/measurement-service';
import ControlService from '../services/control-service';
import TypeDataService from '../services/type-data-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';

export default {
  components: { DxDataGrid, DxColumn, DxEditing, DxValidationRule, DxLookup },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //const { nombre } = this.templateData.data;
    return {
      measurements: null,
      dataSourceChecklistMeasurements: null,
      dataSourceMeasurements: null,
      dataSourceControls: null,
      dataSourceMeasurementUnits: null,
      dataSourceDataTypes: null,
      //detailInfo: `${nombre}`,
    };
  },
  created() {
    this.configChecklistMeasurementService = new ConfigChecklistMeasurementService();
    this.measurementService = new MeasurementService();
    this.controlService = new ControlService();
    this.typeDataService = new TypeDataService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
  },
  mounted() {
    this.typeDataService.getTipoDatos().then(data => this.dataSourceDataTypes=data);
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceMeasurementUnits=data);
    this.measurementService.getMediciones().then(data => this.dataSourceMeasurements=data);
    this.controlService.getControles().then(data => this.dataSourceControls=data);
    this.configChecklistMeasurementService.getConfigListaVerificacionMedicionesWeb().then((data) => {
        this.measurements=data;         
        this.dataSourceChecklistMeasurements = this.getMeasurements(this.templateData.key); 
    });             
  },
  methods: {
    editChecklistMeasurement(e) {
        this.configChecklistMeasurementService.updateConfigListaVerificacionMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    deleteChecklistMeasurement(e) {
		this.configChecklistMeasurementService.deleteConfigListaVerificacionMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    createChecklistMeasurement(e) {
        e.data.id_config_lista_verificacion = this.templateData.key;
		this.configChecklistMeasurementService.createConfigListaVerificacionMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.refreshData();
      });
	},
    refreshData() {
        this.configChecklistMeasurementService.getConfigListaVerificacionMedicionesWeb().then((data) => {
        this.measurements=data;         
        this.dataSourceChecklistMeasurements = this.getMeasurements(this.templateData.key); 
    });
    },           
    getMeasurements(key) {      
      return new DataSource({
        store: new ArrayStore({
          data: this.measurements,
          key: 'id_config_lista_verificacion_detalle',
        }),
        filter: ['id_config_lista_verificacion', '=', key],
      });
    },

    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'id_control'))  {
        e.editorOptions.disabled = e.row.isNewRow;
      }
      if (e.parentType === 'dataRow' && ((e.dataField === 'clave') || (e.dataField === 'id_unidad_medida') || (e.dataField === 'id_tipo_dato')))  {
        e.editorOptions.disabled = true;
      }
    },
    onInitNewRow(e) {
      e.data.orden = 1;
      e.data.activo = true;
    },
  },
};
