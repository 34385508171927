import axios from 'axios';

export default class EquipmentCharacteristicService {

	getEquipoCaracteristicas() {		
        const url = process.env.VUE_APP_APIURL + `equipocaracteristicas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoCaracteristica(equipocaracteristica) {
        const url = process.env.VUE_APP_APIURL + `/equipocaracteristicas/${equipocaracteristica.id_equipo_caracteristica}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoCaracteristica(equipocaracteristica){
        const url = process.env.VUE_APP_APIURL + `/equipocaracteristicas/${equipocaracteristica.id_equipo_caracteristica}`;
		return axios.put(url,equipocaracteristica).then(res=>res.data.data);
	}
}