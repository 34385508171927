import axios from 'axios';

export default class DataOriginService {

	getOrigenDatos() {		
        const url = process.env.VUE_APP_APIURL + `origendatos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getOrigenDato(origendato) {
        const url = process.env.VUE_APP_APIURL + `/origendatos/${origendato.id_origen_dato}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateOrigenDato(origendato){
        const url = process.env.VUE_APP_APIURL + `/origendatos/${origendato.id_origen_dato}`;
		return axios.put(url,origendato).then(res=>res.data.data);
	}

	createOrigenDato(origendato){
        const url = process.env.VUE_APP_APIURL + `/origendatos`;
		return axios.post(url,origendato).then(res=>res.data.data);
	}

	deleteOrigenDato(origendato){
        const url = process.env.VUE_APP_APIURL + `/origendatos/${origendato.id_origen_dato}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}