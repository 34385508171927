import axios from 'axios';

export default class StageService {

	getEtapas() {			
        const url = process.env.VUE_APP_APIURL + `etapas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEtapa(etapa) {
        const url = process.env.VUE_APP_APIURL + `/etapas/${etapa.id_etapa}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEtapa(etapa){
        const url = process.env.VUE_APP_APIURL + `/etapas/${etapa.id_etapa}`;
		return axios.put(url,etapa).then(res=>res.data.data);
	}

	createEtapa(etapa){
        const url = process.env.VUE_APP_APIURL + `/etapas`;
		return axios.post(url,etapa).then(res=>res.data.data);
	}

	deleteEtapa(etapa){
        const url = process.env.VUE_APP_APIURL + `/etapas/${etapa.id_etapa}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}