

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import ConfigPlanMaintenance from './config-plan-maintenance.vue';
import ConfigPlanEquipment from './config-plan-equipment.vue';
import ConfigPlanEquipmentTask from './config-plan-equipment-task.vue';
import ConfigPlanEquipmentTaskItem from './config-plan-equipment-task-item.vue';
import ConfigPlanEquipmentTaskService from './config-plan-equipment-task-service.vue';
import ConfigPlanEquipmentTaskMeasurement from './config-plan-equipment-task-measurement.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    ConfigPlanMaintenance,
    ConfigPlanEquipment,
    ConfigPlanEquipmentTask,
    ConfigPlanEquipmentTaskItem,
    ConfigPlanEquipmentTaskService,
    ConfigPlanEquipmentTaskMeasurement,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
