import axios from 'axios';

export default class WarrantyService {

	getGarantiaCombo() {			
        const url = process.env.VUE_APP_APIURL + `garantias/combos`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getGarantias() {			
        const url = process.env.VUE_APP_APIURL + `garantias`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGarantia(garantia) {
        const url = process.env.VUE_APP_APIURL + `/garantias/${garantia.id_garantia}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGarantia(garantia){
        const url = process.env.VUE_APP_APIURL + `/garantias/${garantia.id_garantia}`;
		return axios.put(url,garantia).then(res=>res.data.data);
	}

	createGarantia(garantia){
        const url = process.env.VUE_APP_APIURL + `/garantias`;
		return axios.post(url,garantia).then(res=>res.data.data);
	}

	deleteGarantia(garantia){
        const url = process.env.VUE_APP_APIURL + `/garantias/${garantia.id_garantia}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}