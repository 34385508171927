import axios from 'axios';

export default class TypeDataService {

	getTipoDatos() {		
        const url = process.env.VUE_APP_APIURL + `tipodatos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoDato(tipodato) {
        const url = process.env.VUE_APP_APIURL + `/tipodatos/${tipodato.id_tipo_dato}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoDato(tipodato){
        const url = process.env.VUE_APP_APIURL + `/tipodatos/${tipodato.id_tipo_dato}`;
		return axios.put(url,tipodato).then(res=>res.data.data);
	}

	createTipoDato(tipodato){
        const url = process.env.VUE_APP_APIURL + `/tipodatos`;
		return axios.post(url,tipodato).then(res=>res.data.data);
	}

	deleteTipoDato(tipodato){
        const url = process.env.VUE_APP_APIURL + `/tipodatos/${tipodato.id_tipo_dato}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}