import axios from "axios";

export default class ChannelActivityStatusService {
  getEstatusActividadCanales() {
    const url = process.env.VUE_APP_APIURL + `estatusactividadcanales`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusActividadCanal(estatusactividadcanal) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividadcanales/${estatusactividadcanal.id_estatus_actividad_canal}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusActividadCanal(estatusactividadcanal) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividadcanales/${estatusactividadcanal.id_estatus_actividad_canal}`;
    return axios.put(url, estatusactividadcanal).then((res) => res.data.data);
  }

  createEstatusActividadCanal(estatusactividadcanal) {
    const url = process.env.VUE_APP_APIURL + `/estatusactividadcanales`;
    return axios.post(url, estatusactividadcanal).then((res) => res.data.data);
  }

  deleteEstatusActividadCanal(estatusactividadcanal) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividadcanales/${estatusactividadcanal.id_estatus_actividad_canal}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getEstatusActividadDisponibles(canal) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividadcanales/disponibles/${canal}`;
    return axios.get(url).then((res) => res.data.data);
  }
  getEstatusActividadAsignados(canal) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividadcanales/asignados/${canal}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusActividadCanalOrden(estatusactividadcanal) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatusactividadcanales/orden/${estatusactividadcanal.id_estatus_actividad_canal}`;
    return axios.put(url, estatusactividadcanal).then((res) => res.data.data);
  }
}
