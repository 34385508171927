
import {
  DxDataGrid,
  DxColumn,
  DxLookup, 
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxSorting,
  DxColumnChooser,
  DxForm,
  DxEditing,
  DxItem
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import LocationService from '../services/location-service';
import BrandService from '../services/brand-service';
import GroupUserService from '../services/group-user-service';
import ConditionService from '../services/condition-service';
import FrequencyService from '../services/frequency-service';
import MaintenancePlanService from '../services/maintenance-plan-service';
import UserCompanyService from '../services/user-company-service';
import UserService from '../services/user-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxGroupPanel,
    DxGrouping,
    DxSorting,
    DxColumnChooser,
    DxForm,
    DxEditing,
    DxItem
  },
  data() {
    return {
      mantenimientos: null,
      dataSourceLastMaintenances: null,
      dataSourceBrands: null,
      dataSourceClients: null,
      dataSourceUserGroups: null,
      dataSourceConditions: null,
      dataSourceFrequencies: null,
      dataSourceCompanies: null,
      dataSourceLocations: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      popupOptions: {
        title: 'Último Mantenimiento',
        width: 600,
        height: 350,
        showTitle: true,
      },
    };
  },
  created() {
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.brandService = new BrandService();
    this.frequencyService = new FrequencyService();
    this.groupUserService = new GroupUserService();
    this.conditionService = new ConditionService();
    this.maintenancePlanService = new MaintenancePlanService();
    this.locationService = new LocationService();
    this.userCompanyService = new UserCompanyService();
  },
  mounted() {
    this.userService.getUsuariosTodos().then(data => this.dataSourceUsers=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
    this.frequencyService.getFrecuencias().then(data => this.dataSourceFrequencies=data);
    this.groupUserService.getGrupoUsuarios().then(data => this.dataSourceUserGroups=data);
    this.conditionService.getCondiciones().then(data => this.dataSourceConditions=data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations=data);
    const usuario = JSON.parse(localStorage.getItem("user"));
    if (usuario.id_usuario_empresa != null) {
      this.userCompanyService.getUsuarioEmpresa(usuario.id_usuario_empresa).then((data) => {
        this.maintenancePlanService.getUltimosMantenimientosPorEmpresa(data.id_empresa).then(data => this.dataSourceLastMaintenances=data);
      });
    }
  },
  methods: {
    editLastMaintenance(e) {
      this.maintenancePlanService.updateUltimoMantenimiento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        const usuario = JSON.parse(localStorage.getItem("user"));
        if (usuario.id_usuario_empresa != null) {
          this.userCompanyService.getUsuarioEmpresa(usuario.id_usuario_empresa).then((data) => {
            this.maintenancePlanService.getUltimosMantenimientosPorEmpresa(data.id_empresa).then(data => this.dataSourceLastMaintenances=data);
          });
        }
      });
		},
  }
};
