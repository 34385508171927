
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import SkillService from '../services/skill-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceSkills: null,
      habilidad: {},
      events: [],
      skillService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.skillService = new SkillService();
  },
  mounted() {    
    this.skillService.getHabilidades().then(data => this.dataSourceSkills=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editAbility(e) {
			this.skillService.updateHabilidad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteAbility(e) {
			this.skillService.deleteHabilidad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createAbility(e) {
			this.skillService.createHabilidad(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
