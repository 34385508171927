import axios from "axios";

export default class WorkOrderEquipmentTaskItemService {
  getArticulosComboPorOrdenTrabajoTarea(ordentrabajotarea) {
    const url =
      process.env.VUE_APP_APIURL +
      `ordentrabajoarticulos/combos/ordentrabajotareas/${ordentrabajotarea}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getArticulosPorOrdenTrabajoTarea(ordentrabajotarea) {
    const url =
      process.env.VUE_APP_APIURL +
      `ordentrabajoarticulos/ordentrabajotareas/${ordentrabajotarea}`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoArticulos() {
    const url = process.env.VUE_APP_APIURL + `ordentrabajoarticulos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getOrdenTrabajoArticulo(ordentrabajoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajoarticulos/${ordentrabajoarticulo.id_orden_trabajo_articulo}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateOrdenTrabajoArticulo(ordentrabajoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajoarticulos/web/${ordentrabajoarticulo.id_orden_trabajo_articulo}`;
    return axios
      .put(url, ordentrabajoarticulo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createOrdenTrabajoArticulo(ordentrabajoarticulo) {
    const url = process.env.VUE_APP_APIURL + `/ordentrabajoarticulos/web`;
    return axios
      .post(url, ordentrabajoarticulo)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteOrdenTrabajoArticulo(ordentrabajoarticulo) {
    const url =
      process.env.VUE_APP_APIURL +
      `/ordentrabajoarticulos/${ordentrabajoarticulo.id_orden_trabajo_articulo}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
