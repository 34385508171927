
import DxTileView from 'devextreme-vue/tile-view';
import { gallery } from '../data/data.js';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
  DxValidationRule,
} from 'devextreme-vue/form';
import { DxLookup } from 'devextreme-vue/lookup';
import DxTreeView from 'devextreme-vue/tree-view';
import { DxToast } from 'devextreme-vue/toast';

import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import LocationService from '../services/location-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import LeagueModuleService from '../services/league-module-service';

export default {
  components: {
    DxTileView,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxLabel,
    DxValidationRule,
    DxLookup,
    DxTreeView,
    DxToast
  },
  data() {
    return {
      dataSource: gallery,
      dataSourceInfo: [],
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceLocations: null,
      dataSourceEquipments: null,
      dataSourceFilters: [],
      idEmpresa: null,
      idCliente: null,
      idLocalizacion: null,
      idModuloLiga: null,
      directionDxTileView: 'vertical',
      idsEquipoEmpresas: [],
    };
  },
  created() {
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.locationService = new LocationService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.leagueModuleService = new LeagueModuleService();
  },
  mounted() {
    this.companyService.getEmpresas().then((data) => {
      this.dataSourceCompanies = data;
    });
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations = data);
    this.leagueModuleService.getModuloLigas().then(data => {
      if (data.length > 0) {
        var itemdata = {
          'id_modulo_liga': 0,
          'clave': 'TODAS',
          'nombre': '-TODAS-'
        };
        this.dataSourceFilters.push(itemdata);
        data.forEach(element => {
          this.dataSourceFilters.push(element);
        });
      }
    });
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
    },
    valueChangedCompany(e) {
      this.idEmpresa = e.value;
      this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesUbicacionPorEquipo(e.value, this.idCliente, this.idLocalizacion).then(data => this.dataSourceEquipments = data);
    },
    valueChangedClient(e) {
      this.idCliente = e.value;
      this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesUbicacionPorEquipo(this.idEmpresa, this.idCliente, this.idLocalizacion).then(data => this.dataSourceEquipments = data);
    },
    valueChangedLocation(e) {
      this.idLocalizacion = e.value;
      this.equipmentCompanyService.getEquipoEmpresasEmpresaClientesUbicacionPorEquipo(this.idEmpresa, this.idCliente, this.idLocalizacion).then(data => this.dataSourceEquipments = data);
    },
    valueChangedFilter(e) {
      this.idModuloLiga = e.value;
      if (this.idsEquipoEmpresas.length > 0 && this.idModuloLiga != null) {
        this.dataSourceInfo = [];
        var ids = this.idsEquipoEmpresas.join(',');
        this.equipmentCompanyService.getEquipoEmpresasEvidencias(this.idModuloLiga, ids).then((data) => {
          this.dataSourceInfo = data;
        });
      }
    },
    treeViewSelectionChanged(e) {
      this.idsEquipoEmpresas = [];
      const selectedEquipments = e.component.getSelectedNodes()
        .map((node) => node.itemData);
      for (var i in selectedEquipments) {
        var id_equipo_empresa = selectedEquipments[i].id_equipo_empresa ?? "";
        this.idsEquipoEmpresas.push(id_equipo_empresa);
      }
      if (this.idsEquipoEmpresas.length > 0 && this.idModuloLiga != null) {
        this.dataSourceInfo = [];
        var ids = this.idsEquipoEmpresas.join(',');
        this.equipmentCompanyService.getEquipoEmpresasEvidencias(this.idModuloLiga, ids).then((data) => {
          this.dataSourceInfo = data;
        });
      }

    },
    openModal(url) {
      var modal = document.getElementById("modalimage");
      var modalImg = document.getElementById("seemodalimage");
      modal.style.display = "block";
      modalImg.src = url;
    },
    closeModal() {
      var modal = document.getElementById("modalimage");
      modal.style.display = "none";
    },
  },
};
