
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import { DxItem, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';

import WarrantyRequestService from '../services/warranty-request-service';
import TypeWarrantyService from '../services/type-warranty-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import LocationService from '../services/location-service';
import SupplierService from '../services/supplier-service';
import StatusWarrantyService from '../services/status-warranty-service';
import UserService from '../services/user-service';
import FaultReportService from '../services/fault-report-service';

import DetailTemplate from './warranty-request-detail.vue';
import auth from '../auth';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxTabbedItem,
    DxTab,
    DxGroupPanel,
    DxGrouping
  },
  data() {
    return {
      autoExpandAll: true,
      idUsuario: null,
      solicitudgarantias: null,
      dataSourceWarrantyRequests: null,
      dataSourceWarranties: null,
      dataSourceWarrantyTypes: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceLocations: null,
      dataSourceSuppliers: null,
      dataSourceVendors: null,
      dataSourceStatus: null,
      dataSourceFailureReport: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Solicitud de Garantía',
        showTitle: true,
        width: 800,
        height: 600,
      },
      mode: 'select',
    };
  },
  created() {
    this.warrantyRequestService = new WarrantyRequestService();
    this.typeWarrantyService = new TypeWarrantyService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.locationService = new LocationService();
    this.supplierService = new SupplierService();
    this.statusWarrantyService = new StatusWarrantyService();
    this.userService = new UserService();
    this.faultReportService = new FaultReportService();
  },
  mounted() {
    this.typeWarrantyService.getTipoGarantias().then(data => this.dataSourceWarrantyTypes=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations=data);
    this.supplierService.getProveedores().then(data => this.dataSourceSuppliers=data);
    this.statusWarrantyService.getEstatusGarantias().then(data => this.dataSourceStatus=data);
    this.userService.getUsuariosPorPuesto('VENDEDOR').then(data => this.dataSourceVendors=data);
    this.faultReportService.getReporteFallasPendientes().then(data => this.dataSourceFailureReport=data);
    this.warrantyRequestService.getSolicitudGarantias().then(data => this.dataSourceWarrantyRequests=data);
    auth.getUser().then((data) => {
        this.idUsuario = data.data.id_usuario;
    });
  },
  methods: {
    editWarrantyRequest(e) {
      this.warrantyRequestService.updateSolicitudGarantia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.warrantyRequestService.getSolicitudGarantias().then(data => this.dataSourceWarrantyRequests=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.warrantyRequestService.getSolicitudGarantias().then(data => this.dataSourceWarrantyRequests=data);
      });
		},
    deleteWarrantyRequest(e) {
			this.warrantyRequestService.deleteSolicitudGarantia(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.warrantyRequestService.getSolicitudGarantias().then(data => this.dataSourceWarrantyRequests=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.warrantyRequestService.getSolicitudGarantias().then(data => this.dataSourceWarrantyRequests=data);
      });
		},
    createWarrantyRequest(e) {
			this.warrantyRequestService.createSolicitudGarantia({...e.data,"id_usuario_reg":this.idUsuario}).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.warrantyRequestService.getSolicitudGarantias().then(data => this.dataSourceWarrantyRequests=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.warrantyRequestService.getSolicitudGarantias().then(data => this.dataSourceWarrantyRequests=data);
      });
		},
    onInitNewRow(e) {
      e.data.fecha = new Date();
      e.data.id_estatus_garantia = 1;
      e.data.actividad = true;
      e.data.aplica_garantia = false;
    },
  },
};
