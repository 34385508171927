

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import Warranty from './warranty.vue';
import WarrantyEquipment from './warranty-equipment.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    Warranty,
    WarrantyEquipment,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
