
import {
  DxDataGrid, DxColumn, DxPaging, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxLookup, DxColumnChooser, DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import auth from '../auth';

import CompanyService from '../services/company-service';
import TypeEventService from '../services/type-event-service';
import LocationService from '../services/location-service';
import DataOriginService from '../services/data-origin-service';
import EventEquipmentService from '../services/event-equipment-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,    
    DxPaging,
    DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxPager, DxLoadPanel, DxScrolling, DxColumnChooser, DxLookup,
    DxGroupPanel, 
    DxGrouping,
    DxToast, 
    DxDateBox,
    DxSelectBox,
    DxButton,
  },
  data() {
    const now = new Date();
    return {
      usuarioId: null,
      company: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      dataSourceCompanies: null,
      dataSourceCompany: null,
      dataSourceEquipmentEvents: null,
      dataSourceTypeEvents: null,
      dataSourceLocations: null,
      dataSourceOrigins: null,
      logs: {},
      events: [],
      tipolog: null,
      usuarios: null,
      alertService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      mode: 'select',
      initialDate: null,
      finalDate: null,
      autoExpandAll: false,
    };
  },
  created() {
    this.companyService = new CompanyService();
    this.typeEventService = new TypeEventService();
    this.locationService = new LocationService();
    this.dataOriginService = new DataOriginService();
    this.eventEquipmentService = new EventEquipmentService();
  },
  mounted() {
    this.typeEventService.getTipoEventos().then(data => this.dataSourceTypeEvents=data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations=data);
    this.dataOriginService.getOrigenDatos().then(data => this.dataSourceOrigins=data);
    auth.getUser().then((data) => {
      this.usuarioId = data.data.id_usuario;
      this.companyService.getEmpresas().then((data) => {
        this.dataSourceCompanies=data;
        this.dataSourceCompany=data; 
        this.company=data[0];
        this.eventEquipmentService.getEventoEquipoPorFiltro(this.usuarioId,this.company.id_empresa,this.startDate,this.endDate).then(data => this.dataSourceEquipmentEvents=data);
      });
    });
  },
  methods: {
    onClick() {
      this.eventEquipmentService.getEventoEquipoPorFiltro(this.usuarioId,this.company.id_empresa,this.startDate,this.endDate).then(data => this.dataSourceEquipmentEvents=data);
    },
  }
};
