
import {
  DxTreeList,
  DxEditing,
  DxColumn,
  DxButton,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser
} from 'devextreme-vue/tree-list';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import DxPopup, { DxToolbarItem }from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import TaskService from '../services/task-service';
import ProcedureService from '../services/procedure-service';
import GroupTaskService from '../services/group-task-service';
import GroupActionService from '../services/group-action-service';
import TypeMaintenanceService from '../services/type-maintenance-service';
import 'devextreme-vue/text-area';
import DxList from 'devextreme-vue/list';
import SkillService from '../services/skill-service';
import TaskSkillService from '../services/task-skill-service';
import TaskMaintenanceTypeService from '../services/task-maintenance-type-service';

export default {
  components: {
    DxTreeList,
    DxEditing,
    DxColumn,
    DxButton,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
    DxPopup,
    DxToolbarItem,
    DxList,
    DxScrollView,
  },
  data() {
    return {
      formData: {},
      formDataMaintenance: {},
      popupTitle: "Habilidades requeridas de la tarea",
      popupVisible: false,
      popupVisibleMaintenanceType: false,
      popupTitleMaintenanceType: "Tipos de mantenimiento de la tarea",
      taskId: null,
      equipos: null,
      expandedRowKeys: [1],
      dataSourceTasks: null,
      dataSourceProcedures: null,
      dataSourceGroupTasks: null,
      dataSourceGroupActions: null,
      dataSourceMaintenanceTypes: null,
      dataSourceTaskMaintenanceTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Tarea',
        showTitle: true,
        width: 800,
      },
      checkBoxesMode: 'always',
      allMode: 'allPages',
      selectedTask: {nombre:''},
      mode: 'select',
      selectedItemKeys: [],
      selectedItemKeysMaintenance: [],
      loading: true,
      dataSourceSkills: null,       
      dataSourceUserSkills: null,
    };
  },
  created() {
    this.taskService = new TaskService();
    this.groupTaskService = new GroupTaskService();
    this.procedureService = new ProcedureService();
    this.skillService = new SkillService();
    this.taskSkillService = new TaskSkillService();
    this.typeMaintenanceService = new TypeMaintenanceService();
    this.taskMaintenanceTypeService = new TaskMaintenanceTypeService();
    this.groupActionService = new GroupActionService();
  },
  mounted() {
    this.groupActionService.getGrupoAcciones().then(data => this.dataSourceGroupActions=data);
    this.groupTaskService.getGrupoTareas().then(data => this.dataSourceGroupTasks=data);    
    this.procedureService.getProcedimientos().then(data => this.dataSourceProcedures=data); 
    this.taskService.getTareas().then(data => this.dataSourceTasks=data);
    this.skillService.getHabilidades().then(data => this.dataSourceSkills=data);
    this.typeMaintenanceService.getTipoMantenimientos().then(data => this.dataSourceMaintenanceTypes=data);
  },
  computed: {
    form: function() {
      return this.$refs.form.instance;
    }
  },
  methods: {
    onSelectionChangedMaintenanceType(e) {
      if (!this.loading) {
        if (e.addedItems.length==1) {
          console.log({"id_tarea": this.taskId, "id_habilidad": e.addedItems[0].id_tipo_mantenimiento});
          this.taskMaintenanceTypeService.createTareaTipoMantenimiento({"id_tarea": this.taskId, "id_tipo_mantenimiento": e.addedItems[0].id_tipo_mantenimiento}).then(() => {           
            //this.refresh();
          }).catch(() => {
            // TODO
          });
        } else if (e.removedItems.length==1) {
          console.log({"id_tarea": this.taskId, "id_habilidad": e.removedItems[0].id_tipo_mantenimiento});
          this.taskMaintenanceTypeService.deleteTareaTipoMantenimiento(this.taskId, e.removedItems[0].id_tipo_mantenimiento).then(() => {          
            //this.refresh();
          }).catch(() => {
            // TODO
          });
        }
      }
    },
    onSelectionChangedSkill (e) {
      if (!this.loading) {
        if (e.addedItems.length==1) {
          //console.log({"id_tarea": this.taskId, "id_habilidad": e.addedItems[0].id_habilidad});
          this.taskSkillService.createTareaHabilidad({"id_tarea": this.taskId, "id_habilidad": e.addedItems[0].id_habilidad}).then(() => {           
            //this.refresh();
          }).catch(() => {
            // TODO
          });
        } else if (e.removedItems.length==1) {
          // console.log({"id_tarea": this.taskId, "id_habilidad": e.removedItems[0].id_habilidad});
          this.taskSkillService.deleteTareaHabilidad(this.taskId, e.removedItems[0].id_habilidad).then(() => {          
            //this.refresh();
          }).catch(() => {
            // TODO
          });
        }
      }
    },
    async refresh() {      
      await this.taskSkillService.getTareaHabilidades(this.taskId).then((data) => {
        this.dataSourceTaskSkills=data;
        var habilidades = "";
        for (var i = 0; i < data.length; i++) {
          if (habilidades.length==0)
            habilidades = data[i].id_habilidad;
          else
            habilidades = habilidades + "," + data[i].id_habilidad;
        }
        if (String(habilidades).length>0) {
          this.selectedItemKeys = String(habilidades).split(',').map(function(item) {
            return parseInt(item, 10);
          });
        }
        this.loading = false;
      });
    },
    showPopup(popupTask, data, taskId) {
      this.loading = true;      
      this.selectedItemKeys = [];
      this.taskId = taskId;
      this.formData = data;      
      this.popupTask = popupTask;
      this.popupVisible = true;
      this.refresh();
    },
    async refreshMaintenance() {      
      await this.taskMaintenanceTypeService.getTareaTipoMantenimientos(this.taskId).then((data) => {
        this.dataSourceTaskMaintenanceTypes=data;
        var tipomantenimiento = "";
        for (var i = 0; i < data.length; i++) {
          if (tipomantenimiento.length==0)
            tipomantenimiento = data[i].id_tipo_mantenimiento;
          else
            tipomantenimiento = tipomantenimiento + "," + data[i].id_tipo_mantenimiento;
        }
        if (String(tipomantenimiento).length>0) {
          this.selectedItemKeysMaintenance = String(tipomantenimiento).split(',').map(function(item) {
            return parseInt(item, 10);
          });
        }
        this.loading = false;
      });
    },
    showPopupMaintenance(popupTaskMaintenance, data, taskId) {
      this.loading = true;      
      this.selectedItemKeysMaintenance = [];
      this.taskId = taskId;
      this.formDataMaintenance = data;      
      this.popupTaskMaintenance = popupTaskMaintenance;
      this.popupVisibleMaintenanceType = true;
      this.refreshMaintenance();
    },
    editSkill(e) {            
      this.showPopup(e.row.data.nombre, {...e.row.data}, e.row.data.id_tarea);
    },
    editMaintenanceType(e) {
      this.showPopupMaintenance(e.row.data.nombre, {...e.row.data}, e.row.data.id_tarea);
    },
    closeClick() {
        this.popupVisible = false;
    },
    closeClickMaintenance() {
        this.popupVisibleMaintenanceType = false;
    },
    confirmClick() {
        let result = this.form.validate();
        if (result.isValid) {
          /*
            let gridSource = this.gridSource,
                gridStore = this.customerStore;

            if (this.popupMode === "Add")
                gridStore.push([{ type: "insert", data: this.formData }]);
            else if (this.popupMode === "Edit") {
                gridStore.push([{ type: "update", data: this.formData, key: this.formData[key]}]);
            }
            
            gridSource.reload();
            this.popupVisible = false;
            */
        }
    },
    onSelectionChanged({ selectedRowsData }) {
      const data = selectedRowsData[0];
      this.selectedTask = data;
      //this.taskSkillService.getTareaHabilidades().then(data => this.dataSourceSkills=data);

      //this.showEmployeeInfo = !!data;
      //this.selectedRowNotes = data && data.Notes;
      //this.selectedRowPicture = data && data.Picture;

    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editTask(e) {
      this.taskService.updateTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.taskService.getTareas().then(data => this.dataSourceTasks=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al editar la tarea';
        this.isVisible = true;
        this.taskService.getTareas().then(data => this.dataSourceTasks=data);
      });
		},
    deleteTask(e) {
			this.taskService.deleteTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.taskService.getTareas().then(data => this.dataSourceTasks=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar la tarea';
        this.isVisible = true;
        this.taskService.getTareas().then(data => this.dataSourceTasks=data);
      });
		},
    createTask(e) {
			this.taskService.createTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.taskService.getTareas().then(data => this.dataSourceTasks=data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear la tarea';
        this.isVisible = true;
        this.taskService.getTareas().then(data => this.dataSourceTasks=data);
      });
		},
    /*
    onEditorPreparing(e) {
      if (e.dataField === 'id_equipo_padre' && e.row.data.id_equipo === 0) {
        e.editorOptions.disabled = true;
        e.editorOptions.value = null;
      }
    },
    */
    onInitNewRow(e) {      
      e.data.activo = true;
      e.data.unica = false;
      e.data.comun = false;
      e.data.relevante_historial = false;
      //e.data.id_equipo_padre = null;
      //this.taskService.getEquipoPadres().then(data => this.dataSourceFatherTasks=data);
    },
  },  
};
