import axios from 'axios';

export default class TypeRequisitionService {

	getTipoRequisiciones() {			
        const url = process.env.VUE_APP_APIURL + `tiporequisiciones`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoRequisicion(tiporequisicion) {
        const url = process.env.VUE_APP_APIURL + `tiporequisiciones/${tiporequisicion.id_tipo_procedimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoRequisicion(tiporequisicion){
        const url = process.env.VUE_APP_APIURL + `tiporequisiciones/${tiporequisicion.id_tipo_procedimiento}`;
		return axios.put(url,tiporequisicion).then(res=>res.data.data);
	}

	createTipoRequisicion(tiporequisicion){
        const url = process.env.VUE_APP_APIURL + `tiporequisiciones`;
		return axios.post(url,tiporequisicion).then(res=>res.data.data);
	}

	deleteTipoRequisicion(tiporequisicion){
        const url = process.env.VUE_APP_APIURL + `tiporequisiciones/${tiporequisicion.id_tipo_procedimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}