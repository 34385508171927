
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser,
  DxButton,
  DxToolbar
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';

import ConfigChecklistService from '../services/config-checklist-service';
import ChecklistService from '../services/checklist-service';
import StatusChecklistService from '../services/status-checklist-service';
import ClientService from '../services/client-service';
import CompanyService from '../services/company-service';
import UserService from '../services/user-service';
import TypeChecklistService from '../services/type-checklist-service';
import DetailTemplate from './checklist-tab.vue';
import BrandService from '../services/brand-service';
import EquipmentCompanyService from '../services/equipment-company-service';

import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import robotoNormal from '@/assets/fonts/Roboto-Thin-normal.js'
import robotoRegular from '@/assets/fonts/Roboto-Thin-regular.js'


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxButton,
    DxToolbar,
  },
  data() {
    return {
      listaverificacion: null,
      dataSourceConfigChecklists: null,
      dataSourceChecklistChecklists: null,
      dataSourceChecklists: null,
      dataSourceStatus: null,
      dataSourceClients: null,
      dataSourceUsers: null,
      dataSourceCompanies: null,
      dataSourceChecklistTypes: null,
      dataSourceEquipments: null,
      dataSourceBrands: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Checklist',
        showTitle: true,
        width: 600,
      },
      mode: 'select',
      gridRefName: 'dataGridCheckList',
    };
  },
  created() {
    this.configChecklistService = new ConfigChecklistService();
    this.checklistService = new ChecklistService();
    this.statusChecklistService = new StatusChecklistService();
    this.clientService = new ClientService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.typeChecklistService = new TypeChecklistService();
    this.brandService = new BrandService();
    this.equipmentCompanyService = new EquipmentCompanyService();
  },
  mounted() {
    this.brandService.getMarcas().then(data => this.dataSourceBrands = data);

    this.typeChecklistService.getTipoListaVerificaciones().then(data => this.dataSourceChecklistTypes = data);
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.equipmentCompanyService.getEquipoEmpresasCombo().then(data => this.dataSourceEquipments = data);
    this.statusChecklistService.getEstatusListaVerificaciones().then(data => this.dataSourceStatus = data);
    this.checklistService.getListaVerificacionesPorEmpresa(1).then(data => this.dataSourceChecklists = data);
    this.configChecklistService.getConfigListaVerificaciones().then(data => this.dataSourceConfigChecklists = data);
  },
  methods: {
    refreshDataGrid() {
      this.checklistService.getListaVerificacionesPorEmpresa(1).then(data => this.dataSourceChecklists = data);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editChecklist(e) {
      this.checklistService.updateListaVerificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.checklistService.getListaVerificacionesPorEmpresa(1).then(data => this.dataSourceChecklists = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.checklistService.getListaVerificacionesPorEmpresa(1).then(data => this.dataSourceChecklists = data);
      });
    },
    deleteChecklist(e) {
      this.checklistService.deleteListaVerificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.checklistService.getListaVerificacionesPorEmpresa(1).then(data => this.dataSourceChecklists = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.checklistService.getListaVerificacionesPorEmpresa(1).then(data => this.dataSourceChecklists = data);
      });
    },
    createChecklist(e) {
      this.checklistService.createListaVerificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.checklistService.getListaVerificacionesPorEmpresa(1).then(data => this.dataSourceChecklists = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.checklistService.getListaVerificacionesPorEmpresa(1).then(data => this.dataSourceChecklists = data);
      });
    },
    onInitNewRow(e) {
      e.data.id_estatus_lista_verificacion = 1;
    },

    createpdf(e) {
      // var imgUrl = require("../assets/11.png");
      this.checklistService
        .getListaVerificacionPDF(e.row.data.id_lista_verificacion).then((dataReporte) => {
          const doc = new jsPDF('portrait', 'mm', 'a4');
          // var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
          // var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

          doc.addFileToVFS('Roboto-normal1.ttf', robotoNormal)
          doc.addFileToVFS('Roboto-regular.ttf', robotoRegular)
          doc.setFont("RobotoRegular");

          doc.setDrawColor("35B729");
          doc.setLineWidth(0);
          doc.setFillColor("35B729");
          doc.triangle(200, 0, 20, 0, 120, 10, 'FD');


          doc.setDrawColor("F80202");
          doc.setLineWidth(0);
          doc.setFillColor("F80202");
          doc.triangle(0, 0, 0, 3, 80, 10, 'FD');
          doc.triangle(140, 0, 0, 0, 80, 10, 'FD');

          doc.setDrawColor("004EFF");
          doc.setLineWidth(0);
          doc.setFillColor("004EFF");
          doc.triangle(120, 0, 20, 0, 60, 5, 'FD');

          var imgUrl = dataReporte['empresa_logotipo'];
          var img = new Image;
          img.src = imgUrl;
          // img.src = imgUrl;

          var date = new Date();
          var fechaactual = date.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

          doc.text("Checklist " + dataReporte["folio"] ?? "", 105, 18, { align: 'center' });

          doc.setFontSize(8);
          doc.text(`Fecha: ${fechaactual}`, 155, 18, { align: "left" });

          doc.addImage(img, 5, 5, 50, 20);
          doc.setFontSize(9);

          // doc.setFillColor("E2F7DA");
          // doc.rect(5, 30, 200, 28, 'F');

          doc.setFont("RobotoNormal");
          doc.text("Empresa: ", 8, 35, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["empresa_clave"] ?? "", 22, 35, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Cliente: ", 8, 40, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["cliente_clave"] ?? "", 20, 40, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Tipo: ", 70, 35, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["tipo_lista_verificacion"] ?? "", 78, 35, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Realizado el: ", 140, 35, { align: "left" });

          var datefechafalla = new Date(dataReporte["fecha"]);
          var fechafalla = datefechafalla.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

          doc.setFont("RobotoRegular");
          doc.text(fechafalla, 158, 35, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Responsable: ", 70, 40, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["usuario_responsable"] ?? "", 90, 40, { align: "left" });

          doc.setFont("RobotoNormal");
          doc.text("Estatus: ", 140, 40, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["estatus_lista_verificacion"] ?? "", 152, 40, { align: "left" });

          var value = 50;
          if (dataReporte["id_equipo_empresa"] != null) {
            doc.setFont("RobotoRegular");
            doc.setFontSize(12);
            doc.text("Información del equipo", 8, value, { align: "left" });
            value += 5;
            doc.setFontSize(10);
            doc.text(dataReporte["equipo_empresa_nombre"] ?? "", 8, value, { align: "left" });
            doc.setFontSize(9);

            doc.setFont("RobotoNormal");
            doc.text("Parte de: ", 140, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_padre_nombre"] ?? "", 153, value, { align: "left" });

            value += 5;

            doc.setFont("RobotoNormal");
            doc.text("Marca: ", 8, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["marca"] ?? "", 18, value, { align: "left" });

            doc.setFont("RobotoNormal");
            doc.text("Modelo: ", 70, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_modelo"] ?? "", 82, value, { align: "left" });

            doc.setFont("RobotoNormal");
            doc.text("No. Serie: ", 140, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_serie"] ?? "", 154, value, { align: "left" });

            value += 5;

            doc.setFont("RobotoNormal");
            doc.text("No. Activo: ", 8, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_numero_activo"] ?? "", 24, value, { align: "left" });

            doc.setFont("RobotoNormal");
            doc.text("Referencia: ", 70, value, { align: "left" });

            doc.setFont("RobotoRegular");
            doc.text(dataReporte["equipo_empresa_numero_referencia"] ?? "", 86, value, { align: "left" });

            value += 10;
          }

          doc.setFont("RobotoNormal");
          doc.setFontSize(12);
          doc.text("Asunto: ", 8, value, { align: "left" });

          doc.setFont("RobotoRegular");
          doc.text(dataReporte["nombre"] ?? "", 24, value, { align: "left" });

          value += 10;

          doc.setFont("RobotoNormal");
          doc.text("Comentarios: ", 8, value, { align: "left" });

          value += 5;

          doc.setFont("RobotoRegular");
          doc.setFontSize(9);
          let desfalla = ((dataReporte["comentarios"] ?? ""));
          let splitText = doc.splitTextToSize(desfalla, 188);
          doc.text(splitText, 8, value, { align: "left" });

          value += 5;

          value += (splitText.length * 3.1);


          var columnsTareaMedicion = [
            { title: "Medición", dataKey: "medicion" },
            { title: "Valor", dataKey: "valor" },
            { title: "UM", dataKey: "unidad_medida" },
            { title: "Fecha de Registro", dataKey: "fecha" },
            { title: "Capturado Por", dataKey: "iniciales" },
          ];

          var mediciones = dataReporte["listaverificaciondetalle"];
          mediciones.forEach(element => {
            var fechaformat = new Date(element.fecha);
            var fechamedicion = fechaformat.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });
            element.fecha = fechamedicion;
            if (element.mostrar_um == 0) {
              element.unidad_medida = "";
            }
          });

          let coordenada = 0;

          doc.autoTable(columnsTareaMedicion, mediciones, {
            rowPageBreak: 'avoid',
            pageBreak: 'auto',
            finalY: value,
            startY: (value),
            styles: {
              fontSize: 9,
              font: 'RobotoNormal'
            },
            margin: {
              left: 8,
              right: 8
            },
            headerStyles: {
              font: 'RobotoRegular'
            },
          });

          if (doc.autoTable.previous) {
            coordenada = Number(doc.autoTable.previous.finalY.toFixed());
          }
          value = + (coordenada + 8);




          var dataimgevidencia = dataReporte["listaverificacionevidencia"];

          if (dataimgevidencia.length > 0) {
            doc.setFont("RobotoNormal");
            doc.setFontSize(12);
            doc.text("Evidencias ", 8, value, { align: "left" });
          }

          let imgposicion = value + 5;
          let textToSizeDes = 0;

          var evidenciasimg = dataimgevidencia.filter(ele => ele.id_tipo_archivo === 6);

          evidenciasimg.forEach((imgEvidencia, index) => {
            var img = new Image;

            var datefechaimg = new Date(imgEvidencia["fecha_reg"]);
            var fechafallasimg = datefechaimg.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

            img.src = (imgEvidencia["archivo"] ?? "");
            // img.src = imgUrl; //Dev Local

            index += 1;

            if (imgposicion >= 200) {
              doc.addPage();
              imgposicion = 15; // Restart height position
            }

            if ((index % 3) == 1) {
              textToSizeDes = 0;
              doc.setFontSize(9);
              doc.addImage(img, 8, imgposicion, 50, 50);
              let desfalla = ((imgEvidencia["descripcion"] ?? ""));
              let splitText = doc.splitTextToSize(desfalla, 60);
              doc.text(splitText, 8, imgposicion + 55, { align: "left" });
              let textsize = (splitText.length * 3.1);
              textToSizeDes = textsize;
              doc.text(fechafallasimg, 8, imgposicion + (60 + textsize), { align: "left" });
              doc.text((imgEvidencia["iniciales"] ?? ""), 53, imgposicion + (60 + textsize), { align: "left" });

            }

            if ((index % 3) == 2) {
              doc.setFontSize(9);
              doc.addImage(img, 80, imgposicion, 50, 50);
              let desfalla = ((imgEvidencia["descripcion"] ?? ""));
              let splitText = doc.splitTextToSize(desfalla, 60);
              doc.text(splitText, 80, imgposicion + 55, { align: "left" });

              let textsize = (splitText.length * 3.1);
              if (textsize > textToSizeDes) {
                textToSizeDes = textsize;
              }
              doc.text(fechafallasimg, 80, imgposicion + (60 + textsize), { align: "left" });
              doc.text((imgEvidencia["iniciales"] ?? ""), 125, imgposicion + (60 + textsize), { align: "left" });

            }

            if ((index % 3) == 0) {
              doc.setFontSize(9);
              doc.addImage(img, 150, imgposicion, 50, 50);
              let desfalla = ((imgEvidencia["descripcion"] ?? ""));
              let splitText = doc.splitTextToSize(desfalla, 58);
              doc.text(splitText, 150, imgposicion + 55, { align: "left" });

              let textsize = (splitText.length * 3.1);
              if (textsize > textToSizeDes) {
                textToSizeDes = textsize;
              }
              doc.text(fechafallasimg, 150, imgposicion + (60 + textsize), { align: "left" });
              doc.text((imgEvidencia["iniciales"] ?? ""), 195, imgposicion + (60 + textsize), { align: "left" });

              imgposicion += (70 + textToSizeDes);
            }

          });
          value = imgposicion + 65 + textToSizeDes;

          value += 10;

          if ((dataReporte["firma"] ?? "").length > 0) {
            var imgfirma = new Image;
            doc.setFont("RobotoNormal");
            doc.setFontSize(12);
            doc.text("Firma ", 8, value, { align: "left" });
            imgfirma.src = (dataReporte["firma"] ?? "");

            // imgfirma.src = imgUrl;
            value += 5;
            doc.addImage(imgfirma, 8, value, 50, 50);
          }

          //Dev
          window.open(
            URL.createObjectURL(doc.output("blob")),
            "_blank",
            "scrollbars=yes,location=yes"
          );
          setTimeout(() => {
            window.URL.revokeObjectURL(doc.output("bloburl"));
          }, 100);
        });
    }
  },
};
