import axios from 'axios';

export default class GroupAlertService {

	getGrupoAlertas() {	
        const url = process.env.VUE_APP_APIURL + `grupoalertas`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoAlerta(grupoalerta) {
        const url = process.env.VUE_APP_APIURL + `/grupoalertas/${grupoalerta.id_grupo_alerta}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoAlerta(grupoalerta) {
        const url = process.env.VUE_APP_APIURL + `/grupoalertas/${grupoalerta.id_grupo_alerta}`;
		return axios.put(url,grupoalerta)
		.then(res=>res.data.data);
	}

	createGrupoAlerta(grupoalerta){
        const url = process.env.VUE_APP_APIURL + `/grupoalertas`;
		return axios.post(url,grupoalerta).then(res=>res.data.data);
	}

	deleteGrupoAlerta(grupoalerta){
        const url = process.env.VUE_APP_APIURL + `/grupoalertas/${grupoalerta.id_grupo_alerta}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}