import axios from 'axios';

export default class TypeMajorMinorService {

	getTipoMayorMenores() {			
        const url = process.env.VUE_APP_APIURL + `tipomayormenores`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoMayorMenor(tipomayormenor) {
        const url = process.env.VUE_APP_APIURL + `/tipomayormenores/${tipomayormenor.id_tipo_mayor_menor}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoMayorMenor(tipomayormenor){
        const url = process.env.VUE_APP_APIURL + `/tipomayormenores/${tipomayormenor.id_tipo_mayor_menor}`;
		return axios.put(url,tipomayormenor).then(res=>res.data.data);
	}

	createTipoMayorMenor(tipomayormenor){
        const url = process.env.VUE_APP_APIURL + `/tipomayormenores`;
		return axios.post(url,tipomayormenor).then(res=>res.data.data);
	}

	deleteTipoMayorMenor(tipomayormenor){
        const url = process.env.VUE_APP_APIURL + `/tipomayormenores/${tipomayormenor.id_tipo_mayor_menor}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}