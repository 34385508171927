
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import SynonymousService from '../services/synonymous-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceSynonymous: null,
      sinonimo: {},
      events: [],
      synonymousService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.synonymousService = new SynonymousService();
  },
  mounted() {    
    this.synonymousService.getSinonimos().then(data => this.dataSourceSynonymous=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editSynonymous(e) {
			this.synonymousService.updateSinonimo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteSynonymous(e) {
			this.synonymousService.deleteSinonimo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createSynonymous(e) {
			this.synonymousService.createSinonimo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
