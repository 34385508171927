import axios from "axios";

export default class ActivityEventService {
  getActividadesEventos() {
    const url = process.env.VUE_APP_APIURL + `actividadeventos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getActividadEvento(actividadevento) {
    const url =
      process.env.VUE_APP_APIURL +
      `/actividadeventos/${actividadevento.id_actividad_evento}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateActividadEventos(actividadevento) {
    const url =
      process.env.VUE_APP_APIURL +
      `/actividadeventos/${actividadevento.id_actividad_evento}`;
    return axios.put(url, actividadevento).then((res) => res.data.data);
  }

  createActividadEventos(actividadevento) {
    const url = process.env.VUE_APP_APIURL + `/actividadeventos`;
    return axios.post(url, actividadevento).then((res) => res.data.data);
  }

  deleteActividadEventos(actividadevento) {
    const url =
      process.env.VUE_APP_APIURL +
      `/actividadeventos/${actividadevento.id_actividad_evento}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getActividadEventoPorActividad(actividadevento) {
    const url =
      process.env.VUE_APP_APIURL +
      `/actividadeventos/actividad/${actividadevento}`;
    return axios.get(url).then((res) => res.data.data);
  }
}
