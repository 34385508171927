import axios from "axios";

export default class SendMailService {
  sendMailServiceReport(reporteservicio) {
    const url = process.env.VUE_APP_APIURL + `/reporteservicio`;

    return axios
      .post(url, reporteservicio)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
