import axios from 'axios';

export default class ConfigChecklistMeasurementService {

	getConfigListaVerificacionMediciones() {			
        const url = process.env.VUE_APP_APIURL + `configlistaverificaciondetalles`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getConfigListaVerificacionMedicionesWeb() {			
        const url = process.env.VUE_APP_APIURL + `configlistaverificaciondetalles/web`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getConfigListaVerificacionMedicion(configlistaverificacionmedicion) {
        const url = process.env.VUE_APP_APIURL + `/configlistaverificaciondetalles/${configlistaverificacionmedicion.id_config_lista_verificacion_detalle}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateConfigListaVerificacionMedicion(configlistaverificacionmedicion){
        const url = process.env.VUE_APP_APIURL + `/configlistaverificaciondetalles/${configlistaverificacionmedicion.id_config_lista_verificacion_detalle}`;
		return axios.put(url,configlistaverificacionmedicion).then(res=>res.data.data);
	}

	createConfigListaVerificacionMedicion(configlistaverificacionmedicion){
        const url = process.env.VUE_APP_APIURL + `/configlistaverificaciondetalles`;
		return axios.post(url,configlistaverificacionmedicion).then(res=>res.data.data);
	}

	deleteConfigListaVerificacionMedicion(configlistaverificacionmedicion){
        const url = process.env.VUE_APP_APIURL + `/configlistaverificaciondetalles/${configlistaverificacionmedicion.id_config_lista_verificacion_detalle}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}