
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxSelection,
  DxSorting,
  DxForm,
  DxItem,
  DxValidationRule,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
// Catalogos
import GroupItemService from '../services/group-item-service';
import TypeItemervice from '../services/type-item-service';
import BrandService from '../services/brand-service';
import ItemService from '../services/item-service';
import WorkOrderEquipmentTaskItemService from '../services/workorder-equipment-task-item-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule,
  },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    workOrderTaskId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ordentrabajoarticulos: null,
      dataSourceWorkOrderItems: null,
      dataSourceItems: null,
      dataSourceItemGroups: null,
      dataSourceBrands: null,
      dataSourceItemTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      popupOptions: {
        title: 'Articulo',
        showTitle: true,
        width: 600,
      },
    };
  },
  created() {
    this.typeItemervice = new TypeItemervice();
    this.brandService = new BrandService();
    this.itemService = new ItemService();
    this.groupItemService = new GroupItemService();
    this.workOrderEquipmentTaskItemService = new WorkOrderEquipmentTaskItemService();
  },
  mounted() {
    this.typeItemervice.getTipoArticulos().then(data => this.dataSourceItemTypes=data);
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
    this.itemService.getArticulos().then(data => this.dataSourceItems=data);
    this.groupItemService.getGrupoArticulos().then(data => this.dataSourceItemGroups=data);
    //this.workOrderEquipmentTaskItemService.getArticulosComboPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceItems=data);
    this.workOrderEquipmentTaskItemService.getArticulosPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceWorkOrderItems=data);
  },

  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    deleteWorkOrderItem(e) {
        this.workOrderEquipmentTaskItemService.deleteOrdenTrabajoArticulo(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.workOrderEquipmentTaskItemService.getArticulosPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceWorkOrderItems=data);
            //this.workOrderEquipmentTaskItemService.getArticulosComboPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceItems=data);
        });
    },
    createWorkOrderItem(e) {
      this.workOrderEquipmentTaskItemService.createOrdenTrabajoArticulo({"id_orden_trabajo_tarea": this.workOrderTaskId, "id_articulo": e.data.id_articulo,
      "cantidad": e.data.cantidad, "reporte_servicio": e.data.reporte_servicio}).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;

        this.workOrderEquipmentTaskItemService.getArticulosPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceWorkOrderItems=data);
        //this.workOrderEquipmentTaskItemService.getArticulosComboPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceItems=data);
      });
    },
    editWorkOrderItem(e) {
      this.workOrderEquipmentTaskItemService.updateOrdenTrabajoArticulo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;

        this.workOrderEquipmentTaskItemService.getArticulosPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceWorkOrderItems=data);
        //this.workOrderEquipmentTaskItemService.getArticulosComboPorOrdenTrabajoTarea(this.workOrderTaskId).then(data => this.dataSourceItems=data);
      });
    },
    onInitNewRow(e) {
      e.data.reporte_servicio = true;
      e.data.cantidad = 1;
    },
  },
};
