import axios from 'axios';

export default class GroupKpiService {

	getGrupoKpis() {	
		const url = process.env.VUE_APP_APIURL + `grupokpis`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getGrupoKpi(grupokpi) {
		const url = process.env.VUE_APP_APIURL + `/grupokpis/${grupokpi.id_grupo_kpi}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateGrupoKpi(grupokpi) {
		const url = process.env.VUE_APP_APIURL + `/grupokpis/${grupokpi.id_grupo_kpi}`;
		return axios.put(url,grupokpi)
		.then(res=>res.data.data);
	}

	createGrupoKpi(grupokpi){
        const url = process.env.VUE_APP_APIURL + `/grupokpis`;
		return axios.post(url,grupokpi).then(res=>res.data.data);
	}

	deleteGrupoKpi(grupokpi){
		const url = process.env.VUE_APP_APIURL + `/grupokpis/${grupokpi.id_grupo_kpi}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}