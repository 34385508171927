
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxSelection,
  DxSorting,
  DxForm,
  DxItem,
  DxValidationRule,
  DxMasterDetail,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
// Catalogos
import UserService from '../services/user-service';
import TaskService from '../services/task-service';
import StatusTaskService from '../services/status-task-service';
// TreeList
import WorkOrderEquipmentTaskService from '../services/workorder-equipment-task-service';
import WorkOrderMasterDetail from './workorder-equipment-task-tab.vue'

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule,
    DxMasterDetail,
    WorkOrderMasterDetail,
  },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    workOrderEquipmentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    //const { id_orden_trabajo_equipo } = this.templateData.data;
    return {
      ordentrabajotareas: null,
      dataSourceWorkOrderTasks: null,
      dataSourceStatus: null,
      dataSourceUsers: null,
      dataSourceFatherTasks: null,
      dataSourceTasks: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      popupOptions: {
        title: 'Tarea',
        showTitle: true,
        width: 800,
      },
    };
  },
  created() {
    this.workOrderEquipmentTaskService = new WorkOrderEquipmentTaskService();
    this.userService = new UserService();
    this.taskService = new TaskService();
    this.statusTaskService = new StatusTaskService();
  },
  mounted() {
    this.workOrderEquipmentTaskService.getTareasPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceWorkOrderTasks=data);
    this.userService.getUsuariosPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceUsers=data);
    this.taskService.getTareas().then((data) => {this.dataSourceTasks=data; this.dataSourceFatherEquipment=data;});
    this.statusTaskService.getEstatusTareas().then(data => this.dataSourceStatus=data);
  },

  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    deleteWorkOrderEquipmentTask(e) {
        this.workOrderEquipmentTaskService.deleteOrdenTrabajoTarea(e.data).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.workOrderEquipmentTaskService.getTareasPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceWorkOrderTasks=data);
        });
    },
    createWorkOrderEquipmentTask(e) {
    
      this.workOrderEquipmentTaskService.createOrdenTrabajoTarea({"id_orden_trabajo_equipo": this.workOrderEquipmentId, "id_tarea": e.data.id_tarea,
       "id_estatus_tarea":e.data.id_estatus_tarea, "orden": e.data.orden, "id_usuario_responsable": e.data.id_usuario_responsable}).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.workOrderEquipmentTaskService.getTareasPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceWorkOrderTasks=data);
        this.taskService.getTareas().then((data) => {this.dataSourceTasks=data; this.dataSourceFatherEquipment=data;});
      });
    },
    editWorkOrderEquipmentTask(e) {
    
      this.workOrderEquipmentTaskService.updateOrdenTrabajoTarea(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.workOrderEquipmentTaskService.getTareasPorOrdenTrabajoEquipo(this.workOrderEquipmentId).then(data => this.dataSourceWorkOrderTasks=data);
        this.taskService.getTareas().then((data) => {this.dataSourceTasks=data; this.dataSourceFatherEquipment=data;});
      });
    },
    onInitNewRow(e) {
      e.data.id_estatus_tarea = 1;
      e.data.reporte_servicio = true;
      e.data.orden = 1;
    },
  },
};
