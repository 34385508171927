
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxEditing,
  DxSorting
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import CompanyService from '../services/company-service';
import CompanyClientService from '../services/company-client-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting
  },
  data() {
    return {
      company: null,      
      dataSourceCompanies: null,
      dataSourceClientAvailable: null,
      dataSourceClientAssigned: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      i: 0,
    };
  },
  created() {
    this.companyService = new CompanyService();
    this.companyClientService = new CompanyClientService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);  
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_cliente);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addClient() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.companyClientService.createEmpresaCliente({"id_empresa": this.company.id_empresa, "id_cliente": this.selectedRowsData[this.i].id_cliente}).then(() => {           
            
            if (this.i==this.selectedRowsData.length) {
              
              this.companyClientService.getClientesDisponiblesPorEmpresa(this.company.id_empresa).then(data => this.dataSourceClientAvailable=data);
              this.companyClientService.getClientesAsignadosPorEmpresa(this.company.id_empresa).then(data => this.dataSourceClientAssigned=data);
            }
            
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    valueChangedCompany(e) {
        this.companyClientService.getClientesDisponiblesPorEmpresa(e.value.id_empresa).then(data => this.dataSourceClientAvailable=data);
        this.companyClientService.getClientesAsignadosPorEmpresa(e.value.id_empresa).then(data => this.dataSourceClientAssigned=data);           
    },
    deleteClient(e) {
      this.companyClientService.deleteEmpresaCliente(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.companyClientService.getClientesDisponiblesPorEmpresa(this.company.id_empresa).then(data => this.dataSourceClientAvailable=data);
        this.companyClientService.getClientesAsignadosPorEmpresa(this.company.id_empresa).then(data => this.dataSourceClientAssigned=data); 
      });
    },
  },  
};
