
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser
} from 'devextreme-vue/data-grid';
import { DxItem, DxEmptyItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import ProcedureService from '../services/procedure-service';
import TypeProcedureService from '../services/type-procedure-service';
import TypeFileService from '../services/type-file-service';
import DetailTemplate from './procedure-detail.vue';


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxEmptyItem
  },
  data() {
    return {
      procedimientos: null,
      dataSourceProcedures: null,
      dataSourceProcedureTypes: null,
      dataSourceFileTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Procedimiento',
        showTitle: true,
        width: 800,
        height: 500,
      },
      mode: 'select',
    };
  },
  created() {
    this.procedureService = new ProcedureService();
    this.typeProcedureService = new TypeProcedureService();
    this.typeFileService = new TypeFileService();    
  },
  mounted() {    
    this.procedureService.getProcedimientos().then(data => this.dataSourceProcedures=data);
    this.typeProcedureService.getTipoProcedimientos().then(data => this.dataSourceProcedureTypes=data);
    this.typeFileService.getTipoArchivos().then(data => this.dataSourceFileTypes=data);       
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editProcedure(e) {
      this.procedureService.updateProcedimiento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
		},
    deleteProcedure(e) {
			this.procedureService.deleteProcedimiento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createProcedure(e) {
			this.procedureService.createProcedimiento(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},    
    onInitNewRow(e) {      
      e.data.activo = true;      
    },
  },
};
