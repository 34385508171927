import axios from 'axios';

export default class StatusServiceRequestService {

	getEstatusSolicitudServicios() {			
        const url = process.env.VUE_APP_APIURL + `estatussolicitudservicios`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEstatusSolicitudServicio(estatussolicitudservicio) {
        const url = process.env.VUE_APP_APIURL + `/estatussolicitudservicios/${estatussolicitudservicio.id_estatus_solicitud_servicio}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEstatusSolicitudServicio(estatussolicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/estatussolicitudservicios/${estatussolicitudservicio.id_estatus_solicitud_servicio}`;
		return axios.put(url,estatussolicitudservicio).then(res=>res.data.data);
	}

	createEstatusSolicitudServicio(estatussolicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/estatussolicitudservicios`;
		return axios.post(url,estatussolicitudservicio).then(res=>res.data.data);
	}

	deleteEstatusSolicitudServicio(estatussolicitudservicio){
        const url = process.env.VUE_APP_APIURL + `/estatussolicitudservicios/${estatussolicitudservicio.id_estatus_solicitud_servicio}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}