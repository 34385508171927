import axios from 'axios';

export default class RequisitionServiceService {

  createRequisicionServicio(requisicion) {
    const url = process.env.VUE_APP_APIURL + `requisicionesservicios`;
    return axios.post(url, requisicion).then((res) => res.data.data);
  }

  getRequisicionServicioID(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/requisicionesservicios/requisicion`;
    return axios
      .get(url, { params: requisicion })
      .then((res) => res.data.data);
  }

  updateRequisicionServicio(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/requisicionesservicios/${requisicion.id_requisicion_articulo}`;
    return axios.put(url, requisicion).then((res) => res.data.data);
  }

  deleteRequisicionServicio(requisicion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/requisicionesservicios/${requisicion.id_requisicion_articulo}`;
    return axios.delete(url).then((res) => res.data.data);
  }

  getRequisicionServicioPorUsuario(id_requisicion) {
    const url =
      process.env.VUE_APP_APIURL + `requisicionesservicios/requisicion`;
    return axios
      .get(url, { params: { id_requisicion: id_requisicion } })
      .then((res) => res.data.data);
  }

}