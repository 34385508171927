
import {
  DxDataGrid, DxColumn, DxLookup, DxEditing, DxPaging, DxValidationRule, DxFilterRow, DxSelection, DxSearchPanel, DxSorting, DxScrolling, DxPager,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import ServiceService from '../services/service-service';
import CurrencyService from '../services/currency-service';
import GroupActionService from '../services/group-action-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxValidationRule,
    DxFilterRow,
    DxSelection,
    DxSearchPanel,
    DxSorting,
    DxScrolling,
    DxPager,
    DxLookup,
  },
  data() {
    return {      
      dataSourceServices: null,
      dataSourceCurrencies: null,
      dataSourceUnitOfMeasurements: null,
      dataSourceActionGroups: null,
      servicio: {},
      events: [],
      serviceService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
    };
  },
  created() {
    this.serviceService = new ServiceService();
    this.currencyService = new CurrencyService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.groupActionService = new GroupActionService();
  },
  mounted() {
    this.currencyService.getMonedas().then((data) => {
      this.dataSourceCurrencies=data;
      this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitOfMeasurements=data);
      this.serviceService.getServicios().then(dataSev => this.dataSourceServices=dataSev);
      this.groupActionService.getGrupoAcciones().then(data => this.dataSourceActionGroups=data);
    });
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editService(e) {
			this.serviceService.updateServicio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteService(e) {
			this.serviceService.deleteServicio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createService(e) {
			this.serviceService.createServicio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
