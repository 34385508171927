
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxSearchPanel,  
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
// Combos
import ConfigPlanMaintenanceService from '../services/config-plan-maintenance-service';
import ConfigPlanMaintenanceEquipmentService from '../services/config-plan-maintenance-equipment-service';
import ConfigPlanMaintenanceTaskService from '../services/config-plan-maintenance-task-service';
// Catalogos
import GroupMeasurementService from '../services/group-measurement-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import TypeDataService from '../services/type-data-service';
// TreeList
import ConfigPlanMaintenanceTaskMeasurementService from '../services/config-plan-maintenance-task-measurement-service';

export default {
  components: {
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxSelectBox,
    DxSelection,
    DxSorting,
    Button,
    DxDataGrid
  },
  data() {
    return {
      dataSourceEquipments: null,
      dataSourceMaintenancePlan: null,
      dataSourceMaintenancePlanEquipments: null,
      dataSourceMaintenancePlanTasks: null,
      dataSourceMaintenancePlanAssigned: null,
      dataSourceMaintenancePlanAvailable: null,
      dataSourceMeasurementGroups: null,
      dataSourceUnitMeasurements: null,
      dataSourceDataTypes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      maintenanceplan: null,
      configplanmaintenanceequipment: null,
      maintenanceplantask: null,
      selectedRowsData: [],
    };
  },
  created() {
    this.configPlanMaintenanceService = new ConfigPlanMaintenanceService();
    this.configPlanMaintenanceEquipmentService = new ConfigPlanMaintenanceEquipmentService();
    this.configPlanMaintenanceTaskService = new ConfigPlanMaintenanceTaskService();
    
    this.groupMeasurementService = new GroupMeasurementService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.typeDataService = new TypeDataService();
    
    this.configPlanMaintenanceTaskMeasurementService = new ConfigPlanMaintenanceTaskMeasurementService();
  },
  mounted() {
    this.configPlanMaintenanceService.getConfigPlanMttos().then(data => this.dataSourceMaintenancePlan=data);
    this.groupMeasurementService.getGrupoMediciones().then(data => this.dataSourceMeasurementGroups=data);
    this.unitOfMeasurementService.getUnidadMedidas().then(data => this.dataSourceUnitMeasurements=data);
    this.typeDataService.getTipoDatos().then(data => this.dataSourceDataTypes=data);
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_medicion);
    },
  },
  methods: {
    addMeasurement() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.configPlanMaintenanceTaskMeasurementService.createConfigPlanMttoTareaMedicion({"id_config_plan_mtto_tarea": this.maintenanceplantask.id_config_plan_mtto_tarea, "id_medicion": this.selectedRowsData[this.i].id_medicion}).then(() => {           
            if (this.i>=this.selectedRowsData.length) {
                this.configPlanMaintenanceTaskMeasurementService.getMedicionesDisponiblesPorPlanTarea(this.maintenanceplantask.id_config_plan_mtto_tarea).then(data => this.dataSourceMaintenancePlanAvailable=data);
                this.configPlanMaintenanceTaskMeasurementService.getMedicionesAsignadasPorPlanTarea(this.maintenanceplantask.id_config_plan_mtto_tarea).then(data => this.dataSourceMaintenancePlanAssigned=data);
            }
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRowsData = selectedRowsData;
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'id_grupo_medicion' || e.dataField === 'id_medicion' || e.dataField === 'clave' || 
      e.dataField === 'nombre' || e.dataField === 'descripcion' || e.dataField === 'id_unidad_medida' || e.dataField === 'id_tipo_dato'))  {
        e.editorOptions.disabled = true;
      }
    },
    valueChangedMaintenancePlan(e) {
      this.configPlanMaintenanceEquipmentService.getEquiposAsignadosPorPlan(e.value.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipments=data);
      this.dataSourceMaintenancePlanAvailable = null;
      this.dataSourceMaintenancePlanAssigned = null;
    },
    valueChangedEquipment(e) {
      this.configPlanMaintenanceTaskService.getTareasAsignadasPorPlanEquipo(e.value.id_config_plan_mtto_equipo).then(data => this.dataSourceMaintenancePlanTasks=data);
      this.dataSourceMaintenancePlanAvailable = null;
      this.dataSourceMaintenancePlanAssigned = null;
    },
    valueChangedMaintenancePlanTask(e) {
      this.configPlanMaintenanceTaskMeasurementService.getMedicionesDisponiblesPorPlanTarea(e.value.id_config_plan_mtto_tarea).then(data => this.dataSourceMaintenancePlanAvailable=data);
      this.configPlanMaintenanceTaskMeasurementService.getMedicionesAsignadasPorPlanTarea(e.value.id_config_plan_mtto_tarea).then(data => this.dataSourceMaintenancePlanAssigned=data);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConfigMeasurement(e) {
        this.configPlanMaintenanceTaskMeasurementService.updateConfigPlanMttoTareaMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.configPlanMaintenanceTaskMeasurementService.getMedicionesAsignadasPorPlanTarea(this.maintenanceplantask.id_config_plan_mtto_tarea).then(data => this.dataSourceMaintenancePlanAssigned=data);
      });
    },
    deleteConfigMeasurement(e) {
        this.configPlanMaintenanceTaskMeasurementService.deleteConfigPlanMttoTareaMedicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.configPlanMaintenanceTaskMeasurementService.getMedicionesDisponiblesPorPlanTarea(this.maintenanceplantask.id_config_plan_mtto_tarea).then(data => this.dataSourceMaintenancePlanAvailable=data);
        this.configPlanMaintenanceTaskMeasurementService.getMedicionesAsignadasPorPlanTarea(this.maintenanceplantask.id_config_plan_mtto_tarea).then(data => this.dataSourceMaintenancePlanAssigned=data);
      });
    },
  },
};
