
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import FindingLevelService from '../services/finding-level-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceFindingLevel: null,
      events: [],
      jobTitleService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.findingLevelService = new FindingLevelService();
  },
  mounted() {    
    this.findingLevelService.getNivelHallazgos().then(data => this.dataSourceFindingLevel=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editFindingLevel(e) {
			this.findingLevelService.updateNivelHallazgo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteFindingLevel(e) {
			this.findingLevelService.deleteNivelHallazgo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createFindingLevel(e) {
			this.findingLevelService.createNivelHallazgo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
