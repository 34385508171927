import axios from "axios";

export default class EquipmentCompanyService {
  getEquipoEmpresas() {
    const url = process.env.VUE_APP_APIURL + `equipoempresas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquipoEmpresasEmpresaClientesPorEquipo(empresa, cliente) {
    const url =
      process.env.VUE_APP_APIURL + `equipoempresas/empresaclientes/equipos`;
    if (cliente != null)
      return axios
        .get(url, { params: { id_empresa: empresa, id_cliente: cliente } })
        .then((res) => res.data.data);
    else
      return axios
        .get(url, { params: { id_empresa: empresa } })
        .then((res) => res.data.data);
  }

  getEquipoEmpresasEmpresaClientesUbicacionPorEquipo(
    empresa,
    cliente,
    ubicacion
  ) {
    const url =
      process.env.VUE_APP_APIURL +
      `equipoempresas/empresaclienteslocalizacion/equipos`;

    return axios
      .get(url, {
        params: {
          id_empresa: empresa,
          id_cliente: cliente,
          id_localizacion: ubicacion,
        },
      })
      .then((res) => res.data.data);
  }

  getEquipoEmpresasEmpresaClientesPorEquipoGenerico() {
    const url =
      process.env.VUE_APP_APIURL +
      `equipoempresas/empresaclientes/equipogenericos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquipoEmpresasEmpresaClientesPorArmado() {
    const url =
      process.env.VUE_APP_APIURL + `equipoempresas/empresaclientes/armados`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquipoEmpresasEmpresaClientesPorPlantilla(empresa, cliente, plantilla) {
    const url =
      process.env.VUE_APP_APIURL + `equipoempresas/empresaclientes/plantillas`;
    if (cliente != null)
      return axios
        .get(url, {
          params: {
            id_empresa: empresa,
            id_cliente: cliente,
            id_config_plan_mtto: plantilla,
          },
        })
        .then((res) => res.data.data);
    else
      return axios
        .get(url, {
          params: { id_empresa: empresa, id_config_plan_mtto: plantilla },
        })
        .then((res) => res.data.data);
  }

  getEquipoEmpresasEmpresaClientesPorPolizaMtto(empresa, cliente, poliza) {
    const url =
      process.env.VUE_APP_APIURL + `equipoempresas/empresaclientes/polizas`;
    if (cliente != null)
      return axios
        .get(url, {
          params: {
            id_empresa: empresa,
            id_cliente: cliente,
            id_poliza_mantenimiento: poliza,
          },
        })
        .then((res) => res.data.data);
    else
      return axios
        .get(url, {
          params: { id_empresa: empresa, id_poliza_mantenimiento: poliza },
        })
        .then((res) => res.data.data);
  }

  getEquipoEmpresasPadres() {
    const url = process.env.VUE_APP_APIURL + `equipoempresas/padres`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquipoEmpresasConsultas() {
    const url = process.env.VUE_APP_APIURL + `equipoempresas/consultas`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquipoEmpresasEvidencias(idModuloLiga, equiposEmpresas) {
    const url = process.env.VUE_APP_APIURL + `equipoempresas/evidenciasweb`;
    return axios
      .get(url, {
        params: {
          modulo: idModuloLiga,
          equipos_empresas: equiposEmpresas,
        },
      })
      .then((res) => res.data.data);
  }

  getEquipoEmpresasCatalogos() {
    const url = process.env.VUE_APP_APIURL + `equipoempresas/catalogos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquipoEmpresasCombo() {
    const url = process.env.VUE_APP_APIURL + `equipoempresas/combos/equipos`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEquipoEmpresa(equipoempresa) {
    const url =
      process.env.VUE_APP_APIURL +
      `/equipoempresas/${equipoempresa.id_equipo_empresa}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEquipoEmpresa(equipoempresa) {
    const url =
      process.env.VUE_APP_APIURL +
      `/equipoempresas/${equipoempresa.id_equipo_empresa}`;
    return axios.put(url, equipoempresa).then((res) => res.data.data);
  }

  createEquipoEmpresa(equipoempresa) {
    const url = process.env.VUE_APP_APIURL + `/equipoempresas`;
    return axios.post(url, equipoempresa).then((res) => res.data.data);
  }

  createEquipoEmpresaCopia(equipoempresa) {
    const url = process.env.VUE_APP_APIURL + `/equipoempresas/copias`;
    return axios.post(url, equipoempresa).then((res) => res.data.data);
  }

  deleteEquipoEmpresa(equipoempresa) {
    const url =
      process.env.VUE_APP_APIURL +
      `/equipoempresas/${equipoempresa.id_equipo_empresa}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
