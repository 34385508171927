
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import ConfigSurvey from './config-survey.vue';
import ConfigSurveyUser from './config-survey-user.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    ConfigSurvey,
    ConfigSurveyUser,
  },
  data() {
    return {

    }
  },
  created() { },
  mounted() { },
  methods: {

  },
}
