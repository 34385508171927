import axios from "axios";

export default class StatusChecklistService {
  getEstatusListaVerificaciones() {
    const url = process.env.VUE_APP_APIURL + `estatuslistaverificaciones`;
    return axios.get(url).then((res) => res.data.data);
  }

  getEstatusListaVerificacion(estatuslistaverificacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatuslistaverificaciones/${estatuslistaverificacion.id_estatus_lista_verificacion}`;
    return axios.get(url).then((res) => res.data.data);
  }

  updateEstatusListaVerificacion(estatuslistaverificacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatuslistaverificaciones/${estatuslistaverificacion.id_estatus_lista_verificacion}`;
    return axios
      .put(url, estatuslistaverificacion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  createEstatusListaVerificacion(estatuslistaverificacion) {
    const url = process.env.VUE_APP_APIURL + `/estatuslistaverificaciones`;
    return axios
      .post(url, estatuslistaverificacion)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }

  deleteEstatusListaVerificacion(estatuslistaverificacion) {
    const url =
      process.env.VUE_APP_APIURL +
      `/estatuslistaverificaciones/${estatuslistaverificacion.id_estatus_lista_verificacion}`;
    return axios
      .delete(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
