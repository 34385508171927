
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxScrolling,
  DxPager,
  DxColumnChooser,
  DxSelection,
  DxSorting,
  DxForm,
  DxItem,
  DxValidationRule,
  DxMasterDetail,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
// Catalogos
import EquipmentCompanyService from '../services/equipment-company-service';
import EquipmentService from '../services/equipment-service';
import ClientService from '../services/client-service';
import LocationService from '../services/location-service';
import LayoutService from '../services/layout-service';
import ConditionService from '../services/condition-service';
import CompanyService from '../services/company-service';
import BrandService from '../services/brand-service';
import StatusWorkOrderEquipmentService from '../services/status-wo-equipment-service';
// TreeList
import WorkOrderEquipmentService from '../services/workorder-equipment-service';
import WorkOrderMasterDetail from './workorder-equipment-tab.vue';
import WorkOrderUserService from '../services/workorder-user-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxToast,
    DxColumnChooser,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule,
    DxMasterDetail,
    WorkOrderMasterDetail,
  },
  props: {
    templateData: {
      type: Object,
      default: () => { },
    },
    workOrderId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ordentrabajoequipos: null,
      dataSourceWorkOrderUsers: null,
      dataSourceWorkOrderEquipments: null,
      dataSourceConditions: null,
      dataSourceLayouts: null,
      dataSourceEquipments: null,
      dataSourceClients: null,
      dataSourceLocations: null,
      dataSourceCompanies: null,
      dataSourceFatherEquipment: null,
      dataSourceBrands: null,
      dataSourceEquipmentCompanies: null,
      dataSourceStatus: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      popupOptions: {
        title: 'Equipo',
        showTitle: true,
        width: 800,
      },
    };
  },
  created() {
    this.workOrderEquipmentService = new WorkOrderEquipmentService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.equipmentService = new EquipmentService();
    this.clientService = new ClientService();
    this.locationService = new LocationService();
    this.companyService = new CompanyService();
    this.layoutService = new LayoutService();
    this.conditionService = new ConditionService();
    this.brandService = new BrandService();
    this.statusWorkOrderEquipmentService = new StatusWorkOrderEquipmentService();
    this.workOrderUserService = new WorkOrderUserService();
  },
  mounted() {
    this.workOrderEquipmentService.getEquiposPorOrdenTrabajo(this.workOrderId).then(data => this.dataSourceWorkOrderEquipments = data);
    this.equipmentService.getEquipoCatalogos().then(data => this.dataSourceEquipments = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations = data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.layoutService.getLayouts().then(data => this.dataSourceLayouts = data);
    this.conditionService.getCondiciones().then(data => this.dataSourceConditions = data);
    //this.equipmentCompanyService.getEquipoEmpresasPadres().then(data => this.dataSourceFatherEquipment=data);
    this.workOrderEquipmentService.getEquiposComboPorOrdenTrabajo(this.workOrderId).then((data) => { this.dataSourceEquipmentCompanies = data; this.dataSourceFatherEquipment = data; });

    this.brandService.getMarcas().then(data => this.dataSourceBrands = data);
    this.statusWorkOrderEquipmentService.getEstatusOrdenTrabajoEquipos().then(data => this.dataSourceStatus = data);
    this.workOrderUserService.getUsuariosPorOrdenTrabajo(this.workOrderId).then(data => this.dataSourceWorkOrderUsers = data);
  },

  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    deleteWorkOrderEquipment(e) {
      this.workOrderEquipmentService.deleteOrdenTrabajoEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.workOrderEquipmentService.getEquiposComboPorOrdenTrabajo(this.workOrderId).then((data) => { this.dataSourceEquipmentCompanies = data; this.dataSourceFatherEquipment = data; });
      });
    },
    createWorkOrderEquipment(e) {

      this.workOrderEquipmentService.createOrdenTrabajoEquipo({
        "id_equipo_empresa": e.data.id_equipo_empresa, "id_orden_trabajo": this.workOrderId,
        "orden": e.data.orden, "reporte_servicio": e.data.reporte_servicio, "fecha_inicio_plan:": e.data.fecha_inicio_plan,
        "fecha_fin_plan": e.data.fecha_fin_plan, "id_estatus_orden_trabajo_equipo": e.data.id_estatus_orden_trabajo_equipo
      }).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;

        this.workOrderEquipmentService.getEquiposComboPorOrdenTrabajo(this.workOrderId).then((data) => { this.dataSourceEquipmentCompanies = data; this.dataSourceFatherEquipment = data; });
        this.workOrderEquipmentService.getEquiposPorOrdenTrabajo(this.workOrderId).then(data => this.dataSourceWorkOrderEquipments = data);

      });
    },
    onInitNewRow(e) {
      e.data.id_estatus_orden_trabajo_equipo = 1;
      e.data.reporte_servicio = true;
      e.data.orden = 1;
    },
    editWorkOrderEquipment(e) {
      this.workOrderEquipmentService.updateOrdenTrabajoEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;

        this.workOrderEquipmentService.getEquiposComboPorOrdenTrabajo(this.workOrderId).then((data) => { this.dataSourceEquipmentCompanies = data; this.dataSourceFatherEquipment = data; });
        this.workOrderEquipmentService.getEquiposPorOrdenTrabajo(this.workOrderId).then(data => this.dataSourceWorkOrderEquipments = data);

      });
    },
  },

};
