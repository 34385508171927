
  
  import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
  import ActionPage from './action.vue';
  import ActionWorkOrderPage from './action-workorder.vue';
  import ActionMaintenanceTypePage from './action-maintenance-type.vue';
  
  export default {
    components: {
      DxTabPanel,
      DxItem,
      ActionPage,
      ActionWorkOrderPage,
      ActionMaintenanceTypePage,
    },
    data() {
      return {
        selectedIndex: 0,
        loop: true,
        animationEnabled: true,
        swipeEnabled: false,
      };
    },
  };
  