import axios from 'axios';

export default class EquipmentCategoryDocumentService {
	
    getEquipoCategoriaAsignadosPorDocumento(documentoId) {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriadocumentos/asignados/${documentoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoCategoriaDisponiblesPorDocumento(documentoId) {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriadocumentos/disponibles/${documentoId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    createEquipoCategoriaDocumento(equipocategoriadocumento){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriadocumentos`;
		return axios.post(url,equipocategoriadocumento).then(res=>res.data.data);		
	}

	deleteEquipoCategoriaDocumento(equipocategoriadocumento){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriadocumentos/${equipocategoriadocumento.id_equipo_categoria_documento}`;
		return axios.delete(url).then(res=>res.data.data);
	}

}