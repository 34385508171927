
// :data="masterDetailData.data"
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import WorkOrderEquipment from './workorder-equipment.vue';
import WorkOrderUser from './workorder-user.vue';

export default {
  components: {
    DxTabPanel, DxItem, WorkOrderEquipment, WorkOrderUser
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
