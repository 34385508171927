
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import NotificationMethodService from '../services/notification-method-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceNotificationMethods: null,
      events: [],
      notificationMethodService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.notificationMethodService = new NotificationMethodService();
  },
  mounted() {    
    this.notificationMethodService.getMetodoNotificaciones().then(data => this.dataSourceNotificationMethods=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editNotificationMethods(e) {
			this.notificationMethodService.updateMetodoNotificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteNotificationMethods(e) {
			this.notificationMethodService.deleteMetodoNotificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createNotificationMethods(e) {
			this.notificationMethodService.createMetodoNotificacion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
