
import {
  DxTreeList,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxLookup, 
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/tree-list';
import { DxToast } from 'devextreme-vue/toast';
import PermitService from '../services/permit-service';
import GroupPermitService from '../services/group-permit-service';
import 'devextreme-vue/text-area';

export default {
  components: {
    DxTreeList,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxSorting,
  },
  data() {
    return {
      expanded: false,
      permisos: null,
      expandedRowKeys: [],
      dataSourcePermits: null,
      dataSourceFatherPermits: null,
      dataSourceGroupPermits: null,
      dataSourceApplications: [{"id_aplicacion": 1, "nombre": "WEB"},{"id_aplicacion": 2, "nombre": "APP"}],
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Permiso',
        showTitle: true,
        width: 800,
      },
    };
  },
  created() {
    this.permitService = new PermitService();
    this.groupPermitService = new GroupPermitService();    
  },
  mounted() {    
    this.groupPermitService.getGrupoPermisos().then(data => this.dataSourceGroupPermits=data);
    this.permitService.getPermisos().then((data) => {
      this.dataSourcePermits=data;
      //this.dataSourceFatherPermits=data;
    });   
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editPermit(e) {
      this.permitService.updatePermiso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        console.log('bandera');
      });
		},
    deletePermit(e) {
			this.permitService.deletePermiso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createPermit(e) {
			this.permitService.createPermiso(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
    onInitNewRow(e) {      
      e.data.activo = true;
      //e.data.id_equipo_padre = null;      
      //this.permitService.getEquipoPadres().then(data => this.dataSourceFatherPermits=data);
    },
  },  
};
