import axios from "axios";

export default class KpiSectionService {
  getkpisection() {
    const url = process.env.VUE_APP_APIURL + `/seccionkpi`;
    return axios
      .get(url)
      .then((res) => res.data.data)
      .catch((error) => {
        return error.response.data;
      });
  }
}
