
  import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,  
    DxScrolling,
    DxPager,
    DxColumnChooser,
    DxValidationRule,
    DxSelection,
    DxSorting,
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';
  import DxSelectBox from 'devextreme-vue/select-box';
  import MeasurementFormulaService from '../services/measurement-formula-service';
  import MeasurementService from '../services/measurement-service';  
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxPaging,
      DxEditing,    
      DxLookup,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxToast,
      DxColumnChooser,
      DxValidationRule,
      DxSelection,
      DxSorting,
      DxSelectBox
    },
    data() {
      return {
        group: {},
        clientes: null,
        dataSourceMeasurementFormulas: null,
        dataSourceMeasurements: null,
        dataSourceMeasurementVariables: null,
        allowedPageSizes: [5, 10, 20, 50, 'all'],
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        mode: 'select',
        Measurement: null,
      };
    },
    created() {
      this.MeasurementFormulaService = new MeasurementFormulaService();
      this.MeasurementService = new MeasurementService();
    },
    mounted() {    
      this.MeasurementService.getMedicionesTipoFormula().then(data => this.dataSourceMeasurements=data);
      this.MeasurementService.getMedicionesNumericas().then(data => this.dataSourceMeasurementVariables=data);
    },
    methods: {
      valueChangedMeasurement(e) {
        this.MeasurementFormulaService.getMedicionFormulasPorMedicion(e.value.id_medicion).then(data => this.dataSourceMeasurementFormulas=data);        
      },
      onEditorPreparing(e) {
        if (e.parentType === 'dataRow' && e.dataField === 'id_medicion') {
          e.editorOptions.disabled = true;
        }
      },
      /*
      onEditorPreparing(e) {
        if (e.parentType === 'dataRow' && e.dataField === 'id_grupo_tarea') {
          e.editorOptions.disabled = (typeof e.row.data.id_tarea !== 'number');
        }
      },
      setStateValue(rowData, value) {
        this.taskService.getTareasPorGrupo(value).then((data) => {
            this.dataSourceTasks=data;          
        });
        rowData.id_tarea = null;
        this.defaultSetCellValue(rowData, value);      
      },
      getFilteredTasks: (options) => ({
        filter: options.data ? ['id_grupo_tarea', '=', options.data.id_grupo_tarea] : null,
      }),
      */
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      editMeasurementFormula(e) {
          this.MeasurementFormulaService.updateMedicionFormula(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.MeasurementFormulaService.getMedicionFormulasPorMedicion(e.data.id_medicion).then(data => this.dataSourceMeasurementFormulas=data);
        });
      },
      deleteMeasurementFormula(e) {
          this.MeasurementFormulaService.deleteMedicionFormula(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
        });
      },
      createMeasurementFormula(e) {
          this.MeasurementFormulaService.createMedicionFormula(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.MeasurementFormulaService.getMedicionFormulasPorMedicion(e.data.id_medicion).then(data => this.dataSourceMeasurementFormulas=data);
        });
      },    
      onInitNewRow(e) {      
        e.data.id_medicion = this.Measurement.id_medicion;
      },
    },
  };
  