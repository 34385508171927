

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import Measurement from './measurement.vue';
import MeasurementOption from './measurement-option.vue';
import MeasurementFormula from './measurement-formula.vue';
import MeasurementFlow from './measurement-flow.vue';


export default {
  components: {
    DxTabPanel,
    DxItem,
    Measurement,
    MeasurementOption,
    MeasurementFlow,
    MeasurementFormula,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
