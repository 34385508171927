

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import PhysicalInventory from './physical-inventory.vue';
import PhysicalInventoryUser from './physical-inventory-user.vue';
import PhysicalInventoryActive from './physical-inventory-active.vue';
import PhysicalInventoryHistory from './physical-inventory-history.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    PhysicalInventory,
    PhysicalInventoryUser,
    PhysicalInventoryActive,
    PhysicalInventoryHistory,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
