import axios from 'axios';

export default class KpiUserService {

	getKPIAsignadosPorUsuario(usuarioId) {		
        const url = process.env.VUE_APP_APIURL + `kpiusuarios/asignados/${usuarioId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getKPIDisponiblesPorUsuario(usuarioId) {		
        const url = process.env.VUE_APP_APIURL + `kpiusuarios/disponibles/${usuarioId}`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getKPIUsuario(kpiusuario) {
        const url = process.env.VUE_APP_APIURL + `/kpiusuarios/${kpiusuario.id_kpi_usuario}`;
		return axios.get(url).then(res=>res.data.data);
	}

    updateKPIUsuario(kpiusuario){
        const url = process.env.VUE_APP_APIURL + `kpiusuarios/${kpiusuario.id_kpi_usuario}`;
		return axios.put(url,kpiusuario).then(res=>res.data.data);
	}

	createKPIUsuario(kpiusuario){
        const url = process.env.VUE_APP_APIURL + `/kpiusuarios`;
		return axios.post(url,kpiusuario).then(res=>res.data.data);		
	}

	deleteKPIUsuario(kpiusuario){
        const url = process.env.VUE_APP_APIURL + `/kpiusuarios/${kpiusuario.id_kpi_usuario}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}