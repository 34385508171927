import axios from 'axios';

export default class TypeMovementService {

	getTipoMovimientoEntradas() {			
        const url = process.env.VUE_APP_APIURL + `tipomovimientos/entradas`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getTipoMovimientoSalidas() {			
        const url = process.env.VUE_APP_APIURL + `tipomovimientos/salidas`;		
		return axios.get(url).then(res=>res.data.data);
	}

	getTipoMovimientos() {			
        const url = process.env.VUE_APP_APIURL + `tipomovimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoMovimiento(tipomovimiento) {
        const url = process.env.VUE_APP_APIURL + `/tipomovimientos/${tipomovimiento.id_tipo_movimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoMovimiento(tipomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipomovimientos/${tipomovimiento.id_tipo_movimiento}`;
		return axios.put(url,tipomovimiento).then(res=>res.data.data);
	}

	createTipoMovimiento(tipomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipomovimientos`;
		return axios.post(url,tipomovimiento).then(res=>res.data.data);
	}

	deleteTipoMovimiento(tipomovimiento){
        const url = process.env.VUE_APP_APIURL + `/tipomovimientos/${tipomovimiento.id_tipo_movimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}