import axios from 'axios';

export default class TypePhysicalInventoryService {

	getTipoInventarioFisicos() {			
        const url = process.env.VUE_APP_APIURL + `tipoinventariofisicos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getTipoInventarioFisico(tipoinventariofisico) {
        const url = process.env.VUE_APP_APIURL + `/tipoinventariofisicos/${tipoinventariofisico.id_tipo_inventario_fisico}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateTipoInventarioFisico(tipoinventariofisico){
        const url = process.env.VUE_APP_APIURL + `/tipoinventariofisicos/${tipoinventariofisico.id_tipo_inventario_fisico}`;
		return axios.put(url,tipoinventariofisico).then(res=>res.data.data);
	}

	createTipoInventarioFisico(tipoinventariofisico){
        const url = process.env.VUE_APP_APIURL + `/tipoinventariofisicos`;
		return axios.post(url,tipoinventariofisico).then(res=>res.data.data);
	}

	deleteTipoInventarioFisico(tipoinventariofisico){
        const url = process.env.VUE_APP_APIURL + `/tipoinventariofisicos/${tipoinventariofisico.id_tipo_inventario_fisico}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}