import axios from "axios";

export default class SurveyFrequencyServiece {
  getSurveyFrequency() {
    const url = process.env.VUE_APP_APIURL + `frecuenciaencuestas`;
    return axios.get(url).then((res) => res.data.data);
  }
  createSurveyFrequency(frecuenciaencuesta) {
    const url = process.env.VUE_APP_APIURL + `/frecuenciaencuestas`;
    return axios.post(url, frecuenciaencuesta).then((res) => res.data.data);
  }
  updateSurveyFrequency(frecuenciaencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/frecuenciaencuestas/${frecuenciaencuesta.id_frecuencia_encuesta}`;
    return axios.put(url, frecuenciaencuesta).then((res) => res.data.data);
  }
  deleteSurveyFrequency(frecuenciaencuesta) {
    const url =
      process.env.VUE_APP_APIURL +
      `/frecuenciaencuestas/${frecuenciaencuesta.id_frecuencia_encuesta}`;
    return axios.delete(url).then((res) => res.data.data);
  }
}
