import axios from 'axios';

export default class LogService {

    getLogsPorRangoFecha(fecha_inicio,fecha_fin) {			
        const url = process.env.VUE_APP_APIURL + `logs/fechas`;		
		return axios.get(url,{ params: {fecha_inicio: fecha_inicio, fecha_fin: fecha_fin} }).then(res=>res.data.data);
	}

	getLogsPorFiltro(id_empresa,fecha_inicio,fecha_fin) {			
        const url = process.env.VUE_APP_APIURL + `logs/filtros`;		
		return axios.get(url,{ params: {id_empresa: id_empresa, fecha_inicio: fecha_inicio, fecha_fin: fecha_fin} }).then(res=>res.data.data);
	}

	getLogs() {			
        const url = process.env.VUE_APP_APIURL + `logs`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getLog(log) {
        const url = process.env.VUE_APP_APIURL + `/logs/${log.id_log}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateLog(log){
        const url = process.env.VUE_APP_APIURL + `/logs/${log.id_log}`;
		return axios.put(url,log).then(res=>res.data.data);
	}

	createLog(log){
        const url = process.env.VUE_APP_APIURL + `/logs`;
		return axios.post(url,log).then(res=>res.data.data);
	}

	deleteLog(log){
        const url = process.env.VUE_APP_APIURL + `/logs/${log.id_log}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}