
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxFilterRow,
  DxScrolling,
  DxPager,
  DxSelection,
  DxSorting,
  DxForm,
  DxItem,
  DxValidationRule,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
// Catalogos
import UserService from '../services/user-service';
// DataGrid
import WorkOrderUserService from '../services/workorder-user-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,    
    DxLookup,
    DxFilterRow,
    DxScrolling,
    DxPager,
    DxToast,
    DxSelection,
    DxSorting,
    DxForm,
    DxItem,
    DxValidationRule
  },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    workOrderId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ordentrabajousuarios: null,
      dataSourceWorkOrderUsers: null,
      dataSourceUsers: null,      
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      popupOptions: {
        title: 'Usuario',
        showTitle: true,
        width: 400,
      },
    };
  },
  created() {
    this.workOrderUserService = new WorkOrderUserService();
    this.userService = new UserService();
  },
  mounted() {
    this.workOrderUserService.getUsuariosPorOrdenTrabajo(this.workOrderId).then(data => this.dataSourceWorkOrderUsers=data);
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers=data);
  },

  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    deleteWorkOrderUser(e) {
        console.log(e.data);
        this.workOrderUserService.deleteOrdenTrabajoUsuario({"id_orden_trabajo_responsable": e.data.id_orden_trabajo_responsable}).then(() => {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.workOrderUserService.getUsuariosPorOrdenTrabajo(this.workOrderId).then(data => this.dataSourceWorkOrderUsers=data);
        });
    },
    createWorkOrderUser(e) {
    
      this.workOrderUserService.createOrdenTrabajoUsuario({"id_usuario": e.data.id_usuario, "id_orden_trabajo": this.workOrderId, "responsable": e.data.responsable}).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.workOrderUserService.getUsuariosPorOrdenTrabajo(this.workOrderId).then(data => this.dataSourceWorkOrderUsers=data);
      });
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'apellido_paterno' || e.dataField === 'apellido_materno' || e.dataField === 'iniciales'))  {
        e.editorOptions.disabled = true;
      }
    },
    onInitNewRow(e) {
      e.data.responsable = false;
    },
  },
};
