

var dataSourceRequisitionItems = [];
var dataSourceRequisitionItems1 = [];
var dataSourceItem1 = [];
var dataSourceUnitOfMeasurement1 = [];

var dataSourceRequisitionServices = [];
var dataSourceRequisitionServices1 = [];
var dataSourceService1 = [];

import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxMasterDetail,
  DxValidationRule,
  DxColumnChooser,
  DxPopup,
  DxToolbar,
  DxButton as DxBtn
} from 'devextreme-vue/data-grid';
import { DxItem, DxTabbedItem, DxTab } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import { DxButton } from 'devextreme-vue/button';

import RequisitionService from '../services/requisition-service';
import StatusRequisitionService from '../services/status-requisition-service';
import ClientService from '../services/client-service';
import CompanyService from '../services/company-service';
import UserService from '../services/user-service';
import WarehouseService from '../services/warehouse-service';
import DepartmentService from '../services/department-service';

import DetailTemplate from './requisition-detail.vue';
import SupplierService from '../services/supplier-service';
import ItemService from '../services/item-service';
import ServiceService from '../services/service-service';
import UnitOfMeasurementService from '../services/unit-of-measurement-service';
import RequisitionItemService from '../services/requisition-item-service';
import TypeItemService from '../services/type-item-service'
import CurrencyService from '../services/currency-service'

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxMasterDetail,
    DetailTemplate,
    DxValidationRule,
    DxColumnChooser,
    DxTabbedItem,
    DxTab,
    DxPopup,
    DxButton,
    DxToolbar,
    DxBtn,
  },
  data() {
    return {
      gridRefName: 'dataGrid',
      usuario: null,
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      requisicion: null,
      detallerequisicion: null,
      dataSourceRequisitions: null,
      dataSourceStatus: null,
      dataSourceUsers: null,
      dataSourceCompanies: null,
      dataSourceWarehouse: null,
      dataSourceDepartments: null,
      dataSourceClients: null,
      dataSourceSupplier: null,
      dataSourceCurrencies: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Requisición',
        showTitle: true,
        width: 900,
        height: 600
      },
      mode: 'select',
      dataSourceRequisitionServices,
      dataSourceRequisitionServices1,
      dataSourceRequisitionItems1,
      dataSourceItem: null,
      dataSourceRequisitionItems,
      dataSourceUnitOfMeasurement: null,
      dataSourceTypeItem: null,
      dataSourceItem1,
      dataSourceService1,
      dataSourceUnitOfMeasurement1,
      setStateValue(rowData, value) {
        rowData.id_empresa = null;
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueArticulo(rowData, value) {
        var resultado = dataSourceItem1.filter((element) => element.id_articulo === value);
        if (resultado.length > 0) {
          rowData.id_unidad_medida = resultado[0].id_unidad_medida;
          rowData.articulo = resultado[0].articulo;
        }
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueServicio(rowData, value) {
        var resultado = dataSourceService1.filter((element) => element.id_servicio === value);
        if (resultado.length > 0) {
          rowData.id_unidad_medida = resultado[0].id_unidad_medida;
          rowData.servicio = resultado[0].servicio;
        }
        this.defaultSetCellValue(rowData, value);
      },
      setStateValueTipoArticulo(rowData, value) {
        rowData.id_articulo = null;
        this.defaultSetCellValue(rowData, value);
      },
      id_requisition: null,
      dataSourdeItems: null,
      dataSourdeServices: null,
      addButtonOptions: {
        icon: 'plus',
        onClick: () => {
          this.popupVisible = true;
        },
      },
      refreshButtonOptions: {
        icon: 'refresh',
        onClick: () => {
          this.refresh();
        },
      },
    };
  },
  created() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
    this.requisitionService = new RequisitionService();
    this.statusRequisitionService = new StatusRequisitionService();
    this.clientService = new ClientService();
    this.userService = new UserService();
    this.companyService = new CompanyService();
    this.warehouseService = new WarehouseService();
    this.departmentService = new DepartmentService();
    this.supplierService = new SupplierService();
    this.itemService = new ItemService();
    this.serviceService = new ServiceService();
    this.unitOfMeasurementService = new UnitOfMeasurementService();
    this.requisitionItemService = new RequisitionItemService();
    this.typeItemService = new TypeItemService();
    this.currencyService = new CurrencyService();
  },
  mounted() {
    this.userService.getUsuariosComboWeb().then(data => this.dataSourceUsers = data);
    this.clientService.getClientes().then(data => this.dataSourceClients = data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies = data);
    this.statusRequisitionService.getEstatusRequisiciones().then(data => this.dataSourceStatus = data);
    this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouse = data);
    this.departmentService.getDepartamentos().then(data => this.dataSourceDepartments = data);
    this.requisitionService.getRequisicionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceRequisitions = data);
    this.supplierService.getProveedores().then(data => this.dataSourceSupplier = data);
    this.typeItemService.getTipoArticulosComboRequisiciones().then(data => this.dataSourceTypeItem = data);
    this.currencyService.getMonedas().then(data => this.dataSourceCurrencies = data);
    this.itemService.getArticuloComboActivos().then(data => {
      dataSourceItem1 = data;
      this.dataSourceItem = data;
    });
    this.serviceService.getServicioComboActivos().then(data => {
      dataSourceService1 = data;
      this.dataSourceService = data;
    });
    this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
      dataSourceUnitOfMeasurement1 = data;
      this.dataSourceUnitOfMeasurement = data;
    });
  },
  methods: {
    refreshDataGrid() {
      //this.$refs[this.gridRefName].instance.refresh();
      this.requisitionService.getRequisicionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceRequisitions = data);
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editRequisition(e) {
      this.requisitionService.updateRequisicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.requisitionService.getRequisicionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceRequisitions = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al actualizar el registro';
        this.isVisible = true;
        this.requisitionService.getRequisicionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceRequisitions = data);
      });
      this.dataSourceRequisitionItems.forEach(element => {
        if (typeof (element.id_requisicion) === "undefined") {
          var dataJson = {
            id_requisicion: e.data.id_requisicion,
            cantidad: element.cantidad,
            id_unidad_medida: element.id_unidad_medida,
            precio_unitario: element.precio_unitario,
            id_moneda: element.id_moneda,
            id_articulo: element.id_articulo,
            articulo: element.articulo,
          };
          this.requisitionItemService.createRequisicionArticulo(dataJson);
        }
      });
      this.dataSourceRequisitionServices.forEach(element => {
        if (typeof (element.id_requisicion) === "undefined") {
          var dataJson = {
            id_requisicion: e.data.id_requisicion,
            cantidad: element.cantidad,
            id_unidad_medida: element.id_unidad_medida,
            precio_unitario: element.precio_unitario,
            id_moneda: element.id_moneda,
            id_servicio: element.id_servicio,
            servicio: element.servicio,
          };
          this.requisitionServiceService.createRequisicionServicio(dataJson);
        }
      });
    },
    deleteRequisition(e) {
      this.requisitionService.deleteRequisicion(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.requisitionService.getRequisicionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceRequisitions = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al eliminar el registro';
        this.isVisible = true;
        this.requisitionService.getRequisicionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceRequisitions = data);
      });
    },
    createRequisition(e) {
      var date = new Date(e.data.fecha);
      var datetoLocaleString = date.toLocaleString("en-ZA");
      e.data.fecha = datetoLocaleString;
      var user = JSON.parse(localStorage.getItem("user-data"));
      e.data.id_usuario = user;
      e.data.id_usuario_reg = user;
      this.requisitionService.createRequisicion(e.data).then((data) => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        console.log("Articulos");
        // Artículos
        if (this.dataSourdeItems != null) {
          this.dataSourdeItems.forEach(element => {
            var dataJson = {
              id_requisicion: data.id_requisicion,
              cantidad: element.cantidad,
              id_unidad_medida: element.id_unidad_medida,
              id_articulo: element.id_articulo,
              precio_unitario: element.precio_unitario,
              id_moneda: element.id_moneda,
              articulo: element.articulo,
            };
            this.requisitionItemService.createRequisicionArticulo(dataJson);
            this.dataSourdeItems = null;
            console.log("createRequisicionArticulo");
          });
        }
        // Servicios
        console.log("dataSourdeServices");
        console.log(this.dataSourdeServices);
        if (this.dataSourdeServices != null) {
          console.log("entro");
          this.dataSourdeServices.forEach(element => {
            var dataJson = {
              id_requisicion: data.id_requisicion,
              cantidad: element.cantidad,
              id_unidad_medida: element.id_unidad_medida,
              id_servicio: element.id_servicio,
              precio_unitario: element.precio_unitario,
              id_moneda: element.id_moneda,
              servicio: element.servicio,
            };
            console.log("dataJson");
            console.log(dataJson);
            this.requisitionServiceService.createRequisicionServicio(dataJson);
            this.dataSourdeServices = null;
            console.log("createRequisicionServicio");
          });
        }
        this.requisitionService.getRequisicionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceRequisitions = data);
      }).catch(() => {
        this.type = 'error';
        this.message = 'Falla al crear el registro';
        this.isVisible = true;
        this.requisitionService.getRequisicionesPorEmpresa(this.usuario.id_usuario_empresa).then(data => this.dataSourceRequisitions = data);
      });
    },
    onInitNewRow(e) {
      this.id_requisicion = null;
      var user = JSON.parse(localStorage.getItem("user"));
      e.data.fecha = Date.now();
      e.data.id_estatus_requisicion = 1;
      e.data.requiere_cotizacion = true;
      var resultado = this.dataSourceCompanies.filter((element) => element.id_empresa === user.id_usuario_empresa);
      if (resultado.length > 0) {
        e.data.id_empresa = user.id_usuario_empresa;
      }
    },
    onEditorRequisition(e) {
      /*
      this.dataSourceRequisitionItems = [];
      this.dataSourceItem = [];
      this.dataSourceUnitOfMeasurement = [];
      //this.typeItemService.getTipoArticulosCombo().then(data => this.dataSourceTypeItem = data);
      this.itemService.getArticulosComboActive().then(data => {
        dataSourceItem1 = data;
        this.dataSourceItem = data;
      });
      this.unitOfMeasurementService.getUnidadMedidasCombos().then(data => {
        dataSourceRequisitionItems1 = data;
        this.dataSourceUnitOfMeasurement = data;
      });
      */
      if (e.parentType === 'dataRow' && (e.dataField === 'id_estatus_requisicion')) {
        if (e.row.isEditing)
          e.editorOptions.disabled = true;
      }
      if (typeof (e.row) != "undefined") {
        if (e.parentType === 'dataRow' && (e.dataField === 'id_estatus_requisicion')) {
          if (!e.row.isEditing) {
            this.id_requisicion = e.row.data.id_requisicion;
            var dataJson = {
              id_requisicion: e.row.data.id_requisicion,
            };
            this.requisitionItemService.getRequisicionArticuloID(dataJson).then(data => {
              this.dataSourceRequisitionItems = data;
            });
            this.requisitionServiceService.getRequisicionServicioID(dataJson).then(data => {
              this.dataSourceRequisitionServices = data;
            });
          }
        }
      }
      /*
      if (typeof (e.row) != "undefined") {

        this.id_requisicion = e.row.data.id_requisicion;
        var dataJson = {
          id_requisicion: e.row.data.id_requisicion,
        };
        console.log('onEditorRequisition');
        this.requisitionItemService.getRequisicionArticuloID(dataJson).then(data => {
          this.dataSourceRequisitionItems = data;
        });
      }
      */
    },
    createRequisitionItems(e) {
      this.dataSourdeItems = this.dataSourceRequisitionItems;
      e.data.id_requisicion = this.id_requisicion;
      if (this.id_requisicion != null) {
        this.requisitionItemService.createRequisicionArticulo(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.requisitionItemService.getRequisicionArticuloID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.dataSourceRequisitionItems = [];
          this.requisitionItemService.getRequisicionArticuloID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionItems = data);
        });
      }
    },
    editRequisitionItems(e) {
      e.data.id_requisicion = this.id_requisicion;
      if (this.id_requisicion != null) {
        var dataJson = {
          id_requisicion: this.id_requisicion,
          id_requisicion_articulo: e.data.id_requisicion_articulo,
          id_articulo: e.data.id_articulo,
          cantidad: e.data.cantidad,
        };
        this.requisitionItemService.updateRequisicionArticulo(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.requisitionItemService.getRequisicionArticuloID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.requisitionItemService.getRequisicionArticuloID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionItems = data);
        });
      }
    },
    deleteRequisitionItems(e) {
      e.data.id_requisicion = this.id_requisicion;
      if (this.id_requisicion != null) {
        var dataJson = {
          id_requisicion_articulo: e.data.id_requisicion_articulo,
        };
        this.requisitionItemService.deleteRequisicionArticulo(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.requisitionItemService.getRequisicionArticuloID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionItems = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.requisitionItemService.getRequisicionArticuloID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionItems = data);
        });
      }
    },
    getFilteredArticulo: (options) => (
      {
        store: dataSourceItem1,
        filter: options.data ? ['id_tipo_articulo', '=', options.data.id_tipo_articulo] : null,
      }),
    onInitNewRowRequisitionItems(e) {
      e.data.cantidad = 1;
      e.data.id_tipo_articulo = 1;
    },

    createRequisitionServices(e) {
      this.dataSourdeServices = this.dataSourceRequisitionServices;
      e.data.id_requisicion = this.id_requisicion;
      if (this.id_requisicion != null) {
        this.requisitionServiceService.createRequisicionServicio(e.data).then(() => {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.requisitionServiceService.getRequisicionServicioID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionServices = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al crear el registro';
          this.isVisible = true;
          this.dataSourceRequisitionServices = [];
          this.requisitionServiceService.getRequisicionServicioID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionServices = data);
        });
      }
    },
    editRequisitionServices(e) {
      e.data.id_requisicion = this.id_requisicion;
      if (this.id_requisicion != null) {
        var dataJson = {
          id_requisicion: this.id_requisicion,
          id_requisicion_servicio: e.data.id_requisicion_servicio,
          id_servicio: e.data.id_servicio,
          cantidad: e.data.cantidad,
          precio_unitario: e.data.precio_unitario,
          id_moneda: e.data.id_moneda,
          servicio: e.data.servicio,
        };
        this.requisitionServiceService.updateRequisicionServicio(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.requisitionServiceService.getRequisicionServicioID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionServices = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.requisitionServiceService.getRequisicionServicioID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionServices = data);
        });
      }
    },
    deleteRequisitionServices(e) {
      e.data.id_requisicion = this.id_requisicion;
      if (this.id_requisicion != null) {
        var dataJson = {
          id_requisicion_servicio: e.data.id_requisicion_servicio,
        };
        this.requisitionServiceService.deleteRequisicionServicio(dataJson).then(() => {
          this.type = 'success';
          this.message = 'Se ha editado el registro';
          this.isVisible = true;
          this.requisitionServiceService.getRequisicionServicioID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionServices = data);
        }).catch(() => {
          this.type = 'error';
          this.message = 'Falla al editar el registro';
          this.isVisible = true;
          this.requisitionServiceService.getRequisicionServicioID({ id_requisicion: this.id_requisicion }).then(data => this.dataSourceRequisitionServices = data);
        });
      }
    },
    getFilteredServicio: () => (
    {
      store: dataSourceService1
    }),
    onInitNewRowRequisitionServices(e) {
      e.data.cantidad = 1;
    },

  },
};
