import axios from 'axios';

export default class ChannelService {

	getCanales() {				
        const url = process.env.VUE_APP_APIURL + `canales`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getCanal(marca) {
        const url = process.env.VUE_APP_APIURL + `/canales/${marca.id_canal}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateCanal(marca){
        const url = process.env.VUE_APP_APIURL + `/canales/${marca.id_canal}`;
		return axios.put(url,marca).then(res=>res.data.data);
	}

	createCanal(marca){
        const url = process.env.VUE_APP_APIURL + `/canales`;
		return axios.post(url,marca).then(res=>res.data.data);
	}

	deleteCanal(marca){
        const url = process.env.VUE_APP_APIURL + `/canales/${marca.id_canal}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}