

import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';
import Product from './product.vue';
import ProductSpecialField from './product-special-field.vue';
import ProductSparePart from './product-spare-part.vue';
import GenericEquipment from './generic-equipment.vue';
import Equipment from './equipment.vue';

import RolePermitService from '../services/role-permit-service'

export default {
  components: {
    DxTabPanel,
    DxItem,
    Product,
    ProductSpecialField,
    ProductSparePart,
    GenericEquipment,
    Equipment
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
      components: false,
      specialFields: false,
      spareParts: false,
      teamBuilding: false,
      genericEquipment: false
    };
  },
  created() {
    this.rolePermitService = new RolePermitService();
  },
  mounted() {
    var user = JSON.parse(localStorage.getItem("user-data"));

    var dataJsonComponents = {
      id_usuario: user,
      clave: 'OP-COM'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonComponents).then(data => {
      if (data) {
        this.components = true;
      } else {
        this.components = false;
      }
    });

    var dataJsonSpecialFields = {
      id_usuario: user,
      clave: 'OP-COM-CE'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonSpecialFields).then(data => {
      if (data) {
        this.specialFields = true;
      } else {
        this.specialFields = false;
      }
    });

    var dataJsonSpareParts = {
      id_usuario: user,
      clave: 'OP-COM-REF'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonSpareParts).then(data => {
      if (data) {
        this.spareParts = true;
      } else {
        this.spareParts = false;
      }
    });

    var dataJsonTeamBuilding = {
      id_usuario: user,
      clave: 'OP-COM-AE'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonTeamBuilding).then(data => {
      if (data) {
        this.teamBuilding = true;
      } else {
        this.teamBuilding = false;
      }
    });

    var dataJsonGenericEquipment = {
      id_usuario: user,
      clave: 'OP-COM-EG'
    };
    this.rolePermitService.getRolPermisoPorClave(dataJsonGenericEquipment).then(data => {
      if (data) {
        this.genericEquipment = true;
      } else {
        this.genericEquipment = false;
      }
    });
  }
};
