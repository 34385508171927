
import { DxDataGrid, DxColumn, DxGrouping } from "devextreme-vue/data-grid";
import { DxBox, DxItem } from "devextreme-vue/box";
import DxList from "devextreme-vue/list";
import DxTileView from "devextreme-vue/tile-view";
import DxGallery from "devextreme-vue/gallery";
import DxSelectBox from "devextreme-vue/select-box";
import DxDateBox from "devextreme-vue/date-box";
import DxButton from "devextreme-vue/button";
//import { DxScrollView } from "devextreme-vue/scroll-view";
//import { DxSortable } from "devextreme-vue/sortable";

import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import html2canvas from "html2canvas";
import ServiceReportService from "../services/service-report-service";
import CompanyService from "../services/company-service";
import ClientService from "../services/client-service";
import WorkOrderService from "../services/workorder-service";
import CompanyClientService from "../services/company-client-service";
import axios from "axios";
import CryptoJS from "crypto-js";

const leftHeader = [];

const rightHeader = [];

const faultReport = [];

const dataSourceFaulReportFiles = [];

const dataSourceEquipmentFields1 = [];

const dataSourceEquipmentFields2 = [];

const dataSourceEquipmentFields3 = [];

const dataSourceEquipmentFields4 = [];

const dataSourceSpecialFields1 = [
  { name: "Volúmen / capacidad", value: "1 gal" },
  { name: "Presión de diseño", value: "30 psi" },
  { name: "HP", value: "10 hp" },
];

const dataSourceSpecialFields2 = [
  { name: "Presión de Paro", value: "85 + 10%" },
  { name: "Gasto", value: "90 gpm" },
  { name: "Presión de Succión", value: "positiva" },
];

const dataSourceSpecialFields3 = [
  { name: "RPM de diseño", value: "120 rpm" },
  { name: "Emp. Placa", value: "21amp@3550" },
  { name: null, value: null },
];

const dataSourceSpecialFields4 = [
  { name: null, value: null },
  { name: null, value: null },
  { name: null, value: null },
];

const dataSourceTaskMeasurements = [
  {
    id_medicion: 1,
    tarea: "Revisión de condiciones del tablero",
    medicion: "Switch general de posición energizado",
    resultado: "BUENO",
    accion: null,
    observaciones: null,
    responsable: null,
  },
  {
    id_medicion: 2,
    tarea: null,
    medicion: "Switch de arranque en automático",
    resultado: "BUENO",
    accion: null,
    observaciones: null,
    responsable: null,
  },
  {
    id_medicion: 3,
    tarea: null,
    medicion: "Exterior sin golpes y limpio",
    resultado: "BUENO",
    accion: null,
    observaciones: null,
    responsable: null,
  },
  {
    id_medicion: 4,
    tarea: null,
    medicion: "Sin cables sueltos o desconectados",
    resultado: "MALO",
    accion: "Corregido",
    observaciones: null,
    responsable: "JAL",
  },
  {
    id_medicion: 5,
    tarea: "Calibración del switch de presión de arranque",
    medicion: "Calibrar switch de presión de arranque",
    resultado: "165 psi",
    accion: null,
    observaciones: null,
    responsable: null,
  },
];

const dataSourceSpareParts = [
  {
    id_articulo: 2,
    cantidad: 1,
    um: "pza",
    clave: "BG002",
    nombre: "Bomba multietapa",
    equipo: "BMDSL01 Sistema de Bombeo Motor Diesel",
    precio_unitario: 4500,
    importe: 4500,
  },
  {
    id_articulo: 2,
    cantidad: 2,
    um: "pza",
    clave: "BN032",
    nombre: "Banda para motor",
    equipo: "BMDSL01 Sistema de Bombeo Motor Diesel",
    precio_unitario: 135,
    importe: 135,
  },
];

const dataSourceLegends = [
  { name: "Empresa", value: "SHN Monterrey" },
  { name: "Cliente", value: "Workin" },
  { name: "Atención", value: "Arq. Raúl Salazar, Hugo Malacar" },
  { name: "Proyecto", value: "Workin" },
  { name: "Técnicos", value: "José Ángel, Jaime Leal" },
  { name: "Cliente1", value: "Workin" },
  { name: "Atención1", value: "Arq. Raúl Salazar, Hugo Malacar" },
  { name: "Proyecto1", value: "Workin" },
  { name: "Técnicos1", value: "José Ángel, Jaime Leal" },
];

function decryptId(encryptedId, key) {
  const ciphertext = decodeURIComponent(encryptedId);
  const bytes = CryptoJS.AES.decrypt(ciphertext, key);
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  return parseInt(plaintext, 10);
}

export default {
  props: ['id_orden_trabajo'],
  components: {
    DxBox,
    DxItem,
    DxList,
    DxTileView,
    DxGallery,
    DxSelectBox,
    DxDateBox,
    DxButton,
    //DxScrollView,
    //DxSortable,
    DxDataGrid,
    DxColumn,
    DxGrouping,
  },
  data() {
    const now = new Date();
    return {
      autoExpandAll: false,
      equipments: [
        {
          id_equipo_empresa: 1,
          nombre: "Motor US 10 Hp@100 psi",
          marca: "US Motors",
          modelo: "p63 AMV 1435",
          serie: "9893762",
        },
        {
          id_equipo_empresa: 2,
          nombre: "Motor US 30 Hp@100 psi",
          marca: "US Motors",
          modelo: "p70 AMV 1435",
          serie: "3312442",
        },
      ],
      dropFeedbackMode: "push",
      itemOrientation: "horizontal",
      dragDirection: "horizontal",
      scrollSpeed: 30,
      scrollSensitivity: 60,
      handle: "",
      dragTemplate: "",

      directionDxTileView: 'vertical',
      height: '100%',
      baseItemHeight: 220,
      baseItemWidth: 220,
      width: '100%',
      itemMargin: 10,
      workorder: null,
      usuarioId: null,
      company: null,
      client: null,
      startDate: new Date(now.getFullYear(), now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      visibleReport: true,
      folioReporteFalla: true,
      leftHeader,
      rightHeader,
      faultReport,
      dataSourceFaulReportFiles,
      dataSourceLegends,
      dataSourceServiceReport: null,
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceWorkOrders: null,
      dataSourceEquipment: null,
      dataSourceEquipmentFields1,
      dataSourceEquipmentFields2,
      dataSourceEquipmentFields3,
      dataSourceEquipmentFields4,
      dataSourceSpecialFields1,
      dataSourceSpecialFields2,
      dataSourceSpecialFields3,
      dataSourceSpecialFields4,
      dataSourceTaskMeasurements,
      dataSourceSpareParts,
      selectionMode: "none",
      listData: [{ itemProperty: "someValue" }],
      base64: "",
      url: "",
      viewPDF: false
    };
  },
  created() {
    this.serviceReportService = new ServiceReportService();
    this.companyService = new CompanyService();
    this.clientService = new ClientService();
    this.companyClientService = new CompanyClientService();
    this.workOrderService = new WorkOrderService();
    this.companyService
      .getEmpresasCombo()
      .then((data) => (this.dataSourceCompanies = data));
  },
  mounted() {

    console.log(this.$route.params.id_orden_trabajo);

    this.consultMount(parseInt(decryptId(this.$route.params.id_orden_trabajo, process.env.VUE_APP_ENCRYPT_KEY)));
    this.companyService
      .getEmpresasCombo()
      .then((data) => (this.dataSourceCompanies = data));
  },
  methods: {
    getPriorityClass(activity) {
      return `priority-${activity.id_estatus}`;
    },
    consultMount(id_orden_trabajo) {
      this.workorder = {
        "id_orden_trabajo": id_orden_trabajo
      };
      this.consult(id_orden_trabajo);
    },

    getClientCompany() {
      this.companyClientService
        .getClientesAsignadosPorEmpresaCombo(this.company.id_empresa)
        .then((data) => (this.dataSourceClients = data));
      this.getWorkOrder();
    },

    getWorkOrder() {
      var id_usuario = localStorage.getItem("user-data");

      if (
        this.company != null &&
        this.client != null &&
        this.startDate != null &&
        this.endDate != null
      ) {
        const params = {
          id_empresa: this.company.id_empresa,
          id_cliente: this.client.id_cliente,
          fecha_inicio: this.startDate,
          fecha_fin: this.endDate,
          id_usuario: id_usuario,
        };
        this.workOrderService
          .getWorkOrder(params)
          .then((data) => (this.dataSourceWorkOrders = data));
      }
    },

    searchWO() {
      this.consult(this.workorder.id_orden_trabajo);
    },

    consult(idOrdenTrabajo) {
      this.viewPDF = false;
      if (this.consult != null) {
        this.serviceReportService
          .getReporteServiciosWeb(idOrdenTrabajo)
          //.getReporteServiciosWeb(this.workorder.id_orden_trabajo)
          .then((data) => {
            this.dataSourceServiceReport = data;
            this.viewPDF = data.orden_trabajo.pdf;

            this.leftHeader = [
              { name: "Empresa", value: data.orden_trabajo.empresa },
              { name: "Cliente", value: data.orden_trabajo.cliente },
              { name: "Atención", value: data.orden_trabajo.atencion },
              { name: "Ubicación", value: data.orden_trabajo.ubicacion },
              { name: "Responsable", value: data.orden_trabajo.responsable },
            ];

            this.rightHeader = [
              { name: "Orden de Trabajo", value: data.orden_trabajo.folio + (data.orden_trabajo.folioorigen == null ? '' : ' (Relación con ' + data.orden_trabajo.folioorigen + ')') },
              {
                name: "Fecha de órden de trabajo",
                value: data.orden_trabajo.fecha,
              },
              {
                name: "Tipo de Mantenimiento",
                value: data.orden_trabajo.tipo_mantenimiento,
              },
              {
                name: "Proyecto", value: data.orden_trabajo.proyecto
              },
              { name: "Referencia", value: data.orden_trabajo.referencia },
            ];

            this.visibleReport =
              data.orden_trabajo.origen_orden_trabajo_clave == "RF"
                ? true
                : false;

            if (this.visibleReport) {
              this.folioReporteFalla = "";
              if (data.origen_orden_trabajo.length > 0) {
                this.folioReporteFalla = data.origen_orden_trabajo[0].folio;
                this.faultReport = [
                  {
                    name: "Fecha del reporte",
                    value: data.origen_orden_trabajo[0].fecha,
                  },
                  { name: "Título", value: data.origen_orden_trabajo[0].titulo },
                  {
                    name: "Descripción",
                    value: data.origen_orden_trabajo[0].descripcion,
                  },
                  {
                    name: "Reportado por",
                    value: data.origen_orden_trabajo[0].usuario_reporta,
                  },
                ];
              }

              this.dataSourceFaulReportFiles = [];

              data.reportefallas.forEach((element) => {
                var files = {
                  url: element.url,
                  descripcion: element.descripcion,
                  titulo: element.titulo,
                  usuario: element.usuario_reporta,
                  fecha: element.fecha_reg,
                  id_tipo_archivo: element.id_tipo_archivo,
                };
                this.dataSourceFaulReportFiles.push(files);
              });
            }

            this.dataSourceEquipment = data.equipos;
          });
      }
    },

    printPDF(nameDiv) {
      console.log(nameDiv);

      let pdfRef = this.$refs.contentreporteserviciopdf;

      html2canvas(pdfRef).then((canvas) => {
        let pdfImage = canvas.toDataURL("image/jpeg", 1.0);

        let pdf = new jsPDF("l", "mm", [750, 750]);
        pdf.addImage(pdfImage, "image/jpg", 0, 0);
        pdf.autoPrint();
        window.open(
          URL.createObjectURL(pdf.output("blob")),
          "_blank",
          "height=650,width=500,scrollbars=yes,location=yes"
        );
        setTimeout(() => {
          window.URL.revokeObjectURL(pdf.output("bloburl"));
        }, 100);
      });

    },

    openModal(url) {
      var modal = document.getElementById("modalimage");
      var modalImg = document.getElementById("seemodalimage");
      modal.style.display = "block";
      modalImg.src = url;
    },

    closeModal() {
      var modal = document.getElementById("modalimage");
      modal.style.display = "none";
    },

    printPDFReport() {
      const doc = new jsPDF('portrait', 'mm', 'a4');
      var imageurl = this.dataSourceServiceReport["orden_trabajo"]["logo_empresa"];

      // var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();	
      doc.setFontSize(14);
      doc.text("Reporte de Servicio", 160, 15, { align: "left" });
      var img = new Image;
      img.src = imageurl;
      doc.addImage(img, 5, 5, 50, 20);
      doc.setFontSize(8);

      doc.setFillColor("E2F7DA");
      doc.rect(5, 30, 200, 28, 'F');


      doc.text("Empresa: " + this.dataSourceServiceReport["orden_trabajo"]["empresa"], 8, 35, { align: "left" });
      doc.text("Cliente: " + this.dataSourceServiceReport["orden_trabajo"]["cliente"], 8, 40, { align: "left" });
      doc.text("Atención: " + this.dataSourceServiceReport["orden_trabajo"]["atencion"], 8, 45, { align: "left" });
      doc.text("Proyecto: " + (this.dataSourceServiceReport["orden_trabajo"]["proyecto"] ?? ""), 8, 50, { align: "left" });
      doc.text("Responsable: " + (this.dataSourceServiceReport["orden_trabajo"]["responsable"] ?? ""), 8, 55, { align: "left" });

      doc.text("Orden de Trabajo: " + (this.dataSourceServiceReport["orden_trabajo"]["folio"] ?? ""), 120, 35, { align: "left" });
      doc.text("Fecha de órden de trabajo: " + (this.dataSourceServiceReport["orden_trabajo"]["fecha"] ?? ""), 120, 40, { align: "left" });
      doc.text("Tipo de Mantenimiento: " + (this.dataSourceServiceReport["orden_trabajo"]["tipo_mantenimiento"] ?? ""), 120, 45, { align: "left" });
      doc.text("Clasificación: " + (this.dataSourceServiceReport["orden_trabajo"]["tipo_mayor_menor"] ?? ""), 120, 50, { align: "left" });
      doc.text("Referencia: " + (this.dataSourceServiceReport["orden_trabajo"]["referencia"] ?? ""), 120, 55, { align: "left" });

      let value = 65;

      if (this.dataSourceServiceReport.orden_trabajo["origen_orden_trabajo_clave"] == "RF") {
        doc.setFillColor("EBF1F2");
        doc.rect(5, value - 5, 200, 28, 'F');
        doc.setFontSize(10);
        doc.text("Reporte de Falla: " + (this.dataSourceServiceReport.origen_orden_trabajo[0].folio ?? ""), 8, value, { align: "left" });
        value += 5;
        doc.text("Fecha del reporte: " + (this.dataSourceServiceReport.origen_orden_trabajo[0].fecha ?? ""), 8, value, { align: "left" });
        value += 5;
        doc.text("Título: " + (this.dataSourceServiceReport.origen_orden_trabajo[0].titulo ?? ""), 8, value, { align: "left" });
        value += 5;
        doc.text("Descripción: " + (this.dataSourceServiceReport.origen_orden_trabajo[0].descripcion ?? ""), 8, value, { align: "left" });
        value += 5;
        doc.text("Reportado por: " + (this.dataSourceServiceReport.origen_orden_trabajo[0].usuario_reporta ?? ""), 8, value, { align: "left" });
        value += 10;

        var reportefallas = this.dataSourceServiceReport.reportefallas.filter(ele => ele.id_tipo_archivo === 6);
        if (reportefallas.length > 0) {
          var countfalla = Number((reportefallas.length / 4).toFixed(2));

          let fallaposicion = value;
          doc.setFontSize(8);
          reportefallas.forEach((reporte, index) => {
            var img = new Image;
            img.src = (reporte["url"] ?? "");

            let desimg = this.truncate((reporte["descripcion"] ?? ""), 25, '...');
            let usuario_reporta = this.truncate((reporte["usuario_reporta"] ?? ""), 25, '...');
            let fecha_reg = this.truncate((reporte["fecha_reg"] ?? ""), 20, '...');
            index += 1;

            if ((index % 4) == 1) {
              doc.addImage(img, 5, fallaposicion, 40, 40);

              doc.text(desimg, 5, fallaposicion + 45, { align: "left" });
              doc.text(usuario_reporta, 5, fallaposicion + 50, { align: "left" });
              doc.text(fecha_reg, 5, fallaposicion + 55, { align: "left" });

            }

            if ((index % 4) == 2) {
              doc.addImage(img, 55, fallaposicion, 40, 40);
              doc.text(desimg, 55, fallaposicion + 45, { align: "left" });
              doc.text(usuario_reporta, 55, fallaposicion + 50, { align: "left" });
              doc.text(fecha_reg, 55, fallaposicion + 55, { align: "left" });
            }

            if ((index % 4) == 3) {
              doc.addImage(img, 105, fallaposicion, 40, 40);
              let desimg = this.truncate((reporte["descripcion"] ?? ""), 28, '...');
              doc.text(desimg, 105, fallaposicion + 45, { align: "left" });
              doc.text(usuario_reporta, 105, fallaposicion + 50, { align: "left" });
              doc.text(fecha_reg, 105, fallaposicion + 55, { align: "left" });
            }

            if ((index % 4) == 0) {
              doc.addImage(img, 155, fallaposicion, 40, 40);
              let desimg = this.truncate((reporte["descripcion"] ?? ""), 28, '...');
              doc.text(desimg, 155, fallaposicion + 45, { align: "left" });
              doc.text(usuario_reporta, 155, fallaposicion + 50, { align: "left" });
              doc.text(fecha_reg, 155, fallaposicion + 55, { align: "left" });
              fallaposicion += 58;
            }
          });
          value += ((countfalla) > 1 ? fallaposicion - 20 : 65);
        }
      }

      this.dataSourceEquipment.forEach((element, index) => {
        doc.setFontSize(10);
        doc.text((element.info_equipo[0]["nombre_equipo"] ?? ""), 5, value, { align: "left" });
        value += 3;
        if ((element.info_equipo[0]["alias"] ?? "").length > 0) {
          value += 3;
          doc.text((element.info_equipo[0]["alias"] ?? ""), 5, value, { align: "left" });
        }
        var comentario = "";
        if ((element.info_equipo[0]["iniciales"] ?? "").length > 0) {
          comentario += (element.info_equipo[0]["iniciales"] ?? "");
        }
        if ((element.info_equipo[0]["accion_texto"] ?? "").length > 0) {
          comentario += (" • Acción: " + (element.info_equipo[0]["accion_texto"] ?? ""));
        }
        if ((element.info_equipo[0]["comentario_mtto"] ?? "").length > 0) {
          comentario += (" • Comentarios: " + (element.info_equipo[0]["comentario_mtto"] ?? ""));
        }
        value += 3;
        doc.setFontSize(8);
        doc.text(comentario, 5, value, { align: "left" });
        value += 3;

        doc.setFillColor("EBF1F2");
        doc.rect(5, value, 200, element.dataSourceEquipmentFields1.length * 5.2, 'F');
        let equipodetalle = value;
        element.dataSourceEquipmentFields1.forEach(detalle1 => {
          value += 5;
          doc.text((detalle1["name"] ?? "") + ": " + (detalle1["value"] ?? ""), 8, value, { align: "left" });
        });
        value = equipodetalle;
        element.dataSourceEquipmentFields2.forEach(detalle2 => {
          value += 5;
          let valuedetalle = this.truncate((detalle2["value"] ?? ""), 10, '...')
          doc.text((detalle2["name"] ?? "") + ": " + valuedetalle, 50, value, { align: "left" });
        });
        value = equipodetalle;
        element.dataSourceEquipmentFields3.forEach(detalle3 => {
          value += 5;
          let valuedetalle = this.truncate((detalle3["value"] ?? ""), 20, '...')
          doc.text((detalle3["name"] ?? "") + ": " + valuedetalle, 100, value, { align: "left" });
        });
        value = equipodetalle;
        element.dataSourceEquipmentFields4.forEach(detalle4 => {
          value += 5;
          doc.text((detalle4["name"] ?? "") + ": " + (detalle4["value"] ?? ""), 160, value, { align: "left" });
        });

        value += 5;

        if (element.dataSourceSpecialFields1.length > 0) {
          value += 5;

          doc.text("Características especiales", 5, value, { align: "left" });
          let specialvalue = value;

          element.dataSourceSpecialFields1.forEach(special1 => {
            value += 5;
            let valuespecial1 = this.truncate((special1["value"] ?? ""), 20, '...')
            doc.text((special1["name"] ?? "") + ": " + valuespecial1, 5, value, { align: "left" });
          });

          value = specialvalue;
          element.dataSourceSpecialFields2.forEach(special2 => {
            value += 5;
            let valuespecial1 = this.truncate((special2["value"] ?? ""), 20, '...')
            doc.text((special2["name"] ?? "") + ": " + valuespecial1, 50, value, { align: "left" });
          });

          value = specialvalue;
          element.dataSourceSpecialFields3.forEach(special3 => {
            value += 5;
            let valuespecial1 = this.truncate((special3["value"] ?? ""), 20, '...')
            doc.text((special3["name"] ?? "") + ": " + valuespecial1, 100, value, { align: "left" });
          });

          value = specialvalue;
          element.dataSourceSpecialFields4.forEach(special4 => {
            value += 5;
            let valuespecial1 = this.truncate((special4["value"] ?? ""), 20, '...')
            doc.text((special4["name"] ?? "") + ": " + valuespecial1, 160, value, { align: "left" });
          });

          var speciallength = element.dataSourceSpecialFields1.length;
          value += speciallength * 3;
          value += 5;
        }

        value += 5;

        doc.text("Tareas y Mediciones", 5, value, { align: "left" });

        var columnsTareaMedicion = [
          { title: "Tarea", dataKey: "tarea_accion" },
          { title: "Medición", dataKey: "medicion" },
          { title: "Resultado", dataKey: "resultado" },
          { title: "Acción", dataKey: "accion" },
          { title: "Observaciones", dataKey: "observaciones" },
          { title: "Técnico", dataKey: "usuario" },
        ];
        value += 5;
        let coordenada = 0
        let final = 0;

        if (index > 0) {
          if (doc.autoTable.previous) {
            final = value;
            coordenada = Number(doc.autoTable.previous.finalY.toFixed());
          } else {
            coordenada = 10
            final = value;
          }
        } else {
          coordenada = value;
          final = value;
        }


        doc.autoTable(columnsTareaMedicion, element.tareasmedicion, {
          rowPageBreak: 'avoid',
          pageBreak: 'auto',
          finalY: final,
          startY: (final),
        });
        if (doc.autoTable.previous) {
          coordenada = Number(doc.autoTable.previous.finalY.toFixed());
        }
        value = + (coordenada + 20);

        var evidenciasimg = element.evidencias.filter(ele => ele.tipo_archivo === "Imagen");

        if (evidenciasimg.length > 0) {
          value += 5;
          doc.text("Evidencias", 5, value, { align: "left" });
          value += 5;
          if (value >= 240) {
            doc.addPage();
            value = 15; // Restart height position
          }
          var countevidencia = Number((evidenciasimg.length / 4).toFixed());

          let imgposicion = value;
          evidenciasimg.forEach((imgEvidencia, index) => {
            var img = new Image;
            img.src = (imgEvidencia["archivo"] ?? "");
            index += 1;
            if (imgposicion >= 240) {
              doc.addPage();
              imgposicion = 15; // Restart height position
            }

            if ((index % 4) == 1) {
              doc.addImage(img, 5, imgposicion, 40, 40);
              let desimg = this.truncate((imgEvidencia["descripcion"] ?? ""), 28, '...');
              doc.text(desimg, 5, imgposicion + 45, { align: "left" });

            }

            if ((index % 4) == 2) {
              doc.addImage(img, 55, imgposicion, 40, 40);
              let desimg = this.truncate((imgEvidencia["descripcion"] ?? ""), 28, '...');
              doc.text(desimg, 55, imgposicion + 45, { align: "left" });
            }

            if ((index % 4) == 3) {
              doc.addImage(img, 105, imgposicion, 40, 40);
              let desimg = this.truncate((imgEvidencia["descripcion"] ?? ""), 28, '...');
              doc.text(desimg, 105, imgposicion + 45, { align: "left" });
            }

            if ((index % 4) == 0) {
              doc.addImage(img, 155, imgposicion, 40, 40);
              let desimg = this.truncate((imgEvidencia["descripcion"] ?? ""), 28, '...');
              doc.text(desimg, 155, imgposicion + 45, { align: "left" });
              imgposicion += 48;
            }

          });
          value += (countevidencia * 70);
        }
        if (value >= 240) {
          doc.addPage();
          value = 15; // Restart height position
        }
      });

      if ((this.dataSourceServiceReport["orden_trabajo"]["comentario"] ?? "").length > 0) {
        value += 10;
        doc.setFontSize(14);
        doc.text("Comentarios de la orden de trabajo", 5, value, { align: "left" });
        value += 5;
        doc.setFontSize(8);
        var comentario = doc.splitTextToSize((this.dataSourceServiceReport["orden_trabajo"]["comentario"] ?? ""), 180);
        doc.text(comentario, 5, value, { align: "left" });
        value += 25;
      }
      if ((this.dataSourceServiceReport["orden_trabajo"]["firma"] ?? "").length > 0) {
        doc.setFontSize(14);
        doc.text("Firmas", 5, value, { align: "left" });
        value += 5;
        var imgfirmaurl = (this.dataSourceServiceReport["orden_trabajo"]["firma"] ?? "");
        var imgfirma = new Image;
        imgfirma.src = imgfirmaurl;
        doc.addImage(imgfirma, 5, value, 60, 60);
        value += 5;
      }

      doc.autoPrint();
      window.open(
        URL.createObjectURL(doc.output("blob")),
        "_blank",
        "scrollbars=yes,location=yes"
      );
      // var formData = new FormData();
      // formData.append('file', doc.output("blob"), this.dataSourceServiceReport["orden_trabajo"]["id_orden_trabajo"]);
      // formData.append('id_orden_trabajo', this.dataSourceServiceReport["orden_trabajo"]["id_orden_trabajo"]);

      // this.serviceReportService
      //   .uploadfile(formData);

      setTimeout(() => {
        window.URL.revokeObjectURL(doc.output("bloburl"));
      }, 100);
    },
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    downloadpdf() {
      if (this.viewPDF) {
        axios({
          url: process.env.VUE_APP_APIURL + `ordentrabajos/descargar?id_orden_trabajo=` + this.workorder.id_orden_trabajo,
          method: 'GET',
          responseType: 'blob',
        }).then((res) => {
          var url = window.URL.createObjectURL((res.data));
          var namepdf = this.dataSourceServiceReport.orden_trabajo.clave_empresa + "_";
          var cliente = this.dataSourceServiceReport.orden_trabajo.clave_cliente ?? "";
          if (cliente.length > 0) {
            namepdf += cliente + "_";
          }
          namepdf += this.dataSourceServiceReport.orden_trabajo.folio.toString();
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', namepdf + '.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    }


  },
}; 
