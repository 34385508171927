
import {
  DxTreeList,
  DxColumn,
  DxLookup, 
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxEditing,
  DxSorting
} from 'devextreme-vue/tree-list';
import { DxToast } from 'devextreme-vue/toast';
import DxSelectBox from 'devextreme-vue/select-box';
import Button from 'devextreme-vue/button';
import ConfigPlanMaintenanceService from '../services/config-plan-maintenance-service';
import ConfigPlanMaintenanceEquipmentService from '../services/config-plan-maintenance-equipment-service';
import EquipmentCompanyService from '../services/equipment-company-service';
import EquipmentService from '../services/equipment-service';
import ClientService from '../services/client-service';
import LocationService from '../services/location-service';
import LayoutService from '../services/layout-service';
import ConditionService from '../services/condition-service';
import CompanyService from '../services/company-service';

export default {
  components: {
    DxTreeList,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    DxColumnChooser,
    DxSelectBox,
    Button,
    DxEditing,
    DxSorting
  },
  data() {
    return {
      polizamantenimiento: null,      
      dataSourceMaintenancePlan: null,
      dataSourceMaintenancePlanEquipmentAvailable: null,
      dataSourceMaintenancePlanEquipmentAssigned: null,
      dataSourceConditions: null,
      dataSourceLayouts: null,
      dataSourceEquipments: null,
      dataSourceClients: null,
      dataSourceLocations: null,
      dataSourceCompanies: null,
      dataSourceFatherEquipment: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      mode: 'select',
      selectedRowsData: [],
      maintenanceplan: null,
      i: 0,
    };
  },
  created() {
    this.configPlanMaintenanceService = new ConfigPlanMaintenanceService();
    this.configPlanMaintenanceEquipmentService = new ConfigPlanMaintenanceEquipmentService();
    this.equipmentCompanyService = new EquipmentCompanyService();
    this.equipmentService = new EquipmentService();
    this.clientService = new ClientService();
    this.locationService = new LocationService();
    this.companyService = new CompanyService();
    this.layoutService = new LayoutService();
    this.conditionService = new ConditionService();
  },
  mounted() {    
    this.configPlanMaintenanceService.getConfigPlanMttos().then(data => this.dataSourceMaintenancePlan=data);
    this.equipmentService.getEquipoCatalogos().then(data => this.dataSourceEquipments=data);
    this.clientService.getClientes().then(data => this.dataSourceClients=data);
    this.locationService.getLocalidades().then(data => this.dataSourceLocations=data);
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.layoutService.getLayouts().then(data => this.dataSourceLayouts=data);
    this.conditionService.getCondiciones().then(data => this.dataSourceConditions=data);  
    this.equipmentCompanyService.getEquipoEmpresasPadres().then(data => this.dataSourceFatherEquipment=data);    
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRowsData.map((data) => data.id_equipo_empresa);
    },
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    addEquipment() {
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          this.configPlanMaintenanceEquipmentService.createConfigPlanMttoEquipo({"id_config_plan_mtto": this.maintenanceplan.id_config_plan_mtto, "id_equipo_empresa": this.selectedRowsData[this.i].id_equipo_empresa}).then(() => {           
            
            if (this.i==this.selectedRowsData.length) {
              
              this.configPlanMaintenanceEquipmentService.getEquiposDisponiblesPorPlan(this.maintenanceplan.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipmentAvailable=data);
              this.configPlanMaintenanceEquipmentService.getEquiposAsignadosPorPlan(this.maintenanceplan.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipmentAssigned=data);
            }
            
          });
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      //console.log(selectedRowsData[0].id_equipo_empresa);
      this.selectedRowsData = selectedRowsData;
      /*
      console.log(this.maintenanceplan);
      for (this.i = 0; this.i < this.selectedRowsData.length; this.i++) {
          console.log(this.selectedRowsData[this.i].id_equipo_empresa);
      }
      */
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && (e.dataField === 'fecha_ultimo_mtto' || e.dataField === 'id_equipo_empresa_padre' || e.dataField === 'imagen' || 
      e.dataField === 'codigo_qr' || e.dataField === 'disponible' || e.dataField === 'id_condicion' || e.dataField === 'edificio' || 
      e.dataField === 'posicion' || e.dataField === 'nivel' || e.dataField === 'fecha_adquisicion' || e.dataField === 'fuera_servicio' ||
      e.dataField === 'longitud' || e.dataField === 'latitud' ||e.dataField === 'referencia' ||e.dataField === 'id_localizacion' || 
      e.dataField === 'id_cliente' || e.dataField === 'id_empresa' || e.dataField === 'id_equipo' || e.dataField === 'marca' || 
      e.dataField === 'modelo' || e.dataField === 'numero_activo' || e.dataField === 'serie' || e.dataField === 'clave'))  {
        e.editorOptions.disabled = true;
      }
    },
    /*
    selectFirstRow(e) {
        //const rowKey = e.component.getKeyByRowIndex(0);
        this.selectedRowKeys = [...this.selectedRowKeys, e.value.id_plan_mantenimiento];
        console.log(this.selectedRowKeys);
    },
    onEditorPreparing(e) {
      if (e.dataField === 'id_equipo_padre' && e.row.data.id_equipo === 0) {
        e.editorOptions.disabled = true;
        e.editorOptions.value = null;
      }
    },
    */
    valueChangedMaintenancePlan(e) {
        this.configPlanMaintenanceEquipmentService.getEquiposDisponiblesPorPlan(e.value.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipmentAvailable=data);
        this.configPlanMaintenanceEquipmentService.getEquiposAsignadosPorPlan(e.value.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipmentAssigned=data);           
    },
    deleteMaintenancePlan(e) {
      this.configPlanMaintenanceEquipmentService.deleteConfigPlanMttoEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.configPlanMaintenanceEquipmentService.getEquiposDisponiblesPorPlan(this.maintenanceplan.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipmentAvailable=data);
        this.configPlanMaintenanceEquipmentService.getEquiposAsignadosPorPlan(this.maintenanceplan.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipmentAssigned=data); 
      });
    },
    editMaintenancePlan(e) {
      console.log(e.data);
      this.configPlanMaintenanceEquipmentService.updateConfigPlanMttoEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        //this.configPlanMaintenanceEquipmentService.getEquiposDisponiblesPorPlan(this.maintenanceplan.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipmentAvailable=data);
        this.configPlanMaintenanceEquipmentService.getEquiposAsignadosPorPlan(this.maintenanceplan.id_config_plan_mtto).then(data => this.dataSourceMaintenancePlanEquipmentAssigned=data); 
      });
    }
    
  },  
};
