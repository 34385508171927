
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxScrolling,
  DxPager,
  DxSelection,
  DxSorting,
  DxButton,
  DxGroupPanel,
  DxGrouping
} from 'devextreme-vue/data-grid';
import { DxResponsiveBox, DxItem, DxLocation, DxCol, DxRow } from 'devextreme-vue/responsive-box';
import DxScheduler, { DxEditing, DxResource } from 'devextreme-vue/scheduler';
import { DxSlider } from 'devextreme-vue/slider';

import TypeMaintenanceService from '../services/type-maintenance-service';
import StatusWorkOrderService from '../services/status-wo-service';
import PortalClientService from '../services/portal-client-service';
// import moment from 'moment'; 


const downloadFile2 = (path, filename) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;
  anchor.setAttribute('target', '_blank');

  // Append to the DOM
  document.body.appendChild(anchor);

  anchor.click();

  document.body.removeChild(anchor);
};
const format = (value) => `${value}%`;

export default {
  components: {
    DxResponsiveBox, DxItem, DxLocation, DxCol, DxRow,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxScrolling,
    DxPager,
    DxSelection,
    DxSorting,
    DxButton,
    DxScheduler,
    DxEditing,
    DxResource,
    DxGroupPanel,
    DxGrouping,
    DxSlider
  },
  data() {
    return {
      encuestas: null,
      screen(width) {
        return (width < 700) ? 'sm' : 'lg';
      },
      imageURL: process.env.VUE_APP_APIURL + "storage/fotos/",
      imageDocs: process.env.VUE_APP_APIURL + "storage/documentos/",
      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
      dataSourceSurveys: null,
      dataSourceTypeMaintenance: null,
      dataSourceStatusWorkOrder: null,
      dataSourceClientWorkOrders: [
      ],
      dataSourceClientWorkOrdersDev: null,
      dataSourceTechnical: null,
      dataSourceHeaders: null,
      dataSourceTeams: null,
      dataSourceContacts: null,
      currentDate: new Date(),
      dataSourceDocuments: null,
      selectedRowKeys: [],
      views: ['day', 'week', 'workWeek', 'month'],
      documentos: null,
      radioGroupValue: 'Estatus',
      allowedPageSizes: [10, 20, 50, 'all'],
      tooltip: {
        enabled: false,
        position: 'top',
        format,
      },
    };
  },
  created() {
    this.typeMaintenanceService = new TypeMaintenanceService();
    this.statusWorkOrderService = new StatusWorkOrderService();
    this.portalClientService = new PortalClientService();
  },
  computed: {

  },
  mounted() {
    this.typeMaintenanceService.getCalendarioTipoMantenimientos().then(data => this.dataSourceTypeMaintenance = data);
    this.statusWorkOrderService.getEstatusOrdenTrabajos().then(data => {
      this.dataSourceStatusWorkOrder = data;
      for (const keydata in data) {
        data[keydata].color = '#' + data[keydata].color;
        data[keydata].text = data[keydata].nombre;
        data[keydata].id = data[keydata].id_estatus_orden_trabajo;
      }
    });
    this.portalClientService.getPortalClientHeaders().then(data => this.dataSourceHeaders = data[0]);
    this.portalClientService.getPortalClientCalendars().then(data => {

      this.dataSourceTechnical = data.responsables;
      this.dataSourceClientWorkOrders = data.orden_trabajo;

    });
    this.portalClientService.getPortalClientTeams().then(data => this.dataSourceTeams = data);
    this.portalClientService.getPortalClientDocuments().then(data => this.dataSourceDocuments = data);
    this.portalClientService.getPortalClientSurveys().then(data => this.dataSourceSurveys = data);
    this.portalClientService.getPortalClientContacts().then(data => this.dataSourceContacts = data);

  },
  methods: {
    downloadMyFile(e) {
      const liga = this.imageDocs + e.row.data.archivo;
      console.log(liga);
      downloadFile2(liga, e.row.data.archivo);
      console.log(e.row.data.archivo);
      console.log(e.row.data.url);
    },
    openSurvey(e) {
      this.$router.push({ path: "/survey/" + e.row.data.token });
    }
  }
};
