

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import EquipmentCompany from './equipment-company.vue';
import EquipmentCompanyQR from './equipment-company-qr.vue';
import EquipmentCompanyData from './equipment-company-data.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    EquipmentCompany,
    EquipmentCompanyQR,
    EquipmentCompanyData,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: true,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
