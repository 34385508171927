
import ProfileService from '../services/profile-service';

export default {
  data() {
    return {
      completeName: null,
      userName: null,
      role: null,
      initials: null,
      email: null,
      position: null,
      groupName: null,
      bossName: null,
      phone: null,
      notes: null,
      dataSourceProfile: null,
    };
  },
  created() {
    this.profileService = new ProfileService();
  },
  mounted() {
    this.profileService.getPerfil().then((data) => {
      this.dataSourceProfile=data;
      this.completeName = data.nombre;
      this.userName = data.usuario;
      this.role = data.rol;
      this.initials = data.iniciales;
      this.email = data.email;
      this.position = data.puesto;
      this.bossName = data.nombre_padre;
      this.groupName = data.grupo;
      this.phone = data.celular;
      this.notes = data.comentario;
    });  
  },
};
