import axios from 'axios';

export default class EquipmentCategoryProcedureService {
    
	getEquipoCategoriaProcedimientosPorTarea(equipoCategoriaTareaId) {
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareaprocedimientos/equipocategoriatareas/${equipoCategoriaTareaId}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getEquipoCategoriaProcedimientos() {		
        const url = process.env.VUE_APP_APIURL + `equipocategoriatareaprocedimientos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getEquipoCategoriaProcedimiento(equipocategoriaprocedimiento) {
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaprocedimientos/${equipocategoriaprocedimiento.id_equipo_categoria_tarea_procedimiento}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateEquipoCategoriaProcedimiento(equipocategoriaprocedimiento){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaprocedimientos/${equipocategoriaprocedimiento.id_equipo_categoria_tarea_procedimiento}`;
		return axios.put(url,equipocategoriaprocedimiento).then(res=>res.data.data);
	}

	createEquipoCategoriaProcedimiento(equipocategoriaprocedimiento){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaprocedimientos`;
		return axios.post(url,equipocategoriaprocedimiento).then(res=>res.data.data);
	}

	deleteEquipoCategoriaProcedimiento(equipocategoriaprocedimiento){
        const url = process.env.VUE_APP_APIURL + `/equipocategoriatareaprocedimientos/${equipocategoriaprocedimiento.id_equipo_categoria_tarea_procedimiento}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}