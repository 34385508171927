import axios from 'axios';

export default class ActionWorkOrderService {

	getAccionOrdenTrabajos() {		
        const url = process.env.VUE_APP_APIURL + `accionordentrabajos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getAccionOrdenTrabajo(accionordentrabajo) {
        const url = process.env.VUE_APP_APIURL + `/accionordentrabajos/${accionordentrabajo.id_accion_orden_trabajo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateAccionOrdenTrabajo(accionordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/accionordentrabajos/${accionordentrabajo.id_accion_orden_trabajo}`;
		return axios.put(url,accionordentrabajo).then(res=>res.data.data);
	}

	createAccionOrdenTrabajo(accionordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/accionordentrabajos`;
		return axios.post(url,accionordentrabajo).then(res=>res.data.data);
	}

	deleteAccionOrdenTrabajo(accionordentrabajo){
        const url = process.env.VUE_APP_APIURL + `/accionordentrabajos/${accionordentrabajo.id_accion_orden_trabajo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}