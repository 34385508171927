

import {DxTabPanel, DxItem} from 'devextreme-vue/tab-panel';
import Role from './role.vue';
import RolePermit from './role-permit.vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    Role,
    RolePermit,
  },
  data() {
    return {
      selectedIndex: 0,
      loop: false,
      animationEnabled: true,
      swipeEnabled: false,
    };
  },
};
