
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import CurrencyService from '../services/currency-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
  },
  data() {
    return {      
      dataSourceCurrency: null,
      moneda: {},
      events: [],
      currencyService: null,
      isVisible: false,
      message: '',
      type: 'info',
    };
  },
  created() {
    this.currencyService = new CurrencyService();
  },
  mounted() {    
    this.currencyService.getMonedas().then(data => this.dataSourceCurrency=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editCurrency(e) {
			this.currencyService.updateMoneda(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.currencyService.getMonedas().then(data => this.dataSourceCurrency=data);
      });
		},
    deleteCurrency(e) {
			this.currencyService.deleteMoneda(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.currencyService.getMonedas().then(data => this.dataSourceCurrency=data);
      });
		},
    createCurrency(e) {
			this.currencyService.createMoneda(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.currencyService.getMonedas().then(data => this.dataSourceCurrency=data);
      });
		},
    onInitNewRow(e) {      
      e.data.activo = true;
    },
  },
};
