
import {
  DxDataGrid, DxColumn, DxLookup, DxEditing, DxPaging, DxValidationRule, DxFilterRow, DxSelection, DxScrolling, DxPager,
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import ConsecutiveService from '../services/consecutive-service';
import CompanyService from '../services/company-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxValidationRule,
    DxFilterRow,
    DxSelection,
    DxScrolling,
    DxPager,
    DxLookup,
  },
  data() {
    return {      
      dataSourceConsecutives: null,
      dataSourceCompanies: null,
      folio: {},
      events: [],
      consecutiveService: null,
      isVisible: false,
      message: '',
      type: 'info',
      allowedPageSizes: [5, 10, 20, 50, 'all'],
    };
  },
  created() {
    this.consecutiveService = new ConsecutiveService();
    this.companyService = new CompanyService();
  },
  mounted() {
    this.companyService.getEmpresas().then(data => this.dataSourceCompanies=data);
    this.consecutiveService.getFolios().then(data => this.dataSourceConsecutives=data);
  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editConsecutive(e) {
			this.consecutiveService.updateFolio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;        
      });
		},
    deleteConsecutive(e) {
			this.consecutiveService.deleteFolio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
		},
    createConsecutive(e) {
			this.consecutiveService.createFolio(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
		},
  },
};
