import axios from 'axios';

export default class FindingLevelService {

	getNivelHallazgos() {			
        const url = process.env.VUE_APP_APIURL + `nivelhallazgos`;		
		return axios.get(url).then(res=>res.data.data);
	}

    getNivelHallazgo(nivelhallazgo) {
        const url = process.env.VUE_APP_APIURL + `/nivelhallazgos/${nivelhallazgo.id_nivel_hallazgo}`;
		return axios.get(url).then(res=>res.data.data);
	}

	updateNivelHallazgo(nivelhallazgo){
        const url = process.env.VUE_APP_APIURL + `/nivelhallazgos/${nivelhallazgo.id_nivel_hallazgo}`;
		return axios.put(url,nivelhallazgo).then(res=>res.data.data);
	}

	createNivelHallazgo(nivelhallazgo){
        const url = process.env.VUE_APP_APIURL + `/nivelhallazgos`;
		return axios.post(url,nivelhallazgo).then(res=>res.data.data);
	}

	deleteNivelHallazgo(nivelhallazgo){
        const url = process.env.VUE_APP_APIURL + `/nivelhallazgos/${nivelhallazgo.id_nivel_hallazgo}`;
		return axios.delete(url).then(res=>res.data.data);
	}
}