import axios from 'axios';

export default class EquipmentCompanyDataService {

	getLevantamientoDatos() {		
        const url = process.env.VUE_APP_APIURL + `equipoempresas/levantamientodatos`;		
		return axios.get(url).then(res=>res.data.data);
	}

	updateLevantamientoDato(ordentrabajomedicion){
        const url = process.env.VUE_APP_APIURL + `/equipoempresas/levantamientodatos/${ordentrabajomedicion.id_orden_trabajo_medicion}`;
		return axios.put(url,ordentrabajomedicion).then(res=>res.data.data);
	}

	deleteLevantamientoDato(ordentrabajomedicion){
        const url = process.env.VUE_APP_APIURL + `/equipoempresas/levantamientodatos/${ordentrabajomedicion.id_orden_trabajo_medicion}`;
		return axios.delete(url,ordentrabajomedicion).then(res=>res.data.data);
	}
}