

import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxLookup, 
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxToast } from 'devextreme-vue/toast';
import DxPopup, { DxToolbarItem }from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxList from 'devextreme-vue/list';
import notify from 'devextreme/ui/notify';

import BrandService from '../services/brand-service';
import GroupItemService from '../services/group-item-service';
import TypeItemService from '../services/type-item-service';
import WarehouseService from '../services/warehouse-service';
import StockService from '../services/stock-service';
import StockSerieService from '../services/stock-serie-service';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxSelection,
    DxColumnChooser,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxList,
    DxButton,
    DxGroupPanel,
    DxGrouping,
    DxToolbar,
    DxItem,
  },
  data() {
    return {
      gridRefName: 'dataGrid',
      popupTitle: "Series del Artículo",
      popupVisible: false,
      stockId: null,
      popupStock: null,
      existencias: null,
      dataSourceInventory: null,
      dataSourceBrands: null,
      dataSourceItemTypes: null,
      dataSourceItemGroups: null,
      dataSourceWarehouses: null,
      dataSourceSeries: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      mode: 'select',
      isVisible: false,
      message: '',
      type: 'info',
      loading: false,
      refreshButtonOptions: {
        icon: 'refresh',
        onClick: () => {
          this.loadIndicatorVisible = true;
          this.stockService.getExistencias().then((data) => {
            this.dataSourceInventory=data;
            notify('Se han actualizado las existencias');
          });
        },
      },
    };
  },
  created() {
    this.brandService = new BrandService();
    this.groupItemService = new GroupItemService();
    this.typeItemService = new TypeItemService();
    this.warehouseService = new WarehouseService();
    this.stockService = new StockService();
    this.stockSerieService = new StockSerieService();
  },
  mounted() {    
    this.brandService.getMarcas().then(data => this.dataSourceBrands=data);
    this.groupItemService.getGrupoArticulos().then(data => this.dataSourceItemGroups=data);
    this.typeItemService.getTipoArticulos().then(data => this.dataSourceItemTypes=data);
    this.warehouseService.getAlmacenes().then(data => this.dataSourceWarehouses=data);
    this.stockService.getExistencias().then(data => this.dataSourceInventory=data);
  },
  methods: {
    showSeries(e) {
      this.showPopup(e.row.data.nombre, e.row.data.id_existencia);
    },
    showPopup(popupStock, stockId) {
      this.loading = true;      
      this.stockId = stockId;
      this.popupStock = popupStock;
      this.popupVisible = true;
      this.refresh();
    },
    async refresh() {      
      await this.stockSerieService.getExistenciaSeries(this.stockId).then((data) => {
        this.dataSourceSeries=data;
        this.loading = false;
      });
    },
    closeClick() {
        this.popupVisible = false;
    },
    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },
  }
};
